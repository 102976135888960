import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { Formik, Form, ErrorMessage } from 'formik'
import { useSelector } from 'react-redux'
import './AddAndModifyClientModal.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import Input from 'components/Others/Input/Input'
import provinces from 'resources/provinces.json'
import LicensesTable from 'components/Office/Clients/AddAndModifyClientModal/LicensesTable/LicensesTable'
import parseFormValues from 'myMethods/parseFormValues'
import { putClient, postClient, getClientByDni } from 'services/Clients'
import { Client as formSchema } from 'formSchemas/Client'
import formatIban from 'myMethods/formatIban'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import useService from 'hooks/useService'
import Select from 'components/Others/Select/Select'
import Modal from 'components/Others/Modal/Modal'
import useResources from 'hooks/useResources'

export default function AddAndModifyClientModal({ dni, refetch, closeModal, isNew = false }) {
  const [data, setData] = useState({})
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [showProvince, setShowProvince] = useState(Boolean(data?.zipCode))
  const [values, setValues] = useState({})
  const [licenses, setLicenses] = useState(data?.chargeAccount?.authVehicles || [])
  const maxNoteLength = useSelector(store => store.config.itv?.accounting?.maxNoteLength)
  const { clientPaymentMethods } = useResources({ clientPaymentMethods: true })
  const chargeAccountNeeded = useMemo(
    () => ['RECIBO BANCARIO', 'CRÉDITO'].includes(paymentMethod),
    [paymentMethod]
  )
  const form = useRef()
  const initialValuesClear = useMemo(
    () => ({
      dni: '',
      name: '',
      address: '',
      zipCode: '',
      province: '',
      locality: '',
      email: '',
      phone: '',
      note: '',
      paymentMethod: '',
      iban: '',
      showPaymentMethod: true,
    }),
    []
  )

  const { fetch: fetchClient, loading: loading1 } = useService({
    service: getClientByDni,
    serviceParams: dni,
  })
  const { fetch: servicePostClient, loading: loading2 } = useService({
    service: postClient,
  })
  const { fetch: servicePutClient, loading: loading3 } = useService({
    service: putClient,
  })

  const loading = useMemo(() => loading1 || loading2 || loading3, [loading1, loading2, loading3])
  const hasError = useMemo(
    () => !loading && !isNew && !Object.keys(data).length > 0,
    [loading, isNew, data]
  )

  const handleInitialValues = useCallback(() => {
    if (Object.keys(values).length) {
      return parseFormValues({ ...values, iban: values.iban ? formatIban(values.iban) : '' }, true)
    } else {
      return initialValuesClear
    }
  }, [values, initialValuesClear])

  const handleZipCode = useCallback(zipCode => {
    if (zipCode && zipCode.toString().length > 1) {
      const id = zipCode.toString().substring(0, 2)
      const province = provinces.find(elem => elem.id === id)
      form.current.setFieldValue('province', province ? province.value : '')
      setShowProvince(true)
    } else {
      form.current.setFieldValue('province', '')
      form.current.setFieldValue('locality', '')
      setShowProvince(false)
    }
  }, [])

  const handleAccept = useCallback(() => {
    try {
      form.current?.submitForm()
    } catch (err) {
      console.error(err)
    }
  }, [])

  const handleSubmit = async values => {
    try {
      if (!isNew) values.dni = data?.dni
      if (chargeAccountNeeded) values.iban = values.iban || data.chargeAccount?.iban
      values.iban = values.iban?.replace(/\s+/g, '') || null
      if (values.note) values.note = values.note.toUpperCase().trim()
      setValues(values)

      if (chargeAccountNeeded) {
        values.chargeAccount = {
          iban: values.iban,
          authVehicles: licenses.map(elem => ({ license: elem.license })),
          state: 1,
          showPaymentMethod: values.showPaymentMethod,
        }
      }

      values.phone = values.phone.toString()
      values = parseFormValues(values)

      if (!isNew) {
        await servicePutClient(values)
        easyToast('dark', `Cliente ${values.dni} actualizado`)
      } else {
        await servicePostClient(values)
        easyToast('dark', `Cliente ${values.dni} añadido`)
      }

      refetch()
      closeModal()
    } catch (err) {
      if (err.response?.status === 422) {
        easyToast('error', 'Este cliente ya existe')
      } else {
        easyToast('error', 'Ha ocurrido un error guardando los datos. Inténtelo de nuevo')
      }
    }
  }

  useEffect(() => {
    if (dni && !isNew && !Object.keys(data).length) {
      fetchClient(dni)
        .then(res => {
          if (res) {
            setData(res)
            const newValues = { ...initialValuesClear }
            Object.keys(newValues).forEach(key => {
              newValues[key] = res[key] || ''
              if (key === 'iban') newValues[key] = res.chargeAccount?.iban || ''
              if (key === 'showPaymentMethod')
                newValues[key] = res.chargeAccount?.showPaymentMethod || false
            })
            setValues(newValues)
            if (res.chargeAccount?.authVehicles) setLicenses(res.chargeAccount?.authVehicles)
            setPaymentMethod(res.paymentMethod)
            setShowProvince(Boolean(res.zipCode))
          }
        })
        .catch(() => easyToast('error', 'Ha ocurrido un error cargando los datos del cliente'))
    }
  }, [dni, isNew, data, fetchClient, initialValuesClear])

  useEffect(() => {
    if (chargeAccountNeeded && data.chargeAccount?.iban) {
      form.current?.setFieldValue('iban', formatIban(data.chargeAccount.iban))
    }
  }, [data.chargeAccount, chargeAccountNeeded])

  return (
    <Modal
      open={true}
      title={isNew ? 'Añadir cliente' : 'Editar cliente'}
      loading={loading}
      hasAcceptButton={!hasError}
      onClose={closeModal}
      onAccept={handleAccept}
      closeAfterAccept={false}
      closeOnOverlay={false}
      content={
        <>
          {hasError ? (
            <div className="no-values">Ha ocurrido un error cargando los datos del cliente</div>
          ) : (
            <Formik
              enableReinitialize
              initialValues={handleInitialValues()}
              validationSchema={formSchema}
              innerRef={form}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
                <div className="add-and-modify-client-modal" data-aos="zoom-in">
                  {!isNew && data.user?.username && data.user?.name && (
                    <div className="add-and-modify-client-modal__last-modification">
                      Última modificación realizada por
                      <span>
                        {data.user.username} - {data.user.name}
                      </span>
                    </div>
                  )}
                  <Form className="add-and-modify-client-modal__form">
                    {!isNew ? (
                      <div className="add-and-modify-client-modal__dni">{data?.dni}</div>
                    ) : (
                      <div className="add-and-modify-client-modal__dni--new">
                        <Input
                          className="form-input"
                          label="DNI/CIF"
                          name="dni"
                          onChange={e => setFieldValue('dni', e.target.value.toUpperCase())}
                        />
                      </div>
                    )}
                    <Input className="form-input" label="Nombre o razón social" name="name" />
                    <Input className="form-input" label="Domicilio" name="address" />
                    <Input
                      className="form-input"
                      label="Código Postal"
                      name="zipCode"
                      type="number"
                      inputMode="numeric"
                      onChange={e => {
                        setFieldValue('zipCode', e.target.value)
                        handleZipCode(e.target.value)
                      }}
                    />
                    {showProvince && (
                      <>
                        <Select
                          label="Provincia"
                          ErrorComponent={
                            <ErrorMessage
                              className="form-error"
                              name="province"
                              component="small"
                            />
                          }
                          name="province"
                          options={provinces}
                          value={provinces.find(elem => elem.value === values.province) || ''}
                          onChange={opt => setFieldValue('province', opt?.value || '')}
                          onBlur={() => setFieldTouched('province', true)}
                          error={errors.province}
                          touched={touched.province}
                        />
                        <Input
                          className="form-input add-and-modify-client-modal__location"
                          label="Localidad"
                          name="locality"
                        />
                      </>
                    )}
                    <Input
                      className="form-input add-and-modify-client-modal__email"
                      label="Email"
                      name="email"
                      type="email"
                    />
                    <Input className="form-input" label="Teléfono" name="phone" type="number" />
                    <div className="add-and-modify-client-modal__note">
                      <label>
                        Observaciones <span>(aparecerán en la factura)</span>
                      </label>
                      <Input
                        type="textarea"
                        isFormikInput={false}
                        onChange={e => setFieldValue('note', e.target.value)}
                        value={values.note || ''}
                        maxLength={maxNoteLength}
                      />
                    </div>
                    <Select
                      className="add-and-modify-client-modal__payment-method"
                      label="Método de pago"
                      ErrorComponent={
                        <ErrorMessage
                          className="form-error"
                          name="paymentMethod"
                          component="small"
                        />
                      }
                      name="paymentMethod"
                      placeholder="Elige uno..."
                      options={clientPaymentMethods}
                      value={
                        clientPaymentMethods.find(elem => elem.value === values.paymentMethod) || ''
                      }
                      onChange={opt => {
                        const value = opt?.value || ''
                        setFieldValue('paymentMethod', value)
                        setPaymentMethod(value)
                      }}
                      onBlur={() => setFieldTouched('paymentMethod', true)}
                      error={errors.paymentMethod}
                      touched={touched.paymentMethod}
                    />
                    {chargeAccountNeeded && paymentMethod === 'RECIBO BANCARIO' && (
                      <Input
                        className="form-input add-and-modify-client-modal__iban"
                        label="IBAN"
                        name="iban"
                        onChange={e =>
                          form.current.setFieldValue('iban', formatIban(e.target.value))
                        }
                      />
                    )}
                  </Form>
                  {chargeAccountNeeded && (
                    <>
                      <Checkbox
                        label="Mostrar método de pago en factura"
                        checked={values.showPaymentMethod}
                        onChange={value => setFieldValue('showPaymentMethod', value)}
                      />
                      <LicensesTable licenses={licenses} setLicenses={setLicenses} />
                    </>
                  )}
                </div>
              )}
            </Formik>
          )}
        </>
      }
    />
  )
}
