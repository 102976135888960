import React, { useState, useEffect, useCallback } from 'react'

// MY IMPORTS
import './DealModal.scss'
import {
  postCancelInvoice as postCancelInvoiceService,
  getInvoicePDF as getInvoicePDFService,
  getInvoiceData as getInvoiceDataService,
} from 'services/Billing'
import PDFViewer from 'components/Others/PDFViewer/PDFViewer'
import openPDF from 'myMethods/openPDF'
import easyToast from 'components/Others/EasyToast/easyToast'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import InvoiceModifier from '../../InvoiceModifier/InvoiceModifier'
import Modal from 'components/Others/Modal/Modal'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import useService from 'hooks/useService'
import useMultipleServices from 'hooks/useMultipleServices'

export default function DealModal({
  invoiceId,
  closeModal,
  onlyRead = false,
  refetchDeals,
  refetchInvoices,
}) {
  const [data, setData] = useState({})
  const [PDF, setPDF] = useState(null)
  const inUse = Boolean(data?.inUse)
  const [showModifierModal, setShowModifierModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const { fetch: postCancelInvoice } = useService({
    service: postCancelInvoiceService,
    setOutLoading: setLoading,
    throwMessage: 'Ha ocurrido un error anulando la factura',
  })

  const { fetch: fetchInitialData, loading: initialLoading } = useMultipleServices({
    services: [getInvoicePDFService, getInvoiceDataService],
  })

  const cancel = async () => {
    try {
      postCancelInvoice({ invoiceId: data.idFactura })
      easyToast('dark', 'Factura anulada')
      closeModal()
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error anulando la factura')
    }
  }

  const fetchData = useCallback(
    async (invoiceIdParam, serieParam) => {
      try {
        setData({})
        setPDF(null)
        const [pdf, invoice] = await fetchInitialData([
          { invoiceId, serie: 'deal' },
          { invoiceId, serie: 'deal' },
        ])
        if (pdf?.data) setPDF(pdf.data)
        if (invoice?.data) setData(invoice.data)
      } catch (error) {
        easyToast('error', 'Ha ocurrido un error cargando la información del albarán')
      }
    },
    [fetchInitialData, invoiceId]
  )

  useEffect(() => {
    if (invoiceId) {
      fetchData()
    }
    //eslint-disable-next-line
  }, [invoiceId])

  return (
    <Modal
      open={true}
      onClose={closeModal}
      buttons={!loading}
      hasCloseButton={false}
      content={
        <>
          {loading && (
            <div className="invoice-modal">
              <ChildSpinner tight={false} />
            </div>
          )}
          {!loading && (
            <>
              <div className="deals-modal">
                {inUse && (
                  <div className="invoice-modal__banner">ESTE ALBARÁN YA HA SIDO FACTURADO</div>
                )}
                <section>
                  <div>
                    {data?.user?.username && data?.user?.fullname && (
                      <div className="deals-modal__user">
                        Generado por
                        <div>
                          {data.user.username} - {data.user.fullname}
                        </div>
                      </div>
                    )}
                    <MyButtonsContainer
                      margin="1rem auto"
                      className="invoice-modal__buttons-container"
                      fullWidth={false}
                    >
                      <MyButton text={'ABRIR ALBARÁN'} onClick={() => openPDF(PDF)} />
                      {!inUse && !onlyRead && data?.serie !== 'noPriceDeal' && (
                        <MyButton text={'MODIFICAR'} onClick={() => setShowModifierModal(true)} />
                      )}
                      {!inUse && !data.serie?.includes('cancelled') && !onlyRead && (
                        <MyButton text={'ANULAR'} onClick={cancel} />
                      )}
                    </MyButtonsContainer>
                  </div>
                  <PDFViewer
                    pdf={PDF}
                    errorMessage="Ha ocurrido un error cargando la factura. Recargue la página"
                    show={true}
                    loading={loading}
                  />
                </section>
              </div>

              {showModifierModal && (
                <InvoiceModifier
                  data={data}
                  closeModal={() => setShowModifierModal(false)}
                  refetch={() => {
                    refetchDeals()
                    refetchInvoices()
                  }}
                />
              )}
            </>
          )}
        </>
      }
    />
  )
}
