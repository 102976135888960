import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { resetReduxNewVehicle, setReduxFormState } from 'redux/ducks/newInspectionDuck'
import { useHistory } from 'react-router-dom'

// SERVICES
import { getInvoicePDF } from 'services/Billing'

// MY IMPORTS
import 'components/Office/InvoiceSample/InvoiceSample.scss'
import openPDF from 'myMethods/openPDF'
import PDFViewer from 'components/Others/PDFViewer/PDFViewer'
import useService from 'hooks/useService'

export default function InvoiceSample({ reduxNewVehicle }) {
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(true)

  const { serviceData: PDF, fetch: fetchInvoicePDF } = useService({
    service: getInvoicePDF,
  })

  useEffect(() => {
    // Esta función se encarga de realizar la petición para obtener el PDF.
    const fetchPDF = async () => {
      try {
        console.log('Llamo a fetchInvoicePDF')
        await fetchInvoicePDF({
          invoiceId: reduxNewVehicle.invoiceInfo.invoiceId,
          serie: reduxNewVehicle.invoiceInfo.serie,
        })
        console.log('Recibo fetchInvoicePDF')
      } catch (error) {
        console.error('Error fetching invoice PDF:', error)
      }
    }

    let interval
    if (!PDF) {
      setLoading(true)
      // Iniciar un intervalo para ejecutar fetchPDF cada segundo.
      interval = setInterval(() => {
        fetchPDF()
      }, 1000)
    } else {
      // Si PDF está disponible, asegurarse de no cargar y detener cualquier intervalo activo.
      setLoading(false)
      if (interval) {
        clearInterval(interval)
      }
    }

    // Función de limpieza que se ejecutará cuando el componente se desmonte o cuando las dependencias cambien.
    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [PDF, fetchInvoicePDF, reduxNewVehicle.invoiceInfo]) // Dependencias del useEffect

  return (
    <section className="invoice-sample">
      <h2>FACTURA GENERADA CORRECTAMENTE</h2>
      <div className="invoice-sample__btns">
        <i className="flaticon-printing" onClick={() => openPDF(PDF)}></i>
        <div
          onClick={() => {
            dispatch(resetReduxNewVehicle())
            dispatch(setReduxFormState(0))
            history.replace('/lobby')
          }}
        >
          SALIR
        </div>
      </div>
      <PDFViewer
        pdf={PDF}
        show={true}
        showPageChanger={false}
        loading={loading}
        errorMessage="Ha ocurrido un error cargando la factura. Inténtelo desde el menú de facturación"
      />
    </section>
  )
}
