//@ts-check
import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import './Gallery.scss'
import { Gallery as GridGallery } from 'react-grid-gallery'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/counter.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import { useDispatch } from 'react-redux'
import TrashIcon from '@mui/icons-material/Delete'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import easyToast from '../EasyToast/easyToast'
import isValidFunction from 'myMethods/isValidFunction'
import { Plus as AddIcon } from 'react-bootstrap-icons'
import CaptureImage from 'components/Inspection/CaptureImage/CaptureImage'
import MyButtonsContainer from '../Buttons/MyButtonsContainer'
import MyButton from '../Buttons/MyButton/MyButton'
import ImageLightbox from './ImageLighbox/ImageLightbox'

/**
 * @typedef {import('types/Inspection').InspectionImageType} InspectionImageType
 */

/**
 * Gallery component to display a gallery of images with various interactive features.
 *
 * @param {Object} props - The properties object.
 * @param {Array} props.images - The array of image objects to be displayed in the gallery.
 * @param {boolean} [props.download=true] - Flag to enable or disable image downloading.
 * @param {boolean} [props.counter=true] - Flag to enable or disable image counter display.
 * @param {boolean} [props.thumbnails=true] - Flag to enable or disable thumbnail previews.
 * @param {boolean} [props.zoom=true] - Flag to enable or disable image zoom functionality.
 * @param {boolean} [props.captions=true] - Flag to enable or disable image captions.
 * @param {boolean} [props.selection=true] - Flag to enable or disable image selection feature.
 * @param {boolean} [props.letDelete=true] - Flag to enable or disable image deletion functionality.
 * @param {boolean} [props.letCapture=true] - Flag to enable or disable image capture functionality.
 * @param {boolean} [props.hideOptions=false] - Flag to hide options bar.
 * @param {Function} [props.onDeleteImage] - Callback function to handle image deletion.
 * @param {(image: File, geolocation: import('types/Inspection').Geolocation, type: InspectionImageType) => void} [props.onCaptureImage] - Callback function to handle image capture.
 * @param {boolean} [props.typeSelectable=true] - Flag to enable or disable type selection for images.
 * @param {InspectionImageType=} [props.defaultSelectValue=undefined] - Flag to enable or disable type selection for images.
 * @param {string} [props.noImagesText=null] - Text to show when there are no images.
 * @param {string} [props.addButtonText="Añadir imagen"] - Text to show in add button.
 * @param {boolean} [props.centerImages=true] - Flag to enable or disable images on center.
 *
 *
 * @returns {JSX.Element} The Gallery component.
 */
export default function Gallery({
  images: imagesProp,
  download = true,
  counter = true,
  thumbnails = true,
  zoom = true,
  captions = true,
  selection = true,
  letDelete = true,
  letCapture = true,
  hideOptions = false,
  onDeleteImage,
  onCaptureImage,
  typeSelectable = true,
  defaultSelectValue,
  noImagesText = 'Aún no hay imágenes',
  addButtonText = 'Añadir imagen',
  centerImages = true,
}) {
  const [images, setImages] = useState(imagesProp ?? [])
  const dispatch = useDispatch()
  const captureImageRef = useRef()
  const lightboxRef = useRef()

  const selectedImages = useMemo(() => images.filter(image => image.isSelected), [images])

  const handleClick = useCallback(index => lightboxRef?.current?.setIndex(index), [])

  const deleteImage = useCallback(
    async index => {
      try {
        if (index == null || index < 0) throw new Error('No index provided')
        const imageToDelete = images[index]
        if (!imageToDelete) throw new Error('No image found')
        if (isValidFunction(onDeleteImage)) {
          await onDeleteImage(imageToDelete.filename)
        }
        setImages(images.filter((_, i) => i !== index))
      } catch (err) {
        console.error(err)
        easyToast('error', 'Ha ocurrido un error eliminando la imagen')
      }
    },
    [images, onDeleteImage]
  )

  const deleteSelectedImages = useCallback(async () => {
    try {
      if (selectedImages.length === 0) throw new Error('No images selected')
      await Promise.all(selectedImages.map(image => deleteImage(images.indexOf(image))))
    } catch (err) {
      console.error(err)
    }
  }, [selectedImages, deleteImage, images])

  const handleSelect = useCallback(
    index => {
      const nextImages = images.map((image, i) =>
        i === index ? { ...image, isSelected: !image.isSelected } : image
      )
      setImages(nextImages)
    },
    [images]
  )

  useEffect(() => {
    setImages(imagesProp)
  }, [imagesProp])

  return (
    <div className={`gallery ${centerImages ? 'gallery--center' : ''}`}>
      {!hideOptions && (
        <div className="gallery__options">
          <MyButtonsContainer margin="1rem auto">
            {letCapture && (
              <MyButton
                text={addButtonText}
                icon={<AddIcon />}
                onClick={() => {
                  captureImageRef?.current?.triggerCapture()
                }}
              />
            )}
            {selectedImages.length > 0 && (
              <MyButton
                text="Eliminar selección"
                icon={<TrashIcon />}
                warningColor={true}
                onClick={() => {
                  dispatch(
                    setReduxConfigModal({
                      title: `¿Eliminar ${selectedImages?.length} ${
                        selectedImages?.length > 1 ? 'imágenes' : 'imagen'
                      }?`,
                      onAccept: async () => await deleteSelectedImages(),
                    })
                  )
                }}
              />
            )}
          </MyButtonsContainer>
        </div>
      )}
      {!(images?.length > 0) && noImagesText !== null && (
        <div className="no-values">{noImagesText}</div>
      )}
      {images?.length > 0 && (
        <>
          <GridGallery
            images={images}
            rowHeight={250}
            onClick={handleClick}
            onSelect={selection ? handleSelect : undefined}
          />
          <ImageLightbox
            images={images}
            ref={lightboxRef}
            download={download}
            counter={counter}
            thumbnails={thumbnails}
            zoom={zoom}
            captions={captions}
            letDelete={letDelete}
            onDeleteImage={deleteImage}
          />
        </>
      )}
      <CaptureImage
        ref={captureImageRef}
        onCaptureImage={onCaptureImage}
        typeSelectable={typeSelectable}
        defaultSelectValue={defaultSelectValue}
      />
    </div>
  )
}
