import React, { useEffect, useState } from 'react'

// SERVICES
import { getLicenseHistory as getLicenseHistoryService } from 'services/History'

// MY IMPORTS
import './InspectionHistory.scss'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import InspectionHistoryModal from './InspectionHistoryModal/InspectionHistoryModal'
import moment from 'moment'
import useService from 'hooks/useService'
import easyToast from 'components/Others/EasyToast/easyToast'

export default function InspectionHistory({ license }) {
  const [modal, setModal] = useState({ show: false, data: null })

  const {
    serviceData: data,
    fetch: getLicenseHistory,
    loading,
  } = useService({
    service: getLicenseHistoryService,
  })

  useEffect(() => {
    if (!data) {
      getLicenseHistory(license).catch(() => {
        easyToast('error', 'Ha ocurrido un error cargando el historial de este vehículo')
      })
    }
  }, [data, getLicenseHistory, license])
  return (
    <>
      {loading && (
        <div className="inspection-history list-container">
          <ChildSpinner visible={true} />
        </div>
      )}
      {!loading && (!data || !data.length > 0) && (
        <div className="no-values">NO HAY HISTORIAL SOBRE ESTA MATRÍCULA</div>
      )}
      {!loading && data && data.length > 0 && (
        <div className="list-container">
          {data.map((elem, i) => {
            return (
              <div
                key={i}
                className="inspection-history__elem list-content"
                onClick={() => setModal({ show: true, data: elem })}
              >
                <div className="inspection-history__card">
                  <span>Nº informe</span>
                  <div>{elem.numero_informe}</div>
                </div>
                <div className="inspection-history__card">
                  <span>Fecha</span>
                  <div>{moment(elem.tiempos.supervision_end).format('DD-MM-YYYY')}</div>
                </div>
                <div className="inspection-history__card">
                  <span>Defectos</span>
                  <div>
                    {(elem.defectos_visuales?.length || 0) + (elem.defectos_maquinas?.length || 0)}
                  </div>
                </div>
                <div className="inspection-history__card">
                  <span>Resultado</span>
                  <div>{elem.resultado}</div>
                </div>
              </div>
            )
          })}
        </div>
      )}
      {modal?.show && (
        <InspectionHistoryModal
          data={modal?.data}
          closeModal={() => setModal({ show: false, data: null })}
        />
      )}
    </>
  )
}
