//@ts-check
import React from 'react'
import { Fab, Action } from 'react-tiny-fab'
import 'react-tiny-fab/dist/styles.css'
import { Add as MainIcon } from '@mui/icons-material'
import isValidFunction from 'myMethods/isValidFunction'
import './FloatingButton.scss'

/**
 * Custom Floating Action Button component.
 *
 * This component wraps the `react-tiny-fab` library to provide a customized Floating Action Button (FAB) with additional actions.
 *
 * @param {Object} props - The component props.
 * @param {string=} [props.className=""]
 * @param {React.ReactNode} [props.mainButtonIcon=<MainIcon />] - The icon for the main button.
 * @param {{text?: string, icon: React.ReactNode, onClick: function}[]} [props.actions=[]] - The array of action objects for the FAB.
 * @param {boolean=} [props.alwaysShowTitle=true] - Whether to always show the title of the action buttons.
 *
 * @returns {React.ReactElement}
 */
export default function FloatingButton({
  className = '',
  mainButtonIcon = <MainIcon />,
  actions = [],
  alwaysShowTitle = true,
}) {
  return (
    <div className={`floating-button ${className ? className : ''}`}>
      <Fab icon={mainButtonIcon} alwaysShowTitle={alwaysShowTitle}>
        {actions.map(({ text, icon, onClick }, index) => (
          <Action key={index} text={text} onClick={() => isValidFunction(onClick) && onClick()}>
            {icon}
          </Action>
        ))}
      </Fab>
    </div>
  )
}
