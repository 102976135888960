import React, { useRef } from 'react'
import './PrintWrapper.scss'
import { useReactToPrint } from 'react-to-print'
import MyButtonsContainer from '../Buttons/MyButtonsContainer'
import MyButton from '../Buttons/MyButton/MyButton'
import { Printer } from 'react-bootstrap-icons'

const PrintWrapper = ({ children }) => {
  const componentRef = useRef()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  })

  return (
    <div>
      <MyButtonsContainer margin="1rem auto">
        <MyButton text="Imprimir" onClick={handlePrint} icon={<Printer />} />
      </MyButtonsContainer>
      <div ref={componentRef} className="printable-content">
        {children}
      </div>
    </div>
  )
}

export default PrintWrapper
