import React, { useState, useEffect } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useHistory } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
import { Key as LoginIcon } from '@mui/icons-material'
import './Login.scss'
import Input from 'components/Others/Input/Input'
import useLogin from 'hooks/useLogin'
import Spinner from 'components/Others/Spinner/Spinner'
import easyToast from 'components/Others/EasyToast/easyToast'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { useDispatch } from 'react-redux'
import { setReduxConfigShowAnimatedLogo } from 'redux/ducks/configDuck'
import { Person as UserIcon, Key as PasswordIcon } from '@mui/icons-material'

const formSchema = Yup.object().shape({
  user: Yup.string().required('Requerido'),
  pass: Yup.string().required('Requerido'),
})

export default function Login() {
  const [user, setUser] = useState(null)
  const history = useHistory()
  const { login, isLogged, isLoginLoading, hasLoginError, hasServerError } = useLogin()
  const dispatch = useDispatch()

  if (window.sessionStorage.getItem('jwtExpired') === 'true') {
    easyToast('error', 'No ha iniciado sesión o su sesión ha caducado')
    window.sessionStorage.removeItem('jwtExpired')
  }

  useEffect(() => {
    if (isLogged && !isLoginLoading) {
      dispatch(setReduxConfigShowAnimatedLogo(true))
      history.replace('/lobby')
    }
  }, [isLogged, isLoginLoading, history, dispatch])

  const handleInitialValues = () => ({
    user: user || '',
    pass: '',
  })

  function handleSubmit(values) {
    login(values)
  }

  const props = useSpring({
    to: { opacity: hasLoginError || hasServerError ? 1 : 0 },
    from: { opacity: 0 },
    config: { duration: 1000 },
  })

  return (
    <>
      <Spinner visible={isLoginLoading} />
      <div className="login">
        <h2>INICIAR SESIÓN</h2>

        <Formik
          enableReinitialize
          initialValues={handleInitialValues()}
          validationSchema={formSchema}
          onSubmit={values => {
            setUser(user)
            handleSubmit(values)
          }}
        >
          {() => (
            <div className="login__form">
              <Form>
                <Input name="user" label="Usuario" autoComplete="on" icon={<UserIcon />} />
                <Input
                  type="password"
                  name="pass"
                  label="Contraseña"
                  autoComplete="on"
                  icon={<PasswordIcon />}
                />

                <animated.div style={props} className="login__span">
                  {hasLoginError && !hasServerError && <strong>Credenciales incorrectas</strong>}
                  {hasServerError && <strong>Error de servidor</strong>}
                </animated.div>
                <MyButtonsContainer>
                  <MyButton text={'Iniciar Sesión'} type="submit" icon={<LoginIcon />} />
                </MyButtonsContainer>
              </Form>
            </div>
          )}
        </Formik>
      </div>
    </>
  )
}
