//@ts-check
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import AnalizadorDeGasesConfig from './AnalizadorDeGasesConfig/AnalizadorDeGasesConfig'
import { setReduxInspectionConfig } from 'redux/ducks/inspectionDuck'

export default function AnalizadorDeGases({ sectionNumber, vehicleData, disabled = false }) {
  const [config, setConfig] = useState({
    show: false,
  })
  const reduxConfig = useSelector(store => store.inspection?.config)
  const reduxGasAnalyzerData = reduxConfig?.gasAnalyzerData
  const [values, setValues] = useState({
    accelerated: reduxGasAnalyzerData?.accelerated || false,
  })
  const isAvailable = sectionNumber === '2' && vehicleData?.fuel1 === 'GASOLINA'
  const dispatch = useDispatch()

  // INICIALIZA MOSTRANDO MODAL SI NO HAY VALORES
  useEffect(() => {
    if (isAvailable && !config.show) {
      setConfig({ ...config, show: true })
    }
  }, [isAvailable, config, reduxGasAnalyzerData])

  useEffect(() => {
    const newConfig = { ...(reduxConfig || {}), gasAnalyzerData: values }
    dispatch(setReduxInspectionConfig(newConfig))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values])

  return (
    <>
      {config.show && (
        <AnalizadorDeGasesConfig setValues={values => setValues(values)} values={values} />
      )}
    </>
  )
}
