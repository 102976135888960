//@ts-check

/**
 * @typedef {import('./Invoice').InvoiceGetAllResDTO} InvoiceGetAllResDTO
 * @typedef {import('./Invoice').InvoiceDataResDTO} InvoiceDataResDTO
 */

class InvoiceTypeResponse {
  /**
   *
   * @param {any} object
   * @returns { InvoiceDataResDTO}
   */
  static fromGetInvoice(object) {
    return object
  }

  /**
   *
   * @param {any} object
   * @returns {{invoices: InvoiceGetAllResDTO[], total: number, next: string}}
   */
  static fromGetAllInvoices(object) {
    if (typeof object.total !== 'number') throw new Error('total is not a number')
    return object
  }

  static fromGetAllDeals(object) {
    return object
  }
}

export default InvoiceTypeResponse
