import React from 'react'

// MY IMPORTS
import 'components/Inspection/Check/Cards/Cards.scss'
import getLastTestValue from 'myMethods/inspection/getLastTestValue'

const Card = ({ name, number, className }) => {
  return (
    <div className={className || 'cards__elem'}>
      <span>{number}</span>
      <div>{name}</div>
    </div>
  )
}

const valuesToShow = [
  { id: 'testsValues', name: 'Tests realizados', special: false },
  { id: 'visualDefects', name: 'Defectos visuales', special: false },
  {
    id: 'machineDefects',
    name: 'Defectos de máquinas',
    special: false,
  },
  {
    id: 'remarks',
    name: 'Observaciones generales',
    special: false,
  },
  {
    id: 'defectsRemarks',
    name: 'Observaciones de defectos',
    special: true,
  },
  {
    id: 'images',
    name: 'Imágenes de inspección',
    special: false,
  },
  {
    id: 'testsImages',
    name: 'Imágenes de tests manuales',
    special: true,
  },
  { id: 'inspectors', name: 'Inspectores', special: true },
]

export default function Cards({ reduxInspection, ...rest }) {
  const getSpecialLength = id => {
    if (id === 'defectsRemarks') {
      try {
        let length = 0
        reduxInspection.visualDefects?.forEach(elem => {
          if (elem.values.remark) {
            length++
          }
        })
        return length
      } catch (err) {
        console.error()
        return -1
      }
    } else if (id === 'testsImages') {
      try {
        let length = 0
        reduxInspection.testsImages?.forEach(elem => {
          if (elem.images && Object.keys(elem.images).length > 0)
            length += Object.keys(elem.images).length
        })
        return length
      } catch (err) {
        console.error(err)
        return -1
      }
    } else if (id === 'inspectors') {
      try {
        let rawInspectors = []

        reduxInspection.defectsDone?.forEach(elem => elem.user && rawInspectors.push(elem.user))
        reduxInspection.testsValues?.forEach(elem => {
          if (!elem.machine) {
            elem.user && rawInspectors.push(elem.user)
          } else {
            const lastTest = getLastTestValue(elem.testName, reduxInspection.testsValues)
            if (lastTest && lastTest.test) {
              lastTest.test.inspector && rawInspectors.push(lastTest.test.inspector)
            }
          }
        })
        // Elimina repetidos
        return [...new Set(rawInspectors)].length
      } catch (err) {
        console.error(err)
        return -1
      }
    }
  }

  return (
    <section className="cards" {...rest}>
      {valuesToShow.map((elem, i) => {
        const isLastAndUneven = () => {
          if (i + 1 === valuesToShow.length) {
            return (i + 1) % 2 !== 0
          }
          return false
        }
        if (elem.special) {
          return (
            getSpecialLength(elem.id) !== -1 && (
              <Card
                key={elem.id}
                name={elem.name}
                number={getSpecialLength(elem.id)}
                className={isLastAndUneven() ? 'cards__elem cards__elem--last' : null}
              />
            )
          )
        } else {
          return (
            <Card
              key={elem.id}
              name={elem.name}
              number={reduxInspection[elem.id]?.length || 0}
              className={isLastAndUneven() ? 'cards__elem cards__elem--last' : null}
            />
          )
        }
      })}
    </section>
  )
}
