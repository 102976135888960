//@ts-check
import { useCallback, useState, useEffect, useRef } from 'react'
import easyToast from 'components/Others/EasyToast/easyToast'
import isValidFunction from 'myMethods/isValidFunction'
import handleUnauthorizedResponse from 'myMethods/handleUnauthorizedResponse'

/**
 * Hook para realizar peticiones a servicios de manera concurrente, con soporte para cancelación y manejo de errores.
 *
 * @param {Array<{service: function, serviceParams?: any, handleResponseData?: function, throwMessage?: string}>} servicesConfig - Configuración de los servicios a invocar.
 *
 * @returns {{servicesData: Array<any>, loading: boolean, fetchAll: function, statuses: Array<number | undefined>, errorMessages: Array<string | undefined>}}
 */
function useConcurrentServices(servicesConfig) {
  const [state, setState] = useState({
    loading: false,
    servicesData: [],
    statuses: [],
    errorMessages: [],
  })
  const controllersRef = useRef([])

  useEffect(() => {
    // Inicializar un controlador de aborto para cada servicio
    controllersRef.current = servicesConfig.map(() => new AbortController())

    return () => {
      // Abortar todas las solicitudes al desmontar el componente
      controllersRef.current.forEach(controller => controller.abort())
    }
  }, [servicesConfig])

  /**
   * @returns {Promise<Array<{data: any, status: number | undefined}>>}
   */
  const fetchAll = useCallback(
    async params => {
      try {
        setState(prevState => ({ ...prevState, loading: true }))
        const promises = servicesConfig.map(({ service, serviceParams }, index) =>
          service({
            ...(params?.[index] ? params[index] : serviceParams),
            signal: controllersRef.current[index].signal,
          })
            .then(res => ({
              status: 'fulfilled',
              value: res,
            }))
            .catch(err => ({
              status: 'rejected',
              reason: err,
            }))
        )
        const results = await Promise.allSettled(promises)

        const newStates = results.map((result, index) => {
          if (result.status === 'fulfilled') {
            const { value } = result
            if (isValidFunction(servicesConfig[index].handleResponseData)) {
              servicesConfig[index].handleResponseData(value.data)
            }
            return {
              data: value,
              status: value.status,
              errorMessage: null,
            }
          } else {
            const { reason } = result
            if (reason.response?.status === 401) {
              handleUnauthorizedResponse()
            }
            if (servicesConfig[index].throwMessage) {
              easyToast('error', servicesConfig[index].throwMessage)
            }
            return {
              data: null,
              status: reason.response?.status,
              errorMessage: reason.response?.message,
            }
          }
        })

        setState({
          loading: false,
          servicesData: newStates.map(s => s.data),
          statuses: newStates.map(s => s.status),
          errorMessages: newStates.map(s => s.errorMessage),
        })

        return newStates.map(s => ({ data: s.data, status: s.status }))
      } catch (err) {
        console.error(err)
      }
    },
    [servicesConfig]
  )

  return {
    servicesData: state.servicesData,
    loading: state.loading,
    statuses: state.statuses,
    errorMessages: state.errorMessages,
    fetchAll,
  }
}

export default useConcurrentServices
