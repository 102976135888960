//@ts-check
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setReduxInspectionAllData,
  setReduxInspectionFilter,
  setReduxInspectionHardwareConfig,
  setReduxInspectionManualManager,
  setReduxInspectionVehicleType,
} from 'redux/ducks/inspectionDuck'
import 'components/MobileMenu/MobileMenu.scss'
import { getHistoryById as getHistoryByIdService } from 'services/History'
import easyToast from 'components/Others/EasyToast/easyToast'
import Id from 'Share/Id'
import { getHardwareConfigFile as getHardwareConfigFileService } from 'services/Files'
import Filters from 'Share/Filters'
import useService from 'hooks/useService'
import InspectionDataMapper from 'dataMappers/InspectionDataMapper'
import TestsValues from 'Share/TestsValues'
import InspectionDefectsManager from 'Share/Defect/InspectionDefectsManager'

export default function FillReduxInspection() {
  const redux = useSelector(store => store)
  const reduxInspection = redux?.inspection
  const dispatch = useDispatch()

  const storageId = window.sessionStorage.getItem('historyId')

  const { fetch: getHistoryById } = useService({
    service: getHistoryByIdService,
  })

  const { fetch: getHardwareConfigFile } = useService({
    service: getHardwareConfigFileService,
  })

  useEffect(() => {
    if (!reduxInspection.initialized) {
      getHistoryById({ id: Id.decrypt(storageId) }).then(response => {
        const reduxData = InspectionDataMapper.fromResponse(response)
        dispatch(setReduxInspectionAllData(reduxData))
      })
    }
  }, [dispatch, getHistoryById, reduxInspection.initialized, storageId])

  useEffect(() => {
    if (
      !reduxInspection?.filter &&
      reduxInspection?.vehicleData &&
      Object.keys(reduxInspection.vehicleData)?.length &&
      reduxInspection?.vehicleData.category &&
      reduxInspection.vehicleData.classification
    ) {
      const filterHandler = new Filters({
        filters: redux?.config?.filters,
        category: reduxInspection.vehicleData.category,
        classification: reduxInspection.vehicleData.classification,
      })
      const vehicleType = filterHandler.getVehicleType()

      dispatch(setReduxInspectionFilter(filterHandler.filter))
      dispatch(setReduxInspectionVehicleType(vehicleType))
    }
  }, [dispatch, reduxInspection, redux?.config?.filters, storageId])

  useEffect(() => {
    // Si no hay hardwareConfig en Redux
    if (!reduxInspection.hardwareConfig) {
      getHardwareConfigFile()
        .then(res => {
          if (res) {
            dispatch(setReduxInspectionHardwareConfig(res))
          }
        })
        .catch(() => {
          easyToast('error', 'Ha ocurrido un error cargando los datos de las máquinas')
        })
    }
  }, [dispatch, getHardwareConfigFile, reduxInspection.hardwareConfig])

  // Instancia manualManager
  useEffect(() => {
    try {
      if (
        reduxInspection?.vehicleData &&
        reduxInspection?.isFiltered != null &&
        redux?.config?.filters?.length &&
        redux?.config?.linkBetweenMachineAndManual &&
        reduxInspection?.properties &&
        reduxInspection.optionalDefects
      ) {
        const testsValuesHandler = new TestsValues(reduxInspection.testsValues)

        const manualManager = InspectionDefectsManager.inicializeSections({
          filters: redux?.config?.filters,
          categoryAndClasification: {
            category: reduxInspection?.vehicleData?.category,
            classification: reduxInspection?.vehicleData?.classification,
          },
          fuels: {
            fuel1: reduxInspection?.vehicleData?.fuel1,
            fuel2: reduxInspection?.vehicleData?.fuel2,
          },
          visualDefects: reduxInspection?.visualDefects,
          machineDefects: reduxInspection?.machineDefects,
          linkBetweenMachineAndManual: redux?.config?.linkBetweenMachineAndManual,
          defectsDone: reduxInspection?.defectsDone,
          optionalDefects: reduxInspection?.optionalDefects,
          isFiltered: reduxInspection?.isFiltered,
          testsValuesHandler: testsValuesHandler,
          inspectionProperties: reduxInspection?.properties,
          // @ts-ignore
          images: reduxInspection?.images?.filter(image => image.type === 'defect') ?? [],
        })
        console.log({ manualManager: manualManager.listAllDefectsShowOnlyShow() })
        if (manualManager) dispatch(setReduxInspectionManualManager(manualManager))
      }
    } catch (error) {
      console.error(error)
    }
  }, [
    redux?.config?.filters,
    reduxInspection?.vehicleData,
    reduxInspection?.visualDefects,
    dispatch,
    reduxInspection?.defectsDone,
    reduxInspection.optionalDefects,
    reduxInspection?.isFiltered,
    reduxInspection?.properties,
    reduxInspection?.machineDefects,
    reduxInspection.testsValues,
    redux?.config?.linkBetweenMachineAndManual,
    reduxInspection?.images,
  ])

  return <></>
}
