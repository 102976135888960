export default class ErrorForDefect extends Error {
  constructor({ mensage, internal }, code) {
    super(mensage)
    this.internal = internal
    this.code = code
  }

  static sectionIdNotExist(id) {
    return new ErrorForDefect(
      {
        mensage: `No existe la seccion del manual con id: ${id}`,
        internal: `No existe la seccion del manual con id: ${id}`,
      },
      422
    )
  }

  static invalidIdFormat(id) {
    return new ErrorForDefect(
      {
        mensage: `El sectionId "${id}" para una seccion del manual no tiene un formato correcto`,
        internal: `El sectionId "${id}" para una seccion del manual no tiene un formato correcto`,
      },
      422
    )
  }

  /**
   *
   * @param {{key: string, value: any}} param0
   * @returns
   */
  static valueFormatInvalid({ key, value }) {
    return new ErrorForDefect(
      {
        mensage: `El valor/formato de ${key}: ${value} no es valido`,
        internal: `El valor/formato de ${key}: ${value} no es valido`,
      },
      422
    )
  }

  static existOneVisualDefectOnSameFatherThatIsNotTheSameUser() {
    return new ErrorForDefect(
      {
        mensage: `Existe un defecto visual que no es del mismo inspector`,
        internal: `Existe un defecto visual que no es del mismo inspector`,
      },
      422
    )
  }

  /**
   *
   * @param {string} score
   * @param {Score[]} scoreList
   * @returns
   */
  static defectDontHaveThisScoreOnPossibleScores(score, scoreList) {
    return new ErrorForDefect(
      {
        mensage: `El defecto no tiene la puntuacion ${score} en la lista de puntuaciones posibles ${scoreList}`,
        internal: `El defecto no tiene la puntuacion ${score} en la lista de puntuaciones posibles ${scoreList}`,
      },
      422
    )
  }

  /**
   *
   * @param {string} id
   * @returns
   */
  static defectNotScoring(id) {
    return new ErrorForDefect(
      {
        mensage: `El defecto ${id} no es calificable`,
        internal: `El defecto ${id} no es calificable`,
      },
      422
    )
  }

  static invalidParentOrder(order) {
    return new ErrorForDefect(
      {
        mensage: `El numero ${order} de orden de la seccion no es valido `,
        internal: `El numero ${order} de orden de la seccion no es valido `,
      },
      422
    )
  }

  static invalidScore(calification) {
    return new ErrorForDefect(
      {
        mensage: `Score not valid:  ${calification}`,
        internal: `Score not valid:  ${calification}`,
      },
      422
    )
  }

  /**
   *
   * @param {string} id
   * @param {string} idToCompare
   */
  static defectIdNotMatch(id, idToCompare) {
    return new ErrorForDefect(
      {
        mensage: `Not match ids ${id}, ${idToCompare}`,
        internal: `Not match ids ${id}, ${idToCompare}`,
      },
      422
    )
  }

  /**
   *
   * @param {string} id
   * @param {string} idToCompare
   */
  static defectNotApply(id) {
    return new ErrorForDefect(
      {
        mensage: `Defect with ${id} not apply`,
        internal: `Defect with ${id} not apply`,
      },
      422
    )
  }

  static machineDefectAndVisualDefectCannotMatch() {
    return new ErrorForDefect(
      {
        mensage: `VisulaDefectAndMachineDefectCannotMatch`,
        internal: `VisulaDefectAndMachineDefectCannotMatch`,
      },
      422
    )
  }

  static visualDefectNotFound(id, name) {
    return new ErrorForDefect(
      {
        mensage: `Visual Defect with id ${id} and name ${name} not found`,
        internal: `Visual Defect with id ${id} and name ${name} not found`,
      },
      422
    )
  }

  static invaliceInicializedContructorDefectManager() {
    return new ErrorForDefect(
      {
        mensage: `Invalid Defect Inicialized Param on the contructor`,
        internal: `Invalid Defect Inicialized Param on the contructor`,
      },
      422
    )
  }

  static defectDoneWithOtherUser(user, other) {
    return new ErrorForDefect(
      {
        mensage: `Defect just view for other user ${user}`,
        internal: `Defect just view for other user ${user}`,
      },
      422
    )
  }

  static notHaveFather(id) {
    return new ErrorForDefect({
      mensage: `This id ${id}, Not have father`,
      internal: `This id ${id}, Not have father`,
    })
  }
  /**
   *
   * @param {string} id
   * @param {string} lastNodeIdToDescend
   * @returns
   */
  static notHaveNodeToDescend(id, lastNodeIdToDescend) {
    return new ErrorForDefect({
      mensage: `This id ${id} not have node to descend form. Last NodeId to Descend ${lastNodeIdToDescend}`,
      internal: `This id ${id} not have node to descend form. Last NodeId to Descend ${lastNodeIdToDescend}`,
    })
  }

  static defectNotDoneFalseHaveDefect(id, defect) {
    return (
      new ErrorForDefect({
        mensage: `Defect with id ${id} have a ${defect}`,
        internal: `Defect with id ${id} have a ${defect}`,
      }),
      422
    )
  }

  /**
   *
   * @param {string} id
   * @returns
   */
  static thisDefectJustExist(id) {
    return (
      new ErrorForDefect({
        mensage: `Defect with id ${id} just exist`,
        internal: `Defect with id ${id} just exist`,
      }),
      422
    )
  }
}
