import React, { useState } from 'react'
import './ChatbotIcon.scss'
import Logo from 'logo/mint-ai.svg'
import Modal from 'components/Others/Modal/Modal'
import Chatbot from '../Chatbot'

export default function ChatbotIcon() {
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <div className="chatbot-icon">
        <img src={Logo} alt="MintAI" onClick={() => setShowModal(true)} />
      </div>
      <Modal
        open={showModal}
        buttons={false}
        onClose={() => setShowModal(false)}
        content={<Chatbot closeModal={() => setShowModal(false)} />}
        noPadding={true}
      />
    </>
  )
}
