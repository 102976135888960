//@ts-check

/**
 * @description Compara dos objetos. El objeto principal será la referencia de keys a buscar en el objeto secundario.
 * @param {object} param0
 * @param {object} param0.obj1 Objecto principal
 * @param {object} param0.obj2 Objeto secundario
 * @param  {boolean=} param0.forceSameLength Indica si debe compararse la longitud de ambos objetos
 * @param {string[]=} param0.ignore Lista de keys a ignorar
 * @param {string[]=} param0.onlyKeys Lista de keys a comparar exclusivamente
 *
 * @returns {{equals:  boolean, diff: object[], error: boolean }} Objecto cuyas keys son "equals" {boolean} (si es igual) y "diff" {array} (diferencias encontradas)
 */
export default function compareObjects({
  obj1,
  obj2,
  forceSameLength = false,
  ignore = [],
  onlyKeys = [],
}) {
  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    console.error('Missing arguments or incorrect arguments type. Params: ', { obj1, obj2 })
    return { equals: false, diff: [], error: true }
  }

  try {
    let diff = []

    const compare = (obj1, obj2) => {
      Object.entries(obj1).forEach(([key, value]) => {
        if (
          typeof value === 'object' &&
          value !== null &&
          !Array.isArray(value) &&
          typeof obj2[key] === 'object' &&
          obj2[key] !== null &&
          !Array.isArray(obj2[key])
        ) {
          const equalsRes = compareObjects({
            obj1: value,
            obj2: obj2[key],
            forceSameLength,
            ignore,
            onlyKeys,
          })
          if (equalsRes.equals) return
          if (equalsRes.error) throw new Error('Error in nested comparison')
          if (equalsRes.diff.length) diff = [...diff, ...equalsRes.diff]
        } else {
          if (onlyKeys.length > 0 && !onlyKeys.includes(key)) return
          if (key.toLowerCase().includes('id')) return
          if (ignore.includes(key.toLowerCase())) return

          if (value !== obj2[key]) {
            diff.push({ key, value1: value, value2: obj2[key] })
          }
        }
      })
      return { equals: !diff.length, diff, error: false }
    }

    if (
      !forceSameLength ||
      (forceSameLength && Object.keys(obj1).length === Object.keys(obj2).length)
    ) {
      return compare(obj1, obj2)
    } else {
      return { equals: false, diff: [], error: false }
    }
  } catch (err) {
    console.error(err)
    return { equals: false, diff: [], error: true }
  }
}
