//@ts-check

export default class InspectionDataMapper {
  /**
   *
   * @param {import("types/InspectionResponse").InspectionGenericResponse} inspectionResponse
   * @param {import("types/Inspection").Inspection=} currentValues
   * @return {import("types/Inspection").Inspection}
   */
  static fromResponse(inspectionResponse, currentValues) {
    console.log({ inspectionResponse, currentValues })
    return {
      vehicleData: inspectionResponse.vehicleData,
      vehicleType: inspectionResponse.vehicleData?.vehicleType,
      reportId: inspectionResponse.idInforme,
      reportNumber: inspectionResponse.numero_informe,
      expedient: inspectionResponse.expedient,
      inspectionType: inspectionResponse.inspeccion,
      config: inspectionResponse.config,
      times: inspectionResponse.tiempos,
      axles: inspectionResponse.ejes,
      techWeights: inspectionResponse.masas,
      optionalDefects: inspectionResponse.defectos_opcionales,
      visualDefects: inspectionResponse.defectos_visuales,
      defectsDone: inspectionResponse.defectos_vistos,
      machineDefects: inspectionResponse.defectos_maquinas,
      speedometer: inspectionResponse.kilometros,
      images: inspectionResponse.imagenes,
      testsImages: inspectionResponse.imagenes_tests,
      hardwareStatus: inspectionResponse.estado_hardware,
      testsValues: inspectionResponse.valores_tests,
      remarks: inspectionResponse.observaciones,
      internalRemarks: inspectionResponse.observaciones_internas,
      complementaryEquipment: inspectionResponse.equipo_complementario,
      traceability: inspectionResponse.trazabilidad,
      line: inspectionResponse.linea,
      rev: inspectionResponse.rev,
      errors: inspectionResponse.errores,
      licenseImage: inspectionResponse.captura_matricula,
      grade: inspectionResponse.resultado,
      nextInspectionDate: inspectionResponse.nextInspectionDate,
      inspector: inspectionResponse.inspectorData,
      itvCard: inspectionResponse.vehicleCard,
      initialized: true,
      filter: currentValues?.filter ?? null,
      isFiltered: currentValues?.isFiltered ?? true,
      hardwareConfig: currentValues?.hardwareConfig ?? null,
      properties: inspectionResponse.properties,
      machinesApplying: inspectionResponse.machinesNeeded ?? null,
      calculations: inspectionResponse.calculations,
    }
  }
}
