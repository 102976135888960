const SERVER_URL = process.env.REACT_APP_MINTAI_CHATBOT_URL

export function postChatMessageStream(params, signal) {
  return fetch(`${SERVER_URL}/api/ask/stream`, {
    method: 'post',
    signal,
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      // ...getJWT().headers,
    },
    body: JSON.stringify(params),
  })
}
