import React, { isValidElement, useCallback, useState } from 'react'
import moment from 'moment'
import DealModal from 'components/Office/Billing/Deals/DealModal/DealModal'
import InvoiceModal from 'components/Office/Billing/Invoices/InvoiceModal/InvoiceModal'
import MatTable from 'components/Others/MuiTable/MuiTable'
import formatEuro from 'myMethods/formatEuro'

export default function ListCashRegisterMovementsTable({
  data,
  endOfDay = false,
  cashRegisterName,
  selectedDate,
}) {
  const [modal, setModal] = useState({ show: false, content: null })

  const columns = [
    {
      title: 'Cantidad',
      render: useCallback(data => {
        let amount = data?.breakdown?.total ?? data.amount
        return amount > 0 ? '+ ' + formatEuro(amount) : '- ' + formatEuro(Math.abs(amount))
      }, []),

      customFilterAndSearch: useCallback((term, rowData) => {
        try {
          let amount = 0
          if (rowData.desglose) {
            amount = rowData.desglose.total || 0
          } else {
            amount = rowData.amount || 0
          }
          term.replace(',', '.')
          if (amount.toString().includes(term)) return true
          return false
        } catch (err) {
          console.error(err)
          return false
        }
      }, []),
    },
    {
      title: 'Fecha',
      render: useCallback(data => {
        if (data.createdAt) return moment(data.createdAt)?.format('DD-MM-YYYY HH:mm')
      }, []),
    },
    {
      title: 'Concepto',
      render: useCallback(data => data.concepto ?? data.reason ?? data.report?.inspectionType, []),
      customFilterAndSearch: useCallback((term, rowData) => {
        try {
          term = term.toUpperCase()
          if (rowData.concepto) {
            if (rowData.concepto.toUpperCase().includes(term)) return true
          }
          if (rowData.report?.inspectionType) {
            if (rowData.report.inspectionType.toUpperCase().includes(term)) return true
          }
          return false
        } catch (err) {
          console.error(err)
          return false
        }
      }, []),
    },
    { title: 'Nº Factura', field: 'number' },
    {
      title: 'Método de pago',
      render: useCallback(data => data.paymentDetails?.tpv?.name ?? data.paymentMethod ?? '-', []),
      field: 'paymentMethod',
      customFilterAndSearch: (term, rowData) => {
        try {
          if (rowData.paymentMethod) {
            if (rowData.paymentMethod.toUpperCase().includes(term.toUpperCase())) return true
          }
          return false
        } catch (err) {
          console.error(err)
          return false
        }
      },
    },
  ]

  return (
    <>
      <MatTable
        columns={endOfDay ? [...columns, { title: 'Caja', field: 'cashRegisterName' }] : columns}
        values={data}
        title="Operaciones"
        exportation={{
          type: 'CASH_REGISTER_OPERATIONS',
          data: { values: data },
          modalTitle: 'Exportar operaciones de caja registradora',
          defaultInputValue: 'OPERACIONES DE CAJA REGISTRADORA',
          options: {
            cashRegisterName,
            date: selectedDate,
          },
        }}
        options={{
          rowStyle: useCallback(rowData => {
            return {
              color: rowData.serie ? '#000' : '#8884d8',
            }
          }, []),
        }}
        onRowClick={rowData => {
          if (!rowData.serie) return
          if (rowData.serie === 'deal')
            return setModal({
              show: true,
              content: (
                <DealModal
                  invoiceId={rowData.id}
                  serie={rowData.serie}
                  closeModal={() => setModal({ show: false, content: null })}
                />
              ),
            })
          return setModal({
            show: true,
            content: (
              <InvoiceModal
                invoiceId={rowData?.id}
                serie={rowData?.serie}
                closeModal={() => setModal({ show: false, content: null })}
              />
            ),
          })
        }}
      />
      {modal.show && modal.content && isValidElement(modal.content) && modal.content}
    </>
  )
}
