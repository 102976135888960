import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

// MY IMPORTS
import './MobileBackBtn.scss'

export default function MobileBackBtn() {
  const history = useHistory()
  const params = useParams()

  const handleClick = () => {
    if (history.location.pathname.includes('defects')) {
      const splittedId = params.manualChapter?.split('.')
      if (splittedId.length > 1) {
        let newId = ''
        splittedId.forEach((elem, i) => {
          if (i === 0) return (newId = elem)
          if (i + 1 < splittedId.length) return (newId += `.${elem}`)
          return
        })
        history.replace(`/new_inspection/${params.license}/defects/${newId}`)
      } else history.replace(`/new_inspection/${params.license}/defects`)
    }
    if (history.location.pathname.includes('hardware')) {
      if (params.test) {
        history.replace(`/new_inspection/${params.license}/hardware`)
      } else {
        history.replace(`/new_inspection/${params.license}/defects`)
      }
    }
    if (history.location.pathname.includes('complementary_equipment')) {
      history.replace(`/new_inspection/${params.license}/defects`)
    }
  }

  return (
    <div className="mobile-back-btn" onClick={handleClick}>
      <i className="flaticon-upload" />
    </div>
  )
}
