//@ts-check
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  setReduxInspectionFilter,
  setReduxInspectionHardwareConfig,
  setReduxInspectionVehicleType,
  setReduxInspectionManualManager,
  setReduxInspectionPreviousInspection,
  setReduxInspectionAllData,
} from 'redux/ducks/inspectionDuck'
import 'components/MobileMenu/MobileMenu.scss'
import { getInspectionById as getInspectionByIdService } from 'services/Inspection'
import easyToast from 'components/Others/EasyToast/easyToast'
import SelectAxles from 'components/Inspection/SelectAxles/SelectAxles'
import Id from 'Share/Id'
import { getHardwareConfigFile as getHardwareConfigFileService } from 'services/Files'
import Filters from 'Share/Filters'
import InspectionDefectsManager from 'Share/Defect/InspectionDefectsManager'
import TestsValues from 'Share/TestsValues'
import { getLicenseHistory as getLicenseHistoryService } from 'services/History'
import moment from '../../../node_modules/moment/moment'
import InspectionDataMapper from 'dataMappers/InspectionDataMapper'
import useService from 'hooks/useService'

export default function FillReduxInspection() {
  const [showAxlesModal, setShowAxlesModal] = useState(false)
  const redux = useSelector(store => store)
  const dispatch = useDispatch()
  const history = useHistory()
  const storageId = window.sessionStorage.getItem('id')
  /**@type {import('types/Inspection').Inspection} */
  const reduxInspection = redux.inspection

  const { fetch: getInspectionById } = useService({
    service: getInspectionByIdService,
  })

  const { fetch: getHardwareConfigFile } = useService({
    service: getHardwareConfigFileService,
  })
  const { fetch: getLicenseHistory } = useService({
    service: getLicenseHistoryService,
  })

  useEffect(() => {
    if (!reduxInspection.initialized) {
      getInspectionById({ id: Id.decrypt(storageId) }).then(response => {
        const reduxData = InspectionDataMapper.fromResponse(response)
        dispatch(setReduxInspectionAllData(reduxData))
      })
    }
  }, [dispatch, getInspectionById, reduxInspection.initialized, storageId])

  useEffect(() => {
    if (
      !reduxInspection?.filter &&
      reduxInspection?.vehicleData &&
      Object.keys(reduxInspection.vehicleData)?.length &&
      reduxInspection?.vehicleData.category &&
      reduxInspection.vehicleData.classification
    ) {
      const filterHandler = new Filters({
        filters: redux?.config?.filters,
        category: reduxInspection.vehicleData.category,
        classification: reduxInspection.vehicleData.classification,
      })
      const vehicleType = filterHandler.getVehicleType()

      dispatch(setReduxInspectionFilter(filterHandler.filter))
      dispatch(setReduxInspectionVehicleType(vehicleType))
    }
  }, [dispatch, reduxInspection, redux?.config?.filters, storageId])

  useEffect(() => {
    // Si no hay hardwareConfig en Redux
    if (!reduxInspection.hardwareConfig) {
      getHardwareConfigFile()
        .then(res => {
          if (res) {
            dispatch(setReduxInspectionHardwareConfig(res))
          }
        })
        .catch(() => {
          easyToast('error', 'Ha ocurrido un error cargando los datos de las máquinas')
        })
    }
  }, [dispatch, getHardwareConfigFile, reduxInspection.hardwareConfig])

  useEffect(() => {
    // Si no hay axles en redux
    if (
      storageId &&
      !showAxlesModal &&
      !reduxInspection?.axles?.tractor &&
      reduxInspection?.initialized &&
      !history.location.pathname.match('detailed-history', 'i') &&
      !history.location.pathname.match('new_supervision', 'i')
    ) {
      const timeout = setTimeout(() => {
        setShowAxlesModal(true)
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [
    history.location.pathname,
    reduxInspection.axles?.tractor,
    reduxInspection.initialized,
    reduxInspection?.properties?.hasInspection,
    showAxlesModal,
    storageId,
  ])

  // Instancia manualManager
  useEffect(() => {
    try {
      if (
        reduxInspection?.vehicleData &&
        reduxInspection?.isFiltered != null &&
        redux?.config?.filters?.length &&
        redux?.config?.linkBetweenMachineAndManual &&
        reduxInspection?.properties &&
        reduxInspection.optionalDefects
      ) {
        const testsValuesHandler = new TestsValues(reduxInspection.testsValues)

        const manualManager = InspectionDefectsManager.inicializeSections({
          filters: redux?.config?.filters,
          categoryAndClasification: {
            category: reduxInspection?.vehicleData?.category,
            classification: reduxInspection?.vehicleData?.classification,
          },
          fuels: {
            fuel1: reduxInspection?.vehicleData?.fuel1,
            fuel2: reduxInspection?.vehicleData?.fuel2,
          },
          visualDefects: reduxInspection?.visualDefects,
          machineDefects: reduxInspection?.machineDefects,
          linkBetweenMachineAndManual: redux?.config?.linkBetweenMachineAndManual,
          defectsDone: reduxInspection?.defectsDone,
          optionalDefects: reduxInspection?.optionalDefects,
          isFiltered: reduxInspection?.isFiltered,
          testsValuesHandler: testsValuesHandler,
          inspectionProperties: reduxInspection?.properties,
          manualDefects: reduxInspection?.properties?.manualSection?.defects,
          // @ts-ignore
          images: reduxInspection?.images?.filter(image => image.type === 'defect') ?? [],
        })
        if (manualManager) dispatch(setReduxInspectionManualManager(manualManager))
      }
    } catch (error) {
      console.error(error)
    }
  }, [
    redux?.config?.filters,
    reduxInspection?.vehicleData,
    reduxInspection?.visualDefects,
    dispatch,
    reduxInspection?.defectsDone,
    reduxInspection?.optionalDefects,
    reduxInspection?.isFiltered,
    reduxInspection?.properties,
    reduxInspection?.machineDefects,
    reduxInspection?.testsValues,
    redux?.config?.linkBetweenMachineAndManual,
    reduxInspection?.images,
  ])

  useEffect(() => {
    // Si no hay previousInspection en redux
    if (
      storageId &&
      !reduxInspection.previousInspection &&
      reduxInspection.inspectionType != null &&
      reduxInspection.inspectionType.includes('SEGUNDA INSPECCIÓN O SUCESIVAS')
    ) {
      getLicenseHistory(reduxInspection.vehicleData?.license)
        .then(res => {
          if (res && res.length) {
            res.sort(
              (a, b) =>
                moment(b.tiempos.supervision_end).valueOf() -
                moment(a.tiempos.supervision_end).valueOf()
            )
            const formattedRes = InspectionDataMapper.fromResponse(res[0])
            dispatch(setReduxInspectionPreviousInspection(formattedRes))
          }
        })
        .catch(err => {
          console.error(err)
          easyToast('error', '  Ha ocurrido un error cargando la anterior inspección del vehículo')
        })
    }
  }, [
    dispatch,
    getLicenseHistory,
    reduxInspection.inspectionType,
    reduxInspection.previousInspection,
    reduxInspection.vehicleData?.license,
    storageId,
  ])

  return (
    <>
      <SelectAxles show={showAxlesModal} onClose={setShowAxlesModal} />
    </>
  )
}
