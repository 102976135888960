//@ts-check
import moment from 'moment'

const FORMAT_TIME = 'YYYY-MM-DD HH:mm:ss'

export default class DateTimeITV {
  /**
   * @class DateTimeITV
   * @classdesc Maneja y formatea fechas y horas.
   *
   * @param {string | moment.Moment} [date=moment()] - La fecha a utilizar. Puede ser una cadena en formato 'YYYY-MM-DD HH:mm:ss' o un objeto moment.
   *
   * @throws {Error} Si el formato de la fecha no es válido.
   */
  constructor(date) {
    if (typeof date === 'string') date = moment(date)
    this.date = date
  }

  /**
   *
   * @param {string} fecha
   */
  static isValid(fecha) {
    return moment(fecha, FORMAT_TIME).isValid()
  }

  /**
   *
   * @param {string} fecha
   * @returns
   */
  static validate(fecha) {
    if (DateTimeITV.isValid(fecha)) return fecha
    throw new Error(`FORMATO FECHA ${fecha} NO VALIDA SE ESPERA EL FORMATO YYYY-MM-DD HH:mm:ss`)
  }

  toUTC() {
    return this.date.toISOString()
  }

  /**
   *
   * @param {string} fecha
   * @returns
   */
  static isDate(fecha, format = 'YYYY-MM-DD') {
    if (moment(fecha, format).isValid()) return fecha
    throw new Error(`FORMATO FECHA ${fecha} NO VALIDA SE ESPERA EL FORMATO ${format}`)
  }
  /**
   *
   * @param {string} fecha
   * @returns {DateTimeITV}
   */
  static fromString(fecha) {
    if (!DateTimeITV.isValid(fecha)) {
      throw new Error(`FORMATO FECHA ${fecha} NO VALIDA SE ESPERA EL FORMATO ${FORMAT_TIME}`)
    }
    return new DateTimeITV(moment(fecha, FORMAT_TIME))
  }

  /**
   *
   * @param {number} timeStamp
   * @returns
   */
  static fromUnixMillisecond(timeStamp) {
    if (typeof timeStamp !== 'number') {
      throw new Error('Formato de fecha invalido')
    }
    return new DateTimeITV(moment(timeStamp))
  }

  /**
   *
   * @returns {DateTimeITV}
   */
  static now() {
    return new DateTimeITV(moment())
  }

  /**
   *
   * @returns {string} Retorna el dia dado en el formato 'YYYY-MM-DD 00:00:00'
   */
  toStringDateTimeBegin() {
    const day = this.date.format('YYYY-MM-DD')
    return day + ' 00:00:00'
  }

  /**
   *
   * @returns {string} Retorna el dia dado en el formato 'YYYY-MM-DD 23:59:59'
   */
  toStringDateTimeEnd() {
    const day = this.date.format('YYYY-MM-DD')
    return day + ' 23:59:59'
  }

  /**
   *
   * @returns {string} Retorna el dia dado en el formato 'YYYY-MM-DD 23:58:00'
   */
  toStringDateTimeTo235800() {
    const day = this.date.format('YYYY-MM-DD')
    return day + ' 23:58:00'
  }
  /**
   *
   * @returns {string}
   */
  toString() {
    return this.date.format(FORMAT_TIME)
  }

  /**
   *
   * @returns {string}
   */
  toDDMMYYYY() {
    return this.date.format('DD-MM-YYYY')
  }

  /**
   *
   * @returns {string} Day on format "YYY-MM-DD"
   */
  toStringDate() {
    return this.date.format('YYYY-MM-DD')
  }
  /**
   *
   * @returns {number} - Timestamp in Milliseconds
   */
  valueOf() {
    return this.date.valueOf()
  }

  /**
   *
   * @param {number} milliseconds
   * @returns
   */
  static fromTimestamp(milliseconds) {
    return new DateTimeITV(moment(milliseconds))
  }

  /**
   *
   * @param {string} fecha
   * @returns {boolean}
   */
  isBeforeThan(fecha) {
    return this.date.isBefore(DateTimeITV.fromString(fecha).date)
  }

  isSameOrBefore(stringDate) {
    return this.date.isSameOrBefore(DateTimeITV.fromString(stringDate).date)
  }
  /**
   *
   * @param {string} fecha
   * @returns {boolean}
   */
  isAfterThan(fecha) {
    return this.date.isAfter(DateTimeITV.fromString(fecha).date)
  }

  /**
   *
   * @param {string} fecha
   * @returns {boolean}
   */
  isSameOrAfterThan(fecha) {
    return this.date.isSameOrAfter(DateTimeITV.fromString(fecha).date)
  }
  /**
   *
   * @param {string} start
   * @param {string} end
   * @param {"()"|"[]"|"[)"|"(]"} inclusivity [] inclusion, () exclusion
   */
  isBetween(start, end, inclusivity = '()') {
    const startDateTime = DateTimeITV.fromString(start)
    const endDateTime = DateTimeITV.fromString(end)
    if (['()', '[]', '[)', '(]'].includes(inclusivity) == false)
      throw { message: `Error ${inclusivity} no es valido solo se aceptan '()', '[]', '[)', '(]'` }
    return this.date.isBetween(startDateTime.date, endDateTime.date, undefined, inclusivity)
  }

  /**
   *
   * @param {string} string
   * @returns
   */
  isInThisDay(string) {
    return this.date.isSame(DateTimeITV.fromString(string).date, 'day')
  }

  addMonths(month) {
    this.date.add(month, 'months')
    return this
  }

  /**
   *
   * @param {number} seconds
   * @returns
   */
  addSeconds(seconds) {
    this.date.add(seconds, 'seconds')
    return this
  }
  addHours(hours) {
    this.date.add(hours, 'h')
    return this
  }
  /**
   *
   * @param {number} days
   * @returns
   */
  addDays(days) {
    this.date.add(days, 'days')
    return this
  }

  /**
   *
   * @param {number} minuts
   * @returns
   */
  addMinutes(minuts) {
    this.date.add(minuts, 'minutes')
    return this
  }

  subtractMonths(months) {
    this.date.subtract(months, 'months')
    return this
  }

  /**
   *
   * @param {number} seconds
   * @returns
   */
  subtractSeconds(seconds) {
    this.date.subtract(seconds, 's')
    return this
  }

  sustractHours(hours) {
    this.date.subtract(hours, 'h')
    return this
  }

  sustractMinutes(minuts) {
    this.date.subtract(minuts, 'm')
    return this
  }

  /**
   *
   * @param {number} days
   * @returns
   */
  subtractDays(days) {
    this.date.subtract(days, 'days')
    return this
  }

  /**
   *
   * @param {number} years
   * @returns
   */
  subtractYears(years) {
    this.date.subtract(years, 'years')
    return this
  }

  setTimeNow() {
    this.date.set('h', moment().get('h'))
    this.date.set('m', moment().get('m'))
    this.date.set('second', moment().get('second'))
    return this
  }

  /**
   *
   * @param {String} stringDate
   */
  static getYear(stringDate) {
    const date = new Date(stringDate)
    return date.getFullYear()
  }

  /**
   *
   * @param {String} start
   * @param {String} end
   * @returns
   */
  static diffTwoDatesOnDays(start, end) {
    const date1 = new Date(start)
    const date2 = new Date(end)

    if (date1 instanceof Date == false) {
      throw new Error('Start date is a Invalid Date')
    }

    if (date2 instanceof Date == false) {
      throw new Error('End date is a Invalid Date')
    }

    // @ts-ignore
    const diff = date2 - date1
    const diffTime = Math.abs(diff)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
    if (isNaN(diffDays)) throw new Error('Invalid Date')
    return diffDays
  }

  /**
   * @description Devuelve la diferencia en dias entre dos fechas
   * @param {DateTimeITV} otherDate
   * @returns
   */
  diffDays(otherDate) {
    if (!(otherDate instanceof DateTimeITV)) {
      throw new Error('the other date not a DateTimeITV')
    }
    return this.date.diff(otherDate.date, 'days')
  }

  /**
   *  Calculate the number of milliseconds that "a" is greater than "b".
   *  If "a" is greater, the result will be > 0.
   *  If "a" is less than "b", the result will be < 0.
   *  If they are equal, the output will be 0.
   *
   * @param {string} a - Fecha ISO
   * @param {string} b - Fecha ISO
   * @returns {number} number of miliseconds that 'a' is greater than 'b'
   */
  static compare(a, b) {
    const aUnixtime = DateTimeITV.fromString(a).valueOf()
    const bUnixTime = DateTimeITV.fromString(b).valueOf()
    return aUnixtime - bUnixTime
  }

  /**
   *
   * @param {DateTimeITV} otherDate
   */
  duration(otherDate) {
    if (!(otherDate instanceof DateTimeITV)) {
      throw new Error('the other date not a DateTimeITV')
    }
    return moment.duration(this.date.diff(otherDate.date)).locale('es').humanize()
  }

  /**
   * Si la fecha pasada es posterior el valor sera negativo
   * @param {DateTimeITV} otherDate
   */
  diffMinutes(otherDate) {
    if (!(otherDate instanceof DateTimeITV)) {
      throw new Error('the other date not a DateTimeITV')
    }
    return this.date.diff(otherDate.date, 'minutes', true)
  }

  /**
   *
   * @param {string} start Fecha mas antigual
   * @param {string} end Fecha mas reciente
   */
  static duration(start, end) {
    const dateA = DateTimeITV.fromString(start)
    const dateB = DateTimeITV.fromString(end)
    return dateB.duration(dateA)
  }

  /**
   *
   * @param {string} start Fecha mas antigual
   * @param {string} end Fecha mas reciente
   */
  static diffMinutes(start, end) {
    const dateA = DateTimeITV.fromString(start)
    const dateB = DateTimeITV.fromString(end)
    return dateB.diffMinutes(dateA)
  }

  static getElapsedTime(start, end) {
    try {
      if (!start || !DateTimeITV.isValid(start)) throw new Error('Start time is invalid')
      else if (end && !DateTimeITV.isValid(end)) throw new Error('End time is invalid')

      const startTime = moment(start)
      const endTime = end ? moment(end) : moment()
      const duration = moment.duration(endTime.diff(startTime))
      return {
        days: duration._data.days,
        hours: duration._data.hours,
        minutes: duration._data.minutes,
        seconds: duration._data.seconds,
      }
    } catch (error) {
      console.error(error)
      return null
    }
  }

  static getFormatedElapsedTime(start, end) {
    const duration = this.getElapsedTime(start, end)
    let finalString = ''
    if (duration?.days) finalString += `${duration.days}d `
    if (duration?.hours) finalString += `${duration.hours}h `
    if (duration?.minutes) finalString += `${duration.minutes}min `
    if (duration?.seconds) finalString += `${duration.seconds}s`

    return finalString
  }

  /**
   * Formatea la fecha a un texto legible.
   * Si es de hoy, devuelve solo la hora (sin segundos).
   * Si es de otro día del mismo año, devuelve el día, mes y hora (sin segundos).
   * Si es de otro año, devuelve el día, mes, año y hora (sin segundos).
   * @returns {string}
   */
  static formatDate(date) {
    if (!DateTimeITV.isValid(date)) return ''
    date = moment(date)
    const now = moment()
    const isToday = date.isSame(now, 'day')
    const isSameYear = date.isSame(now, 'year')

    if (isToday) {
      return date.format('HH:mm')
    } else if (isSameYear) {
      return date.format('D MMM HH:mm').toUpperCase() // ejemplo: 14 JUN 15:30
    } else {
      return date.format('D MMM YYYY HH:mm').toUpperCase() // ejemplo: 14 JUN 2023 15:30
    }
  }

  static formatDateToStringAgo(date) {
    try {
      if (!DateTimeITV.isValid(date)) return ''
      const startTime = moment(date)
      const duration = moment.duration(moment().diff(startTime))
      if (
        !duration._data.days &&
        !duration._data.hours &&
        !duration._data.minutes &&
        duration._data.milliseconds
      )
        return 'Justo ahora'
      let finalDate = 'Hace '

      if (duration._data.days) finalDate += `${duration._data.days}d `
      if (duration._data.hours) finalDate += `${duration._data.hours}h `
      if (duration._data.minutes) finalDate += `${duration._data.minutes}min`

      return finalDate
    } catch (err) {
      console.error(err)
      return ''
    }
  }
}
