import * as Yup from 'yup'

export function NewInspection() {
  return Yup.object().shape({
    noValidation: Yup.boolean(),
    license: Yup.string()
      .required('Requerido')
      .matches(/^\w+$/, '   ❌ MATRÍCULA INVÁLIDA')
      .max(15, '   ❌ MATRÍCULA MUY LARGA'),
    frame: Yup.string().required('Requerido'),
    inspectionType: Yup.string().required('Requerido'),
  })
}
