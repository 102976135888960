import * as Yup from 'yup'

export function Client() {
  return Yup.object().shape({
    dni: Yup.string()
      .required('Requerido')
      .matches(/^[A-Z]?\d{5,8}[A-Z]?$/, '   ❌ NO VÁLIDO'),

    name: Yup.string()
      .required('Requerido')
      .transform(value => value.replace(/[/ ,:-]+/g, ''))
      .max(100, '   ❌ MUY LARGO'),
    address: Yup.string().required('Requerido').max(100, '   ❌ MUY LARGO'),
    zipCode: Yup.number()
      .required('Requerido')
      .min(5, '   ❌ MUY CORTO')
      .max(5, '   ❌ MUY LARGO')
      .lessThan(52999, '   ❌ VALOR INCORRECTO')
      .moreThan(1000, '   ❌ VALOR INCORRECTO'),
    province: Yup.string().required('Requerido'),
    locality: Yup.string().required('Requerido'),
    email: Yup.string().email('   ❌ EMAIL INVÁLIDO').nullable(),
    phone: Yup.string().min(9, '   ❌ MUY CORTO').nullable(),
    paymentMethod: Yup.string().required('Requerido'),
    iban: Yup.string()
      .nullable()
      .when('metodo_pago', {
        is: paymentMethod => ['RECIBO BANCARIO', 'CRÉDITO'].includes(paymentMethod),
        then: Yup.string()
          .required('Requerido')
          .matches(
            /^(ES[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}\s?|GB[a-zA-Z0-9]{2}\s?([a-zA-Z]{4}\s?){1}([0-9]{4}\s?){3}([0-9]{2})\s?)|DE[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){4}([0-9]{2})\s?|IE[a-zA-Z0-9]{2}\s?([a-zA-Z0-9]{4}\s?){1}([0-9]{4}\s?){3}([0-9]{2})\s?|FR[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){2}([0-9]{2})([a-zA-Z0-9]{2}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})([0-9]{2})\s?|PT[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){5}([0-9]{1})\s?|AD[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){2}([a-zA-Z0-9]{4}\s?){3}\s?|CH[a-zA-Z0-9]{2}\s?([0-9]{4}\s?){1}([0-9]{1})([a-zA-Z0-9]{3}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{1})\s?|IT[a-zA-Z0-9]{2}\s?([a-zA-Z]{1})([0-9]{3}\s?)([0-9]{4}\s?){1}([0-9]{3})([a-zA-Z0-9]{1}\s?)([a-zA-Z0-9]{4}\s?){2}([a-zA-Z0-9]{3})\s?/i,
            '   ❌ NO VÁLIDO'
          ),
      }),
  })
}

export function ClientNewInspection() {
  return Yup.object().shape({
    dni: Yup.string()
      .required('Requerido')
      .matches(/^[A-Z]?\d{5,8}[A-Z]?$/, '   ❌ NO VÁLIDO')
      .transform(value => value.toUpperCase()),

    name: Yup.string()
      .required('Requerido')
      .transform(value => value.replace(/[/ ,:-]+/g, ''))
      .max(100, '   ❌ MUY LARGO'),
    address: Yup.string().required('Requerido').max(100, '   ❌ MUY LARGO'),
    zipCode: Yup.number()
      .required('Requerido')
      .min(5, '   ❌ MUY CORTO')
      .max(5, '   ❌ MUY LARGO')
      .lessThan(52999, '   ❌ VALOR INCORRECTO')
      .moreThan(1000, '   ❌ VALOR INCORRECTO'),
    province: Yup.string().required('Requerido'),
    locality: Yup.string().required('Requerido'),

    email: Yup.string().email('   ❌ EMAIL INVÁLIDO').nullable(),
    telefono: Yup.string().min(9, '   ❌ MUY CORTO').nullable(),
  })
}
