//@ts-check
import React, { useState } from 'react'
import CardTextAndIcon from 'components/Others/Cards/CardTextAndIcon/CardTextAndIcon'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import Modal from 'components/Others/Modal/Modal'
import TireEquivalence from './TireEquivalence/TireEquivalence'
import LightHitch from './LightHitch/LightHitch'
import isValidFunction from 'myMethods/isValidFunction'
import HeavyHitch from './HeavyHitch/HeavyHitch'
import FifthWheelHitch from './FifthWheelHitch/FifthWheelHitch'
import FiscalHorsepower from './FiscalHorsepower/FiscalHorsepower'

export default function Calculations({ closeModal }) {
  const [modalsShow, setModalsShow] = useState({
    tireEquivalence: false,
    flaps: false,
    fiscalHorsepower: false,
    lightHitch: false,
    HeavyHitch: false,
    fifthWheelHitch: false,
  })

  const closeCalculationsModal = key => {
    setModalsShow(prevState => ({ ...prevState, [key]: false }))
    isValidFunction(closeModal) && closeModal()
  }
  return (
    <>
      <Modal
        open={true}
        title="Cálculos"
        icon={<i className="flaticon-calculator" />}
        onClose={closeModal}
        hasAcceptButton={false}
        content={
          <div className="calculations">
            <ListItemsInTwoColumns>
              <CardTextAndIcon
                text="Equivalencias de neumáticos"
                onClick={() => {
                  setModalsShow(prevState => ({ ...prevState, tireEquivalence: true }))
                }}
              />
              <CardTextAndIcon
                text="Faldillas intermedias"
                onClick={() => {
                  setModalsShow(prevState => ({ ...prevState, fiscalHorsepower: true }))
                }}
              />
              <CardTextAndIcon
                text="Potencia fiscal"
                onClick={() => {
                  setModalsShow(prevState => ({ ...prevState, fiscalHorsepower: true }))
                }}
              />

              <CardTextAndIcon
                text="Enganches de ligeros"
                onClick={() => {
                  setModalsShow(prevState => ({ ...prevState, lightHitch: true }))
                }}
              />
              <CardTextAndIcon
                text="Enganches de pesados"
                onClick={() => {
                  setModalsShow(prevState => ({ ...prevState, heavyHitch: true }))
                }}
              />
              <CardTextAndIcon
                text="Quinta rueda"
                onClick={() => {
                  setModalsShow(prevState => ({ ...prevState, fifthWheelHitch: true }))
                }}
              />
            </ListItemsInTwoColumns>
          </div>
        }
      />
      {(modalsShow.tireEquivalence || modalsShow.flaps) && (
        <TireEquivalence
          closeModal={() => closeCalculationsModal('flaps')}
          flaps={modalsShow.flaps}
        />
      )}
      {modalsShow.fiscalHorsepower && (
        <FiscalHorsepower closeModal={() => closeCalculationsModal('fiscalHorsepower')} />
      )}
      {modalsShow.lightHitch && (
        <LightHitch closeModal={() => closeCalculationsModal('lightHitch')} />
      )}
      {modalsShow.heavyHitch && (
        <HeavyHitch closeModal={() => closeCalculationsModal('heavyHitch')} />
      )}
      {modalsShow.fifthWheelHitch && (
        <FifthWheelHitch closeModal={() => closeCalculationsModal('fifthWheelHitch')} />
      )}
    </>
  )
}
