//@ts-check
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import React, { useEffect, useReducer, useState } from 'react'
import StatisticsTools from '../StatisticsTools/StatisticsTools'
import ListOfStatisticsDefects from './ListOfStatisticsDefects/ListOfStatisticsDefects'
import StatisticsDefectsByFilter from './StatisticsDefectsByFilter/StatisticsDefectsByFilter'
import './StatisticsDefects.scss'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import AnimatedCounter from 'components/Others/AnimatedCounter/AnimatedCounter'
import reportDefectsJSON from 'resources/defectology/reportDefects.json'
import GradientAreaChart from 'components/Others/Charts/GradientAreaChart'
import getCssVariable from 'myMethods/getCssVariable'

/**
 * @typedef {import('./../StatisticsHandler').default} StatisticsHandler
 */
/**
 *
 * @param {{statisticsHandler: StatisticsHandler}} param0
 * @returns
 */

export default function StatisticsDefects({ statisticsHandler }) {
  const statisticsDefects = statisticsHandler?.defects
  const dateRangeText = statisticsDefects?.dateRangeText
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState({
    show: false,
    title: '',
    defects: null,
    filterKey: '',
    filterLabel: '',
    total: false,
  })
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const selectors = statisticsDefects?.selectors
    ? Object.entries(statisticsDefects.selectors)
        .map(([selector, state]) => (state ? selector : null))
        .filter(elem => elem)
    : []
  const changeData = async selectedDateRange => {
    try {
      if (!selectedDateRange) return easyToast('error', 'Seleccione un rango de fechas')

      if (JSON.stringify(selectedDateRange) !== JSON.stringify(statisticsDefects.dateRange)) {
        setLoading(true)

        await statisticsHandler.changeDefectsData(selectedDateRange[0], selectedDateRange[1])
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error con el rango de fechas seleccionado')
    } finally {
      setLoading(false)
    }
  }

  const formatDefectsToExportation = initialDefects => {
    try {
      if (!initialDefects) return []
      const finalDefects = []
      reportDefectsJSON.forEach(({ id, name }) => {
        if (id in initialDefects) {
          finalDefects.push({ ...initialDefects[id], name })
        } else {
          finalDefects.push({
            defectId: id,
            name: name,
            slight: 0,
            serious: 0,
            verySerious: 0,
            total: 0,
          })
        }
      })
      return finalDefects
    } catch (err) {
      console.error(err)
      return []
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    try {
      if (
        statisticsHandler &&
        (!statisticsDefects?.initialized ||
          (statisticsHandler?.dateRange?.length &&
            JSON.stringify(statisticsDefects.dateRange) !==
              JSON.stringify(statisticsHandler.dateRange)))
      ) {
        const initialize = async () => {
          setLoading(true)
          await statisticsHandler.initializeDefects()
          setLoading(false)
        }
        initialize()
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos. Seleccione una fecha')
      setLoading(false)
    }
    return () => abortController.abort()
  }, [statisticsHandler, statisticsDefects?.initialized, setLoading, statisticsDefects?.dateRange])

  return (
    <>
      {loading && <ChildSpinner text="Cargando defectos..." mintLogo={true} />}
      {!loading && (
        <>
          <StatisticsTools
            onAccept={changeData}
            loading={loading}
            dateRange={statisticsDefects?.dateRange || []}
            dateRangeText={dateRangeText}
            exportation={{
              type: 'STATISTICS_DEFECTS_SUMMARY',
              data: {
                values: {
                  ...statisticsDefects?.data?.totalRange?.defects,
                  listOfDefects: formatDefectsToExportation(
                    statisticsDefects?.data?.totalRange?.defects?.byDefects
                  ),
                },
                inspections: statisticsDefects?.data?.totalRange?.numberOfInspections,
              },
              options: {
                dateRange: statisticsDefects?.dateRange,
              },
              modalTitle: 'Exportar resumen de defectos',
              defaultInputValue: 'RESUMEN DE DEFECTOS',
            }}
          />

          <div className="statistics-defects">
            <div className="statistics__main-info" data-aos="fade-up">
              <CardNameValue
                className="statistics__info-card"
                name="TOTAL INSPECCIONES"
                value={
                  <AnimatedCounter
                    number={statisticsDefects?.data?.totalRange?.numberOfInspections ?? 0}
                  />
                }
              />
              <CardNameValue
                className="statistics__info-card"
                name="TOTAL DEFECTOS"
                value={
                  <AnimatedCounter
                    number={statisticsDefects?.data?.totalRange?.defects?.total ?? 0}
                  />
                }
              />
              {selectors.includes('Leves') && (
                <CardNameValue
                  className="statistics__info-card statistics-defects__info-card--l"
                  name="TOTAL DEFECTOS LEVES"
                  value={
                    <AnimatedCounter
                      number={statisticsDefects?.data?.totalRange?.defects?.slight ?? 0}
                    />
                  }
                />
              )}
              {selectors.includes('Graves') && (
                <CardNameValue
                  className="statistics__info-card statistics-defects__info-card--g"
                  name="TOTAL DEFECTOS GRAVES"
                  value={
                    <AnimatedCounter
                      number={statisticsDefects?.data?.totalRange?.defects?.serious ?? 0}
                    />
                  }
                />
              )}
              {selectors.includes('Muy graves') && (
                <CardNameValue
                  className="statistics__info-card statistics-defects__info-card--vg"
                  name="TOTAL DEFECTOS MUY GRAVES"
                  value={
                    <AnimatedCounter
                      number={statisticsDefects?.data?.totalRange?.defects?.verySerious ?? 0}
                    />
                  }
                />
              )}
            </div>
            <div className="statistics__selectors" data-aos="fade-up" data-aos-delay="100">
              <Checkbox
                label="Leves"
                color={getCssVariable('--l-defect-color')}
                onChange={checked => {
                  statisticsHandler?.toggleDefectsSelectorState('Leves', checked)
                  forceUpdate()
                }}
                checked={selectors.includes('Leves')}
              />
              <Checkbox
                label="Graves"
                color={getCssVariable('--g-defect-color')}
                onChange={checked => {
                  statisticsHandler?.toggleDefectsSelectorState('Graves', checked)
                  forceUpdate()
                }}
                checked={selectors.includes('Graves')}
              />
              <Checkbox
                label="Muy graves"
                color={getCssVariable('--vg-defect-color')}
                onChange={checked => {
                  statisticsHandler?.toggleDefectsSelectorState('Muy graves', checked)
                  forceUpdate()
                }}
                checked={selectors.includes('Muy graves')}
              />
            </div>
            <div data-aos="fade-up" data-aos-delay="100">
              <GradientAreaChart
                data={statisticsDefects?.areaChartData}
                dataKeys={statisticsDefects?.rawSelectors}
                hiddenDataKeys={statisticsDefects?.rawSelectors?.filter(
                  sel => !selectors.includes(sel) || sel === 'Inspecciones'
                )}
                title="Total defectos"
                height={300}
                extraKeysToShowInToolkit={['Inspecciones']}
                colors={[
                  getCssVariable('--l-defect-color'),
                  getCssVariable('--g-defect-color'),
                  getCssVariable('--vg-defect-color'),
                ]}
              />
              <MyButtonsContainer margin="1rem auto">
                <MyButton
                  text="Resumen de defectos"
                  onClick={() =>
                    setModal({
                      show: true,
                      defects: {
                        ...statisticsDefects?.data?.totalRange?.defects,
                        inspections: statisticsDefects?.data?.totalRange?.numberOfInspections ?? 0,
                      },
                      filterKey: '',
                      filterLabel: '',
                      title: 'Resumen de defectos',
                      total: true,
                    })
                  }
                />
              </MyButtonsContainer>
            </div>
            <div className="statistics__section" data-aos="fade-up" data-aos-delay="200">
              <div className="statistics__section-title">Resumen de defectos según</div>
              <div className="statistics__section-body">
                <MyButtonsContainer margin="0 auto" styles={{ maxWidth: '100%' }}>
                  <MyButton
                    text="Tipo de inspección"
                    onClick={() =>
                      setModal({
                        show: true,
                        defects:
                          statisticsDefects?.data?.totalRange?.byFilter?.inspectionType?.defectsBy,

                        title: 'DEFECTOS POR TIPO DE INSPECCIÓN',
                        filterKey: 'INSPECTION',
                        filterLabel: 'inspección',

                        total: false,
                      })
                    }
                  />
                  <MyButton
                    text="Categoría"
                    onClick={() =>
                      setModal({
                        show: true,
                        defects: statisticsDefects?.data?.totalRange?.byFilter?.category?.defectsBy,
                        title: 'DEFECTOS POR CATEGORÍA',
                        filterKey: 'CATEGORY',
                        filterLabel: 'categoría',
                        total: false,
                      })
                    }
                  />
                  <MyButton
                    text="Clasificación"
                    onClick={() =>
                      setModal({
                        show: true,
                        defects:
                          statisticsDefects?.data?.totalRange?.byFilter?.classification?.defectsBy,
                        title: 'DEFECTOS POR CLASIFICACIÓN',
                        filterKey: 'CLASSIFICATION',
                        filterLabel: 'clasificación',
                        total: false,
                      })
                    }
                  />
                  <MyButton
                    text="Combustible"
                    onClick={() =>
                      setModal({
                        show: true,
                        defects: {
                          ...statisticsDefects?.data?.totalRange?.byFilter?.fuel1?.defectsBy,
                          ...statisticsDefects?.data?.totalRange?.byFilter?.fuel2?.defectsBy,
                        },
                        filterKey: 'FUEL',
                        filterLabel: 'combustible',
                        title: 'DEFECTOS POR COMBUSTIBLE',
                        total: false,
                      })
                    }
                  />
                  <MyButton
                    text="Usuario"
                    onClick={() =>
                      setModal({
                        show: true,
                        defects: statisticsDefects?.data?.totalRange?.byFilter?.user?.defectsBy,
                        title: 'DEFECTOS POR USUARIO',
                        filterKey: 'USER',
                        filterLabel: 'usuario',
                        total: false,
                      })
                    }
                  />
                </MyButtonsContainer>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal
        open={modal?.show}
        onClose={() =>
          setModal({
            show: false,
            title: '',
            defects: null,
            filterKey: '',
            filterLabel: '',
            total: false,
          })
        }
        title={modal?.title}
        hasAcceptButton={false}
        content={
          modal.total ? (
            <ListOfStatisticsDefects defects={modal?.defects} />
          ) : (
            <StatisticsDefectsByFilter
              defectsByFilter={modal?.defects}
              filterKey={modal?.filterKey}
              filterLabel={modal?.filterLabel}
              dateRange={statisticsDefects?.dateRange}
              totalInspections={statisticsDefects?.data?.totalRange?.numberOfInspections}
              formatDefectsToExportation={formatDefectsToExportation}
            />
          )
        }
      />
    </>
  )
}
