import React, { useState, useEffect } from 'react'
import './Drawer.scss'
import { ArrowBackIos, Menu } from '@mui/icons-material'
import { motion } from 'framer-motion'
import useWindowDimensions from 'hooks/useWindowDimensions'
import ChildSpinner from '../Spinner/ChildSpinner'

export default function Drawer({ items, loading, loadingText = '' }) {
  const [open, setOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState(0)
  const { width } = useWindowDimensions()

  const getItemClassName = index => {
    try {
      let className = 'drawer__menu-item'
      if (index === selectedItem) {
        className += ' drawer__menu-item--selected'
      }
      if (open) {
        className += ' drawer__menu-item--open'
      }
      return className
    } catch (err) {
      console.error(err)
      return 'drawer__menu-item'
    }
  }

  const menuOpenWidth = width > 800 ? (width > 1200 ? '20%' : '30%') : '40%'

  const headerVariants = {
    open: {
      left: menuOpenWidth,
    },
    closed: { left: 0 },
  }

  const menuVariants = {
    open: {
      width: menuOpenWidth,
    },
    closed: { width: '5rem' },
  }

  useEffect(() => {
    // Elimina el marginBottom del appMain div
    const $appMain = document.getElementById('app__main')
    const $body = document.getElementsByTagName('body')[0]

    if ($appMain) {
      $appMain.style.marginBottom = 0
    }
    if ($body) {
      $body.style.overflow = 'hidden'
    }

    return () => {
      if ($appMain) {
        $appMain.style.marginBottom = '10rem'
      }
      if ($body) {
        $body.style.overflow = 'auto'
      }
    }
  }, [])
  return (
    <div className="drawer">
      <motion.div
        className="drawer__header"
        animate={open ? 'open' : 'closed'}
        variants={headerVariants}
      >
        {!open && (
          <div className="drawer__btn drawer__btn-wrapper" onClick={() => setOpen(true)}>
            <Menu />
          </div>
        )}
        <div className="drawer__title">{items[selectedItem]?.text || ''}</div>
      </motion.div>
      <motion.div
        className="drawer__menu"
        animate={open ? 'open' : 'closed'}
        variants={menuVariants}
      >
        <div className="drawer__menu-btn" onClick={() => setOpen(false)}>
          <div className="drawer__btn-wrapper" style={{ paddingLeft: '0.4rem' }}>
            <ArrowBackIos />
          </div>
        </div>
        {items.map((item, index) => {
          return (
            <div
              key={index}
              className={getItemClassName(index)}
              onClick={() => setSelectedItem(index)}
            >
              <div className="drawer__menu-icon">{item.icon}</div>
              {open && <div className="drawer__menu-text">{item.text}</div>}
            </div>
          )
        })}
      </motion.div>
      {!loading && <div className="drawer__body">{items[selectedItem]?.content}</div>}
      {loading && (
        <div className="drawer__body">
          <ChildSpinner text={loadingText} />
        </div>
      )}
    </div>
  )
}
