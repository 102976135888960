//@ts-check
import React, { isValidElement } from 'react'
import './CardTextAndIcon.scss'
import isValidFunction from 'myMethods/isValidFunction'

/**
 *
 * @param {{text?: string, icon?: any, onClick?: function, className?: string}} param0
 * @returns
 */
export default function CardTextAndIcon({ text, icon, onClick, className }) {
  return (
    <div
      className={`card-text-and-icon${className ? ' ' + className : ''}`}
      onClick={props => isValidFunction(onClick) && onClick(props)}
    >
      {icon && isValidElement(icon) && <div>{icon}</div>}
      {text && <div>{text}</div>}
    </div>
  )
}
