//@ts-check
import React, { useState } from 'react'
import Modal from 'components/Others/Modal/Modal'
import './ClientOptionsModal.scss'
import BigButtonsContainer from 'components/Others/BigButtons/BigButtonsContainer'
import BigButton from 'components/Others/BigButtons/BigButton'
import AddAndModifyClientModal from '../AddAndModifyClientModal/AddAndModifyClientModal'
import History from 'components/Supervision/History/History'
import { getHistoryOfAClientByChunk } from 'services/History'
import BillingPage from 'pages/Office/Billing/Billing'
/**
 *
 * @param {{clientData: import('types/Client').Client, isNew: boolean, closeModal: function, refetch: function}} param0
 */
export default function ClientOptionsModal({ clientData, isNew, closeModal, refetch }) {
  const [modalsState, setModalsState] = useState({
    billing: false,
    history: false,
    modify: false,
  })
  const clientNameToShow = `${clientData?.dni || ''} - ${clientData?.name || ''}`
  return (
    <>
      {!isNew && (
        <>
          <Modal
            open={true}
            onClose={closeModal}
            hasAcceptButton={false}
            content={
              <div className="client-options-modal">
                <div className="client-options-modal__header">
                  <i className="flaticon-usuario" />
                  <div>{clientNameToShow}</div>
                </div>
                <BigButtonsContainer>
                  <BigButton
                    text="Editar cliente"
                    icon={<i className="flaticon-edit-2" />}
                    onClick={() => setModalsState({ ...modalsState, modify: true })}
                  />
                  <BigButton
                    text="Ver facturación del cliente"
                    icon={<i className="flaticon-bill" />}
                    onClick={() => setModalsState({ ...modalsState, billing: true })}
                  />
                  <BigButton
                    text="Ver historial de inspecciones del cliente"
                    icon={<i className="flaticon-folder-1" />}
                    onClick={() => setModalsState({ ...modalsState, history: true })}
                  />
                </BigButtonsContainer>
              </div>
            }
          />
          {(modalsState.billing || modalsState.history) && (
            <Modal
              open={true}
              onClose={() => setModalsState({ ...modalsState, billing: false, history: false })}
              hasAcceptButton={false}
              title={
                modalsState.billing
                  ? `FACTURACIÓN DE CLIENTE ${clientNameToShow}`
                  : `HISTORIAL DE CLIENTE ${clientNameToShow}`
              }
              content={
                modalsState.billing ? (
                  <BillingPage
                    forcedFilterValues={{ dni: clientData?.dni }}
                    showTableFilter={false}
                    onlyShowTables={true}
                    style={{ paddingBottom: '1.5rem' }}
                  />
                ) : (
                  <History
                    forcedFilterValues={{ likeDniHolder: clientData?.dni }}
                    showTableFilter={false}
                  />
                )
              }
            />
          )}
        </>
      )}
      {(isNew || modalsState?.modify) && (
        <AddAndModifyClientModal
          dni={clientData?.dni}
          refetch={refetch}
          closeModal={closeModal}
          isNew={isNew}
        />
      )}
    </>
  )
}
