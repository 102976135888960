import * as Yup from 'yup'

export function cashRegister() {
  return Yup.object().shape({
    userId: Yup.string().required('Requerido').ensure(),
    name: Yup.string()
      .required('Requerido')
      .min(3, '   ❌ MÍNIMO 3 CARACTERES')
      .max(20, '   ❌ MÁXIMO 20 CARACTERES'),
    activeUntil: Yup.date().nullable(),
  })
}
