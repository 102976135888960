import React, { useState } from 'react'
import { Formik, Form, ErrorMessage, Field } from 'formik'
import Select from 'react-select'
import {
  setReduxFormState,
  setReduxInspectionData,
  initReduxNewVehicle,
} from 'redux/ducks/newInspectionDuck'
import { useDispatch, useSelector } from 'react-redux'
import { NewInspection as formSchema } from 'formSchemas/NewInspection'
import 'components/Office/NewInspectionForm/NewInspectionForm.scss'
import parseFormValues from 'myMethods/parseFormValues.js'
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import { selectStyles } from 'components/Others/Select/SelectStyles'
import moment from 'moment'
import PrelicenseModal from '../PrelicenseModal/PrelicenseModal'
import MuiPopover from 'components/Others/MuiPopover/MuiPopover'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Input from 'components/Others/Input/Input'
import { useParams } from 'react-router-dom'
import { ArrowRight as NextIcon } from 'react-bootstrap-icons'
import validateLicense from 'myMethods/validateLicense'
import useResources from 'hooks/useResources'

const initialValues = {
  license: '',
  frame: '',
  inspectionType: '',
}

export default function NewInspectionForm() {
  const dispatch = useDispatch()
  const redux = useSelector(store => store)
  const reduxInspectionData = redux?.newVehicle?.reduxInspectionData
  const params = useParams()
  const { inspectionTypes } = useResources({
    inspectionTypes: true,
  })
  const [showModal, setShowModal] = useState(false)
  const [prelicenseModal, setPrelicenseModal] = useState({
    show: false,
    data: null,
    formValues: null,
  })
  console.log({ inspections: inspectionTypes })
  const TrailerPopover = () => (
    <div className="new-inspection-form__popover">
      <MuiPopover
        id="new-inspection-form__popover"
        outsideContent="¿Vehículo sin matrícula?"
        insideContent={
          <>
            Inserte los últimos 8 dígitos del bastidor del vehículo.
            <br />
            En el caso de que el bastidor sea demasiado corto, rellene empezando con{' '}
            <b>0 (ceros)</b> hasta cumplir las 8 cifras.
            <br />
            <br />
            <div>
              <span>EJEMPLO</span>
              <br />
              <b>BASTIDOR: </b>4130
              <br />
              <b>MATRÍCULA: </b>004130
            </div>
          </>
        }
      />
    </div>
  )

  function handleInitialValues() {
    if (!reduxInspectionData) {
      if (params.license || params.frame) {
        return {
          ...initialValues,
          license: params.license,
          frame: params.frame,
        }
      }
      return initialValues
    } else {
      if (reduxInspectionData.inspectionType) {
        let inspectionObj = inspectionTypes.find(
          elem => elem.label === reduxInspectionData.inspectionType
        )

        return parseFormValues(
          { ...reduxInspectionData, inspectionType: inspectionObj ? inspectionObj : '' },
          true
        )
      }
      return reduxInspectionData
    }
  }

  const handleSubmit = async values => {
    try {
      console.log({ values })
      dispatch(initReduxNewVehicle(moment().format('YYYY-MM-DD HH:mm:ss')))
      if (
        values.inspectionType.value === 'PREVIA A MATRICULACIÓN' &&
        !prelicenseModal.data?.value
      ) {
        return setPrelicenseModal({ ...prelicenseModal, show: true, formValues: values })
      }
      // if (values.inspectionType.value === 'SEGUNDA INSPECCIÓN O SUCESIVAS') {
      //   return await isValidSecondInspection(values.license)
      // }

      values = parseFormValues(values, false, true)
      let aux = {
        ...values,
        inspectionType: values.inspectionType.value,
      }
      if (prelicenseModal.data) {
        aux.config = { prelicense: prelicenseModal.data }
      }
      dispatch(setReduxInspectionData(aux))
      validateLicense(values.license) ? dispatch(setReduxFormState(1)) : setShowModal(true)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Error enviando los datos, inténtelo de nuevo')
    }
  }

  return (
    <>
      <section className="new-inspection-form">
        <TrailerPopover />
        <Formik
          initialValues={handleInitialValues()}
          validationSchema={formSchema}
          onSubmit={values => {
            handleSubmit(values)
          }}
        >
          {({ setFieldValue, setFieldTouched, touched, errors, values }) => (
            <Form className="new-inspection-form__form">
              <div className="new-inspection-form__license-container">
                <div className="new-inspection-form__license">
                  <Input
                    label="Matrícula"
                    name="license"
                    placeholder="Matrícula"
                    styleVariant="outlined"
                  />
                </div>
                <div className="new-inspection-form__frame">
                  <Input
                    label="Bastidor"
                    name="frame"
                    placeholder="Bastidor"
                    styleVariant="outlined"
                  />
                </div>
              </div>
              <div className="new-inspection-form__select">
                <label>
                  Tipo de inspección
                  <ErrorMessage className="form-error" name="inspectionType" component="small" />
                </label>
                <Select
                  noOptionsMessage={() => 'No hay opciones'}
                  menuPortalTarget={document.body}
                  styles={selectStyles}
                  name="inspectionType"
                  placeholder="Seleccione uno..."
                  options={inspectionTypes}
                  value={values.inspectionType}
                  onChange={opt => {
                    setFieldValue('inspectionType', opt)
                  }}
                  onBlur={() => setFieldTouched('inspectionType', true)}
                  error={errors.inspectionType}
                  touched={touched.inspectionType}
                />
              </div>

              <MyButtonsContainer fullWidth={false}>
                <MyButton text="Siguiente" type="submit" icon={<NextIcon />} />
              </MyButtonsContainer>
            </Form>
          )}
        </Formik>
      </section>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title="La matrícula que ha introducido no cumple un formato estándar"
        message="¿Continuar?"
        onAccept={() => dispatch(setReduxFormState(1))}
      />
      <Modal
        className="new-inspection-form__prelicense-modal"
        open={prelicenseModal.show}
        onClose={() => setPrelicenseModal({ ...prelicenseModal, show: false })}
        title="Seleccione tipo de previa a matriculación"
        onAccept={() => {
          prelicenseModal.data
            ? handleSubmit(prelicenseModal.formValues)
            : easyToast('error', 'Seleccione al menos una opción')
        }}
        closeAfterAccept={false}
        content={
          <PrelicenseModal
            setPrelicense={data => setPrelicenseModal({ ...prelicenseModal, data })}
          />
        }
      />
    </>
  )
}
