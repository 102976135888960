// constantes
const initialData = {
  formState: 0,
  inspectionData: null,
  vehicleData: null,
  holderData: null,
  invoiceInfo: {
    invoiceId: null,
    serie: null,
  },
  invoiceData: null,
  feeData: null,
  paymentData: null,
  discountData: null,
  time: null,
}

// types
const SET_FORM_STATE_SUCCESS = 'SET_FORM_STATE_SUCCESS'
const SET_INSPECTION_DATA_SUCCESS = 'SET_INSPECTION_DATA_SUCCESS'
const SET_VEHICLE_DATA_SUCCESS = 'SET_VEHICLE_DATA_SUCCESS'
const SET_HOLDER_DATA_SUCCESS = 'SET_HOLDER_DATA_SUCCESS'
const SET_INVOICE_INFO_SUCCESS = 'SET_INVOICE_INFO_SUCCESS'
const SET_INVOICE_DATA_SUCCESS = 'SET_INVOICE_DATA_SUCCESS'
const SET_FEE_DATA_SUCCESS = 'SET_FEE_DATA_SUCCESS'
const SET_PAYMENT_DATA_SUCCESS = 'SET_PAYMENT_DATA_SUCCESS'
const SET_DISCOUNT_DATA_SUCCESS = 'SET_DISCOUNT_DATA_SUCCESS'
const SET_TIME_SUCCESS = 'SET_TIME_SUCCESS'
const INIT_REDUCER_SUCCESS = 'INIT_REDUCER_SUCCESS'
const RESET_REDUCER_SUCCESS = 'RESET_REDUCER_SUCCESS'
// reducer
export default function newInspectionReducer(state = initialData, action) {
  switch (action.type) {
    case SET_FORM_STATE_SUCCESS:
      return { ...state, formState: action.payload }
    case SET_INSPECTION_DATA_SUCCESS:
      return { ...state, inspectionData: action.payload }
    case SET_VEHICLE_DATA_SUCCESS:
      return { ...state, vehicleData: action.payload }
    case SET_HOLDER_DATA_SUCCESS:
      return { ...state, holderData: action.payload }
    case SET_INVOICE_INFO_SUCCESS:
      return { ...state, invoiceInfo: action.payload }
    case SET_INVOICE_DATA_SUCCESS:
      return { ...state, invoiceData: action.payload }
    case SET_FEE_DATA_SUCCESS:
      return { ...state, feeData: action.payload }
    case SET_PAYMENT_DATA_SUCCESS:
      return { ...state, paymentData: action.payload }
    case SET_DISCOUNT_DATA_SUCCESS:
      return { ...state, discountData: action.payload }
    case SET_TIME_SUCCESS:
      return { ...state, time: action.payload }
    case INIT_REDUCER_SUCCESS:
      return { ...initialData, time: action.payload }
    case RESET_REDUCER_SUCCESS:
      return (state = initialData)
    default:
      return state
  }
}

// actions

export const setReduxFormState = number => dispatch => {
  try {
    dispatch({
      type: SET_FORM_STATE_SUCCESS,
      payload: number,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionData = data => dispatch => {
  try {
    dispatch({
      type: SET_INSPECTION_DATA_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxVehicleData = data => dispatch => {
  try {
    dispatch({
      type: SET_VEHICLE_DATA_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxHolderData = data => dispatch => {
  try {
    dispatch({
      type: SET_HOLDER_DATA_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInvoiceInfo = data => dispatch => {
  try {
    dispatch({
      type: SET_INVOICE_INFO_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInvoiceData = data => dispatch => {
  try {
    dispatch({
      type: SET_INVOICE_DATA_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxFeeData = data => dispatch => {
  try {
    dispatch({
      type: SET_FEE_DATA_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxPaymentData = data => dispatch => {
  try {
    dispatch({
      type: SET_PAYMENT_DATA_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxDiscountData = data => dispatch => {
  try {
    dispatch({
      type: SET_DISCOUNT_DATA_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxNewVehicleTime = data => dispatch => {
  try {
    dispatch({
      type: SET_TIME_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const initReduxNewVehicle = data => dispatch => {
  try {
    dispatch({
      type: INIT_REDUCER_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const resetReduxNewVehicle = () => dispatch => {
  try {
    dispatch({
      type: RESET_REDUCER_SUCCESS,
    })
  } catch (err) {
    console.error(err)
  }
}
