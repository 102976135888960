import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

// MY IMPORTS
import 'components/MobileMenu/MobileMenu.scss'
import FillReduxInspection from 'components/FillRedux/FillReduxInspection'

function MobileMenu() {
  const [state, setState] = useState(null)
  const history = useHistory()
  const params = useParams()

  // Cambia ruta al main de inspección cuando se recarga la página
  // window.onbeforeunload = function () {
  //   window.setTimeout(function () {
  //     window.location.href = `/new_inspection/${params.license}`
  //   }, 0)
  //   window.onbeforeunload = null // necessary to prevent infinite loop, that kills your browser
  // }

  const handleClick = newRoute => {
    if (newRoute === 'home') {
      setState('home')
      return history.replace(`/new_inspection/${params.license}`)
    }
    setState(newRoute)
    history.replace(`/new_inspection/${params.license}/${newRoute}`)
  }

  useEffect(() => {
    //Establece el State por si se ha refrescado la página
    let { pathname } = history.location

    if (
      pathname.includes('defects') ||
      pathname.includes('hardware') ||
      pathname.includes('complementary_equipment')
    )
      return setState('defects')
    if (pathname.includes('images')) return setState('images')
    if (pathname.includes('remarks')) return setState('remarks')
    if (pathname.includes('history')) return setState('history')
    setState('home')
  }, [history.location])

  return (
    <>
      <FillReduxInspection />
      <div className="mobile-menu">
        <div className="mobile-menu__btn" onClick={() => handleClick('defects')}>
          <div>
            {state === 'defects' ? (
              <i className="flaticon-edit-1" />
            ) : (
              <i className="flaticon-edit" />
            )}
          </div>
        </div>
        <div className="mobile-menu__btn" onClick={() => handleClick('images')}>
          <div>
            {state === 'images' ? (
              <i className="flaticon-photo-camera-1" />
            ) : (
              <i className="flaticon-photo-camera" />
            )}
          </div>
        </div>
        <div
          className="mobile-menu__btn mobile-menu__btn--home"
          onClick={() => handleClick('home')}
        >
          <div>
            {state === 'home' ? <i className="flaticon-home-1" /> : <i className="flaticon-home" />}
          </div>
        </div>
        <div className="mobile-menu__btn" onClick={() => handleClick('remarks')}>
          <div>
            {state === 'remarks' ? (
              <i className="flaticon-message-1" />
            ) : (
              <i className="flaticon-message" />
            )}
          </div>
        </div>
        <div className="mobile-menu__btn" onClick={() => handleClick('history')}>
          <div>
            {state === 'history' ? (
              <i className="flaticon-folder-1" />
            ) : (
              <i className="flaticon-folder" />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default React.memo(MobileMenu)
