//@ts-check
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { SwipeableDrawer } from '@mui/material'
import './SidebarMenu.scss'

/**
 *
 * @param {{anchor?: 'top' | 'left' | 'bottom' | 'right', children: any, swipeToOpen?: boolean, className?: string}} param0
 * @returns
 */
function SidebarMenu({ anchor = 'left', children, swipeToOpen = true, className }, ref) {
  const [isOpened, setIsOpened] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      open: () => setIsOpened(true),
      close: () => setIsOpened(false),
    }
  })
  return (
    <React.Fragment key={anchor}>
      <SwipeableDrawer
        anchor={anchor}
        open={isOpened}
        onClose={() => setIsOpened(false)}
        onOpen={() => setIsOpened(true)}
        disableSwipeToOpen={!swipeToOpen}
        className="sidebar-menu__drawer"
      >
        <div className={className ?? 'sidebar-menu'}>{children}</div>
      </SwipeableDrawer>
    </React.Fragment>
  )
}

export default forwardRef(SidebarMenu)
