import MatTable from 'components/Others/MuiTable/MuiTable'
import React, { useState, useEffect } from 'react'
import { getHistoryByChunk } from 'services/History'
import Modal from 'components/Others/Modal/Modal'
import HistoryModal from './HistoryModal/HistoryModal'
import TablePagination from 'components/Others/MuiTable/TablePagination/TablePagination'
import TableFilter from 'components/Others/TableFilter/TableFilter'
import useServiceByChunk from 'hooks/useServiceByChunk'

/**
 *
 * @param {{forcedFilterValues?: import('types/FilterServiceParams').FilterServiceParams, showTableFilter?: boolean}} param0
 * @returns
 */
export default function History({ forcedFilterValues, showTableFilter = true }) {
  const [showModal, setShowModal] = useState(false)
  const [rowClicked, setRowClicked] = useState(null)

  const {
    data: history,
    total: totalData,
    fetch: fetchHistory,
    fetchNextChunk,
    clearFilter,
    loading,
    currentFilterValues,
  } = useServiceByChunk({ service: getHistoryByChunk, defaultLimit: 1000, forcedFilterValues })

  useEffect(() => {
    if (history == null) {
      fetchHistory()
    }
  }, [fetchHistory, history])

  return (
    <>
      <MatTable
        tableFilter={
          showTableFilter ? (
            <TableFilter
              totalValues={totalData}
              initialFilterValues={currentFilterValues}
              startDate={true}
              endDate={true}
              likeLicense={true}
              likeHolderDni={true}
              loading={loading}
              onSubmit={filterValues => {
                fetchHistory(null, filterValues)
              }}
              onClear={clearFilter}
            />
          ) : null
        }
        filterValues={currentFilterValues}
        onClearTableFilter={clearFilter}
        columnsKey="history"
        values={history}
        loading={loading}
        onRowClick={rowData => {
          setRowClicked(rowData)
          setShowModal(true)
        }}
        showToolbar={false}
        exportation={{
          type: 'HISTORY',
          data: { values: history },
          modalTitle: 'Exportar historial de inspecciones',
          defaultInputValue: 'HISTORIAL DE INSPECCIONES',
        }}
        toolbarComponent={
          <TablePagination
            currentDataLength={history?.length}
            totalDataLength={totalData}
            onClick={fetchNextChunk}
          />
        }
      />
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title={`Informe ${rowClicked?.numero_informe} a ${rowClicked?.vehicleData?.license}`}
        hasAcceptButton={false}
        content={<HistoryModal data={rowClicked} closeModal={() => setShowModal(false)} />}
      />
    </>
  )
}
