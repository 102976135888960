import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

// MY IMPORTS
import ListOfManualChapters from 'components/Inspection/Defectology/ManualChapter/ListOfManualChapters'
import Title from 'components/Others/Title/Title'
import Modal from 'components/Others/Modal/Modal'
import Preinspection from 'components/Inspection/Preinspection/Preinspection'
import easyToast from 'components/Others/EasyToast/easyToast'
import Id from 'Share/Id'
import {
  PcDisplayHorizontal as HardwareIcon,
  Rulers as ComplementaryEquipmentIcon,
} from 'react-bootstrap-icons'

// SERVICES
import { putInspectionOptionalDefects as putInspectionOptionalDefectsService } from 'services/Inspection'
import { setReduxInspectionShowPreinspectionModal } from 'redux/ducks/inspectionDuck'
import DefectsFinder from 'components/Inspection/DefectsFinder/DefectsFinder'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import RenderListOfDefects from 'components/Inspection/Defectology/Defect/RenderListOfDefects'
import FilterInfo from 'components/Inspection/FilterInfo/FilterInfo'
import NoFilter from 'components/Inspection/NoFilter/NoFilter'
import PrintWrapper from 'components/Others/PrintWrapper/PrintWrapper'

export default function Defects() {
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()
  const redux = useSelector(store => store)
  const reduxInspection = redux?.inspection
  const [preinspectionLoading, setPreinspectionLoading] = useState(false)
  const [foundDefects, setFoundDefects] = useState(null)
  const [rawManual, setRawManual] = useState([])

  const handleModalAccept = async () => {
    try {
      setPreinspectionLoading(true)
      await putInspectionOptionalDefectsService({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        optionalDefects: reduxInspection.optionalDefects,
      })
      dispatch(setReduxInspectionShowPreinspectionModal(false))
      window.sessionStorage.removeItem('new_inspection')

      setShowModal(false)
      easyToast('dark', 'Defectos opcionales actualizados')
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error al actualizar los defectos opcionales')
    } finally {
      setPreinspectionLoading(false)
    }
  }

  const handleModalClose = () => {
    window.sessionStorage.removeItem('new_inspection')
    setShowModal(false)
    dispatch(setReduxInspectionShowPreinspectionModal(false))
  }
  useEffect(() => {
    try {
      try {
        /**@type {InspectionDefectsManager} */
        const manualManager = reduxInspection?.manualManager
        console.log({ manualManager2: manualManager })
        if (manualManager) {
          const rawManual = manualManager.listAllDefectsShowOnlyShow()
          console.log({ rawManual })
          rawManual?.length && setRawManual(rawManual)
        }
      } catch (err) {
        console.error(err)
      }
    } catch (err) {
      console.error(err)
    }
  }, [rawManual?.length, reduxInspection?.manualManager])
  useEffect(() => {
    if (
      (reduxInspection?.showPreinspectionModal ||
        window.sessionStorage.getItem('new_inspection') === 'true') &&
      !showModal
    ) {
      setShowModal(true)
    }
  }, [reduxInspection?.showPreinspectionModal, showModal])

  return (
    <>
      <>
        <Title text={'EQUIPOS Y DEFECTOS VISUALES'} />
        {!reduxInspection.filter && <NoFilter />}
        {reduxInspection.filter && (
          <>
            <MyButtonsContainer margin="0 auto 1rem auto">
              {!reduxInspection.rev && (
                <MyButton
                  text="EQUIPOS"
                  onClick={() => history.replace('hardware')}
                  transparent={false}
                  icon={<HardwareIcon />}
                />
              )}
              <MyButton
                text="EQUIPOS COMPLEMENTARIOS"
                onClick={() => history.replace('complementary_equipment')}
                transparent={false}
                icon={<ComplementaryEquipmentIcon />}
              />
            </MyButtonsContainer>

            {rawManual && rawManual.length > 0 && (
              <DefectsFinder defects={rawManual} setFoundDefects={setFoundDefects} />
            )}
            <FilterInfo reduxInspection={reduxInspection} />
            {!foundDefects && <ListOfManualChapters />}
            {foundDefects && Array.isArray(foundDefects) && foundDefects.length > 0 && (
              <RenderListOfDefects defects={foundDefects} />
            )}
            {foundDefects && Array.isArray(foundDefects) && !foundDefects.length > 0 && (
              <div className="no-values">Sin coincidencias</div>
            )}
            <Modal
              open={showModal}
              onClose={() => setShowModal(false)}
              onAccept={handleModalAccept}
              onCloseButton={handleModalClose}
              closeAfterAccept={false}
              closeOnOverlay={false}
              buttons={true}
              loading={preinspectionLoading}
              content={
                <Preinspection
                  optionalDefects={reduxInspection?.optionalDefects}
                  manualManager={reduxInspection?.manualManager}
                />
              }
            />
          </>
        )}
      </>
    </>
  )
}
