import React from 'react'
import MyButton from '../Buttons/MyButton/MyButton'
import MyButtonsContainer from '../Buttons/MyButtonsContainer'
import './ErrorBoundary.scss'

class ErrorBoundary extends React.Component {
  state = { hasError: false }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }
  componentDidCatch(error, info) {
    console.log(error, info)
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary">
          <i className="flaticon-crash" />
          <h3>Ha ocurrido un error fatal en la aplicación</h3>
          <MyButtonsContainer margin="0">
            <MyButton text="Volver al inicio" onClick={this.props.goToLobby} />
          </MyButtonsContainer>
        </div>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
