import moment from 'moment'

export default function formatDate(date, noHours = false, toUser = false) {
  try {
    if (!date) throw new Error('date is required')
    const momentDate = moment(date)
    if (!momentDate.isValid()) throw new Error('date is invalid')
    return noHours
      ? momentDate.format(toUser ? 'DD-MM-YYYY' : 'YYYY-MM-DD')
      : momentDate.format(toUser ? 'DD-MM-YYYY HH:mm:ss' : 'YYYY-MM-DD HH:mm:ss')
  } catch (err) {
    console.error(err)
    return null
  }
}
