//@ts-check
import Accordion from 'components/Others/Accordion/Accordion'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import easyToast from 'components/Others/EasyToast/easyToast'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import Switch from 'components/Others/Switch/Switch'
import useService from 'hooks/useService'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setReduxConfigHardwareByLine } from 'redux/ducks/configDuck'
import { putItvHardwareConfig as putItvHardwareConfigService } from 'services/Hardware'

// MY IMPORTS
import './HardwareByLine.scss'

/**
 *
 * @param {{hardwareList: import('types/HardwareConfig').HardwareByLineElem[], setLoading: function}} param0
 * @returns
 */
export default function HardwareByLine({ hardwareList, setLoading }) {
  const timedOutTests = useSelector(store => store.config?.timedOutTests)
  const dispatch = useDispatch()

  const { fetch: putItvHardwareConfig } = useService({
    service: putItvHardwareConfigService,
    setOutLoading: setLoading,
  })

  const formatTest = testname => {
    try {
      return testname.replace('_', ' ').toUpperCase()
    } catch (err) {
      return ''
    }
  }

  const Hardware = ({ hardware }) => {
    const [editable, setEditable] = useState(false)
    const [values, setValues] = useState({
      alias: hardware.alias || '',
      tests: hardware.tests || {},
      serialNumber: hardware.serialNumber,
      settings: hardware.settings || {},
      id: hardware.id || '',
      active: hardware.active,
      editable: false,
    })

    const expirationData = timedOutTests?.find(elem => elem.internalId === hardware.internalId)

    const Message = ({ days }) => {
      if (days > 0) {
        return (
          <>
            Caducó hace {days}
            {days > 1 ? ' días' : ' día'}
          </>
        )
      }
      if (days < 0) {
        return (
          <>
            {days < -1 ? 'Quedan' : 'Queda'} <span>{days / -1} </span>
            {days < -1 ? ' días' : ' día'}
          </>
        )
      }
      if (days === 0)
        return (
          <>
            Caduca <span>hoy</span>
          </>
        )
      return <></>
    }

    const saveChanges = async () => {
      try {
        if (!values.id) return easyToast('error', 'El equipo debe tener un ID')
        delete values.editable
        const res = await putItvHardwareConfig({
          internalId: hardware?.internalId,
          name: hardware?.name,
          data: { ...values, id: values.id.toUpperCase() },
        })
        dispatch(setReduxConfigHardwareByLine(res?.hardwareByLines || null))
        setEditable(false)
        easyToast(
          'dark',
          `${hardware?.name?.toUpperCase()} ${hardware.id} modificado correctamente`
        )
      } catch (error) {
        easyToast('error', 'Ha ocurrido un error guardando los cambios')
      }
    }
    console.log({ values })

    return (
      <div className="hardware-by-line__hardware">
        <ListItemsInTwoColumns className="hardware-by-line__hardware-inputs-container">
          <CardNameValue
            name="Activo"
            value={
              <Switch
                value={values.active}
                onChange={checked => setValues({ ...values, active: checked })}
                disabled={!editable}
              />
            }
          />
          <CardNameValue
            name="ID"
            value={
              <input
                id="id"
                value={values.id}
                onChange={e => setValues({ ...values, id: e.target.value })}
                disabled={!editable}
                maxLength={8}
              />
            }
          />
          <CardNameValue
            name="Alias"
            value={
              <input
                value={values.alias}
                onChange={e => setValues({ ...values, alias: e.target.value })}
                disabled={!editable}
                maxLength={12}
              />
            }
          />
          <CardNameValue
            name="Número de serie"
            value={
              <input
                value={values.serialNumber}
                onChange={e => setValues({ ...values, serialNumber: e.target.value })}
                disabled={!editable}
              />
            }
          />

          {values.settings?.boxCorrectionFactor != null && (
            <CardNameValue
              name="Factor correción box"
              value={
                <input
                  type="number"
                  value={values.settings.boxCorrectionFactor}
                  onChange={e =>
                    setValues({
                      ...values,
                      settings: { ...values.settings, boxCorrectionFactor: e.target.value },
                    })
                  }
                  placeholder="Factor"
                  disabled={!editable}
                />
              }
            />
          )}
        </ListItemsInTwoColumns>

        {values.settings?.pressureSensors != null &&
          Object.keys(values.settings.pressureSensors).length > 0 && (
            <>
              <ListItemsInTwoColumns
                title="Sensores de presión"
                className="hardware-by-line__hardware-inputs-container"
              >
                {Object.keys(values.settings.pressureSensors).map((psNumber, i) => (
                  <CardNameValue
                    key={i}
                    name={`ID del sensor ${psNumber}`}
                    value={
                      <input
                        value={values.settings.pressureSensors[psNumber]?.id ?? ''}
                        onChange={e =>
                          setValues({
                            ...values,
                            settings: {
                              ...values.settings,
                              pressureSensors: {
                                ...values.settings.pressureSensors,
                                [psNumber]: { id: e.target.value },
                              },
                            },
                          })
                        }
                        placeholder="ID"
                        disabled={!editable}
                      />
                    }
                  />
                ))}
              </ListItemsInTwoColumns>
            </>
          )}

        <ListItemsInTwoColumns
          title="TESTS"
          className="hardware-by-line__hardware-inputs-container"
        >
          {Object.keys(values.tests)?.map((elem, i) => {
            const testExpirationData = expirationData?.timedOutTests.find(
              el => el.testName === elem
            )
            return (
              <CardNameValue
                key={i}
                name={formatTest(elem)}
                value={
                  <>
                    <div
                      style={{ opacity: editable ? 0 : 1, userSelect: 'none' }}
                      className="hardware-by-line__hardware-test-message"
                    >
                      <Message days={testExpirationData?.daysElapsed} />
                    </div>
                    {
                      <input
                        className={
                          !editable && !values.tests[elem] ? 'hardware-by-line__no-date-input' : ''
                        }
                        type={editable ? 'date' : values.tests[elem] ? 'date' : 'text'}
                        value={
                          editable ? values.tests[elem] ?? '' : values.tests[elem] || 'Sin fecha'
                        }
                        onChange={e =>
                          setValues({
                            ...values,
                            tests: { ...values.tests, [elem]: e.target.value },
                          })
                        }
                        disabled={!editable}
                      />
                    }
                  </>
                }
              />
            )
          })}
        </ListItemsInTwoColumns>
        <MyButtonsContainer margin="1.5rem auto 0 auto">
          <MyButton
            selected={editable}
            text={editable ? 'Guardar datos' : 'Habilitar edición'}
            icon={<i className={editable ? 'flaticon-diskette' : 'flaticon-pencil'} />}
            onClick={() => {
              editable ? saveChanges() : setEditable(true)
            }}
          />
        </MyButtonsContainer>
      </div>
    )
  }

  return (
    <section className="hardware-by-line">
      {hardwareList?.length > 0 &&
        hardwareList.map((hardware, i) => (
          <Accordion
            key={i}
            className="hardware-by-line__accordion"
            title={
              <div className="hardware-by-line__accordion-title">
                {hardware.active != null && (
                  <div
                    className={
                      hardware.active
                        ? 'hardware-by-line__accordion-status--active'
                        : 'hardware-by-line__accordion-status--not-active'
                    }
                  >
                    {hardware.active ? 'ACTIVO' : 'NO ACTIVO'}
                  </div>
                )}
                {hardware?.name?.toUpperCase() ?? '-'}
              </div>
            }
            titleWhenOpen={hardware?.name?.toUpperCase() ?? '-'}
            elemKey={i}
            // eslint-disable-next-line react/no-children-prop
            children={<Hardware hardware={hardware} />}
          />
        ))}
      {!hardwareList ||
        (!hardwareList.length && (
          <div className="no-values">Vaya, parece que aún no hay máquinas...</div>
        ))}
    </section>
  )
}
