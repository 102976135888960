import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import 'components/Inspection/ListInspections/ListInspections.scss'
import Modal from 'components/Others/Modal/Modal'
import Spinner from 'components/Others/Spinner/Spinner'
import easyToast from 'components/Others/EasyToast/easyToast'
import Id from 'Share/Id'
import sandClockAnimationData from 'lottie/sand-clock.json'
import {
  getAllInspections as getAllInspectionsService,
  getInspectionById as getInspectionByIdService,
  putInspectionInspector as putInspectionInspectorService,
  putInspectionLine as putInspectionLineService,
} from 'services/Inspection'
import ItemPicker from '../../Others/ItemPicker/ItemPicker'
import { resetInspectionDuck, setReduxInspectionAllData } from 'redux/ducks/inspectionDuck'
import formatDateToStringAgo from 'myMethods/formatDateToStringAgo'
import InspectionDataMapper from 'dataMappers/InspectionDataMapper'
import useService from 'hooks/useService'
import Filters from 'Share/Filters'
import Slider from 'components/Others/Slider/Slider'
import Title from 'components/Others/Title/Title'
import FilterNameIcon from 'components/Others/FilterNameIcon/FilterNameIcon'
import LottieAnimation from 'components/Others/LottieAnimation/LottieAnimation'
import useSessionStorage from 'hooks/useSessionStorage'
import useWindowDimensions from 'hooks/useWindowDimensions'
import SuperList from 'components/Others/SuperList/SuperList'
import ListCard from 'components/Others/ListCard/ListCard'
import { Engineering as UserIcon } from '@mui/icons-material'
import {
  LightningFill as ElectricFuelIcon,
  FuelPumpDieselFill as DieselFuelIcon,
  FuelPumpFill as GasolineFuelIcon,
  HourglassBottom as ClockIcon,
} from 'react-bootstrap-icons'
import getCssVariable from 'myMethods/getCssVariable'

export default function ListInspections() {
  const [inspections, setInspections] = useState([])
  const [supervisions, setSupervisions] = useState([])
  const { width } = useWindowDimensions()
  const onMobile = width < 640
  const [inspectionsLoaded, setInspectionsLoaded] = useState(false)

  const [modal, setModal] = useState({
    show: false,
    id: null,
    license: null,
    inspectionType: null,
    isSupervision: false,
    error: false,
  })
  const [takenModal, setTakenModal] = useState({
    show: false,
    data: null,
    inspector: null,
    isSupervision: false,
  })
  const [options, setOptions] = useState({
    inspection: {
      forMe: true,
      noUser: true,
      search: '',
    },
    supervision: {
      forMe: true,
      noUser: true,
      search: '',
    },
  })

  const history = useHistory()
  const dispatch = useDispatch()
  const redux = useSelector(store => store)
  const isSupervisor = redux.user.data?.role === 'SUPERVISOR'
  const reduxConfig = redux.config
  const modalValues = useMemo(() => reduxConfig.itv.lines, [reduxConfig.itv.lines])
  const [loadingInspection, setLoadingInspection] = useState(false)
  const [loadingSupervision, setLoadingSupervision] = useState(false)
  const [bigLoading, setBigLoading] = useState(false)
  const { initInspectionSS, enterInspectionSS, initSupervisionSS, enterSupervisionSS } =
    useSessionStorage()

  const { fetch: getAllInspections } = useService({ service: getAllInspectionsService })
  const { fetch: getInspectionById } = useService({ service: getInspectionByIdService })
  const { fetch: putInspectionLine } = useService({ service: putInspectionLineService })
  const { fetch: putInspectionInspector } = useService({ service: putInspectionInspectorService })

  const loadReduxData = useCallback(
    async id => {
      const response = await getInspectionById({ id })
      const reduxData = InspectionDataMapper.fromResponse(response)
      dispatch(setReduxInspectionAllData(reduxData))
    },
    [dispatch, getInspectionById]
  )

  const handleTakenClick = useCallback(
    async (data, isSupervision = false, isForced) => {
      try {
        setBigLoading(true)
        if (!isForced) {
          if (
            (data.inspectorData?.usuario_inspector &&
              data.inspectorData?.usuario_inspector !== redux.user.data?.username) ||
            window.sessionStorage.getItem('user')
          ) {
            return setTakenModal({
              show: true,
              data,
              inspector: data.inspectorData?.usuario_inspector,
              isSupervision,
            })
          }

          await putInspectionInspector({
            id: data.idInspeccion,
            operation: isSupervision ? 'enterSupervision' : 'enterInspection',
          })
        }
        dispatch(resetInspectionDuck())
        if (isSupervision)
          enterSupervisionSS(Id.encrypt(data.idInspeccion), data.linea, data.vehicleData?.license)
        else enterInspectionSS(Id.encrypt(data.idInspeccion), data.linea, data.vehicleData?.license)
        await loadReduxData(data.idInspeccion)
        history.replace(
          isSupervision
            ? `/new_supervision/${data.vehicleData?.license}`
            : `/new_inspection/${data.vehicleData?.license}`
        )
      } catch (err) {
        console.error(err)
        easyToast('error', 'Ha ocurrido un error cargando el vehículo. Inténtelo de nuevo')
        setTakenModal({ show: false, data: null, inspector: null })
      } finally {
        setBigLoading(false)
      }
    },
    [
      dispatch,
      enterInspectionSS,
      enterSupervisionSS,
      history,
      loadReduxData,
      putInspectionInspector,
      redux.user.data?.username,
    ]
  )

  const forceEnterInspection = useCallback(
    async (isSupervision = false) => {
      try {
        setBigLoading(true)
        await handleTakenClick(takenModal.data, isSupervision, true)
      } catch (err) {
        console.error(err)
        easyToast(
          'error',
          `Ha ocurrido un error expulsando al ${isSupervision ? 'SUPERVISOR' : 'INSPECTOR'} actual`
        )
      } finally {
        setBigLoading(false)
      }
    },
    [handleTakenClick, takenModal.data]
  )

  const handleInitModalAccept = useCallback(
    async (line, isSupervision = false, paramId, paramLicense) => {
      try {
        setBigLoading(true)
        if (!line && !isSupervision) {
          setModal({ ...modal, error: true })
          return easyToast('error', 'Ha ocurrido un problema seleccionando la línea')
        }
        const id = modal.id ?? paramId
        const license = modal.license ?? paramLicense
        await putInspectionInspector({
          id: id,
          operation: isSupervision ? 'startSupervision' : 'startInspection',
        })
        if (!isSupervision) {
          try {
            await putInspectionLine({ id: id, line })
          } catch (err) {
            console.error(err)
          }
        }
        dispatch(resetInspectionDuck())
        if (isSupervision) initSupervisionSS(Id.encrypt(id), license)
        else initInspectionSS(Id.encrypt(id), line, license)

        await loadReduxData(id)
        history.push(isSupervision ? `/new_supervision/${license}` : `/new_inspection/${license}`)
      } catch (err) {
        console.error(err)
        setModal({ ...modal, show: false })
        easyToast('error', 'Ha ocurrido un error accediento al vehículo. Inténtelo de nuevo')
      } finally {
        setBigLoading(false)
      }
    },
    [
      dispatch,
      history,
      initInspectionSS,
      initSupervisionSS,
      loadReduxData,
      modal,
      putInspectionInspector,
      putInspectionLine,
    ]
  )

  const handleClick = useCallback(
    (data, isSupervision = false) => {
      if (isSupervision) {
        return handleInitModalAccept(null, true, data.idInspeccion, data.vehicleData.license)
      }

      setModal({
        show: true,
        id: data.idInspeccion,
        license: data.vehicleData?.license,
        inspectionType: data.inspeccion,
        isSupervision,
        error: null,
      })
    },
    [handleInitModalAccept]
  )

  const fetchInspections = useCallback(
    async (loadInspection, loadSupervision) => {
      try {
        console.log('Entro fetchInspections: ', { loadInspection, loadSupervision })
        if (loadInspection) setLoadingInspection(true)
        if (loadSupervision) setLoadingSupervision(true)

        const res = await getAllInspections({
          applyInspectionRestrictions: options.inspection.forMe,
          applySupervisionRestriction: options.supervision.forMe,
        })

        const filteredInspections = res.map(ins => {
          try {
            const filterHandler = new Filters({
              filters: redux?.config?.filters,
              category: ins.vehicleData.category,
              classification: ins.vehicleData.classification,
            })
            ins.filter = filterHandler.filter
            return ins
          } catch (err) {
            console.error(err)
            return ins
          }
        })

        setInspections(filteredInspections.filter(elem => elem.estado))
        setSupervisions(filteredInspections.filter(elem => !elem.estado))
        if (!inspectionsLoaded) setInspectionsLoaded(true)
      } catch (err) {
        console.error(err)
        easyToast('error', 'Ha ocurrido un error cargando las inspecciones. Inténtelo de nuevo')
      } finally {
        if (loadInspection) setLoadingInspection(false)
        if (loadSupervision) setLoadingSupervision(false)
      }
    },
    [getAllInspections, options.inspection.forMe, options.supervision.forMe, redux?.config?.filters]
  )

  useEffect(() => {
    if (window.sessionStorage.getItem('justInspected') === 'true') {
      easyToast('dark', 'Inspección enviada a supervisión, seleccione otro vehículo')
      window.sessionStorage.removeItem('justInspected')
    }
    if (!inspectionsLoaded) fetchInspections(true, true)
    if (inspectionsLoaded) {
      const intervalId = setInterval(() => fetchInspections(false, false), 5000000)
      return () => clearInterval(intervalId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchInspections])

  useEffect(() => {
    console.log('Entro useEffect inspection For Me')
    if (inspectionsLoaded) {
      fetchInspections(true, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.inspection.forMe])

  useEffect(() => {
    console.log('Entro supervision inspection For Me')

    if (inspectionsLoaded) {
      fetchInspections(false, true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.supervision.forMe])

  const renderListOfInspections = useCallback(
    (inspections, isSupervision, isLoading) => {
      const filteredInspections = filterInspections(inspections, isSupervision, options)
      const sortedInspections = sortInspections(filteredInspections)
      const thisOptions = isSupervision ? options.supervision : options.inspection
      return (
        <div className="list-inspections__wrapper">
          <div className="list-inspections__hint">
            {isSupervisor && onMobile && (
              <>
                {isSupervision
                  ? 'Desliza a la derecha para ver las inspecciones'
                  : 'Desliza a la izquierda para ver las supervisiones'}
              </>
            )}
          </div>
          <SuperList
            loading={isLoading}
            search={true}
            animationType="slideInDown"
            noItemsMessage="Sin vehículos"
            options={[
              {
                text: 'Para mí',
                onClick: () =>
                  setOptions({
                    ...options,
                    [isSupervision ? 'supervision' : 'inspection']: {
                      ...thisOptions,
                      forMe: !thisOptions?.forMe,
                    },
                  }),
                checked: thisOptions?.forMe,
              },
              {
                text: isSupervision ? 'Sin supervisor' : 'Sin inspector',
                onClick: () =>
                  setOptions(prevState => ({
                    ...prevState,
                    [isSupervision ? 'supervision' : 'inspection']: {
                      ...thisOptions,
                      noUser: !thisOptions?.noUser,
                    },
                  })),
                checked: thisOptions?.noUser,
              },
            ]}
          >
            {sortedInspections.map((ins, i) => {
              const filterName = ins?.filter?.name?.toLowerCase()?.replaceAll(' ', '-')
              const hasStarted = Boolean(
                isSupervision ? ins.tiempos?.supervision_start : ins.tiempos?.inspection_start
              )

              const user = hasStarted
                ? isSupervision
                  ? ins.supervisorData?.usuario_supervisor
                  : ins.inspectorData?.usuario_inspector
                : null
              const yearOfLicenseDate = ins.vehicleData?.licenseDate
                ? new Date(ins.vehicleData.licenseDate).getFullYear()
                : null
              const tags = [
                {
                  text: ins.vehicleData?.fuel1,
                  icon:
                    ins.vehicleData?.fuel1 === 'ELÉCTRICO' ? (
                      <ElectricFuelIcon />
                    ) : ins.vehicleData?.fuel1 === 'DIÉSEL' ? (
                      <DieselFuelIcon />
                    ) : (
                      <GasolineFuelIcon />
                    ),
                },
              ]
              if (user || hasStarted) {
                tags.unshift({
                  text: user || 'En curso...',
                  icon: user ? <UserIcon /> : <ClockIcon />,
                  color: getCssVariable('--error-color'),
                })
              }
              return (
                <ListCard
                  key={i}
                  tags={tags}
                  className={`list-inspections__inspection-card${
                    hasStarted ? '--has-started' : ''
                  }`}
                  date={ins.tiempos?.office_end}
                  formatDateAgo={true}
                  content={
                    <div
                      key={i}
                      className={`list-inspections__inspection`}
                      onClick={() =>
                        hasStarted
                          ? handleTakenClick(ins, isSupervision)
                          : handleClick(ins, isSupervision)
                      }
                    >
                      <div className="list-inspections__license">{ins.vehicleData?.license}</div>
                      <div className="list-inspections__inspection-type">{ins.inspeccion}</div>
                      <div className="list-inspections__filter-name">
                        {ins.filter?.name ?? ''} ({ins.vehicleData?.category}) (
                        {ins.vehicleData?.classification})
                      </div>
                      <div className="list-inspections__brand">
                        {ins.vehicleData?.brand} {ins.vehicleData?.model}{' '}
                        {yearOfLicenseDate ? `(${yearOfLicenseDate})` : ''}
                      </div>

                      {filterName && (
                        <div className="list-inspections__filter-name-icon">
                          <FilterNameIcon type={filterName} />
                        </div>
                      )}
                    </div>
                  }
                />
              )
            })}
          </SuperList>
        </div>
      )
    },
    [handleClick, handleTakenClick, isSupervisor, onMobile, options]
  )

  return (
    <>
      <Spinner visible={bigLoading} />
      <div className="list-inspections">
        <Slider
          detectSlideOnFullContainer={true}
          slidesPerViewOnPc={2}
          autoslideToLastIndex={false}
          centeredSlides={!isSupervisor}
          hidePagination={true}
        >
          <div className="list-inspections__each-slide">
            <Title text="INSPECCIONES" />
            {renderListOfInspections(inspections, false, loadingInspection)}
          </div>
          {isSupervisor && (
            <div className="list-inspections__each-slide">
              <Title text="SUPERVISIONES" />
              {renderListOfInspections(supervisions, true, loadingSupervision)}
            </div>
          )}
        </Slider>
      </div>

      {modal.show && (
        <ItemPicker
          title="Seleccione una línea"
          values={modalValues}
          onAccept={line => handleInitModalAccept(line, modal.isSupervision)}
          onClose={() =>
            setModal({
              show: false,
              id: null,
              license: null,
              inspectionType: null,
              isSupervision: false,
              error: null,
            })
          }
          animate={false}
        />
      )}

      <Modal
        open={takenModal.show}
        onClose={() =>
          setTakenModal({
            show: false,
            data: null,
            inspector: null,
            isSupervision: false,
          })
        }
        title={`${
          takenModal.data?.vehicleData?.license
        } está siendo inspeccionado por ${takenModal?.inspector?.toUpperCase()}`}
        message="¿Entrar a inspeccionar en conjunto?"
        onAccept={() => forceEnterInspection(takenModal.isSupervision)}
      />
    </>
  )
}

function sortInspections(inspections) {
  return inspections.sort((a, b) => {
    const getRelevantStart = obj => {
      if (obj.estado === 1) {
        return obj.tiempos.inspection_start ? new Date(obj.tiempos.inspection_start) : null
      } else {
        return obj.tiempos.supervision_start ? new Date(obj.tiempos.supervision_start) : null
      }
    }

    const relevantStartA = getRelevantStart(a)
    const relevantStartB = getRelevantStart(b)

    if (relevantStartA && !relevantStartB) return -1
    if (!relevantStartA && relevantStartB) return 1
    if (relevantStartA && relevantStartB) {
      if (relevantStartA < relevantStartB) return 1
      if (relevantStartA > relevantStartB) return -1
    }

    const officeEndA = new Date(a.tiempos.office_end)
    const officeEndB = new Date(b.tiempos.office_end)

    if (officeEndA < officeEndB) return 1
    if (officeEndA > officeEndB) return -1
    return 0
  })
}

function filterInspections(inspections, isSupervision, options) {
  try {
    const thisOptions = isSupervision ? options.supervision : options.inspection
    const onlyNoUser = thisOptions?.noUser
    return inspections.filter(ins => {
      if (onlyNoUser) {
        if (isSupervision && ins.supervisorData) return false
        if (!isSupervision && ins.inspectorData) return false
      }

      return true
    })
  } catch (err) {
    console.error(err)
    return inspections
  }
}
