import React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import isValidFunction from 'myMethods/isValidFunction'
import './PopoverMenu.scss'

/**
 *
 * @param {{children?:Component, items:{icon?: any, content: Component, onClick?:function, className?: string, anchorOrigin?: {vertical: "top"|"center"|"bottom", horizontal: "left"|"center"|"right"}}[]}} param0
 * @returns
 */
export default function PopoverMenu({
  children,
  items,
  anchorOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
}) {
  try {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const isOpened = Boolean(anchorEl)

    const handleClick = e => {
      e.preventDefault()
      e.stopPropagation()
      setAnchorEl(e.currentTarget)
    }
    const handleClose = e => {
      e.stopPropagation()
      setAnchorEl(null)
    }

    return (
      <div className="popover-menu">
        {React.cloneElement(children, {
          id: 'basic-button',
          'aria-controls': isOpened ? 'popover-menu' : undefined,
          'aria-haspopup': 'true',
          'aria-expanded': isOpened ? 'true' : undefined,
          onClick: handleClick,
        })}
        <Menu
          id="popover-menu"
          anchorEl={anchorEl}
          open={isOpened}
          onClose={handleClose}
          anchorOrigin={anchorOrigin}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {items.map((item, i) => (
            <MenuItem
              key={i}
              onClick={e => {
                e.stopPropagation()
                isValidFunction(item.onClick) && item.onClick()
                handleClose(e)
              }}
              className={item.className || 'popover-menu__item'}
            >
              {item.icon && item.icon}
              {item.content}
            </MenuItem>
          ))}
        </Menu>
      </div>
    )
  } catch (err) {
    console.error(err)
    return <></>
  }
}
