//@ts-check
import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import 'components/Inspection/Defectology/ManualChapter/ManualChapter.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import isValidFunction from 'myMethods/isValidFunction'
import reduceHigherDefectScore from 'myMethods/reduceHigherDefectScore'

/**
 *
 * @param {{number: string, name: string, manualManager?: import('Share/Defect/InspectionDefectsManager').default, checkIfDone?: boolean, forcedOnClick?: function}} param0
 * @returns
 */
export default function ManualChapter({
  number,
  name,
  manualManager,
  checkIfDone = true,
  forcedOnClick,
}) {
  const location = useLocation()
  const [done, setDone] = useState(false)
  const [score, setScore] = useState(null)
  const history = useHistory()

  useEffect(() => {
    try {
      if (manualManager && checkIfDone) {
        const node = manualManager.findDefectAndChilds(number)
        const childsToShow = node.listToShowThisAndChilds()
        setDone(childsToShow?.length === childsToShow?.filter(child => child.done)?.length)
        const maxScore = reduceHigherDefectScore(childsToShow, 'maxChildrenScore')
        setScore(maxScore)
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error filtrando el manual')
    }
  }, [number, manualManager, checkIfDone])

  return (
    <>
      <div
        className={`manual-chapter contrast-on-hover list-content ${
          score ? `manual-chapter--` + score : ''
        }`}
        onClick={() => {
          if (forcedOnClick && isValidFunction(forcedOnClick))
            return forcedOnClick({ id: number, name })
          history.replace(`${location.pathname}/${number}`)
        }}
      >
        <div className="manual-chapter__number">{number}</div>
        <div className="manual-chapter__name">{name}</div>
        {done && <div className={`manual-chapter__done`}>VISTO</div>}
      </div>
    </>
  )
}
