import React from 'react'
import ErrorView from 'components/Others/ErrorView/ErrorView'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import isValidFunction from 'myMethods/isValidFunction'
import { useDispatch } from 'react-redux'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import './MachineTestRead.scss'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { X as CancelIcon, Check as ReadIcon } from 'react-bootstrap-icons'

/**
 * @typedef {import('types/HardwareConfig').Machine} Machine
 * @typedef {import('types/HardwareStatus').hardwareStatus} HardwareStatus
 */
/**
 *
 * @param {{testNames: string[], state: {machine: Machine, status: HardwareStatus[]}}} param0
 * @returns
 */
export default function MachineTestRead({ testNamesToWorkOn, line, read, abortSend, loading }) {
  const dispatch = useDispatch()
  try {
    return (
      <section className="machine-test-read">
        <div className="machine-test-read__read">
          <>
            {loading && <ChildSpinner visible={true} />}
            {!loading && (
              <>
                {testNamesToWorkOn?.length > 0 && (
                  <h3>
                    EN PROCESO: <span>{testNamesToWorkOn.join(' + ').toUpperCase()}</span>
                  </h3>
                )}
                <h4>
                  DATOS ENVIADOS A MÁQUINA DE <span>LÍNEA {line}</span>
                </h4>
                <h5>Cuando termine de realizar las pruebas, lea los datos...</h5>
                <MyButtonsContainer>
                  <MyButton
                    text="Anular prueba"
                    warningColor={true}
                    icon={<CancelIcon />}
                    onClick={() =>
                      dispatch(
                        setReduxConfigModal({
                          error: true,
                          title: '¿Anular prueba?',
                          alertMessage: 'Los datos que haya recibido la máquina no se borrarán',
                          onAccept: abortSend,
                          hasCloseButton: true,
                        })
                      )
                    }
                  />
                  <MyButton
                    text="Finalizar prueba"
                    onClick={() => isValidFunction(read) && read(read)}
                    icon={<ReadIcon />}
                  />
                </MyButtonsContainer>
              </>
            )}
          </>
        </div>
      </section>
    )
  } catch (error) {
    console.error(error)
    return <ErrorView />
  }
}
