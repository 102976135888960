//@ts-check
import React from 'react'
import {
  ThreeDots,
  Audio,
  BallTriangle,
  Bars,
  Circles,
  Grid,
  Hearts,
  Oval,
  Puff,
  Rings,
  TailSpin,
  Watch,
  RevolvingDot,
  Triangle,
  MutatingDots,
} from 'react-loader-spinner'

// MY IMPORTS
import 'components/Others/Spinner/ChildSpinner.scss'
import AnimatedLogoInfinity from '../AnimatedLogo/AnimatedLogoInfinity'

const loaders = {
  ThreeDots,
  Audio,
  BallTriangle,
  Bars,
  Circles,
  Grid,
  Hearts,
  Oval,
  Puff,
  Rings,
  TailSpin,
  Watch,
  RevolvingDot,
  Triangle,
  MutatingDots,
}

/**
 *
 * @param {{
 *  visible?: boolean,
 *  color?: string,
 *  height?: number,
 *  width?: number,
 *  tight?: boolean,
 * containerWidth?: string,
 * contanerHeight?: string,
 *  text?: string,
 *  icon?: any,
 *  iconSize?: string,
 *  mintLogo?: boolean,
 *  type?: keyof typeof loaders
 * }} param0
 * @returns
 */
export default function ChildSpinner({
  visible = true,
  color = '#9c9c9c',
  height = 100,
  width = 100,
  tight = true,
  containerWidth = '100%',
  contanerHeight = '100%',
  text = '',
  icon = null,
  iconSize = '5rem',
  mintLogo = true,
  type = 'ThreeDots',
  ...rest
}) {
  const LoaderComponent = loaders[type] || ThreeDots

  return (
    <>
      {visible && (
        <div
          style={{
            width: containerWidth,
            height: contanerHeight,
          }}
          className={tight ? 'child-spinner' : 'child-spinner--big'}
          {...rest}
        >
          {icon && (
            <div className="child-spinner__icon" style={{ fontSize: iconSize, color }}>
              {icon}
            </div>
          )}
          {!mintLogo && (
            <LoaderComponent visible={true} color={color} height={height} width={width} />
          )}
          {mintLogo && <AnimatedLogoInfinity relative={false} />}
          {text && <h3>{text}</h3>}
        </div>
      )}
    </>
  )
}
