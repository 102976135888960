import DateTimeITV from './DateTimeITV'

export default class Clock {
  /**
   *
   * @param {string} startTime
   * @returns
   */
  static getElapsedTimeString(startTime) {
    try {
      if (startTime == null) throw new Error('No startTime provided')
      const duration = DateTimeITV.getElapsedTime(startTime)
      let finalString = ''
      if (duration?.days) finalString += `${duration.days}d `
      if (duration?.hours) finalString += `${duration.hours}h `
      if (duration?.minutes) finalString += `${duration.minutes}min `
      if (duration?.seconds) finalString += `${duration.seconds}s`
      return finalString || '0s'
    } catch (err) {
      console.error(err)
      return null
    }
  }
}
