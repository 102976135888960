//@ts-check
import { Delete } from '@mui/icons-material'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import React, { useCallback } from 'react'

/**
 *
 * @param {{values: any[], showAddModal: () => void, deleteDeal: (id: number) => void, onRowClick: (rowData: any) => void, loading: boolean}} param0
 * @returns
 */
export default function ListRectifierDeals({
  values,
  showAddModal,
  deleteDeal,
  onRowClick,
  loading,
}) {
  return (
    <MuiTable
      values={values}
      columnsKey="rectifierDeals"
      loading={loading}
      options={{
        grouping: false,
        selection: false,
        filtering: false,
      }}
      onRowClick={onRowClick}
      showToolbar={false}
      toolbarComponent={
        <MyButtonsContainer fullWidth={false} margin="0" styles={{ width: 'auto' }}>
          <MyButton text="Añadir albarán" onClick={showAddModal} />
        </MyButtonsContainer>
      }
      moreActions={[
        {
          icon: () => <Delete />,
          tooltip: 'Eliminar albarán',
          onClick: useCallback((event, rowData) => deleteDeal(rowData.id), [deleteDeal]),
        },
      ]}
    />
  )
}
