import React from 'react'

// MY IMPORTS
import Vehicles from 'components/Office/Vehicles/Vehicles'
import Title from 'components/Others/Title/Title'

export default function VehiclesPage() {
  return (
    <>
      <Title text="VEHÍCULOS" />
      <Vehicles />
    </>
  )
}
