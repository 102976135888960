//@ts-check
import React, { useState, useImperativeHandle, forwardRef } from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// MY IMPORTS
import './MaterialTab.scss'
import ChildSpinner from '../Spinner/ChildSpinner'
import isValidFunction from 'myMethods/isValidFunction'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

/**
 * MaterialTabComponent renders a tab interface with optional loading indicators and custom tab contents.
 *
 * @param {Object} props The properties passed to the component.
 * @param {Array.<{text: string, counter?: number, onClick?: Function}>} props.tabs An array of objects describing each tab. Each object can have a text, an optional counter, and an optional onClick function.
 * @param {React.ReactNode[]} props.contents An array of React nodes to be rendered in each tab panel corresponding to the tab index.
 * @param {boolean} [props.loading=false] If true, displays loading indicators in the tab and tab contents.
 * @param {string} [props.className] Optional CSS class to apply to the tab container.
 * @param {boolean} [props.scrollButtons=true] If true, scroll buttons are displayed when tabs overflow their container.
 * @param {React.Ref} ref A ref object that allows parent components to access the internal API of this component.
 *
 * @returns {JSX.Element} The rendered component.
 */

const MaterialTabComponent = forwardRef(
  ({ tabs, contents, loading, className, scrollButtons = true }, ref) => {
    const [value, setValue] = useState(0)
    const handleChange = (event, newValue) => {
      setValue(newValue)
    }

    useImperativeHandle(ref, () => ({
      changeTab: index => {
        setValue(index)
      },
    }))

    return (
      <div
        className={`${loading ? 'material-tab-container--loading' : 'material-tab-container'} ${
          className ? className : ''
        }`}
      >
        <div className="material-tab">
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons={scrollButtons}
            >
              {!loading &&
                tabs &&
                tabs.length > 0 &&
                tabs.map((tab, i) => {
                  let text = <div className="material-tab__label">{tab.text}</div>
                  if (tab.counter != null) {
                    text = (
                      <div className="material-tab__label">
                        {tab.text}
                        <div className="material-tab__counter">{tab.counter}</div>
                      </div>
                    )
                  }
                  return (
                    <Tab
                      key={i}
                      label={text}
                      {...a11yProps(i)}
                      onClick={() => isValidFunction(tab.onClick) && tab.onClick()}
                    />
                  )
                })}
              {tabs?.length > 0 && loading && (
                <Tab
                  label={<ChildSpinner height="1.5rem" width="2rem" mintLogo={false} />}
                  {...a11yProps(0)}
                />
              )}
            </Tabs>
          </AppBar>
          {contents &&
            contents.length > 0 &&
            contents.map((content, i) => {
              return (
                <TabPanel value={value} index={i} key={i}>
                  {loading ? <ChildSpinner tight={false} /> : content}
                </TabPanel>
              )
            })}
        </div>
      </div>
    )
  }
)

const MaterialTab = React.memo(MaterialTabComponent)

export default MaterialTab
