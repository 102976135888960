//@ts-check
import React, { useState } from 'react'
import Modal from 'components/Others/Modal/Modal'
import {
  CalendarMonth as CalendarMonthIcon,
  DownloadRounded as ExportIcon,
} from '@mui/icons-material'
import RangeCalendar from 'components/Others/RangeCalendar/RangeCalendar'
import './StatisticsTools.scss'
import ExportationModal from 'components/Others/ExportationModal/ExportationModal'

/**
 * @typedef {import('types/ExportationModal').Exportation} Exportation
 */

/**
 *
 * @param {{otherComponents: any, onAccept: function, loading: boolean, dateRange: [string, string] | [], dateRangeText: string, calendarInfo: any, exportation: Exportation | undefined }} param0
 * @returns
 */
export default function StatisticsTools({
  otherComponents = <></>,
  onAccept,
  loading = false,
  dateRange,
  dateRangeText,
  calendarInfo = <></>,
  exportation,
}) {
  const [showModal, setShowModal] = useState(false)
  const [selectedDateRange, setSelectedDateRange] = useState(dateRange || [])
  const [showExportModal, setShowExportModal] = useState(false)
  return (
    <div className="statistics__tools">
      <>
        {exportation && (
          <div className="statistics-tools__export" onClick={() => setShowExportModal(true)}>
            <ExportIcon />
            <span>Exportar</span>
          </div>
        )}
        <div className="statistics-tools__date-range" onClick={() => setShowModal(true)}>
          <CalendarMonthIcon />
          <span>{dateRangeText || 'Seleccione una fecha'}</span>
        </div>
        {otherComponents}
      </>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        loading={loading}
        onAccept={() => onAccept(selectedDateRange)}
        title="Seleccione un rango de fechas"
        content={
          <>
            <RangeCalendar
              returnValuesInObject={false}
              onChange={setSelectedDateRange}
              onClear={() => setSelectedDateRange(null)}
              dates={dateRange}
            />
            {calendarInfo}
          </>
        }
      />
      {showExportModal && (
        <ExportationModal
          show={true}
          closeModal={() => setShowExportModal(false)}
          data={exportation?.data}
          type={exportation?.type}
          defaultInputValue={exportation?.defaultInputValue}
          options={exportation?.options}
          pdf={exportation?.pdf}
          csv={exportation?.csv}
          modalTitle={exportation?.modalTitle}
        />
      )}
    </div>
  )
}
