//@ts-check
import React, { useEffect, useRef } from 'react'
import CashRegisterArching from './CashRegisterArching/CashRegisterArching'
import ListCashRegisterMovements from './ListCashRegisterMovements/ListCashRegisterMovements'
import MaterialTab from 'components/Others/MaterialTab/MaterialTab'
import CashFlows from './CashFlows/CashFlows'
import MyArchings from './MyArchings/MyArchings'
import CashRegisterSelector from './CashRegisterSelector/CashRegisterSelector'
import EndOfDay from './EndOfDay/EndOfDay'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import {
  setReduxCashRegisterStatus,
  setReduxSelectedCashRegister,
} from 'redux/ducks/cashRegisterDuck'
import { getCashRegisterStatus as getCashRegisterStatusService } from 'services/CashRegister'
import useService from 'hooks/useService'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'

export default function CashRegister({ redux }) {
  const status = redux?.cashRegister?.cashSelectorValues?.id
    ? redux?.cashRegister?.selectedCashRegister
    : redux?.cashRegister?.status
  const isChanging = redux?.cashRegister?.isChanging
  const isItvSelected = Boolean(status?.name === 'ITV')
  const isFetching = redux?.cashRegister?.fetchingData
  const dispatch = useDispatch()
  const selectedDate =
    redux?.cashRegister?.cashSelectorValues?.date || moment().format('YYYY-MM-DD')
  const refetch = () => {
    dispatch(setReduxCashRegisterStatus(null))
    dispatch(setReduxSelectedCashRegister(null))
  }

  const tabRef = useRef(null)

  const selectedDateIsToday = () => moment(selectedDate).isSame(moment(), 'day')

  const { fetch: getCashRegisterStatus, loading } = useService({
    service: getCashRegisterStatusService,
  })

  const tabs = [
    {
      text: `Arqueo${
        selectedDateIsToday()
          ? ''
          : ' del ' + moment(status?.archingState?.date).format('DD/MM/YYYY')
      }`,
    },
    {
      text: `Arqueos ${
        selectedDateIsToday()
          ? 'de hoy'
          : 'del ' + moment(status?.archingState?.date).format('DD/MM/YYYY')
      }`,
    },
    { text: 'Movimiento manual' },
  ]
  if (isItvSelected && !tabs.some(elem => elem.text === 'Fin de día')) {
    tabs.splice(1, 0, {
      text: `Fin de día ${
        selectedDateIsToday()
          ? 'de hoy'
          : 'del ' + moment(status?.archingState?.date).format('DD/MM/YYYY')
      }`,
    })
  }

  const contents = [
    <>
      <CashRegisterArching
        status={status}
        refetch={refetch}
        isFetching={isFetching}
        isChanging={isChanging}
        selectedDate={selectedDate}
        selectedDateIsToday={selectedDateIsToday()}
      />
      {status && Object.keys(status).length > 0 && !isChanging && !isFetching && (
        <ListCashRegisterMovements status={status} selectedDate={selectedDate} />
      )}
    </>,
    <MyArchings key={1} status={status} refetch={refetch} isChanging={isChanging} />,
    <CashFlows
      key={2}
      status={status}
      refetch={refetch}
      isChanging={isChanging}
      selectedDate={selectedDate}
      selectedDateIsToday={selectedDateIsToday()}
    />,
  ]

  const contentsEnd = [
    <>
      <CashRegisterArching
        status={status}
        refetch={refetch}
        isFetching={isFetching}
        isChanging={isChanging}
        selectedDate={selectedDate}
        selectedDateIsToday={selectedDateIsToday()}
      />
      {status && Object.keys(status).length > 0 && !isChanging && !isFetching && (
        <ListCashRegisterMovements status={status} selectedDate={selectedDate} />
      )}
    </>,
    <EndOfDay
      key={1}
      status={status}
      date={redux?.cashRegister?.cashSelectorValues?.date}
      isChanging={isChanging}
    />,
    <MyArchings status={status} isChanging={isChanging} key={2} refetch={refetch} />,
    <CashFlows
      key={3}
      status={status}
      refetch={refetch}
      isChanging={isChanging}
      selectedDate={selectedDate}
      selectedDateIsToday={selectedDateIsToday()}
    />,
  ]

  useEffect(() => {
    getCashRegisterStatus({}).then(res => dispatch(setReduxCashRegisterStatus(res)))
  }, [dispatch, getCashRegisterStatus])
  return (
    <>
      {redux.user.data?.role === 'ACCOUNTANT' && (
        <CashRegisterSelector
          cashRegister={redux?.cashRegister}
          goToInitialTab={() => tabRef?.current?.changeTab(0)}
        />
      )}
      <MaterialTab
        ref={tabRef}
        tabs={tabs}
        contents={
          isChanging
            ? Array.from({ length: contentsEnd.length }, (_, index) => (
                <ChildSpinner
                  key={index}
                  mintLogo={true}
                  text="Esperando a que seleccione una caja"
                />
              ))
            : isItvSelected
            ? contentsEnd
            : contents
        }
        loading={loading}
      />
    </>
  )
}
