import { createTheme } from '@mui/material'
import getCssVariable from 'myMethods/getCssVariable'

const materialTheme = createTheme({
  palette: {
    primary: {
      main: `${getCssVariable('--main-color') || '#5fa186'} !important`,
    },
    secondary: {
      main: '#333333',
    },
  },
})

export default materialTheme
