import React, { useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import './Input.scss'

/**
 * A reusable input component that supports various types of inputs including text, password, textarea, and number.
 * It can be used with or without Formik integration.
 *
 * @param {Object} props - The props for the input component.
 * @param {string} [props.className='form-input'] - Additional class names for the input element.
 * @param {string=} props.id - The ID for the input element.
 * @param {string} [props.type='text'] - The type of the input element.
 * @param {string} [props.label=''] - The label for the input element.
 * @param {string=} props.name - The name attribute for the input element, can be undefined if not using Formik.
 * @param {string} [props.placeholder=null] - The placeholder text for the input element.
 * @param {boolean} [props.disabled=false] - Whether the input is disabled.
 * @param {string} [props.styleVariant='standard'] - The variant style of the input element.
 * @param {boolean} [props.isFormikInput=true] - Whether the input is used within Formik.
 * @param {number} [props.textAreaMaxLength=100] - The maximum length for textarea input.
 * @param {ReactNode} [props.icon] - An icon to be displayed at the start of the input.
 * @param {string} [props.unit] - A unit to be displayed at the end of the input.
 * @param {any} [props.value] - The value of the input element.
 * @param {function} [props.onChange] - The change handler for the input element.
 * @param {Object} [props.style] - Additional styles for the input element.
 * @param {{onClick: function, content: any}=} [props.actionButton] - An action button to be displayed at the end of the input.
 * @param {Object} rest - Additional props to be passed to the input element.
 * @returns {JSX.Element} The rendered input component.
 */
export default function Input({
  className = 'form-input',
  id,
  type = 'text',
  label = '',
  name,
  placeholder = null,
  disabled = false,
  styleVariant = 'standard',
  isFormikInput = true,
  textAreaMaxLength,
  icon,
  unit,
  value,
  onChange,
  style,
  actionButton,
  ...rest
}) {
  const [showPassword, setShowPassword] = useState(false)

  if (type === 'textarea') styleVariant = 'outlined'

  const handleWheel = event => {
    if (type === 'number') {
      event.target.blur()
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword)
  }

  const getType = () => {
    if (type === 'password' && showPassword) return 'text'
    return type
  }

  const getInputAdornmentStart = () => {
    if (actionButton) {
      return (
        <InputAdornment position="start">
          <IconButton
            onClick={actionButton.onClick}
            onMouseDown={event => event.preventDefault()}
            edge="start"
          >
            {actionButton.content}
          </IconButton>
        </InputAdornment>
      )
    }
    if (icon) {
      return <InputAdornment position="start">{icon}</InputAdornment>
    }
    return null
  }

  const getInputAdornmentEnd = () => {
    if (unit) return <InputAdornment position="end">({unit})</InputAdornment>
    if (type === 'password') {
      return (
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={togglePasswordVisibility}
            onMouseDown={event => event.preventDefault()}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      )
    }
    return null
  }

  if (!isFormikInput)
    return (
      <div
        className={`${className} input__${type} ${value != null ? className + '--has-value' : ''}`}
        id={id ?? name}
      >
        <TextField
          {...rest}
          variant={styleVariant}
          type={getType()}
          label={label}
          id={id ?? name}
          placeholder={placeholder}
          disabled={disabled}
          value={value}
          onChange={onChange}
          multiline={type === 'textarea'}
          rows={type === 'textarea' ? 4 : undefined}
          InputLabelProps={{ shrink: true }}
          inputProps={{
            maxLength: type === 'textarea' ? textAreaMaxLength : undefined,
            tabIndex: 0,
          }}
          onWheel={type === 'number' ? handleWheel : undefined}
          InputProps={{
            startAdornment: getInputAdornmentStart(),
            endAdornment: getInputAdornmentEnd(),
          }}
          style={style}
        />
      </div>
    )

  return (
    <div className={`${className} input__${type}`} id={id ?? name}>
      <Field name={name}>
        {({ field, meta }) => (
          <TextField
            {...field}
            {...rest}
            variant={styleVariant}
            type={getType()}
            label={label}
            id={id ?? name}
            placeholder={placeholder}
            disabled={disabled}
            multiline={type === 'textarea'}
            rows={type === 'textarea' ? 4 : undefined}
            error={!!meta.error}
            helperText={
              <ErrorMessage name={name}>
                {msg => {
                  if (!msg || JSON.stringify(msg) === '{}') return null
                  return <small className="error">{msg}</small>
                }}
              </ErrorMessage>
            }
            InputLabelProps={{ shrink: true }}
            inputProps={{
              maxLength: type === 'textarea' ? textAreaMaxLength : undefined,
            }}
            onWheel={type === 'number' ? handleWheel : undefined}
            InputProps={{
              startAdornment: getInputAdornmentStart(),
              endAdornment: getInputAdornmentEnd(),
              tabIndex: 0,
            }}
            style={style}
          />
        )}
      </Field>
    </div>
  )
}
