export default function formatIban(iban) {
  try {
    return iban
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
  } catch (err) {
    console.error(err)
    return ''
  }
}
