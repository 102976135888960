import React from 'react'
import './TablePagination.scss'

export default function TablePagination({ currentDataLength, totalDataLength, onClick }) {
  const hasToRender = Boolean(
    currentDataLength > 0 && totalDataLength > 0 && currentDataLength < totalDataLength
  )
  console.log({ hasToRender })
  console.log(currentDataLength, totalDataLength)
  try {
    return (
      <>
        {hasToRender && (
          <div className="table-pagination">
            <div className="table-pagination__text">
              Viendo <span>{currentDataLength}</span> de <span>{totalDataLength}</span>
            </div>
            <div
              className="table-pagination__btn"
              onClick={() => {
                onClick && typeof onClick === 'function' && onClick()
              }}
            >
              Pedir más
            </div>
          </div>
        )}
      </>
    )
  } catch (err) {
    console.error(err)
    return <></>
  }
}
