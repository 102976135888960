//@ts-check
import React, { useRef, useCallback, useMemo, useEffect } from 'react'
import Modal from 'components/Others/Modal/Modal'
import { Form, Formik, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { putInspectionCalculations as putInspectionCalculationsService } from 'services/Inspection'
import useService from 'hooks/useService'
import isValidFunction from 'myMethods/isValidFunction'
import Id from 'Share/Id'
import easyToast from 'components/Others/EasyToast/easyToast'
import round from 'myMethods/round'
import ToggleButtons from 'components/Others/Buttons/ToggleButtons/ToggleButtons'
import Input from 'components/Others/Input/Input'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import './FiscalHorsepower.scss'
import Switch from 'components/Others/Switch/Switch'

const CalcResults = () => {
  const { values, setFieldValue } = useFormikContext()

  const calcularCilindrada = (diametro, carrera, cilindros) => {
    try {
      return round((Math.PI * Math.pow(diametro / 2, 2) * carrera * cilindros) / 1000, 0)
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const calcularPotenciaFiscalPorCilindrada = (cilindrada, cilindros, es2tiempos) => {
    try {
      return round(Math.pow(cilindrada / cilindros, 0.6) * cilindros * (es2tiempos ? 0.11 : 0.08))
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const calcularPotenciaElectricos = potenciaEfectiva => {
    try {
      return round(potenciaEfectiva / 5.152)
    } catch (err) {
      console.error(err)
      return null
    }
  }

  useEffect(() => {
    console.log('Entro useEffect')
    if (values.tipoMotor === '2 tiempos' || values.tipoMotor === '4 tiempos') {
      if (!values.porCilindrada && values.diametro && values.carrera && values.cilindros) {
        const cilindrada = calcularCilindrada(values.diametro, values.carrera, values.cilindros)
        setFieldValue('cilindrada', cilindrada)
      } else if (!values.porCilindrada) {
        setFieldValue('cilindrada', '')
      }

      if (values.cilindrada && values.cilindros) {
        const potencia = calcularPotenciaFiscalPorCilindrada(
          values.cilindrada,
          values.cilindros,
          values.tipoMotor === '2 tiempos'
        )
        setFieldValue('results.potencia', potencia)
      } else {
        setFieldValue('results.potencia', '')
      }
    } else if (values.tipoMotor === 'eléctricos o rotativos') {
      if (values.potenciaEfectiva) {
        const potencia = calcularPotenciaElectricos(values.potenciaEfectiva)
        setFieldValue('results.potencia', potencia)
      } else {
        setFieldValue('results.potencia', '')
      }
    }
  }, [
    setFieldValue,
    values.carrera,
    values.cilindrada,
    values.cilindros,
    values.diametro,
    values.porCilindrada,
    values.potenciaEfectiva,
    values.tipoMotor,
  ])

  return null
}

const initialValues = {
  tipoMotor: null,
  porCilindrada: false,
  cilindros: '',
  diametro: '',
  carrera: '',
  cilindrada: '',
  potenciaEfectiva: '',
  results: {
    potencia: '',
  },
}

export default function FiscalHorsepower({ closeModal }) {
  const formRef = useRef()
  const inspectionCalculations = useSelector(store => store.inspection.calculations)

  const { fetch: putCalculations, loading } = useService({
    service: putInspectionCalculationsService,
  })

  const handleInitialValues = useCallback(() => {
    if (inspectionCalculations?.fiscalHorsepower) return inspectionCalculations.fiscalHorsepower

    return initialValues
  }, [inspectionCalculations?.fiscalHorsepower])

  const formikInitialValues = useMemo(() => handleInitialValues(), [handleInitialValues])

  const persistValues = async shouldCloseModal => {
    try {
      const values = formRef.current.values
      let newInspectionCalculations = {
        ...(inspectionCalculations ?? {}),
        fiscalHorsepower: values,
      }
      await putCalculations({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        calculations: newInspectionCalculations,
      })
      if (shouldCloseModal && isValidFunction(closeModal)) closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error al guardar los valores')
    }
  }

  return (
    <Modal
      open={true}
      closeOnOverlay={false}
      closeAfterAccept={false}
      title="Potencia fiscal"
      onAccept={() => persistValues(true)}
      loading={loading}
      onClose={closeModal}
      content={
        <div className="fiscal-horsepower">
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={formikInitialValues}
            onSubmit={() => {}}
          >
            {({ values, resetForm }) => (
              <Form>
                <CalcResults />
                <ToggleButtons
                  label="Tipo de motor"
                  selectedValue={values.tipoMotor}
                  handleChange={value => {
                    resetForm({
                      values: {
                        ...initialValues,
                        tipoMotor: value,
                        porCilindrada: values.porCilindrada,
                      },
                    })
                  }}
                  buttons={[
                    { text: '2 tiempos', value: '2 tiempos' },
                    { text: '4 tiempos', value: '4 tiempos' },
                    { text: 'Eléctrico o rotativo', value: 'eléctricos o rotativos' },
                  ]}
                />
                {values.tipoMotor != null && (
                  <div className="fiscal-horsepower__form">
                    <div className="fiscal-horsepower__inputs-box">
                      <div className="fiscal-horsepower__title">
                        Potencia fiscal para motores {values.tipoMotor}
                      </div>
                      {(values.tipoMotor === '2 tiempos' || values.tipoMotor === '4 tiempos') && (
                        <div className="fiscal-horsepower__inputs">
                          <Switch
                            label="Diámetro y carrera"
                            label2="Cilindrada"
                            onChange={checked => {
                              resetForm({
                                values: {
                                  ...initialValues,
                                  tipoMotor: values.tipoMotor,
                                  porCilindrada: checked,
                                },
                              })
                            }}
                            value={values.porCilindrada}
                          />
                          <Input name="cilindros" label="Nº de cilindros" />
                          {!values.porCilindrada && (
                            <>
                              <Input name="diametro" label="Diámetro" unit="mm" />
                              <Input name="carrera" label="Carrera" unit="mm" />
                              <Input
                                name="cilindrada"
                                label="Cilindrada"
                                unit="cc"
                                disabled={true}
                              />
                            </>
                          )}
                          {values.porCilindrada && (
                            <>
                              <Input name="cilindrada" label="Cilindrada" unit="cc" />
                            </>
                          )}
                        </div>
                      )}
                      {values.tipoMotor === 'eléctricos o rotativos' && (
                        <div className="fiscal-horsepower__inputs">
                          <Input name="potenciaEfectiva" label="Potencia efectiva" unit="Kw" />
                        </div>
                      )}
                      <div className={`fiscal-horsepower__result`}>
                        <CardNameValue
                          name="Potencia fiscal"
                          value={values.results.potencia}
                          unit="CVF"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  )
}
