import Accordion from 'components/Others/Accordion/Accordion'
import Modal from 'components/Others/Modal/Modal'
import React, { useState, useCallback } from 'react'
import './FiltersModal.scss'
import { Tune, Edit } from '@mui/icons-material'
import EditFilterDefectModal from './EditFilterDefectModal/EditFilterDefectModal'
import { getFiltersFile, putFiltersFile } from 'services/Files'
import { setReduxConfigFilters } from 'redux/ducks/configDuck'
import { useDispatch } from 'react-redux'
import easyToast from 'components/Others/EasyToast/easyToast'
import EditFilterCategoriesModal from './EditFilterCategoriesModal/EditFilterCategoriesModal'
import EditFilterClassificationsModal from './EditFilterClassificationsModal/EditFilterClassificationsModal'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'
import useInspectionManual from 'hooks/useInspectionManual'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'

export default function FiltersModal({ closeModal, filters }) {
  const [loading, setLoading] = useState(false)
  const [modalsState, setModalsState] = useState({
    newDefect: false,
    categories: false,
    classifications: false,
    filter: null,
  })
  const [editDefectModal, setEditDefectModal] = useState({
    show: false,
    defectId: null,
    filter: null,
  })
  const [openAccordion, setOpenAccordion] = useState(null)
  const dispatch = useDispatch()

  const updateFiltersOnDb = useCallback(
    async filter => {
      try {
        if (!filter) throw new Error('Missing filter in updateFilterOnDb')
        if (
          !(
            filter.name &&
            filter.categories?.length &&
            filter.classifications?.length &&
            filter.section &&
            filter.defects?.length
          )
        )
          throw new Error('Filter format is not correct')
        console.log({ filter })
        setLoading(true)
        const res = await putFiltersFile(filter)
        dispatch(setReduxConfigFilters(res?.filters || null))
        easyToast('dark', 'Filtro actualizado')
      } catch (err) {
        console.error(err)
        easyToast(
          'error',
          'Ha ocurrido un error actualizando los cambios en el filtro. Inténtelo de nuevo'
        )
      } finally {
        setLoading(false)
        setModalsState({
          newDefect: false,
          categories: false,
          classifications: false,
          filter: null,
        })
        if (editDefectModal?.show) {
          setEditDefectModal({ show: false, defectId: false, filter: null })
        }
      }
    },
    [dispatch, editDefectModal?.show]
  )

  const FilterDetails = React.memo(({ thisFilter }) => {
    const { inspectionManual, loading: loadingInspectionManual } = useInspectionManual(
      thisFilter.section
    )
    if (loadingInspectionManual) return <ChildSpinner tight={false} />
    const defects = inspectionManual?.getOptionalDefects(thisFilter.defects)
    const defectsBreakpoint = Math.round(defects?.length / 2)
    try {
      return (
        <div className="filters-modal__details">
          <div className="filters-modal__main-cards">
            <div className="filters-modal__details-card">
              <span>Categorías</span>
              <div>{thisFilter.categories?.join(', ') || 'Sin categorías'}</div>
              <div
                className="filters-modal__btn"
                onClick={() =>
                  setModalsState({ ...modalsState, categories: true, filter: thisFilter })
                }
              >
                <HoverTooltip title="Editar categorías">
                  <Edit />
                </HoverTooltip>
              </div>
            </div>
            <div className="filters-modal__details-card">
              <span>Clasificaciones</span>
              <div>
                {thisFilter.classifications
                  ?.map((cl, i) => (cl.length > 2 ? cl : cl + 'xx'))
                  ?.join(', ') || 'Sin clasificaciones'}
              </div>
              <div
                className="filters-modal__btn"
                onClick={() =>
                  setModalsState({ ...modalsState, classifications: true, filter: thisFilter })
                }
              >
                <HoverTooltip title="Editar clasificaciones">
                  <Edit />
                </HoverTooltip>
              </div>
            </div>
            <div className="filters-modal__details-card">
              <span>Sección Manual ITV</span>
              <div className="filters-modal__section">{thisFilter.section || 'Sin sección'}</div>
            </div>
          </div>
          <div className="filters-modal__details-card">
            <span>Defectos</span>
            <span>Pulsa un defecto para editarlo</span>
            <div
              className="filters-modal__btn"
              onClick={() => setModalsState({ ...modalsState, defects: true, filter: thisFilter })}
            >
              <HoverTooltip title="Añadir defecto">
                <i className="flaticon-plus" />
              </HoverTooltip>
            </div>
            <div className="filters-modal__defects">
              {defects?.length > 0 &&
                defects?.map((def, i) => {
                  return (
                    <div
                      key={i}
                      className={`filters-modal__defect${
                        def.causes ? ' filters-modal__defect--uneditable' : ''
                      }`}
                      style={{ gridColumnStart: i < defectsBreakpoint ? 1 : 2 }}
                      onClick={() => {
                        if (def.causes)
                          return easyToast('error', 'Este defecto no se puede editar o modificar')
                        setEditDefectModal({ show: true, defectId: def.id, filter: thisFilter })
                      }}
                    >
                      <div>{def.id}</div>
                      <div className="filters-modal__defect-name">{def.name}</div>
                      {def.optional && !def.causes && (
                        <div
                          className={
                            def.initialValue
                              ? 'filters-modal__defect-optional'
                              : 'filters-modal__defect-optional--false'
                          }
                        >
                          <HoverTooltip
                            title={`Defecto opcional ${
                              def.initialValue ? 'activo' : 'no activo'
                            } por defecto`}
                          >
                            <Tune />
                          </HoverTooltip>
                        </div>
                      )}
                    </div>
                  )
                })}
              {!(defects?.length > 0) && (
                <div className="no-values">Vaya... aún no hay defectos para este filtro</div>
              )}
            </div>
          </div>
        </div>
      )
    } catch (err) {
      console.error(err)
      return <></>
    }
  })

  return (
    <>
      <Modal
        open={true}
        hasCloseButton={false}
        loading={loading}
        onClose={closeModal}
        title="Filtros de inspección"
        message={filters && filters.length > 0 ? filters.length + ' filtros' : null}
        content={
          <>
            {(!filters || !filters.length > 0) && (
              <div className="no-values">Ha ocurrido un error cargando el filtro</div>
            )}
            {filters && filters.length > 0 && (
              <div className="filters-modal">
                <h4>
                  El estado de estos filtros influyen directamente en el funcionamiento de la
                  inspección y el informe. Realice los cambios con máxima atención
                </h4>
                {filters.map((elem, i) => (
                  <Accordion
                    title={elem.name}
                    key={i}
                    elemKey={i}
                    initiallyOpen={openAccordion}
                    setOpenIndex={index => setOpenAccordion(index)}
                    // eslint-disable-next-line react/no-children-prop
                    children={<FilterDetails thisFilter={elem} />}
                  />
                ))}
              </div>
            )}
          </>
        }
      />
      {modalsState?.defects && (
        <EditFilterDefectModal
          filter={modalsState?.filter}
          closeModal={() => setModalsState({ ...modalsState, defects: false })}
          saveChanges={updateFiltersOnDb}
        />
      )}
      {editDefectModal?.show && (
        <EditFilterDefectModal
          filter={editDefectModal.filter}
          edit={true}
          defectId={editDefectModal.defectId}
          closeModal={() => setEditDefectModal({ show: false, defectId: null, filter: null })}
          saveChanges={updateFiltersOnDb}
        />
      )}
      {modalsState?.categories && (
        <EditFilterCategoriesModal
          allFilters={filters}
          filter={modalsState?.filter}
          saveChanges={updateFiltersOnDb}
          closeModal={() => setModalsState({ ...modalsState, categories: false, filter: null })}
        />
      )}
      {modalsState?.classifications && (
        <EditFilterClassificationsModal
          allFilters={filters}
          filter={modalsState?.filter}
          saveChanges={updateFiltersOnDb}
          closeModal={() =>
            setModalsState({ ...modalsState, classifications: false, filter: null })
          }
        />
      )}
    </>
  )
}
