import React from 'react'

// MY IMPORTS
import 'components/Inspection/Check/Traceability/Traceability.scss'
import Accordion from 'components/Others/Accordion/Accordion'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'

/**
 *
 * @param {{traceability: import('types/Traceability').TraceabilityChapter[]}} param0
 * @returns
 */
export default function Traceability({ traceability, ...rest }) {
  console.log({ traceability })
  return (
    <>
      <section className="traceability" {...rest}>
        <h3>TRAZABILIDAD</h3>
        {!(traceability?.length > 0) && <ChildSpinner visible={true} />}
        {traceability?.length > 0 && (
          <div>
            {traceability.map((chapter, i) => {
              const mustBeSeenChapter = chapter.childs.some(child => child.apply && !child.user)

              return (
                <Accordion
                  key={i}
                  title={
                    <div
                      className={
                        mustBeSeenChapter
                          ? `traceability__parent-container traceability__parent-container--error`
                          : `traceability__parent-container`
                      }
                    >
                      <div className="traceability__id">{chapter.id}</div>
                      <div className="traceability__name">{chapter.name.toUpperCase()}</div>
                    </div>
                  }
                  elemKey={chapter?.id}
                  // eslint-disable-next-line react/no-children-prop
                  children={chapter.childs.map((child, i) => {
                    const mustBeSeenChild = child.apply
                    const getClassName = () => {
                      if (child.apply && child.user)
                        return 'traceability__child-container traceability__child-container--success'
                      if (mustBeSeenChild && !child.user)
                        return 'traceability__child-container traceability__child-container--error'

                      return 'traceability__child-container'
                    }
                    return (
                      <div className={getClassName()} key={i}>
                        <div className="traceability__id">{child.id}</div>
                        <div className="traceability__name">{child.name.toUpperCase()}</div>
                        <div className="traceability__detail">
                          {mustBeSeenChild ? (child.user ? child.user : 'x') : child.user || 'N/A'}
                        </div>
                      </div>
                    )
                  })}
                />
              )
            })}
          </div>
        )}
      </section>
    </>
  )
}
