//@ts-check
import easyToast from 'components/Others/EasyToast/easyToast'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import formatEuro from 'myMethods/formatEuro'
import React, { useEffect } from 'react'
import { useState } from 'react'
import StatisticsListCard from '../Share/StatisticsListCard/StatisticsListCard'
import StatisticsPercentageCard from '../Share/StatisticsPercentageCard/StatisticsPercentageCard.js'
import StatisticsSummaryCard from '../Share/StatisticsSummaryCard/StatisticsSummaryCard'
import StatisticsHandler from '../StatisticsHandler'
import './StatisticsSummary.scss'

/**@param {{statisticsHandler: StatisticsHandler}} param0 */
export default function StatisticsSummary({ statisticsHandler }) {
  const statisticsSummary = statisticsHandler?.summary
  const [loading, setLoading] = useState(true)
  console.log({ statisticsHandler })
  const sales = {
    thisMonth: statisticsHandler?.summary?.billing?.thisMonth?.totalRange?.amount ?? 0,
    today: statisticsHandler?.summary?.billing?.today?.amount ?? 0,
    diff: statisticsHandler?.summary?.billing?.diffBetweenThisAndLastMonth,
  }

  const inspections = {
    thisMonth: statisticsHandler?.summary?.inspection?.thisMonth?.totalRange?.total ?? 0,
    today: statisticsHandler?.summary?.inspection?.today?.total ?? 0,
    diff: statisticsHandler?.summary?.inspection?.diffBetweenThisAndLastMonth,
  }

  useEffect(() => {
    const abortController = new AbortController()
    try {
      if (statisticsHandler && !statisticsSummary?.initialized) {
        const initialize = async () => {
          setLoading(true)
          await statisticsHandler.initializeSummary()
          setLoading(false)
        }
        initialize()
      } else if (statisticsHandler) {
        setLoading(false)
      } else {
        setLoading(true)
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos')
      setLoading(false)
    }
    return () => abortController.abort()
  }, [statisticsHandler, setLoading, statisticsSummary?.initialized])

  if (loading) {
    return <ChildSpinner text="Cargando resumen del mes..." mintLogo={true} />
  }

  return (
    <section className="statistics-summary">
      <StatisticsSummaryCard
        title="VENTAS"
        primaryValue={sales.thisMonth}
        primaryLabel="este mes"
        secondaryValue={sales.today}
        secondaryLabel="hoy"
        diffValue={sales.diff}
        diffLabel="respecto al mes pasado"
        unit="€"
        data-aos="fade-up"
      />
      <StatisticsListCard
        title="TOP 10 CLIENTES"
        items={statisticsSummary?.topClients?.thisMonth?.map(client => ({
          name: `${client.name} - ${client.dni}`,
          value: client.amount,
        }))}
        unit="€"
        data-aos="fade-up"
      />
      <StatisticsListCard
        title="TOP 10 USUARIOS INTERVINIENTES"
        items={statisticsSummary?.billing?.topUsers?.map(user => ({
          name: user.username,
          value: user.amount,
        }))}
        unit="€"
        data-aos="fade-up"
      />
      <StatisticsSummaryCard
        title="INSPECCIONES"
        primaryValue={inspections.thisMonth}
        primaryLabel="este mes"
        secondaryValue={inspections.today}
        secondaryLabel="hoy"
        diffValue={inspections.diff}
        diffLabel="respecto al mes pasado"
        data-aos="fade-up"
      />
      <StatisticsListCard
        title="TOP 10 TIPOS DE INSPECCIONES"
        items={statisticsSummary?.inspection?.topByInspectionType?.map(insp => ({
          name: insp.type,
          value: insp.total,
        }))}
        data-aos="fade-up"
      />
      <StatisticsListCard
        title="TOP 10 CATEGORÍAS"
        items={statisticsSummary?.inspection?.topByCategory?.map(insp => ({
          name: insp.category,
          value: insp.total,
        }))}
        data-aos="fade-up"
      />
      {statisticsSummary.inspection.thisMonth?.totalRange?.rejectionRate != null && (
        <StatisticsPercentageCard
          title="ÍNDICE DESFAVORABLES"
          value={statisticsSummary.inspection.thisMonth.totalRange.rejectionRate}
          data-aos="fade-up"
        />
      )}
    </section>
  )
}
