import easyToast from 'components/Others/EasyToast/easyToast'

export default async function downloadFile(data, name, datatype) {
  try {
    let url = null
    switch (datatype) {
      case 'base64': {
        const blob = await fetch(data).then(res => res.blob())
        url = URL.createObjectURL(blob)
        break
      }
      case 'blob':
        url = URL.createObjectURL(data)
        break
      case 'string':
        url = 'data:text/plain;charset=utf-8,' + encodeURIComponent(data)
        break
      case 'url':
        url = data
        break
      default:
        throw new Error('Datatype missing in downloadFile function')
    }
    if (!url) throw new Error('No url generated in downloadFile function')
    var a = document.createElement('a')
    document.body.appendChild(a)
    a.style = 'display: none'
    a.href = url
    a.download = name || 'archivo'
    a.click()
    window.URL.revokeObjectURL(url)
    easyToast('dark', 'Archivo descargado')
  } catch (err) {
    console.error(err)
  }
}
