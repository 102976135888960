import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { EyeFill as SeeIcon } from 'react-bootstrap-icons'
import 'components/Inspection/Banner/Banner.scss'
import Modal from 'components/Others/Modal/Modal'
import DefectsSupervisor from '../DefectsSupervisor/DefectsSupervisor'
import InspectionFloatingButton from '../InspectionFloatingButton/InspectionFloatingButton'

export default function Banner() {
  const redux = useSelector(store => store)
  const inspection = redux.inspection
  const isSupervisor = redux.user.data?.role === 'SUPERVISOR'
  const { machineDefects, visualDefects } = inspection
  const previousInspectionMachineDefects = inspection?.previousInspection?.machineDefects
  const previousInspectionVisualDefects = inspection?.previousInspection?.visualDefects
  const [modal, setModal] = useState(false)

  const currentInspectionDefects = [...(visualDefects || []), ...(machineDefects || [])]
  const previousInspectionDefects = [
    ...(previousInspectionVisualDefects || []),
    ...(previousInspectionMachineDefects || []),
  ]

  const currentInspectionLength = currentInspectionDefects.length

  const previousInspectionLength = previousInspectionDefects.length

  const priority = {
    L: 1,
    G: 2,
    VG: 3,
  }

  const currentInspectionMaxCalificationDefect = currentInspectionDefects.reduce((max, def) => {
    return priority[def.values?.calification ?? def.calification] >
      priority[max.values?.calification ?? max.calification]
      ? def
      : max
  }, currentInspectionDefects[0])
  const currentInspectionMaxCalification =
    currentInspectionMaxCalificationDefect?.values?.calification ??
    currentInspectionMaxCalificationDefect?.calification
  return (
    <>
      <InspectionFloatingButton />
      <div
        className="banner"
        style={{
          gridTemplateColumns:
            previousInspectionLength && currentInspectionLength
              ? '1fr 1fr'
              : previousInspectionLength || currentInspectionLength
              ? '1fr'
              : '',
        }}
      >
        {currentInspectionLength > 0 && (
          <div
            onClick={() => setModal({ current: true, previous: false })}
            className={`banner__current ${
              currentInspectionMaxCalification
                ? `banner__current--${currentInspectionMaxCalification}`
                : ''
            }`}
          >
            <div className="banner__icon">
              <SeeIcon />
            </div>
            <span>
              {currentInspectionLength}
              {currentInspectionLength > 1 ? ' DEFECTOS APLICADOS' : ' DEFECTO APLICADO'}
            </span>
          </div>
        )}
        {previousInspectionLength > 0 && (
          <div
            onClick={() => setModal({ current: false, previous: true })}
            className="banner__previous"
          >
            VER DEFECTOS DE ANTERIOR INSPECCIÓN
          </div>
        )}
      </div>
      <Modal
        className="defects-supervisor__modal"
        open={modal?.current || modal?.previous}
        onClose={() => setModal({ current: false, previous: false })}
        closeOnOverlay={false}
        buttons={true}
        hasCloseButton={false}
        content={
          <DefectsSupervisor
            manualManager={inspection?.manualManager}
            isPreviousInspection={modal?.previous}
            isSupervisor={isSupervisor}
            visualDefects={
              modal?.current
                ? visualDefects
                : modal?.previous
                ? previousInspectionVisualDefects
                : null
            }
            machineDefects={
              modal?.current
                ? machineDefects
                : modal?.previous
                ? previousInspectionMachineDefects
                : null
            }
          />
        }
      />
    </>
  )
}
