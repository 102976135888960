import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export function getHardwareConfigFile(options = {}) {
  return Axios.get(`${SERVER_URL}/api/files/hardware`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getHardwareByLine(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}config/hardware`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getItvLogo(options = {}) {
  return Axios.get(`${SERVER_URL}/api/files/itv_logo`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getItvConfigFile(options = {}) {
  return Axios.get(`${SERVER_URL}/api/files/itv`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getFiltersFile(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}config/vehicle_filter`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getLinkBetweenMachineAndManualFile(options = {}) {
  return Axios.get(`${SERVER_URL}/api/files/link_between_machine_and_manual`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putFiltersFile(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}config/vehicle_filter`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putItvControlPanelFile(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/files/itv_control_panel`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getTestSourceFile(filename, options = {}) {
  return Axios.get(`${SERVER_URL}/api/files/hardware_out/${filename}`, {
    ...getJWT(),
    responseType: 'blob',
    signal: options.signal,
  })
}
