import useService from 'hooks/useService'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  setReduxCashRegisterIsChanging,
  setReduxCashRegisterNames,
  setReduxCashRegisterStatus,
  setReduxSelectedCashRegister,
} from 'redux/ducks/cashRegisterDuck'
import {
  getCashRegisterNames as getCashRegisterNamesService,
  getCashRegisterStatus as getCashRegisterStatusService,
} from 'services/CashRegister'

export default function FillReduxCashRegister({ redux }) {
  const dispatch = useDispatch()

  const { fetch: getCashRegisterStatus } = useService({
    service: getCashRegisterStatusService,
  })
  const { fetch: getCashRegisterNames } = useService({
    service: getCashRegisterNamesService,
  })

  // CashRegister Status
  useEffect(() => {
    if (!redux?.cashRegister?.status && !redux?.cashRegister?.isChanging) {
      getCashRegisterStatus({}).then(res => {
        if (res) {
          dispatch(setReduxCashRegisterStatus(res))
        }
      })
    }
  }, [dispatch, getCashRegisterStatus, redux?.cashRegister])

  //CashSelected Status
  useEffect(() => {
    const cashSelectorValues = redux?.cashRegister?.cashSelectorValues
    if (
      !redux?.cashRegister?.selectedCashRegister &&
      cashSelectorValues?.id &&
      cashSelectorValues?.date
    ) {
      getCashRegisterStatus({
        cashId: cashSelectorValues.id,
        date: cashSelectorValues.date,
      })
        .then(res => {
          if (res) {
            console.log({ res })
            dispatch(setReduxSelectedCashRegister(res))
          }
        })
        .finally(() => {
          if (redux?.cashRegister?.isChanging) dispatch(setReduxCashRegisterIsChanging(false))
        })
    }
  }, [dispatch, getCashRegisterStatus, redux?.cashRegister])

  useEffect(() => {
    if (!redux?.cashRegister?.isChanging && !redux?.cashRegister?.names?.length) {
      getCashRegisterNames().then(res => {
        if (res?.cashRegisters?.length) {
          dispatch(setReduxCashRegisterNames(res.cashRegisters))
        }
      })
    }
  }, [dispatch, getCashRegisterNames, redux?.cashRegister])

  return <></>
}
