import React, { useState } from 'react'

// MY IMPORTS
import 'components/Office/Checkout/PaymentMethods/ReturnCalculator/ReturnCalculator.scss'
import formatEuro from 'myMethods/formatEuro'

export default function ReturnCalculator({ total }) {
  const [value, setValue] = useState('')
  const [returnValue, setReturnValue] = useState(formatEuro(0))

  const handleReturn = e => {
    try {
      const value = parseFloat(e.target.value)
      console.log({ value, total })
      const calc = (value || 0) - (total ?? 0)
      setValue(value)

      setReturnValue(formatEuro(calc < 0 ? 0 : calc))
    } catch (err) {
      console.error(err)
      return 0
    }
  }
  console.log({ value, returnValue })
  return (
    <div className="return-calculator">
      <div className="return-calculator__input">
        <span>Total</span>
        <input value={formatEuro(total)} disabled={true} />
      </div>
      <div className="return-calculator__input">
        <span>Recibo</span>
        <input type="number" value={value} onChange={e => handleReturn(e)} />
      </div>
      <div className="return-calculator__input">
        <span>Devuelvo</span>
        <input value={returnValue} disabled={true} />
      </div>
    </div>
  )
}
