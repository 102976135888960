import React from 'react'
//import { motion } from 'framer-motion/dist/framer-motion'

export default function AnimatedRoute({ children, ...rest }) {
  // const motionVariants = {
  //   initial: {
  //     opacity: 0,
  //     x: '-100vw',
  //     scale: 0.8,
  //   },
  //   in: {
  //     opacity: 1,
  //     x: 0,
  //     scale: 1,
  //   },
  //   out: {
  //     opacity: 0,
  //     x: '100vw',
  //     scale: 1.2,
  //   },
  // }
  // const motionTransition = {
  //   type: 'tween',
  //   ease: 'anticipate',
  //   duration: 0.4,
  // }
  // const motionStyles = {
  //   position: 'absolute',
  //   top: '5.5rem',
  //   left: 0,
  //   right: 0,
  //   bottom: 0,
  // }

  return (
    // <motion.div
    //   style={motionStyles}
    //   initial="initial"
    //   animate="in"
    //   exit="out"
    //   variants={motionVariants}
    //   transition={motionTransition}
    // >
    //   {React.cloneElement(children, { ...rest })}
    // </motion.div>
    <div>{React.cloneElement(children, { ...rest })}</div>
  )
}
