import React, { useState, useEffect } from 'react'

// MY IMPORTS
import './CashRegisterArching.scss'
import Modal from 'components/Others/Modal/Modal'
import CashModal from './CashModal/CashModal'
import easyToast from 'components/Others/EasyToast/easyToast'
import MuiPopover from 'components/Others/MuiPopover/MuiPopover'
import PieChart from 'components/Others/Charts/Pie/Pie'
import { postCashRegisterArching } from 'services/CashRegister'
import formatEuro from 'myMethods/formatEuro'
import round from 'myMethods/round'
import SubmitArchingModal from './SubmitArchingModal/SubmitArchingModal'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import moment from 'moment'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'

export default function CashRegisterArching({
  status,
  refetch,
  isFetching,
  isChanging,
  selectedDate,
  selectedDateIsToday,
}) {
  const [showCashModal, setShowCashModal] = useState(false)
  const [showSubmitModal, setShowSubmitModal] = useState(false)
  const [pieData, setPieData] = useState(null)
  const [loading, setLoading] = useState(false)
  const arching = status?.archingState
  const [values, setValues] = useState({
    coinsValue: 0,
    coins: {
      500: 0,
      200: 0,
      100: 0,
      50: 0,
      20: 0,
      10: 0,
      5: 0,
      2: 0,
      1: 0,
      0.5: 0,
      0.2: 0,
      0.1: 0,
      0.02: 0,
      0.01: 0,
    },
    totalCash: 0,
    creditCard: { total: 0, details: {} },
    otherMovements: 0,
    initial: 0,
    final: arching?.rest || 0,
    difference: 0,
  })

  const isClosed = status?.isClosed
  const wasOpen = status?.wasOpen
  const getClosingTime = () => {
    try {
      let archDateTime = status?.archings?.find(elem => elem.reason === 'FIN')?.dateTime
      if (!archDateTime) return null
      archDateTime = moment(archDateTime)
      if (selectedDateIsToday) return ' A LAS ' + archDateTime.format('HH:mm')
      else
        return ' EL ' + archDateTime.format('DD/MM/YYYY') + ' A LAS ' + archDateTime.format('HH:mm')
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const Detail = ({ name, amount = 0, popoverInsideContent = null, ...rest }) => {
    if (!popoverInsideContent) {
      return (
        <div className="cash-register-arching__detail" {...rest}>
          <b>{name}</b>
          <span>{formatEuro(amount)}</span>
        </div>
      )
    } else {
      return (
        <MuiPopover
          id="cash-register-arching__popover"
          outsideContent={
            <div className="cash-register-arching__detail">
              <b>{name}</b>
              <span>{formatEuro(amount)}</span>
            </div>
          }
          insideContent={popoverInsideContent}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          popoverOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        />
      )
    }
  }

  const renderCreditCardDetail = () => {
    try {
      if (!Object.keys(values.creditCard.details).length) return null
      return Object.entries(values.creditCard.details).map(([name, amount], i) => {
        return (
          <div key={i}>
            <b>{name}: </b>
            <span>{formatEuro(amount)}</span>
          </div>
        )
      })
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error obteniendo los detalles de pagos con tarjeta')
      return null
    }
  }

  const handleSubmit = async (type, keep) => {
    try {
      if (!type) return easyToast('error', 'Inserte un tipo de arqueo')
      if (keep > values.coinsValue)
        return easyToast('error', 'La cantidad de dinero que quede en caja no puede ser negativo')
      let postValues = {
        id: parseInt(status?.id),
        cash: values.coinsValue,
        coins: values.coins,
        reason: type,
        cashRegisterId: status.id,
        date: selectedDate
          ? moment(selectedDate).isSame(moment(), 'day')
            ? undefined
            : selectedDate
          : undefined,
      }
      if (keep === 0 || keep > 0) {
        postValues.keep = keep
      }
      setLoading(true)
      await postCashRegisterArching(postValues)
      refetch()
      setShowSubmitModal(false)
      easyToast('dark', 'Arqueo realizado')
      if (status?.name === 'ITV') {
        document.getElementById('scrollable-auto-tab-2').click()
      } else {
        document.getElementById('scrollable-auto-tab-1').click()
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error arqueando la caja')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    try {
      let otherMovementsTotal = 0
      const initial = status?.lastArching?.cash || 0
      arching?.cashFlows
        ?.filter(elem => elem.concepto !== 'FIN')
        ?.forEach(elem => (otherMovementsTotal += elem.amount))

      // Otros movimientos
      setValues(prevState => ({
        ...prevState,
        otherMovements: otherMovementsTotal,
      }))

      // Total tarjeta
      let totalCreditCard = 0
      let filterByTpv = {}

      arching?.invoices?.forEach(elem => {
        if (elem.paymentMethod === 'TARJETA') {
          totalCreditCard += elem.breakdown?.total || 0
          if (elem.paymentDetails?.tpv) {
            filterByTpv[elem.paymentDetails.tpv.name] =
              filterByTpv[elem.paymentDetails.tpv.name] || 0 + elem.breakdown?.total || 0
          }
        }
      })

      setValues(prevState => ({
        ...prevState,
        creditCard: { total: round(totalCreditCard), details: filterByTpv },
      }))
      //Total efectivo
      setValues(prevState => ({
        ...prevState,
        totalCash: round(arching?.invoiceTotal - totalCreditCard),
      }))

      // Inicio
      setValues(prevState => ({
        ...prevState,
        initial: round(initial),
      }))
      // Final
      let final = round(totalCreditCard + (values.coinsValue || 0))
      setValues(prevState => ({
        ...prevState,
        final,
      }))
      const difference = isClosed
        ? status?.archings?.find(
            arch => arch.reason === 'FIN' && moment(arch.date).isSame(moment(selectedDate), 'day')
          )?.rest
        : null

      // Diferencia
      setValues(prevState => ({
        ...prevState,
        difference:
          difference != null
            ? difference
            : round(final - (initial + arching?.cashFlowTotal + arching?.invoiceTotal)),
      }))
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error calculando el arqueo. Inténtelo de nuevo')
    }
    //eslint-disable-next-line
  }, [status, values.coinsValue])

  console.log({ values })

  useEffect(() => {
    if (!loading && !isFetching) {
      const timer = setTimeout(() => {
        setPieData([
          {
            name: 'Efectivo',
            value: values.totalCash,
            color: 'var(--cash-color)',
            unit: '€',
          },
          {
            name: 'Tarjeta',
            value: values.creditCard.total,
            color: 'var(--credit-card-color)',
            unit: '€',
          },
          {
            name: 'Otros movimientos',
            value: arching?.cashFlowTotal < 0 ? 0 : status.cashFlowTotal,
            color: 'var(--gray-color)',
            unit: '€',
          },
        ])
      }, 500)

      return () => clearTimeout(timer)
    }
  }, [values, arching, status, isFetching, loading])

  return (
    <>
      {!loading && !isChanging && !isFetching && (!status || !Object.keys(status).length > 0) && (
        <div className="no-values">Vaya... parece que aún no tienes asignada una caja</div>
      )}
      {isFetching && <ChildSpinner tight={false} />}
      {!loading && !isFetching && !isChanging && status && Object.keys(status).length > 0 && (
        <section className="cash-register-arching">
          <h4 data-aos="fade-in">
            {!wasOpen && !status.isClosed && (
              <>
                {selectedDateIsToday ? 'ESTA CAJA AÚN NO SE HA ABIERTO' : 'ESTA CAJA NO SE ABRIÓ'}
              </>
            )}

            {isClosed && 'ESTA CAJA HA SIDO CERRADA'}
            {isClosed && getClosingTime() && getClosingTime()}
          </h4>

          <div className="cash-register-arching__wrapper">
            {pieData?.some(elem => elem?.value > 0) && (
              <div className="cash-register-arching__pie">
                <PieChart.donut data={pieData} unit={'€'} />
              </div>
            )}

            <div>
              <div className="cash-register-arching__main-info">
                <Detail name="Último arqueo" amount={values.initial} />
                <Detail name="Otros movimientos" amount={values.otherMovements} />
                <Detail name="Tarjeta + caja" amount={values.final} />
                <Detail
                  name="Diferencia"
                  amount={values.difference}
                  className={
                    values.difference !== 0 ? 'cash-register-arching__difference--error' : ''
                  }
                />
              </div>
              <div className="cash-register-arching__details">
                <Detail name="Total ingresos" amount={arching?.invoiceTotal} />
                <Detail name="Ingresos efectivo" amount={values.totalCash} />
                <Detail
                  name="Ingresos tarjeta"
                  amount={values.creditCard.total || 0}
                  popoverInsideContent={renderCreditCardDetail()}
                  style={{
                    cursor:
                      values?.creditCard?.details &&
                      Object.keys(values.creditCard.details).length > 0
                        ? 'help'
                        : 'auto',
                  }}
                />
                <Detail name="Ingresos crédito" amount={arching?.dealTotal || 0} />
              </div>
              <MyButtonsContainer>
                {!isClosed && (
                  <MyButton
                    text={'MODIFICAR EFECTIVO (' + formatEuro(values.coinsValue) + ')'}
                    onClick={() => setShowCashModal(true)}
                    transparent={true}
                  />
                )}
              </MyButtonsContainer>
            </div>
          </div>
          <MyButtonsContainer>
            {!isClosed && (
              <MyButton
                text={wasOpen ? 'ARQUEAR' : 'ABRIR CAJA'}
                onClick={() => setShowSubmitModal(true)}
              />
            )}
          </MyButtonsContainer>
        </section>
      )}

      {showCashModal && (
        <CashModal
          setCoinsValue={coinsValue => setValues({ ...values, coinsValue })}
          coins={values.coins}
          closeModal={() => setShowCashModal(false)}
          onAccept={(coins, coinsValue) => {
            setValues({ ...values, coins, coinsValue })
            setShowCashModal(false)
          }}
        />
      )}

      {showSubmitModal && (
        <SubmitArchingModal
          status={status}
          showPartial={selectedDate && selectedDateIsToday && status?.name !== 'ITV'}
          closeModal={() => setShowSubmitModal(false)}
          onAccept={(type, keep) => {
            handleSubmit(type, keep)
          }}
          loading={loading}
          coinsValue={values.coinsValue}
          difference={values.difference}
        />
      )}
    </>
  )
}
