//@ts-check
import {
  getStatisticsTopClients,
  getStatisticsBillingByClient,
  getStatisticsBillingByDateRange,
  getStatisticsDefectsByDateRange,
  getStatisticsInspectionByDateRange,
  getStatisticsTimesByDateRange,
} from 'services/Statistics'
import moment from 'moment'
import formatDate from 'myMethods/formatDate'
import easyToast from 'components/Others/EasyToast/easyToast'
import formatTimeToHoursMinutesSeconds from 'myMethods/formatTimeToHoursMinutesSeconds'
import formatEuro from 'myMethods/formatEuro'

export default class StatisticsHandler {
  constructor({ abortServiceCall }) {
    console.log('Construyo StatisticsHandler 🚧')
    this.abortServiceCall = abortServiceCall
    this.monthStartDate = formatDate(moment().startOf('month'), true)
    this.todayDate = formatDate(moment(), true)
    this.dateRange = [this.monthStartDate, this.todayDate]
    this.summary = {
      initialized: false,
      billing: {
        today: null,
        thisMonth: null,
        lastMonth: null,
        diffBetweenThisAndLastMonth: 0,
        topUsers: [],
      },
      inspection: {
        today: null,
        thisMonth: null,
        lastMonth: null,
        topByInspectionType: [],
        topByCategory: [],
      },
      topClients: {
        thisMonth: null,
      },
    }
    this.billing = {
      initialized: false,
      rawSelectors: ['Efectivo', 'Tarjeta', 'Albaranes'],
      selectors: {
        Efectivo: true,
        Tarjeta: true,
        Albaranes: true,
      },
      dateRange: [this.monthStartDate, this.todayDate],
      client: null,
      data: null,
      topClients: [],
      areaChartData: [],
      barsChartData: [],
      dateRangeText: '',
      users: [],
    }
    this.times = {
      initialized: false,
      dateRange: [this.monthStartDate, this.todayDate],
      dateRangeText: '',
      data: null,
      isOfficeIncluded: false,
    }
    this.inspection = {
      initialized: false,
      rawSelectors: ['Favorables', 'Desfavorables', 'Negativas'],
      selectors: {
        Favorables: true,
        Desfavorables: true,
        Negativas: true,
      },
      dateRange: [this.monthStartDate, this.todayDate],
      dateRangeText: '',
      data: null,
      areaChartData: [],
    }
    this.defects = {
      initialized: false,
      rawSelectors: ['Leves', 'Graves', 'Muy graves'],
      selectors: {
        Leves: true,
        Graves: true,
        'Muy graves': true,
      },
      dateRange: [this.monthStartDate, this.todayDate],
      dateRangeText: '',
      data: null,
      areaChartData: [],
    }
  }

  set billingLoading(value) {
    this.billing.loading = value
  }

  async initializeSummary() {
    try {
      await this.#getSummaryBilling()
      this.summary.topClients.thisMonth =
        (await this.getTopClients(this.monthStartDate, this.todayDate)) || null
      this.summary.billing.topUsers = this.summary.billing.thisMonth?.totalRange?.users
        ? Object.values(this.summary.billing.thisMonth.totalRange.users)
            .sort((a, b) => b.amount - a.amount)
            .map(user => user)
        : []
      await this.#getSummaryInspection()
      this.summary.inspection.topByInspectionType = this.#getTopInspections(
        this.summary.inspection?.thisMonth?.totalRange?.byType
          ? Object.values(this.summary.inspection.thisMonth.totalRange.byType)
          : []
      )
      this.summary.inspection.topByCategory = this.#getTopInspections(
        this.summary.inspection?.thisMonth?.totalRange?.byFilters?.category?.filterValues
          ? Object.entries(
              this.summary.inspection.thisMonth.totalRange.byFilters.category.filterValues
            ).map(([category, insp]) => ({ category, ...insp }))
          : []
      )

      this.summary.initialized = true
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos')
    }
  }

  async initializeBilling() {
    try {
      await this.changeBillingData()
      this.billing.initialized = true
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos. Seleccione una fecha')
    }
  }

  async initializeTimes() {
    try {
      await this.changeTimesData()
      this.times.initialized = true
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos. Seleccione una fecha')
    }
  }

  /**
   *
   * @param {any[]} inspections
   * @returns
   */
  #getTopInspections(inspections, maxLength = 10) {
    try {
      const topByInspectionType = []
      inspections
        .sort((a, b) => b.total - a.total)
        .every(insp => {
          if (topByInspectionType.length >= maxLength) return false
          return topByInspectionType.push(insp)
        })
      return topByInspectionType
    } catch (err) {
      console.error(err)
      return []
    }
  }

  async initializeInspection() {
    try {
      await this.changeInspectionData()
      this.inspection.initialized = true
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos. Seleccione una fecha')
    }
  }

  async initializeDefects() {
    try {
      await this.changeDefectsData()
      this.defects.initialized = true
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos. Seleccione una fecha')
    }
  }

  #generateExternalDates(startDate, endDate, timeUnit) {
    if (!(startDate && endDate)) {
      if (this.dateRange?.length) {
        startDate = this.dateRange[0]
        endDate = this.dateRange[1]
      } else throw new Error('No date provided')
    }
    const start = moment(startDate)
    const end = moment(endDate)
    if (!(start.isValid() && end.isValid())) throw new Error('Invalid dates')

    const format = this.#getDateRangeFormat(startDate, endDate, timeUnit)
    const startDate_text = start.format(format)
    const endDate_text = end.format(format)
    const dateRangeText = `${startDate_text} - ${endDate_text}`
    return { format, startDate_text, endDate_text, dateRangeText }
  }

  #generateInternalDates(startDate, endDate) {
    if (!(startDate && endDate)) {
      if (this.dateRange?.length) {
        startDate = this.dateRange[0]
        endDate = this.dateRange[1]
      } else throw new Error('No date provided')
    }
    const start = moment(startDate)
    const end = moment(endDate)
    if (!(start.isValid() && end.isValid())) throw new Error('Invalid dates')
    const startDate_internal_format = formatDate(start, true)
    const endDate_internal_format = formatDate(end, true)
    const dateRange = [formatDate(start, true), formatDate(end, true)]
    return {
      start,
      end,
      startDate_internal_format,
      endDate_internal_format,
      dateRange,
    }
  }

  async #getSummaryBilling() {
    try {
      const today = moment()
      /**@type {import('types/Statistics/Billing').Billing} */
      const thisMonth = await getStatisticsBillingByDateRange(
        formatDate(moment().startOf('month'), true),
        formatDate(today, true)
      )

      this.summary.billing.today = thisMonth.statistics[today.format('YYYY-MM-DD') + ' 00:00:00']
      this.summary.billing.thisMonth = thisMonth

      const lastMonth = await getStatisticsBillingByDateRange(
        formatDate(moment().subtract(1, 'months').startOf('month'), true),
        formatDate(moment().subtract(1, 'months'), true)
      )
      this.summary.billing.diffBetweenThisAndLastMonth =
        (thisMonth?.totalRange?.amount ?? 0) - (lastMonth?.totalRange?.amount ?? 0) ?? 0
      this.summary.billing.lastMonth = lastMonth
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos de ventas del mes')
      this.abortServiceCall()
    }
  }

  async #getSummaryInspection() {
    try {
      const today = moment()
      const thisMonth = await getStatisticsInspectionByDateRange(
        formatDate(moment().startOf('month'), true),
        formatDate(today, true)
      )

      this.summary.inspection.today = thisMonth.statistics[today.format('YYYY-MM-DD') + ' 00:00:00']
      this.summary.inspection.thisMonth = thisMonth

      const lastMonth = await getStatisticsInspectionByDateRange(
        formatDate(moment().subtract(1, 'months').startOf('month'), true),
        formatDate(moment().subtract(1, 'months'), true)
      )

      this.summary.inspection.diffBetweenThisAndLastMonth =
        (thisMonth?.totalRange?.total ?? 0) - (lastMonth?.totalRange?.total ?? 0) ?? 0
      this.summary.inspection.lastMonth = lastMonth
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos de inspecciones del mes')
      this.abortServiceCall()
    }
  }

  async getTopClients(startDate, endDate) {
    try {
      const clients = await getStatisticsTopClients(startDate, endDate)
      if (!clients || !Object.keys(clients).length) return []
      const allClients = []
      Object.entries(clients).forEach(([_, data]) => {
        allClients.push(...data)
      })
      allClients.sort((a, b) => b.amount - a.amount)
      const finalClients = []
      for (const client of allClients) {
        if (finalClients.length === 10) break
        if (!finalClients.some(cl => cl.dni === client.dni)) finalClients.push(client)
      }
      return finalClients
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error obteniendo el top 10 clientes')
      this.abortServiceCall()
      return []
    }
  }

  #getDateRangeFormat(startDate, endDate, timeUnit = 'day') {
    if (!['day', 'month', 'year'].includes(timeUnit)) throw new Error('No time unit provided')
    if (!startDate || !endDate) throw new Error('No dates provided')
    startDate = moment(startDate)
    endDate = moment(endDate)
    const now = moment()
    // Muestra el año en el caso de que alguna de las fechas sea de un año diferente al actual
    const showYear = startDate.year() !== now.year() || endDate.year() !== now.year()
    if (timeUnit === 'year') return 'YYYY'
    else if (timeUnit === 'month') {
      if (showYear) return 'MMM-YYYY'
      else return 'MMM'
    } else {
      if (showYear) return 'DD-MMM-YYYY'
      else return 'DD-MMM'
    }
  }

  /**
   * @param {number[]} totals
   */
  #calcTrend(totals) {
    try {
      const sum = totals.reduce((a, b) => a + b, 0)
      const mean = sum / totals.length

      const x = totals.map((_, i) => i + 1)
      const y = totals

      const sumXY = x.map((xi, i) => xi * y[i]).reduce((a, b) => a + b, 0)
      const sumX = x.reduce((a, b) => a + b, 0)
      const sumY = y.reduce((a, b) => a + b, 0)
      const sumXSquared = x.map(xi => xi ** 2).reduce((a, b) => a + b, 0)

      const slope =
        (totals.length * sumXY - sumX * sumY) / (totals.length * sumXSquared - sumX ** 2)
      const intercept = mean - slope * ((totals.length + 1) / 2)
      const trend = x.map(xi => slope * xi + intercept)

      return trend
    } catch (err) {
      console.error(err)
      return []
    }
  }

  #generateBillingAreaChartData(statistics, format) {
    try {
      const trend = this.#calcTrend(
        Object.entries(statistics)
          ?.sort(([date1], [date2]) => moment(date1).valueOf() - moment(date2).valueOf())
          .map(([, data]) => data.amount)
      )

      return Object.entries(statistics)
        ?.sort(([date1], [date2]) => moment(date1).valueOf() - moment(date2).valueOf())
        ?.map(([date, data], i) => {
          if (Array.isArray(data)) data = data[0]
          return {
            name: moment(date).format(format),
            Efectivo: data.efectivo?.amount ?? 0,
            Tarjeta: data.tarjeta?.amount ?? 0,
            Albaranes: (data.dealsBilling?.amount ?? 0) + (data.dealsNotBilling?.amount ?? 0),
            Total: data.amount,
            'Total facturado': formatEuro(data.amount),
            Tendencia: trend[i],
            date,
          }
        })
    } catch (err) {
      console.error(err)
      return []
    }
  }

  #generateBillingBarsChartData(totals) {
    return [
      {
        name: 'Efectivo',
        Total: totals?.efectivo?.amount ?? 0,
      },
      {
        name: 'Tarjeta',
        Total: totals?.tarjeta?.amount ?? 0,
      },
      {
        name: 'Albaranes',
        Total: (totals?.dealsBilling?.amount ?? 0) + (totals?.dealsNotBilling?.amount ?? 0),
      },
    ]
  }

  #generateBillingUsersData(users) {
    if (users == null) return []
    return Object.entries(users)
      ?.sort(([, data1], [, data2]) => data2.amount - data1.amount)
      ?.map(([username, data]) => ({
        username,
        fullName: data.fullName,
        amount: data.amount,
        total: data.total,
      }))
  }

  async removeBillingClient() {
    try {
      this.billing.client = null
      await this.changeBillingData(this.billing.dateRange[0], this.billing.dateRange[1])
    } catch (err) {
      console.error(err)
    }
  }

  async changeBillingData(startDate, endDate, client) {
    try {
      let data = null
      if (!client && this.billing.client) client = this.billing.client
      const { startDate_internal_format, endDate_internal_format, dateRange } =
        this.#generateInternalDates(startDate, endDate)
      if (!data || client) {
        if (client) {
          data = await getStatisticsBillingByClient(
            startDate_internal_format,
            endDate_internal_format,
            client.dni
          )
          this.billing.client = client
          data.totalRange = data.total
          data.statistics = data.range
          delete data.total
          delete data.range
        } else {
          data = await getStatisticsBillingByDateRange(
            startDate_internal_format,
            endDate_internal_format
          )
          this.billing.topClients = await this.getTopClients(
            startDate_internal_format,
            endDate_internal_format
          )
        }
      }
      const { format, dateRangeText } = this.#generateExternalDates(
        startDate,
        endDate,
        data?.groupBy
      )
      this.billing.areaChartData = this.#generateBillingAreaChartData(data?.statistics, format)
      this.billing.barsChartData = this.#generateBillingBarsChartData(data?.totalRange)
      this.billing.users = this.#generateBillingUsersData(data?.totalRange?.users)
      this.billing.dateRangeText = dateRangeText
      this.billing.data = data
      this.billing.dateRange = dateRange
      this.dateRange = dateRange
    } catch (err) {
      console.error(err)
      this.abortServiceCall()
    }
  }

  toggleBillingSelectorState(selector, state) {
    try {
      if (selector == null || state == null) throw new Error('No selector or state specified')
      if (selector in this.billing.selectors) {
        this.billing.selectors[selector] = state
      }
    } catch (err) {
      console.error(err)
    }
  }

  toggleInspectionSelectorState(selector, state) {
    try {
      if (selector == null || state == null) throw new Error('No selector or state specified')
      if (selector in this.inspection.selectors) {
        this.inspection.selectors[selector] = state
      }
    } catch (err) {
      console.error(err)
    }
  }

  toggleDefectsSelectorState(selector, state) {
    try {
      if (selector == null || state == null) throw new Error('No selector or state specified')
      if (selector in this.defects.selectors) {
        this.defects.selectors[selector] = state
      }
    } catch (err) {
      console.error(err)
    }
  }

  async changeTimesData(startDate, endDate) {
    try {
      const { startDate_internal_format, endDate_internal_format, dateRange } =
        this.#generateInternalDates(startDate, endDate)

      this.times.data = await getStatisticsTimesByDateRange(
        startDate_internal_format,
        endDate_internal_format
      )

      const { dateRangeText } = this.#generateExternalDates(startDate, endDate)

      this.times.dateRangeText = dateRangeText
      this.times.dateRange = dateRange
      this.dateRange = dateRange
    } catch (err) {
      console.error(err)
      this.abortServiceCall()
      throw err
    }
  }

  #generateInspectionAreaChartData(statistics, format) {
    try {
      return (
        Object.values(statistics)
          ?.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
          ?.map(eachDay => {
            return {
              name: moment(eachDay.date).format(format),
              Favorables: eachDay.favorables ?? 0,
              Desfavorables: eachDay.desfavorables ?? 0,
              Negativas: eachDay.negativas ?? 0,
              Inspecciones: eachDay.total ?? 0,
              'Tiempo medio':
                eachDay.halfTime != null ? formatTimeToHoursMinutesSeconds(eachDay.halfTime) : 0,
              'Porcentaje de rechazo': eachDay.rejectionRate ?? 0,
              date: eachDay.date,
            }
          }) || []
      )
    } catch (error) {
      console.error(error)
      return []
    }
  }

  #generateDefectsAreaChartData(statistics, format) {
    try {
      return (
        Object.entries(statistics)
          ?.sort((a, b) => moment(a[0]).valueOf() - moment(b[0]).valueOf())
          ?.map(([date, eachDay]) => {
            return {
              name: moment(date).format(format),
              Leves: eachDay.slight ?? 0,
              Graves: eachDay.serious ?? 0,
              'Muy graves': eachDay.verySerious ?? 0,
              Inspecciones: eachDay.numberOfInspections ?? 0,
              date,
            }
          }) || []
      )
    } catch (error) {
      console.error(error)
      return []
    }
  }

  async changeInspectionData(startDate, endDate) {
    try {
      const { startDate_internal_format, endDate_internal_format, dateRange } =
        this.#generateInternalDates(startDate, endDate)

      const res = await getStatisticsInspectionByDateRange(
        startDate_internal_format,
        endDate_internal_format
      )
      this.inspection.data = res

      console.log({ res })
      const { format, dateRangeText } = this.#generateExternalDates(
        startDate,
        endDate,
        res?.groupBy
      )
      this.inspection.areaChartData = this.#generateInspectionAreaChartData(res?.statistics, format)
      this.inspection.dateRangeText = dateRangeText
      this.inspection.dateRange = dateRange
      this.dateRange = dateRange
    } catch (err) {
      console.error(err)
      this.abortServiceCall()
      throw err
    }
  }

  async changeDefectsData(startDate, endDate) {
    try {
      const { startDate_internal_format, endDate_internal_format, dateRange } =
        this.#generateInternalDates(startDate, endDate)
      const res = await getStatisticsDefectsByDateRange(
        startDate_internal_format,
        endDate_internal_format
      )
      this.defects.data = res

      const { format, dateRangeText } = this.#generateExternalDates(
        startDate,
        endDate,
        res?.groupBy
      )

      this.defects.areaChartData = this.#generateDefectsAreaChartData(
        res?.statistics?.byDates,
        format
      )
      this.defects.dateRangeText = dateRangeText
      this.defects.dateRange = dateRange
      this.dateRange = dateRange
    } catch (err) {
      console.error(err)
      this.abortServiceCall()
      throw err
    }
  }

  set isOfficeIncluded(value) {
    this.times.isOfficeIncluded = value
  }
}
