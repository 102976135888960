//@ts-check
import DateTimeITV from 'Share/DateTimeITV'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useStreamService from './useStreamService'
import { postChatMessageStream as postChatMessageStreamService } from 'services/MintAIChatbot'
import easyToast from 'components/Others/EasyToast/easyToast'
import { v4 as uuidv4 } from 'uuid'

/**
 * @typedef {import('types/Chatbot').Chatbot} Chatbot
 * @typedef {import('types/Chatbot').Message} Message
 * @typedef {import('types/Vehicle').Vehicle} VehicleData
 */

/**
 * @returns {import('../types/UseMintAIChatbot').UseMintAIChatbot}}
 */
export default function useMintAIChatbot() {
  const reduxChatbot = useSelector(state => state.mintAIChatbot?.chatbot)
  const reduxInspection = useSelector(state => state.inspection)
  const [lastMessageStatus, setLastMessageStatus] = useState({ text: '', error: false })
  const [chatbot, setChatbot] = useState(reduxChatbot || null)
  const [selectorState, setSelectorState] = useState({
    type: 'inspection',
    inspection: {
      vehicleData: null,
      manualSection: null,
      chapter: null,
      parent: null,
    },
  })
  const [inMemoryMessages, setInMemoryMessages] = useState(chatbot?.messages || [])
  const [loadingStatus, setLoadingStatus] = useState({ answer: false, chatbot: false })
  const messages = inMemoryMessages || []
  const botMessages = messages?.filter(m => m.role === 'BOT')
  const userMessages = messages?.filter(m => m.role === 'USER')
  const MAX_BOT_MESSAGES = 100

  const { fetch: postChatMessage, abortStream: abortResponse } = useStreamService({
    service: postChatMessageStreamService,
    setOutLoading: value => {
      setLoadingStatus(prevState => ({ ...prevState, answer: value }))
    },
  })

  useEffect(() => {
    if (!lastMessageStatus.text && userMessages?.[userMessages.length - 1]?.text) {
      setLastMessageStatus({ text: userMessages[userMessages.length - 1].text, error: false })
    }
  }, [lastMessageStatus.text, userMessages])

  const setInspectionSelectorState = ({ manualSection, chapter, parent, vehicleData }) => {
    setSelectorState(prevState => ({
      ...prevState,
      type: 'inspection',
      inspection: {
        ...prevState.inspection,
        ...(manualSection !== undefined && { manualSection }),
        ...(chapter !== undefined && { chapter }),
        ...(parent !== undefined && { parent }),
        ...(vehicleData !== undefined && { vehicleData }),
      },
    }))
  }

  const getChatbotName = useCallback(() => {
    try {
      if (selectorState.type === 'inspection') {
        let name = ''
        if (selectorState.inspection.manualSection !== null) {
          name = 'Sección ' + selectorState.inspection.manualSection

          if (
            selectorState.inspection.chapter !== null &&
            selectorState.inspection.parent == null
          ) {
            name += ' - Capítulo ' + selectorState.inspection.chapter.id
          } else if (selectorState.inspection.parent !== null) {
            name += ' - Unidad' + selectorState.inspection.parent.id
          }
        }
        if (!name) throw new Error('No name in generated for inspection type')
        return name
      }
    } catch (err) {
      console.error(err)
      return 'MintAI'
    }
  }, [
    selectorState.inspection.chapter,
    selectorState.inspection.manualSection,
    selectorState.inspection.parent,
    selectorState.type,
  ])

  const resendLastMessage = () => {
    try {
      if (!lastMessageStatus?.text) {
        setLastMessageStatus({ text: '', error: false })
        return
      }
      sendMessage(lastMessageStatus.text)
    } catch (err) {
      console.error(err)
    }
  }

  const initialize = () => {
    try {
      setLoadingStatus(prevState => ({ ...prevState, chatbot: true }))
      const date = new DateTimeITV()

      setChatbot({
        id: Math.random(),
        userId: Math.random(),
        name: getChatbotName(),
        createdOn: date.toString(),
        isAvailable: true,
        messages: [],
      })
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingStatus(prevState => ({ ...prevState, chatbot: false }))
    }
  }

  const sendMessage = async text => {
    try {
      setLastMessageStatus({ text, error: false })
      const responseId = uuidv4()

      const date = new DateTimeITV().toUTC()

      const message = {
        id: uuidv4(),
        created: date,
        role: 'USER',
        userId: chatbot.userId,
        text,
      }

      let newMessages = JSON.parse(JSON.stringify([...(inMemoryMessages ?? []), message]))

      setInMemoryMessages(newMessages)

      await postChatMessage({
        params: {
          message: text,
          history: [] ?? newMessages,
          context: {
            manualSection: selectorState.inspection.manualSection,
            chapter: selectorState.inspection.chapter?.id,
            parent: selectorState.inspection.parent?.id,
            vehicleData: reduxInspection?.vehicleData ?? undefined,
          },
        },
        onNext: chunk => {
          console.log({ chunk })

          setInMemoryMessages(currentMessages => {
            // Clona el estado actual de los mensajes para evitar mutaciones directas
            let updatedMessages = JSON.parse(JSON.stringify(currentMessages))

            // Busca si el mensaje de respuesta ya existe
            const foundMessageIndex = updatedMessages.findIndex(msg => msg.id === responseId)

            // Si se encuentra el mensaje, actualiza su texto concatenando el nuevo chunk
            if (foundMessageIndex !== -1) {
              updatedMessages[foundMessageIndex] = {
                ...updatedMessages[foundMessageIndex],
                text: (updatedMessages[foundMessageIndex].text || '') + chunk,
              }
            } else {
              // Si no se encuentra, crea un nuevo mensaje de tipo BOT con el chunk recibido
              updatedMessages.push({
                id: responseId,
                created: new DateTimeITV().toUTC(), // Asegúrate de que la fecha siempre esté actualizada
                userId: chatbot.userId,
                role: 'BOT',
                text: chunk,
              })
            }

            console.log({ inMemoryMessages: currentMessages, newMessages: updatedMessages })

            // Devuelve la nueva lista de mensajes actualizada
            return updatedMessages
          })
        },
      })
    } catch (err) {
      console.error(err)
      setLastMessageStatus({ text, error: true })
    }
  }

  try {
    return {
      data: chatbot,
      messages,
      botMessages,
      userMessages,
      hasReachedMaxMessages: (botMessages?.length ?? 0) >= MAX_BOT_MESSAGES,
      hasLastResponseError: lastMessageStatus.error,
      canRepeatLastMessage: Boolean(lastMessageStatus.text),
      maxBotAnswers: MAX_BOT_MESSAGES,
      loadingChatbot: loadingStatus.chatbot, // TODO:
      sendMessage,
      delete: () => {}, //TODO:
      abortResponse,
      resendLastMessage,
      loadingResponse: loadingStatus.answer,
      canSendMessages: chatbot?.isAvailable,
      selectorState,
      setInspectionSelectorState,
      initialize,
    }
  } catch (error) {
    console.error(error)
    return {
      data: undefined,
      maxBotAnswers: MAX_BOT_MESSAGES,
      loadingChatbot: loadingStatus.chatbot,
      loadingResponse: loadingStatus.answer,
      selectorState,
      setInspectionSelectorState,
      initialize,
    }
  }
}
