import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// MY IMPORTS
import VehicleForm from 'components/Office/VehicleForm/VehicleForm'
import HolderForm from 'components/Office/HolderForm/HolderForm'
import NewInspectionForm from 'components/Office/NewInspectionForm/NewInspectionForm'
import Title from 'components/Others/Title/Title'
import Checkout from 'components/Office/Checkout/Checkout'
import InvoiceSample from 'components/Office/InvoiceSample/InvoiceSample'
import MuiStepper from 'components/Others/MuiStepper/MuiStepper'
import { setReduxFormState } from 'redux/ducks/newInspectionDuck'

export default function CreateInspection() {
  const redux = useSelector(store => store)
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = useState(0)
  const steps = ['Datos del vehículo', 'Datos del titular', 'Facturación']

  function renderSwitch(formState) {
    switch (formState) {
      case 0:
        return <NewInspectionForm reduxNewVehicle={redux.newVehicle} />
      case 1:
        return <VehicleForm redux={redux} />
      case 2:
        return <HolderForm reduxNewVehicle={redux.newVehicle} />
      case 3:
        return <Checkout redux={redux} />
      case 4:
        return <InvoiceSample reduxNewVehicle={redux.newVehicle} />
      default:
        break
    }
  }

  useEffect(() => {
    setActiveStep(redux.newVehicle.formState)
  }, [redux.newVehicle.formState])

  return (
    <>
      {redux.newVehicle.formState === 0 && <Title text="NUEVA INSPECCIÓN" />}
      {![0, 4].includes(redux.newVehicle.formState) && (
        <MuiStepper
          steps={steps}
          activeStep={activeStep - 1}
          handleClick={index => dispatch(setReduxFormState(index))}
        />
      )}
      {renderSwitch(redux.newVehicle.formState)}
    </>
  )
}
