//@ts-check
import React from 'react'
import Gallery from 'components/Others/Gallery/Gallery'
import useInspectionImages from 'hooks/useInspectionImages'
import './InspectionImages.scss'

export default function InspectionImages() {
  const { images, deleteImage, uploadImage } = useInspectionImages({})
  return (
    <div className="inspection-images">
      <Gallery
        images={images}
        onDeleteImage={deleteImage}
        onCaptureImage={(image, geolocation, type) => uploadImage({ image, geolocation, type })}
        defaultSelectValue="general"
      />
    </div>
  )
}
