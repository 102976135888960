import ErrorView from 'components/Others/ErrorView/ErrorView'
import Modal from 'components/Others/Modal/Modal'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import React, { useCallback, useState } from 'react'
import ListOfStatisticsDefects from '../ListOfStatisticsDefects/ListOfStatisticsDefects'

/**
 *
 * @param {{defectsByFilter: object, filterKey: string, filterLabel: string, dataRange: any, totalInspections: number, formatDefectsToExportation: function}} param0
 * @returns
 */
export default function StatisticsDefectsByFilter({
  defectsByFilter,
  filterKey,
  filterLabel,
  dateRange,
  totalInspections,
  formatDefectsToExportation,
}) {
  const [modalDefects, setModalDefects] = useState({ show: false, data: null, filterKey: '' })
  const formatValues = useCallback(
    values => {
      return Object.entries(values).map(([key, data]) => {
        return {
          filterKey: key,
          listOfDefects: formatDefectsToExportation(data.defects),
          ...data,
        }
      })
    },
    [formatDefectsToExportation]
  )
  try {
    return (
      <>
        <div>
          <MuiTable
            values={formatValues(defectsByFilter)}
            columns={[
              { title: filterLabel, field: 'filterKey' },
              { title: 'Total inspecciones', field: 'inspections' },
              { title: 'Total defectos', field: 'totalDefects' },
              { title: 'Leves', field: 'slight' },
              { title: 'Graves', field: 'serious' },
              { title: 'Muy graves', field: 'verySerious' },
            ]}
            options={{
              grouping: false,
              filtering: false,
              search: true,
            }}
            showToolbar={true}
            singleSelection={true}
            singleSelectionLabel="Mostrar defectos"
            onSingleSelectionClick={rowData =>
              setModalDefects({
                show: true,
                data: defectsByFilter[rowData.filterKey],
                filterKey: rowData.filterKey,
              })
            }
            exportation={{
              type: `STATISTICS_DEFECTS_${filterKey}`,
              data: { totalInspections, values: formatValues(defectsByFilter) },
              options: {
                dateRange,
              },
              modalTitle: `Exportar defectos por ${filterLabel}`,
              defaultInputValue: `DEFECTOS POR ${filterLabel.toUpperCase()}`,
            }}
          />
        </div>
        <Modal
          open={modalDefects.show}
          onClose={() => setModalDefects({ show: false, data: null, filterKey: '' })}
          title={`DEFECTOS POR ${filterLabel.toUpperCase()} ${modalDefects.filterKey}`}
          hasAcceptButton={false}
          content={<ListOfStatisticsDefects defects={modalDefects.data} />}
        />
      </>
    )
  } catch (err) {
    console.error(err)
    return <ErrorView />
  }
}
