//@ts-check
import reduceHigherDefectScore from 'myMethods/reduceHigherDefectScore'
import ErrorForDefect from './ErrorForDefect'
import InspectionDefect from './InspectionDefect'

export default class SectionNode {
  /**
   * @param {Object} param
   * @param {InspectionDefect} param.defect
   * @param {Map<string, SectionNode> | null} param.childs
   * @param {SectionNode | null} param.father
   */
  constructor({ defect, childs, father }) {
    this.defect = defect
    this.childs = childs
    this.father = father
  }

  toList() {
    /**@type {InspectionDefect[]} */
    const list = []
    if (this.defect != null) {
      const faherId = this.defect.father
      list.push(this.defect)
    }
    if (this.childs == null) return list
    for (const [_, node] of this.childs) {
      list.push(...node.toList())
    }
    return list
  }

  getChildrenMaxScore() {
    const list = this.toList()

    const filteredList = list.filter(def => def.score)
    if (filteredList.length === 0) return null
    return reduceHigherDefectScore(filteredList)
  }

  /**
   *
   * @returns {('G'| 'VG'| 'L')[]}
   */
  getchildrenPossibleScores() {
    const list = this.toList()

    if (list.length === 0) return []
    /**@type {Set<("G"| "L" | "VG")>} */
    const posibleScores = new Set()
    for (const def of list) {
      for (const score of def.possibleScores ?? []) {
        posibleScores.add(score)
      }
    }
    return Array.from(posibleScores.values())
  }

  /**
   *
   * @returns {import('types/ManualManager').DefectToShow}
   */
  toPrimitiveWithoutChildsAndFather() {
    return {
      ...this.defect?.toPrimitives(),
      numberOfChilds: this.childs?.size || 0,
      numberOfChildsThatShows: this.getNumberOfChildsOnDirectChilds(),
      depth: this.defect.parentOrderDepth,
      maxChildrenScore: this.getChildrenMaxScore(),
      childrenPossibleScores: this.getchildrenPossibleScores(),
    }
  }

  getNumberOfChildsOnDirectChilds() {
    if (this.childs == null) return 0
    let numberOfChildrenToShow = 0
    for (const [_, node] of this.childs) {
      const toShow = node.listToShowThisAndChilds()
      for (const defect of toShow) {
        if (defect.isFiltered) {
          numberOfChildrenToShow++
        }
      }
    }
    return numberOfChildrenToShow
  }

  listOnlyChildsNode() {
    if (this.childs == null) return []
    return Array.from(this.childs.values())
  }

  listToShow() {
    /**@type {import('types/ManualManager').DefectToShow[]} */
    const list = []
    if (
      this.defect != null &&
      this.defect.name &&
      this.defect.isScoring() &&
      this.defect.isFiltered
    ) {
      list.push(this.toPrimitiveWithoutChildsAndFather())
    }

    if (this.childs == null) return list
    for (const [_, node] of this.childs) {
      list.push(...node.listToShow())
    }
    return list
  }

  listToShowThisAndChilds() {
    /**@type {import('types/ManualManager').DefectToShow[]} */
    const list = []
    if (
      this.defect != null &&
      !this.defect.hide &&
      this.defect.isScoring() &&
      this.defect.showWhenFilterOn
    ) {
      list.push(this.toPrimitiveWithoutChildsAndFather())
    }

    if (this.childs == null) return list
    for (const [_, node] of this.childs) {
      if (node != null && !node.defect.hide && node.defect.showWhenFilterOn) {
        list.push(node.toPrimitiveWithoutChildsAndFather())
      }
    }

    return list
  }

  /**
   * @param {string} id
   */
  findChild(id) {
    return this.#findChild(id, this.childs)
  }

  /**
   *
   * @param {string} id
   * @param {Map<string, SectionNode> | null} childs
   * @return {SectionNode | null}
   */
  #findChild(id, childs) {
    if (childs == null) return null
    const node = childs.get(id)
    if (node != null) return node

    for (const [_, child] of childs) {
      const node = this.#findChild(id, child.childs)
      if (node) {
        return node
      }
    }
    return null
  }

  /**
   *
   * @param {boolean} value
   */
  setThisAndChildsApply(value) {
    if (this.defect) {
      this.defect.apply = value
    }
    if (this.childs) {
      for (const [id, child] of this.childs) {
        child.setThisAndChildsApply(value)
      }
    }
  }

  /**
   *
   * @param {boolean} value
   */
  setThisAndChildsApplyAndShow(value) {
    if (this.defect) {
      this.defect.apply = value
      this.defect.isFiltered = value
      this.defect.showWhenFilterOn = value
    }
    if (this.childs) {
      for (const [id, child] of this.childs) {
        child.setThisAndChildsApplyAndShow(value)
      }
    }
  }

  /**
   *
   * @param {boolean} value
   */
  setThisAndChildsDone(value, user) {
    if (this.defect) {
      this.defect.done = value
      this.defect.user = user
    }
    if (this.childs) {
      for (const [id, child] of this.childs) {
        child.setThisAndChildsDone(value, user)
      }
    }
  }

  /**
   *
   * @param {InspectionDefect} defect
   * @returns
   */
  addNewInspectionDefect(defect) {
    if (defect == null) {
      return
    }
    if (defect.id === this.defect.id) {
      throw ErrorForDefect.thisDefectJustExist(defect.id)
    }
    if (defect.getParentIdFormDepth(this.defect.parentOrderDepth) !== this.defect.id) {
      return false
    }
    if (defect.parentOrderDepth - 1 === this.defect.parentOrderDepth) {
      if (this.childs == null) {
        this.childs = new Map()
      }
      this.childs.set(defect.id, new SectionNode({ defect: defect, childs: null, father: this }))
      return true
    }

    if (this.childs == null) {
      throw ErrorForDefect.notHaveNodeToDescend(defect.id, this.defect.id)
    }

    for (const [_, child] of this.childs) {
      const result = child.addNewInspectionDefect(defect)
      if (result) return true
    }

    return false
  }
}
