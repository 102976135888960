import React from 'react'

// MY IMPORTS
import 'components/Others/Spinner/Spinner.scss'
import AnimatedLogoInfinity from '../AnimatedLogo/AnimatedLogoInfinity'

export default function Spinner({ visible }) {
  return (
    <>
      {visible && (
        <>
          <div className="spinner__overlay" />
          <div className="spinner">
            <AnimatedLogoInfinity />
          </div>
        </>
      )}
    </>
  )
}
