import React from 'react'
import Modal from 'components/Others/Modal/Modal'
import isValidFunction from 'myMethods/isValidFunction'
import './MachineInfo.scss'
import moment from 'moment/moment'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import easyToast from 'components/Others/EasyToast/easyToast'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'

/**
 *
 * @param {{testNames: string[], machine: Machine, closeModal: function}} param0
 * @returns
 */
export default function MachineInfo({ testNames, machine, closeModal }) {
  let content = null
  if (!machine) {
    easyToast('error', 'No se ha encontrado información sobre los equipos')
    closeModal()
  }
  let items = []
  if (testNames && testNames.length > 1 && machine) {
    content = (
      <div className="machine-info__container">
        {testNames.map((name, i) => {
          items = [
            <CardNameValue key={i} name="Marca" value={machine.brand} />,
            <CardNameValue key={i} name="ID" value={machine.id[name]} />,
            <CardNameValue key={i} name="Alias" value={machine.alias[name]} />,
            <CardNameValue key={i} name="Número de serie" value={machine.serialNumber[name]} />,
            <CardNameValue key={i} name="Línea" value={machine.line} />,
          ]

          if (machine.tests?.[name]) {
            items.push(
              ...Object.entries(machine.tests[name]).map(([test, date], i) => (
                <CardNameValue
                  key={i}
                  name={test}
                  value={date ? moment(date).format('DD-MM-YYYY') : null}
                  style={{
                    textTransform: 'capitalize',
                  }}
                />
              ))
            )
          }
          return (
            <div className="machine-info" key={i}>
              <ListItemsInTwoColumns animate={true} title={name?.toUpperCase()}>
                {items}
              </ListItemsInTwoColumns>
            </div>
          )
        })}
      </div>
    )
  } else if (testNames && testNames.length === 1 && machine) {
    const testName = testNames[0]
    items = [
      <CardNameValue key={1} name="Marca" value={machine.brand} />,
      <CardNameValue key={2} name="ID" value={machine.id} />,
      <CardNameValue key={3} name="Alias" value={machine.alias} />,
      <CardNameValue key={4} name="Número de serie" value={machine.serialNumber} />,
      <CardNameValue key={5} name="Línea" value={machine.line} />,
    ]

    if (
      machine.settings?.pressureSensors != null &&
      Object.keys(machine.settings.pressureSensors).length
    ) {
      Object.entries(machine.settings.pressureSensors).forEach(([psNumber, value], i) => {
        items.push(
          <CardNameValue key={i} name={`ID del sensor de presión ${psNumber}`} value={value.id} />
        )
      })
    }

    if (machine.tests) {
      items.push(
        ...Object.entries(machine.tests).map(([test, date], i) => (
          <CardNameValue
            key={i}
            name={test}
            value={date ? moment(date).format('DD-MM-YYYY') : null}
            style={{
              textTransform: 'capitalize',
            }}
          />
        ))
      )
    }

    content = (
      <div className="machine-info__container">
        <div className="machine-info">
          <ListItemsInTwoColumns animate={true} title={testName?.toUpperCase()}>
            {items}
          </ListItemsInTwoColumns>
        </div>
      </div>
    )
  }

  return (
    <Modal
      open={true}
      title="Info de equipos"
      onClose={() => isValidFunction(closeModal) && closeModal()}
      hasCloseButton={false}
      content={content}
    />
  )
}
