import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

// MY IMPORTS
import 'components/Office/InvoiceSample/InvoiceSample.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import PDFViewer from 'components/Others/PDFViewer/PDFViewer'

// SERVICES
import { getReport as getReportService } from 'services/Reports'
import Spinner from 'components/Others/Spinner/Spinner'
import openPDF from 'myMethods/openPDF'
import useService from 'hooks/useService'

export default function ReportSample() {
  const [PDF, setPDF] = useState(null)
  const history = useHistory()
  const params = useParams()

  const { fetch: getReport, loading } = useService({
    service: getReportService,
  })

  useEffect(() => {
    getReport(params.reportId)
      .then(resReport => {
        if (resReport && resReport instanceof Blob) {
          setPDF(resReport)
        }
      })
      .catch(err => {
        console.error(err)
        easyToast(
          'error',
          'Ha ocurrido un error cargando el informe. Inténtelo desde el menú historial'
        )
      })
  }, [getReport, params.reportId])

  return (
    <>
      <Spinner visible={loading} />
      <section className="invoice-sample">
        <h2>INFORME FIRMADO CORRECTAMENTE</h2>
        <div className="invoice-sample__btns">
          <i className="flaticon-printing" onClick={() => openPDF(PDF)}></i>
          <div
            onClick={() => {
              window.sessionStorage.setItem('justSupervised', true)
              history.replace('/new_inspection')
            }}
          >
            SALIR
          </div>
        </div>

        <PDFViewer
          pdf={PDF}
          show={true}
          errorMessage="Ha ocurrido un error cargando el informe. Inténtelo desde el menú historial"
          loading={loading}
        />
      </section>
    </>
  )
}
