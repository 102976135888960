import React from 'react'
import { getDealsByChunk as getDealsByChunkService } from 'services/Billing'
import DeepSearch from 'components/Others/DeepSearch/DeepSearch'

export default function AddRectifierDeals({ dealsToHide, closeModal, handleAccept }) {
  return (
    <DeepSearch
      open={true}
      onClose={closeModal}
      title="Añadir albaranes"
      message="Seleccione al menos un albarán"
      onAccept={handleAccept}
      service={getDealsByChunkService}
      columnsKey="deals"
      multiselection={true}
      filterServiceOptions={{
        startDate: true,
        endDate: true,
        likeDni: true,
        likeName: true,
      }}
      forcedFilterValues={{
        inUse: false,
      }}
      idsToExclude={dealsToHide?.map(deal => deal.id)}
    />
  )
}
