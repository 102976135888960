import React, { useState, useRef, useEffect } from 'react'
import { Stage, Layer, Image, Line, Transformer, Circle, Text } from 'react-konva'
import { Button } from '@mui/material'
import {
  ZoomIn,
  ZoomOut,
  ArrowsMove,
  ArrowCounterclockwise,
  Square as RectangleIcon,
  QuestionSquareFill as HelpIcon,
} from 'react-bootstrap-icons'
import './ImagePolygonAnnotator.scss'
import HoverTooltip from '../HoverTooltip/HoverTooltip'
import GuidedTourWrapper from '../GuidedTourWrapper/GuidedTourWrapper'

const EditablePolygon = ({ shapeProps, isSelected, onSelect, onChange, onDelete, isPanning }) => {
  const shapeRef = useRef()
  const trRef = useRef()

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  return (
    <React.Fragment>
      <Line
        onClick={e => {
          e.cancelBubble = true
          onSelect()
        }}
        ref={shapeRef}
        {...shapeProps}
        closed
        draggable={!isPanning}
        fill={shapeProps.fill}
        opacity={0.5}
        stroke={shapeProps.stroke}
        strokeWidth={8}
        onDragEnd={e => {
          if (shapeRef.current) {
            onChange({
              ...shapeProps,
              points: shapeRef.current.points(),
              x: shapeRef.current.x(),
              y: shapeRef.current.y(),
            })
          }
        }}
        onTransformEnd={e => {
          const node = shapeRef.current
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()
          node.scaleX(1)
          node.scaleY(1)
          onChange({
            ...shapeProps,
            points: node
              .points()
              .map((point, i) => (i % 2 === 0 ? point * scaleX : point * scaleY)),
            x: node.x(),
            y: node.y(),
            rotation: node.rotation(),
          })
        }}
        onMouseEnter={e => {
          const container = e.target.getStage().container()
          container.style.cursor = 'move'
        }}
        onMouseLeave={e => {
          const container = e.target.getStage().container()
          container.style.cursor = 'default'
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
      {isSelected && (
        <Text
          text="X"
          x={(shapeProps.points[0] + shapeProps.points[2]) / 2 + shapeProps.x - 10}
          y={(shapeProps.points[1] + shapeProps.points[5]) / 2 + shapeProps.y - 20}
          fontSize={30}
          fill="red"
          onClick={e => {
            e.cancelBubble = true
            onDelete()
          }}
          onMouseEnter={e => {
            const container = e.target.getStage().container()
            container.style.cursor = 'pointer'
          }}
          onMouseLeave={e => {
            const container = e.target.getStage().container()
            container.style.cursor = 'default'
          }}
        />
      )}
      {shapeProps.points.map((point, idx) =>
        idx % 2 === 0 ? (
          <Circle
            key={idx}
            x={shapeProps.points[idx] + shapeProps.x}
            y={shapeProps.points[idx + 1] + shapeProps.y}
            radius={5}
            fill="blue"
            draggable={!isPanning}
            onDragMove={e => {
              const newPoints = shapeProps.points.slice()
              newPoints[idx] = e.target.x() - shapeProps.x
              newPoints[idx + 1] = e.target.y() - shapeProps.y
              onChange({
                ...shapeProps,
                points: newPoints,
              })
            }}
            onMouseEnter={e => {
              const container = e.target.getStage().container()
              container.style.cursor = 'pointer'
            }}
            onMouseLeave={e => {
              const container = e.target.getStage().container()
              container.style.cursor = 'default'
            }}
          />
        ) : null
      )}
    </React.Fragment>
  )
}

const EditableRectangle = ({ shapeProps, isSelected, onSelect, onChange, onDelete, isPanning }) => {
  const shapeRef = useRef()
  const trRef = useRef()

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current])
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected])

  return (
    <React.Fragment>
      <Line
        onClick={e => {
          e.cancelBubble = true
          onSelect()
        }}
        ref={shapeRef}
        {...shapeProps}
        closed
        draggable={!isPanning}
        fill={shapeProps.fill}
        opacity={0.5}
        stroke={shapeProps.stroke}
        strokeWidth={8}
        onDragEnd={e => {
          if (shapeRef.current) {
            onChange({
              ...shapeProps,
              x: shapeRef.current.x(),
              y: shapeRef.current.y(),
            })
          }
        }}
        onTransformEnd={e => {
          const node = shapeRef.current
          const scaleX = node.scaleX()
          const scaleY = node.scaleY()
          node.scaleX(1)
          node.scaleY(1)
          onChange({
            ...shapeProps,
            points: node
              .points()
              .map((point, i) => (i % 2 === 0 ? point * scaleX : point * scaleY)),
            x: node.x(),
            y: node.y(),
            rotation: node.rotation(),
          })
        }}
        onMouseEnter={e => {
          const container = e.target.getStage().container()
          container.style.cursor = 'move'
        }}
        onMouseLeave={e => {
          const container = e.target.getStage().container()
          container.style.cursor = 'default'
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
      {isSelected && (
        <Text
          text="X"
          x={(shapeProps.points[0] + shapeProps.points[4]) / 2 + shapeProps.x - 10}
          y={(shapeProps.points[1] + shapeProps.points[5]) / 2 + shapeProps.y - 20}
          fontSize={30}
          fill="red"
          onClick={e => {
            e.cancelBubble = true
            onDelete()
          }}
          onMouseEnter={e => {
            const container = e.target.getStage().container()
            container.style.cursor = 'pointer'
          }}
          onMouseLeave={e => {
            const container = e.target.getStage().container()
            container.style.cursor = 'default'
          }}
        />
      )}
    </React.Fragment>
  )
}

const ImagePolygonAnnotator = ({ file, polygons, setPolygons }) => {
  const [image, setImage] = useState(null)
  const [imageObject, setImageObject] = useState(null)
  const [currentPolygon, setCurrentPolygon] = useState([])
  const [selectedId, setSelectedId] = useState(null)
  const [mousePosition, setMousePosition] = useState(null)
  const [isPanning, setIsPanning] = useState(false)
  const [imageLoaded, setImageLoaded] = useState(false)
  const [isRectangleMode, setIsRectangleMode] = useState(false)
  const [rectangleStart, setRectangleStart] = useState(null)
  const [stageScale, setStageScale] = useState(1)
  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 })
  const stageRef = useRef()
  const scaleBy = 1.1
  const colors = [
    'rgba(255, 0, 0, 0.5)',
    'rgba(0, 255, 0, 0.5)',
    'rgba(0, 0, 255, 0.5)',
    'rgba(255, 255, 0, 0.5)',
    'rgba(0, 255, 255, 0.5)',
    'rgba(255, 0, 255, 0.5)',
  ]

  useEffect(() => {
    const reader = new FileReader()
    reader.onload = function (event) {
      setImage(event.target.result)
    }
    reader.readAsDataURL(file)
  }, [file])

  useEffect(() => {
    if (image) {
      const img = new window.Image()
      img.src = image
      img.onload = () => {
        setImageObject(img)
        setImageLoaded(true)
      }
    }
  }, [image])

  useEffect(() => {
    if (imageLoaded && imageObject) {
      const timer = setTimeout(() => {
        const containerWidth = window.innerWidth * 0.8
        const containerHeight = window.innerHeight * 0.8
        const scaleX = containerWidth / imageObject.width
        const scaleY = containerHeight / imageObject.height
        const scale = Math.min(scaleX, scaleY)
        setStageScale(scale)

        const stage = stageRef.current
        if (stage) {
          stage.width(containerWidth)
          stage.height(containerHeight)

          const x = (containerWidth - imageObject.width * scale) / 2
          const y = (containerHeight - imageObject.height * scale) / 2
          setStagePosition({ x, y })
          stage.position({ x, y })
          stage.scale({ x: scale, y: scale })
          stage.batchDraw()
        }
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [imageLoaded, imageObject])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const handleStageClick = e => {
    if (polygons.length >= 6 || isPanning) return

    const stage = e.target.getStage()
    const pointerPosition = stage.getPointerPosition()
    if (!pointerPosition) return
    const x = (pointerPosition.x - stage.x()) / stage.scaleX()
    const y = (pointerPosition.y - stage.y()) / stage.scaleY()

    if (isRectangleMode) {
      if (!rectangleStart) {
        setRectangleStart({ x, y })
      } else {
        const rectPoints = [
          rectangleStart.x,
          rectangleStart.y,
          x,
          rectangleStart.y,
          x,
          y,
          rectangleStart.x,
          y,
        ]
        setPolygons([
          ...polygons,
          {
            points: rectPoints,
            fill: colors[polygons.length % colors.length],
            stroke: colors[polygons.length % colors.length].replace('0.5', '1'),
            id: `polygon${polygons.length + 1}`,
            x: 0,
            y: 0,
          },
        ])
        setRectangleStart(null)
      }
    } else {
      setCurrentPolygon([...currentPolygon, x, y])

      if (
        currentPolygon.length >= 4 &&
        Math.abs(currentPolygon[0] - x) < 10 &&
        Math.abs(currentPolygon[1] - y) < 10
      ) {
        setPolygons([
          ...polygons,
          {
            points: currentPolygon,
            fill: colors[polygons.length % colors.length],
            stroke: colors[polygons.length % colors.length].replace('0.5', '1'),
            id: `polygon${polygons.length + 1}`,
            x: 0,
            y: 0,
          },
        ])
        setCurrentPolygon([])
        setMousePosition(null)
      }
    }
  }

  const handleMouseMove = e => {
    if (isPanning) return
    const stage = e.target.getStage()
    const pointerPosition = stage.getPointerPosition()
    if (!pointerPosition) return
    const x = (pointerPosition.x - stage.x()) / stage.scaleX()
    const y = (pointerPosition.y - stage.y()) / stage.scaleY()
    setMousePosition({ x, y })
  }

  const handleContextMenu = e => {
    e.preventDefault()
    undoLastChange()
  }

  const handleKeyDown = e => {
    if (e.key === 'Escape') {
      undoLastChange()
    }
  }

  const zoomIn = () => {
    const stage = stageRef.current
    const oldScale = stage.scaleX()
    const newScale = oldScale * scaleBy
    const pointer = stage.getPointerPosition() || { x: stage.width() / 2, y: stage.height() / 2 }
    const mousePointTo = {
      x: pointer.x / oldScale - stage.x() / oldScale,
      y: pointer.y / oldScale - stage.y() / oldScale,
    }

    setStageScale(newScale)
    stage.scale({ x: newScale, y: newScale })

    const newPos = {
      x: -(mousePointTo.x - pointer.x / newScale) * newScale,
      y: -(mousePointTo.y - pointer.y / newScale) * newScale,
    }
    setStagePosition(newPos)
    stage.position(newPos)
    stage.batchDraw()
  }

  const zoomOut = () => {
    const stage = stageRef.current
    const oldScale = stage.scaleX()
    const newScale = oldScale / scaleBy
    const pointer = stage.getPointerPosition() || { x: stage.width() / 2, y: stage.height() / 2 }
    const mousePointTo = {
      x: pointer.x / oldScale - stage.x() / oldScale,
      y: pointer.y / oldScale - stage.y() / oldScale,
    }

    setStageScale(newScale)
    stage.scale({ x: newScale, y: newScale })

    const newPos = {
      x: -(mousePointTo.x - pointer.x / newScale) * newScale,
      y: -(mousePointTo.y - pointer.y / newScale) * newScale,
    }
    setStagePosition(newPos)
    stage.position(newPos)
    stage.batchDraw()
  }

  const togglePanning = () => {
    setIsPanning(!isPanning)
  }

  const toggleRectangleMode = () => {
    if (currentPolygon.length === 0 && !rectangleStart) {
      setIsRectangleMode(!isRectangleMode)
    }
  }

  const undoLastChange = () => {
    if (currentPolygon.length > 0) {
      setCurrentPolygon(currentPolygon.slice(0, -2))
    } else if (rectangleStart) {
      setRectangleStart(null)
    } else if (polygons.length > 0) {
      setPolygons(polygons.slice(0, -1))
    }
  }

  const updatePolygon = (id, newAttrs) => {
    const updatedPolygons = polygons.map(polygon => {
      if (polygon.id === id) {
        return newAttrs
      }
      return polygon
    })
    setPolygons(updatedPolygons)
  }

  const deletePolygon = id => {
    const filteredPolygons = polygons.filter(polygon => polygon.id !== id)
    setPolygons(filteredPolygons)
    setSelectedId(null)
  }

  const Component = ({ startTour }) => (
    <div onKeyDown={handleKeyDown} tabIndex="0" onContextMenu={handleContextMenu}>
      {imageObject && (
        <>
          <div className="image-polygon-annotator__options">
            <HoverTooltip text="Ayuda">
              <Button className="image-polygon-annotator__button" onClick={startTour}>
                <HelpIcon className="image-polygon-annotator__icon" />
              </Button>
            </HoverTooltip>
            <HoverTooltip text="Acercar">
              <Button
                className="image-polygon-annotator__button"
                disabled={isPanning}
                onClick={zoomIn}
              >
                <ZoomIn className="image-polygon-annotator__icon" />
              </Button>
            </HoverTooltip>
            <HoverTooltip text="Alejar">
              <Button
                className="image-polygon-annotator__button"
                disabled={isPanning}
                onClick={zoomOut}
              >
                <ZoomOut className="image-polygon-annotator__icon" />
              </Button>
            </HoverTooltip>
            <HoverTooltip text="Mover">
              <Button
                className={`image-polygon-annotator__button ${
                  isPanning ? 'image-polygon-annotator__button--selected' : ''
                }`}
                onClick={togglePanning}
              >
                <ArrowsMove className="image-polygon-annotator__icon" />
              </Button>
            </HoverTooltip>
            <HoverTooltip text="Dibujar rectángulo">
              <Button
                className={`image-polygon-annotator__button image-polygon-annotator__button-rectangle ${
                  isRectangleMode ? 'image-polygon-annotator__button--selected' : ''
                }`}
                disabled={isPanning || currentPolygon.length > 0 || rectangleStart}
                onClick={toggleRectangleMode}
              >
                <RectangleIcon className="image-polygon-annotator__icon" />
              </Button>
            </HoverTooltip>
            <HoverTooltip text="Deshacer última modificación">
              <Button
                className="image-polygon-annotator__button image-polygon-annotator__button-undo"
                disabled={isPanning}
                onClick={undoLastChange}
              >
                <ArrowCounterclockwise className="image-polygon-annotator__icon" />
              </Button>
            </HoverTooltip>
          </div>
          <Stage
            ref={stageRef}
            width={window.innerWidth * 0.8}
            height={window.innerHeight * 0.8}
            onClick={handleStageClick}
            onMouseMove={handleMouseMove}
            onContextMenu={handleContextMenu}
            draggable={isPanning}
            style={{
              cursor: isPanning ? 'grab' : 'crosshair',
              margin: 'auto',
              overflow: 'hidden',
            }}
            scale={{ x: stageScale, y: stageScale }}
            position={stagePosition}
          >
            <Layer>
              <Image image={imageObject} width={imageObject.width} height={imageObject.height} />
              {polygons.map((polygon, i) => (
                <React.Fragment key={i}>
                  {polygon.points.length === 8 ? (
                    <EditableRectangle
                      shapeProps={polygon}
                      isSelected={polygon.id === selectedId}
                      onSelect={() => {
                        setSelectedId(polygon.id)
                      }}
                      onChange={newAttrs => {
                        updatePolygon(polygon.id, newAttrs)
                      }}
                      onDelete={() => deletePolygon(polygon.id)}
                      isPanning={isPanning}
                    />
                  ) : (
                    <EditablePolygon
                      shapeProps={polygon}
                      isSelected={polygon.id === selectedId}
                      onSelect={() => {
                        setSelectedId(polygon.id)
                      }}
                      onChange={newAttrs => {
                        updatePolygon(polygon.id, newAttrs)
                      }}
                      onDelete={() => deletePolygon(polygon.id)}
                      isPanning={isPanning}
                    />
                  )}
                </React.Fragment>
              ))}
              <Line
                points={currentPolygon}
                stroke="green"
                strokeWidth={8}
                lineCap="round"
                lineJoin="round"
              />
              {mousePosition && currentPolygon.length > 0 && (
                <Line
                  points={[...currentPolygon, mousePosition.x, mousePosition.y]}
                  stroke={colors[polygons.length % colors.length].replace('0.5', '1')}
                  strokeWidth={8}
                  lineCap="round"
                  lineJoin="round"
                  dash={[4, 4]}
                />
              )}
              {rectangleStart && mousePosition && (
                <Line
                  points={[
                    rectangleStart.x,
                    rectangleStart.y,
                    mousePosition.x,
                    rectangleStart.y,
                    mousePosition.x,
                    mousePosition.y,
                    rectangleStart.x,
                    mousePosition.y,
                    rectangleStart.x,
                    rectangleStart.y,
                  ]}
                  stroke={colors[polygons.length % colors.length].replace('0.5', '1')}
                  strokeWidth={8}
                  lineCap="round"
                  lineJoin="round"
                  dash={[4, 4]}
                />
              )}
            </Layer>
          </Stage>
        </>
      )}
    </div>
  )

  return (
    <GuidedTourWrapper tourKey="itvCardAnnotator">
      <Component />
    </GuidedTourWrapper>
  )
}

export default ImagePolygonAnnotator
