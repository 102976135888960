import React, { useState, memo } from 'react'

// MY IMPORTS
import './ItemPicker.scss'
import Modal from 'components/Others/Modal/Modal'
import isValidFunction from 'myMethods/isValidFunction'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'

/**
 * Componente para seleccionar elementos de una lista. Puede ser en modal
 * @param {{values: string[] | number[], currentValue?: string | number, onClose?: function, onAccept: function, renderOnModal?: boolean, title?: string, animate?: boolean}} param0
 * @returns
 */
const ItemPicker = memo(
  ({
    values,
    currentValue = null,
    onClose,
    onAccept,
    renderOnModal = true,
    title,
    animate = true,
  }) => {
    const [selectedValue, setSelectedValue] = useState(currentValue)
    const [userInteractionDisabled, setUserInteractionDisabled] = useState(false)
    console.log('Rerender itemPicker')

    const handleAccept = async selectedValueProp => {
      try {
        const value = selectedValueProp || selectedValue
        if (value === currentValue) {
          isValidFunction(onClose) && onClose()
          return
        }
        setUserInteractionDisabled(true)
        await onAccept(value)
      } catch (err) {
        console.error(err)
      } finally {
        setUserInteractionDisabled(false)
      }
    }

    const RenderContent = () => (
      <>
        {values && values.length > 0 && (
          <div>
            <ListItemsInTwoColumns title={!renderOnModal && title ? title : null} animate={animate}>
              {values.map((item, i) => {
                let className = 'item-picker__card'
                if (selectedValue === item) className = 'item-picker__card--selected'
                return (
                  <div
                    key={i}
                    onClick={() => {
                      if (userInteractionDisabled) return
                      setSelectedValue(item)
                      if (!renderOnModal) handleAccept(item)
                    }}
                    className={className}
                  >
                    {item}
                  </div>
                )
              })}
            </ListItemsInTwoColumns>
          </div>
        )}
      </>
    )
    if (!renderOnModal) return <RenderContent />

    return (
      <>
        <Modal
          title={title || null}
          open={true}
          onClose={() => isValidFunction(onClose) && onClose()}
          onAccept={handleAccept}
          closeAfterAccept={false}
          content={<RenderContent />}
        />
      </>
    )
  }
)

export default ItemPicker
