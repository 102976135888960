import React, { useState, useEffect } from 'react'

// MY IMPORTS
import 'components/Supervision/NewSupervision/Others/Others.scss'
import Modal from 'components/Others/Modal/Modal'
import { useHistory, useParams } from 'react-router-dom'
import { putInspectionInspector as putInspectionInspectorService } from 'services/Inspection'
import Id from 'Share/Id'
import easyToast from 'components/Others/EasyToast/easyToast'
import Spinner from 'components/Others/Spinner/Spinner'
import { getUsers as getUsersService } from 'services/Users'
import moment from 'moment'
import Table from 'components/Others/Table/Table'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import useService from 'hooks/useService'

export default function Others({ reduxInspection, allowReturn, ...rest }) {
  const [showModal, setShowModal] = useState(false)
  const params = useParams()
  const history = useHistory()
  const [inspectors, setInspectors] = useState([])
  const [repTests, setRepTests] = useState(null)
  const [userThatSignInspection, setUserThatSignInspection] = useState(null)

  const { fetch: getUsers, serviceData: users } = useService({
    service: getUsersService,
  })

  const { fetch: putInspectionInspector, loading } = useService({
    service: putInspectionInspectorService,
  })

  const returnToInspection = async () => {
    try {
      if (!allowReturn) {
        return easyToast('error', 'Este vehículo ya terminó su inspección')
      }
      await putInspectionInspector({
        id: Id.decrypt(sessionStorage.getItem('id')),
        operation: 'returnToInspection',
      })

      history.replace('/new_inspection')
    } catch (error) {
      console.error(error)
      easyToast('error', 'Ha ocurrido un error devolviendo el informe a inspección')
    }
  }

  useEffect(() => {
    try {
      if (reduxInspection?.testsValues && !repTests) {
        let reps = []
        reduxInspection.testsValues.forEach(test => {
          if (test.data.length > 1 && test.data.some(elem => elem.cancellation)) {
            const cancellations = test.data.filter(elem => elem.cancellation)
            cancellations.forEach(can => {
              reps.push({
                testName: test.testName.toUpperCase(),
                reason: can.cancellation.reason.toUpperCase(),
                user: can.cancellation.user,
                date: moment(can.cancellation.date)?.format('DD-MM-YYYY HH:mm:ss'),
              })
            })
          }
        })
        setRepTests(reps)
      }
    } catch (err) {
      console.error(err)
    }
  }, [reduxInspection?.testsValues, repTests])

  useEffect(() => {
    const sendError = err => {
      easyToast('error', 'Error cargando los inspectores que han intervenido en la inspección')
      console.error(err)
    }
    try {
      if (users != null) {
        let inspectors = {}
        if (reduxInspection?.inspector) {
          inspectors[reduxInspection.inspector.usuario_inspector] = ['FIRMA INSPECCIÓN']
        }
        reduxInspection.defectsDone?.forEach(elem => {
          if (elem.user) {
            inspectors[elem.user] = [
              ...new Set([...(inspectors[elem.user] || []), 'DEFECTOS VISUALES']),
            ]
          }
        })
        reduxInspection.complementaryEquipment?.forEach(elem => {
          if (elem.user) {
            inspectors[elem.user] = [
              ...new Set([...(inspectors[elem.user] || []), 'EQUIPOS COMPLEMENTARIOS']),
            ]
          }
        })

        reduxInspection.testsValues?.forEach(test => {
          test.data.forEach(testData => {
            if (testData.user) {
              inspectors[testData.user] = [
                ...new Set([...(inspectors[testData.user] || []), test.testName.toUpperCase()]),
              ]
            }
            if (testData.cancellation && testData.cancellation.user) {
              inspectors[testData.cancellation.user] = [
                ...new Set([
                  ...(inspectors[testData.cancellation.user] || []),
                  test.testName.toUpperCase(),
                ]),
              ]
            }
          })
        })
        let aux = []
        Object.entries(inspectors).forEach(([user, contributions]) => {
          const found = users.find(elem => elem.username === user)
          if (found) {
            aux.push({
              user,
              fullname: `(${user}) ` + found.fullname?.toUpperCase(),
              contributions: contributions.join(' • '),
            })
          }
        })

        setInspectors(aux)
      }
    } catch (err) {
      sendError(err)
    }
  }, [
    reduxInspection.defectsDone,
    reduxInspection.testsValues,
    reduxInspection.hardwareStatus,
    users,
    reduxInspection.complementaryEquipment,
  ])

  useEffect(() => {
    if (users == null) getUsers()
  }, [getUsers, users])

  useEffect(() => {
    if (users && !userThatSignInspection) {
      const user = users.find(user => user.username === reduxInspection.inspector)
      user && setUserThatSignInspection(user)
    }
  }, [reduxInspection?.inspector, userThatSignInspection, users])

  return (
    <>
      <Spinner visible={loading} />
      <section className="others" {...rest}>
        {allowReturn && (
          <MyButtonsContainer margin="1.5rem auto">
            <MyButton text="DEVOLVER A INSPECCIÓN" onClick={() => setShowModal(true)} />
          </MyButtonsContainer>
        )}

        <div className="others__table-container">
          <h4>INSPECTORES INTERVINIENTES</h4>
          <Table
            columnsNames={['Usuario', 'Nombre', 'Contribución']}
            values={inspectors?.map((data, i) => {
              return {
                id: i,
                data: [data.user, data.fullname, data.contributions],
              }
            })}
            noValuesText="Ningún inspector intervino"
          />
        </div>

        <div className="others__table-container">
          <h4>TESTS REPETIDOS</h4>
          <Table
            columnsNames={['Test', 'Motivo', 'INSPECTOR', 'Fecha']}
            values={repTests?.map((data, i) => {
              return {
                id: i,
                data: [data.testName, data.reason, data.user, data.date],
              }
            })}
            noValuesText="Ningún test fue repetido"
          />
        </div>
        <div className="others__table-container">
          <h4>EQUIPOS COMPLEMENTARIOS USADOS</h4>
          <Table
            columnsNames={['Nombre', 'ID', 'Nº Serie', 'Usuario']}
            values={reduxInspection?.complementaryEquipment?.map((data, i) => {
              return {
                id: i,
                data: [
                  data.equipment.name,
                  data.equipment.id,
                  data.equipment.serialNumber,
                  data.user,
                ],
              }
            })}
            noValuesText="No se usó ningún equipo complementario"
          />
        </div>
      </section>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        onAccept={returnToInspection}
        title={`¿Devolver informe de ${params.license} a inspección?`}
        message="Ningún dato se perderá"
        alertMessage="No podrá seguir la supervisión hasta que termine la inspección del vehículo"
        loading={loading}
      />
    </>
  )
}
