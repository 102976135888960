import axios from 'axios'
import moment from 'moment'
import { getTpv, postTpvCharge, postTpvRefund } from 'services/Tpv'

export default class Tpv {
  constructor({ terminal, name }) {
    this.terminal = terminal
    this.name = name
  }

  /**
   * @return {Promise<Tpv>}
   */
  static async create() {
    try {
      const res = await getTpv()
      if (res.status === 400) {
        return
      }
      if (res.status === 200 && res.data) {
        return new Tpv({ terminal: res.data.terminal, name: res.data.name })
      }
    } catch (err) {
      console.error(err)
      return
    }
  }

  async charge(amount, ref) {
    try {
      console.log('ENTRO CHARGE')
      if (!this.terminal) throw new Error('No terminal was constructed')

      if (!amount || isNaN(amount)) throw new Error('Missing amount or amount is NaN')

      const data = {
        terminal: this.terminal,
        operacion: 'PAGO',
        test: 'TEST',
        datos: {
          importe: '0.01' || amount.toString(),
          factura: ref || moment().format('DD/MM/YYYY HH:mm:ss'),
        },
      }

      const res = await postTpvCharge(data)
      if (!res) throw new Error('No response from TPV api')
      if (res?.data && res?.data?.resultadoOperacion === 'AUTORIZADA') {
        return { ok: true, error: null, data: res.data }
      }
    } catch (err) {
      console.error(err)
      return { ok: false, error: err, data: null }
    }
  }

  async refund({ amount, ref, numPedido, rts }) {
    try {
      console.log('ENTRO REFUND')
      if (!this.terminal) throw new Error('No terminal was constructed')
      console.log({ amount, ref, numPedido, rts })
      if (!(amount && !isNaN(amount) && numPedido && rts))
        throw new Error('Missing params for refund')

      const data = {
        importe: '0.01' || amount.toString(),
        referencia: ref || moment().format('DD/MM/YYYY HH:mm:ss'),
        numPedido,
        rts,
      }

      const res = await postTpvRefund(data)
      console.log({ res })
      if (!res) throw new Error('No response from TPV api')
      if (res?.data && res?.data?.resultadoOperacion === 'Autorizada') {
        return { ok: true, error: null, data: res.data, duplicate: false }
      } else if (res?.date && res?.data?.datos?.codigo === '409') {
        return { ok: true, error: null, data: res.data, duplicate: true }
      } else throw new Error('No data received from TPV api')
    } catch (err) {
      console.error(err)
      return { ok: false, error: err, data: null, duplicate: false }
    }
  }
}
