import React from 'react'

//MY IMPORTS
import 'components/Others/Title/Title.scss'

export default function Title({ className = '', text }) {
  let finalText = text

  if (typeof text === 'object') {
    finalText = ''
    text.forEach((elem, i) => {
      if (i === 0) {
        finalText += elem.toUpperCase() + ' Y '
      } else {
        finalText += elem.toUpperCase()
      }
    })
  }
  return <h2 className={`title${className ? ' ' + className : ''}`}>{finalText}</h2>
}
