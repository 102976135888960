import Modal from 'components/Others/Modal/Modal'
import React, { useRef, useCallback, useMemo, useEffect } from 'react'
import { Form, Formik, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import Input from 'components/Others/Input/Input'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import './HeavyHitch.scss'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import round from 'myMethods/round'
import { putInspectionCalculations as putInspectionCalculationsService } from 'services/Inspection'
import useService from 'hooks/useService'
import isValidFunction from 'myMethods/isValidFunction'
import Id from 'Share/Id'
import easyToast from 'components/Others/EasyToast/easyToast'

const initialvalues = {
  a: 1.8,
  l: '',
  x: '',
  c: '',
  T: '',
  V: '',
  Dc: '',
  suspensionNeumatica: true,
  results: {
    V: {
      value: '',
      kg: '',
      valid: null,
    },
    Dc: {
      value: '',
      valid: null,
    },
  },
}

const CalcResults = () => {
  const { values, setFieldValue } = useFormikContext()

  const calcV = useCallback(() => {
    try {
      let calc = round((values.x * values.x) / (values.l * values.l))
      if (calc < 1) calc = 1

      return round(values.a * calc * values.c)
    } catch (err) {
      console.error(err)
      return null
    }
  }, [values.a, values.c, values.l, values.x])

  const calcDc = useCallback(() => {
    try {
      return round((9.81 * (values.t * values.c)) / (values.t + values.c))
    } catch (err) {
      console.error(err)
    }
  }, [values.c, values.t])

  useEffect(() => {
    const newAValue = values.suspensionNeumatica ? 1.8 : 2.4
    setFieldValue('a', newAValue)
  }, [values.suspensionNeumatica, setFieldValue])

  useEffect(() => {
    if (values.l && values.x && values.c && values.V) {
      const V = calcV()
      setFieldValue('results.V.value', V)
      setFieldValue('results.V.kg', round(V * 101.97))
      setFieldValue('results.V.valid', V <= values.V)
    } else {
      setFieldValue('results.V.value', '')
      setFieldValue('results.V.kg', '')
      setFieldValue('results.V.valid', null)
    }
  }, [calcV, setFieldValue, values.V, values.c, values.l, values.x])

  useEffect(() => {
    if (values.t && values.c && values.Dc) {
      const Dc = calcDc()
      setFieldValue('results.Dc.value', Dc)
      setFieldValue('results.Dc.valid', Dc <= values.Dc)
    } else {
      setFieldValue('results.Dc.value', '')
      setFieldValue('results.Dc.valid', null)
    }
  }, [calcDc, setFieldValue, values.Dc, values.c, values.t])

  return null
}

export default function HeavyHitch({ closeModal }) {
  const formRef = useRef()
  const inspectionCalculations = useSelector(store => store.inspection.calculations)

  const { fetch: putCalculations, loading } = useService({
    service: putInspectionCalculationsService,
  })

  const handleInitialValues = useCallback(() => {
    if (inspectionCalculations?.heavyHitch) return inspectionCalculations.heavyHitch

    return initialvalues
  }, [inspectionCalculations?.heavyHitch])

  const persistValues = async shouldCloseModal => {
    try {
      const values = formRef.current.values
      let newInspectionCalculations = { ...(inspectionCalculations ?? {}), heavyHitch: values }
      await putCalculations({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        calculations: newInspectionCalculations,
      })
      if (shouldCloseModal && isValidFunction(closeModal)) closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error al guardar los valores')
    }
  }

  const formikInitialValues = useMemo(() => handleInitialValues(), [handleInitialValues])

  return (
    <Modal
      open={true}
      closeOnOverlay={false}
      closeAfterAccept={false}
      onAccept={() => persistValues(true)}
      title="Enganche de pesados"
      onClose={closeModal}
      loading={loading}
      content={
        <div className="heavy-hitch">
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={formikInitialValues}
            onSubmit={() => {}}
          >
            {({ values, setFieldValue }) => (
              <Form>
                {console.log({ values })}
                <CalcResults />
                <div className="heavy-hitch__form">
                  <div className="heavy-hitch__inputs-box">
                    <div className="heavy-hitch__title">Valor V</div>
                    <div className="heavy-hitch__inputs">
                      <Checkbox
                        name="suspensionNeumatica"
                        label="Suspensión neumática"
                        checked={values.suspensionNeumatica}
                        onChange={checked => setFieldValue('suspensionNeumatica', checked)}
                      />
                      <Input
                        name="a"
                        label="Aceleración de comparación en el punto de acoplamiento"
                        disabled={true}
                        unit="m/s²"
                      />
                      <Input
                        name="l"
                        label="Longitud teórica de la barra de tracción (timón)"
                        unit="m"
                      />
                      <Input name="x" label="Longitud de la superficie de carga" unit="m" />
                      <Input
                        name="c"
                        label="Suma de las cargas de los ejes del remolque"
                        unit="T"
                      />
                      <Input name="V" label="V de certificado" unit="Kn" />
                    </div>
                    <div
                      className={`heavy-hitch__result ${
                        values.results.V.valid != null
                          ? values.results.V.valid
                            ? 'heavy-hitch__result--valid'
                            : 'heavy-hitch__result--invalid'
                          : ''
                      }`}
                    >
                      <CardNameValue name="V" value={values.results.V.value} unit="Kn" />
                      <CardNameValue name="V" value={values.results.V.kg} unit="Kg" />
                    </div>
                  </div>
                  <div className="heavy-hitch__inputs-box">
                    <div className="heavy-hitch__title">Valor Dc</div>
                    <div className="heavy-hitch__inputs">
                      <Input name="t" label="MMA del vehículo tractor" unit="T" />
                      <Input
                        name="c"
                        label="Suma de las cargas de los ejes del remolque"
                        unit="T"
                      />
                      <Input name="Dc" label="Dc de certificado" unit="Kn" />
                    </div>
                    <div
                      className={`heavy-hitch__result ${
                        values.results.Dc.valid != null
                          ? values.results.Dc.valid
                            ? 'heavy-hitch__result--valid'
                            : 'heavy-hitch__result--invalid'
                          : ''
                      }`}
                    >
                      <CardNameValue name="Dc" value={values.results.Dc.value} unit="Kn" />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  )
}
