//@ts-check
// @ts-ignore
import React, { useState, useEffect } from 'react'
import Modal from 'components/Others/Modal/Modal'
import Input from 'components/Others/Input/Input'
import useService from 'hooks/useService'
import { putInspectionConfig as putInspectionConfigService } from 'services/Inspection'
import isValidFunction from 'myMethods/isValidFunction'
import Id from 'Share/Id'
import Switch from 'components/Others/Switch/Switch'
import { useDispatch } from 'react-redux'
import { setReduxInspectionConfig } from 'redux/ducks/inspectionDuck'

/**
 *
 * @param {{inspectionConfig: import('types/Inspection').Config, onClose: function}} param0
 * @returns
 */
export default function MechanicForm({ inspectionConfig, onClose }) {
  const [values, setValues] = useState({ name: '', selfRepair: false })
  const dispatch = useDispatch()
  const { fetch: putInspectionConfig, loading } = useService({
    service: putInspectionConfigService,
  })

  const handleAccept = async () => {
    try {
      let newValues = { ...values }
      if (values.selfRepair) {
        newValues.name = ''
      }
      const newConfig = { ...(inspectionConfig || {}), mechanic: newValues }
      const res = await putInspectionConfig({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        config: newConfig,
      })
      console.log({ res })
      dispatch(setReduxInspectionConfig(res?.config ?? null))
      isValidFunction(onClose) && onClose()
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const mechanic = inspectionConfig?.mechanic
    if (mechanic && !values.cif && !values.name) {
      if (mechanic.selfRepair == true) {
        setValues(prevState => ({
          ...prevState,
          selfRepair: true,
        }))
      } else {
        setValues(prevState => ({
          ...prevState,
          name: mechanic.name ?? '',
        }))
      }
    }
  }, [inspectionConfig?.mechanic, values.cif, values.name])

  return (
    <Modal
      open={true}
      title="Identificar taller de reparación"
      onAccept={handleAccept}
      closeAfterAccept={false}
      onClose={onClose}
      loading={loading}
      content={
        <div>
          <Switch
            name="selfRepair"
            label="Auto reparación"
            value={values.selfRepair}
            onChange={value => setValues(prevState => ({ ...prevState, selfRepair: value }))}
          />
          {!values.selfRepair && (
            <>
              <Input
                isFormikInput={false}
                label="NIF/Razón social"
                name="name"
                placeholder="NIF/Razón social"
                value={values.name}
                maxLength={64}
                onChange={e => {
                  setValues(prevState => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }}
              />
            </>
          )}
        </div>
      }
    />
  )
}
