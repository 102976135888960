//@ts-check

import DefectId from './DefectId'
import ErrorForDefect from './ErrorForDefect'

/**
 * @typedef {import('types/Inspection').MachineDefect} InspectionMachineDefectPrimitive
 * @typedef {import('types/Inspection').VisualDefect} InspectionVisualDefectPrimitive
 * @typedef {import('types/TestValue').TestValuesData} TestValues
 * @typedef {"G"|"L"|"VG"} Score
 */

export default class InspectionDefect {
  #id
  /**@type {Score | null} */
  #score

  /**@type {boolean} */
  #done
  /**
   *
   * @param {DefectId} id "a.b.c.d" where letters are numbers
   * @param {string} name
   * @param {("G"|"L"|"VG")[]| null} possibleScores
   * @param {boolean} apply
   * @param {boolean} done
   * @param {boolean} optional
   * @param {boolean} isFiltered
   * @param {boolean} showWhenFilterOn
   * @param {{name: string, automatic: boolean} | null} machineInfo
   * @param {string | null} user
   * @param {string | null} remark
   * @param {TestValues | null}  testData
   * @param {InspectionMachineDefectPrimitive | null} machineDefect
   * @param {InspectionVisualDefectPrimitive | null}  visualDefect
   * @param {Score | null } score
   * @param {import('types/Inspection').InspectionDefectImagePrimitive | null} image
   */
  constructor(
    id,
    name,
    possibleScores,
    apply,
    done,
    optional,
    isFiltered,
    showWhenFilterOn,
    machineInfo,
    user,
    remark,
    testData,
    visualDefect,
    machineDefect,
    score,
    image
  ) {
    this.#id = id
    this.name = name
    this.possibleScores = possibleScores
    this.optional = optional
    this.apply = apply
    this.isFiltered = isFiltered ?? false
    this.showWhenFilterOn = showWhenFilterOn ?? true
    this.machineInfo = machineInfo
    this.#done = done
    this.user = user
    this.remark = remark
    this.testData = testData ?? null
    this.machineDefect = machineDefect ?? null
    this.visualDefect = visualDefect ?? null
    this.#score = score ?? null
    this.image = image
  }

  /**
   *
   * @param {any} sectionId
   */
  static validateSectionId(sectionId) {
    if (typeof sectionId !== 'string' || sectionId.length === 0) {
      throw ErrorForDefect.invalidIdFormat(sectionId)
    }
    const sections = sectionId.split('.')
    sections.forEach(familyOrder => {
      if (familyOrder == null || Number.isNaN(familyOrder) || familyOrder === '') {
        throw ErrorForDefect.invalidIdFormat(sectionId)
      }
    })
    return sectionId
  }

  get id() {
    return this.#id.toPrimitive()
  }
  get score() {
    return this.#score
  }

  toString() {
    return JSON.stringify(this.toPrimitives())
  }

  set score(newScore) {
    if (
      this.isScoring() === false ||
      this.possibleScores == null ||
      this.possibleScores.length === 0
    ) {
      throw ErrorForDefect.defectNotScoring(this.id)
    }

    if (newScore != null && this.possibleScores.includes(newScore) === false) {
      throw ErrorForDefect.defectDontHaveThisScoreOnPossibleScores(newScore, this.possibleScores)
    }
    this.#score = newScore
  }
  /**
   * Crea una copia profunda y valida a la vez
   * @param {any} calification
   * @returns {("G"|"L"|"VG")[] | null}
   */
  static validCalification(calification) {
    if (calification == null) return null
    if (typeof calification === 'boolean') return null
    if (!Array.isArray(calification)) {
      throw ErrorForDefect.invalidScore(calification)
    }
    /**@type {("G"|"L"|"VG")[]} */
    const filteredCalifications = []
    for (const value of calification) {
      if (value === 'G') {
        filteredCalifications.push('G')
        continue
      }
      if (value === 'VG') {
        filteredCalifications.push('VG')
        continue
      }
      if (value === 'L') {
        filteredCalifications.push('L')
        continue
      }
      throw ErrorForDefect.invalidScore(value)
    }
    return filteredCalifications
  }

  /**
   *
   * @param {{id: string, name: string, possibleScores: Score[]| null , apply: boolean, done: boolean,showWhenFilterOn: boolean, machineInfo: {name: string, automatic: boolean}| null, user: string | null, remark: string | null, isFiltered?: boolean
   * testData: TestValues | null,
   * visualDefect: InspectionVisualDefectPrimitive | null ,
   * machineDefect: InspectionMachineDefectPrimitive | null,
   * score: Score | null, optional: boolean, image: import('types/Inspection').InspectionDefectImagePrimitive | null}} param0
   * @returns
   */
  static formPrimitives({
    id,
    name,
    possibleScores,
    apply,
    done,
    isFiltered,
    machineInfo,
    showWhenFilterOn,
    user,
    remark,
    testData,
    visualDefect,
    machineDefect: machineDefects,
    score: calificationSet,
    optional,
    image,
  }) {
    InspectionDefect.validateSectionId(id)
    return new InspectionDefect(
      DefectId.formPrimitive(id),
      name,
      InspectionDefect.validCalification(possibleScores),
      apply,
      done,
      optional,
      isFiltered ?? false,
      showWhenFilterOn,
      machineInfo,
      user,
      remark,
      testData,
      visualDefect,
      machineDefects,
      calificationSet,
      image
    )
  }

  toPrimitives() {
    return {
      id: this.id,
      name: this.name,
      possibleScores: this.possibleScores,
      apply: this.apply,
      done: this.done,
      score: this.#score,
      remarks: this.remark,
      isFiltered: this.isFiltered,
      showWhenFilterOn: this.showWhenFilterOn,
      hide: this.hide,
      optional: this.optional,
      user: this.user,
      remark: this.remark,
      testData: this.testData,
      visualDefect: this.visualDefect,
      machineDefect: this.machineDefect,
    }
  }
  isScoring() {
    if (this.possibleScores == null || this.possibleScores.length === 0) return false
    return true
  }

  get hide() {
    if (!this.name) return true
    if (this.machineInfo?.automatic) return true
    if (this.testData && !this.testData.machine?.manual) return true
    if (!this.apply) {
      if (this.done) return false
      if (!this.isFiltered) return false
      else return true
    }
    return false
  }

  get detailedName() {
    const father = this.father

    console.log({ father })
    return null
  }

  /**
   *
   * @returns {string| null}
   */
  get charper() {
    return this.#id.charper
  }
  /**
   *
   * @returns {string| null}
   */
  get father() {
    return this.#id.father
  }

  /**
   *
   * @returns {string| null}
   */
  get son() {
    return this.#id.son
  }
  /**
   *
   * @returns {string| null}
   */
  get grandson() {
    return this.#id.grandson
  }

  get parentOrderFive() {
    return this.#id.parentOrderFive
  }

  getParentOrder() {
    const familyOrder = InspectionDefect.validateSectionId(this.id).split('.')
    switch (familyOrder.length) {
      case 1:
        return 'CHARPER'
      case 2:
        return 'FATHER'
      case 3:
        return 'SON'
      case 4:
        return 'GRANDSON'
      case 5:
        return 'CINCO'

      default:
        throw ErrorForDefect.invalidParentOrder(familyOrder.length)
    }
  }

  /**
   *
   * @param {number} depth
   */
  getParentIdFormDepth(depth) {
    switch (depth) {
      case 1:
        return this.charper
      case 2:
        return this.father
      case 3:
        return this.son
      case 4:
        return this.grandson
      case 5:
        return this.parentOrderFive
      default:
        return null
    }
  }

  get parentOrderDepth() {
    return this.#id.parentOrderDepth
  }

  /**
   *
   * @param {InspectionMachineDefectPrimitive} machineDefect
   */
  addMachineDefect(machineDefect) {
    if (machineDefect.defect.id !== this.id) {
      throw ErrorForDefect.defectIdNotMatch(machineDefect.defect.id, this.id)
    }
    if (this.apply === false) {
      throw ErrorForDefect.defectNotApply(this.id)
    }
    if (this.visualDefect != null) {
      throw ErrorForDefect.machineDefectAndVisualDefectCannotMatch()
    }

    this.machineDefect = machineDefect
    this.#score = this.#choseMaxCalification(machineDefect.calification)
    this.done = true
  }

  /**
   *
   * @param {InspectionVisualDefectPrimitive} visualDefect
   */
  addVisualDefect(visualDefect) {
    if (visualDefect.id !== this.id) {
      throw ErrorForDefect.defectIdNotMatch(visualDefect.id, this.id)
    }
    if (this.apply === false) {
      // throw ErrorForDefect.defectNotApply(this.id)
    }
    if (this.machineDefect != null) {
      throw ErrorForDefect.machineDefectAndVisualDefectCannotMatch()
    }

    this.score = this.#choseMaxCalification(visualDefect.values.calification)
    this.visualDefect = visualDefect
    this.apply = true
    this.done = true
    this.user = visualDefect.user
  }

  /**
   *
   * @param {string} visualDefectId
   * @param {string} user
   */
  deleteVisualDefect(visualDefectId, user) {
    if (visualDefectId !== this.id) {
      throw ErrorForDefect.defectIdNotMatch(visualDefectId, this.id)
    }
    if (this.apply === false) {
      // throw ErrorForDefect.defectNotApply(this.id)
    }
    if (
      this.machineDefect != null &&
      Array.isArray(this.machineDefect) &&
      this.machineDefect.length > 0
    ) {
      throw ErrorForDefect.machineDefectAndVisualDefectCannotMatch()
    }
    if (this.user && this.user !== user) {
      throw ErrorForDefect.defectDoneWithOtherUser(this.user, user)
    }

    this.visualDefect = null
    this.score = null
    this.done = true
    this.user = null
  }

  set done(value) {
    if (value === false) {
      if (this.visualDefect != null) {
        throw ErrorForDefect.defectNotDoneFalseHaveDefect(this.id, 'Visual Defect')
      }
      if (
        this.machineDefect != null &&
        Array.isArray(this.machineDefect) &&
        this.machineDefect.length > 0
      ) {
        throw ErrorForDefect.defectNotDoneFalseHaveDefect(this.id, 'Machine Defect')
      }
    }
    this.#done = value
  }

  get done() {
    return this.#done
  }

  /**
   *
   * @param {Score[]|Score | string} score
   * @return {Score}
   */
  #choseMaxCalification(score) {
    const validCalification = InspectionDefect.validCalification([score])
    if (validCalification == null) {
      throw ErrorForDefect.invalidScore(validCalification)
    }

    /**@type {Score} */
    let maxClaification = 'L'
    for (const calification of validCalification) {
      const { weight, code } = this.#getWeigthAndCalification(calification)
      if (this.#getWeigthAndCalification(maxClaification).weight < weight) {
        maxClaification = code
      }
    }
    return maxClaification
  }

  haveVisualDefect() {
    if (this.visualDefect != null) {
      return true
    }
    return false
  }

  /**
   *
   * @param {string} score
   * @return {{weight: number, code: Score}}
   */
  #getWeigthAndCalification(score) {
    switch (score.toUpperCase()) {
      case 'L':
        return { weight: 1, code: 'L' }
      case 'G':
        return { weight: 2, code: 'G' }
      case 'VG':
        return { weight: 3, code: 'VG' }
      default:
        return { weight: 1, code: 'L' }
    }
  }
}
