import CashRegistersConfigurator from 'components/Office/CashRegister/CashRegistersManager/CashRegistersConfigurator'
import DiscountsConfigurator from 'components/Office/DiscountsConfigurator/DiscountsConfigurator'
import FeesConfigurator from 'components/Office/FeesConfigurator/FeesConfigurator'
import MaterialTab from 'components/Others/MaterialTab/MaterialTab'
import React from 'react'

export default function OfficeConfigurator() {
  return (
    <MaterialTab
      tabs={[{ text: 'Cajas' }, { text: 'Promociones' }, { text: 'Tarifas' }]}
      contents={[<CashRegistersConfigurator />, <DiscountsConfigurator />, <FeesConfigurator />]}
    />
  )
}
