//@ts-check
import React, { useState } from 'react'
import { X as CloseIcon, Plus as AddIcon } from 'react-bootstrap-icons'
import MyButtonsContainer from '../Buttons/MyButtonsContainer'
import MyButton from '../Buttons/MyButton/MyButton'
import Modal from '../Modal/Modal'
import easyToast from '../EasyToast/easyToast'
import './ListOfString.scss'
import { AnimatePresence, motion } from 'framer-motion'
import AnimatedListElem from 'components/Others/AnimatedList/AnimatedListElem'
import Input from '../Input/Input'

/**
 *
 * @param {{values: string[], setValues: (values: string[]) => void, maxLength?: number, removable?: boolean, editable?: boolean, title?: string, addItemsText?: string, addItemsIcon?: any, childrenBefore?: import('react').ReactElement }} param0
 */
export default function ListOfStrings({
  values = [],
  setValues,
  maxLength,
  removable = true,
  editable = true,
  title,
  addItemsText = 'Añadir',
  addItemsIcon = <AddIcon />,
  childrenBefore,
}) {
  const [inputValue, setInputValue] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [indexToEdit, setIndexToEdit] = useState(null)

  const handleModalAccept = () => {
    try {
      const newValues = [...values]
      if (indexToEdit !== null) {
        newValues[indexToEdit] = inputValue
      } else {
        newValues.push(inputValue)
      }
      setValues(newValues)
      setShowModal(false)
      setIndexToEdit(null)
      setInputValue('')
    } catch (err) {
      console.error(err)
      easyToast(
        'error',
        indexToEdit !== null ? 'Error al editar el elemento' : 'Error al añadir el elemento'
      )
    }
  }

  const removeElement = index => {
    try {
      const newValues = [...values]
      newValues.splice(index, 1)
      setValues(newValues)
    } catch (err) {
      console.error(err)
    }
  }

  const handleClickEdit = (index, value) => {
    setIndexToEdit(index)
    setInputValue(value)
    setShowModal(true)
  }

  return (
    <>
      <div className="list-of-strings">
        {title && <div className="list-of-strings__title">{title}</div>}
        {childrenBefore !== null && React.cloneElement(childrenBefore)}
        {(maxLength == null || (maxLength !== null && values.length < maxLength)) && (
          <MyButtonsContainer>
            <MyButton text={addItemsText} icon={addItemsIcon} onClick={() => setShowModal(true)} />
          </MyButtonsContainer>
        )}
        {editable && values.length > 0 && (
          <div className="list-of-strings__hint">Pulsa un elemento de la lista para editarlo</div>
        )}
        {values.length > 0 && (
          <motion.div className="list-of-strings__list">
            <AnimatePresence>
              {values.map((value, i) => (
                <AnimatedListElem index={i} key={i}>
                  <div
                    key={i}
                    className="list-of-strings__elem"
                    onClick={() => handleClickEdit(i, value)}
                  >
                    <div className="list-of-strings__text">{value}</div>
                    {removable && (
                      <div className="list-of-strings__removable">
                        <CloseIcon
                          onClick={e => {
                            e.stopPropagation()
                            removeElement(i)
                          }}
                        />
                      </div>
                    )}
                  </div>
                </AnimatedListElem>
              ))}
            </AnimatePresence>
          </motion.div>
        )}
      </div>
      {showModal && (
        <Modal
          open={showModal}
          title={indexToEdit !== null ? 'Editar' : addItemsText}
          onClose={() => {
            setShowModal(false)
            setIndexToEdit(null)
            setInputValue('')
          }}
          onAccept={handleModalAccept}
          closeAfterAccept={false}
          content={
            <Input
              type="textarea"
              isFormikInput={false}
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
            />
          }
        />
      )}
    </>
  )
}
