import React from 'react'

// MY IMPORTS
import './TimedOutTests.scss'

export default function TimedOutTests({ data }) {
  return (
    <>
      <div className="timed-out-tests">
        {data &&
          data.length > 0 &&
          data.map((elem, id) => {
            return (
              <div key={id}>
                <div className="timed-out-tests__header">
                  <div>
                    {elem.name?.toUpperCase()}
                    {elem.alias && <span>{elem.alias}</span>}
                  </div>
                  <div>
                    {elem.id && <div>{elem.id}</div>}
                    {elem.line && <div>LÍNEA {elem.line}</div>}
                  </div>
                </div>
                <div className="timed-out-tests__body">
                  {elem.timedOutTests?.map((test, i) => {
                    const Message = () => {
                      const days = test.daysElapsed
                      if (days > 0) {
                        return (
                          <>
                            Caducó hace <span>{days}</span> {days > 1 ? 'días' : 'día'}
                          </>
                        )
                      }
                      if (days < 0) {
                        return (
                          <>
                            {days < -1 ? 'Quedan' : 'Queda'}{' '}
                            <span className="timed-out-tests__on-time">{days / -1}</span>{' '}
                            {days < -1 ? 'días' : 'día'}
                          </>
                        )
                      }
                      if (days === 0)
                        return (
                          <>
                            Caduca <span>hoy</span>
                          </>
                        )
                    }
                    return (
                      <div key={i}>
                        <div>{test.testName?.replace('_', ' ')?.toUpperCase()}</div>
                        <div>
                          <span className={test.daysElapsed < 0 ? 'timed-out-tests__on-time' : ''}>
                            {test.date}
                          </span>
                        </div>
                        <div>{<Message />}</div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
      </div>
    </>
  )
}
