//@ts-check

import React, { useEffect, useState } from 'react'
import Popup from 'reactjs-popup'
import 'reactjs-popup/dist/index.css'
import 'components/Others/Modal/Modal.scss'
import ModalButtons from './ModalButtons'
import isValidFunction from 'myMethods/isValidFunction'
import ChildSpinner from '../Spinner/ChildSpinner'
import { Check as CheckIcon, X as CloseIcon } from 'react-bootstrap-icons'

/**
 * Modal Component
 * @param {Object} props - Component props
 * @param {string} [props.className] - Additional class names for the modal
 * @param {boolean} [props.open] - Indicates if the modal is open
 * @param {boolean} [props.show] - Another flag to indicate if the modal is open
 * @param {Function} [props.onClose] - Function to call when the modal is closed
 * @param {boolean} [props.closeOnOverlay] - Should the modal close when clicking on the overlay
 * @param {boolean} [props.hasCloseButton] - Should the modal have a close button
 * @param {boolean} [props.hasAcceptButton] - Should the modal have an accept button
 * @param {boolean} [props.closeAfterAccept] - Should the modal close after accepting
 * @param {JSX.Element} [props.acceptButtonIcon] - Icon for the accept button
 * @param {JSX.Element} [props.closeButtonIcon] - Icon for the close button
 * @param {string} [props.acceptButtonText] - Text for the accept button
 * @param {string} [props.closeButtonText] - Text for the close button
 * @param {string} [props.title] - Title of the modal
 * @param {JSX.Element} [props.icon] - Icon for the modal
 * @param {string} [props.message] - Message to display in the modal
 * @param {string} [props.message2] - Second message to display in the modal
 * @param {string} [props.alertMessage] - Alert message to display in the modal
 * @param {JSX.Element} [props.content] - Content of the modal
 * @param {Function} [props.onAccept] - Function to call when the accept button is clicked
 * @param {Function} [props.onCloseButton] - Function to call when the close button is clicked
 * @param {boolean} [props.loading] - Is the modal in loading state
 * @param {boolean} [props.mintLoadingIcon] - Should display mint loading icon
 * @param {string} [props.loadingText] - Text to display during loading
 * @param {boolean} [props.buttons] - Should display buttons
 * @param {boolean} [props.noPadding] - Should remove padding
 * @param {boolean} [props.closeOnEscape] - Should close modal on escape key
 * @param {boolean} [props.acceptOnEnter] - Should accept on enter key
 * @returns {JSX.Element} Modal component
 */
export default function Modal({
  className = '',
  open = false,
  show = false,
  onClose = null,
  closeOnOverlay = true,
  hasCloseButton = true,
  hasAcceptButton = true,
  closeAfterAccept = true,
  acceptButtonIcon = <CheckIcon />,
  closeButtonIcon = <CloseIcon />,
  acceptButtonText = '',
  closeButtonText = '',
  title = '',
  icon = null,
  message = '',
  message2 = '',
  alertMessage = '',
  content = null,
  onAccept = null,
  onCloseButton = null,
  loading = false,
  mintLoadingIcon = true,
  loadingText = '',
  buttons = true,
  noPadding = false,
  closeOnEscape = false,
  acceptOnEnter = true,
}) {
  const [internalLoading, setInternalLoading] = useState(false)
  const isLoading = loading || internalLoading
  const isOpen = open || show

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
      return () => {
        document.body.style.overflow = 'unset'
      }
    }
  }, [isOpen])

  const handleOnClose = () => {
    if (isValidFunction(onClose)) onClose()
  }

  const modalClasses = `${className || 'modal-popup'} ${
    noPadding ? (className ? `${className}--no-padding` : '') + ' modal-popup--no-padding' : ''
  }`

  return (
    <Popup
      open={isOpen}
      className={modalClasses}
      closeOnEscape={closeOnEscape}
      closeOnDocumentClick={closeOnOverlay}
      onClose={handleOnClose}
      lockScroll={true}
      nested={true}
    >
      <div className="modal" id="modal">
        {(title || message || alertMessage) && (
          <div className="modal__header">
            <div className="modal__title">
              {icon}
              {title && <h2>{title}</h2>}
            </div>
            {message && <h3>{message}</h3>}
            {message2 && <h3>{message2}</h3>}
            {alertMessage && <h4>{alertMessage}</h4>}
          </div>
        )}
        {isLoading && <ChildSpinner tight={false} mintLogo={mintLoadingIcon} text={loadingText} />}
        {!isLoading && content && <div className="modal-content">{content}</div>}
      </div>
      {buttons && !isLoading && (
        <ModalButtons
          onAccept={onAccept}
          onClose={onClose}
          onCloseButton={onCloseButton}
          hasAcceptButton={hasAcceptButton}
          hasCloseButton={hasCloseButton}
          closeAfterAccept={closeAfterAccept}
          setInternalLoading={setInternalLoading}
          acceptButtonIcon={acceptButtonIcon}
          closeButtonIcon={closeButtonIcon}
          acceptButtonText={acceptButtonText}
          closeButtonText={closeButtonText}
          acceptOnEnter={acceptOnEnter}
        />
      )}
    </Popup>
  )
}
