export default function reduceHigherDefectScore(defects, key = 'score') {
    const PRIORITY = {
      L: 1,
      G: 2,
      VG: 3,
    };
  
    if (defects.length === 0) return null;
  
    const maxScore = defects.reduce((max, def) => {
      const currentScore = PRIORITY[def[key]];
      const maxScore = PRIORITY[max[key]];
  
      if (currentScore == null) return max;
      if (maxScore == null) return def; 
      return currentScore > maxScore ? def : max;
    }, defects[0])?.[key];
  
    return maxScore ?? null;
  }
  