import moment from 'moment'

export default function getLastTestValue(testName, testsValues) {
  try {
    const outIndex = testsValues?.findIndex(elem => elem.testName === testName)
    let test = testsValues.find(elem => elem.testName === testName)

    if (test && test.machine) {
      test =
        test?.data
          ?.filter(elem => !elem.rep)
          ?.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
          ?.reverse()[0] || null

      if (!test || test.rep) return null
      const inIndex = testsValues
        .find(elem => elem.testName === testName)
        .data.findIndex(elem => elem.date === test.date)

      if (!(outIndex >= 0 && inIndex >= 0)) return null
      return {
        test,
        outIndex,
        inIndex,
      }
    } else if (test && !test.machine) {
      return { test }
    } else return null
  } catch (err) {
    console.error(err)
    return null
  }
}
