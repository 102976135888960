//@ts-check
import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLongPress } from 'use-long-press'

// MY IMPORTS
import 'components/Inspection/DefectsSupervisor/DefectsSupervisor.scss'
import Modal from 'components/Others/Modal/Modal'
import easyToast from 'components/Others/EasyToast/easyToast'
import Id from 'Share/Id'

// SERVICES
import MachineDefectDetail from './MachineDefectDetail/MachineDefectDetail'
import ModalDefect from '../Defectology/Modal/ModalDefect/ModalDefect'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import useService from 'hooks/useService'
import { deleteInspectionVisualDefect as deleteInspectionVisualDefectService } from 'services/Inspection'
import SuperList from 'components/Others/SuperList/SuperList'
import getCssVariable from 'myMethods/getCssVariable'
import ListCard from 'components/Others/ListCard/ListCard'
import calificationToText from 'myMethods/calificationToText'
import sortArrayOfObjectsByDate from 'myMethods/sortArrayOfObjectsByDate'
import { CameraFill } from 'react-bootstrap-icons'

/**
 * @typedef {import('types/Inspection').VisualDefect} VisualDefect
 * @typedef {import('types/Inspection').MachineDefect} MachineDefect
 * @typedef {import('Share/Defect/InspectionDefectsManager').default} ManualManager
 */

/**
 *
 * @param {{onlyRead?: boolean, isPreviousInspection?: boolean, isSupervisor?: boolean, visualDefects: VisualDefect[], machineDefects: MachineDefect[], manualManager: ManualManager}} param0
 * @returns
 */
export default function DefectsSupervisor({
  onlyRead = false,
  isPreviousInspection = false,
  isSupervisor = false,
  visualDefects,
  machineDefects,
  manualManager,
  ...rest
}) {
  const dispatch = useDispatch()
  const redux = useSelector(store => store)
  const [visualDefectIdToShow, setVisualDefectIdToShow] = useState(null)
  const [isLongPressed, setIsLongPressed] = useState(false)
  const [modalDetail, setModalDetail] = useState({ show: false, detail: null })
  /**@type {(VisualDefect | MachineDefect)[]} */
  const allDefects = visualDefects?.concat(machineDefects) ?? []
  const [types, setTypes] = useState(['l', 'g', 'vg', 'visual', 'machine'])
  const [loadingDefectId, setLoadingDefectId] = useState(null)
  if (isPreviousInspection) onlyRead = true

  const { fetch: deleteDefect } = useService({
    service: deleteInspectionVisualDefectService,
  })

  const hasAuth = useCallback(
    defectId => {
      if (!defectId) throw new Error('No defectId provided')
      const found =
        visualDefects?.find(elem => elem.id === defectId) ||
        machineDefects?.find(elem => elem.defect.id === defectId)
      if (!found) throw new Error('defect not found')
      if (found.user === redux?.user?.data?.user || isSupervisor) {
        return { auth: true, user: found.user }
      } else {
        return { auth: false, user: found.user }
      }
    },
    [isSupervisor, machineDefects, redux?.user?.data?.user, visualDefects]
  )

  const getDetailedName = defectId => {
    try {
      let name = ''
      const splittedId = defectId.split('.')
      for (let index = 1; index < splittedId.length; index++) {
        let idToFind = ''
        Array(index + 1)
          .fill(null)
          .forEach((_, i) => {
            idToFind += splittedId[i]
            if (i !== index) idToFind += '.'
          })
        const found = manualManager.findDefectAndChilds(idToFind)
        if (found) {
          name += found.defect.name
        }
        if (index + 1 !== splittedId.length) name += ' - '
      }
      return name
    } catch (err) {
      console.error(err)
    }
  }

  const handleDelete = async defect => {
    try {
      const authCheck = hasAuth(defect.id)
      if (!authCheck.auth) {
        return dispatch(
          setReduxConfigModal({
            error: true,
            message:
              'No puedes eliminar un defecto añadido por otro usuario o de una inspección ya terminada',
            message2: authCheck.user ? 'Usuario: ' + authCheck.user : null,
            onClose: () => isLongPressed && setIsLongPressed(false),
          })
        )
      }
      setLoadingDefectId(defect.id)
      await deleteDefect({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        defectId: defect.id,
      })

      easyToast('dark', 'Defecto eliminado')
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error eliminando el defecto')
    } finally {
      setLoadingDefectId(null)
    }
  }

  const handleEdit = defect => {
    try {
      if (isLongPressed) return
      if (!isPreviousInspection) {
        const defectIdToShow = manualManager.findDefectAndChilds(defect.id)?.defect?.id
        if (!defectIdToShow) throw new Error('No error found')
        setVisualDefectIdToShow(defectIdToShow)
      } else {
        setVisualDefectIdToShow(defect.id)
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Defecto no encontrado')
    }
  }

  const handleDetail = defect => {
    setModalDetail({ show: true, detail: defect })
  }

  const colors = {
    L: getCssVariable('--l-defect-color'),
    G: getCssVariable('--g-defect-color'),
    VG: getCssVariable('--vg-defect-color'),
    visual: getCssVariable('--visual-defect-color'),
    machine: getCssVariable('--machine-defect-color'),
  }

  const filteredDefects = allDefects.filter(defect => {
    const isMachineDefect = Boolean(defect.testName)
    const calification = isMachineDefect
      ? defect.calification?.toLowerCase()
      : defect.values?.calification?.toLowerCase()
    if (
      (isMachineDefect && types.includes('machine')) ||
      (!isMachineDefect && types.includes('visual'))
    ) {
      if (types.includes(calification)) return true
    }
    return false
  })

  return (
    <>
      <section className="defects-supervisor" {...rest}>
        {!onlyRead && (
          <div className="defects-supervisor__title">
            <h3>Defectos aplicados</h3>
            {!onlyRead && (
              <div className="defects-supervisor__info">
                <span>Mantén pulsado para eliminar un defecto</span>
                <span>
                  Los defectos de máquina no pueden ser eliminados mientras exista la prueba
                </span>
              </div>
            )}
          </div>
        )}
        {isPreviousInspection && (
          <div className="defects-supervisor__title">
            <h3>Defectos aplicados en la última inspección de este vehículo</h3>
          </div>
        )}
        <SuperList
          className="defects-supervisor__super-list"
          noItemsMessage="No hay defectos aplicados"
          search={true}
          loading={loadingDefectId}
          options={[
            {
              text: 'Leves',
              color: colors.L,
              checked: types.includes('l'),
              counter: allDefects?.filter(defect => defect.calification === 'L').length ?? 0,
              onClick: () =>
                setTypes(types =>
                  types.includes('l') ? types.filter(t => t !== 'l') : [...types, 'l']
                ),
            },
            {
              text: 'Graves',
              color: colors.G,
              checked: types.includes('g'),
              counter: allDefects?.filter(defect => defect.calification === 'G').length ?? 0,
              onClick: () =>
                setTypes(types =>
                  types.includes('g') ? types.filter(t => t !== 'g') : [...types, 'g']
                ),
            },
            {
              text: 'Muy graves',
              color: colors.VG,
              checked: types.includes('vg'),
              counter: allDefects?.filter(defect => defect.calification === 'VG').length ?? 0,
              onClick: () =>
                setTypes(types =>
                  types.includes('vg') ? types.filter(t => t !== 'vg') : [...types, 'vg']
                ),
            },
            {
              text: 'Visuales',
              color: colors.visual,
              checked: types.includes('visual'),
              counter: visualDefects?.length ?? 0,
              onClick: () =>
                setTypes(types =>
                  types.includes('visual')
                    ? types.filter(t => t !== 'visual')
                    : [...types, 'visual']
                ),
            },
            {
              text: 'Máquina',
              color: colors.machine,
              checked: types.includes('machine'),
              counter: machineDefects?.length ?? 0,
              onClick: () =>
                setTypes(types =>
                  types.includes('machine')
                    ? types.filter(t => t !== 'machine')
                    : [...types, 'machine']
                ),
            },
          ]}
        >
          {sortArrayOfObjectsByDate(filteredDefects, 'date', false).map((defect, i) => {
            console.log({ filteredDefects })
            const isMachineDefect = Boolean(defect.testName)
            if (isMachineDefect)
              defect = { ...defect, id: defect.defect.id, name: defect.defect.name }
            else defect.calification = defect.values?.calification ?? defect.calification
            defect.name = getDetailedName(defect.id)
            defect.remark = defect.remark ?? defect.values?.remark ?? null

            const tags = [
              {
                text: calificationToText(defect.calification, false),
                color: colors[defect.calification],
              },
              {
                text: isMachineDefect ? 'Máquina' : 'Visual',
                color: isMachineDefect ? colors.machine : colors.visual,
              },
            ]
            if (isMachineDefect) {
              tags.push({
                text: `${defect.value}${defect.unit ? defect.unit : ''}`,
                color: getCssVariable('--gray-color'),
              })
            }
            if (defect.remark) {
              tags.push({
                icon: <i className="flaticon-message-1" />,
              })
            }
            if (redux?.inspection?.images?.some(image => image.defectId === defect.id)) {
              tags.push({
                icon: <CameraFill />,
              })
            }
            return (
              <ListCard
                key={i}
                id={defect.id}
                tags={tags}
                loading={loadingDefectId === defect.id}
                onClick={() => (isMachineDefect ? handleDetail(defect) : handleEdit(defect))}
                onDelete={isMachineDefect ? undefined : () => handleDelete(defect)}
                date={defect.date}
                content={
                  <div className="defects-supervisor__defect">
                    <div className="defects-supervisor__defect-id">{defect.id}</div>
                    <div className="defects-supervisor__defect-name">{defect.name}</div>
                  </div>
                }
              />
            )
          })}
        </SuperList>
      </section>

      <Modal
        open={modalDetail.show}
        onClose={() => setModalDetail({ show: false, detail: null })}
        closeOnOverlay={true}
        buttons={true}
        hasCloseButton={false}
        content={
          modalDetail.detail && (
            <MachineDefectDetail
              defectId={modalDetail?.detail?.defect?.id}
              maxRemarkLength={redux?.config?.itv?.maxTextsLengths?.defectRemark}
              machineDefects={machineDefects}
              letModify={(() => {
                try {
                  if (onlyRead) return false
                  return hasAuth(modalDetail?.detail?.defect?.id)?.auth
                } catch (err) {
                  console.error(err)
                  return false
                }
              })()}
              sectionNumber={redux?.inspection?.filter?.section}
              inspectionType={redux?.inspection?.inspectionType}
              vehicleData={redux?.inspection?.vehicleData}
            />
          )
        }
      />

      {visualDefectIdToShow && (
        <ModalDefect
          defectId={visualDefectIdToShow}
          closeModal={() => setVisualDefectIdToShow(null)}
        />
      )}
    </>
  )
}
