import React, { useState, useEffect } from 'react'

// MY IMPORTS
import './ListCashRegisterMovements.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import ListCashRegisterMovementsTable from './ListCashRegisterMovementsTable'

export default function ListCashRegisterMovements({ status, selectedDate }) {
  const [data, setData] = useState([])

  useEffect(() => {
    try {
      if (status?.archingState) {
        let aux = []
        if (!status.isClosed) {
          aux.push(
            ...status.archingState.deals,
            ...status.archingState.invoices,
            ...status.archingState.cashFlows
          )
        }
        status.archings?.forEach(elem => {
          if (elem.reason !== 'INICIO') aux.push(...elem.invoices, ...elem.deals, ...elem.cashFlows)
        })
        if (aux && aux.length)
          aux = aux?.map(elem => {
            if (elem.reason) return { ...elem, concepto: elem.reason }
            if (elem.report?.inspeccion) return { ...elem, concepto: elem.report.inspeccion }
            return elem
          })
        setData(aux)
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los movimientos')
    }
  }, [status])
  return (
    <section className="list-cash-register-movements">
      <ListCashRegisterMovementsTable
        data={data}
        endOfDay={status?.name === 'ITV'}
        cashRegisterName={status?.name}
        selectedDate={selectedDate}
      />
    </section>
  )
}
