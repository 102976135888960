//@ts-check
import React from 'react'
import Switch from 'components/Others/Switch/Switch'
import './VelocimetroConfig.scss'
import TestConfigWrapper from '../../../TestConfigWrapper/TestConfigWrapper'

export default function VelocimetroConfig({ setValues, values, disabled = false }) {
  return (
    <TestConfigWrapper testName="Velocímetro" disabled={disabled}>
      <div className="velocimetro-config">
        <div className="velocimetro-config__switch">
          <Switch
            disabled={disabled}
            label="Caja de cambios manual"
            name="hasGears"
            value={values?.hasGears}
            onChange={checked => setValues({ ...values, hasGears: checked })}
          />
        </div>
      </div>
    </TestConfigWrapper>
  )
}
