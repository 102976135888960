//ts-check
import React from 'react'
import { motion } from 'framer-motion'

const animationVariants = {
  fadeIn: {
    hidden: { opacity: 0 },
    visible: ({ delay }) => ({
      opacity: 1,
      transition: { delay, duration: 0.8 },
    }),
  },
  fadeInUp: {
    hidden: { opacity: 0, y: 20 },
    visible: ({ delay }) => ({
      opacity: 1,
      y: 0,
      transition: { delay, duration: 0.8 },
    }),
  },
  fadeInDown: {
    hidden: { opacity: 0, y: -20 },
    visible: ({ delay }) => ({
      opacity: 1,
      y: 0,
      transition: { delay, duration: 0.8 },
    }),
  },
  fadeInLeft: {
    hidden: { opacity: 0, x: -20 },
    visible: ({ delay }) => ({
      opacity: 1,
      x: 0,
      transition: { delay, duration: 0.8 },
    }),
  },
  fadeInRight: {
    hidden: { opacity: 0, x: 20 },
    visible: ({ delay }) => ({
      opacity: 1,
      x: 0,
      transition: { delay, duration: 0.8 },
    }),
  },
  zoomIn: {
    hidden: { opacity: 0, scale: 0.8 },
    visible: ({ delay }) => ({
      opacity: 1,
      scale: 1,
      transition: { delay, duration: 0.8 },
    }),
  },
  zoomOut: {
    hidden: { opacity: 0, scale: 1.2 },
    visible: ({ delay }) => ({
      opacity: 1,
      scale: 1,
      transition: { delay, duration: 0.8 },
    }),
  },
  slideInUp: {
    hidden: { y: 20 },
    visible: ({ delay }) => ({
      y: 0,
      transition: { delay, duration: 0.8 },
    }),
  },
  slideInDown: {
    hidden: { y: -20 },
    visible: ({ delay }) => ({
      y: 0,
      transition: { delay, duration: 0.8 },
    }),
  },
  slideInLeft: {
    hidden: { x: -20 },
    visible: ({ delay }) => ({
      x: 0,
      transition: { delay, duration: 0.8 },
    }),
  },
  slideInRight: {
    hidden: { x: 20 },
    visible: ({ delay }) => ({
      x: 0,
      transition: { delay, duration: 0.8 },
    }),
  },
}

/**
 * AnimatedListElem Component
 *
 * This component animates its child element based on the specified animation type.
 *
 * @param {{ children: React.ReactNode, index: number, className?: string, animationType?: 'fadeIn' | 'fadeInUp' | 'fadeInDown' | 'fadeInLeft' | 'fadeInRight' | 'zoomIn' | 'zoomOut' | 'slideInUp' | 'slideInDown' | 'slideInLeft' | 'slideInRight' }} props
 * @returns {JSX.Element}
 */
export default function AnimatedListElem({ children, index, className, animationType = 'fadeIn' }) {
  const variants = animationVariants[animationType] || animationVariants.fadeIn

  return (
    <motion.div
      custom={{ delay: index * 0.08 }}
      initial="hidden"
      animate="visible"
      exit="hidden"
      variants={variants}
      style={{ width: '100%', height: '100%' }}
      className={`animated-list-elem__wrapper ${className ? className : ''}`}
    >
      {React.cloneElement(children, { id: 'animated-list-elem' })}
    </motion.div>
  )
}
