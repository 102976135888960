import React from 'react'
import './BigButtonsContainer.scss'

export default function BigButtonsContainer({
  margin = '1.5rem auto 0 auto',
  styles = {},
  className = '',
  children = null,
  ...rest
}) {
  try {
    if (children && Array.isArray(children)) {
      children = children?.filter(children => children)
    } else if (children && !Array.isArray(children)) {
      children = [children]
    }

    return (
      <div
        className={`${className ? `big-buttons-container ${className}` : 'big-buttons-container'}`}
        style={{ margin, ...styles }}
        {...rest}
      >
        {children}
      </div>
    )
  } catch (err) {
    console.error(err)
    return <></>
  }
}
