import React, { useImperativeHandle, useRef, useState, forwardRef } from 'react'
import CaptureImage from 'components/Inspection/CaptureImage/CaptureImage'
import Modal from 'components/Others/Modal/Modal'
import ImagePolygonAnnotator from 'components/Others/Canvas/ImagePolygonAnnotator'
import easyToast from 'components/Others/EasyToast/easyToast'
import useService from 'hooks/useService'
import { postRecognizeItvCard } from 'services/Inspection'

const ITVCardRecognition = forwardRef(({ setRecognizedValues }, ref) => {
  const captureImageRef = useRef()
  const [modal, setModal] = useState({
    show: false,
    image: null,
  })
  const [polygons, setPolygons] = useState([])

  const { fetch: recognize, loading } = useService({
    service: postRecognizeItvCard,
    timeout: 60000,
  })

  const handleAccept = async () => {
    try {
      if (!polygons.length)
        return easyToast('error', 'Debe seleccionar al menos una columna para reconocer')

      const fd = new FormData()
      fd.append('image', modal.image)
      fd.append('polygons', JSON.stringify(polygons))
      const res = await recognize(fd)
      if (!res || !(typeof res === 'object') || Object.keys(res).length < 0)
        throw new Error('Response is not an object')
      setRecognizedValues(res)
      setModal({ show: false, image: null })
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error reconociendo la imagen. Inténtalo de nuevo')
    }
  }

  useImperativeHandle(ref, () => ({
    capture: () => {
      if (captureImageRef.current) {
        setPolygons([])
        captureImageRef.current.triggerCapture()
      }
    },
  }))

  return (
    <>
      {modal.show && (
        <Modal
          show={true}
          title={loading ? null : 'Selecciona las columnas a reconocer'}
          content={
            <ImagePolygonAnnotator
              file={modal.image}
              polygons={polygons}
              setPolygons={setPolygons}
            />
          }
          onClose={() => setModal({ show: false, image: null })}
          onAccept={handleAccept}
          loading={loading}
          loadingText="Reconociendo tarjeta, este proceso puede durar unos segundos..."
          mintLoadingIcon={true}
          closeAfterAccept={false}
          closeOnOverlay={false}
        />
      )}
      <CaptureImage
        geolocate={false}
        ref={captureImageRef}
        onCaptureImage={image => setModal({ show: true, image })}
        showImageAfterCapture={false}
        letBrowseFiles={true}
      />
    </>
  )
})

export default ITVCardRecognition
