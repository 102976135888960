// constantes
const initialData = {
  roles: null,
  fuels: null,
  countries: null,
  categories: null,
  clientPaymentMethods: null,
  inspectionTypes: null,
  manualSections: null,
  reformsManualSections: null,
}

// types
const SET_RESOURCES_ROLES_SUCCESS = 'SET_RESOURCES_ROLES_SUCCESS'
const SET_RESOURCES_FUELS_SUCCESS = 'SET_RESOURCES_FUELS_SUCCESS'
const SET_RESOURCES_COUNTRIES_SUCCESS = 'SET_RESOURCES_COUNTRIES_SUCCESS'
const SET_RESOURCES_CATEGORIES_SUCCESS = 'SET_RESOURCES_CATEGORIES_SUCCESS'
const SET_RESOURCES_CLIENT_PAYMENT_METHODS_SUCCESS = 'SET_RESOURCES_CLIENT_PAYMENT_METHODS_SUCCESS'
const SET_RESOURCES_INSPECTION_TYPES_SUCCESS = 'SET_RESOURCES_INSPECTION_TYPES_SUCCESS'
const SET_RESOURCES_MANUAL_SECTIONS_SUCCESS = 'SET_RESOURCES_MANUAL_SECTIONS_SUCCESS'
const SET_RESOURCES_REFORMS_MANUAL_SECTIONS_SUCCESS =
  'SET_RESOURCES_REFORMS_MANUAL_SECTIONS_SUCCESS'

// reducer
export default function configReducer(state = initialData, action) {
  switch (action.type) {
    case SET_RESOURCES_ROLES_SUCCESS:
      return { ...state, roles: action.payload }
    case SET_RESOURCES_FUELS_SUCCESS:
      return { ...state, fuels: action.payload }
    case SET_RESOURCES_COUNTRIES_SUCCESS:
      return { ...state, countries: action.payload }
    case SET_RESOURCES_CATEGORIES_SUCCESS:
      return { ...state, categories: action.payload }
    case SET_RESOURCES_CLIENT_PAYMENT_METHODS_SUCCESS:
      return { ...state, clientPaymentMethods: action.payload }
    case SET_RESOURCES_INSPECTION_TYPES_SUCCESS:
      return { ...state, inspectionTypes: action.payload }
    case SET_RESOURCES_MANUAL_SECTIONS_SUCCESS:
      return { ...state, manualSections: action.payload }
    case SET_RESOURCES_REFORMS_MANUAL_SECTIONS_SUCCESS:
      return { ...state, reformsManualSections: action.payload }
    default:
      return state
  }
}

// actions
export const setReduxResourcesRoles = data => dispatch => {
  try {
    dispatch({
      type: SET_RESOURCES_ROLES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxResourcesFuels = data => dispatch => {
  try {
    dispatch({
      type: SET_RESOURCES_FUELS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxResourcesCountries = data => dispatch => {
  try {
    dispatch({
      type: SET_RESOURCES_COUNTRIES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxResourcesCategories = data => dispatch => {
  try {
    dispatch({
      type: SET_RESOURCES_CATEGORIES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxResourcesClientPaymentMethods = data => dispatch => {
  try {
    dispatch({
      type: SET_RESOURCES_CLIENT_PAYMENT_METHODS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxResourcesInspectionTypes = data => dispatch => {
  try {
    dispatch({
      type: SET_RESOURCES_INSPECTION_TYPES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxResourcesManualSections = data => dispatch => {
  try {
    dispatch({
      type: SET_RESOURCES_MANUAL_SECTIONS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxResourcesReformsManualSections = data => dispatch => {
  try {
    dispatch({
      type: SET_RESOURCES_REFORMS_MANUAL_SECTIONS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}
