import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export function postInitInspection(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}/init_inspection`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putEndSupervision(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}use_cases/end_supervision`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}
