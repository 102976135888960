//@ts-check
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

// MY IMPORTS
import LinesConfig from 'components/Supervision/ItvConfigurator/LinesConfig/LinesConfig'
import Title from 'components/Others/Title/Title'
import ControlPanel from 'components/Supervision/ItvConfigurator/ControlPanel/ControlPanel'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import FiltersModal from 'components/Supervision/ItvConfigurator/FiltersModal/FiltersModal'

export default function ItvConfiguratorPage() {
  const redux = useSelector(state => state)
  const itv = redux?.config?.itv
  const [showFilterModal, setShowFilterModal] = useState(false)
  console.log({ itv })
  return (
    <>
      <Title text="CONFIGURAR ITV" />
      <ControlPanel itv={itv} />
      <MyButtonsContainer margin="1.5rem auto 1.5rem">
        <MyButton
          text="EDITAR FILTROS"
          icon={<i className="flaticon-filter" />}
          transparent={false}
          onClick={() => setShowFilterModal(true)}
        />
      </MyButtonsContainer>
      <LinesConfig
        hardwareByLine={redux?.config?.hardwareByLine}
        complementaryEquipment={redux?.config?.complementaryEquipment}
      />
      {showFilterModal && (
        <FiltersModal
          closeModal={() => setShowFilterModal(false)}
          filters={redux?.config?.filters}
        />
      )}
    </>
  )
}
