import Accordion from 'components/Others/Accordion/Accordion'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import easyToast from 'components/Others/EasyToast/easyToast'
import ErrorView from 'components/Others/ErrorView/ErrorView'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import useService from 'hooks/useService'
import formatEuro from 'myMethods/formatEuro'
import React, { useEffect, useState } from 'react'
import {
  postCalculateFeeTotal as postCalculateFeeTotalService,
  putFee as putFeeService,
} from 'services/Fee'
import './FeesConfigurator.scss'
import { getResourcesFees } from 'services/Resources'

export default function FeesConfigurator() {
  const [unlockedFees, setUnlockedFees] = useState([null, null])
  const [feesByInspectionType, setFeesByInspectionType] = useState([])

  const { fetch: getAllFees, loading: loadingGetAll } = useService({
    service: getResourcesFees,
    handleResponseData: res => setFeesByInspectionType(res?.fees ?? []),
  })
  const { fetch: postCalculateFeeTotal } = useService({
    service: postCalculateFeeTotalService,
  })
  const { fetch: putFee, loading: loadingSaveChanges } = useService({
    service: putFeeService,
  })

  const unlockFees = (parentIndex, feeIndex) => {
    try {
      if (parentIndex == null || feeIndex == null)
        throw new Error('Parent index and fee index is required')
      const foundFee = feesByInspectionType[parentIndex]?.fees[feeIndex]?.values
      if (!foundFee) throw new Error('No fee found')
      setUnlockedFees([parentIndex, feeIndex])
      return foundFee
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error habilitando la edición')
    }
  }

  const calculateFee = async feeData => {
    try {
      const res = await postCalculateFeeTotal({ fee: feeData })
      return res.fee ?? feeData
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error calculando el total de la tarifa')
    }
  }

  const saveChanges = async (inspectionType, feeId, feeData) => {
    try {
      const res = await putFee({ inspectionType, feeId, fee: feeData })
      setFeesByInspectionType(res?.fees || [])
      setUnlockedFees([null, null])
      easyToast('dark', 'Datos guardados')
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error guardando los datos')
    }
  }

  const FeeContent = ({ fee, feeInfo, inspectionType, parentIndex, feeIndex }) => {
    const [feeData, setFeeData] = useState(
      feesByInspectionType[parentIndex]?.fees?.[feeIndex]?.values
    )
    const isUnlocked = unlockedFees[0] === parentIndex && unlockedFees[1] === feeIndex
    return (
      <div className="fees-configurator__fee-content">
        {!loadingSaveChanges && (
          <div className="fees-configurator__fee-inputs">
            <div className="fees-configurator__fee-input">
              <label>Inspección</label>
              {isUnlocked && (
                <input
                  type="number"
                  value={feeData?.inspection}
                  onChange={async e => {
                    setFeeData(
                      await calculateFee({
                        ...feeData,
                        inspection: parseFloat(e.target.value || 0),
                      })
                    )
                  }}
                />
              )}
              {!isUnlocked && (
                <div className="fees-configurator__fee-value">
                  {formatEuro(fee.inspection || 0)}
                </div>
              )}
            </div>
            <div className="fees-configurator__fee-input">
              <label>Emisiones</label>
              {isUnlocked && (
                <input
                  type="number"
                  value={feeData?.emissions}
                  onChange={async e => {
                    setFeeData(
                      await calculateFee({ ...feeData, emissions: parseFloat(e.target.value || 0) })
                    )
                  }}
                />
              )}
              {!isUnlocked && (
                <div className="fees-configurator__fee-value">{formatEuro(fee.emissions || 0)}</div>
              )}
            </div>
            <div className="fees-configurator__fee-input">
              <label>Tráfico</label>
              {isUnlocked && (
                <input
                  type="number"
                  value={feeData?.traffic}
                  onChange={async e => {
                    setFeeData(
                      await calculateFee({ ...feeData, traffic: parseFloat(e.target.value || 0) })
                    )
                  }}
                />
              )}
              {!isUnlocked && (
                <div className="fees-configurator__fee-value">{formatEuro(fee.traffic || 0)}</div>
              )}
            </div>
            <div className="fees-configurator__fee-input">
              <label>Otras tasas</label>
              {isUnlocked && (
                <input
                  type="number"
                  value={feeData?.otherTaxes}
                  onChange={async e => {
                    setFeeData(
                      await calculateFee({
                        ...feeData,
                        otherTaxes: parseFloat(e.target.value || 0),
                      })
                    )
                  }}
                />
              )}
              {!isUnlocked && <div>{formatEuro(fee.otherTaxes || 0)}</div>}
            </div>
            <div className="fees-configurator__fee-input">
              <label>IVA</label>
              {isUnlocked && (
                <input
                  type="number"
                  value={feeData?.IVA}
                  onChange={async e => {
                    setFeeData(
                      await calculateFee({
                        ...feeData,
                        IVA: parseFloat(e.target.value || 0),
                      })
                    )
                  }}
                />
              )}
              {!isUnlocked && (
                <div className="fees-configurator__fee-value">{fee.IVA ?? 0 + '%'}</div>
              )}
            </div>
            <div className="fees-configurator__fee-input">
              <label>TOTAL</label>
              <div className="fees-configurator__fee-value">{formatEuro(feeData.total || 0)}</div>
            </div>
          </div>
        )}
        {loadingSaveChanges && <ChildSpinner />}
        <MyButtonsContainer>
          <MyButton
            loading={loadingSaveChanges}
            transparent={!isUnlocked}
            text={isUnlocked ? 'Guardar' : 'Habilitar edición'}
            icon={<i className={isUnlocked ? 'flaticon-diskette' : 'flaticon-pencil'} />}
            onClick={async () => {
              if (isUnlocked) {
                saveChanges(inspectionType, feeInfo?.id, feeData)
              } else {
                if (!feeData) easyToast('error', 'Ha ocurrido un error habilitando la edición')
                unlockFees(parentIndex, feeIndex)
              }
            }}
          />
        </MyButtonsContainer>
      </div>
    )
  }

  useEffect(() => {
    if (!feesByInspectionType?.length)
      getAllFees().catch(() => easyToast('error', 'Ha ocurrido un error cargando las tarifas'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [feesByInspectionType?.length])
  console.log({ feesByInspectionType })
  try {
    return (
      <div className="fees-configurator">
        <h3>A continuación se listan las tarifas por tipo de inspección</h3>
        <h4>
          Las tarifas influyen directamente en los cobros y las modificaciones de facturas. Realice
          los cambios con máxima atención
        </h4>
        {loadingGetAll && <ChildSpinner tight={false} />}
        {!loadingGetAll &&
          feesByInspectionType?.length > 0 &&
          feesByInspectionType?.map((fee, outIndex) => (
            <Accordion
              className="fees-configurator__out-accordion"
              title={fee.inspectionType}
              key={outIndex}
              elemKey={outIndex}
              // eslint-disable-next-line react/no-children-prop
              children={
                <div className="fees-configurator__out-accordion-content">
                  {fee?.fees?.length > 0 &&
                    fee?.fees?.map((fees, inIndex) => (
                      <Accordion
                        className="fees-configurator__in-accordion"
                        title={fees?.vehicleType?.name}
                        key={inIndex}
                        elemKey={inIndex}
                        // eslint-disable-next-line react/no-children-prop
                        children={
                          <FeeContent
                            fee={fees?.values}
                            feeInfo={fees?.vehicleType}
                            inspectionType={fee?.inspectionType}
                            parentIndex={outIndex}
                            feeIndex={inIndex}
                          />
                        }
                      />
                    ))}
                  {!fee?.fees?.length > 0 && (
                    <div className="no-values">Aún hay tarifas para este tipo de inspección</div>
                  )}
                </div>
              }
            />
          ))}
      </div>
    )
  } catch (error) {
    console.error(error)
    return <ErrorView />
  }
}
