import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_FACE_ID_URL

export function postFaceIDVerify(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/recognize-face`, data, {
    headers: { ...getJWT().headers, 'content-type': 'multipart/form-data' },
    signal: options.signal,
  })
}
