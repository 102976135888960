import React, { useEffect } from 'react'
import { BrowserRouter as Router, useHistory } from 'react-router-dom'
import { Provider } from 'react-redux'
import generateStore from 'redux/store'
import AOS from 'aos'
import 'aos/dist/aos.css'

// MY IMPORTS
import EasyToastContainer from 'components/Others/EasyToast/EasyToastContainer'
import Header from 'components/Header/Header'
import Routes from 'components/Others/Routes/Routes'
import ErrorBoundary from 'components/Others/ErrorBoundary/ErrorBoundary'
import AnimatedLogo from 'components/Others/AnimatedLogo/AnimatedLogo'
import materialTheme from 'styles/MaterialTheme'
import { ThemeProvider as MaterialUiThemeProvider } from '@mui/material'

const store = generateStore()

function App() {
  const history = useHistory()
  // INICIALIZA AOS
  useEffect(() => {
    AOS.init({
      duration: 800,
      once: false,
    })
  }, [])
  return (
    <ErrorBoundary
      goToLobby={() => {
        history.replace('/lobby')
        history.go(0)
      }}
    >
      <Provider store={store}>
        <MaterialUiThemeProvider theme={materialTheme}>
          <Router>
            <EasyToastContainer />
            <AnimatedLogo />
            <Header />
            <section className="app__main" id="app__main">
              <Routes />
            </section>
          </Router>
        </MaterialUiThemeProvider>
      </Provider>
    </ErrorBoundary>
  )
}

export default App
