import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import Input from 'components/Others/Input/Input'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setReduxConfigModal } from 'redux/ducks/configDuck'

/**
 *
 * @param {object} param0
 * @param {import('types/Inspection').MachineDefect} param0.machineDefect
 * @param {string} param0.sectionNumber
 * @param {import('types/Inspection').VehicleData} param0.vehicleData
 * @param {string} param0.inspectionType
 * @param {Function} param0.recalc
 */
export default function MachineDefectRecalc({
  machineDefect,
  sectionNumber,
  vehicleData,
  inspectionType,
  recalc,
}) {
  const [COModal, setCOModal] = useState({ show: false, inputValue: '' })
  const dispatch = useDispatch()
  const submitCO = async () => {
    try {
      const value = parseFloat(COModal.inputValue)
      console.log({ value })

      if (!value || typeof value !== 'number') return easyToast('error', 'Inserte un valor válido')
      await recalc({ CO: value })
      setCOModal({ show: false, inputValue: '' })
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error con el valor límite insertado')
    }
  }
  const defectId = machineDefect?.defect?.id
  console.log({ machineDefect })
  switch (sectionNumber) {
    case '1':
      if (
        (defectId === '5.2.6.3' ||
          defectId === '5.2.6.5' ||
          defectId === '5.3.6.3' ||
          defectId === '5.3.6.5') &&
        machineDefect.calification === 'G' &&
        inspectionType.includes('SEGUNDA INSPECCIÓN')
      ) {
        return (
          <MyButtonsContainer>
            <MyButton
              text="DECLARACIÓN DE TALLER"
              onClick={() =>
                dispatch(
                  setReduxConfigModal({
                    message:
                      '¿El taller declara que no ha sido posible restituir los valores de distancia o tiempo desde borrado de DTC?',
                    alertMessage: `Esta acción puede cambiar la calificación del defecto ${defectId}`,
                    onAccept: async () => await recalc({ workshop: true }),
                  })
                )
              }
            />
          </MyButtonsContainer>
        )
      }
      return <></>
    case '2':
      if (defectId === '5.2.2' && vehicleData.fuel1 === 'GASOLINA') {
        return (
          <>
            <MyButtonsContainer>
              <MyButton
                text="INSERTAR VALOR LÍMITE"
                onClick={() => setCOModal({ show: true, inputValue: '' })}
              />
            </MyButtonsContainer>
            <Modal
              open={COModal.show}
              onClose={() => setCOModal({ show: false, inputValue: '' })}
              message="Inserte el valor límite de emisiones de la homologación del vehículo"
              alertMessage={`Esta acción recalculará el defecto ${defectId}. Pudiendo eliminarlo o cambiar su calificación`}
              onAccept={submitCO}
              content={
                <Input
                  isFormikInput={false}
                  label="Valor límite"
                  type="number"
                  value={COModal.inputValue}
                  onChange={e =>
                    setCOModal(prevState => ({ ...prevState, inputValue: e.target.value }))
                  }
                />
              }
            />
          </>
        )
      }
      return <></>

    default:
      return <></>
  }
}
