import React from 'react'
import { setReduxFormState } from 'redux/ducks/newInspectionDuck'
import { useDispatch } from 'react-redux'
import { setReduxHolderData } from 'redux/ducks/newInspectionDuck'
import 'components/Office/HolderForm/HolderForm.scss'
import parseFormValues from 'myMethods/parseFormValues'
import ClientForm from '../ClientForm/ClientForm'
import OfficeFormState from 'components/Others/OfficeFormState/OfficeFormState'

export default function HolderForm({ reduxNewVehicle }) {
  const dispatch = useDispatch()

  function handleSubmit(values) {
    values = parseFormValues(values)
    dispatch(setReduxHolderData(values))
    dispatch(setReduxFormState(3))
  }

  return (
    <div className="holder-form">
      <OfficeFormState
        onClick={() => {
          dispatch(setReduxFormState(1))
        }}
      />
      <ClientForm reduxNewVehicle={reduxNewVehicle} onSubmit={values => handleSubmit(values)} />
    </div>
  )
}
