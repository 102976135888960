//@ts-check
import React, { useState, useEffect } from 'react'

// MY IMPORTS
import Invoices from 'components/Office/Billing/Invoices/Invoices'
import MaterialTab from 'components/Others/MaterialTab/MaterialTab'
import { getDealsByChunk, getInvoicesByChunk } from 'services/Billing'
import { useSelector } from 'react-redux'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { Share as ShareIcon } from '@mui/icons-material'
import BillingExportModal from 'components/Office/Billing/BillingExportModal/BillingExportModal'
import useServiceByChunk from 'hooks/useServiceByChunk'
import Deals from 'components/Office/Billing/Deals/Deals'

/**
 *
 * @param {{forcedFilterValues?: import('types/FilterServiceParams').FilterServiceParams, showTableFilter?: boolean, onlyShowTables?: boolean, [x: string]: any}} param0
 * @returns
 */
export default function BillingPage({
  forcedFilterValues,
  showTableFilter = true,
  onlyShowTables = false,
  ...rest
}) {
  const {
    data: invoices,
    total: totalInvoices,
    fetch: fetchInvoices,
    fetchNextChunk: fetchNextInvoicesChunk,
    clearFilter: clearInvoicesFilter,
    loading: loadingInvoices,
    currentFilterValues: currentInvoicesFilterValues,
    initialized: areInvoicesInitialized,
  } = useServiceByChunk({ service: getInvoicesByChunk, forcedFilterValues })
  console.log({ invoices, totalInvoices })
  const {
    data: deals,
    total: totalDeals,
    fetch: fetchDeals,
    fetchNextChunk: fetchNextDealsChunk,
    clearFilter: clearDealsFilter,
    loading: loadingDeals,
    currentFilterValues: currentDealsFilterValues,
    initialized: areDealsInitialized,
  } = useServiceByChunk({ service: getDealsByChunk, forcedFilterValues })
  const [showExportModal, setShowExportModal] = useState(false)
  const reduxUser = useSelector(store => store.user?.data)

  const handleDealsClick = () => {
    if (!areDealsInitialized) {
      fetchDeals()
    }
  }

  useEffect(() => {
    fetchInvoices()
    if (forcedFilterValues) {
      fetchDeals()
    }
  }, [fetchInvoices, fetchDeals, forcedFilterValues])

  return (
    <div {...rest}>
      {!onlyShowTables && reduxUser?.role && reduxUser.role === 'ACCOUNTANT' && (
        <MyButtonsContainer>
          <MyButton
            text="OPCIONES DE EXPORTACIÓN Y FIN DE MES"
            transparent={false}
            icon={<ShareIcon />}
            onClick={() => setShowExportModal(true)}
            loading={loadingInvoices || loadingDeals}
          />
        </MyButtonsContainer>
      )}
      {showExportModal && (
        <BillingExportModal
          closeModal={() => setShowExportModal(false)}
          refetchData={() => {
            fetchInvoices()
            fetchDeals()
          }}
        />
      )}
      <MaterialTab
        tabs={[
          {
            text: 'FACTURAS',
            counter: areInvoicesInitialized ? totalInvoices : undefined,
          },
          {
            text: 'ALBARANES',
            counter: areDealsInitialized ? totalDeals : undefined,
            onClick: handleDealsClick,
          },
        ]}
        contents={[
          <Invoices
            key={1}
            invoices={invoices}
            loading={loadingInvoices}
            total={totalInvoices}
            fetchInvoices={fetchInvoices}
            fetchDeals={fetchDeals}
            fetchNextChunk={fetchNextInvoicesChunk}
            currentFilterValues={currentInvoicesFilterValues}
            onClearFilter={clearInvoicesFilter}
            showTableFilter={showTableFilter}
          />,
          <Deals
            key={2}
            deals={deals}
            loading={loadingDeals}
            total={totalDeals}
            fetchDeals={fetchDeals}
            fetchNextChunk={fetchNextDealsChunk}
            fetchInvoices={fetchInvoices}
            currentFilterValues={currentDealsFilterValues}
            onClearFilter={clearDealsFilter}
            showTableFilter={showTableFilter}
          />,
        ]}
      />
    </div>
  )
}
