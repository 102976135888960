//@ts-check
import React, { useEffect, useRef } from 'react'
import './ChatbotBody.scss'
import { ArrowRepeat as RepeatIcon, SignStop as AbortResponseIcon } from 'react-bootstrap-icons'
import BotMessage from './BotMessage/BotMessage'
import Message from './Message'
import ErrorMessage from './ErrorMessage'
import ScrollToBottom from 'react-scroll-to-bottom'

/**
 *
 * @param {{icon?: any, text?: string, onClick: any}} param0
 * @returns
 */
const ActionBtn = ({ icon, text, onClick }) => (
  <div className="chatbot-body__action-btn-container" onClick={onClick}>
    <div className="chatbot-body__action-btn">
      {icon && <div className="chatbot-body__action-btn-icon">{icon}</div>}
      {text && <span>{text}</span>}
    </div>
  </div>
)

/**
 * @typedef {import('types/UseMintAIChatbot').UseMintAIChatbot} useChatbot
 */

/**
 *
 * @param {{chatbot?:useChatbot}} param0
 * @returns
 */

export default function ChatbotBody({ chatbot }) {
  const messages = chatbot?.messages
  const botAnswers = chatbot?.botMessages
  const loadingResponse = chatbot?.loadingResponse
  const bodyRef = useRef(null)
  const messagesEndRef = useRef(null)
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = true
  }, [chatbot?.data?.id])

  console.log({ messages })

  if (!chatbot) return <div className="chatbot-body"></div>

  return (
    <>
      <ScrollToBottom className="chatbot-body__scroll-to-bottom" initialScrollBehavior="auto">
        <div className="chatbot-body" id="chatbot-body" ref={bodyRef}>
          {messages?.map(message => {
            if (message.role === 'USER')
              return <Message id={message.id} key={message.id} content={message.text} />
            if (message.role === 'BOT')
              return (
                <BotMessage
                  id={message.id}
                  key={message.id}
                  content={message.text}
                  maxAnswers={chatbot?.maxBotAnswers ?? 0}
                  answerNumber={
                    botAnswers ? botAnswers?.findIndex(m => m.id === message.id) + 1 : 0
                  }
                  isWritting={loadingResponse}
                />
              )
            return <></>
          })}
          {loadingResponse && <Message loading={true} />}
          <div className="chatbot-body__options">
            {loadingResponse && (
              <ActionBtn
                text="Abortar respuesta"
                icon={<AbortResponseIcon />}
                onClick={chatbot.abortResponse}
              />
            )}
            {!loadingResponse && chatbot.hasLastResponseError && (
              <>
                <ErrorMessage />
                <ActionBtn
                  icon={<RepeatIcon />}
                  text="Volver a intentar"
                  onClick={chatbot?.resendLastMessage}
                />
              </>
            )}
            {!loadingResponse &&
              !chatbot.hasLastResponseError &&
              chatbot.canSendMessages &&
              chatbot.canRepeatLastMessage && (
                <ActionBtn
                  icon={<RepeatIcon />}
                  text="Repetir pregunta"
                  onClick={chatbot?.resendLastMessage}
                />
              )}
          </div>
          <div ref={messagesEndRef} />
        </div>
      </ScrollToBottom>
    </>
  )
}
