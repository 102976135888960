// constantes
const initialData = {
  JWT: window.sessionStorage.getItem('jwt'),
  data: {},
}

// types
const SET_JWT_SUCCESS = 'SET_JWT_SUCCESS'
const SET_USER_DATA_SUCCESS = 'SET_USER_DATA_SUCCESS'
const SET_USER_REMARKS_SUCCESS = 'SET_USER_REMARKS_SUCCESS'
const RESET = 'RESET_SUCCESS'

// reducer
export default function userReducer(state = initialData, action) {
  switch (action.type) {
    case SET_JWT_SUCCESS:
      return { ...state, JWT: action.payload }
    case SET_USER_DATA_SUCCESS:
      return { ...state, data: action.payload }
    case SET_USER_REMARKS_SUCCESS:
      return { ...state, data: { ...state, personalSettings: action.payload } }
    case RESET:
      return initialData
    default:
      return state
  }
}

// actions
export const setReduxJWT = JWT => dispatch => {
  try {
    dispatch({
      type: SET_JWT_SUCCESS,
      payload: JWT,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxUserData = data => dispatch => {
  try {
    dispatch({
      type: SET_USER_DATA_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxUserPersonalSettings = personalSettings => (dispatch, getState) => {
  const currentData = getState().user.data || {}

  try {
    dispatch({
      type: SET_USER_DATA_SUCCESS,
      payload: { ...currentData, personalSettings },
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxUserPersonalSettingsRemarks =
  (remarks = []) =>
  (dispatch, getState) => {
    let currentSettings = getState().user.data.personalSettings || {}
    console.log({ remarks, currentData: currentSettings })
    currentSettings.remarks = remarks
    try {
      dispatch({
        type: SET_USER_REMARKS_SUCCESS,
        payload: currentSettings,
      })
    } catch (err) {
      console.error(err)
    }
  }
