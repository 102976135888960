import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// MY IMPORTS
import 'components/Office/Checkout/Checkout.scss'
import InvoiceForm from 'components/Office/Checkout/InvoiceForm/InvoiceForm'
import PaymentMethod from './PaymentMethods/PaymentMethods'
import Fee from './Fee/Fee'
import easyToast from 'components/Others/EasyToast/easyToast'

// SERVICES
import { postInitInspection as postInitInspectionService } from 'services/Inspection'
import Spinner from 'components/Others/Spinner/Spinner'
import {
  setReduxFormState,
  setReduxInvoiceInfo,
  setReduxPaymentData,
} from 'redux/ducks/newInspectionDuck'
import Modal from 'components/Others/Modal/Modal'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Tpv from 'Share/Tpv'
import ManualTpvModal from '../ManualTpvModal/ManualTpvModal'
import useService from 'hooks/useService'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import { CurrencyEuro as EuroIcon } from 'react-bootstrap-icons'

export default function Checkout({ redux }) {
  const dispatch = useDispatch()
  const [showInvoiceSerieModal, setShowInvoiceSerieModal] = useState(false)
  const [showLoadingModal, setShowLoadingModal] = useState(false)
  const [manualTpvModal, setManualTpvModal] = useState({
    show: false,
    error: false,
  })
  const [retryModal, setRetryModal] = useState({ show: false, data: null })
  const reduxNewVehicle = useSelector(store => store.newVehicle)
  const paymentData = reduxNewVehicle.paymentData
  const hasNoPrice = reduxNewVehicle?.feeData?.total === 0
  const hasFeeError = reduxNewVehicle?.feeData == null
  const { fetch: postInitInspection, loading } = useService({
    service: postInitInspectionService,
  })

  const handlePayment = async invoiceSerie => {
    try {
      if (
        !redux?.cashRegister?.status ||
        ('isClosed' in redux.cashRegister.status && redux.cashRegister.status.isClosed) ||
        ('wasOpen' in redux?.cashRegister?.status && !redux.cashRegister.status.wasOpen)
      ) {
        return easyToast('error', 'No puedes cobrar con la caja cerrada')
      }
      let method = paymentData?.method
      if (redux?.newVehicle?.discountData?.name === 'SIN CARGOS') {
        return handleSubmit({ invoiceSerie: 'ticket', paymentMethod: 'SIN CARGOS' })
      }
      if (hasNoPrice) {
        return handleSubmit({ invoiceSerie: 'noPriceDeal', paymentMethod: 'CRÉDITO' })
      }
      if (!method) return easyToast('error', 'Seleccione un método de pago')
      if (invoiceSerie) {
        console.log(invoiceSerie)
        dispatch(setReduxPaymentData({ ...paymentData, invoiceSerie }))
      }
      if (['CRÉDITO', 'RECIBO BANCARIO'].includes(method)) {
        dispatch(setReduxPaymentData({ ...paymentData, invoiceSerie: 'deal' }))
        return handleSubmit({ invoiceSerie: 'deal' })
      }
      if (!paymentData.invoiceSerie && !invoiceSerie && method !== 'SIN CARGOS')
        return setShowInvoiceSerieModal(true)

      if (method === 'TARJETA') {
        const myTpv = await Tpv.create()
        setShowLoadingModal(true)
        if (!myTpv || !myTpv.terminal) {
          setShowLoadingModal(false)
          setManualTpvModal({
            show: true,
            error: false,
          })
          return
        }
        const tpvRes = await myTpv.charge(reduxNewVehicle.feeData.total)
        setShowLoadingModal(false)
        if (tpvRes?.ok && !tpvRes?.error && tpvRes?.data) {
          const tpv = {
            tpv: {
              terminal: myTpv.terminal,
              name: myTpv.name,
              manual: false,
              transactionData: tpvRes.data?.datos,
            },
          }
          return handleSubmit({
            invoiceSerie,
            TpvData: tpv,
          })
        } else {
          setManualTpvModal({
            show: true,
            error: true,
          })
          return
        }
      }
      handleSubmit({ invoiceSerie })
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error con el método de pago seleccionado')
    }
  }

  const handleSubmit = async ({ invoiceSerie, TpvData, paymentMethod }) => {
    try {
      setRetryModal({ show: false, data: null })
      paymentMethod = paymentMethod || redux.newVehicle.paymentData.method
      invoiceSerie = invoiceSerie || paymentData.invoiceSerie
      if (paymentMethod) {
        // VALORES PARA CADA TABLA
        const vehicleValues = {
          ...redux.newVehicle.vehicleData,
          dni: redux.newVehicle.holderData.dni,
        }
        const holderValues = {
          ...redux.newVehicle.holderData,
          license: redux.newVehicle.vehicleData.license,
        }
        const invoicedClientValues = {
          ...redux.newVehicle.holderData,
          ...redux.newVehicle.invoiceData,
        }

        const inspectionValues = {
          license: vehicleValues.license,
          category: vehicleValues.category,
          inspection: redux.newVehicle.inspectionData.inspectionType,
          config: redux.newVehicle.inspectionData.config,
          times: { office_start: redux.newVehicle.time },
        }

        const paymentValues = {
          method: redux.newVehicle.paymentData.method,
          discountId: redux.newVehicle.discountData?.id || null,
        }
        if (TpvData) paymentValues.details = TpvData

        const res = await postInitInspection({
          data: {
            vehicle: vehicleValues,
            holder: holderValues,
            invoicedClient: invoicedClientValues,
            inspection: inspectionValues,
            payment: paymentValues,
            invoiceSerie: invoiceSerie || paymentData.invoiceSerie,
          },
        })

        easyToast('dark', 'Vehículo enviado a inspección')

        res?.invoiceId &&
          dispatch(setReduxInvoiceInfo({ invoiceId: res.invoiceId, serie: res.serie }))
        dispatch(setReduxFormState(4))
      } else {
        easyToast('error', 'Seleccione un método de pago')
      }
    } catch (err) {
      console.error(err)
      if (err.response?.status === 409) {
        easyToast('error', 'Esta matrícula no está incluída en la cuenta a cobro del cliente')
        return
      }
      setShowInvoiceSerieModal(false)
      setManualTpvModal({
        show: false,
        error: false,
      })
      setRetryModal({ show: true, data: { invoiceSerie, TpvData, paymentMethod } })

      easyToast('error', 'Ha ocurrido un error, inténtelo de nuevo')
    }
  }

  console.log({ retryModal })

  return (
    <>
      <Spinner visible={loading} />
      <section className="checkout">
        <div>
          <Fee redux={redux} />
        </div>
        <div>
          <InvoiceForm reduxNewVehicle={redux.newVehicle} />
          <PaymentMethod reduxNewVehicle={redux.newVehicle} />
          {hasFeeError && (
            <div className="checkout__fee-error">
              No se ha encontrado una tarifa definida para la combinación seleccionada de tipo de
              inspección y vehículo
            </div>
          )}
          <MyButtonsContainer className="client-form__btn" margin="0 auto">
            {!hasFeeError && (
              <MyButton
                text="Facturar"
                onClick={() => handlePayment()}
                disabled={loading}
                transparent={false}
                icon={<EuroIcon />}
              />
            )}
          </MyButtonsContainer>
        </div>
      </section>
      <Modal
        open={showLoadingModal}
        onClose={() => setShowLoadingModal(false)}
        title="Esperando TPV..."
        closeOnOverlay={false}
        buttons={false}
        content={<ChildSpinner visible={true} />}
      />
      <Modal
        open={showInvoiceSerieModal}
        onClose={() => {
          setShowInvoiceSerieModal(false)
          dispatch(setReduxPaymentData({ ...paymentData, invoiceSerie: null }))
        }}
        title="Seleccione tipo de factura"
        buttons={false}
        closeAfterAccept={false}
        content={
          <MyButtonsContainer margin="1.5rem auto">
            <MyButton
              text="Factura simplificada / ticket"
              onClick={() => handlePayment('ticket')}
              transparent={true}
            />

            <MyButton text="Factura" onClick={() => handlePayment('invoice')} />
          </MyButtonsContainer>
        }
      />
      {retryModal.show && (
        <Modal
          open={true}
          title="Ha ocurrido un error generando la inspección"
          message="¿Reintentar?"
          alertMessage={retryModal.data.TpvData ? 'El cobro con datáfono ya se ha realizado' : null}
          onAccept={() => handleSubmit({ ...retryModal.data })}
          closeAfterAccept={false}
          onClose={() => setRetryModal({ show: false, data: null })}
        />
      )}
      {manualTpvModal.show && (
        <ManualTpvModal
          closeModal={() => {
            dispatch(setReduxPaymentData({ ...paymentData, invoiceSerie: null }))
            setManualTpvModal({ show: false, title: '', message: '', tpvs: null })
          }}
          title={manualTpvModal.title}
          error={manualTpvModal.error}
          handleClick={name =>
            handleSubmit({
              TpvData: {
                tpv: {
                  name,
                  manual: true,
                },
              },
            })
          }
        />
      )}
    </>
  )
}
