import 'pages/Error404/Error404.scss'
import { useHistory } from 'react-router-dom'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'

export default function Error404() {
  const history = useHistory()
  return (
    <div className="error404">
      <i className="flaticon-crash" />
      <strong>ERROR 404</strong>
      <b>Oops... Parece que no encontramos la página que buscas</b>
      <MyButtonsContainer fullWidth={false}>
        <MyButton text="Volver Atrás" onClick={() => history.goBack()} />
      </MyButtonsContainer>
    </div>
  )
}
