//@ts-check
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import Modal from 'components/Others/Modal/Modal'
import { Form, Formik, useFormikContext } from 'formik'
import Input from 'components/Others/Input/Input'
import './LightHitch.scss'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import round from 'myMethods/round'
import ToggleButtons from 'components/Others/Buttons/ToggleButtons/ToggleButtons'
import useInspectionImages from 'hooks/useInspectionImages'
import Gallery from 'components/Others/Gallery/Gallery'
import easyToast from 'components/Others/EasyToast/easyToast'
import useService from 'hooks/useService'
import { putInspectionCalculations as putInspectionCalculationsService } from 'services/Inspection'
import { useSelector } from 'react-redux'
import Id from 'Share/Id'
import isValidFunction from 'myMethods/isValidFunction'

const initialValues = {
  vehicle: [
    { name: 'mmta', label: 'MMTA (F.1)', type: 'number' },
    { name: 'mom', label: 'MOM (G)', type: 'number' },
    { name: 'pasajeros', label: 'Pasajeros (S)', type: 'number' },
    { name: 'homologacion', label: 'Contraseña de homologación (K)', type: 'text' },
    { name: 'barraTraccion', label: 'Barra de tracción (O.1.1)', type: 'number' },
    { name: 'semirremolque', label: 'Semirremolque (O.1.2)', type: 'number' },
    { name: 'remolqueEjeCentral', label: 'Remolque eje central (O.1.3)', type: 'number' },
    { name: 'remolqueSinFreno', label: 'Remolque sin freno (O.1.4)', type: 'number' },
  ],
  hitch: [
    { name: 'marca', label: 'Marca', type: 'text' },
    { name: 'tipo', label: 'Tipo', type: 'text' },
    { name: 'homologacion', label: 'Contraseña de homologación', type: 'text' },
    { name: 'clase', label: 'Clase', type: 'text' },
    { name: 'D', label: 'D (Kn)', type: 'number' },
    { name: 'Dc', label: 'Dc (Kn)', type: 'number' },
    { name: 'S', label: 'S (Kg)', type: 'number' },
    { name: 'U', label: 'U (T)', type: 'number' },
    { name: 'V', label: 'V', type: 'number' },
  ],
  remark: '',
  pins: '',
  results: {
    hitch1: { mmr: '', aptoRemolqueEjeCentral: null, aptoRemolqueBarraTraccion: null },
    hitch2: { mmr: '', aptoRemolqueEjeCentral: null, aptoRemolqueBarraTraccion: null },
  },
}

const RenderSuitable = ({ hitch }) => (
  <>
    {hitch.mmr !== '' && (
      <div className="light-hitch__suitable">
        {hitch.aptoRemolqueEjeCentral != null && (
          <CardNameValue
            className={`light-hitch__message ${
              hitch.aptoRemolqueEjeCentral ? '' : 'light-hitch__message--invalid'
            }`}
            name="Remolcar remolque de eje central"
            value={hitch.aptoRemolqueEjeCentral ? 'APTO' : 'NO APTO'}
          />
        )}
        {hitch.aptoRemolqueBarraTraccion != null && (
          <CardNameValue
            className={`light-hitch__message ${
              hitch.aptoRemolqueBarraTraccion ? '' : 'light-hitch__message--invalid'
            }`}
            name="Remolcar remolque de barra de tracción"
            value={hitch.aptoRemolqueBarraTraccion ? 'APTO' : 'NO APTO'}
          />
        )}
      </div>
    )}
  </>
)

const CalcResults = React.memo(() => {
  const { values, setFieldValue } = useFormikContext()

  const calcMmr = useCallback((mmta, D) => {
    try {
      return round((mmta * D * 1000) / (9.81 * mmta - D * 1000))
    } catch (err) {
      console.error(err)
      return null
    }
  }, [])

  useEffect(() => {
    const updateResults = (
      hitchIndex,
      mmrValue,
      aptoRemolqueEjeCentral,
      aptoRemolqueBarraTraccion
    ) => {
      setFieldValue(`results.hitch${hitchIndex}`, {
        mmr: mmrValue,
        aptoRemolqueEjeCentral,
        aptoRemolqueBarraTraccion,
      })
    }
    if (
      values.vehicle.mmta !== '' &&
      (values.vehicle.remolqueEjeCentral !== '' || values.vehicle.barraTraccion !== '') &&
      (values.hitch1.D || values.hitch2.D)
    ) {
      const hook1Mmr = values.hitch1.D ? calcMmr(values.vehicle.mmta, values.hitch1.D) : ''
      const hook1AptoRemolqueEjeCentral =
        values.vehicle.remolqueEjeCentral !== '' && values.hitch1.D !== ''
          ? hook1Mmr >= values.vehicle.remolqueEjeCentral
          : null
      const hook1AptoRemolqueBarraTraccion =
        values.vehicle.barraTraccion !== '' && values.hitch1.D !== ''
          ? hook1Mmr >= values.vehicle.barraTraccion
          : null
      const hook2Mmr = values.hitch2.D ? calcMmr(values.vehicle.mmta, values.hitch2.D) : ''
      const hook2AptoRemolqueEjeCentral =
        values.vehicle.remolqueEjeCentral !== '' && values.hitch2.D !== ''
          ? hook2Mmr >= values.vehicle.remolqueEjeCentral
          : null
      const hook2AptoRemolqueBarraTraccion =
        values.vehicle.barraTraccion !== '' && values.hitch2.D !== ''
          ? hook2Mmr >= values.vehicle.barraTraccion
          : null

      updateResults('1', hook1Mmr, hook1AptoRemolqueEjeCentral, hook1AptoRemolqueBarraTraccion)
      updateResults('2', hook2Mmr, hook2AptoRemolqueEjeCentral, hook2AptoRemolqueBarraTraccion)
    } else {
      updateResults('1', '', null, null)
      updateResults('2', '', null, null)
    }
  }, [
    calcMmr,
    setFieldValue,
    values.vehicle.mmta,
    values.vehicle.remolqueEjeCentral,
    values.vehicle.barraTraccion,
    values.hitch1.D,
    values.hitch2.D,
  ])

  return null
})

const LightHook = ({ closeModal }) => {
  const formRef = useRef()
  const { images, uploadImage } = useInspectionImages({ type: 'bastidor' })
  const inspectionCalculations = useSelector(store => store.inspection.calculations)
  const { fetch: putCalculations, loading } = useService({
    service: putInspectionCalculationsService,
  })

  const persistValues = async shouldCloseModal => {
    try {
      const values = formRef.current.values
      let newInspectionCalculations = { ...(inspectionCalculations ?? {}), lightHitch: values }
      await putCalculations({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        calculations: newInspectionCalculations,
      })
      if (shouldCloseModal && isValidFunction(closeModal)) closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error al guardar los valores')
    }
  }

  const handleInitialValues = useCallback(() => {
    if (inspectionCalculations?.lightHitch) return inspectionCalculations.lightHitch
    const vehicle = initialValues.vehicle.reduce((acc, item) => {
      acc[item.name] = ''
      return acc
    }, {})
    const hitch = initialValues.hitch.reduce((acc, item) => {
      acc[item.name] = ''
      return acc
    }, {})
    return { vehicle, hitch1: hitch, hitch2: hitch, results: initialValues.results }
  }, [inspectionCalculations?.lightHitch])

  const formikInitialValues = useMemo(() => handleInitialValues(), [handleInitialValues])

  return (
    <Modal
      open={true}
      closeOnOverlay={false}
      closeAfterAccept={false}
      onAccept={() => persistValues(true)}
      title="Enga de ligeros"
      onClose={closeModal}
      loading={loading}
      content={
        <div className="light-hitch">
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={formikInitialValues}
            onSubmit={() => {}}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <div className="light-hitch__form">
                    <CalcResults />
                    <div>
                      <div className="light-hitch__inputs-box">
                        <div className="light-hitch__title">Vehículo tractor</div>
                        <div className="light-hitch__inputs">
                          {initialValues.vehicle.map((item, i) => (
                            <Input
                              key={i}
                              label={item.label}
                              name={`vehicle.${item.name}`}
                              type={item.type}
                            />
                          ))}
                        </div>
                      </div>
                      <div className="light-hitch__pins-wrapper">
                        <ToggleButtons
                          selectedValue={values.pins}
                          handleChange={value => setFieldValue('pins', value)}
                          buttons={[
                            {
                              text: '7 pines',
                              value: '7',
                            },
                            {
                              text: '13 pines',
                              value: '13',
                            },
                            {
                              text: '15 pines',
                              value: '15',
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="light-hitch__inputs-box">
                      <div className="light-hitch__title">Dispositivo de acoplamiento 1</div>
                      <div className="light-hitch__inputs">
                        {initialValues.hitch.map((item, i) => (
                          <Input
                            key={i}
                            label={item.label}
                            name={`hitch1.${item.name}`}
                            type={item.type}
                          />
                        ))}
                      </div>
                      <div className="light-hitch__result">
                        <CardNameValue
                          name="MMR"
                          value={
                            values.results.hitch1.mmr === ''
                              ? 'Introduzca los valores'
                              : values.results.hitch1.mmr
                          }
                        />
                        <RenderSuitable hitch={values.results.hitch1} />
                      </div>
                    </div>
                    <div className="light-hitch__inputs-box">
                      <div className="light-hitch__title">Dispositivo de acoplamiento 2</div>
                      <div className="light-hitch__inputs">
                        {initialValues.hitch.map((item, i) => (
                          <Input
                            key={i}
                            label={item.label}
                            name={`hitch2.${item.name}`}
                            type={item.type}
                          />
                        ))}
                      </div>
                      <div className="light-hitch__result">
                        <CardNameValue
                          name="MMR"
                          value={
                            values.results.hitch2.mmr === ''
                              ? 'Introduzca los valores'
                              : values.results.hitch2.mmr
                          }
                        />
                        <RenderSuitable hitch={values.results.hitch2} />
                      </div>
                    </div>
                  </div>
                  <div className="light-hitch__remark">
                    <Input
                      type="textarea"
                      name="remark"
                      label="Observaciones"
                      isFormikInput={false}
                    />
                  </div>
                </Form>
              )
            }}
          </Formik>
          <Gallery
            images={images}
            noImagesText="Sin imagen del bastidor"
            addButtonText="Añadir imagen del bastidor"
            defaultSelectValue="bastidor"
            typeSelectable={false}
            onCaptureImage={(image, geolocation) =>
              uploadImage({ image, geolocation, type: 'bastidor' })
            }
            centerImages={true}
          />
        </div>
      }
    />
  )
}

export default LightHook
