import { createTheme } from '@mui/material'

export default function materialTableTheme() {
  return createTheme({
    palette: {
      primary: {
        main: '#5fa186 !important',
      },
      secondary: {
        main: '#333333',
      },
    },
    overrides: {
      MuiTableRow: {
        footer: {
          '& > td > div': {
            height: '3rem',
            minHeight: '2rem',
            overflow: 'hidden',
          },
          height: '3rem',
          overflow: 'hidden',
        },
      },
    },
  })
}
