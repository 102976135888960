import React, { useState, useEffect } from 'react'
import OpacimetroConfig from './OpacimetroConfig/OpacimetroConfig'
import { useDispatch, useSelector } from 'react-redux'
import { setReduxInspectionConfig } from 'redux/ducks/inspectionDuck'

export default function Opacimetro({ sectionNumber, disabled = false }) {
  const [config, setConfig] = useState({
    show: false,
  })
  const reduxConfig = useSelector(store => store.inspection?.config)
  const reduxOpacimeterData = reduxConfig?.opacimeterData
  const [values, setValues] = useState(() => {
    let obj = {}
    if (reduxOpacimeterData?.turbo === undefined) obj.turbo = true
    else obj.turbo = reduxOpacimeterData?.turbo
    if (reduxOpacimeterData?.euro === undefined) obj.euro = null
    else obj.euro = reduxOpacimeterData?.euro ?? true
    if (reduxOpacimeterData?.plate === undefined) obj.plate = null
    else obj.plate = parseFloat(reduxOpacimeterData?.plate || 0)
    return obj
  })
  console.log({ values })
  const dispatch = useDispatch()

  useEffect(() => {
    const newConfig = { ...(reduxConfig || {}), opacimeterData: values }
    dispatch(setReduxInspectionConfig(newConfig))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values])

  useEffect(() => {
    if (!config.show) {
      setConfig({ ...config, show: true })
    }
  }, [config, reduxOpacimeterData])

  return (
    <>
      {config.show && (
        <OpacimetroConfig
          sectionNumber={sectionNumber}
          setValues={values => setValues(values)}
          values={values}
          disabled={disabled}
        />
      )}
    </>
  )
}
