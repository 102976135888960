import easyToast from 'components/Others/EasyToast/easyToast'
import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import {
  getClientByDni as getClientByDniService,
  getClientsByChunk as getClientsByChunkService,
} from 'services/Clients'
import { LocalOffer as DiscountIcon, Close as CloseIcon } from '@mui/icons-material'

import {
  putAlterInvoice as putAlterInvoiceService,
  getInvoiceData as getInvoiceDataService,
  getInvoiceDraft,
  putAbortAlterInvoiceVehicle as putAbortAlterInvoiceVehicleService,
} from 'services/Billing'

// MY IMPORTS
import './InvoiceModifier.scss'
import compareObjects from 'myMethods/compareObjects'
import { getAllInspections as getAllInspectionsService } from 'services/Inspection'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import PDFViewer from 'components/Others/PDFViewer/PDFViewer'
import ModificationDiff from '../ModificationDiff/ModificationDiff'
import DeepSearch from 'components/Others/DeepSearch/DeepSearch'
import { getVehicleByLicense as getVehicleByLicenseService } from 'services/Vehicles'
import Modal from 'components/Others/Modal/Modal'
import Discounts from 'components/Office/Checkout/Discounts/Discounts'
import { getAllDiscounts as getAllDiscountsService } from 'services/Discounts'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'
import formatEuro from 'myMethods/formatEuro'
import useService from 'hooks/useService'
import useMultipleServices from 'hooks/useMultipleServices'
import Select from 'components/Others/Select/Select'

/**
 * @param {{data: import('types/InvoiceTypeResponse').InvoiceDataResDTO}} props
 */
export default function InvoiceModifier({ data, closeModal, refetch }) {
  const [rawData, setRawData] = useState({})
  const [seriesOptions, setSeriesOptions] = useState([])
  const [values, setValues] = useState({
    serie: data?.serie,
    client: data?.client,
  })
  const [status, setStatus] = useState({
    serie: {
      changing: false,
      diff: [],
    },
    client: {
      changing: false,
      refresh: false,
      diff: [],
    },
    vehicle: {
      refresh: false,
      diff: [],
    },
    discountId: null,
  })
  const [errors, setErrors] = useState({ fetchData: false, fees: false })
  const [pdf, setPdf] = useState({
    show: false,
    data: null,
  })
  const [discounts, setDiscounts] = useState(null)
  const [hasChanges, setHasChanges] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showDiscountsModal, setShowDiscountsModal] = useState(false)
  const reduxConfig = useSelector(store => store.config)
  const reduxItv = reduxConfig?.itv
  const reduxAccounting = reduxItv?.accounting
  const requestInProgress = Boolean(rawData?.invoiceData?.to_update?.report)
  const appliedDiscountData =
    status.discountId != null ? discounts?.find(d => d.id === status.discountId) : null

  const { fetch: fetchInvoiceDraft } = useService({
    service: getInvoiceDraft,
    setOutLoading: setLoading,
  })
  const { fetch: getAllDiscounts, loading: loadingDiscounts } = useService({
    service: getAllDiscountsService,
    handleResponseData: res => setDiscounts(res?.discounts ?? null),
  })

  const { fetch: putAlterInvoice } = useService({
    service: putAlterInvoiceService,
    setOutLoading: setLoading,
    fullResponse: true,
  })

  const { fetch: putAbortAlterInvoiceVehicle } = useService({
    service: putAbortAlterInvoiceVehicleService,
    setOutLoading: setLoading,
  })

  const { fetch: fetchInitialData, loading: initialLoading } = useMultipleServices({
    services: [
      getAllInspectionsService,
      getInvoiceDataService,
      getVehicleByLicenseService,
      getClientByDniService,
    ],
  })

  const alterStatus = (key, value) => {
    try {
      if (key === 'changing' && !values[key]) return easyToast('error', 'Escoja un valor')

      setStatus(prevState => ({
        ...prevState,
        [key]: { ...status[key], [value]: !status[key][value] },
      }))
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error habilitando los cambios')
    }
  }

  const checkIfPaymentNeeded = () => {
    // const newSerie = values.serie !== data.serie ? values.serie : undefined
    // // if (newSerie && data.serie === 'deal' && ['invoice', 'ticket'].includes(newSerie)) {
    // // }
    handleAccept()
  }

  const handleAccept = useCallback(async () => {
    try {
      const res = await putAlterInvoice({
        invoiceId: data.id,
        serie: data.serie,
        newSerie: values.serie !== data.serie ? values.serie : undefined,
        cashRegisterId: undefined,
        newClientDNI:
          values.client.dni !== data.client?.dni
            ? values.client.dni
            : status.client.refresh
            ? data.client?.dni
            : null,
        vehicleUpdate: status.vehicle.refresh,
        discountId: status.discountId,
        newPaymentMethod: 'EFECTIVO',
      })
      if (res.status === 200) {
        easyToast('dark', res.data.message)
        closeModal()
        refetch()
      } else if (res.status === 422) {
        easyToast('error', res.data.message)
      } else if (res.status === 218) {
        easyToast('warning', res.data.message)
        closeModal()
        refetch()
      } else {
        throw new Error('No response in putAlterInvoice')
      }
    } catch (error) {
      console.error('error')
      easyToast('error', 'Ha ocurrido un error modificando la factura')
    }
  }, [
    closeModal,
    data?.client?.dni,
    data?.id,
    data?.serie,
    putAlterInvoice,
    refetch,
    status?.client?.refresh,
    status?.discountId,
    status?.vehicle?.refresh,
    values?.client?.dni,
    values?.serie,
  ])

  const abortChanges = async () => {
    try {
      putAbortAlterInvoiceVehicle({ invoiceId: data.id, serie: data.serie })
      easyToast('dark', 'Cambios abortados')
    } catch (error) {
      easyToast('error', 'Ha ocurrido un error cancelando los cambios')
    }
  }

  // OBTENER TODOS LOS DATOS NECESARIOS
  useEffect(() => {
    try {
      const fetchData = async () => {
        let [inspectionVehicles, invoiceData, vehicle, client] = await fetchInitialData([
          null,
          { serie: data.serie, invoiceId: data.id },
          data.report.vehicle.license,
          data.client.dni,
        ])
        inspectionVehicles = inspectionVehicles.data
        invoiceData = invoiceData.data
        vehicle = vehicle.data
        client = client.data
        setStatus(prevState => ({
          ...prevState,
          vehicle: {
            ...prevState.vehicle,
            onInspection: inspectionVehicles.some(
              insp => insp.idInforme === invoiceData?.report?.id
            ),
          },
        }))
        setRawData({ invoiceData, vehicle, client })
        let series = Object.entries(reduxAccounting?.series || [])
          ?.filter(array => ['invoice', 'ticket', 'deal'].includes(array[0]))
          .map(([key, value]) => {
            return { label: value, value: key }
          })

        setSeriesOptions(series)
        errors?.fetchData && setErrors({ ...errors, fetchData: false })
      }

      data && Object.keys(data)?.length && !Object.keys(rawData).length && fetchData()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos')
      setErrors({ ...errors, fetchData: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxAccounting, data, rawData, errors])

  const requestDraft = useCallback(async () => {
    try {
      const pdf = await fetchInvoiceDraft({
        invoiceId: data.id,
        serie: data.serie,
        newSerie: values.serie !== data.serie ? values.serie : undefined,
        cashRegisterId: undefined,
        newClientDNI:
          values.client.dni !== data.client?.dni
            ? values.client.dni
            : status.client.refresh
            ? data.client?.dni
            : null,
        vehicleUpdate: status.vehicle.refresh,
        discountId: status.discountId,
      })
      if (!pdf) throw new Error('No pdf in requestDraft')
      setPdf({ show: false, data: pdf })
    } catch (err) {
      console.error(err)
      if (err.response?.data?.message) {
        easyToast('error', err.response.data.message)
      }
      if (pdf.show || pdf.data) setPdf({ show: false, data: null })
    }
  }, [data, fetchInvoiceDraft, status, values])

  console.log({ status, rawData, values, discounts })

  // SERIE
  useEffect(() => {
    try {
      if (
        !status.serie.changing &&
        data.serie !== values.serie &&
        (!status.serie.diff.length ||
          seriesOptions?.find(elem => elem.label === status.serie.diff[0]?.value2)?.value !==
            values.serie)
      ) {
        if (values.serie === 'deal') {
          const license = rawData.vehicle.license
          if (!rawData.client?.chargeAccount?.authVehicles?.some(l => l.license === license)) {
            easyToast(
              'error',
              'El vehículo no está en la lista de matrículas autorizadas para ser cobradas al cliente'
            )
            return setValues(prevState => ({ ...prevState, serie: rawData.invoiceData.serie }))
          }
        }
        setStatus(prevState => ({
          ...prevState,
          serie: {
            ...prevState.serie,
            diff: [
              {
                key: 'serie',
                value1: seriesOptions?.find(elem => elem.value === data.serie)?.label,
                value2: seriesOptions?.find(elem => elem.value === values.serie)?.label,
              },
            ],
          },
        }))
      } else if (
        !status.serie.changing &&
        data.serie === values.serie &&
        status.serie.diff[0]?.value2 === values.serie
      ) {
        setStatus(prevState => ({ ...prevState, serie: { ...prevState.serie, diff: [] } }))
      }
    } catch (error) {
      easyToast('error', 'Ha ocurrido un error actualizando la serie')
    }
    // eslint-disable-next-line
  }, [data.serie, seriesOptions, status, values.serie])

  // CLIENTE
  useEffect(() => {
    if (!(status.serie.changing || status.client.changing)) {
      if (values.client?.dni !== data.client.dni || status.client.refresh) {
        const oldClient = rawData?.client
        const newClient = values.client
        const equalsRes = compareObjects({
          obj1: oldClient,
          obj2: newClient,
          onlyKeys: [
            'dni',
            'name',
            'address',
            'locality',
            'zipCode',
            'email',
            'phone',
            'paymentMethod',
            'iban',
            'note',
          ],
        })
        if (equalsRes.error) throw new Error('Error in areObjectEquals comparing client')
        if (equalsRes.equals) {
          easyToast('error', 'No hay cambios que actualizar en el cliente')
          setStatus(prevState => ({ ...prevState, client: { changing: false, refresh: false } }))
          setValues(prevState => ({
            ...prevState,
            client: { dni: data.client?.dni, name: data.client?.name },
          }))
        } else if (
          !equalsRes.equals &&
          equalsRes.diff?.length &&
          JSON.stringify(equalsRes.diff) !== JSON.stringify(status.client.diff)
        ) {
          setStatus(prevState => ({
            ...prevState,
            client: { ...prevState.client, diff: equalsRes.diff },
          }))
        }
      } else if (values.client === data.client?.dni && status.client.diff?.length) {
        setStatus(prevState => ({
          ...prevState,
          client: { changing: false, refresh: false, diff: [] },
        }))
      }
    }
    //eslint-disable-next-line
  }, [data.client, rawData.client, rawData.invoiceData, status, values])

  // VEHÍCULO
  useEffect(() => {
    try {
      const toUpdate = rawData?.invoiceData?.to_update
      if (status.vehicle.refresh || (requestInProgress && toUpdate?.report?.vehicle?.matricula)) {
        if (!status.vehicle.onInspection && !requestInProgress) {
          easyToast('error', 'El vehículo solo se puede modificar durante la inspección')
          setStatus(prevState => ({ ...prevState, vehicle: { refresh: false } }))
          return
        }
        const obj1 = rawData?.invoiceData?.report?.vehicle
        if (obj1) delete obj1.idVehiculo

        const equalsRes = compareObjects({
          obj1,
          obj2: requestInProgress ? toUpdate.report.vehicle : rawData.vehicle,
          skipIds: true,
        })
        if (equalsRes.error) throw new Error('Error in areObjectEquals comparing vehicle')
        if (equalsRes.equals && !requestInProgress) {
          easyToast('error', 'No hay cambios que actualizar en el vehículo')
          setStatus(prevState => ({ ...prevState, vehicle: { refresh: false } }))
        } else if (
          !equalsRes.equals &&
          equalsRes.diff.length &&
          JSON.stringify(equalsRes.diff) !== JSON.stringify(status.vehicle.diff)
        ) {
          setStatus(prevState => ({
            ...prevState,
            vehicle: { ...status.vehicle, diff: equalsRes.diff },
          }))
        }
      } else {
        if (status.vehicle.diff?.length)
          setStatus(prevState => ({ ...prevState, vehicle: { ...status.vehicle, diff: [] } }))
      }
    } catch (err) {
      console.error(err)
    }
    //eslint-disable-next-line
  }, [rawData, values, data, status, requestInProgress, seriesOptions])

  useEffect(() => {
    try {
      if (
        !status.serie.changing &&
        !status.client.changing &&
        (status.client.diff?.length ||
          values.serie !== data.serie ||
          status.vehicle.diff?.length ||
          status.discountId)
      ) {
        setHasChanges(true)
        requestDraft()
      } else {
        setHasChanges(false)
      }
    } catch (err) {
      console.error(err)
    }
  }, [
    data.serie,
    requestDraft,
    status,
    values.serie,
    rawData.invoiceData?.paymentDetails?.metodo_pago,
    errors,
  ])

  useEffect(() => {
    if (!discounts && !loadingDiscounts) {
      getAllDiscounts()
    }
  }, [discounts, getAllDiscounts, loadingDiscounts])

  const Buttons = ({ type = 'serie' }) => {
    const isChanging = status[type]?.changing
    const refresh = status[type]?.refresh

    return (
      <MyButtonsContainer>
        {type !== 'vehicle' && (
          <MyButton
            text={isChanging ? 'Aceptar' : 'Cambiar'}
            icon={<i className={isChanging ? 'flaticon-diskette' : 'flaticon-pencil'} />}
            onClick={() => alterStatus(type, 'changing')}
            selectable={true}
            selected={isChanging}
          />
        )}
        {type !== 'serie' &&
          !(type === 'client' && values?.client?.dni !== data?.dni_cliente) &&
          !isChanging && (
            <MyButton
              text="Actualizar"
              icon={<i className="flaticon-refresh" />}
              onClick={() => alterStatus(type, 'refresh')}
              selectable={true}
              selected={refresh}
            />
          )}
      </MyButtonsContainer>
    )
  }

  return (
    <Modal
      open={true}
      onClose={closeModal}
      onAccept={
        hasChanges
          ? checkIfPaymentNeeded
          : () => easyToast('error', 'No hay cambios que actualizar')
      }
      closeAfterAccept={false}
      hasAcceptButton={hasChanges && !Object.values(errors).some(elem => elem)}
      closeOnOverlay={false}
      buttons={!loading}
      title={`Modificar factura ${data.number}`}
      loading={initialLoading}
      content={
        <section className="invoice-modifier">
          {!errors.fetchData && loading && <ChildSpinner visible={true} />}
          {errors.fetchData && (
            <div className="no-values">
              Ha ocurrido un error obteniendo la información de la factura. Inténtelo de nuevo
            </div>
          )}
          {!errors?.fetchData && !loading && !requestInProgress && (
            <>
              <div className="invoice-modifier__inputs">
                <div className="invoice-modifier__elem">
                  <h3>SERIE</h3>
                  <Select
                    name="type"
                    options={seriesOptions}
                    value={seriesOptions?.find(elem => elem.value === values?.serie) || ''}
                    onChange={opt => setValues(prevState => ({ ...prevState, serie: opt?.value }))}
                  />
                </div>
                <div className="invoice-modifier__elem">
                  <h3>CLIENTE</h3>
                  <div className="invoice-modifier__text-box">
                    {values.client.dni} - {values.client.name}
                  </div>
                  <Buttons type={'client'} />
                </div>
                <div className="invoice-modifier__elem">
                  <h3>VEHÍCULO</h3>
                  <div className="invoice-modifier__text-box">{data.report?.vehicle?.license}</div>
                  <Buttons type={'vehicle'} />
                </div>
              </div>
              <div className="invoice-modifier__footer">
                <b>
                  <i className="flaticon-refresh" /> Actualizar
                </b>{' '}
                cambiará los datos de la factura a los que tenga actualmente el cliente o vehículo
              </div>
              {status.discountId == null && (
                <MyButtonsContainer>
                  <MyButton
                    text={`Aplicar promoción${
                      discounts?.length ? ' (' + discounts.length + ')' : ''
                    }`}
                    loading={loadingDiscounts}
                    icon={<DiscountIcon />}
                    onClick={() => setShowDiscountsModal(true)}
                  />
                </MyButtonsContainer>
              )}
              {appliedDiscountData && (
                <div className="fee__discount">
                  <span>DESCUENTO APLICADO</span>
                  <div>
                    {appliedDiscountData.name || ''}{' '}
                    <b>
                      (
                      {appliedDiscountData.method === '%'
                        ? appliedDiscountData.amount + '%'
                        : formatEuro(appliedDiscountData.amount)}
                      )
                    </b>
                  </div>
                  <div
                    className="fee__delete-discount"
                    onClick={() => setStatus({ ...status, discountId: null })}
                  >
                    <HoverTooltip title="Cancelar descuento">
                      <CloseIcon />
                    </HoverTooltip>
                  </div>
                </div>
              )}
            </>
          )}
          {!errors.fetchData && !loading && hasChanges && (
            <>
              <ModificationDiff status={status} requestInProgress={requestInProgress} />
              <MyButtonsContainer>
                {requestInProgress && <MyButton text={'Cancelar cambios'} onClick={abortChanges} />}
              </MyButtonsContainer>
              <MyButtonsContainer
                margin="1rem auto 2rem auto"
                fullWidth={true}
              ></MyButtonsContainer>
              <PDFViewer
                pdf={pdf.data}
                show={Boolean(pdf.data)}
                closable={false}
                // onClose={() => setPdf(prevState => ({ ...prevState, show: false }))}
                loading={loading}
                parentToScroll={
                  document.getElementsByClassName('modal')?.[
                    document.getElementsByClassName('modal')?.length - 1
                  ]
                }
              />
            </>
          )}
          <DeepSearch
            open={status?.client?.changing}
            onClose={() =>
              setStatus(prevState => ({
                ...prevState,
                client: { ...status.client, changing: false },
              }))
            }
            title="Buscar cliente"
            message="Seleccione un cliente"
            columnsKey="clients"
            filterServiceOptions={{
              likeDni: true,
              likeName: true,
            }}
            service={getClientsByChunkService}
            onAccept={async selectedValue => {
              setValues({
                ...values,
                client: selectedValue,
              })
            }}
          />
          {showDiscountsModal && (
            <Discounts
              discounts={discounts}
              fee={data?.breakdown}
              vehicleData={data?.report?.vehicle}
              applyDiscount={discountId => setStatus(prevState => ({ ...prevState, discountId }))}
              closeModal={() => setShowDiscountsModal(false)}
            />
          )}
        </section>
      }
    />
  )
}
