import useService from 'hooks/useService'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { setReduxUserData } from 'redux/ducks/userDuck'
import { getUser as getUserService } from 'services/Users'

export default function FillReduxUser({ redux }) {
  const dispatch = useDispatch()
  const { fetch: getUser } = useService({ service: getUserService })

  useEffect(() => {
    if (!redux?.user?.data?.initialized && redux.user.data.user) {
      getUser(redux.user.data.user)
        .then(res => {
          dispatch(setReduxUserData({ ...res, initialized: true }))
        })
        .catch(err => {
          console.error(err)
        })
    }
  }, [dispatch, getUser, redux?.user])

  return <></>
}
