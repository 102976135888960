//@ts-check
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import './CashRegisterSelector.scss'
import {
  setReduxCashRegisterIsChanging,
  setReduxCashSelectorValues,
  setReduxSelectedCashRegister,
} from 'redux/ducks/cashRegisterDuck'
import easyToast from 'components/Others/EasyToast/easyToast'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Select from 'components/Others/Select/Select'

/**
 *
 * @param {{cashRegister: import('types/CashRegister').ReduxCashRegister, goToInitialTab: function}} param0
 * @returns
 */
export default function CashRegisterSelector({ cashRegister, goToInitialTab }) {
  const dispatch = useDispatch()
  const rawOptions = cashRegister?.names
  const [value, setValue] = useState(null)
  const [date, setDate] = useState(
    cashRegister?.cashSelectorValues?.date || moment().format('YYYY-MM-DD')
  )
  const [editable, setEditable] = useState(false)

  const formatOptions = () => {
    try {
      const aux = rawOptions?.map(elem => {
        return { value: elem.name, label: elem.name }
      })
      return aux && aux.length ? aux : []
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const handleClick = async () => {
    const sendError = () =>
      easyToast('error', 'Ha ocurrido un error obteniendo la información de la caja')
    try {
      if (!editable) {
        setEditable(true)
        dispatch(setReduxCashRegisterIsChanging(true))
      } else {
        if (!value) easyToast('error', 'Seleccione una caja')
        if (!date) easyToast('error', 'Seleccione una fecha')
        if (!value || !date) return

        const selected = rawOptions?.find(elem => elem.name === value)
        if (!selected) throw new Error('no option selected in cash register selector')
        if (selected.id === cashRegister?.status.id && moment(date).isSame(moment(), 'day')) {
          // Si se ha seleccionado la caja del usuario en el día de hoy
          dispatch(setReduxCashSelectorValues({}))
          dispatch(setReduxCashRegisterIsChanging(false))
        } else {
          dispatch(
            setReduxCashSelectorValues({
              date,
              id: selected.id,
            })
          )
        }
        dispatch(setReduxSelectedCashRegister(null))
        setEditable(false)
        goToInitialTab()
      }
    } catch (err) {
      console.error(err)
      sendError()
    }
  }
  useEffect(() => {
    // Establece la caja del usuario si no hay ninguna seleccionada y no es editable
    if (
      !editable &&
      cashRegister &&
      (cashRegister.status || cashRegister.selectedCashRegister) &&
      rawOptions &&
      rawOptions.find(
        elem => elem.name === cashRegister.status?.name || elem.name === cashRegister?.name
      ) &&
      !value
    ) {
      const myCashRegister = cashRegister.selectedCashRegister
        ? cashRegister.selectedCashRegister
        : cashRegister.status

      const found = rawOptions?.find(elem => elem.name === myCashRegister.name)
      found && setValue(found.name)
    }
  }, [cashRegister, rawOptions, value, editable])

  return (
    <>
      <section className="cash-register-selector">
        <span>CAJA SELECCIONADA</span>
        <div className="cash-register-selector__inputs">
          <Select
            isClearable={false}
            placeholder={
              rawOptions && rawOptions.length
                ? `${rawOptions.length} ${
                    rawOptions.length > 1 ? 'cajas encontradas' : 'caja encontrada'
                  }...`
                : 'Ninguna caja'
            }
            options={formatOptions()}
            value={formatOptions()?.find(elem => elem.value === value) || ''}
            onChange={opt => {
              setValue(opt ? opt.value : '')
            }}
            isDisabled={!editable}
          />

          <input
            type="date"
            value={date}
            className="form-input"
            onClick={() => setDate('')}
            onChange={e => {
              setDate(e.target.value)
            }}
            disabled={!editable}
          />
          <MyButtonsContainer className="cash-register-selector__btn">
            <MyButton onClick={handleClick} text={editable ? 'GUARDAR' : 'CAMBIAR'} />
          </MyButtonsContainer>
        </div>
      </section>
    </>
  )
}
