import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

// MY IMPORTS
import ManualTest from 'components/Inspection/Hardware/ManualTest/ManualTest'
import { useHistory, useParams } from 'react-router-dom'
import Title from 'components/Others/Title/Title'
import MobileBackBtn from 'components/Others/MobileBackBtn/MobileBackBtn'
import HardwareHandler from 'Share/HardwareHandler'
import MachineTest from 'components/Inspection/Hardware/MachineTest/MachineTest'

export default function Test() {
  const redux = useSelector(store => store)
  const reduxInspection = redux?.inspection
  const history = useHistory()
  const params = useParams()
  const testNames = params.test.split('_')
  const machineType = params.machineType
  const hardwareHandler = useMemo(
    () =>
      new HardwareHandler({
        hardwareConfig: reduxInspection?.hardwareConfig,
        machinesApplying: reduxInspection?.machinesApplying,
        testNames,
        manual: machineType ? Boolean(machineType === 'manual') : null,
        vehicleFuel1: reduxInspection?.vehicleData?.fuel1,
      }),
    [
      machineType,
      reduxInspection?.hardwareConfig,
      reduxInspection?.machinesApplying,
      reduxInspection?.vehicleData?.fuel1,
      testNames,
    ]
  )

  console.log({ hardwareHandler })

  useEffect(() => {
    if (!reduxInspection?.hardwareConfig?.length) {
      history.push(`/new_inspection/${params.license}/hardware`)
    }
  }, [history, params.license, reduxInspection.hardwareConfig])

  return (
    <>
      {hardwareHandler?.hardware && (
        <>
          <Title text={hardwareHandler.hardware.name?.join(' Y ')?.toUpperCase()} />
          {hardwareHandler.hardware.manual ? (
            <ManualTest testName={testNames[0]} hardwareHandler={hardwareHandler} />
          ) : (
            <MachineTest
              testNames={testNames}
              hardwareHandler={hardwareHandler}
              manualManager={reduxInspection?.manualManager}
              linkBetweenMachineAndManual={redux?.config?.linkBetweenMachineAndManual}
            />
          )}
        </>
      )}
      <MobileBackBtn />
    </>
  )
}
