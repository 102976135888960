import React, { useState, useEffect, useMemo, useCallback } from 'react'
import './ManualTest.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import { useDispatch, useSelector } from 'react-redux'
import TestsValues from 'Share/TestsValues'
import Select from 'react-select'
import { selectStyles } from 'components/Others/Select/SelectStyles'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Id from 'Share/Id'
import { deleteInspectionManualTestValues, putInspectionTestsValue } from 'services/Inspection'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import Gallery from 'components/Others/Gallery/Gallery'
import useInspectionImages from 'hooks/useInspectionImages'
import SaveIcon from '@mui/icons-material/Save'

/**
 * @typedef {import('types/HardwareConfig').Machine} Machine
 * @typedef {import('types/HardwareStatus').hardwareStatus} HardwareStatus
 * @typedef {import("Share/HardwareHandler").default} HardwareHandler
 * @typedef {import('types/Hardware').HardwareValue} HardwareValue
 */

/**
 *
 * @param {{testName: string, hardwareHandler: HardwareHandler}} param0
 * @returns
 */
export default function ManualTest({ testName, hardwareHandler }) {
  const [id, setId] = useState(null)
  const [machine, setMachine] = useState(null)
  const [possibleIds, setPossibleIds] = useState([])
  const [inputsConfig, setInputsConfig] = useState([])
  const [inputsValues, setInputsValues] = useState({})
  const [enableEditing, setEnableEditing] = useState(true)
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const thisHardware = hardwareHandler?.hardware
  const machines = thisHardware?.machines
  const redux = useSelector(store => store)
  const reduxInspection = redux?.inspection
  const testsValuesHandler = useMemo(
    () => new TestsValues(reduxInspection?.testsValues, reduxInspection?.hardwareConfig),
    [reduxInspection?.testsValues, reduxInspection?.hardwareConfig]
  )
  const currentTest = testsValuesHandler.getLastTestValues(testName)?.test
  const { images, deleteImage, uploadImage } = useInspectionImages({
    type: 'testMachine',
    testName,
  })

  const HAS_AUTH =
    currentTest?.user === redux.user?.data?.username || redux.user?.data?.role === 'SUPERVISOR'

  const testDoneByAutomaticMachine = useCallback(() => {
    const doneRes = testsValuesHandler.isThisTestDone(thisHardware.name[0])
    if (doneRes?.done && !doneRes?.manual) return true
    return false
  }, [testsValuesHandler, thisHardware.name])

  const handleIdChange = opt => {
    try {
      const id = opt && opt.value ? opt.value : ''
      setId(id)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cambiando el ID')
    }
  }

  const handleInputChange = e => {
    const inputConfig = inputsConfig.find(elem => elem.name === e.target.name)
    if (e.target.type !== inputConfig.type)
      return easyToast('error', 'Inserte un valor correcto en el campo ', e.target.name)
    if (e.target.value.trim() !== '') {
      setInputsValues({
        ...inputsValues,
        [e.target.name]: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value,
      })
    } else {
      setInputsValues({ ...inputsValues, [e.target.name]: '' })
    }
  }

  const setInitialIdValue = useCallback(
    possibleIds => {
      if (currentTest?.machine?.id) {
        const foundId = possibleIds.find(elem => elem.value === currentTest?.machine?.id)
        if (foundId) {
          setId(foundId.value)
        }
      } else {
        const foundByLine = possibleIds.find(elem =>
          elem.label.includes(`Línea ${reduxInspection?.line}`)
        )
        if (foundByLine) {
          setId(foundByLine.value)
        }
      }
    },
    [currentTest?.machine?.id, reduxInspection?.line]
  )

  const setInitialInputValues = useCallback(
    inputValues => {
      if (!inputValues) throw new Error('No inputValues provided')
      let inputValuesCopy = JSON.parse(JSON.stringify(inputValues))
      if (currentTest && currentTest.values?.length) {
        currentTest.values.forEach(value => {
          const match = Object.keys(inputValuesCopy).some(key => key === value.name)
          console.log({ value, match, name: value.name, value2: value.value })
          if (match) inputValuesCopy[value.name] = value.value || ''
        })
        setEnableEditing(false)
        setInputsValues(inputValuesCopy)
      } else {
        setInputsValues(inputValues)
      }
    },
    [currentTest]
  )
  /**
   * @returns {HardwareValue[]}
   */
  const formatValuesToHardwareValues = () => {
    console.log('ANTES', { inputsValues })
    let hardwareValues = []
    Object.entries(inputsValues).forEach(([name, value]) => {
      const foundConfig = inputsConfig.find(config => config.name === name)
      if (!foundConfig) throw new Error('No inputConfig found for name: ', name)
      hardwareValues.push({
        name,
        value,
        unit: foundConfig.unit || null,
      })
    })

    return hardwareValues
  }

  const handleSave = () => {
    try {
      return save()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error guardando los datos')
    }
  }

  const save = async () => {
    try {
      setLoading(true)
      await putInspectionTestsValue({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        testName,
        hardwareValues: formatValuesToHardwareValues(),
        internalId: machine.internalId,
      })
      setEnableEditing(false)
      easyToast('dark', 'Datos almacenados')
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error guardando los datos')
    } finally {
      setLoading(false)
    }
  }

  const clearAll = async () => {
    try {
      setLoading(true)
      await deleteInspectionManualTestValues({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        testName,
      })
      const newInputValues = {}
      Object.keys(inputsValues).forEach(key => (newInputValues[key] = ''))
      setInputsValues(newInputValues)
      setId(null)
      setEnableEditing(true)
      easyToast('dark', 'Test borrado correctamente')
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error borrando los datos')
    } finally {
      setLoading(false)
    }
  }

  const initializeInputs = useCallback(() => {
    switch (testName) {
      case 'báscula': {
        let newInputs = []
        let newValues = {}
        for (let i = 1; i <= reduxInspection.axles.tractor; i++) {
          newInputs.push({ name: `Peso eje ${i} del vehículo`, type: 'number', unit: 'kg' })
        }
        if (reduxInspection.axles.trailer) {
          for (let i = 1; i <= reduxInspection.axles.trailer; i++) {
            newInputs.push({ name: `Peso eje ${i} del remolque`, type: 'number', unit: 'kg' })
          }
        }

        newInputs.forEach(elem => {
          newValues = { ...newValues, [elem.name]: '' }
        })
        setInitialInputValues(newValues)
        setInputsConfig(newInputs)
        break
      }
      case 'eobd':
        break
      case 'dinamómetro':
        {
          const inputValues = {
            'Puerta 1': '',
            'Puerta 2': '',
            'Puerta 3': '',
          }

          setInputsConfig([
            { name: 'Puerta 1', type: 'number', unit: 'N' },
            { name: 'Puerta 2', type: 'number', unit: 'N' },
            { name: 'Puerta 3', type: 'number', unit: 'N' },
          ])

          setInitialInputValues(inputValues)
        }
        break

      case 'decelerómetro': {
        const inputValues = {
          Deceleración: '',
        }
        setInputsConfig([{ name: 'Deceleración', type: 'number', unit: 'm/s²' }])
        setInitialInputValues(inputValues)
        break
      }
      case 'simulador de velocidad': {
        const inputValues = {
          'Limitación de velocidad': '',
        }
        setInputsConfig([{ name: 'Limitación de velocidad', type: 'number', unit: 'km/h' }])
        setInitialInputValues(inputValues)

        break
      }
      default:
        break
    }
  }, [
    reduxInspection.axles.tractor,
    reduxInspection.axles.trailer,
    setInitialInputValues,
    testName,
  ])

  useEffect(() => {
    // Initialize possibleIds
    try {
      if (machines?.length) {
        let ids = []
        machines.forEach(machine => {
          if (machine.line && machine.active && machine.id?.[testName]) {
            ids.push({
              value: machine.id[testName],
              label: `Línea ${machine.line}: ${machine.id[testName]}`,
            })
          } else if (!machine.line && machine.active && machine.id?.[testName]) {
            ids.push({
              value: machine.id[testName],
              label: machine.id[testName],
            })
          }
        })
        if (ids?.length) {
          setPossibleIds(ids)
          setInitialIdValue(ids)
        }
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error encontrando los identificadores de este equipo')
    }
  }, [machines, setInitialIdValue, testName])

  useEffect(() => {
    if (testName && !inputsConfig?.length) {
      initializeInputs()
    }
  }, [
    initializeInputs,
    inputsConfig?.length,
    reduxInspection.axles.tractor,
    reduxInspection.axles.trailer,
    setInitialInputValues,
    testName,
  ])

  useEffect(() => {
    if (!machine && id && machines?.length) {
      const machine = hardwareHandler.findMachineByMachineId({ machineId: id, testName })
      if (machine) setMachine(machine)
    }
  }, [hardwareHandler, id, machine, machines?.length, testName])

  if (testDoneByAutomaticMachine()) {
    return (
      <div className="manual-test">
        <div className="manual-test__done-automatic-machine">
          <i className="flaticon-checklist" />
          <span>
            Vaya... parece que ya has hecho este test mediante transmisión automática de datos
          </span>
        </div>
      </div>
    )
  }
  return (
    <section className="manual-test">
      <>
        {loading && (
          <div className="manual-test__id-container">
            <ChildSpinner visible={true} />
          </div>
        )}
        {!loading && (
          <div className="manual-test__id-container">
            <span>IDENTIFICADOR DEL EQUIPO</span>
            <Select
              noOptionsMessage={() => 'No hay máquinas disponibles'}
              menuPortalTarget={document.body}
              styles={selectStyles}
              className="manual-test__select"
              name="id"
              isDisabled={!enableEditing}
              placeholder="Seleccione una opción..."
              options={possibleIds}
              value={possibleIds?.find(elem => elem.value === id) || ''}
              onChange={opt => handleIdChange(opt)}
            />
            {
              <MyButtonsContainer margin="0 auto">
                {currentTest && (
                  <MyButton
                    text="Anular test"
                    onClick={() => {
                      if (!HAS_AUTH)
                        return easyToast(
                          'error',
                          `No puedes anular el test porque fue realizado por otro inspector ${
                            currentTest.user ? '(' + currentTest?.user + ')' : ''
                          }`
                        )
                      dispatch(
                        setReduxConfigModal({
                          icon: <i className="flaticon-warning" />,
                          title: '¿ANULAR TEST?',
                          message:
                            'Esta acción eliminará todos los valores e imágenes almacenados sobre este test',
                          onAccept: clearAll,
                        })
                      )
                    }}
                    warningColor={true}
                    icon={<i className="flaticon-trash" />}
                  />
                )}
                {!enableEditing && (
                  <MyButton
                    text="Editar"
                    onClick={() => {
                      if (!HAS_AUTH)
                        return easyToast(
                          'error',
                          `No puedes editar el test porque fue realizado por otro inspector ${
                            currentTest.user ? '(' + currentTest?.user + ')' : ''
                          }`
                        )
                      setEnableEditing(true)
                    }}
                    icon={<i className="flaticon-pencil" />}
                  />
                )}
                {enableEditing && id && (
                  <MyButton text="Guardar datos" onClick={handleSave} icon={<SaveIcon />} />
                )}
              </MyButtonsContainer>
            }
          </div>
        )}

        {id && (
          <>
            {loading && (
              <div className="manual-test__inputs-container">
                <ChildSpinner visible={true} tight={false} />
              </div>
            )}
            {!loading && (
              <div className="manual-test__inputs-container">
                <span>VALORES DEL TEST</span>
                <>
                  {inputsConfig?.length > 0 &&
                    inputsConfig?.map(elem => {
                      return (
                        <div
                          className="manual-test__input"
                          key={elem.name}
                          onClick={() => {
                            !enableEditing && easyToast('error', 'Antes debes habilitar la edición')
                          }}
                        >
                          {elem.unit && <div className="manual-test__unit">{elem.unit}</div>}
                          <label>{elem.name}</label>
                          <input
                            type={elem.type}
                            inputMode={elem.type === 'number' ? 'numeric' : 'text'}
                            name={elem.name}
                            value={inputsValues[elem.name]}
                            onChange={e => handleInputChange(e)}
                            disabled={!enableEditing}
                          />
                        </div>
                      )
                    })}
                </>
                {!inputsConfig?.length > 0 && (
                  <div className="no-values">ESTE EQUIPO NO TIENE VALORES QUE CUMPLIMENTAR</div>
                )}
              </div>
            )}
          </>
        )}

        {id && (
          <>
            {loading && (
              <div className="manual-test__id-container">
                <ChildSpinner visible={true} />
              </div>
            )}
            {!loading && (
              <div className="manual-test__images-container">
                <span>IMÁGENES DEL TEST</span>
                <Gallery
                  images={images}
                  onDeleteImage={deleteImage}
                  typeSelectable={false}
                  onCaptureImage={(image, geolocation) =>
                    uploadImage({ image, geolocation, testName, label: testName })
                  }
                />
              </div>
            )}
          </>
        )}
      </>
    </section>
  )
}
