//@ts-check
import React from 'react'
import { ExclamationTriangle as ErrorIcon } from 'react-bootstrap-icons'

/**
 *
 * @param {{icon?: any, text?: string}} param0
 * @returns
 */
export default function ErrorMessage({
  icon,
  text = 'Hay dificultades para responder tu mensaje en este momento. Inténtalo de nuevo',
}) {
  return (
    <div className="chatbot-body__error-message-container">
      <div className="chatbot-body__error-message">
        <div className="chatbot-body__error-message-icon">{icon ? icon : <ErrorIcon />}</div>
        <span>{text}</span>
      </div>
    </div>
  )
}
