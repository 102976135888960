export default function getUserShortName() {
  try {
    const storageName = window.sessionStorage.getItem('userFullName')
    if (storageName) {
      const splittedName = storageName.split(' ')
      return splittedName.length > 3 ? splittedName[0] + ' ' + splittedName[1] : splittedName[0]
    }
    return null
  } catch (error) {
    return null
  }
}
