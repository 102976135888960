import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import handleUnauthorizedResponse from 'myMethods/handleUnauthorizedResponse'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export function getUsers(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}users`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getUser(user, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}users/${user}`, {
    ...getJWT(),
    signal: options.signal,
  })
    .then(res => {
      return res
    })
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      throw err
    })
}

export function postUser(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}users`, data, {
    headers: { ...getJWT().headers, 'content-type': 'multipart/form-data' },
    signal: options.signal,
  })
}

export function putUser(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}users`, data, {
    headers: { ...getJWT().headers, 'content-type': 'multipart/form-data' },
    signal: options.signal,
  })
}

export function putUserPersonalSettings(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}users/personal_settings`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function postUserPersonalRemark(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}users/remark`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function deleteUserPersonalRemark(data, options = {}) {
  return Axios.delete(`${SERVER_URL}/api/${v2}users/remark`, {
    ...getJWT(),
    data,
    signal: options.signal,
  })
}
