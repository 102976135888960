import Modal from 'components/Others/Modal/Modal'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import formatTimeToHoursMinutesSeconds from 'myMethods/formatTimeToHoursMinutesSeconds'
import React, { useCallback } from 'react'

/**
 *
 * @param {{values: any[], closeModal: function, isOfficeIncluded: Boolean, loading: Boolean, Tools: Component, dateRange: [string, string] | []}} param0
 * @returns
 */
export default function TimesByInspectionType({
  values = [],
  closeModal,
  isOfficeIncluded = false,
  loading = false,
  Tools = <></>,
  dateRange,
}) {
  const formatValues = useCallback(values => {
    try {
      return Object.entries(values).map(([fuel, data]) => {
        return {
          fuel,
          inspections: data.numberInspections,
          ...data.averageTime,
          formatted_administrative: formatTimeToHoursMinutesSeconds(
            data.averageTime.administrative
          ),
          formatted_inspection: formatTimeToHoursMinutesSeconds(data.averageTime.inspection),
          formatted_supervisor: formatTimeToHoursMinutesSeconds(data.averageTime.supervisor),
          formatted_inspectionWaitTime: formatTimeToHoursMinutesSeconds(
            data.averageTime.inspectionWaitTime
          ),
          formatted_supervisorWaitTime: formatTimeToHoursMinutesSeconds(
            data.averageTime.supervisorWaitTime
          ),
        }
      })
    } catch (err) {
      console.error(err)
      return []
    }
  }, [])
  return (
    <Modal
      open={true}
      title="Tiempos medios por combustible"
      alertMessage="Para incluir la oficina en el tiempo medio total marque la casilla"
      onClose={closeModal}
      hasAcceptButton={false}
      loading={loading}
      content={
        <>
          {Tools}
          <MuiTable
            values={formatValues(values)}
            columns={[
              { title: 'Combustible', field: 'fuel' },
              { title: 'Total inspecciones', field: 'inspections' },
              {
                title: 'Total',
                render: useCallback(
                  rowData => {
                    return formatTimeToHoursMinutesSeconds(
                      isOfficeIncluded ? rowData.total : rowData.total - rowData.administrative
                    )
                  },
                  [isOfficeIncluded]
                ),
              },
              { title: 'Oficina', field: 'formatted_administrative' },
              { title: 'Esperando inspección', field: 'formatted_inspectionWaitTime' },
              { title: 'Inspección', field: 'formatted_inspection' },
              { title: 'Esperando supervisión', field: 'formatted_supervisorWaitTime' },
              { title: 'Supervisión', field: 'formatted_supervisor' },
            ]}
            options={{
              grouping: false,
              filtering: false,
              search: true,
            }}
            showToolbar={true}
            exportation={{
              type: 'STATISTICS_TIMES_FUEL',
              data: { values: formatValues(values) },
              options: {
                dateRange,
              },
              modalTitle: 'Exportar tiempos por combustible',
              defaultInputValue: 'TIEMPOS POR COMBUSTIBLE',
            }}
          />
        </>
      }
    />
  )
}
