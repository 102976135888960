import React from 'react'
import AnimatedListElem from 'components/Others/AnimatedList/AnimatedListElem'
import { AnimatePresence, motion } from 'framer-motion'
import Defect from './Defect'

/**
 *
 * @param {{defects: import('types/ManualManager').DefectToShow[], noUserInteraction?: boolean, forcedOnClick?: function}} param0
 * @returns
 */
export default function RenderListOfDefects({ defects, noUserInteraction = false, forcedOnClick }) {
  try {
    return (
      <>
        {defects?.length > 0 && (
          <motion.div
            className="list-container"
            variants={{
              hidden: {
                opacity: 0,
              },
              visible: {
                opacity: 1,
                transition: {
                  duration: 0.5,
                },
              },
            }}
            initial="hidden"
            animate="visible"
            exit="hidden"
          >
            <AnimatePresence>
              {defects.map((defect, i) => (
                <AnimatedListElem index={i} key={i}>
                  <Defect
                    key={defect.id}
                    defect={defect}
                    noUserInteraction={noUserInteraction}
                    forcedOnClick={forcedOnClick}
                  />
                </AnimatedListElem>
              ))}
            </AnimatePresence>
          </motion.div>
        )}
        {!defects?.length > 0 && <div className="no-values">No se han encontrado defectos</div>}
      </>
    )
  } catch (error) {
    return <div className="no-values">HA OCURRIDO UN ERROR CARGANDO LOS DEFECTOS VISUALES</div>
  }
}
