import React, { useState, useEffect } from 'react'

// MY IMPORTS
import 'components/Supervision/NewSupervision/Times/Times.scss'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import DateTimeITV from 'Share/DateTimeITV'
import TestsValues from 'Share/TestsValues'

export default function Times({ reduxInspection, showSupervisionTime = false, ...rest }) {
  const [testsCards, setTestsCards] = useState([])
  let cards = [
    {
      name: 'OFICINA',
      dateStart: reduxInspection?.times?.office_start,
      dateEnd: reduxInspection?.times?.office_end,
    },
    {
      name: 'INSPECCIÓN',
      dateStart: reduxInspection?.times?.inspection_start,
      dateEnd: reduxInspection?.times?.inspection_end,
    },
  ]

  if (showSupervisionTime) {
    cards.push({
      name: 'SUPERVISIÓN',
      dateStart: reduxInspection?.times?.supervision_start,
      dateEnd: reduxInspection?.times?.supervision_end,
    })
  }

  console.log({ cards, showSupervisionTime })

  useEffect(() => {
    try {
      if (reduxInspection?.testsValues) {
        let newCards = []
        const testsValuesHandler = new TestsValues(reduxInspection.testsValues)
        testsValuesHandler.testsValues.forEach(test => {
          const lastTest = testsValuesHandler.getLastTestValues(test.testName)?.test
          if (lastTest && lastTest.dates?.send && lastTest.dates?.read) {
            newCards.push({
              name: test.testName.toUpperCase(),
              dateStart: lastTest.dates.send,
              dateEnd: lastTest.dates.read,
            })
          }
        })
        setTestsCards(newCards)
      }
    } catch (error) {
      console.error(error)
    }
  }, [reduxInspection.testsValues])

  return (
    <section className="times" {...rest}>
      <ListItemsInTwoColumns>
        {cards.map(({ name, dateStart, dateEnd }) => (
          <CardNameValue
            name={name}
            value={DateTimeITV.getFormatedElapsedTime(dateStart, dateEnd)}
          />
        ))}
      </ListItemsInTwoColumns>
      {testsCards?.length > 0 && (
        <ListItemsInTwoColumns title="TESTS">
          {testsCards?.map(({ name, dateStart, dateEnd }) => (
            <CardNameValue
              name={name}
              value={DateTimeITV.getFormatedElapsedTime(dateStart, dateEnd)}
            />
          ))}
        </ListItemsInTwoColumns>
      )}
    </section>
  )
}
