//@ts-check
import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'

const SERVER_URL = process.env.REACT_APP_ITV_CARD_URL
/**
 * @typedef {import('types/ITVCard').ModelsTypes} IModelsTypes
 * @typedef {import('types/ITVCard').Values} IValues
 */
/**
 *
 * @param {{model?: IModelsTypes, values: IValues, preview?: boolean, generateITVCard?: boolean, generateAdditionalSheet?: boolean, onlyReforms?: boolean, offsets: {reformas: {x: number, y: number}}}} param0
 * @param {AbortSignal} [options.signal] - Optional AbortSignal to cancel the request.
 * @returns
 */
export function getITVCardPDF(
  {
    model = 'A',
    values,
    preview = false,
    generateITVCard = true,
    generateAdditionalSheet = true,
    onlyReforms = false,
    offsets,
  },
  options = {}
) {
  return Axios.post(
    `${SERVER_URL}/api/generate`,
    { model, values, preview, generateITVCard, generateAdditionalSheet, onlyReforms, offsets },
    {
      headers: { ...getJWT().headers },
      responseType: 'blob',
      signal: options.signal,
    }
  )
}
