import easyToast from 'components/Others/EasyToast/easyToast'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import './MachineTest.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  setReduxInspectionHardwareStatus,
  setReduxInspectionMachineDefects,
  setReduxInspectionTestsValues,
  updateReduxInspectionOneHardwareStatus,
} from 'redux/ducks/inspectionDuck'
import {
  abortHardwareSendFile as abortHardwareSendFileService,
  postHardwareCreateFile as postHardwareCreateFileService,
  postHardwareReadFile as postHardwareReadFileService,
} from 'services/Hardware'
import { postInspectionRemark as postInspectionRemarkService } from 'services/Inspection'
import Id from 'Share/Id'
import TestsValues from 'Share/TestsValues'
import MachineTestRead from './MachineTestRead/MachineTestRead'
import MachineTestSend from './MachineTestSend/MachineTestSend'
import ShowTestsResults from './ShowTestsResults/ShowTestsResults'
import MachineDefectsAppliedModal from './MachineDefectsAppliedModal/MachineDefectsAppliedModal'
import TestCancellationModal from './TestCancellationModal/TestCancellationModal'
import {
  cancelInspectionTestValue as cancelInspectionTestValueService,
  putInspectionConfig as putInspectionConfigService,
  putInspectionTechWeights,
} from 'services/Inspection'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import ErrorView from 'components/Others/ErrorView/ErrorView'
import useService from 'hooks/useService'
import { Eye as ShowIcon } from 'react-bootstrap-icons'

/**
 * @typedef {import('types/HardwareConfig').Machine} Machine
 * @typedef {import('types/HardwareStatus').hardwareStatus} HardwareStatus
 * @typedef {import("Share/HardwareHandler")} HardwareHandler
 * @typedef {import("types/LinkBetweenMachineAndManual").Links} LinkBetweenMachineAndManual
 */

/**
 *
 * @param {{testName: string, hardwareHandler: HardwareHandler, manualManager: InspectionDefectsManager, linkBetweenMachineAndManual: LinkBetweenMachineAndManual } }param0
 * @returns
 */
export default function MachineTest({
  testNames,
  hardwareHandler,
  manualManager,
  linkBetweenMachineAndManual,
}) {
  const redux = useSelector(store => store)
  const possibleCancellationReasons = redux?.config?.itv?.testCancellationReasons
  const testsValuesHandler = useMemo(
    () => new TestsValues(redux?.inspection?.testsValues, redux?.inspection?.hardwareConfig),
    [redux?.inspection?.hardwareConfig, redux?.inspection?.testsValues]
  )
  const dispatch = useDispatch()
  const possibleLines = hardwareHandler.getPossibleLines()
  const machinesByLine = hardwareHandler.getMachinesByLine()
  const [machineDefectsModal, setMachineDefectsModal] = useState({ show: false, defects: [] })
  const [cancellationModal, setCancellationModal] = useState({
    show: false,
    testName: null,
  })
  const [testNamesDoneVisually, setTestNamesDoneVisually] = useState([])
  const testNamesNotDone = useMemo(
    () => testNames.filter(name => !testsValuesHandler.isThisTestDone(name)?.done),
    [testNames, testsValuesHandler]
  )
  const testNamesWithValuesEvenCanceled = useMemo(
    () => testNames.filter(name => Boolean(testsValuesHandler.getLastTestValues(name).test)),
    [testNames, testsValuesHandler]
  )
  const testNamesToWorkOn = testNamesNotDone.filter(
    testName => !testNamesDoneVisually.includes(testName)
  )
  const [showResults, setShowResults] = useState(false)
  /**@type {[{machine: Machine | null, status: HardwareStatus | null}, ]} */
  const [state, setState] = useState({
    machine: null,
    status: null,
  })
  const hasBeenSent = Boolean(state.status?.filename)
  const vehicleData = redux?.inspection?.vehicleData
  const [loadingSend, setLoadingSend] = useState(false)

  const { fetch: putTechWeights } = useService({ service: putInspectionTechWeights })

  const { fetch: putInspectionConfig } = useService({
    service: putInspectionConfigService,
  })

  const { fetch: postHardwareCreateFile } = useService({
    service: postHardwareCreateFileService,
    fullResponse: true,
  })
  const { fetch: abortHardwareSendFile, loading: loadingAbort } = useService({
    service: abortHardwareSendFileService,
  })
  const { fetch: postHardwareReadFile, loading: loadingReadFile } = useService({
    service: postHardwareReadFileService,
    fullResponse: true,
  })
  const { fetch: cancelInspectionTestValue, loading: loadingCancelTest } = useService({
    service: cancelInspectionTestValueService,
  })
  const { fetch: postInspectionRemark } = useService({
    service: postInspectionRemarkService,
  })

  const loading = loadingSend || loadingAbort || loadingReadFile || loadingCancelTest

  const send = async () => {
    try {
      setLoadingSend(true)
      const internalId = state?.machine?.internalId

      if (!internalId) throw new Error('No internalId or vehicleData')
      if (redux.inspection.config) {
        await putInspectionConfig({
          id: Id.decrypt(window.sessionStorage.getItem('id')),
          config: redux.inspection.config,
        })
      }
      if (redux.inspection.techWeights) {
        await putTechWeights({
          id: Id.decrypt(window.sessionStorage.getItem('id')),
          techWeights: redux.inspection.techWeights,
        })
      }
      const res = await postHardwareCreateFile({
        internalId,
        inspectionId: Id.decrypt(window.sessionStorage.getItem('id')),
      })
      if (!res) throw new Error('No response received')
      if (res && res.status === 200 && res.data.hardwareStatus) {
        dispatch(setReduxInspectionHardwareStatus(res.data.hardwareStatus))
        return easyToast('dark', 'Datos enviados correctamente')
      } else if (res && res.status === 404) {
        easyToast('error', 'La máquina no responde')
      } else {
        throw new Error('No case possible with response received')
      }
    } catch (err) {
      console.error(err)
      if (err.response?.status === 501) {
        return easyToast('error', err.response.data?.message || 'La máquina no responde')
      }
      easyToast('error', 'Ha ocurrido un error enviando los datos a la máquina')
    } finally {
      setLoadingSend(false)
    }
  }

  const read = async () => {
    try {
      const res = await postHardwareReadFile({
        testNames: testNamesNotDone,
        internalId: state.machine?.internalId,
        inspectionId: Id.decrypt(window.sessionStorage.getItem('id')),
      })
      if (
        !(
          res &&
          res.data.testsValues &&
          res.data.allTestsValues &&
          res.data.machineDefects &&
          res.data.allMachineDefects &&
          res.data.hardwareStatus
        )
      ) {
        throw new Error('Missing values in response data')
      }
      if (res.status === 204) {
        easyToast('error', 'Ningún valor respondido por la máquina')
      }
      easyToast(
        'dark',
        res.data.testsValues?.length
          ? 'Datos recibidos correctamente'
          : `No se han recibido datos para ${testNamesNotDone?.join(' + ')}`
      )
      dispatch(setReduxInspectionHardwareStatus(res.data.hardwareStatus))
      dispatch(setReduxInspectionTestsValues(res.data.allTestsValues))
      dispatch(setReduxInspectionMachineDefects(res.data.allMachineDefects))

      handleAfterReadingData({ allTestsValues: res.data.allTestsValues })
      setMachineDefectsModal({ show: true, defects: res.data.machineDefects })
      setShowResults(true)
    } catch (err) {
      console.error(err)
      if (err.response?.status === 501) {
        return easyToast('error', err.response.data?.message || 'La máquina no responde')
      } else if (err.response?.status === 502) {
        return easyToast('error', 'No se encuentra la respuesta de la máquina. Inténtelo de nuevo')
      }
      easyToast('error', 'Ha ocurrido un error leyendo los datos')
    }
  }

  const addRemark = async remark => {
    if (!remark) throw new Error('No remark provided')

    await postInspectionRemark({
      id: Id.decrypt(window.sessionStorage.getItem('id')),
      generalRemark: remark,
    })
  }

  const handleAfterReadingData = ({ allTestsValues }) => {
    try {
      const testsValues = new TestsValues(allTestsValues, redux?.inspection?.hardwareConfig)
      const lastFrenometro = testsValues.getLastTestValues('frenómetro', true)
      const remark = 'Comprobado freno de mano de estacionamiento en placas de holgura'
      const foundRemark = redux.inspection.remarks.find(rem => rem.text === remark)
      console.log({ foundRemark, lastFrenometro })
      if (lastFrenometro.test.config?.frenómetro?.deactivateParkingBrake && !foundRemark) {
        console.log('Entro modal')
        dispatch(
          setReduxConfigModal({
            title: 'Has elegido desactivar la medición del freno de mano',
            message: `¿Añadir "${remark}" como observación?`,
            onAccept: async () => await addRemark(remark),
          })
        )
      }
    } catch (err) {
      console.error(err)
    }
  }

  const abortSend = async () => {
    try {
      const res = await abortHardwareSendFile({
        inspectionId: Id.decrypt(window.sessionStorage.getItem('id')),
        internalId: state.machine.internalId,
      })
      if (!res.hardwareStatus) throw new Error('No hardwareStatus responsed')
      dispatch(updateReduxInspectionOneHardwareStatus(res.hardwareStatus))
      easyToast('dark', 'Envío anulado correctamente')
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error anulando el envío. Inténtelo de nuevo')
    }
  }

  const cancelTest = async reason => {
    try {
      await cancelInspectionTestValue({
        inspectionId: Id.decrypt(window.sessionStorage.getItem('id')),
        testName: cancellationModal.testName,
        reason,
      })

      easyToast('dark', `Test ${cancellationModal.testName} anulado correctamente`)
      setCancellationModal({ show: false, testName: null })
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error anulando el test')
    }
  }

  const updateMachineDefects = useCallback(() => {
    try {
      let machineDefects = []
      testNames.forEach(testName => {
        const found = redux.inspection.machineDefects?.filter(elem => elem.testName === testName)
        found && machineDefects.push(...found)
      })
      setMachineDefectsModal(prevState => ({ ...prevState, defects: machineDefects }))
    } catch (err) {
      console.error(err)
    }
  }, [redux?.inspection?.machineDefects, testNames])

  useEffect(() => {
    if (!state.machine && machinesByLine) {
      const lastMachine = testsValuesHandler.getLastMachineUsedByTestNamesAndListOfPossibleMachines(
        testNames,
        hardwareHandler?.hardware?.machines
      )
      if (lastMachine) {
        const foundMachineUpToDate = hardwareHandler.findMachineByInternalId(
          lastMachine.internalId,
          testNames,
          false
        )
        foundMachineUpToDate && setState({ machine: foundMachineUpToDate, status: null })
      }
      const defaultLineMachines = machinesByLine[redux?.inspection?.line]
      if (defaultLineMachines && defaultLineMachines.length === 1) {
        setState(prevState => ({ ...prevState, machine: defaultLineMachines[0] }))
      }
    }
  }, [
    possibleLines,
    redux?.inspection?.line,
    machinesByLine,
    state.machine,
    testsValuesHandler,
    testNames,
    hardwareHandler?.hardware,
    hardwareHandler,
  ])
  useEffect(() => {
    if (!testNamesToWorkOn?.length && testNamesWithValuesEvenCanceled?.length) {
      setShowResults(true)
    }
  }, [testNames.length, testNamesToWorkOn?.length, testNamesWithValuesEvenCanceled])

  useEffect(() => {
    // Find status when machine changes
    if (state.machine) {
      const status = hardwareHandler.findStatus(
        state.machine.internalId,
        redux?.inspection?.hardwareStatus
      )
      if (status) {
        setState(prevState => ({
          ...prevState,
          status,
        }))
      }
    }
  }, [hardwareHandler, redux?.inspection?.hardwareStatus, state.machine])
  console.log({ state })

  useEffect(() => {
    // Inicializa machineDefectsModal
    updateMachineDefects()
  }, [
    machineDefectsModal?.defects?.length,
    redux?.inspection?.machineDefects,
    testNames,
    updateMachineDefects,
  ])

  useEffect(() => {
    // Modifica testNamesToWorkOn
    try {
      let auxNames = []

      // Si hay defectos visuales aplicados
      if (testNamesNotDone.length && manualManager) {
        let filterDefectsByName = linkBetweenMachineAndManual.semiautomatic.byNames.filter(elem =>
          testNamesNotDone.includes(elem.testName)
        )
        filterDefectsByName.forEach(({ testName, defects }) => {
          let defectsMatched = []
          for (const defect of defects) {
            const found = manualManager.findDefectAndChilds(defect)
            console.log({ defect, found })
            if (
              (found &&
                found.defect.done &&
                found.defect.user != null &&
                found.defect.user !== redux?.user?.data?.user) ||
              found?.defect?.visualDefect
            ) {
              console.log({ found, defect })
              defectsMatched.push(defect)
            }
          }
          if (defectsMatched.length) {
            auxNames.push({
              testName,
              defects: defectsMatched,
            })
          }
        })
        if (testNamesDoneVisually.length && !auxNames.length) setTestNamesDoneVisually([])
        if (!testNamesDoneVisually.length && auxNames.length) {
          const namesDoneVisually = auxNames.map(elem => elem.testName)
          const testsCanDo = testNamesNotDone.filter(
            testName => !namesDoneVisually.includes(testName)
          )
          setTestNamesDoneVisually(namesDoneVisually)
          dispatch(
            setReduxConfigModal({
              error: true,
              title: 'CONFLICTO',
              message: `${
                auxNames.length > 1
                  ? `No puede realizar los siguientes tests: ${auxNames
                      .map(elem => elem.testName)
                      .join(', ')
                      .toUpperCase()}. Los defectos del manual que pueden marcar ya han sido revisados visualmente `
                  : `No puede realizar ${auxNames[0].testName.toUpperCase()}. Los defectos del manual que puede marcar ya han sido revisados visualmente`
              }`,
              message2: auxNames
                .map(elem => elem.defects)
                .join()
                .split(',')
                .join(' - '),
              alertMessage: testsCanDo?.length
                ? `PARA REALIZAR ${testsCanDo.join(', ').toUpperCase()} CONTINÚE`
                : null,
            })
          )
        }
      }
    } catch (err) {
      console.error(err)
    }
    //eslint-disable-next-line
  }, [linkBetweenMachineAndManual, manualManager, testNamesNotDone])
  console.log({ testNamesDoneVisually, testNamesToWorkOn, testNamesWithValuesEvenCanceled })
  try {
    return (
      <section className="machine-test">
        {machinesByLine && !hasBeenSent && testNamesToWorkOn.length > 0 && (
          <MachineTestSend
            testNamesToWorkOn={testNamesToWorkOn}
            testNames={testNames}
            machinesByLine={machinesByLine}
            inspectionLine={redux?.inspection?.line}
            state={state}
            setMachine={machine => setState(prevState => ({ ...prevState, machine }))}
            loading={loading}
            send={send}
            sectionNumber={redux?.inspection?.filter?.section}
            vehicleData={vehicleData}
            currentTestsValuesButton={{
              show: testNamesWithValuesEvenCanceled?.length > 0,
              onClick: () => setShowResults(!showResults),
            }}
            machineDefectsButton={{
              show: machineDefectsModal?.defects?.length > 0,
              onClick: () => setMachineDefectsModal({ ...machineDefectsModal, show: true }),
            }}
          />
        )}
        {hasBeenSent && (
          <MachineTestRead
            testNamesToWorkOn={testNamesToWorkOn}
            line={state.machine?.line}
            read={read}
            abortSend={abortSend}
            loading={loading}
          />
        )}
        {showResults && (
          <ShowTestsResults
            testsValues={testsValuesHandler.testsValues}
            testNames={testNames}
            letCancel={true}
            customButton={
              machineDefectsModal?.defects?.length > 0 ? (
                <MyButton
                  text="Mostrar defectos aplicados"
                  icon={<ShowIcon />}
                  onClick={() => setMachineDefectsModal({ ...machineDefectsModal, show: true })}
                />
              ) : null
            }
            cancelTest={testName =>
              setCancellationModal({
                show: true,
                testName,
              })
            }
          />
        )}
        {machineDefectsModal.show && machineDefectsModal.defects?.length > 0 && (
          <MachineDefectsAppliedModal
            machineDefects={machineDefectsModal.defects}
            closeModal={() => setMachineDefectsModal({ ...machineDefectsModal, show: false })}
            sectionNumber={redux?.inspection?.filter?.section}
            vehicleData={redux?.inspection?.vehicleData}
            inspectionType={redux?.inspection?.inspectionType}
            updateMachineDefects={updateMachineDefects}
          />
        )}
        {cancellationModal.show && (
          <TestCancellationModal
            possibleReasons={possibleCancellationReasons}
            testName={cancellationModal.testName}
            handleAccept={cancelTest}
            closeModal={() => setCancellationModal({ show: false, testName: null })}
            loading={loading}
          />
        )}
      </section>
    )
  } catch (err) {
    console.error(err)
    return <ErrorView />
  }
}
