import React, { useState, useEffect } from 'react'
import './SubmitArchingModal.scss'
import formatEuro from 'myMethods/formatEuro'
import isValidFunction from 'myMethods/isValidFunction'
import Modal from 'components/Others/Modal/Modal'
import Select from 'components/Others/Select/Select'

const rawOptions = [
  { value: 'INICIO', label: 'INICIO' },
  { value: 'PARCIAL', label: 'PARCIAL' },
  { value: 'FIN', label: 'FIN' },
]

export default function SubmitArchingModal({
  status,
  showPartial,
  repeating,
  optionToForce,
  closeModal,
  onAccept,
  loading,
  coinsValue,
  difference,
}) {
  const [options, setOptions] = useState(rawOptions)
  const [showInput, setShowInput] = useState(false)
  const [selectedOption, setSelectedOption] = useState(optionToForce)
  const [keep, setKeep] = useState(null)
  const movement = keep != null ? keep - coinsValue ?? 0 : null

  useEffect(() => {
    if (optionToForce) {
      return setOptions([{ value: optionToForce, label: optionToForce }])
    }
    if (!status?.wasOpen) {
      setOptions([{ value: 'INICIO', label: 'INICIO' }])
      setSelectedOption('INICIO')
    } else {
      if (showPartial) {
        setOptions(rawOptions.filter(elem => elem.value !== 'INICIO'))
        setSelectedOption('PARCIAL')
      } else {
        setOptions([{ value: 'FIN', label: 'FIN' }])
        setSelectedOption('FIN')
      }
    }
  }, [status, showPartial, optionToForce])

  return (
    <Modal
      open={true}
      onClose={closeModal}
      title={repeating ? 'Repetir arqueo' : `Arqueo de ${status?.name}`}
      closeAfterAccept={false}
      onAccept={() => isValidFunction(onAccept) && onAccept(selectedOption, keep)}
      closeOnOverlay={false}
      loading={loading}
      content={
        <div className="submit-arching-modal">
          <div className="cash-register-arching__details">
            <div className="cash-register-arching__detail">
              <b>Efectivo</b>
              <span>{formatEuro(coinsValue)}</span>
            </div>
            <div
              className={
                difference !== 0
                  ? 'cash-register-arching__detail cash-register-arching__difference--error'
                  : 'cash-register-arching__detail'
              }
            >
              <b>Diferencia</b>
              <span>{formatEuro(difference)}</span>
            </div>
          </div>
          <Select
            name="type"
            options={options}
            value={options.find(elem => elem.value === selectedOption) || options[0] || ''}
            onChange={opt => {
              setSelectedOption(opt?.value ?? null)
            }}
            isDisabled={Boolean(options.length === 1)}
          />
          {selectedOption === 'FIN' && (
            <span onClick={() => setShowInput(!showInput)}>
              {showInput
                ? 'No voy modificar el dinero que dejo en caja'
                : 'Voy modificar el dinero que dejo en caja'}
            </span>
          )}
          {showInput && (
            <div className="submit-arching-modal__amount" data-aos="fade-in">
              <label>Dinero que queda en caja (€)</label>
              <input
                autoFocus
                type="number"
                min="0"
                value={keep}
                onChange={e => {
                  try {
                    let value = parseFloat(e.target.value)
                    setKeep(isNaN(value) ? '' : parseFloat(e.target.value))
                  } catch (err) {
                    console.error(err)
                  }
                }}
                disabled={!showInput}
              />
              {Boolean(movement) && keep >= 0 && keep <= coinsValue && (
                <div data-aos="fade-in">
                  {'Se retiran '}
                  {formatEuro(Math.abs(movement))}
                </div>
              )}
              {keep > coinsValue && (
                <div data-aos="fade-in">No puede quedar en caja más dinero del que hay</div>
              )}
            </div>
          )}
        </div>
      }
    />
  )
}
