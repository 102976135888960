//@ts-check

import ErrorForDefect from './ErrorForDefect'

export default class DefectId {
  #id
  /**
   *
   * @param {string} id
   */
  constructor(id) {
    this.#id = id
  }

  /**
   *
   * @param {any} sectionId
   */
  static validateSectionId(sectionId) {
    if (typeof sectionId !== 'string' || sectionId.length == 0) {
      throw ErrorForDefect.invalidIdFormat(sectionId)
    }
    const sections = sectionId.split('.')
    sections.forEach(familyOrder => {
      if (familyOrder == null || Number.isNaN(familyOrder) || familyOrder === '') {
        throw ErrorForDefect.invalidIdFormat(sectionId)
      }
    })
    return sectionId
  }

  toPrimitive() {
    return this.#id
  }

  /**
   *
   * @param {string} id
   */
  static formPrimitive(id) {
    return new DefectId(DefectId.validateSectionId(id))
  }

  toString() {
    return this.#id
  }

  /**
   *
   * @returns {string| null}
   */
  get charper() {
    if (typeof this.#id !== 'string') throw ErrorForDefect.invalidIdFormat(this.#id)

    const familyOrder = DefectId.validateSectionId(this.#id).split('.')
    return familyOrder[0] ?? null
  }
  /**
   *
   * @returns {string| null}
   */
  get father() {
    if (typeof this.#id !== 'string') throw ErrorForDefect.invalidIdFormat(this.#id)

    const familyOrder = DefectId.validateSectionId(this.#id).split('.')
    if (familyOrder[1] == null) return null
    return familyOrder[0] + '.' + familyOrder[1]
  }

  /**
   *
   * @returns {string| null}
   */
  get son() {
    if (typeof this.#id !== 'string') throw ErrorForDefect.invalidIdFormat(this.#id)

    const familyOrder = DefectId.validateSectionId(this.#id).split('.')
    if (familyOrder[2] == null) return null
    return familyOrder[0] + '.' + familyOrder[1] + '.' + familyOrder[2]
  }
  /**
   *
   * @returns {string| null}
   */
  get grandson() {
    if (typeof this.#id !== 'string') throw ErrorForDefect.invalidIdFormat(this.#id)

    const familyOrder = DefectId.validateSectionId(this.#id).split('.')
    if (familyOrder[3] == null) return null
    return familyOrder[0] + '.' + familyOrder[1] + '.' + familyOrder[2] + '.' + familyOrder[3]
  }

  get parentOrderFive() {
    if (typeof this.#id !== 'string') throw ErrorForDefect.invalidIdFormat(this.#id)

    const familyOrder = DefectId.validateSectionId(this.#id).split('.')
    if (familyOrder[3] == null) return null
    return (
      familyOrder[0] +
      '.' +
      familyOrder[1] +
      '.' +
      familyOrder[2] +
      '.' +
      familyOrder[3] +
      '.' +
      familyOrder[4]
    )
  }

  getParentOrder() {
    const familyOrder = DefectId.validateSectionId(this.#id).split('.')
    switch (familyOrder.length) {
      case 1:
        return 'CHARPER'
      case 2:
        return 'FATHER'
      case 3:
        return 'SON'
      case 4:
        return 'GRANDSON'
      case 5:
        return 'CINCO'

      default:
        throw ErrorForDefect.invalidParentOrder(this.#id)
    }
  }

  /**
   *
   * @param {number} depth
   * @return {string | null}
   */
  getParentIdFormDepth(depth) {
    switch (depth) {
      case 1:
        return this.charper
      case 2:
        return this.father
      case 3:
        return this.son
      case 4:
        return this.grandson
      case 5:
        return this.parentOrderFive
      default:
        return null
    }
  }

  get parentOrderDepth() {
    return DefectId.validateSectionId(this.#id).split('.').length
  }
}
