import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

// MY IMPORTS
import 'components/Inspection/Defectology/Defect/ListOfDefects.scss'
import manualChapters from 'resources/manualChapters.json'
import Title from 'components/Others/Title/Title'
import easyToast from 'components/Others/EasyToast/easyToast'
import Id from 'Share/Id'
import MobileBackBtn from 'components/Others/MobileBackBtn/MobileBackBtn'
import RenderListOfDefects from './RenderListOfDefects'
import FilterInfo from 'components/Inspection/FilterInfo/FilterInfo'

/**
 * @typedef {import('Share/Defect/InspectionDefectsManager').default} manualManager
 */

// SERVICES
import { putInspectionDefectsDone as putInspectionDefectsDoneService } from 'services/Inspection'
import ManualBookmarkExtractor from 'components/Inspection/ManualBookmarkExtractor/ManualBookmarkExtractor'
import useService from 'hooks/useService'

export default function ListOfDefects() {
  const params = useParams()
  const redux = useSelector(store => store)
  const reduxInspection = redux.inspection
  const dispatch = useDispatch()
  const [allDone, setAllDone] = useState(false)
  const [defectsToShow, setDefectsToShow] = useState([])
  const isParent = params.manualChapter.split('.').length === 1

  const component = useMemo(() => <RenderListOfDefects defects={defectsToShow} />, [defectsToShow])

  const { fetch: putInspectionDefectsDone } = useService({
    service: putInspectionDefectsDoneService,
  })

  const handleClickDone = async () => {
    try {
      let ids = null
      if (allDone) {
        ids = component.props.defects
          .filter(defect => {
            console.log({ defect })
            return (
              (!defect.user || defect.user === redux?.user?.data?.user) &&
              defect.apply &&
              defect.done
            )
          })
          .map(defect => defect.id)
      } else {
        ids = component.props.defects
          .filter(
            defect =>
              (!defect.user || defect.user === redux?.user?.data?.user) &&
              defect.apply &&
              !defect.done
          )
          .map(defect => defect.id)
      }
      if (!ids?.length) return easyToast('error', 'No puedes desmarcar defectos de otro usuario')

      await putInspectionDefectsDone({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        defectsIds: ids,
        doneValue: !allDone,
      })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    try {
      /**@type {manualManager} */
      const manualManager = reduxInspection?.manualManager
      if (manualManager) {
        const node = manualManager.findDefectAndChilds(params.manualChapter)
        const childsToShow = node.listToShowThisAndChilds()
        childsToShow?.length && setDefectsToShow(childsToShow)
      }
    } catch (err) {
      console.error(err)
    }
  }, [params.manualChapter, reduxInspection?.manualManager])

  useEffect(() => {
    // Cambia el valor de allDone en el caso de que todos los defectos estén vistos o sin ver
    if (defectsToShow.length && component && component?.props?.defects?.length) {
      let numberOfDefectsAlreadyDone = 0
      component.props.defects.forEach(elem => {
        reduxInspection.defectsDone?.find(done => done.id === elem.id) &&
          numberOfDefectsAlreadyDone++
      })
      if (numberOfDefectsAlreadyDone === component.props.defects.length) setAllDone(true)
      else setAllDone(false)
    }
  }, [
    component,
    reduxInspection.optionalDefects,
    reduxInspection.defectsDone,
    params.manualChapter,
    isParent,
    defectsToShow,
  ])

  return (
    <>
      {isParent && manualChapters.find(elem => elem.chapter === params.manualChapter)?.title && (
        <Title text={manualChapters.find(elem => elem.chapter === params.manualChapter).title} />
      )}
      <FilterInfo reduxInspection={reduxInspection} />
      {isParent && defectsToShow?.length > 0 && (
        <div className="list-of-defects__options">
          <span>Mantén pulsado un defecto para modificar la trazabilidad</span>
          <div
            className={`list-of-defects__check-all ${
              allDone ? 'list-of-defects__check-all--done' : ''
            }`}
            onClick={handleClickDone}
          >
            {allDone ? 'DESMARCAR TODO' : 'MARCAR TODO'}
          </div>
        </div>
      )}
      <MobileBackBtn />
      <ManualBookmarkExtractor section={reduxInspection?.filter?.section} />
      {component}
    </>
  )
}
