//@ts-check
import React, { useState, useEffect, useCallback } from 'react'

// MY IMPORTS
import InvoiceModal from './InvoiceModal/InvoiceModal'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import { useSelector } from 'react-redux'
import TablePagination from 'components/Others/MuiTable/TablePagination/TablePagination'
import TableFilter from 'components/Others/TableFilter/TableFilter'
import Checkbox from 'components/Others/Checkbox/Checkbox'

/**
 *
 * @param {{invoices: import('types/Invoice').InvoiceGetAllResDTO[], loading: boolean, total: number,
 * fetchInvoices: function, fetchNextChunk: function, fetchDeals: function, currentFilterValues: import('types/FilterServiceParams').FilterServiceParams, onClearFilter: function, showTableFilter: boolean }} param0
 * @returns
 */

export default function Invoices({
  invoices,
  loading,
  total,
  fetchInvoices,
  fetchNextChunk,
  fetchDeals,
  currentFilterValues,
  onClearFilter,
  showTableFilter,
}) {
  const [showModal, setShowModal] = useState(false)
  const [rowClicked, setRowClicked] = useState(null)
  const [hideCancelled, setHideCancelled] = useState(false)

  useEffect(() => {
    if (invoices && showModal && rowClicked) {
      const found = invoices.find(elem => elem.invoiceId === rowClicked.invoiceId)
      if (found) setRowClicked(found)
    }
    //eslint-disable-next-line
  }, [invoices])
  console.log({ invoices })
  return (
    <section>
      <MuiTable
        tableFilter={
          showTableFilter ? (
            <TableFilter
              totalValues={total}
              initialFilterValues={currentFilterValues}
              startDate={true}
              endDate={true}
              likeDni={true}
              likeName={true}
              serie={true}
              loading={loading}
              onSubmit={filterValues => {
                fetchInvoices(null, filterValues)
              }}
              onClear={onClearFilter}
            />
          ) : null
        }
        filterValues={showTableFilter ? currentFilterValues : null}
        onClearTableFilter={onClearFilter}
        columnsKey="invoices"
        values={hideCancelled ? invoices.filter(elem => !elem.canceledBy) : invoices}
        loading={loading}
        onRowClick={rowData => {
          setRowClicked(rowData)
          setShowModal(true)
        }}
        showToolbar={false}
        exportation={{
          type: 'INVOICES',
          data: { values: invoices },
          modalTitle: 'Exportar facturas',
          defaultInputValue: 'FACTURAS',
        }}
        options={{
          rowStyle: useCallback(rowData => {
            return {
              color:
                rowData.canceledBy?.number || rowData.serie === 'cancelledCorrective'
                  ? '#ff6961'
                  : rowData.serie === 'corrective'
                  ? '#8884d8'
                  : 'black',
            }
          }, []),
        }}
        toolbarComponent={
          <>
            <Checkbox
              label="Ocultar canceladas"
              checked={hideCancelled}
              onChange={value => setHideCancelled(value)}
            />
            <TablePagination
              currentDataLength={invoices?.length}
              totalDataLength={total}
              onClick={fetchNextChunk}
            />
          </>
        }
      />
      {showModal && (
        <InvoiceModal
          invoiceId={rowClicked?.invoiceId}
          serie={rowClicked?.serie}
          refetch={fetchInvoices}
          refetchDeals={fetchDeals}
          closeModal={() => {
            setShowModal(false)
          }}
        />
      )}
    </section>
  )
}
