import React, { useState } from 'react'
import Modal from 'components/Others/Modal/Modal'
import MachineDefectDetail from 'components/Inspection/DefectsSupervisor/MachineDefectDetail/MachineDefectDetail'
import Slider from 'components/Others/Slider/Slider'
import './MachineDefectsAppliedModal.scss'

/**@typedef {import('types/Defect').MachineDefect} MachineDefect */
/**
 *
 * @param {{machineDefects: MachineDefect}} param0
 * @returns
 */
export default function MachineDefectsAppliedModal({
  machineDefects,
  maxRemarkLength,
  sectionNumber,
  vehicleData,
  inspectionType,
  closeModal,
}) {
  return (
    <Modal
      title={
        machineDefects?.length ? `${machineDefects.length} defectos de máquina aplicados` : null
      }
      open={true}
      onClose={closeModal}
      hasCloseButton={false}
      loading={!machineDefects?.length}
      content={
        <div className="machine-defects-applied-modal">
          <Slider>
            {machineDefects.map((defect, i) => (
              <MachineDefectDetail
                key={i}
                defectId={defect?.defect?.id}
                machineDefects={machineDefects}
                maxRemarkLength={maxRemarkLength}
                sectionNumber={sectionNumber}
                vehicleData={vehicleData}
                inspectionType={inspectionType}
              />
            ))}
          </Slider>
        </div>
      }
    />
  )
}
