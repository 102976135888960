import KNumberFormatter from 'components/Others/KNumberFormatter/KNumberFormatter'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import React from 'react'
import './StatisticsSummaryCard.scss'

/**
 *
 * @param {{title: string | undefined, primaryValue: number, primaryLabel: string, secondaryValue: number | undefined, secondaryLabel: string | undefined, diffValue: number | undefined, diffLabel: string | undefined, diffPositive: boolean | undefined, unit: string | undefined, shorten: boolean, loading: boolean}} param0
 */
export default function StatisticsSummaryCard({
  title,
  primaryValue,
  primaryLabel,
  secondaryValue,
  secondaryLabel,
  diffValue,
  diffLabel,
  unit,
  shorten = true,
  loading = false,
  ...rest
}) {
  try {
    if (primaryValue == null) throw new Error('No primary value provided')
    if (loading)
      return (
        <div className="statistics-summary-card">
          <ChildSpinner />
        </div>
      )
    return (
      <div className="statistics-summary-card" {...rest}>
        {title && <div className="statistics-summary-card__title">{title}</div>}
        <div className="statistics-summary-card__body">
          <div className="statistics-summary-card__primary">
            <div className="statistics-summary-card__primary-value">
              {<KNumberFormatter number={primaryValue} unit={unit} animate={true} />}
            </div>
            {primaryLabel && <label>{primaryLabel}</label>}
          </div>
          {diffValue != null && (
            <div
              className={
                diffValue > 0
                  ? 'statistics-summary-card__diff--positive'
                  : diffValue < 0
                  ? 'statistics-summary-card__diff--negative'
                  : 'statistics-summary-card__diff'
              }
            >
              {diffValue !== 0 && (
                <div className="statistics-summary-card__diff-icon">
                  {diffValue > 0 ? '▲' : '▼'}
                </div>
              )}
              <div className="statistics-summary-card__diff-value">
                {diffValue === 0 ? (
                  'Igual'
                ) : (
                  <KNumberFormatter number={diffValue} unit={unit} animate={true} />
                )}
              </div>
              {diffLabel && <label>{diffLabel}</label>}
            </div>
          )}
          {secondaryValue != null && (
            <div className="statistics-summary-card__secondary">
              <div className="statistics-summary-card__secondary-value">
                {<KNumberFormatter number={secondaryValue} unit={unit} animate={true} />}
              </div>
              {secondaryLabel && <label>{secondaryLabel}</label>}
            </div>
          )}
        </div>
      </div>
    )
  } catch (error) {
    console.error(error)
    return <></>
  }
}
