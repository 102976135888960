import React from 'react'

//MY IMPORTS
import ListInspections from 'components/Inspection/ListInspections/ListInspections'

export default function NewInspection() {
  return (
    <>
      <ListInspections />
    </>
  )
}
