import React from 'react'
import './TestsResultsErrorModal.scss'

export default function TestsResultsErrorModal({ errors }) {
  console.log({ errors })
  return (
    <div className="tests-results-error-modal">
      {errors &&
        errors.length > 0 &&
        errors.map((error, i) => (
          <div className="tests-results-error-modal__elem" key={i}>
            <div>{error.name}</div>
            <div>{error.reason}</div>
          </div>
        ))}
    </div>
  )
}
