import React, { useState } from 'react'
import { Search as SearchIcon } from 'react-bootstrap-icons'

// MY IMPORTS
import './DefectsFinder.scss'
import removeAccents from 'myMethods/removeAccents'
import { Close } from '@mui/icons-material'

export default function DefectsFinder({ defects, setFoundDefects }) {
  const [value, setValue] = useState('')

  const handleInputChange = value => {
    try {
      setValue(value)
      if (value.length > 2) {
        if (!defects || !defects.length) throw new Error('No defects in DefectsFinder')
        value = removeAccents(value)
        return setFoundDefects(
          defects.filter(
            defect =>
              defect.id.startsWith(value) || defect.name.toLowerCase().includes(value.toLowerCase())
          )
        )
      }
      if (!value) {
        setFoundDefects(null)
      }
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className="defects-finder">
      {value && <SearchIcon />}
      <input
        onChange={e => {
          handleInputChange(e.target.value)
        }}
        value={value}
        placeholder="Filtrar defectos..."
      />
      {!value && <SearchIcon />}
      {value && <Close onClick={() => handleInputChange('')} />}
    </div>
  )
}
