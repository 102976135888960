//@ts-check
import React from 'react'
import { useDispatch } from 'react-redux'
import { setReduxInspectionIsFiltered } from 'redux/ducks/inspectionDuck'
import './FilterInfo.scss'

/**
 *
 * @param {{reduxInspection: import('types/Inspection').Inspection}} param0
 * @returns
 */
export default function FilterInfo({ reduxInspection }) {
  const dispatch = useDispatch()
  return (
    <div className="filter-info">
      {reduxInspection?.isFiltered && (
        <>
          FILTRANDO MANUAL PARA <span>{reduxInspection.inspectionType} </span> A{' '}
          <span>{reduxInspection.filter?.name?.toUpperCase()}</span>
          {reduxInspection.config?.reforms?.length > 0 && (
            <div className="filter-info__reforms">
              {reduxInspection.config.reforms.length > 1 &&
                reduxInspection.config.reforms.length + ' reformas:'}
              {reduxInspection.config.reforms.map(reform => (
                <div key={reform.label}>{reform.label}</div>
              ))}
            </div>
          )}
          {reduxInspection.config?.prelicense?.value != null && (
            <div className="filter-info__inspection-detail">
              {reduxInspection.config.prelicense.value}
            </div>
          )}
        </>
      )}
      {!reduxInspection.isFiltered && (
        <div className="filter-info__off">
          <span>FILTRO DESACTIVADO</span>
          <button
            className="action-btn"
            onClick={() => dispatch(setReduxInspectionIsFiltered(true))}
          >
            Activar
          </button>
        </div>
      )}
    </div>
  )
}
