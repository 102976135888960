//@ts-check
import React, { useRef } from 'react'
import ChatbotHeader from './ChatbotHeader/ChatbotHeader'
import './Chatbot.scss'
// import ChatsHistory from './ChatsHistory/ChatsHistory'
// import useWindowDimensions from '../../../hooks/useWindowDimensions'
// import Drawer from '../../Share/Drawer/Drawer'
import useWindowDimensions from 'hooks/useWindowDimensions'
import useMintAIChatbot from '../../../hooks/useMintAIChatbot'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import ChatbotFooter from './ChatbotFooter/ChatbotFooter'
import ChatbotBody from './ChatbotBody/ChatbotBody'
import ChatbotMain from './ChatbotMain/ChatbotMain'

/**
 *
 * @param {{closeModal: Function}} param0
 * @returns
 */
export default function Chatbot({ closeModal }) {
  const drawerRef = useRef()
  const chatbotRef = useRef()
  const chatbot = useMintAIChatbot()
  const { width } = useWindowDimensions()
  const isMobile = false
  console.log({ chatbot })

  // const ChatsHistoryComponent = ({ onDrawer = false }) => (
  //   <ChatsHistory
  //     chatbot={chatbot}
  //     returnToChatbots={() => navigate('/chatbots')}
  //     onDrawer={onDrawer}
  //     subscriptionStatus={subscriptionStatus}
  //   />
  // )

  if (chatbot.loadingChatbot)
    return (
      <div className="chatbot">
        <ChildSpinner text="Cargando MintAI..." />
      </div>
    )
  if (chatbot.data == null) {
    return (
      <div className={isMobile ? 'chatbot--mobile' : 'chatbot'}>
        <ChatbotMain chatbot={chatbot} />
      </div>
    )
  }
  return (
    <>
      {/* <Drawer ref={drawerRef}>
        <ChatsHistoryComponent onDrawer={isMobile} />
      </Drawer> */}
      <div className={isMobile ? 'chatbot--mobile' : 'chatbot'} ref={chatbotRef}>
        {/* {!isMobile && <ChatsHistoryComponent />} */}
        <div className="chatbot__current-chat">
          <ChatbotHeader
            chatbot={chatbot}
            isMobile={isMobile}
            openDrawer={drawerRef?.current?.open}
          />
          <ChatbotBody chatbot={chatbot} />

          <ChatbotFooter chatbot={chatbot} isMobile={isMobile} />
        </div>
      </div>
    </>
  )
}
