import React from 'react'
import ChildSpinner from '../Spinner/ChildSpinner'
import './BigButton.scss'

export default function BigButton({
  text,
  onClick,
  disabled = false,
  selectable = false,
  selected = false,
  transparent = true,
  style = {},
  icon = null,
  type = 'button',
  loading = false,
  className: classNameProp = '',
  deleteIcon = false,
  warningColor = false,
  ...rest
}) {
  const getClassName = () => {
    let className = 'big-button'
    if (transparent) className += ' big-button--transparent'
    if (selectable) className += ' big-button--selectable'
    if (selected) className += ' big-button--selected'
    if (warningColor) className += ' big-button--warning'
    if (classNameProp) className += ' ' + classNameProp
    return className
  }
  try {
    return (
      <button
        onClick={!loading && onClick ? onClick : () => {}}
        disabled={disabled}
        type={type}
        className={getClassName()}
        {...rest}
        style={{ pointerEvents: loading ? 'none' : 'auto', cursor: loading ? 'wait' : 'pointer' }}
      >
        {!loading && (
          <>
            {(deleteIcon || icon) && (
              <div className="big-button__icon">
                {deleteIcon ? <i className="flaticon-trash" /> : icon}
              </div>
            )}
            <div>{text}</div>
          </>
        )}
        {loading && <ChildSpinner height="0.7rem" color="#333" style={{ padding: '0.32rem 0' }} />}
      </button>
    )
  } catch (err) {
    console.error(err)
    return <></>
  }
}
