//@ts-check

/**
 * @typedef {import('types/HardwareConfig').HardwareConfig} HardwareConfig
 * @typedef {import('types/HardwareConfig').Machine} Machine
 * @typedef {import('types/HardwareStatus').hardwareStatus} HardwareStatus
 * @typedef {import('types/Inspection').MachineApplying} MachineApplying
 */
export default class HardwareHandler {
  /**
   * Si no recibe testNames no inicializa hardware y solo almacena en memoria hardwareConfig
   * Si se le pasa vehicleFuel1, automáticamente filtra de hardwareConfig los hardware no válidos para ese combustible
   * @param {{hardwareConfig: HardwareConfig[], machinesApplying: MachineApplying[], manual: boolean, testNames?: string[], vehicleFuel1?: string}} param0
   */
  constructor({ hardwareConfig, machinesApplying, testNames, manual, vehicleFuel1 }) {
    this.hardwareConfig = hardwareConfig
    if (vehicleFuel1) this.hardwareConfig = this.filterByFuel(vehicleFuel1)
    const foundHardware = this.findHardwareByNames(testNames, manual)
    this.hardware = foundHardware
    this.machinesApplying = machinesApplying
  }

  /**
   *
   * @param {string[]} names
   * @param {boolean} manual
   * @returns {HardwareConfig | null}
   */
  findHardwareByNames(names, manual) {
    if (names == null || !names.length || manual == null) return null
    return (
      this.hardwareConfig?.find(
        hc =>
          hc.manual === manual && JSON.stringify(hc.name.sort()) === JSON.stringify(names.sort())
      ) || null
    )
  }

  /**
   *
   * @param {string} internalId
   * @param {HardwareStatus[]} hardwareStatus
   * @returns {HardwareStatus | null}
   */
  findStatus(internalId, hardwareStatus = []) {
    try {
      if (!internalId) throw new Error('No internalId provided')

      return hardwareStatus?.find(status => status.internalId === internalId) || null
    } catch (err) {
      console.error(err)
      return null
    }
  }

  /**
   *@param {boolean=} listOnlyMachinesApplying
   * @returns {{manual: HardwareConfig[], automatic: HardwareConfig[]}}
   */
  getHardwareConfigByManualOrAutomatic(listOnlyMachinesApplying = false) {
    try {
      const hardwareApplying = listOnlyMachinesApplying
        ? this.hardwareConfig?.filter(hc => {
            if (hc.name.some(name => this.machinesApplying.some(machine => machine.name === name)))
              return true
          })
        : this.hardwareConfig
      let automatic = hardwareApplying?.filter(hc => 'manual' in hc && !hc.manual) || []
      let manual = hardwareApplying?.filter(hc => 'manual' in hc && hc.manual) || []

      return { manual, automatic }
    } catch (err) {
      return { manual: [], automatic: [] }
    }
  }

  /**
   *
   * @returns {string[]} Posibles lineas
   */
  getPossibleLines() {
    const lines = []
    this.hardware?.machines.forEach(machine => machine.line && lines.push(machine.line))
    return lines
  }

  /**
   * @returns {{[key:string]: Machine[]} | null}
   */
  getMachinesByLine(filterIdle = true) {
    try {
      let machinesByLine = {}
      this.hardware?.machines.forEach(machine => {
        if (!filterIdle || (filterIdle && machine.active)) {
          machinesByLine[machine.line] = [...(machinesByLine?.[machine.line] || []), machine]
        }
      })
      if (!Object.keys(machinesByLine).length) return null
      return machinesByLine
    } catch (err) {
      console.error(err)
      return null
    }
  }

  filterByFuel(fuel) {
    try {
      if (!fuel) return this.hardwareConfig
      if (fuel.includes('DIÉSEL'))
        return this.hardwareConfig?.filter(hc => !hc.name.includes('analizador de gases'))
      else if (fuel.includes('GASOLINA'))
        return this.hardwareConfig?.filter(hc => !hc.name.includes('opacímetro'))
      else
        return this.hardwareConfig?.filter(
          hc => !hc.name.some(name => ['analizador de gases', 'opacímetro'].includes(name))
        )
    } catch (err) {
      console.error(err)
      return this.hardwareConfig
    }
  }

  /**
   * Busca un machineId (el proporcionado por el supervisor) entre todo el hardwareConfig, no solo en el hardware encontrado en el constructor
   * @param {{machineId: string, testName: string}} param0
   * @returns {Machine | null}
   */
  findMachineByMachineId({ machineId, testName }) {
    try {
      const foundHardware = this.hardwareConfig?.find(hc =>
        hc.machines?.find(
          machine =>
            machine.id &&
            Object.entries(machine.id).find(([name, id]) => name === testName && id === machineId)
        )
      )
      if (foundHardware) {
        const foundMachine = foundHardware.machines.find(
          machine =>
            machine.id &&
            Object.entries(machine.id).find(([name, id]) => name === testName && id === machineId)
        )
        if (foundMachine) return foundMachine
      }
      return null
    } catch (err) {
      console.error(err)
      return null
    }
  }

  /**
   * Busca una máquina por su internalId
   * @param {string} internalId
   * @param {string[]} testNames
   * @param {boolean=} manual
   * @returns {Machine | null}
   */
  findMachineByInternalId(internalId, testNames, manual = false) {
    try {
      if (!internalId || !testNames?.length) throw new Error('No internalId or testNames provided')
      const foundHardware = this.findHardwareByNames(testNames, manual)
      if (!foundHardware) return null
      if (foundHardware) {
        const foundMachine = foundHardware.machines.find(
          machine => machine.internalId === internalId
        )

        return foundMachine ?? null
      }
    } catch (err) {
      console.error(err)
      return null
    }
  }

  /**
   *
   * @param {string} line
   * @returns {Machine}
   */
  findMachineByLine(line) {
    return this.hardware?.machines.find(machine => machine.line === line) || null
  }
}
