import React, { useState, useEffect } from 'react'

// MY SERVICES
import { getReport as getReportService } from 'services/Reports'

// MY IMPORTS
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import openPDF from 'myMethods/openPDF'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import PDFViewer from 'components/Others/PDFViewer/PDFViewer'
import easyToast from 'components/Others/EasyToast/easyToast'
import { useHistory } from 'react-router-dom'
import Id from 'Share/Id'
import { useDispatch } from 'react-redux'
import { resetInspectionDuck } from 'redux/ducks/inspectionDuck'
import useService from 'hooks/useService'
import Modal from 'components/Others/Modal/Modal'

export default function InspectionHistoryModal({ data, closeModal }) {
  const [showPDF, setShowPDF] = useState(false)
  const [pdf, setPdf] = useState(null)
  const history = useHistory()
  const dispatch = useDispatch()

  const { fetch: getReport, loading } = useService({
    service: getReportService,
  })

  const handleDetailedViewClick = () => {
    try {
      window.sessionStorage.setItem('historyId', Id.encrypt(data.idHistorial))
      window.sessionStorage.setItem('license', data.vehicleData.license)
      window.sessionStorage.setItem('inspectionHistory', true)
      dispatch(resetInspectionDuck())
      history.replace('/detailed-history')
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error abriendo la vista detallada')
    }
  }

  useEffect(() => {
    if (data?.idHistorial) {
      getReport(data.idHistorial)
        .then(res => {
          if (res && res instanceof Blob) {
            setPdf(URL.createObjectURL(res))
          }
        })
        .catch(err => console.error(err))
    }
  }, [data.idHistorial, getReport])

  return (
    <Modal
      open={true}
      onClose={closeModal}
      title={`Informe Nº ${data?.numero_informe}`}
      hasAcceptButton={false}
      content={
        <>
          <MyButtonsContainer margin="1rem auto 0 auto">
            <MyButton text="Vista detallada" onClick={handleDetailedViewClick} />
            {!loading && <MyButton text="Abrir informe" onClick={() => openPDF(pdf)} />}
            {loading && <MyButton icon={<ChildSpinner />} />}
          </MyButtonsContainer>
          <PDFViewer
            pdf={pdf}
            show={showPDF}
            closable={true}
            onClose={() => setShowPDF(!showPDF)}
            loading={loading}
          />
        </>
      }
    />
  )
}
