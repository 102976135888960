//@ts-check
import React, { useState, useCallback, useEffect } from 'react'
import './Frenometro.scss'
import { useDispatch, useSelector } from 'react-redux'
import {
  setReduxInspectionConfig,
  setReduxInspectionTechWeights,
  setReduxInspectionWeight,
} from 'redux/ducks/inspectionDuck'
import easyToast from 'components/Others/EasyToast/easyToast'
import TestsValues from 'Share/TestsValues'
import FrenometroConfig from './FrenometroConfig'

/**@typedef {import('./WeightModal').weigthModalData} WeightModalData */

export default function Frenometro({ disabled = false }) {
  const initialWeightModalValues = {
    show: false,
    message: '',
    detail: '',
    mom: false,
    momTrailer: false,
    mma: false,
    mmaTrailer: false,
    neededValues: [],
    skippable: false,
    scaleNeeded: false,
  }
  const reduxInspection = useSelector(store => store.inspection)
  const vehicleType = reduxInspection.vehicleType
  const [
    /**
     * @type {WeightModalData}
     */
    config,
    setConfig,
  ] = useState(initialWeightModalValues)
  const [techWeights, setTechWeights] = useState(() => {
    let finalObj = JSON.parse(JSON.stringify(reduxInspection.techWeights))
    if (reduxInspection.techWeights?.mom > 75 && ['normalBike', 'rareBike'].includes(vehicleType)) {
      finalObj.mom = finalObj.mom - 75
    }
    return finalObj
  })
  const hasTrailer = reduxInspection.axles.trailer > 0
  const dispatch = useDispatch()
  const [values, setValues] = useState({
    deactivateParkingBrake:
      reduxInspection?.config?.brakeMeterData?.deactivateParkingBrake ?? false,
  })

  /**
   *
   * @param {boolean} simulateInitial Si se envía a true, simula como si techWeights estuviera vacío (para así mostrar todos los mensajes)
   * @returns
   */
  const getWeightModalParams = useCallback(() => {
    const weightTestValues =
      new TestsValues(reduxInspection.testsValues).getLastTestValues('báscula')?.test?.values ||
      null
    console.log({ hasTrailer, techWeightsIn: techWeights })
    let totalWeight = 0
    if (
      weightTestValues &&
      weightTestValues.length ===
        (reduxInspection.axles.tractor || 0) + (reduxInspection.axles.trailer || 0)
    ) {
      weightTestValues.forEach(elem => {
        if (elem.value) totalWeight += elem.value
      })
    }
    totalWeight && dispatch(setReduxInspectionWeight(totalWeight))
    if (vehicleType === 'light') {
      if (hasTrailer && !totalWeight) {
        return {
          neededValues: [],
          message:
            'Debido a que el vehículo tiene remolque, ha de introducir MOM del vehículo tractor y remolque. Si no tiene estos datos, o si el vehículo supera la MOM de alguno de ellos, debe pesar el conjunto en báscula',
          detail: 'Si va a pesar en el frenómetro, continúe',
          mom: true,
          momTrailer: true,
          scaleNeeded: true,
        }
      } else {
        return {
          neededValues: ['mom'],
          message: 'Introduzca la MOM del vehículo para calcular eficacias de frenado',
          mom: true,
        }
      }
    } else if (vehicleType === 'heavy') {
      if (!reduxInspection?.config?.isSemiTrailer) {
        if (!totalWeight) {
          return {
            neededValues: ['mma'],
            message:
              'Introduzca la MMA del vehículo para calcular la eficacia del freno de servicio. El vehículo ha de pesarse en báscula para calcular la eficacias del freno de estacionamiento',
            detail: 'Si va a pesar en el frenómetro, solo añada MMA y continúe',
            mma: true,
          }
        } else {
          return {
            neededValues: ['mma'],
            message: 'Introduzca la MMA del vehículo para calcular eficacias de frenado',
            mma: true,
          }
        }
      } else {
        if (!totalWeight) {
          return {
            neededValues: ['mmaByAxles'],
            scaleNeeded: true,
            message:
              'Introduzca la MMA por ejes del vehículo para calcular la eficacia del freno de servicio. El vehículo ha de pesarse en báscula para calcular la eficacia del freno de estacionamiento',
            detail: 'Si va a pesar en el frenómetro, solo añada MMA por ejes y continúe',
            mmaByAxles: true,
          }
        } else {
          console.log('entro 2')
          return {
            neededValues: ['mmaByAxles'],
            message:
              'Introduzca la MMA por ejes del vehículo para calcular la eficacia del freno de servicio',
            mmaByAxles: true,
          }
        }
      }
    } else if (vehicleType === 'normalBike') {
      return setConfig({
        neededValues: ['mom'],
        show: true,
        message: 'Introduzca la MOM / Tara del vehículo para calcular eficacias de frenado',
        detail: 'MOM + 75kg calculado automáticamente',
        mom: true,
      })
    } else if (vehicleType === 'rareBike') {
      if (!hasTrailer)
        return {
          neededValues: ['mom', 'mma'],
          message:
            'Introduzca la MOM / Tara y la MMA del vehículo para calcular eficacias de frenado',
          detail: 'MOM + 75kg calculado automáticamente',
          mom: true,
          mma: true,
        }
      else {
        return {
          neededValues: ['mom', 'mma'],
          message:
            'Introduzca la MOM del vehículo, la MMA del vehículo y la MMA del remolque para calcular eficacias de frenado',
          detail: 'MOM + 75kg calculado automáticamente',
          mom: true,
          mma: true,
          mmaTrailer: true,
        }
      }
    }
    return null
  }, [
    dispatch,
    hasTrailer,
    reduxInspection.axles.tractor,
    reduxInspection.axles.trailer,
    reduxInspection?.config?.isSemiTrailer,
    reduxInspection.testsValues,
    techWeights,
    vehicleType,
  ])

  const setConfigIsSemiTrailer = async isSemiTrailer => {
    try {
      dispatch(setReduxInspectionConfig({ ...reduxInspection.config, isSemiTrailer }))
      dispatch(setReduxInspectionTechWeights(null))
      setTechWeights(null)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error registrando el semiremolque, inténtelo de nuevo')
    }
  }

  useEffect(() => {
    dispatch(setReduxInspectionTechWeights(techWeights))
  }, [dispatch, techWeights])

  useEffect(() => {
    const newConfig = { ...(reduxInspection?.config || {}), brakeMeterData: values }
    dispatch(setReduxInspectionConfig(newConfig))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values])

  // GESTIONA LA EXISTENCIA DE PESOS
  useEffect(() => {
    const weightParams = getWeightModalParams()
    console.log({ weightParams })
    if (weightParams)
      setConfig({
        show: true,
        ...weightParams,
      })
  }, [getWeightModalParams, reduxInspection.config?.isSemiTrailer])
  console.log({ config })
  if (!config.show) return <></>
  return (
    <FrenometroConfig
      data={config}
      techWeights={techWeights}
      setTechWeights={newTechWeights => setTechWeights(newTechWeights)}
      values={values}
      setValues={setValues}
      filterName={reduxInspection?.filter?.name}
      isSemiTrailer={reduxInspection?.config?.isSemiTrailer}
      setIsSemiTrailer={setConfigIsSemiTrailer}
      tractorAxles={reduxInspection?.axles?.tractor}
      vehicleType={vehicleType}
      disabled={disabled}
    />
  )
}
