//@ts-check
import React, { useState } from 'react'
import Modal from 'components/Others/Modal/Modal'
import Input from 'components/Others/Input/Input'
import useService from 'hooks/useService'
import { getITVCardPDF } from 'services/ITVCard'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import openPDF from 'myMethods/openPDF'
import easyToast from 'components/Others/EasyToast/easyToast'

/**
 *
 * @param {{onClose: function}} param0
 * @returns
 */
export default function AdditionalSheetPrinter({ onClose }) {
  const [certificate, setCertificate] = useState('')

  const { fetch: fetchAdditionalSheet, loading } = useService({
    service: getITVCardPDF,
  })

  const generate = async () => {
    try {
      if (!certificate)
        return easyToast(
          'error',
          'Introduzca el  número de certificado de la tarjeta ITV precedente'
        )
      const pdf = await fetchAdditionalSheet({
        values: { certificado: certificate },
        generateITVCard: false,
      })
      if (!pdf) throw new Error('No pdf returned')
      openPDF(pdf, 'Ficha adicional ' + certificate)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <Modal
      show={true}
      title="Generación de hoja adicional"
      onClose={onClose}
      hasAcceptButton={false}
      content={
        <div className="additional-sheet-printer">
          <Input
            isFormikInput={false}
            label="Nº de certificado de la Tarjeta ITV precedente"
            value={certificate}
            onChange={e => setCertificate(e.target.value)}
          />
          <MyButtonsContainer>
            <MyButton
              icon={<i className="flaticon-copy-two-paper-sheets-interface-symbol" />}
              text="Generar"
              onClick={generate}
              loading={loading}
            />
          </MyButtonsContainer>
        </div>
      }
    />
  )
}
