import React, { forwardRef, useState, useImperativeHandle, useMemo } from 'react'
import { Lightbox, useLightboxState, IconButton, createIcon } from 'yet-another-react-lightbox'
import 'yet-another-react-lightbox/styles.css'
import 'yet-another-react-lightbox/plugins/counter.css'
import 'yet-another-react-lightbox/plugins/thumbnails.css'
import 'yet-another-react-lightbox/plugins/captions.css'
import { Download, Counter, Thumbnails, Zoom, Captions } from 'yet-another-react-lightbox/plugins'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import { useDispatch } from 'react-redux'
import TrashIcon from '@mui/icons-material/Delete'
import './ImageLightbox.scss'

/**
 * Gallery component to display a Lightbox of images
 *
 * @param {Object} props - The properties object.
 * @param {Array} props.images - The array of image objects to be displayed in the lightbox.
 * @param {boolean} [props.download=true] - Flag to enable or disable image downloading.
 * @param {boolean} [props.counter=true] - Flag to enable or disable image counter display.
 * @param {boolean} [props.thumbnails=true] - Flag to enable or disable thumbnail previews.
 * @param {boolean} [props.zoom=true] - Flag to enable or disable image zoom functionality.
 * @param {boolean} [props.captions=true] - Flag to enable or disable image captions.
 * @param {boolean} [props.letDelete=true] - Flag to enable or disable image deletion functionality.
 * @param {Function} [props.onDeleteImage] - Callback function to handle image deletion.
 *
 * @returns {JSX.Element} The Lightbox component.
 */
const ImageLightbox = forwardRef(
  (
    {
      images,
      download = true,
      counter = true,
      thumbnails = true,
      zoom = true,
      captions = true,
      letDelete = true,
      onDeleteImage,
    },
    ref
  ) => {
    const [index, setIndex] = useState(-1)
    const dispatch = useDispatch()

    useImperativeHandle(ref, () => ({
      setIndex(number) {
        return setIndex(number ?? 0)
      },
    }))

    const slides = useMemo(
      () =>
        images.map(image => ({
          src: image.src,
          width: image.width,
          height: image.height,
          download: { url: image.src, filename: image.downloadFilename },
          title: image.caption,
        })),
      [images]
    )

    const DeleteIcon = createIcon('delete-icon', <TrashIcon />)

    const DeleteButton = () => {
      const { currentIndex } = useLightboxState()
      return (
        <div className="gallery__delete-button">
          <IconButton
            label="Eliminar"
            icon={DeleteIcon}
            onClick={() => {
              setIndex(-1)
              dispatch(
                setReduxConfigModal({
                  title: '¿Eliminar imagen?',
                  onAccept: async () => await onDeleteImage?.(currentIndex),
                  onCloseButton: () => {
                    setIndex(currentIndex)
                    dispatch(setReduxConfigModal(null))
                  },
                })
              )
            }}
          />
        </div>
      )
    }

    const buttons = useMemo(() => {
      let btns = []
      if (letDelete) btns.push(<DeleteButton key="delete-button" />)
      if (zoom) btns.push('zoom')
      if (download) btns.push('download')
      btns.push('close')
      return btns
    }, [download, letDelete, zoom])

    const plugins = useMemo(() => {
      const plug = []
      if (download) plug.push(Download)
      if (counter) plug.push(Counter)
      if (thumbnails) plug.push(Thumbnails)
      if (zoom) plug.push(Zoom)
      if (captions) plug.push(Captions)
      return plug
    }, [captions, counter, download, thumbnails, zoom])

    return (
      <Lightbox
        className="gallery__lightbox"
        slides={slides}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        plugins={plugins}
        carousel={{
          finite: true,
        }}
        toolbar={{
          buttons,
        }}
      />
    )
  }
)

export default ImageLightbox
