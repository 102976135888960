import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { LocalOffer as DiscountIcon, Close as CloseIcon } from '@mui/icons-material'

// MY IMPORTS
import 'components/Office/Checkout/Fee/Fee.scss'
import {
  setReduxDiscountData,
  setReduxFeeData,
  setReduxFormState,
} from 'redux/ducks/newInspectionDuck'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import formatEuro from 'myMethods/formatEuro'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { getAllDiscounts as getAllDiscountsService } from 'services/Discounts'
import Discounts from '../Discounts/Discounts'
import OfficeFormState from 'components/Others/OfficeFormState/OfficeFormState'
import easyToast from 'components/Others/EasyToast/easyToast'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'
import { getFee as getFeeService } from 'services/Fee'
import useService from 'hooks/useService'

const FeeAmount = ({ name, amount }) => (
  <div className="fee__amount">
    <span>{name}</span>
    <b>{formatEuro(amount)}</b>
  </div>
)

export default function Fee({ redux }) {
  const { vehicleData, feeData: breakdown } = redux.newVehicle
  const appliedDiscountData = redux.newVehicle.discountData
  const [showDiscountsModal, setShowDiscountsModal] = useState(false)
  const dispatch = useDispatch()
  const [discounts, setDiscounts] = useState([])

  const { fetch: getAllDiscounts, loading: loadingDiscounts } = useService({
    service: getAllDiscountsService,
  })

  const { fetch: getFee, loading: loadingFee } = useService({
    service: getFeeService,
  })

  const cancelDiscount = async () => {
    dispatch(setReduxDiscountData(null))
    await getBreakdown()
    easyToast('dark', 'Descuento cancelado')
  }

  const getBreakdown = useCallback(
    async discountId => {
      try {
        const response = await getFee({
          category: vehicleData.category,
          classification: vehicleData.classification,
          fuel: vehicleData.fuel1,
          licenseDate: vehicleData.licenseDate,
          discountId: discountId,
          inspectionType: redux.newVehicle.inspectionData.inspectionType,
        })
        if (!response?.breakdown) throw new Error('No breakdown responsed')
        if (discountId != null && !response.discount && response.error) {
          easyToast('error', response.error)
        }
        if (discountId != null) {
          dispatch(setReduxDiscountData(response.discount))
          easyToast('dark', 'Descuento aplicado')
        }
        dispatch(setReduxFeeData(response.breakdown))
      } catch (err) {
        console.error(err)
        easyToast('error', 'Ha ocurrido un error calculando la tarifa')
      }
    },
    [
      dispatch,
      getFee,
      redux?.newVehicle?.inspectionData?.inspectionType,
      vehicleData?.category,
      vehicleData?.classification,
      vehicleData?.fuel1,
      vehicleData?.licenseDate,
    ]
  )

  useEffect(() => {
    const abortController = new AbortController()
    if (!breakdown) {
      getBreakdown()
    }
    return () => abortController.abort()
  }, [breakdown, getBreakdown])

  useEffect(() => {
    if (!discounts?.length) {
      getAllDiscounts().then(res => {
        if (res.discounts) {
          setDiscounts(res.discounts)
        }
      })
    }
  }, [discounts, getAllDiscounts])

  return (
    <>
      {loadingFee && (
        <div className="fee">
          <ChildSpinner visible={true} tight={false} />
        </div>
      )}
      {!loadingFee && (
        <div className="fee">
          <OfficeFormState
            onClick={() => {
              dispatch(setReduxFormState(2))
            }}
          />
          <h3>TARIFA</h3>
          {!appliedDiscountData?.discount && (
            <MyButtonsContainer>
              <MyButton
                text={`Promociones${discounts?.length ? ' (' + discounts.length + ')' : ''}`}
                loading={loadingDiscounts}
                icon={<DiscountIcon />}
                onClick={() => setShowDiscountsModal(true)}
              />
            </MyButtonsContainer>
          )}
          {appliedDiscountData && (
            <div className="fee__discount">
              <span>DESCUENTO APLICADO</span>
              <div>
                {appliedDiscountData.name || ''}{' '}
                <b>
                  (
                  {appliedDiscountData.method === '%'
                    ? appliedDiscountData.amount + '%'
                    : formatEuro(appliedDiscountData.amount)}
                  )
                </b>
              </div>
              <div className="fee__delete-discount" onClick={cancelDiscount}>
                <HoverTooltip title="Cancelar descuento">
                  <CloseIcon />
                </HoverTooltip>
              </div>
            </div>
          )}
          <section>
            <div className="fee__reminder">
              <h4>VERIFIQUE</h4>
              <div className="fee__reminder-card">
                <span>Tipo de inspección</span>
                <div>{redux?.newVehicle?.inspectionData?.inspectionType || '-'}</div>
              </div>
              <div className="fee__reminder-card">
                <span>Matrícula</span>
                <div>{vehicleData.license || '-'}</div>
              </div>

              <div className="fee__reminder-card">
                <span>Categoría</span>
                <div>{vehicleData.category || '-'}</div>
              </div>

              <div className="fee__reminder-card">
                <span>Clasificación</span>
                <div>{vehicleData.classification || '-'}</div>
              </div>

              <div className="fee__reminder-card">
                <span>Combustible</span>
                <div>{vehicleData.fuel1 || '-'}</div>
              </div>

              <div className="fee__reminder-card">
                <span>Fecha de matriculación</span>
                <div>{moment(vehicleData.licenseDate).format('DD/MM/YYYY') || '-'}</div>
              </div>
            </div>
            <div className="fee__info">
              <div className="fee__amounts-container">
                <FeeAmount name="Inspección de seguridad" amount={breakdown?.inspection} />
                <FeeAmount name="Emisiones" amount={breakdown?.emissions} />
                <FeeAmount name="Tráfico" amount={breakdown?.traffic} />
                <FeeAmount name="Otras tasas" amount={breakdown?.otherTaxes} />
                <div className="fee__separator"></div>
                {breakdown?.discount > 0 && (
                  <FeeAmount name="Descuento" amount={(breakdown?.discount || 0) * -1} />
                )}
                <FeeAmount
                  name={`IVA ${
                    breakdown?.IVA ? '(' + breakdown.IVA.toString().replace('.', ',') + ')%' : ''
                  }`}
                  amount={breakdown?.totalIVA}
                />
                <FeeAmount name="Total tasas" amount={breakdown?.totalTaxes} />
              </div>
              <div className="fee__final">
                <div className="fee__total">
                  <span>TOTAL</span>
                  <b>{formatEuro(breakdown?.total)}</b>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      {showDiscountsModal && (
        <Discounts
          discounts={discounts}
          applyDiscount={discountId => getBreakdown(discountId)}
          closeModal={() => setShowDiscountsModal(false)}
        />
      )}
    </>
  )
}
