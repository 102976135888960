import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import handleUnauthorizedResponse from 'myMethods/handleUnauthorizedResponse'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export function getStatisticsBilling(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}statistics/invoice`, {
    ...getJWT(),
    signal: options.signal,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      throw err
    })
}

export function getStatisticsBillingByDateRange(startDate, endDate, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}statistics/invoice`, {
    ...getJWT(),
    params: { startDate, endDate },
    signal: options.signal,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      throw err
    })
}

export function getStatisticsBillingByClient(startDate, endDate, dni, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}statistics/client`, {
    ...getJWT(),
    params: { startDate, endDate, dni },
    signal: options.signal,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      throw err
    })
}

export function getStatisticsTopClients(startDate, endDate, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}statistics/client`, {
    ...getJWT(),
    params: { startDate, endDate },
    signal: options.signal,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      throw err
    })
}

export function getStatisticsClientsByDateRange(startDate, endDate, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}statistics/client`, {
    ...getJWT(),
    params: { startDate, endDate },
    signal: options.signal,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      throw err
    })
}

export function getStatisticsInspections(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}statistics/inspection`, {
    ...getJWT(),
    signal: options.signal,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      throw err
    })
}

export function getStatisticsTimesByDateRange(startDate, endDate, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}statistics/times`, {
    ...getJWT(),
    params: { startDate, endDate },
    signal: options.signal,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      throw err
    })
}

export function getStatisticsInspectionByDateRange(startDate, endDate, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}statistics/inspection`, {
    ...getJWT(),
    params: { startDate, endDate },
    signal: options.signal,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      throw err
    })
}

export function getStatisticsDefectsByDateRange(startDate, endDate, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}statistics/defects`, {
    ...getJWT(),
    params: { startDate, endDate },
    signal: options.signal,
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      throw err
    })
}
