//@ts-check
import React, { useRef } from 'react'
import { isMobile } from 'react-device-detect'
import easyToast from '../EasyToast/easyToast'
import { postFaceIDVerify } from 'services/FaceID'
import useService from 'hooks/useService'
import LottieAnimation from '../LottieAnimation/LottieAnimation'
import FaceIdAnimationData from 'lottie/face-id.json'
import './FaceID.scss'
import isValidFunction from 'myMethods/isValidFunction'

/**
 *
 * @param {{userId: string, className?: string, children: any, onSuccess: function}} param0
 * @returns
 */
export default function FaceID({ userId, className, children, onSuccess }) {
  const inputRef = useRef()
  const { fetch: putVerifyIdentity, loading } = useService({
    service: postFaceIDVerify,
    fullResponse: true,
    notThrowWhenError: true,
  })

  const handleClick = () => {
    // if (!isMobile) {
    //   return easyToast('error', 'Para verificar tu identidad debes utilizar un dispositivo móvil')
    // }
    inputRef.current.click()
  }

  const submitInput = async e => {
    try {
      const image = e.target.files[0]
      if (!image) throw new Error('Image not captured')
      let fd = new FormData()
      fd.append('image', image)
      fd.append('id', userId)
      const res = await putVerifyIdentity(fd)
      console.log({ res })
      if (res.status === 200) {
        easyToast('dark', 'Identidad verificada correctamente')
        if (onSuccess && isValidFunction(onSuccess)) return onSuccess()
      } else if (res.status === 404) {
        return easyToast(
          'error',
          'No parece que seas tú, inténtalo de nuevo desde un lugar bien iluminado'
        )
      }
      throw new Error(res.statusText)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error al verificar tu identidad, inténtalo de nuevo')
    }
  }

  return (
    <div className={className ? 'face-id ' + className : 'face-id'}>
      {loading && (
        <LottieAnimation
          className="face-id__lottie"
          animationData={FaceIdAnimationData}
          fullWindow={true}
        />
      )}
      {React.cloneElement(children, { onClick: handleClick })}
      <input
        ref={inputRef}
        className="capture-image__input-image"
        accept="image/*"
        id="file-input"
        type="file"
        capture="camera"
        onChange={submitInput}
        style={{ display: 'none' }}
      />
    </div>
  )
}
