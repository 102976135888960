//@ts-check
import { useCallback, useState, useRef, useEffect } from 'react'
import easyToast from 'components/Others/EasyToast/easyToast'
import isValidFunction from 'myMethods/isValidFunction'
import areIdenticalObjects from 'myMethods/areIdenticalObjects'
import handleUnauthorizedResponse from 'myMethods/handleUnauthorizedResponse'
import axios from '../../node_modules/axios/index'

/**
 * @typedef {import('types/FilterServiceParams').FilterServiceParams} FilterServiceParams
 */

/**
 * @param {{service: function, forcedFilterValues?: FilterServiceParams, defaultLimit?: number, setOutLoading?: function}} param0
 *
 * @returns {{data: any, total: number, loading: boolean, currentFilterValues: FilterServiceParams | null, initialized: boolean, status: number | null, errorMessage: string | null, fetch: (next?: number, filterValues?: FilterServiceParams, limit?: number) => Promise<void>, fetchNextChunk: (limit?: number) => Promise<void>, clearFilter: () => void, clearFilter: function}}
 */
export default function useServiceByChunk({
  service,
  forcedFilterValues = null,
  defaultLimit = 3000,
  setOutLoading,
}) {
  const [currentFilterValues, setCurrentFilterValues] = useState(null)
  const [state, setState] = useState({
    serviceRes: {
      data: null,
      total: 0,
      filteredTotal: null,
      next: null,
    },
    status: null,
    errorMessage: null,
    loading: false,
    initialized: false,
  })
  const controllerRef = useRef()
  useEffect(() => {
    controllerRef.current = new AbortController()
    return () => {
      if (state.loading) {
        controllerRef?.current?.abort()
      }
    }
  }, [state.loading])

  const fetch = useCallback(
    async (next, filterValues, limit) => {
      controllerRef.current.abort() // Abort any ongoing request
      controllerRef.current = new AbortController() // Create a new controller for the new request

      try {
        if (!isValidFunction(service))
          throw new Error('Service is not a valid function: ' + service)

        setState(prevState => ({ ...prevState, loading: true }))
        if (isValidFunction(setOutLoading)) setOutLoading(true)
        console.log('Antes del serviceByChunk')
        console.log({ filterValues: { ...(forcedFilterValues ?? filterValues) } })
        const res = await service(
          {
            limit: limit ?? defaultLimit,
            offset: next ?? undefined,
            ...(forcedFilterValues ?? filterValues),
          },
          {
            signal: controllerRef.current.signal,
          }
        )
        console.log({ resByChunks: res })
        const data = res.data
        setState(prevState => ({
          ...prevState,
          serviceRes: {
            data: next == null ? data?.data ?? null : [...prevState.serviceRes.data, ...data.data],
            total: data.total,
            filteredTotal: forcedFilterValues || filterValues ? data.totalFiltered : null,
            next: data.next,
          },
          status: res.status,
          errorMessage: null,
          initialized: true,
        }))

        if (data.request) {
          delete data.request.limit
          delete data.request.offset
          if (data.request.licenseDate?.start) {
            data.request.licenseDateStart = data.request.licenseDate.start
          }
          if (data.request.licenseDate?.end) {
            data.request.licenseDateEnd = data.request.licenseDate.end
          }
          // if (!areIdenticalObjects(data.request, currentFilterValues ?? {})) {
          //   setCurrentFilterValues(Object.keys(data.request).length ? data.request : null)
          // }
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log('Fetch aborted')
        } else {
          console.error(err)
          easyToast('error', 'Ha ocurrido un error cargando los datos')
          setState(prevState => ({
            ...prevState,
            res: null,
            status: err.response?.status ?? null,
            errorMessage: err.response?.message ?? null,
            initialized: true,
          }))
          if (err.response?.status === 401) {
            handleUnauthorizedResponse()
          }
        }
      } finally {
        setState(prevState => ({ ...prevState, loading: false }))
        if (isValidFunction(setOutLoading)) setOutLoading(false)
      }
    },
    [service, setOutLoading, defaultLimit, forcedFilterValues, currentFilterValues]
  )

  const fetchNextChunk = useCallback(async () => {
    await fetch(state.serviceRes.next, currentFilterValues)
  }, [currentFilterValues, fetch, state.serviceRes.next])

  const clearFilter = useCallback(() => {
    setCurrentFilterValues(null)
    fetch(null, null)
  }, [fetch])
  return {
    data: state.serviceRes?.data ?? null,
    total: state.serviceRes?.filteredTotal ?? state.serviceRes?.total ?? 0,
    loading: state.loading,
    currentFilterValues,
    initialized: state.initialized,
    status: state.status,
    errorMessage: state.errorMessage,
    fetch,
    fetchNextChunk,
    clearFilter,
  }
}
