import React from 'react'
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@mui/material'
import './RadioInputs.scss'
import isValidFunction from 'myMethods/isValidFunction'
/**
 *
 * @param {{defaultValueName: string | undefined, radios: {label: string, value: string}[]}} param0
 * @returns
 */
export default function RadioInputs({
  className,
  label,
  defaultValueName,
  onChange,
  radios,
  value,
  ...rest
}) {
  if (!radios?.length) return <></>
  return (
    <div className={`radio-inputs${className ? ' ' + className : ''}`}>
      <FormControl>
        <FormLabel>{label}</FormLabel>
        <RadioGroup
          value={value}
          defaultValue={defaultValueName}
          onChange={e => isValidFunction(onChange) && onChange(e.target.value)}
          {...rest}
        >
          {radios.map(radio => (
            <FormControlLabel value={radio.value} label={radio.label} control={<Radio />} />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  )
}
