import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import formatEuro from 'myMethods/formatEuro'
import isValidFunction from 'myMethods/isValidFunction'
import round from 'myMethods/round'
import React, { useEffect, useState } from 'react'

// MY IMPORTS
import './CashModal.scss'

export default function CashModal({
  coins: coinsProp = {
    500: 0,
    200: 0,
    100: 0,
    50: 0,
    20: 0,
    10: 0,
    5: 0,
    2: 0,
    1: 0,
    0.5: 0,
    0.2: 0,
    0.1: 0,
    0.02: 0,
    0.01: 0,
  },
  editable = true,
  closeModal,
  onAccept,
}) {
  const [coinsValue, setCoinsValue] = useState(0)
  const [coins, setCoins] = useState(coinsProp)

  useEffect(() => {
    let aux = 0
    Object.entries(coins).forEach(([coin, value]) => {
      if (value && value > 0) aux += coin * value
    })

    setCoinsValue(round(aux))
  }, [coins])

  return (
    <Modal
      open={true}
      onClose={closeModal}
      hasCloseButton={editable}
      title={editable ? 'Modificar efectivo' : 'Efectivo'}
      message={editable ? 'Inserte la cantidad de monedas o billetes de cada tipo' : ''}
      onAccept={() => isValidFunction(onAccept) && onAccept(coins, coinsValue)}
      closeAfterAccept={false}
      content={
        <section className="cash-modal">
          <div className="cash-modal__coins-container">
            {Object.entries(coins)
              ?.sort((a, b) => b[0] - a[0])
              ?.map(([coin, value]) => {
                return (
                  <div className="cash-modal__coin" key={coin}>
                    <div>{coin}€</div>
                    <input
                      type="number"
                      value={value}
                      disabled={!editable}
                      onChange={e => {
                        try {
                          const number = parseInt(e.target.value)
                          if (typeof number !== 'number')
                            return easyToast('error', 'Inserte un valor válido')
                          editable && setCoins({ ...coins, [coin]: number })
                        } catch (err) {
                          console.error(err)
                          easyToast('error', 'Ha ocurrido un error')
                        }
                      }}
                      onFocus={() => {
                        if (!value) setCoins({ ...coins, [coin]: '' })
                      }}
                      onBlur={() => {
                        if (!value) setCoins({ ...coins, [coin]: 0 })
                      }}
                    />
                  </div>
                )
              })}
          </div>
          <div className="cash-modal__total">
            Total efectivo: <span>{formatEuro(coinsValue)}</span>
          </div>
        </section>
      }
    />
  )
}
