import React from 'react'
import './StatisticsTimesAverageCard.scss'

/**
 *
 * @param {{icon: object, text: string, value: string, secondary: boolean}} param0
 */
export default function StatisticsTimesAverageCard({ icon, text, value, secondary = false }) {
  return (
    <div
      className={
        secondary ? 'statistics-times-average-card--secondary' : 'statistics-times-average-card'
      }
    >
      <div className="statistics-times-average-card__icon">{icon}</div>
      <div className="statistics-times-average-card__text">{text}</div>
      <div className="statistics-times-average-card__value">{value}</div>
    </div>
  )
}
