import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setReduxInspectionConfig } from 'redux/ducks/inspectionDuck'
import AlineadorConfig from './AlineadorConfig/AlineadorConfig'

export default function Alineador({ disabled = false }) {
  const [config, setModal] = useState({
    show: false,
  })
  const reduxInspection = useSelector(store => store.inspection)
  const reduxConfig = reduxInspection?.config
  const reduxAlignerData = reduxConfig?.alignerData
  const [values, setValues] = useState({
    axles: null,
  })
  const dispatch = useDispatch()

  useEffect(() => {
    if (values.axles) {
      if (JSON.stringify(values) === JSON.stringify(reduxAlignerData)) return
      const newConfig = { ...(reduxConfig || {}), alignerData: values }
      dispatch(setReduxInspectionConfig(newConfig))
    } else {
      if (reduxAlignerData) {
        setValues(reduxAlignerData)
      } else
        setValues({
          axles: [1],
        })
    }
  }, [dispatch, reduxAlignerData, reduxConfig, reduxInspection.config, values])

  useEffect(() => {
    if (!config.show) {
      setModal({ ...config, show: true })
    }
  }, [config])

  return (
    <>
      {config.show && (
        <AlineadorConfig
          setValues={values => setValues(values)}
          values={values}
          vehicleAxles={reduxInspection?.axles}
          disabled={disabled}
        />
      )}
    </>
  )
}
