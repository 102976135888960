//@ts-check

/**
 * @typedef {import("types/Filter").Filter} Filter
 * @typedef {import("types/Filter").Defect} Defect
 * @typedef {import("types/Filter").VehicleType} VehicleType
 */

export default class Filters {
  /**
   *
   * @param {{filters: Filter[], category?: string, classification?: string, filterName?: string}} param0 Puede recibir category y clasification o filterName
   */
  constructor({ filters, category, classification, filterName }) {
    this.filters = filters
    this.filter = null
    if (category && classification) {
      this.filter = this.#findFilter({ category, classification })
    } else if (!this.filter && filterName) {
      this.filter = this.#findFilterByName(filterName)
    }
  }
  /**
   *
   * @param {{category : string, classification:string}} param0
   * @returns {Filter | null}
   */
  #findFilter({ category, classification }) {
    try {
      return (
        this.filters.find(
          elem =>
            elem.categories.includes(category) &&
            elem.classifications.includes(classification.toString())
        ) ||
        this.filters.find(
          elem =>
            elem.categories.includes(category) &&
            elem.classifications.includes(classification.toString().substring(0, 2))
        ) ||
        null
      )
    } catch (err) {
      console.error(err)
      return null
    }
  }
  /**
   *
   * @param {string} name
   * @returns {Filter | null}
   */
  // @ts-ignore
  #findFilterByName(name) {
    return this.filters.find(filter => filter.name === name) || null
  }

  /**
   *@param {string} onlyBooleans Indica si filtrar solo los defectos opcionales que sean booleanos (excluyendo gasolina, diésel, glp...)
   * @returns {Defect[]}
   */
  getOptionalDefects(onlyBooleans) {
    return this.filter?.defects.filter(defect => {
      if (onlyBooleans && typeof defect.optional === 'boolean' && defect.optional) return true
      else if (!onlyBooleans && defect.optional) return true
      return false
    })
  }

  /**
   * El tipo de vehiculo se utiliza en el frenómetro
   * @returns {VehicleType | null}
   */
  getVehicleType() {
    if (!this.filter?.name) return null
    if (['Turismo', 'Furgoneta'].includes(this.filter.name)) {
      return 'light'
    } else if (['Autobús', 'Camión', 'Tractora', 'Remolque pesado'].includes(this.filter.name)) {
      return 'heavy'
    } else if (['Ciclomotor', 'Motocicleta'].includes(this.filter.name)) {
      return 'normalBike'
    } else if (
      ['Triciclo ligero', 'Triciclo pesado', 'Quad ligero', 'Quad pesado'].includes(
        this.filter.name
      )
    ) {
      return 'rareBike'
    }
    return null
  }

  /**
   * Filtra los defectos incluidos en el filtro según las propiedades del vehículo y los defectos opcionales elegidos por le inspector
   * @param {{vehicleData: any, userOptionalDefects: {[id:string]: boolean}, isFiltered: boolean}} param0
   * @returns
   */
  getAllowableDefects({ vehicleData, userOptionalDefects, isFiltered = false }) {
    try {
      let finalDefects = null
      if (isFiltered) {
        finalDefects = []

        this.filter.defects?.forEach(defect => {
          switch (defect.optional) {
            case false:
              finalDefects.push(defect)
              break
            case true:
              userOptionalDefects &&
                Object.entries(userOptionalDefects).find(
                  ([key, value]) => defect.id === key && value === true
                ) &&
                finalDefects.push(defect)
              break
            case 'gasolina':
              if (vehicleData.fuel1 === 'GASOLINA') finalDefects.push(defect)
              break
            case 'diesel':
              if (vehicleData.fuel1 === 'DIESEL') finalDefects.push(defect)
              break
            case 'glp':
              if (vehicleData?.fuel2 === 'GLP') finalDefects.push(defect)
              break
            default:
              break
          }
        })
        return finalDefects
      } else return this.filter.defects
    } catch (err) {
      console.error(err)
      return []
    }
  }
}
