//@ts-check
import { useCallback, useState, useRef, useEffect } from 'react'
import easyToast from 'components/Others/EasyToast/easyToast'
import isValidFunction from 'myMethods/isValidFunction'
import handleUnauthorizedResponse from 'myMethods/handleUnauthorizedResponse'

/**
 * @template T
 * @param {{service: (...arg0: any[]) => Promise<T>, serviceParams?: any, setOutLoading?: function, handleResponseData?: function, throwMessage?: string, fullResponse?: boolean, notThrowWhenError?: boolean}} param0
 *
 * @returns {{serviceData: T | null, loading: boolean, fetch: (params?: any) => Promise<T>, status: number | undefined, errorMessage: string | undefined, abortStream: function}}
 */
export default function useStreamService({
  service,
  serviceParams,
  setOutLoading,
  throwMessage = '',
}) {
  const [state, setState] = useState({
    loading: false,
    status: null,
    errorMessage: null,
  })
  const controllerRef = useRef(null)

  useEffect(() => {
    controllerRef.current = new AbortController()
    return () => {
      if (state.loading) {
        controllerRef.current.abort()
      }
    }
  }, [state.loading])

  const abortServiceCall = () => {
    controllerRef.current.abort()
    controllerRef.current = new AbortController()
  }

  const fetch = useCallback(
    async ({ params, onNext, onEnd }) => {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          if (!isValidFunction(service))
            throw new Error('Service is not a valid function: ' + service)

          setState(prevState => ({ ...prevState, loading: true }))
          if (setOutLoading && isValidFunction(setOutLoading)) setOutLoading(true)
          const response = await service(params ?? serviceParams, controllerRef.current.signal)
          // @ts-ignore
          if (!response?.ok) throw new Error('Response not ok: ' + response.status)
          // @ts-ignore
          const reader = response.body.getReader()
          const decoder = new TextDecoder('utf-8')

          // eslint-disable-next-line no-constant-condition
          while (true) {
            const result = await reader.read()
            if (result.done) break

            let chunk = decoder.decode(result.value, { stream: true })
            onNext(chunk)
          }
          if (setOutLoading && isValidFunction(setOutLoading)) setOutLoading(false)
          if (onEnd) await onEnd()
          resolve(true)
        } catch (err) {
          if (setOutLoading && isValidFunction(setOutLoading)) setOutLoading(false)
          if (err?.message === 'The user aborted a request.') {
            return resolve(true)
          }
          setState(prevState => ({
            ...prevState,
            res: null,
            status: err.response?.status ?? null,
            errorMessage: err.response?.message ?? null,
          }))
          if (err.response?.status === 401) {
            handleUnauthorizedResponse()
          }
          throwMessage && easyToast('error', throwMessage)

          reject(err)
        }
      })
    },
    [service, setOutLoading, serviceParams, controllerRef, throwMessage]
  )

  return {
    serviceData: state.res,
    loading: state.loading,
    status: state.status,
    errorMessage: state.errorMessage,
    abortStream: abortServiceCall,
    fetch,
  }
}
