//@ts-check
import React, { useCallback, useState } from 'react'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import moment from 'moment'
import formatEuro from 'myMethods/formatEuro'
import { NoteAdd } from '@mui/icons-material'
import './Deals.scss'

// SERVICES
import DealModal from './DealModal/DealModal'
import TablePagination from 'components/Others/MuiTable/TablePagination/TablePagination'
import easyToast from 'components/Others/EasyToast/easyToast'
import TableFilter from 'components/Others/TableFilter/TableFilter'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import DealInvoicePreviewModal from '../BillingExportModal/DealInvoicePreviewModal/DealInvoicePreviewModal'

/**
 * @typedef {import('types/FilterServiceParams').FilterServiceParams} FilterServiceParams
 * @typedef {import('types/FilterServiceParams').FilterServiceOptions} FilterServiceOptions
 */

/**
 *
 * @param {{deals: import('types/Invoice').InvoiceGetAllResDTO[], loading: boolean, total: number,
 * fetchDeals: function, fetchNextChunk: function, fetchInvoices: function, currentFilterValues: FilterServiceParams, onClearFilter: function, showTableFilter: boolean, tableFilterOptions?: FilterServiceOptions, allowGenerateDealInvoice?: boolean, allowExport?: boolean, onTableSelectionChange?: Function }} param0
 * @returns
 */

export default function Deals({
  deals,
  loading,
  total,
  fetchDeals,
  fetchNextChunk,
  fetchInvoices,
  currentFilterValues,
  onClearFilter,
  showTableFilter,
  tableFilterOptions,
  allowGenerateDealInvoice = true,
  allowExport = true,
  onTableSelectionChange,
}) {
  const [showModal, setShowModal] = useState(false)
  const [rowClicked, setRowClicked] = useState(null)
  const [hideInvoiced, setHideInvoiced] = useState(true)
  const [dealInvoiceModal, setDealInvoiceModal] = useState({ show: false, data: null })

  const parseDeals = deals => {
    try {
      let data = []

      for (const deal of deals) {
        const foundIndex = data.findIndex(elem => elem.client.dni === deal.clientDni)
        if (foundIndex !== -1) {
          if (!data[foundIndex].deals.some(elem => elem.invoiceId === deal.invoiceId)) {
            data[foundIndex].deals.push(deal)
          }
        } else {
          data.push({
            client: { dni: deal.clientDni, name: deal.clientName },
            deals: [deal],
          })
        }
      }

      for (const elem of data) {
        elem.deals = elem.deals.map(deal => ({
          report: {
            number: deal.reportNumber,
            inspectionType: deal.inspectionType,
          },
          number: deal.invoiceNumber,
          date: deal.date,
          breakdown: deal.breakdown,
          id: deal.invoiceId,
          client: {
            dni: deal.clientDni,
            name: deal.clientName,
          },
        }))
      }
      return data
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error generando las facturas de fin de mes')
      return []
    }
  }

  return (
    <section>
      <MuiTable
        tableFilter={
          showTableFilter ? (
            <TableFilter
              totalValues={total}
              initialFilterValues={currentFilterValues}
              startDate={true}
              endDate={true}
              likeDni={true}
              likeName={true}
              inUse={true}
              loading={loading}
              onSubmit={filterValues => {
                fetchDeals(null, filterValues)
              }}
              onClear={onClearFilter}
              {...tableFilterOptions}
            />
          ) : null
        }
        filterValues={showTableFilter ? currentFilterValues : null}
        onClearTableFilter={onClearFilter}
        columnsKey="deals"
        values={(hideInvoiced ? deals?.filter(elem => !elem.inUse) : deals) ?? []}
        loading={loading}
        onSelectionChange={onTableSelectionChange}
        onRowClick={rowData => {
          setRowClicked(rowData)
          setShowModal(true)
        }}
        showToolbar={false}
        exportation={
          allowExport
            ? {
                type: 'DEALS',
                data: { values: deals },
                modalTitle: 'Exportar albaranes',
                defaultInputValue: 'ALBARANES',
              }
            : null
        }
        moreActions={
          allowGenerateDealInvoice
            ? [
                {
                  icon: () => <NoteAdd />,
                  onClick: (e, data) => {
                    const deals = parseDeals(data)
                    if (deals?.length > 0) {
                      setDealInvoiceModal({ show: true, data: deals })
                    }
                  },
                  tooltip: 'Generar factura de selección',
                },
              ]
            : []
        }
        toolbarComponent={
          <>
            {currentFilterValues?.inUse == null && (
              <Checkbox
                label="Ocultar facturados"
                checked={hideInvoiced}
                onChange={value => setHideInvoiced(value)}
              />
            )}
            <TablePagination
              currentDataLength={deals?.length}
              totalDataLength={total}
              onClick={fetchNextChunk}
            />
          </>
        }
      />

      {showModal && (
        <DealModal
          invoiceId={rowClicked.invoiceId}
          closeModal={() => setShowModal(false)}
          refetchInvoices={fetchInvoices}
          refetchDeals={fetchDeals}
        />
      )}
      {dealInvoiceModal?.show && (
        <DealInvoicePreviewModal
          data={dealInvoiceModal?.data}
          closeModal={() => setDealInvoiceModal({ show: false, data: null })}
          refetchData={() => {
            fetchDeals()
            fetchInvoices()
          }}
        />
      )}
    </section>
  )
}
