//@ts-check
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// MY IMPORTS
import 'components/Inspection/SelectAxles/SelectAxles.scss'
import {
  putInspectionAxles as putInspectionAxlesService,
  putInspectionConfig as putInspectionConfigService,
} from 'services/Inspection'
import easyToast from 'components/Others/EasyToast/easyToast'
import Id from 'Share/Id'
import Modal from 'components/Others/Modal/Modal'
import useService from 'hooks/useService'
import isValidFunction from 'myMethods/isValidFunction'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Input from 'components/Others/Input/Input'

/**
 *
 * @param {{show: boolean, onClose: function}} param0
 * @returns
 */
export default function SelectAxles({ show, onClose }) {
  const reduxInspection = useSelector(store => store.inspection)
  const [tractorAxles, setTractorAxles] = useState(null)
  const [trailerAxles, setTrailerAxles] = useState(null)
  const [showTrailerAxles, setShowTrailerAxles] = useState(false)
  const dispatch = useDispatch()
  const filterName = reduxInspection.filter?.name
  const [trailerLicense, setTrailerLicense] = useState(
    reduxInspection?.config?.trailerData?.license ?? ''
  )
  const [loading, setLoading] = useState(false)

  const isBike = Boolean(reduxInspection.vehicleData?.category?.substring(0, 1) === 'L')

  const { fetch: putInspectionAxles } = useService({
    service: putInspectionAxlesService,
  })
  const { fetch: putInspectionConfig } = useService({
    service: putInspectionConfigService,
  })

  const toggleIsTrailer = () => {
    setTrailerAxles(null)
    if (showTrailerAxles) setTrailerLicense('')
    setShowTrailerAxles(!showTrailerAxles)
  }
  const handleAccept = async () => {
    try {
      setLoading(true)
      console.log('Entro handleAccept', { tractorAxles, trailerAxles })
      await putInspectionAxles({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        axles: { tractor: tractorAxles, trailer: trailerAxles },
      })
      let newConfig = { ...(reduxInspection?.config ?? {}) }
      if (trailerLicense) {
        newConfig.trailerData = { license: trailerLicense }
      }
      await putInspectionConfig({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        config: newConfig,
      })
      isValidFunction(onClose) && onClose()
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error registrando los ejes del vehículo')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (
      (reduxInspection.axles?.tractor || reduxInspection.axles?.trailer) &&
      !tractorAxles &&
      !trailerAxles
    ) {
      setTractorAxles(reduxInspection.axles?.tractor ?? null)
      setTrailerAxles(reduxInspection.axles?.trailer ?? null)
      if (reduxInspection.axles?.trailer) setShowTrailerAxles(true)
    } else if (isBike && !tractorAxles) {
      setTractorAxles(2)
    }
  }, [
    dispatch,
    tractorAxles,
    trailerAxles,
    isBike,
    reduxInspection.axles?.tractor,
    reduxInspection.axles?.trailer,
  ])

  return (
    <Modal
      open={show}
      onClose={onClose}
      onAccept={handleAccept}
      title="¿CUÁNTOS EJES TIENE EL VEHÍCULO?"
      hasCloseButton={false}
      closeOnOverlay={false}
      closeAfterAccept={false}
      loading={loading}
      content={
        <div className="select-axles">
          {filterName && !['Remolque sin matrícula', 'Remolque ligero'].includes(filterName) && (
            <MyButtonsContainer>
              <MyButton
                text={showTrailerAxles ? 'No tiene remolque' : 'Tiene remolque'}
                onClick={toggleIsTrailer}
                selectable={true}
                selected={showTrailerAxles}
              />
            </MyButtonsContainer>
          )}
          <div className="select-axles__cards-container">
            <div
              className={
                tractorAxles === 2
                  ? 'select-axles__card select-axles__card--selected'
                  : 'select-axles__card'
              }
              onClick={() => setTractorAxles(2)}
            >
              2
            </div>
            {!isBike && (
              <div
                className={
                  tractorAxles === 3
                    ? 'select-axles__card select-axles__card--selected'
                    : 'select-axles__card'
                }
                onClick={() => setTractorAxles(3)}
              >
                3
              </div>
            )}
            {!isBike && (
              <div
                className={
                  tractorAxles === 4
                    ? 'select-axles__card select-axles__card--selected'
                    : 'select-axles__card'
                }
                onClick={() => setTractorAxles(4)}
              >
                4
              </div>
            )}
          </div>
          {showTrailerAxles && (
            <div className="select-axles__trailer-container" data-aos="fade-down">
              <span>EJES DEL REMOLQUE</span>
              <div className="select-axles__cards-container">
                <div
                  className={
                    trailerAxles === 1
                      ? 'select-axles__card select-axles__card--selected'
                      : 'select-axles__card'
                  }
                  onClick={() => setTrailerAxles(1)}
                >
                  1
                </div>
                <div
                  className={
                    trailerAxles === 2
                      ? 'select-axles__card select-axles__card--selected'
                      : 'select-axles__card'
                  }
                  onClick={() => setTrailerAxles(2)}
                >
                  2
                </div>
                <div
                  className={
                    trailerAxles === 3
                      ? 'select-axles__card select-axles__card--selected'
                      : 'select-axles__card'
                  }
                  onClick={() => setTrailerAxles(3)}
                >
                  3
                </div>
              </div>
              {trailerAxles && (
                <Input
                  isFormikInput={false}
                  className="select-axles__trailer-license"
                  label="Matrícula del remolque"
                  value={trailerLicense}
                  onChange={e => setTrailerLicense(e?.target?.value)}
                />
              )}
            </div>
          )}
        </div>
      }
    />
  )
}
