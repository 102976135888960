import isValidFunction from 'myMethods/isValidFunction'
import React from 'react'
import { useDispatch } from 'react-redux'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import Modal from '../Modal/Modal'

export default function GeneralModal({ error = false, ...rest }) {
  const dispatch = useDispatch()
  return (
    <Modal
      open={Boolean(rest.open)}
      icon={error && <i className="flaticon-warning" />}
      title={rest.title || (error ? 'ERROR' : null)}
      hasCloseButton={!error}
      {...rest}
      onClose={async () => {
        dispatch(setReduxConfigModal(null))
        if (rest.onClose && isValidFunction(rest.onClose)) {
          await rest.onClose()
        }
      }}
    />
  )
}
