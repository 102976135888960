import useService from 'hooks/useService'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  setReduxConfigComplementaryEquipment,
  setReduxConfigFees,
  setReduxConfigFilters,
  setReduxConfigHardwareByLine,
  setReduxConfigItv,
  setReduxConfigLinkBetweenMachineAndManual,
} from 'redux/ducks/configDuck'
import {
  getFiltersFile as getFiltersFileService,
  getHardwareByLine as getHardwareByLineService,
  getItvConfigFile as getItvConfigFileService,
  getItvLogo as getItvLogoService,
  getLinkBetweenMachineAndManualFile as getLinkBetweenMachineAndManualFileService,
} from 'services/Files'
import { getComplementaryEquipment as getComplementaryEquipmentService } from 'services/Hardware'

export default function FillReduxConfig({ redux }) {
  const dispatch = useDispatch()

  const { fetch: getItvConfigFile } = useService({
    service: getItvConfigFileService,
  })
  const { fetch: getItvLogo } = useService({
    service: getItvLogoService,
  })
  const { fetch: getFiltersFile } = useService({
    service: getFiltersFileService,
  })

  const { fetch: getHardwareByLine } = useService({
    service: getHardwareByLineService,
  })
  const { fetch: getComplementaryEquipment } = useService({
    service: getComplementaryEquipmentService,
  })
  const { fetch: getLinkBetweenMachineAndManualFile } = useService({
    service: getLinkBetweenMachineAndManualFileService,
  })

  useEffect(() => {
    // ITV file
    if (!redux?.config?.itv?.info) {
      console.log('Entro itv config ')
      getItvConfigFile()
        .then(res => {
          console.log({ res })
          dispatch(setReduxConfigItv({ ...redux.config.itv, ...res }))
        })
        .catch()
    }
  }, [dispatch, getItvConfigFile, redux?.config])

  // ITV logo
  useEffect(() => {
    if (!redux?.config?.itv?.logo) {
      getItvLogo()
        .then(res => dispatch(setReduxConfigItv({ ...redux.config.itv, logo: res })))
        .catch()
    }
  }, [dispatch, getItvLogo, redux?.config?.itv])

  // Inspection Filter
  useEffect(() => {
    if (!redux?.config?.filters?.length) {
      getFiltersFile()
        .then(res => {
          if (res.filters) {
            dispatch(setReduxConfigFilters(res.filters))
          }
        })
        .catch()
    }
  }, [dispatch, getFiltersFile, redux?.config?.filters])

  useEffect(() => {
    if (
      ['INSPECTOR', 'SUPERVISOR'].includes(redux?.user?.data?.role) &&
      redux?.config?.hardwareByLine == null
    ) {
      getHardwareByLine()
        .then(res => {
          if (res.hardwareByLines) {
            dispatch(setReduxConfigHardwareByLine(res.hardwareByLines))
          }
        })
        .catch()
    }
  }, [dispatch, getHardwareByLine, redux?.config?.hardwareByLine, redux?.user?.data?.role])

  useEffect(() => {
    if (
      ['INSPECTOR', 'SUPERVISOR'].includes(redux?.user?.data?.role) &&
      redux?.config?.complementaryEquipment == null
    ) {
      getComplementaryEquipment()
        .then(res => {
          if (res.complementaryEquipments) {
            dispatch(setReduxConfigComplementaryEquipment(res.complementaryEquipments))
          }
        })
        .catch()
    }
  }, [
    dispatch,
    getComplementaryEquipment,
    redux?.config?.complementaryEquipment,
    redux?.user?.data?.role,
  ])

  useEffect(() => {
    if (
      ['INSPECTOR', 'SUPERVISOR'].includes(redux?.user?.data?.role) &&
      redux?.config?.linkBetweenMachineAndManual == null
    ) {
      getLinkBetweenMachineAndManualFile()
        .then(res => {
          if (res != null) {
            dispatch(setReduxConfigLinkBetweenMachineAndManual(res))
          }
        })
        .catch()
    }
  }, [
    dispatch,
    getLinkBetweenMachineAndManualFile,
    redux?.config?.linkBetweenMachineAndManual,
    redux?.user?.data?.role,
  ])

  return <></>
}
