import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import './VehiclesModal.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import Input from 'components/Others/Input/Input'
import {
  getVehicleByLicense,
  postVehicle as postVehicleService,
  putVehicle as putVehicleService,
} from 'services/Vehicles'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Modal from 'components/Others/Modal/Modal'
import { useDispatch, useSelector } from 'react-redux'
import { setReduxConfigModal, setReduxConfigFilters } from 'redux/ducks/configDuck'
import { Vehicle as formSchema } from 'formSchemas/Vehicle'
import ClassificationValidator from 'Share/ClassificationValidator'
import Filters from 'Share/Filters'
import useService from 'hooks/useService'
import parseFormValues from 'myMethods/parseFormValues'
import Select from 'components/Others/Select/Select'
import useResources from 'hooks/useResources'

/**
 * @typedef {import('types/Vehicle').Vehicle} Vehicle
 */
export default function VehiclesModal({ license, refetch, closeModal, isNew = false }) {
  const [data, setData] = useState({})
  const [values, setValues] = useState(null)
  const [classificationValidator, setClassificationValidator] = useState({
    isValid: false,
    text: '',
  })
  const clsInput = useRef()
  const form = useRef()
  const redux = useSelector(store => store)
  const dispatch = useDispatch()
  const { fuels, countries, categories } = useResources({
    fuels: true,
    countries: true,
    categories: true,
  })
  const fuels1 = fuels?.fuels1 ?? []
  const fuels2 = fuels?.fuels2 ?? []

  const { fetch: getVehicle, loading: loading1 } = useService({
    service: getVehicleByLicense,
  })
  const { fetch: postVehicle, loading: loading2 } = useService({
    service: postVehicleService,
  })
  const { fetch: putVehicle, loading: loading3 } = useService({
    service: putVehicleService,
  })
  const loading = loading1 || loading2 || loading3
  const hasError = !loading && !isNew && !Object.keys(data).length > 0

  const initialValuesClear = useMemo(
    () => ({
      license: '',
      classification: '',
      category: '',
      frame: '',
      homologation: '',
      licenseDate: '',
      licenseDate2: '',
      expirationDate: '',
      brand: '',
      model: '',
      fuel1: '',
      fuel2: '',
      type: '',
      dni: '',
      country: countries?.find(elem => elem.label.toLowerCase().includes('españa'))?.value || '',
    }),
    []
  )

  const handleInitialValues = () => {
    if (values && Object.keys(values).length) return parseFormValues(values, true)
    return initialValuesClear
  }

  const handleClassification = useCallback(() => {
    const value = clsInput?.current?.value
    if (value) {
      const validation = new ClassificationValidator(value).validate()
      setClassificationValidator({ isValid: validation.isValid, text: validation.text })
    }
  }, [])

  const checkCompatibility = values => {
    try {
      return Boolean(
        new Filters({
          filters: redux?.config?.filters,
          category: values.category,
          classification: values.classification,
        })?.filter
      )
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error verificando la clasificación')
      return false
    }
  }

  const handleAccept = () => {
    form.current?.submitForm()
  }

  const handleSubmit = async values => {
    try {
      if (!checkCompatibility(values)) {
        dispatch(
          setReduxConfigModal({
            message:
              'No hay ningún filtro de inspección con esa combinación de categoría y clasificación. Consulte al supervisor responsable',
            alertMessage:
              'Si el supervisor ha realizado cambios, pulse para refrescar el filtro de inspección',
            content: (
              <MyButtonsContainer>
                <MyButton
                  text="Refrescar filtro"
                  icon={<i className="flaticon-filter" />}
                  onClick={() => {
                    dispatch(setReduxConfigFilters({}))
                    dispatch(setReduxConfigModal(null))
                    easyToast('dark', 'Filtro refrescado')
                  }}
                />
              </MyButtonsContainer>
            ),
          })
        )
        return
      }

      setValues(values)
      values = parseFormValues(values)
      if (!isNew) {
        await putVehicle(values)
        easyToast('dark', `Vehículo ${values.license} actualizado`)
      } else {
        await postVehicle(values)
        easyToast('dark', `Vehículo ${values.license} añadido`)
      }
      refetch()
      closeModal()
    } catch (err) {
      const errorMessage =
        err.response?.status === 422
          ? 'No existe ningún cliente con el dni introducido'
          : 'Ha ocurrido un error guardando los datos. Inténtelo de nuevo'
      easyToast('error', errorMessage)
    }
  }

  useEffect(() => {
    if (license && !isNew && !Object.keys(data).length) {
      getVehicle(license)
        .then(res => {
          setData(res)
          const newValues = { ...initialValuesClear }
          Object.keys(newValues).forEach(key => {
            newValues[key] = key === 'dni' ? res.holder?.dni || '' : res[key] || ''
          })
          setValues(newValues)
        })
        .catch(() => {
          easyToast('error', 'Ha ocurrido un error cargando los datos del vehículo')
        })
    }
  }, [license, isNew, data, getVehicle, initialValuesClear])

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleClassification()
    }, 400)
    return () => clearTimeout(timeout)
  }, [handleClassification])

  return (
    <Modal
      open={true}
      onClose={closeModal}
      onAccept={handleAccept}
      hasAcceptButton={!hasError}
      closeOnOverlay={false}
      closeAfterAccept={false}
      loading={loading}
      title={isNew ? 'Añadir vehículo' : 'Editar vehículo'}
      content={
        <>
          {hasError && (
            <div className="no-values">Ha ocurrido un error cargando los datos del vehículo</div>
          )}
          {!hasError && (
            <div>
              <Formik
                enableReinitialize
                initialValues={handleInitialValues()}
                validationSchema={() => formSchema(classificationValidator.isValid)}
                innerRef={form}
                onSubmit={handleSubmit}
              >
                {({ setFieldValue, handleChange, values, setFieldTouched, errors, touched }) => (
                  <div className="vehicle-form vehicles-modal" data-aos="zoom-in">
                    {!isNew && data?.usuario_usuario && data?.nombre_usuario && (
                      <div className="vehicles-modal__last-modification">
                        Última modificación realizada por
                        <span>
                          {data?.usuario_usuario} - {data?.nombre_usuario}
                        </span>
                      </div>
                    )}

                    <Form className="vehicle-form__form">
                      {!isNew && <div className="vehicle-form__license">{values?.license}</div>}
                      {isNew && (
                        <div className="vehicles-modal__license">
                          <Input label="Matrícula" name="license" />
                        </div>
                      )}

                      <div className="form-input vehicle-form__clasif-container">
                        <label>
                          Clasificación
                          <ErrorMessage
                            className="form-error"
                            name="classification"
                            component="small"
                          />
                        </label>
                        <div className="vehicle-form__clasif">
                          <Field
                            innerRef={clsInput}
                            onKeyUp={e => {
                              handleChange(e)
                              handleClassification()
                            }}
                            name="classification"
                            placeholder="0000"
                          />
                          <div className="vehicle-form__clasif-text">
                            {classificationValidator.text || '-'}
                          </div>
                        </div>
                      </div>

                      <Select
                        label="Categoría"
                        ErrorComponent={
                          <ErrorMessage className="form-error" name="category" component="small" />
                        }
                        name="category"
                        placeholder="Escribe para buscar..."
                        options={categories}
                        value={categories?.find(elem => elem.value === values?.category) || ''}
                        onChange={opt => setFieldValue('category', opt ? opt.value : '')}
                        onBlur={() => setFieldTouched('category', true)}
                        error={errors.category}
                        touched={touched.category}
                      />

                      <Input className="form-input" label="Marca" name="brand" />
                      <Input className="form-input" label="Modelo" name="model" />
                      <Input className="form-input" label="Tipo" name="type" />
                      <Input className="form-input" label="Bastidor" name="frame" />
                      <Input
                        className="form-input"
                        label="Homologación"
                        name="homologation"
                        style={{ textTransform: 'none' }}
                      />
                      <Input
                        className="form-input"
                        label="Fecha de caducidad inspección"
                        name="expirationDate"
                        type="date"
                      />

                      <Select
                        label="Combustible 1"
                        ErrorComponent={
                          <ErrorMessage className="form-error" name="fuel1" component="small" />
                        }
                        name="fuel1"
                        options={fuels1}
                        value={
                          fuels1?.find(elem => elem.value.toUpperCase() === values?.fuel1) || ''
                        }
                        onChange={opt => setFieldValue('fuel1', opt ? opt.value : '')}
                        onBlur={() => setFieldTouched('fuel1', true)}
                        error={errors.fuel1}
                        touched={touched.fuel1}
                      />

                      <Select
                        label="Combustible 2"
                        ErrorComponent={
                          <ErrorMessage className="form-error" name="fuel2" component="small" />
                        }
                        name="fuel2"
                        options={fuels2}
                        value={
                          fuels2?.find(elem => elem.value.toUpperCase() === values?.fuel2) || ''
                        }
                        onChange={opt => setFieldValue('fuel2', opt ? opt.value : '')}
                        onBlur={() => setFieldTouched('fuel2', true)}
                        error={errors.fuel2}
                        touched={touched.fuel2}
                      />

                      <Select
                        label="País"
                        ErrorComponent={
                          <ErrorMessage className="form-error" name="country" component="small" />
                        }
                        name="country"
                        options={countries}
                        value={countries?.find(elem => elem.value === values.country) || ''}
                        onChange={opt => setFieldValue('country', opt ? opt.value : '')}
                        onBlur={() => setFieldTouched('country', true)}
                        error={errors.country}
                        touched={touched.country}
                      />
                      <Input
                        className="form-input"
                        label="Fecha 1º matriculación"
                        name="licenseDate"
                        type="date"
                      />
                      <Input
                        className="form-input"
                        label="Fecha 2º matriculación"
                        name="licenseDate2"
                        type="date"
                      />
                      <Input className="form-input" label="DNI/CIF titular" name="dni" />
                    </Form>
                  </div>
                )}
              </Formik>
            </div>
          )}
        </>
      }
    />
  )
}
