import formatEuro from 'myMethods/formatEuro'
import React from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Cell,
} from 'recharts'

const ToolTipComponent = ({ payload, unit, formatAsEuros }) => {
  try {
    if (payload?.length) {
      return (
        <div
          style={{
            backgroundColor: '#efefef',
            opacity: '0.9',
            padding: '0.5rem 1rem',
            borderRadius: '0.4rem',
          }}
        >
          <div style={{ color: '#333', fontWeight: '500' }}>{payload[0]?.payload?.name}</div>
          {payload.map(elem => {
            elem.name = elem.name.replace('_', ' ')
            return elem.payload?.[elem.name] ? (
              <div style={{ color: elem.color, fontWeight: '500' }}>
                {elem.name === 'value' ? '' : elem.name + ': '}
                {formatAsEuros ? formatEuro(elem.payload[elem.name]) : elem.payload[elem.name]}{' '}
                {unit ? unit : ''}
              </div>
            ) : (
              <></>
            )
          })}
        </div>
      )
    } else return <></>
  } catch (err) {
    console.error(err)
    return <></>
  }
}

export default function SimpleBarsChart({
  data: dataProps = [],
  dataKey,
  hiddenNames = [],
  formatAsEuros = false,
  unit = null,
  height = 300,
  tooltip = false,
  legend = false,
  title = '',
  YAxisUnit = null,
  colors: colorsProps = ['#8884d8', '#82ca9d', '#ffc658', '#E67777', '#CCCCCC', '#444444'],
  ...rest
}) {
  try {
    let data = JSON.parse(JSON.stringify(dataProps))
    let colors = JSON.parse(JSON.stringify(colorsProps))
    if (hiddenNames?.length) {
      hiddenNames.forEach(hName => {
        const index = data.findIndex(elem => elem.name === hName)
        if (index > -1) {
          data.splice(index, 1)
          if (colors.length > index) {
            colors.splice(index, 1)
          }
        }
      })
    }
    return (
      <div className="simple-bars-chart" {...rest}>
        <h3 style={{ textAlign: 'center', marginBottom: 0 }}>{title}</h3>
        <ResponsiveContainer width={'99%'} height={height}>
          <BarChart
            data={data ?? []}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            maxBarSize={50}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis unit={YAxisUnit} />
            {tooltip && (
              <Tooltip
                content={({ payload }) => (
                  <ToolTipComponent payload={payload} unit={unit} formatAsEuros={formatAsEuros} />
                )}
              />
            )}
            {legend && <Legend />}
            {data && data.length > 0 && dataKey && (
              <Bar dataKey={dataKey} stackId="a">
                {data.map((_, i) => (
                  <Cell cursor="pointer" fill={colors[i] || '#444444'} key={i} />
                ))}
              </Bar>
            )}
          </BarChart>
        </ResponsiveContainer>
      </div>
    )
  } catch (err) {
    console.error(err)
    return <></>
  }
}
