//@ts-check
import React, { useRef, useState } from 'react'
import './TableFilter.scss'
import { Search as SearchIcon, Delete as DeleteIcon } from '@mui/icons-material'
import isValidFunction from 'myMethods/isValidFunction'
import Modal from '../Modal/Modal'
import easyToast from '../EasyToast/easyToast'
import Checkbox from '../Checkbox/Checkbox'
import { Formik } from 'formik'
import Input from '../Input/Input'
import { TableFilter as formSchema } from 'formSchemas/TableFilter'
import parseFormValues from 'myMethods/parseFormValues'
import MyButtonsContainer from '../Buttons/MyButtonsContainer'
import MyButton from '../Buttons/MyButton/MyButton'
import HoverTooltip from '../HoverTooltip/HoverTooltip'
import areIdenticalObjects from 'myMethods/areIdenticalObjects'
import { useSelector } from 'react-redux'
import DateTimeITV from 'Share/DateTimeITV'
import Select from '../Select/Select'
import useResources from 'hooks/useResources'

/**
 *
 * @param {object} param0
 * @param {number} param0.totalValues
 * @param {import('types/FilterServiceParams').FilterServiceParams | undefined} param0.initialFilterValues
 * @param {string | undefined} param0.info
 * @param {boolean | undefined} param0.startDate
 * @param {boolean | undefined} param0.endDate
 * @param {boolean | undefined} param0.likeLicense
 * @param {boolean | undefined} param0.license
 * @param {boolean | undefined} param0.licenseDateStart
 * @param {boolean | undefined} param0.licenseDateEnd
 * @param {boolean | undefined} param0.fuel
 * @param {boolean | undefined} param0.category
 * @param {boolean | undefined} param0.likeDni
 * @param {boolean | undefined} param0.dni
 * @param {boolean | undefined} param0.likeName
 * @param {boolean | undefined} param0.holderDni
 * @param {boolean | undefined} param0.likeHolderDni
 * @param {boolean | undefined} param0.serie
 * @param {boolean | undefined} param0.inUse
 * @param {boolean | undefined} param0.reportNumber
 * @param {boolean | undefined} param0.likeReportNumber
 * @param {boolean | undefined} param0.rev
 * @param {function} param0.onSubmit
 * @param {boolean} param0.loading
 * @param {function} param0.onClear
 * @param {function | undefined} param0.onClose
 * @param {boolean | undefined} param0.initiallyOpen
 *
 */
export default function TableFilter({
  totalValues,
  initialFilterValues,
  info,
  startDate = false,
  endDate = false,
  likeLicense = false,
  license = false,
  licenseDateStart = false,
  licenseDateEnd = false,
  fuel = false,
  category = false,
  likeDni = false,
  dni = false,
  likeName = false,
  holderDni = false,
  likeHolderDni = false,
  serie = false,
  inUse = false,
  reportNumber = false,
  likeReportNumber = false,
  rev = false,
  onSubmit = () => {},
  onClear,
  onClose,
  loading = false,
  initiallyOpen = false,
}) {
  const [open, setOpen] = useState(initiallyOpen)
  const formRef = useRef(null)
  const rawValues = {
    startDate: '',
    endDate: '',
    likeLicense: '',
    likeDni: '',
    likeName: '',
    dniHolder: '',
    licenseDateStart: '',
    licenseDateEnd: '',
    serie: '',
    inUse: false,
    rev: '',
    numero: '',
    likeNumero: '',
  }
  const rawSeries = useSelector(store => store.config?.itv?.accounting?.series)

  const values = { ...rawValues, ...initialFilterValues }
  const seriesOptions = Object.entries(rawSeries ?? []).map(([key, value]) => {
    return { label: value, value: key }
  })
  const { fuels, categories } = useResources({
    fuels: true,
    categories: true,
  })
  const fuelOptions =
    fuels?.fuels1.concat(fuels?.fuels2 ?? [])?.filter(fuel => fuel.value !== 'SIN COMBUSTIBLE') ??
    []
  const handleAccept = () => {
    try {
      if (!formRef?.current?.isValid) return easyToast('error', 'El formulario tiene errores')
      if (!isValidFunction(onSubmit)) throw new Error('onSubmit must be a valid function')
      let formValues = JSON.parse(JSON.stringify(formRef?.current?.values))
      if (formValues == null) throw new Error('No values')
      if (!Object.values(formValues).filter(value => value)?.length)
        return easyToast('error', 'Inserte valores para la búsqueda')

      if (areIdenticalObjects(values, formValues))
        return easyToast('error', 'No se han detectado cambios frente a la búsqueda anterior')

      if (
        formValues.startDate &&
        formValues.endDate &&
        new DateTimeITV(formValues.startDate).isAfterThan(formValues.endDate)
      ) {
        return easyToast('error', 'La fecha de fin no puede ser anterior a la de inicio')
      }
      if (
        formValues.licenseDateStart &&
        formValues.licenseDateEnd &&
        new DateTimeITV(formValues.licenseDateStart).isAfterThan(formValues.licenseDateEnd)
      ) {
        return easyToast(
          'error',
          'La fecha de matriculación de fin no puede ser anterior a la fecha de matriculación de inicio'
        )
      }

      for (const key in formValues) {
        if (formValues[key] === '' || (key === 'inUse' && formValues[key] === false)) {
          delete formValues[key]
        }
      }
      formValues = parseFormValues(formValues)
      onSubmit(formValues)
      setOpen(false)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error con la búsqueda')
    }
  }

  return (
    <>
      <div className="table-filter">
        <HoverTooltip title="Búsqueda avanzada">
          <div className="table-filter__btn" onClick={() => setOpen(true)}>
            <SearchIcon />
          </div>
        </HoverTooltip>
      </div>
      {open && (
        <Modal
          open={true}
          onClose={() => {
            setOpen(false)
            isValidFunction(onClose) && onClose()
          }}
          loading={loading}
          title="Búsqueda avanzada"
          message={`Esta búsqueda se realizará sobre toda la base de datos ${
            totalValues == null ? '' : `(${totalValues} registros)`
          }`}
          message2={info}
          acceptButtonText="Buscar"
          closeAfterAccept={false}
          closeOnOverlay={false}
          onAccept={handleAccept}
          content={
            <>
              <div className="table-filter__modal">
                <Formik
                  enableReinitialize
                  initialValues={values}
                  validationSchema={formSchema}
                  innerRef={formRef}
                  onSubmit={() => {}}
                >
                  {({ values: formValues, setFieldValue, setFieldTouched, errors, touched }) => (
                    <>
                      {startDate && <Input type="date" label="Desde" name="startDate" />}
                      {endDate && <Input type="date" label="Hasta" name="endDate" />}
                      {likeLicense && (
                        <Input label="Matrícula (completa o parcial)" name="likeLicense" />
                      )}
                      {license && <Input label="Matrícula (completa)" name="license" />}
                      {likeDni && <Input label="DNI (completo o parcial)" name="likeDni" />}
                      {dni && <Input label="DNI/CIF (completo)" name="dni" />}
                      {likeName && <Input label="Nombre (completo o parcial)" name="likeName" />}
                      {holderDni && <Input label="DNI/CIF titular (completo)" name="dniHolder" />}
                      {likeHolderDni && (
                        <Input label="DNI/CIF titular (completo o parcial)" name="likeDniHolder" />
                      )}
                      {licenseDateStart && (
                        <Input type="date" label="Matriculado después de" name="licenseDateStart" />
                      )}
                      {licenseDateEnd && (
                        <Input type="date" label="Matriculado antes de" name="licenseDateEnd" />
                      )}

                      {category && (
                        <Select
                          label="Categoría"
                          name="category"
                          isClearable={false}
                          options={categories}
                          value={
                            categories?.find(elem => elem.value === formValues?.category) ?? ''
                          }
                          onChange={opt => setFieldValue('category', opt ? opt.value : '')}
                          onBlur={() => setFieldTouched('category', true)}
                          error={errors.category}
                          touched={touched.category}
                        />
                      )}
                      {fuel && (
                        <Select
                          label="Combustible"
                          name="fuel"
                          isClearable={false}
                          options={fuelOptions}
                          value={fuelOptions?.find(elem => elem.value === formValues?.fuel) ?? ''}
                          onChange={opt => setFieldValue('fuel', opt ? opt.value : '')}
                          onBlur={() => setFieldTouched('fuel', true)}
                          error={errors.fuel}
                          touched={touched.fuel}
                        />
                      )}

                      {serie && (
                        <Select
                          label="Serie"
                          name="serie"
                          isClearable={false}
                          options={seriesOptions}
                          value={
                            seriesOptions?.find(elem => elem.value === formValues?.serie) ?? ''
                          }
                          onChange={opt => setFieldValue('serie', opt ? opt.value : '')}
                          onBlur={() => setFieldTouched('serie', true)}
                          error={errors.serie}
                          touched={touched.serie}
                        />
                      )}
                      {inUse && (
                        <Checkbox
                          label="Solo albaranes facturados"
                          checked={formValues.inUse}
                          onChange={checked => setFieldValue('inUse', checked)}
                        />
                      )}
                      {reportNumber && (
                        <Input type="number" label="Número de informe" name="numero" />
                      )}
                      {likeReportNumber && (
                        <Input
                          type="number"
                          label="Número de informe (completo o parcial)"
                          name="likeNumero"
                        />
                      )}
                      {rev && <Input type="number" label="Revisión" name="rev" />}
                    </>
                  )}
                </Formik>
              </div>
              <MyButtonsContainer>
                <MyButton
                  text="Eliminar búsqueda"
                  warningColor={true}
                  icon={<DeleteIcon />}
                  onClick={() => {
                    isValidFunction(onClear) && onClear()
                    setOpen(false)
                  }}
                />
              </MyButtonsContainer>
            </>
          }
        />
      )}
    </>
  )
}
