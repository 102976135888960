import { useState, useCallback, useMemo, useEffect } from 'react'
import useService from './useService'
import { useSelector, useDispatch } from 'react-redux'
import {
  getResourcesCategories,
  getResourcesClientPaymentMethods,
  getResourcesCountries,
  getResourcesFees,
  getResourcesFuels,
  getResourcesManualSections,
  getResourcesReformsManualSections,
  getResourcesRoles,
} from 'services/Resources'
import {
  setReduxResourcesCategories,
  setReduxResourcesClientPaymentMethods,
  setReduxResourcesCountries,
  setReduxResourcesFuels,
  setReduxResourcesInspectionTypes,
  setReduxResourcesManualSections,
  setReduxResourcesReformsManualSections,
  setReduxResourcesRoles,
} from 'redux/ducks/resourcesDuck'

/**
 * Custom hook to fetch and manage resources.
 *
 * @param {object} requiredResources - An object with keys representing the required resources.
 * @returns {{roles, fuels, countries, categories, clientPaymentMethods, inspectionTypes, manualSections, reformsManualSections, loading, error}}
 */
const useResources = requiredResources => {
  const [loading, setLoading] = useState({})
  const [error, setError] = useState({})
  const [requested, setRequested] = useState({})
  const resources = useSelector(store => store.resources)
  const dispatch = useDispatch()

  const { fetch: fetchRoles } = useService({ service: getResourcesRoles })
  const { fetch: fetchFuels } = useService({ service: getResourcesFuels })
  const { fetch: fetchCountries } = useService({ service: getResourcesCountries })
  const { fetch: fetchCategories } = useService({ service: getResourcesCategories })
  const { fetch: fetchClientPaymentMethods } = useService({
    service: getResourcesClientPaymentMethods,
  })
  const { fetch: fetchAllFees } = useService({ service: getResourcesFees })
  const { fetch: fetchManualSections } = useService({ service: getResourcesManualSections })
  const { fetch: fetchReformsManualSections } = useService({
    service: getResourcesReformsManualSections,
  })

  const resourceFetchers = useMemo(
    () => ({
      roles: fetchRoles,
      fuels: fetchFuels,
      countries: fetchCountries,
      categories: fetchCategories,
      clientPaymentMethods: fetchClientPaymentMethods,
      inspectionTypes: fetchAllFees,
      manualSections: fetchManualSections,
      reformsManualSections: fetchReformsManualSections,
    }),
    [
      fetchRoles,
      fetchFuels,
      fetchCountries,
      fetchCategories,
      fetchClientPaymentMethods,
      fetchAllFees,
      fetchManualSections,
      fetchReformsManualSections,
    ]
  )

  const resourceDispatch = useMemo(
    () => ({
      roles: setReduxResourcesRoles,
      fuels: setReduxResourcesFuels,
      countries: setReduxResourcesCountries,
      categories: setReduxResourcesCategories,
      clientPaymentMethods: setReduxResourcesClientPaymentMethods,
      inspectionTypes: setReduxResourcesInspectionTypes,
      manualSections: setReduxResourcesManualSections,
      reformsManualSections: setReduxResourcesReformsManualSections,
    }),
    []
  )

  const fetchResource = useCallback(
    async resourceName => {
      console.log(`Attempting to fetch resource: ${resourceName}`)
      if (!resourceFetchers[resourceName]) {
        console.error(`No fetcher found for resource: ${resourceName}`)
        return
      }

      if (loading[resourceName] || requested[resourceName]) {
        console.log(`Resource ${resourceName} is already being fetched or has been requested.`)
        return // Prevent duplicate requests
      }

      console.log(`Fetching resource: ${resourceName}`)

      setLoading(prev => ({ ...prev, [resourceName]: true }))
      setError(prev => ({ ...prev, [resourceName]: null }))
      setRequested(prev => ({ ...prev, [resourceName]: true }))

      try {
        let data = await resourceFetchers[resourceName]()
        if (resourceName === 'inspectionTypes') {
          data = data.fees?.map(fee => ({
            value: fee.inspectionType,
            label: fee.inspectionType,
          }))
        } else if (resourceName === 'clientPaymentMethods') {
          data = data.filter(method => method.isActive)
        }
        console.log(`Fetched data for resource: ${resourceName}`, data)
        dispatch(resourceDispatch[resourceName](data))
      } catch (err) {
        console.error(`Error fetching resource: ${resourceName}`, err)
        setError(prev => ({ ...prev, [resourceName]: err.message }))
      } finally {
        setLoading(prev => ({ ...prev, [resourceName]: false }))
      }
    },
    [resourceFetchers, dispatch, resourceDispatch, loading, requested]
  )

  useEffect(() => {
    Object.keys(requiredResources).forEach(resourceName => {
      if (
        requiredResources[resourceName] &&
        !resources[resourceName] &&
        !loading[resourceName] &&
        !requested[resourceName]
      ) {
        console.log(`useEffect: Fetching resource ${resourceName}`)
        fetchResource(resourceName)
      }
    })
  }, [requiredResources, resources, fetchResource, loading, requested])

  const result = useMemo(() => {
    const output = {}
    Object.keys(requiredResources).forEach(resourceName => {
      output[resourceName] = resources[resourceName]
    })
    return output
  }, [requiredResources, resources])

  return {
    ...result,
    loading,
    error,
  }
}

export default useResources
