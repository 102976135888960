import React from 'react'
import './CirclePercentageChart.scss'

export default function CirclePercentageChart({
  number,
  strokeColor = '#333',
  backgroundStrokeColor = '#eee',
  className,
}) {
  try {
    if (number == null || typeof number !== 'number' || isNaN(number))
      throw new Error('No number or valid number provided')
    return (
      <div className={`circle-percentage-chart__container${className ? ' ' + className : ''}`}>
        <svg viewBox="0 0 36 36" className="circle-percentage-chart">
          <path
            className="circle-percentage-chart__bg"
            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
            style={{ stroke: backgroundStrokeColor }}
          />
          <path
            className="circle-percentage-chart__circle"
            style={{ stroke: strokeColor }}
            strokeDasharray={`${number}, 100`}
            d="M18 2.0845
            a 15.9155 15.9155 0 0 1 0 31.831
            a 15.9155 15.9155 0 0 1 0 -31.831"
          />
          <text x="18" y="20.35" className="circle-percentage-chart__percentage">
            {number.toFixed(2).replace('.', ',')}%
          </text>
        </svg>
      </div>
    )
  } catch (error) {
    console.error(error)
    return <></>
  }
}
