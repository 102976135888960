//@ts-check
import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'
import { CashRegisterAPIClient } from 'mint-cash-api-client'
import easyToast from 'components/Others/EasyToast/easyToast'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const handleCashRegisterResponseErrors = err => {
  try {
    if (err.response?.data?.title === 'CASH_REGISTER_NOT_ACTIVE') {
      easyToast(
        'error',
        'Tu caja no está activa, por favor, ponte en contacto con tu administrador'
      )
    }
    throw err
  } catch (err) {
    console.error(err)
    throw err
  }
}

/**
 * @typedef {import('mint-cash-types').GetAllCashRegistersResponseDTO} GetAllCashRegistersResponseDTO
 * @param {*} options
 * @returns {Promise<GetAllCashRegistersResponseDTO>}
 */
export function getAllCashRegisters(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}cash_register`, {
    ...getJWT(),
    signal: options.signal,
  })
}

/**
 * @typedef {import('mint-cash-types').CashRegisterCreatedDTO} CashRegisterCreatedDTO
 * @param {{name: string, validUntil?: string, active?: boolean}} data
 * @param {*} options
 * @returns {Promise<CashRegisterCreatedDTO>}
 */
export function postCashRegister(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}cash_register`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

/**
 * @typedef {import('mint-cash-types').CashRegisterPrimitive} CashRegisterDTO
 * @param {{id: string, data: {name: string, validUntil: string, active: boolean} }} param0
 * @param {*} options
 */
export function putCashRegister({ id, data }, options = {}) {
  /**@type {Promise<{kind: string, cashRegister: CashRegisterDTO  }>} */
  const result = Axios.put(`${SERVER_URL}/api/${v2}cash_register/${id}`, data, {
    ...getJWT(),
    signal: options.signal,
  })
  return result
}

/**
 * @typedef {import('mint-cash-types').CashRegisterStatusResponseDTO} CashRegisterStatusResponseDTO
 * @param {*} param0
 * @param {*} options
 * @returns {Promise<CashRegisterStatusResponseDTO>}
 */
export function getCashRegisterStatus({ cashId, date }, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}cash_register/status`, {
    params: { cashId, date },
    ...getJWT(),
    signal: options.signal,
  }).then(res => {
    console.log({ res })
    if (res && res.data) {
      res.data.isClosed =
        res.data.name === 'ITV'
          ? Boolean(res.data.archings?.[0]?.reason === 'FIN')
          : Boolean(res.data.archings?.some(arc => arc.reason === 'FIN'))

      res.data.wasOpen = res.data.archings?.some(arc => arc.reason === 'INICIO') || false
    }
    return res
  })
}

/**
 * @typedef {import('mint-cash-types').GetAllCashRegisterNamesAndIDsDTO} GetAllCashRegisterNamesAndIDsDTO
 * @param {*} options
 * @returns {Promise<GetAllCashRegisterNamesAndIDsDTO>}
 */
export function getCashRegisterNames(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}cash_register/names`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getCashRegisterEndOfDayReport(date, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}cash_register/report/${date}`, {
    ...getJWT(),
    responseType: 'blob',
    signal: options.signal,
  })
}

export function postCashRegisterArching(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}cash_register/arching`, data, {
    ...getJWT(),
    signal: options.signal,
  }).catch(handleCashRegisterResponseErrors)
}

export function putRepeatCashRegisterArch(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}cash_register/arching`, data, {
    ...getJWT(),
    signal: options.signal,
  }).catch(handleCashRegisterResponseErrors)
}

export function postCashRegisterCashFlow(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}cash_register/cash_flow`, data, {
    ...getJWT(),
    signal: options.signal,
  }).catch(handleCashRegisterResponseErrors)
}
