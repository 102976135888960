export default function sortArrayOfObjectsByDate(array, key, descending = true) {
  try {
    return array.sort((a, b) => {
      const dateA = new Date(a[key])
      const dateB = new Date(b[key])

      if (descending) {
        return dateB - dateA
      } else {
        return dateA - dateB
      }
    })
  } catch (err) {
    console.error(err)
    return array
  }
}
