//@ts-check
import React from 'react'
import './EobdConfig.scss'
import TestConfigWrapper from '../../../TestConfigWrapper/TestConfigWrapper'
import ToggleButtons from 'components/Others/Buttons/ToggleButtons/ToggleButtons'

export default function EobdConfig({ setValues, values, disabled = false }) {
  return (
    <TestConfigWrapper testName="EOBD">
      <ToggleButtons
        label="EURO"
        buttons={[
          { text: '3 y anterior', value: 3 },
          { text: '4', value: 4 },
          { text: '5', value: 5 },
          { text: '5', value: 6 },
        ]}
        selectedValue={values?.euro}
        handleChange={value => setValues({ ...values, euro: value })}
        disabled={disabled}
      />
    </TestConfigWrapper>
  )
}
