import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

/**
 *
 * @param {import('types/FilterServiceParams').FilterServiceParams & {offset?: number, limit?:number}} params
 * @param {AbortSignal} [options.signal] - Optional AbortSignal to cancel the request.
 * @returns
 */
export function getVehiclesByChunk(params, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}vehicles/match`, {
    headers: { ...getJWT().headers },
    params: params ?? {},
    signal: options.signal,
  })
}

export function getVehicleByLicense(license, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}vehicles/${license}`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putVehicle(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}vehicles`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function postVehicle(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}vehicles`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}
