import React, { useRef, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import Typed from 'typed.js'
import { useSelector } from 'react-redux'

// MY IMPORTS
import 'pages/Lobby/Lobby.scss'
import Title from 'components/Others/Title/Title'
import getUserShortName from 'myMethods/getUserShortName'
import easyToast from 'components/Others/EasyToast/easyToast'

export default function Lobby() {
  const el = useRef()
  const redux = useSelector(store => store)
  const reduxConfig = redux.config
  const role = redux.user.data?.role || window.sessionStorage.getItem('role')
  const roleName = redux.user.data?.role
  const userShortName = getUserShortName()?.toUpperCase()
  const history = useHistory()

  window.sessionStorage.removeItem('prompt')

  const rolesAssign = [
    {
      name: 'Nueva inspección',
      link: '/create_inspection',
      roles: ['ADMINISTRATIVE', 'ACCOUNTANT', 'INSPECTOR', 'SUPERVISOR'],
      icon: <i className="flaticon-plus" />,
      className: 'new_vehicle',
    },
    {
      name: 'Inspecciones',
      link: '/new_inspection',
      roles: ['INSPECTOR', 'SUPERVISOR'],
      icon: <i className="flaticon-inspection" />,
      className: 'new_inspection',
    },
    {
      name: 'Historial',
      link: '/history',
      roles: ['SUPERVISOR', 'BOSS'],
      icon: <i className="flaticon-folder-1" />,
      className: 'history',
    },
    {
      name: 'Inspecciones en curso',
      link: '/ongoing_inspections',
      roles: ['ADMINISTRATIVE', 'ACCOUNTANT', 'INSPECTOR', 'SUPERVISOR', 'BOSS'],
      icon: <i className="flaticon-gear" />,
      className: 'ongoing-inspections',
    },
    {
      name: 'Vehículos',
      link: '/vehicles',
      roles: ['ADMINISTRATIVE', 'INSPECTOR', 'SUPERVISOR', 'ACCOUNTANT', 'BOSS'],
      icon: <i className="flaticon-car-1" />,
      className: 'vehicles',
    },
    {
      name: 'Clientes',
      link: '/clients',
      roles: ['ADMINISTRATIVE', 'SUPERVISOR', 'INSPECTOR', 'SUPERVISOR', 'ACCOUNTANT', 'BOSS'],
      icon: <i className="flaticon-people" />,
      className: 'clients',
    },
    {
      name: 'Facturación',
      link: '/billing',
      roles: ['ADMINISTRATIVE', 'ACCOUNTANT', 'admin'],
      icon: <i className="flaticon-bill" />,
      className: 'billing',
    },
    {
      name: redux?.cashRegister?.status?.isClosed ? 'Caja (cerrada)' : 'Caja',
      link: '/cash-register',
      roles: ['ADMINISTRATIVE', 'INSPECTOR', 'SUPERVISOR', 'ACCOUNTANT', 'BOSS'],
      icon: <i className="flaticon-cash-register" />,
      className: 'cash-register',
    },
    {
      name: 'Estadísticas',
      link: '/statistics',
      roles: [],
      icon: <i className="flaticon-analytics" />,
      className: 'dashboard',
    },
    {
      name: 'Configurar ITV',
      link: '/itv_configurator',
      roles: ['SUPERVISOR', 'BOSS'],
      icon: <i className="flaticon-setting-lines" />,
      className: 'itv-configurator',
    },
    {
      name: 'Gestión de oficina',
      link: '/office-configurator',
      roles: ['ACCOUNTANT'],
      icon: <i className="flaticon-setting-lines" />,
      className: 'users',
    },
    {
      name: 'Gestión de usuarios',
      link: '/users',
      roles: [],
      icon: <i className="flaticon-setting-lines" />,
      className: 'users',
    },
  ]

  const getCards = () => rolesAssign.filter(elem => !elem.roles.length || elem.roles.includes(role))

  const getGreeting = useCallback(() => {
    try {
      if (roleName && userShortName) {
        return [
          `HOLA, ${roleName?.toUpperCase()}`,
          `HOLA${userShortName ? ', ' + userShortName : ''}`,
        ]
      }
      throw new Error('No roleName or userShortName in getGreeting lobby function')
    } catch (err) {
      return ['Hola']
    }
  }, [roleName, userShortName])

  const handleCardClick = card => {
    try {
      if (card.link) {
        history.push(card.link)
      } else {
        easyToast('error', 'Error al intentar acceder a la página')
      }
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const typed = new Typed(el.current, {
      strings: getGreeting(),
      startDelay: 100,
      typeSpeed: 50,
      backSpeed: 20,
      backDelay: 1500,
      showCursor: false,
    })
    // Destroying
    return () => {
      typed.destroy()
    }
  }, [role, userShortName, getGreeting])

  return (
    <>
      {reduxConfig?.itv?.info?.name && <Title text={reduxConfig.itv.info.name} />}
      <div className="lobby">
        <span className="lobby__animated-text" ref={el}></span>
        <p>¿Qué quieres hacer...?</p>
        <div className="lobby__grid">
          {getCards().map(elem => {
            return (
              <div
                className={`lobby__card lobby__card--${elem.className}`}
                onClick={() => handleCardClick(elem)}
                key={elem.name}
              >
                {elem.icon}
                <span>{elem.name}</span>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
