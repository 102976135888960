import React from 'react'

// MY IMPORTS
import Hardware from 'components/Inspection/Hardware/Hardware'
import Title from 'components/Others/Title/Title'
import MobileBackBtn from 'components/Others/MobileBackBtn/MobileBackBtn'

export default function HardwareMain() {
  return (
    <>
      <Title text={'EQUIPOS'} />
      <Hardware />
      <MobileBackBtn />
    </>
  )
}
