import * as Yup from 'yup'

export function Vehicle(classificationValidation) {
  return Yup.object().shape({
    country: Yup.string()
      .required('   ❌ PAÍS REQUERIDO')
      .max(3, '   ❌ PAÍS MUY LARGO')
      .matches(/^[a-zA-Z]+$/, '   ❌ PAÍS INVÁLIDO'),
    classification: Yup.mixed()
      .required('Requerido')
      .test('myTest', '   ❌ NO VÁLIDO', () => classificationValidation),
    category: Yup.string().required('Requerido').ensure(),
    frame: Yup.string().required('Requerido'),
    homologation: Yup.string().max(30, '   ❌ MUY LARGO'),
    licenseDate: Yup.date(),
    licenseDate2: Yup.date(),
    expirationDate: Yup.date(),
    brand: Yup.string().required('Requerido').max(45, '    ❌ MUY LARGO'),
    model: Yup.string().required('Requerido').max(45, '    ❌ MUY LARGO'),
    fuel1: Yup.string().ensure().required('Requerido'),
    fuel2: Yup.string().ensure(),
    type: Yup.string().max(20, '    ❌ MUY LARGO'),
    dni: Yup.string().required('Requerido'),
  })
}

export function VehicleNewInspection(classificationValidation) {
  return Yup.object().shape({
    classification: Yup.mixed()
      .required('Requerido')
      .test('myTest', '   ❌ NO VÁLIDO', () => classificationValidation),
    category: Yup.string().required('Requerido'),
    frame: Yup.string().required('Requerido'),
    homologation: Yup.string().max(30, '   ❌ MUY LARGO'),
    licenseDate: Yup.date(),
    licenseDate2: Yup.date(),
    brand: Yup.string().required('Requerido').max(45, '    ❌ MUY LARGO'),
    model: Yup.string().required('Requerido').max(45, '    ❌ MUY LARGO'),
    fuel1: Yup.string().ensure().required('Requerido'),
    fuel2: Yup.string().ensure(),
    type: Yup.string().max(20, '    ❌ MUY LARGO'),
    expirationDate: Yup.date(),
    lastStation: Yup.string()
      .ensure()
      .test('myTest', '   ❌ SOLO NÚMEROS', string => {
        if (string === '') return true
        return string?.match(/^[0-9]*$/)
      })
      .test('myTest', '   ❌ MÍNIMO 4', string => {
        if (string.length > 0 && string.length < 4) return false
        return true
      })
      .test('myTest', '   ❌ MÁXIMO 5', string => {
        if (string.length > 5) return false
        return true
      }),
  })
}
