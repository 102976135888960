//@ts-check
import React from 'react'
import MyButton from '../Buttons/MyButton/MyButton'
import MyButtonsContainer from '../Buttons/MyButtonsContainer'
import ErrorView from '../ErrorView/ErrorView'
import Modal from '../Modal/Modal'
import PDFViewer from '../PDFViewer/PDFViewer'
import openPDF from 'myMethods/openPDF'

/**
 *
 * @param {{pdf: any, title?: string, closeModal: function, displayAllPages?: boolean, loading?: boolean, filename?: string, pdfType?: string}} param0
 * @returns
 */
export default function PDFModal({
  pdf,
  title,
  closeModal,
  displayAllPages,
  loading,
  filename,
  pdfType,
}) {
  try {
    return (
      <Modal
        open={true}
        onClose={closeModal}
        title={title}
        hasAcceptButton={false}
        content={
          <div className="pdf-modal">
            <MyButtonsContainer margin="0 auto 1rem">
              <MyButton text="Abrir PDF" onClick={() => openPDF(pdf, filename, pdfType)} />
            </MyButtonsContainer>
            <PDFViewer pdf={pdf} show={true} displayAllPages={displayAllPages} loading={loading} />
          </div>
        }
      />
    )
  } catch {
    return <ErrorView />
  }
}
