//@ts-check
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'
import './ToggleButtons.scss'
import getCssVariable from 'myMethods/getCssVariable'

/**
 *
 * @param {{buttons: {icon?: any, text?: string, value: string | number, className?: string, color?: string}[], selectedValue: string | null, handleChange: function, className?: string, label?: string, disabled?: boolean}} param0
 * @returns
 */
export default function ToggleButtons({
  buttons,
  selectedValue,
  handleChange,
  className,
  label,
  disabled,
}) {
  return (
    <div className="toggle-buttons__wrapper">
      {label && (
        <div className="toggle-buttons__label">
          <label>{label}</label>
        </div>
      )}
      <ToggleButtonGroup
        value={selectedValue}
        className={`toggle-buttons ${className ? className : ''}`}
        exclusive
        onChange={(e, newValue) => handleChange(newValue)}
        aria-label="text alignment"
      >
        {buttons.map((button, i) => (
          <ToggleButton
            className={`toggle-button ${button.className ? button.className : ''}`}
            key={i}
            value={button.value}
            aria-label={button.text}
            disabled={disabled}
            style={{
              backgroundColor:
                selectedValue === button.value
                  ? button.color ?? getCssVariable('--main-color')
                  : '',
            }}
          >
            {button.icon && <div className="toggle-button__icon">{button.icon}</div>}
            {button.text && <div className="toggle-button__text">{button.text}</div>}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  )
}
