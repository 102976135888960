import React from 'react'
import { useHistory } from 'react-router-dom'

// MY IMPORTS
import 'pages/OnlyMobile/OnlyMobile.scss'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'

export default function OnlyMobile() {
  const history = useHistory()
  return (
    <div className="only-mobile">
      <i className="flaticon-monitor"></i>
      <b className="no-values">ESTE CONTENIDO SOLO ESTÁ DISPONIBLE DESDE UN DISPOSITIVO MÓVIL</b>
      <MyButtonsContainer fullWidth={false}>
        <MyButton text="Volver Atrás" onClick={() => history.goBack()} />
      </MyButtonsContainer>
    </div>
  )
}
