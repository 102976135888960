import React from 'react'

//MY IMPORTS
import InfoMenuField from 'components/Inspection/InfoMenu/InfoMenuField'
import 'components/Inspection/InfoMenu/InfoMenu.scss'
import changeDateFormat from 'myMethods/changeDateFormat'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'

export default function InfoMenu({ className, reduxInspection }) {
  /**
   * @type {import('types/Vehicle').Vehicle}
   */
  const vehicleData = reduxInspection?.vehicleData
  return (
    <>
      {vehicleData && Object.keys(vehicleData)?.length > 0 && (
        <section className={`info-menu ${className ?? ''}`}>
          <InfoMenuField value={vehicleData.license} />
          <InfoMenuField
            label={'TIPO DE INSPECCIÓN'}
            value={
              <div>
                {reduxInspection.inspectionType || '-'}
                {reduxInspection.config?.reforms?.length > 0 && (
                  <div className="info-menu__inspection-type-details">
                    {reduxInspection.config.reforms.map((reform, i) => (
                      <div key={i}>{reform.label}</div>
                    ))}
                  </div>
                )}
                {reduxInspection.config?.prelicense?.value != null && (
                  <div className="info-menu__inspection-type-details">
                    {reduxInspection.config.prelicense.value}
                  </div>
                )}
              </div>
            }
          />
          <InfoMenuField label={'CLASIFICACIÓN'} value={vehicleData.classification || '-'} />
          <InfoMenuField label={'CATEGORÍA'} value={vehicleData.category || '-'} />
          <InfoMenuField label={'MARCA'} value={vehicleData.brand || '-'} />
          <InfoMenuField label={'MODELO'} value={vehicleData.model || '-'} />
          <InfoMenuField label={'TIPO'} value={vehicleData?.type || '-'} />
          <InfoMenuField
            label={'FECHA 1ª MATRICULACIÓN'}
            value={changeDateFormat(vehicleData.licenseDate) || '-'}
          />
          <InfoMenuField label={'COMBUSTIBLE 1'} value={vehicleData.fuel1 || '-'} />
          <InfoMenuField label={'COMBUSTIBLE 2'} value={vehicleData.fuel2 || '-'} />
          <InfoMenuField label={'BASTIDOR'} value={vehicleData.frame || '-'} />
          <InfoMenuField
            label={'CONTRASEÑA DE HOMOLOGACIÓN'}
            value={vehicleData.homologation || '-'}
          />
          <InfoMenuField label={'NOMBRE DEL CLIENTE'} value={vehicleData.holder?.name || '-'} />
          <InfoMenuField
            label={'INFORME'}
            value={
              reduxInspection.reportNumber
                ? reduxInspection.rev
                  ? `${reduxInspection.reportNumber}-${reduxInspection.rev}`
                  : reduxInspection.reportNumber
                : '-'
            }
          />
        </section>
      )}
      {(!vehicleData || !Object.keys(vehicleData).length > 0) && (
        <div className="info-menu info-menu--loading">
          <ChildSpinner visible={true} color={'#ffffff'} />
        </div>
      )}
    </>
  )
}
