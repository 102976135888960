import Input from 'components/Others/Input/Input'
import Modal from 'components/Others/Modal/Modal'
import React, { useEffect, useState, useRef } from 'react'
import { Form, Formik } from 'formik'
import { Discount as formSchema } from 'formSchemas/Discount'
import RadioInputs from 'components/Others/RadioInputs/RadioInputs'
import './DiscountsConfiguratorModal.scss'
import { Edit as EditIcon, Close as CloseIcon } from '@mui/icons-material'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'
import ClassificationsSelector from 'components/Others/ClassificationsSelector/ClassificationsSelector'
import easyToast from 'components/Others/EasyToast/easyToast'
import {
  postDiscount as postDiscountService,
  putDiscount as putDiscountService,
} from 'services/Discounts'
import formatAndTrimText from 'myMethods/formatAndTrimText'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import moment from '../../../../../node_modules/moment/moment'
import Switch from 'components/Others/Switch/Switch'
import useService from 'hooks/useService'
import parseFormValues from 'myMethods/parseFormValues'
import Select from 'components/Others/Select/Select'
import useResources from 'hooks/useResources'

const initialValuesClear = {
  id: null,
  name: '',
  amount: '',
  minimumBenefit: '',
  method: null,
  description: '',
  applyFrom: '',
  applyUntil: '',
  creationDate: '',
}

export default function DiscountsConfiguratorModal({
  discount,
  isNew,
  closeModal,
  refetch,
  deleteDiscount,
}) {
  const formRef = useRef(null)
  const [isActive, setIsActive] = useState(discount?.isActivated ?? true)
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedClassifications, setSelectedClassifications] = useState(discount?.classifications)
  const [selectedFuels, setSelectedFuels] = useState([])
  const [method, setMethod] = useState('%')
  const [showClassificationsModal, setShowClassificationsModal] = useState(false)
  const { categories, fuels } = useResources({ categories: true, fuels: true })
  const fuels1 = fuels?.fuels1
  const fuels2 = fuels?.fuels2

  const { fetch: postDiscount, loading: loading1 } = useService({
    service: postDiscountService,
  })
  const { fetch: putDiscount, loading: loading2 } = useService({
    service: putDiscountService,
  })

  const loading = loading1 || loading2

  const handleInitialValues = () => {
    if (discount && Object.keys(discount).length) return parseFormValues(discount, true)
    else return initialValuesClear
  }

  const printClassifications = () => {
    try {
      if (selectedClassifications?.length) {
        let formatted = []
        selectedClassifications.forEach(cla => {
          if (cla.length === 4) formatted.push(cla)
          if (cla.length === 2) formatted.push(cla + 'xx')
        })
        return formatted.join(', ')
      } else return 'Todas'
    } catch (err) {
      console.error(err)
      return 'Error'
    }
  }

  const changeClassifications = newClassifications => {
    try {
      setSelectedClassifications(newClassifications)
      setShowClassificationsModal(false)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error guardando las clasificaciones')
    }
  }

  const changeIsActive = newIsActive => {
    try {
      setIsActive(newIsActive)
      if (!newIsActive) {
        formRef.current.setFieldValue('applyFrom', '')
        formRef.current.setFieldValue('applyUntil', moment().format('YYYY-MM-DD HH:mm:ss'))
      } else {
        formRef.current.setFieldValue('applyFrom', '')
        formRef.current.setFieldValue('applyUntil', '')
      }
    } catch (err) {
      console.error(err)
    }
  }

  const handleSubmit = async values => {
    try {
      if (method === '%' && values.amount > 100)
        return easyToast('error', 'El porcentaje no puede ser mayor de 100')

      if (!values.amount) return easyToast('error', 'Inserte una cantidad')
      if (
        values.applyFrom &&
        values.applyUntil &&
        moment(values.applyFrom).isAfter(moment(values.applyUntil))
      )
        return easyToast('error', 'Activo hasta no puede ser anterior a activo desde')
      values.name = formatAndTrimText(values.name)
      values.description = formatAndTrimText(values.description)
      const obj = {
        name: values.name,
        description: values.description,
        method,
        amount: values.amount,
        applyUntil: values.applyUntil || null,
        applyFrom: values.applyFrom || null,
        minimumBenefit: values.minimumBenefit || null,
        categories: selectedCategories?.length ? selectedCategories.map(cat => cat.value) : null,
        classifications: selectedClassifications?.length ? selectedClassifications : null,
        fuels: selectedFuels?.length ? selectedFuels.map(fuel => fuel.value) : null,
      }
      console.log({ obj })
      if (isNew) {
        await postDiscount(obj)
      } else {
        obj.discountId = discount.id
        await putDiscount(obj)
      }
      refetch()
      closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error generando la promoción')
    }
  }

  useEffect(() => {
    if (discount?.categories?.length && selectedCategories != null && !selectedCategories.length) {
      let newCategories = []
      discount.categories.forEach(elem => {
        const found = categories.find(cat => cat.value && elem && cat.value === elem)
        found && newCategories.push(found)
      })
      setSelectedCategories(newCategories)
    }
  }, [categories, discount.categories, selectedCategories])

  useEffect(() => {
    if (discount?.fuels?.length && selectedFuels != null && !selectedFuels.length) {
      let newFuels = []
      discount.fuels.forEach(elem => {
        const found = [...fuels1, ...fuels2].find(fuel => fuel.value && elem && fuel.value === elem)
        found && newFuels.push(found)
      })
      setSelectedFuels(newFuels)
    }
  }, [discount.categories, discount.fuels, fuels1, fuels2, selectedCategories, selectedFuels])

  return (
    <>
      <Modal
        open={true}
        loading={loading}
        title={
          isNew
            ? 'NUEVA PROMOCIÓN'
            : `EDITAR PROMOCIÓN${discount?.name ? ' "' + discount.name + '" ' : ''}`
        }
        closeAfterAccept={false}
        closeOnOverlay={false}
        onClose={closeModal}
        onAccept={() => formRef?.current?.submitForm()}
        content={
          <div className="discounts-configurator-modal">
            <Switch
              name="active"
              label="Promoción activa"
              value={isActive}
              onChange={changeIsActive}
            />
            <Formik
              enableReinitialize
              initialValues={handleInitialValues()}
              validationSchema={() => formSchema(isActive, method)}
              onSubmit={values => handleSubmit(values)}
              innerRef={formRef}
            >
              {() => (
                <Form className="discounts-configurator-modal__form">
                  <Input label="Nombre" name="name" />
                  <Input label="Descripción" name="description" />
                  <RadioInputs
                    className="discounts-configurator-modal__method"
                    label="Método de descuento"
                    onChange={value => setMethod(value)}
                    value={method}
                    defaultValueName="%"
                    radios={[
                      {
                        label: 'Porcentaje (%)',
                        value: '%',
                      },
                      {
                        label: 'Cantidad (€)',
                        value: 'DECREMENTO',
                      },
                    ]}
                  />
                  <Input label="Valor de descuento" name="amount" type="number" />
                  <Input label="Mínimo beneficio" name="minimumBenefit" type="number" />
                  <Input
                    label="Activo desde"
                    name="applyFrom"
                    type="datetime-local"
                    disabled={!isActive}
                  />
                  <Input
                    label="Activo hasta"
                    name="applyUntil"
                    type="datetime-local"
                    disabled={!isActive}
                  />
                  <Select
                    label="Categorías"
                    name="categoria"
                    isMulti={true}
                    placeholder="Todas"
                    options={categories}
                    value={selectedCategories}
                    onChange={setSelectedCategories}
                  />
                  <div className="form-input discounts-configurator-modal__classifications">
                    <label>Clasificaciones</label>
                    <div>
                      {printClassifications() || 'Todas'}{' '}
                      <aside>
                        <HoverTooltip
                          title="Limpiar clasificaciones"
                          onClick={() => setSelectedClassifications(null)}
                        >
                          <CloseIcon />
                        </HoverTooltip>
                      </aside>
                      <aside onClick={() => setShowClassificationsModal(true)}>
                        <HoverTooltip title="Editar clasificaciones">
                          <EditIcon />
                        </HoverTooltip>
                      </aside>
                    </div>
                  </div>
                  <Select
                    label="Combustibles"
                    name="fuel"
                    isMulti={true}
                    placeholder="Todos"
                    options={[...fuels1, ...fuels2]}
                    value={selectedFuels}
                    onChange={setSelectedFuels}
                  />
                </Form>
              )}
            </Formik>
            {!isNew && (
              <MyButtonsContainer>
                <MyButton
                  text="Eliminar promoción"
                  deleteIcon={true}
                  warningColor={true}
                  onClick={() => deleteDiscount(discount?.id)}
                />
              </MyButtonsContainer>
            )}
          </div>
        }
      />
      {showClassificationsModal && (
        <ClassificationsSelector
          classifications={selectedClassifications}
          handleAccept={changeClassifications}
          closeModal={() => setShowClassificationsModal(false)}
        />
      )}
    </>
  )
}
