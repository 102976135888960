import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import easyToast from 'components/Others/EasyToast/easyToast'
import PDFModal from 'components/Others/PDFModal/PDFModal'
import useService from 'hooks/useService'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { getBookmarkPdf as getBookmarkPdfService } from 'services/ManualPdfBookmarks'

export default function ManualBookmarkExtractor({ section }) {
  const params = useParams()
  const bookmarkId = params.manualChapter
  const [pdf, setPdf] = useState({
    bookmarkId: null,
    data: null,
    show: false,
  })

  const { fetch: getBookmarkPdf, loading } = useService({
    service: getBookmarkPdfService,
  })

  const getPdf = async () => {
    try {
      let pdfData = null
      if (!pdf.bookmarkId || !pdf.data || (pdf.bookmarkId && pdf.bookmarkId !== bookmarkId)) {
        console.log('HAGO PETICIÓN PDF')
        pdfData = await getBookmarkPdf({ section, bookmarkId })
        if (!pdfData) throw new Error('No pdf responsed')
      }
      setPdf(prevState => ({
        ...prevState,
        bookmarkId,
        data: pdfData ? pdfData : prevState.data,
        show: true,
      }))
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando el Manual ITV')
    }
  }
  console.log({ pdf })
  if (section && bookmarkId && bookmarkId.split('.').length > 1) {
    return (
      <>
        <MyButtonsContainer margin="0 auto 0.5rem">
          <MyButton
            text={`Ver Manual ITV`}
            icon={<i className="flaticon-copy-two-paper-sheets-interface-symbol" />}
            loading={loading}
            onClick={getPdf}
          />
        </MyButtonsContainer>
        {pdf.show && (
          <PDFModal
            pdf={pdf.data}
            closeModal={() => setPdf(prevState => ({ ...prevState, show: false }))}
            displayAllPages={true}
            title={`Manual ITV. Sección ${section} - ${bookmarkId}`}
            filename={`Manual-itv-sección-${section}-${bookmarkId}`}
          />
        )}
      </>
    )
  }
  return <></>
}
