import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import handleUnauthorizedResponse from 'myMethods/handleUnauthorizedResponse'
const SERVER_URL = process.env.REACT_APP_SERVER_URL
const TPV_URL = process.env.REACT_APP_TPV_URL

export function getTpv(options = {}) {
  return Axios.get(`${SERVER_URL}/api/files/TPV`, {
    ...getJWT(),
    signal: options.signal,
  })
    .then(res => {
      return res
    })
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      return err.response
    })
}

export function postTpvCharge(data, options = {}) {
  return Axios.post(`${TPV_URL}/api/tpv`, data, {
    signal: options.signal,
  })
    .then(res => {
      return res
    })
    .catch(err => {
      return err.response
    })
}

export function postTpvRefund(data, options = {}) {
  return Axios.post(`${TPV_URL}/api/tpv/devolucion`, data, {
    signal: options.signal,
  })
    .then(res => {
      return res
    })
    .catch(err => {
      return err.response
    })
}
