import React from 'react'
import './ModificationDiff.scss'

export default function ModificationDiff({ show = true, status, requestInProgress }) {
  try {
    const Diff = ({ nameKey, label, source, value1, value2 }) => {
      try {
        if (!value1)
          value1 =
            status[source]?.diff[status[source]?.diff?.findIndex(elem => elem.key === nameKey)]
              ?.value1
        if (!value2)
          value2 =
            status[source]?.diff[status[source]?.diff?.findIndex(elem => elem.key === nameKey)]
              ?.value2
        if (value1 || value2) {
          return (
            <div>
              {(label === '' ? label : nameKey) && <label>{label || nameKey}</label>}
              <div>
                {value2 && <b>{value2}</b>}
                <span>{value1}</span>
              </div>
            </div>
          )
        } else return <></>
      } catch (err) {
        console.error(err)
        return <></>
      }
    }
    console.log({ status })
    if (
      show &&
      (status.serie?.diff?.length ||
        status.paymentMethod?.diff?.length ||
        status.client?.diff?.length ||
        status.vehicle?.diff?.length ||
        status.deals?.diff?.length)
    )
      return (
        <div className="modification-diff">
          <div className="modification-diff__changes-container">
            <h3>
              {requestInProgress
                ? 'LOS SIGUIENTES CAMBIOS ESTÁN ESPERANDO CONFIRMACIÓN'
                : 'CAMBIOS QUE SE APLICARÁN'}
            </h3>
            <section>
              {status.serie?.diff?.length > 0 && (
                <div>
                  <h3>Serie</h3>
                  <div className="modification-diff__changes">
                    <Diff nameKey="serie" source="serie" label="" />
                  </div>
                </div>
              )}
              {status.paymentMethod?.diff?.length > 0 && (
                <div>
                  <h3>Método de pago</h3>
                  <div className="modification-diff__changes">
                    <Diff nameKey="paymentMethod" source="paymentMethod" label="" />
                  </div>
                </div>
              )}
              {status.client?.diff?.length > 0 && (
                <div>
                  <h3>Cliente</h3>
                  <div className="modification-diff__changes">
                    <Diff nameKey="dni" source="client" />
                    <Diff nameKey="name" label="nombre" source="client" />
                    <Diff nameKey="address" label="domicilio" source="client" />
                    <Diff nameKey="locality" label="localidad" source="client" />
                    <Diff nameKey="zipCode" label="cp" source="client" />
                    <Diff nameKey="province" label="provincia" source="client" />
                    <Diff nameKey="phone" label="teléfono" source="client" />
                    <Diff nameKey="email" label="email" source="client" />
                    <Diff nameKey="paymentMethod" label="Método de pago" source="client" />
                    <Diff nameKey="iban" source="client" />
                    <Diff nameKey="note" label="nota" source="client" />
                  </div>
                </div>
              )}
              {status.vehicle?.diff?.length > 0 && (
                <div>
                  <h3>Vehículo</h3>
                  <div className="modification-diff__changes">
                    <Diff nameKey="classification" label="clasificación" source="vehicle" />
                    <Diff nameKey="category" label="categoría" source="vehicle" />
                    <Diff nameKey="fuel1" label="combustible 1" source="vehicle" />
                    <Diff nameKey="fuel2" label="combustible 2" source="vehicle" />
                    <Diff nameKey="licenseDate" label="Fecha de matriculación" source="vehicle" />
                  </div>
                </div>
              )}
              {status.deals?.diff?.length > 0 && (
                <div>
                  <h3>Albaranes</h3>
                  <div className="modification-diff__changes">
                    {status.deals.diff.map((elem, i) => (
                      <Diff value1={elem.value1} value2={elem.value2} key={i} />
                    ))}
                  </div>
                </div>
              )}
            </section>
          </div>
        </div>
      )
    else return <div></div>
  } catch (err) {
    console.error(err)
    return <></>
  }
}
