import React, { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import './ModalDefect.scss'

//MY IMPORTS
import easyToast from 'components/Others/EasyToast/easyToast'
import calificationToText from 'myMethods/calificationToText'
import Id from 'Share/Id'

//SERVICES
import {
  deleteInspectionVisualDefect as deleteInspectionVisualDefectService,
  putInspectionDefects as putInspectionDefectsService,
} from 'services/Inspection'
import Modal from 'components/Others/Modal/Modal'
import DefectPlace from '../DefectPlace/DefectPlace'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Textarea from 'components/Others/Textarea/Textarea'
import useService from 'hooks/useService'
import { PlusLg as AddIcon, Camera as CaptureImageIcon } from 'react-bootstrap-icons'
import CaptureImage from 'components/Inspection/CaptureImage/CaptureImage'
import useInspectionImages from 'hooks/useInspectionImages'
import ImageLightbox from 'components/Others/Gallery/ImageLighbox/ImageLightbox'
import TrashIcon from '@mui/icons-material/Delete'
import Input from 'components/Others/Input/Input'

const DivisionTitle = ({ className = 'modal-defect__title', text }) => {
  return (
    <div className={className}>
      <div></div>
      <h3>{text}</h3>
      <div></div>
    </div>
  )
}

export default function ModalDefect({ defectId, closeModal }) {
  const [defectPlaceModal, setDefectPlaceModal] = useState({
    show: false,
    value: '',
    inputValue: '',
  })
  const [values, setValues] = useState(null)
  const redux = useSelector(store => store)
  const inspection = redux.inspection
  const [defect, setDefect] = useState(null)
  const [defectPlaces, setDefectPlaces] = useState(
    inspection?.visualDefects?.find(elem => elem.id === defect?.id)?.values?.places || []
  )
  const [imageOnMemory, setImageOnMemory] = useState(null)
  const captureImageRef = useRef()
  const imageLightboxRef = useRef()
  const inputMaxLength = redux?.config?.itv?.maxTextsLengths?.defectRemark ?? 40
  const maxPlaces = redux?.config?.itv?.maxVisualDefectPlaces ?? 3
  const foundOnVisualDefects = inspection?.visualDefects?.find(elem => elem.id === defect?.id)
  const [onlyRead, setOnlyRead] = useState(false)

  const {
    images: defectImages,
    uploadImage,
    deleteImage,
  } = useInspectionImages({
    type: 'defect',
    defectId: defect?.id,
  })

  const { fetch: deleteDefectService, loading: loading2 } = useService({
    service: deleteInspectionVisualDefectService,
  })
  const { fetch: putInspectionDefects, loading: loading1 } = useService({
    service: putInspectionDefectsService,
  })

  const loading = loading1 || loading2

  const handleDefectPlaceModalAccept = () => {
    if (!defectPlaceModal.value) return easyToast('error', 'Seleccione una opción')
    if (defectPlaceModal.value === 'OTROS' && !defectPlaceModal.inputValue)
      return easyToast('error', 'Escriba una localización')
    let aux = defectPlaces
    defectPlaceModal.value === 'OTRO'
      ? aux.push(defectPlaceModal.inputValue?.toUpperCase())
      : aux.push(defectPlaceModal.value)
    setDefectPlaces([...new Set(aux)])
    setDefectPlaceModal({ show: false, value: '' })
  }

  const deleteDefect = async () => {
    try {
      await deleteDefectService({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        defectId: defect.id,
      })
      closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', 'No se ha podido eliminar el defecto')
    }
  }

  const handleSubmit = async () => {
    try {
      if (onlyRead) return easyToast('error', 'No puedes editar el defecto de otro usuario')
      if (!values.calification) {
        return easyToast('error', 'Inserte una calificación')
      }
      if (foundOnVisualDefects) {
        if (
          foundOnVisualDefects.values.calification === values.calification &&
          JSON.stringify(foundOnVisualDefects.places?.sort()) ===
            JSON.stringify(defectPlaces.sort()) &&
          foundOnVisualDefects.values.remark === values.remark
        ) {
          return closeModal()
        }
      }

      if (values.remark?.length > inputMaxLength) {
        return easyToast(
          'error',
          `La observación es demasiado larga, sobran ${
            values.remark.length - inputMaxLength
          } caracteres`
        )
      }

      await putInspectionDefects({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        defect: {
          id: defect.id,
          values: {
            calification: values.calification,
            remark: values?.remark || '',
            places: defectPlaces,
          },
        },
      })
      imageOnMemory && (await uploadImage(imageOnMemory))
      easyToast('dark', 'Defecto actualizado')
      closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error actualizando el defecto. Inténtelo de nuevo')
    }
  }

  useEffect(() => {
    const foundDefect = inspection?.manualManager?.findDefectAndChilds(defectId)?.defect
    if (foundDefect) {
      foundDefect.calification = foundDefect.calification ?? foundDefect.possibleScores
      setDefect(foundDefect)
      setOnlyRead(
        foundDefect.user &&
          foundDefect.user !== redux?.user?.data?.user &&
          redux?.user?.data?.role !== 'SUPERVISOR'
      )
    } else {
      setDefect(null)
      setOnlyRead(false)
    }
  }, [defectId, inspection?.manualManager, redux?.user?.data])

  useEffect(() => {
    if (defect && !values && !onlyRead) {
      const found = inspection.visualDefects?.find(elem => elem.id === defect.id)
      if (found) {
        setValues({
          calification: found.values?.calification,
          remark: found.values?.remark?.text,
          places: found.places,
        })
      }
    }
  }, [defect, onlyRead, inspection.visualDefects, values])

  return (
    <>
      <Modal
        className="modal-defect"
        open={true}
        loading={loading}
        onAccept={handleSubmit}
        onClose={closeModal}
        closeAfterAccept={false}
        closeOnOverlay={false}
        hasAcceptButton={Boolean(values?.calification) && !onlyRead}
        content={
          <>
            {defect && (
              <div className="modal-defect">
                {foundOnVisualDefects?.user &&
                  redux.user.data?.username !== foundOnVisualDefects?.user && (
                    <div className="modal-defect__user">
                      Defecto añadido por {foundOnVisualDefects.user}
                    </div>
                  )}
                <div className="modal-defect__info">
                  <div className="modal-defect__id">{defect.id}</div>
                  <div className="modal-defect__name">{defect.name}</div>
                </div>

                <DivisionTitle text="CALIFICACIÓN" />
                <div className="modal-defect__calification">
                  {!onlyRead && (
                    <MyButtonsContainer flow="column" margin="0 auto">
                      {defect.calification?.map((calification, i) => (
                        <MyButton
                          key={i}
                          text={calificationToText(calification)}
                          selectable={true}
                          selected={calification === values?.calification}
                          className={`modal-defect__btn--${calification}`}
                          onClick={() =>
                            !onlyRead &&
                            setValues({
                              ...values,
                              calification:
                                values?.calification === calification ? null : calification,
                            })
                          }
                        />
                      ))}
                    </MyButtonsContainer>
                  )}
                  {onlyRead && (
                    <div
                      className={`modal-defect__calification-value--${
                        defect?.score ? defect.score : defect?.values?.calification || ''
                      }`}
                    >
                      {calificationToText(defect?.values?.calification || defect?.score)}
                    </div>
                  )}
                </div>
                <>
                  <DivisionTitle text="LUGARES" />
                  {!defectPlaces.length > 0 && (
                    <div className="modal-defect__no-value">SIN LUGARES</div>
                  )}
                  {defectPlaces.length > 0 && (
                    <>
                      {!onlyRead && (
                        <div className="modal-defect__defect-places-hint">Toca para eliminar</div>
                      )}
                      <div className="modal-defect__defect-places">
                        {defectPlaces.map((elem, i) => (
                          <div
                            key={i}
                            onClick={() =>
                              !onlyRead &&
                              setDefectPlaces(defectPlaces.filter(place => place !== elem))
                            }
                          >
                            {elem}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  {!onlyRead && defectPlaces.length < maxPlaces && (
                    <MyButtonsContainer margin="auto">
                      <MyButton
                        text="Añadir nuevo"
                        icon={<AddIcon />}
                        onClick={() => setDefectPlaceModal({ ...defectPlaceModal, show: true })}
                      />
                    </MyButtonsContainer>
                  )}
                  <DivisionTitle text="IMAGEN" />
                  <div className="modal-defect__image-container">
                    {(imageOnMemory?.src || defectImages?.[0]?.src) && (
                      <img
                        src={imageOnMemory?.src || defectImages[0]?.src}
                        alt="Imagen del defecto"
                        onClick={() => imageLightboxRef?.current?.setIndex(0)}
                      />
                    )}
                    {!imageOnMemory && !defectImages?.[0] && (
                      <div className="modal-defect__no-value">SIN IMAGEN</div>
                    )}
                    <ImageLightbox
                      ref={imageLightboxRef}
                      images={
                        defectImages?.length > 0
                          ? defectImages
                          : imageOnMemory
                          ? [imageOnMemory]
                          : []
                      }
                      thumbnails={false}
                      onDeleteImage={() => {
                        setImageOnMemory(null)
                        if (defectImages?.[0]) deleteImage(defectImages?.[0]?.filename)
                      }}
                    />
                    {!onlyRead && (
                      <MyButtonsContainer margin="auto">
                        {!(imageOnMemory || defectImages?.length > 0) && (
                          <MyButton
                            text="Añadir imagen"
                            icon={<CaptureImageIcon />}
                            onClick={() => captureImageRef?.current?.triggerCapture()}
                          />
                        )}
                        {(imageOnMemory !== null || defectImages?.length > 0) && (
                          <MyButton
                            text="Eliminar imagen"
                            transparent={true}
                            warningColor={true}
                            icon={<TrashIcon />}
                            onClick={() =>
                              imageOnMemory
                                ? setImageOnMemory(null)
                                : deleteImage(defectImages?.[0]?.filename)
                            }
                          />
                        )}
                      </MyButtonsContainer>
                    )}
                  </div>
                  <DivisionTitle text="OBSERVACIÓN" />
                  <div className="modal-defect__remark-container">
                    {!onlyRead && (
                      <Input
                        type="textarea"
                        disabled={onlyRead}
                        onChange={e => {
                          !onlyRead &&
                            setValues({
                              ...values,
                              remark: e.target.value.replace(/(\r\n|\n|\r)/gm, ''),
                            })
                        }}
                        value={values?.remark}
                        name="remark"
                        maxLength={inputMaxLength}
                        label="Observación"
                        isFormikInput={false}
                      />
                    )}
                    {onlyRead && (
                      <div>
                        {values?.remark ? (
                          values.remark
                        ) : (
                          <div className="modal-defect__no-value">SIN OBSERVACIÓN</div>
                        )}
                      </div>
                    )}
                  </div>
                  {Boolean(foundOnVisualDefects) && !onlyRead && (
                    <MyButtonsContainer>
                      <MyButton
                        text="ELIMINAR DEFECTO"
                        warningColor={true}
                        icon={<i className="flaticon-trash" />}
                        onClick={deleteDefect}
                      />
                    </MyButtonsContainer>
                  )}
                </>
              </div>
            )}
          </>
        }
      />
      <CaptureImage
        ref={captureImageRef}
        onCaptureImage={(image, geolocation) => {
          setImageOnMemory({
            image,
            geolocation,
            defectId: defect.id,
            label: `Defecto ${defect.id}`,
            src: URL.createObjectURL(image),
          })
        }}
      />
      <Modal
        open={defectPlaceModal.show}
        onClose={() => setDefectPlaceModal({ show: false, value: '', inputValue: '' })}
        onAccept={handleDefectPlaceModalAccept}
        title="Localización del defecto"
        closeAfterAccept={false}
        content={
          <DefectPlace
            value={defectPlaceModal.value}
            inputValue={defectPlaceModal.inputValue}
            setValue={value => setDefectPlaceModal({ ...defectPlaceModal, value })}
            setInputValue={inputValue => setDefectPlaceModal({ ...defectPlaceModal, inputValue })}
            defectPlaces={defectPlaces}
            reduxInspection={inspection}
          />
        }
      />
    </>
  )
}
