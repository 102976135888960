import React, { useEffect, useState } from 'react'
import { Search, Clipboard as CopyIcon, Check2 as CopyCheckIcon } from 'react-bootstrap-icons'
import './BotMessage.scss'
import MarkdownRender from 'components/Others/MarkdownRender/MarkdownRender'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'

/**
 *
 * @param {{id: string, content?: string, textRef?: any,  maxAnswers: number, answerNumber: number, isWritting?: boolean}} param0
 * @returns
 */
export default function BotMessage({ id, content, textRef, maxAnswers, answerNumber }) {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 3000)
    }
  }, [copied])

  return (
    <div className={'chatbot-body__message-container--BOT'} id={id}>
      <div className="chatbot-body__message">
        <div className="chatbot-body__text" ref={textRef}>
          <MarkdownRender text={content} />
        </div>
        <div className="bot-message__info">
          <div className="bot-message__counter">
            <span>
              {answerNumber}/{maxAnswers}
            </span>
          </div>
          <div className="bot-message__options">
            <div className="bot-message__copy">
              {!copied && (
                <HoverTooltip title="Copiar">
                  <CopyIcon
                    onClick={() => {
                      navigator.clipboard.writeText(content)
                      setCopied(true)
                    }}
                  />
                </HoverTooltip>
              )}
              {copied && <CopyCheckIcon />}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
