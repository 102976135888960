import React, { useState } from 'react'

// MY IMPORTS
import 'components/Office/Clients/AddAndModifyClientModal/LicensesTable/LicensesTable.scss'
import Modal from 'components/Others/Modal/Modal'
import easyToast from 'components/Others/EasyToast/easyToast'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'

export default function LicensesTable({ licenses, setLicenses }) {
  const [showModal, setShowModal] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [modalError, setModalError] = useState('')

  const handleModalAccept = () => {
    const valid = Boolean(
      inputValue.match(
        /^\d{4}[\D\w]{3}$|^[\D\w]{1,2}\d{4}[\D\\w]{2}$|^[\D\w]{1,2}\d{6}$|^[c|C][\d]{4}[\D\w]{3}$/
      )
    )
    if (!inputValue) {
      easyToast('error', 'Inserte una matrícula')
    } else if (!valid) {
      if (modalError) {
        setModalError(false)
        addLicense()
      } else
        setModalError(
          'La matrícula que ha introducido no cumple un formato estándar, confirme de nuevo para añadirla de todos modos'
        )
    } else if (valid) {
      addLicense()
    }
  }
  const addLicense = () => {
    if (licenses.find(elem => elem.license === inputValue)) {
      easyToast('error', 'Esta matrícula ya ha sido añadida')
    } else {
      setLicenses([...licenses, { license: inputValue }])
      setShowModal(false)
      setInputValue('')
      easyToast('dark', 'Matrícula añadida')
    }
  }

  const handleDelete = rowData => {
    try {
      let aux = [...licenses]
      aux.splice(rowData.tableData.id, 1)

      setLicenses(aux)
      easyToast('dark', 'Matrícula eliminada')
    } catch (error) {
      easyToast('error', 'Error elinando la matrícula')
    }
  }
  return (
    <>
      <MuiTable
        columns={[{ title: 'Matrícula', field: 'license' }]}
        values={licenses}
        deleteAction={true}
        onDelete={handleDelete}
        showToolbar={false}
        toolbarComponent={
          <MyButtonsContainer fullWidth={false} margin="0">
            <MyButton text="Añadir matrícula" onClick={() => setShowModal(true)} />
          </MyButtonsContainer>
        }
        options={{
          grouping: false,
          filtering: false,
          selection: false,
        }}
      />
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        closeAfterAccept={false}
        onAccept={handleModalAccept}
        title="Añadir nueva matrícula"
        alertMessage={modalError}
        content={
          <div className="licenses-table__modal-input">
            <input
              type="text"
              placeholder="MATRÍCULA"
              value={inputValue}
              onChange={e => {
                modalError && setModalError(false)
                setInputValue(e.target.value.toUpperCase())
              }}
            />
          </div>
        }
      />
    </>
  )
}
