import validateLicense from 'myMethods/validateLicense'
import * as Yup from 'yup'

export function TableFilter() {
  return Yup.object().shape({
    dni: Yup.string().matches(/^[A-Z]?\d{5,8}[A-Z]?$/, '   ❌ NO VÁLIDO'),
    likeDni: Yup.string().min(3, '   ❌ MÍNIMO 3 CARACTERES'),
    license: Yup.string()
      .test('is-valid-license', '   ❌ MATRÍCULA INVÁLIDA', value => validateLicense(value))
      .max(15, '   ❌ MATRÍCULA MUY LARGA'),
    likeLicense: Yup.string().min(3, '   ❌ MÍNIMO 3 CARACTERES'),
  })
}
