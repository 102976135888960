import { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setReduxJWT, setReduxUserData } from 'redux/ducks/userDuck'
import { chatSocket } from 'services/Socket'

//SERVICES
import postLoginService from 'services/Login'
import useService from './useService'
import useResources from './useResources'

export default function useLogin() {
  const dispatch = useDispatch()
  const reduxJWT = useSelector(store => store.user.JWT)
  const [state, setState] = useState({ loading: false, error: false, serverError: false })
  const history = useHistory()
  const { roles } = useResources({ roles: true })

  const { fetch: postLogin } = useService({
    service: postLoginService,
    setOutLoading: newValue => setState(prevState => ({ ...prevState, loading: newValue })),
  })

  const login = useCallback(
    values => {
      setState({ loading: true, error: false })
      postLogin(values)
        .then(res => {
          if (!res.auth) {
            return setState(prevState => ({ ...prevState, loading: false, error: true }))
          } else {
            window.sessionStorage.clear()
            window.sessionStorage.setItem('jwt', res.token)
            window.sessionStorage.setItem('role', res.rol)
            window.sessionStorage.setItem('userFullName', res.name)
            window.sessionStorage.setItem('username', res.username)
            const roleName = roles?.find(elem => elem.value === res?.rol)?.label
            dispatch(setReduxJWT(res.token))
            dispatch(
              setReduxUserData({
                id: res.id,
                user: res.user,
                role: res.rol,
                roleName,
                userFullName: res.name,
                personalSettings: res.ajustes_personales,
              })
            )
            setState(prevState => ({ ...prevState, error: false }))
          }
        })
        .catch(err => {
          if (err.response?.status === 404)
            return setState(prevState => ({ ...prevState, error: true }))
          setState(prevState => ({ ...prevState, serverError: true }))
        })
        .finally(() => {
          setState(prevState => ({ ...prevState, loading: false }))
        })
    },
    [dispatch, postLogin]
  )

  const logout = useCallback(
    (jwtExpired = false) => {
      dispatch({
        type: 'LOGOUT',
      })
      if (chatSocket?.connected) {
        chatSocket.emit('userLeave')
      }
      window.sessionStorage.clear()
      dispatch(setReduxJWT(null))
      window.sessionStorage.setItem('justLoggedOut', true)
      jwtExpired && window.sessionStorage.setItem('jwtExpired', true)
      document.title = 'Mint'
      history.push('/')
    },
    [dispatch, history]
  )

  return {
    isLogged: Boolean(reduxJWT),
    isLoginLoading: state.loading,
    hasLoginError: state.error,
    hasServerError: state.serverError,
    login,
    logout,
  }
}
