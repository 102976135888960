import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import handleUnauthorizedResponse from 'myMethods/handleUnauthorizedResponse'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export function postHardwareCreateFile(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/hardware/create`, data, {
    ...getJWT(),
    signal: options.signal,
  })
    .then(res => res)
    .catch(err => {
      if (err.response?.status === 401) {
        handleUnauthorizedResponse()
      }
      throw err
    })
}

export function postHardwareReadFile(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/hardware/read`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function abortHardwareSendFile(data, options = {}) {
  return Axios.delete(`${SERVER_URL}/api/hardware`, {
    ...getJWT(),
    data,
    signal: options.signal,
  })
}

export function putItvHardwareConfig(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}config/hardware`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getComplementaryEquipment(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}config/complementary_equipment`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function postComplementaryEquipment(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}config/complementary_equipment`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putComplementaryEquipment({ internalId, data }, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}config/complementary_equipment/${internalId}`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}
