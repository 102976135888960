import React, { useRef, useState, useEffect } from 'react'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

export default function TextWithHoverIfEllipsis({ children }) {
  const textRef = useRef(null)
  const [isTruncated, setIsTruncated] = useState(false)
  const [textContent, setTextContent] = useState('')

  useEffect(() => {
    const element = textRef.current
    if (element) {
      // Compara el scrollWidth (ancho del contenido) con el clientWidth (ancho visible).
      const isTextTruncated = element.scrollWidth > element.clientWidth
      setIsTruncated(isTextTruncated)
      // Extrae el texto del elemento
      setTextContent(element.textContent)
    }
  }, [])

  return (
    <>
      {!isTruncated && children}
      {isTruncated && (
        <HoverTooltip title={textContent} placement="top">
          {children}
        </HoverTooltip>
      )}
    </>
  )
}
