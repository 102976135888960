//@ts-check
import React, { useState, useEffect, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { List as MenuIcon } from 'react-bootstrap-icons'

// MY IMPORTS
import 'components/Header/Header.scss'
import useLogin from 'hooks/useLogin'
import Logo from 'logo/logo_black.png'
import Avatar from './Avatar/Avatar'
import WarningModal from 'pages/Lobby/WarningModal/WarningModal'
import HeaderBanner from './HeaderBanner/HeaderBanner'
import FillReduxCashRegister from 'components/FillRedux/FillReduxCashRegister'
import FillReduxConfig from 'components/FillRedux/FillReduxConfig'
import GeneralModal from 'components/Others/GeneralModal/GeneralModal'
import FillReduxUser from 'components/FillRedux/FillReduxUser'
import ChatIcon from '../Chat/ChatIcon'
import InspectionDrawer from './InspectionDrawer/InspectionDrawer'
import SidebarMenu from 'components/Others/SidebarMenu/SidebarMenu'
import ChatbotIcon from 'components/MintAI/Chatbot/ChatbotIcon/ChatbotIcon'

/**
 *
 * @typedef {import('mint-cash-types').CashRegisterStatusResponseDTO} CashRegisterStatusResponseDTO
 */

function Header() {
  const { isLogged } = useLogin()
  /**@type {['inspection' | 'supervision' | 'detailed-history' | null, Function]} */
  const [currentPlace, setCurrentPlace] = useState()
  const redux = useSelector(store => store)
  const history = useHistory()
  const renderSidebar = ['inspection', 'supervision'].includes(currentPlace)
  const sidebarRef = useRef()
  const mintAIChatbotEnabled = process.env.REACT_APP_MINTAI_CHATBOT_ENABLED === 'true'
  const chatServiceEnabled = process.env.REACT_APP_CHAT_ENABLED === 'true'

  const renderBeforeLogo = () => (
    <div className="header__before-logo">
      {currentPlace !== 'detailed-history' && (
        <>
          {renderSidebar && (
            <div className="header__sidebar-btn">
              <MenuIcon onClick={sidebarRef?.current?.open} />
            </div>
          )}
          {!renderSidebar && (
            <div
              className="header__home"
              onClick={() => {
                history.replace('/lobby')
              }}
            >
              <i className="flaticon-home" />
            </div>
          )}
        </>
      )}
      {mintAIChatbotEnabled && <ChatbotIcon />}
    </div>
  )

  const renderAfterLogo = () => (
    <div className="header__after-logo">
      {isLogged && chatServiceEnabled && <ChatIcon username={redux?.user?.data?.user} />}
      {history.location.pathname !== '/' &&
        (!isLogged ? (
          <div className="avatar">
            <Link to="/">Iniciar sesión</Link>
          </div>
        ) : (
          <Avatar />
        ))}
    </div>
  )

  useEffect(() => {
    // Comprueba cuando la URL es la de inspección o supervisión
    if (history.location.pathname.match('new_inspection/\\w', 'i')) setCurrentPlace('inspection')
    else if (history.location.pathname.match('new_supervision/\\w', 'i'))
      setCurrentPlace('supervision')
    else if (history.location.pathname.match('detailed-history', 'i'))
      setCurrentPlace('detailed-history')
    else setCurrentPlace(null)
  }, [history.location.pathname])

  history.listen((newLocation, action) => {
    if (currentPlace === 'inspection' && newLocation.pathname === '/lobby' && action === 'POP') {
      window.sessionStorage.setItem('prompt', true)
      history.go(1)
    }

    if (currentPlace === 'supervision' && newLocation.pathname === '/lobby' && action === 'POP') {
      window.sessionStorage.setItem('prompt', true)
      history.go(1)
    }
  })

  return (
    <>
      <section className="header-wrapper">
        <header className="header">
          {isLogged && renderBeforeLogo()}
          <div className="header__logo">
            <img src={Logo} alt="Mint" />
          </div>
          {renderAfterLogo()}
        </header>
        <HeaderBanner />
      </section>
      {renderSidebar && (
        <SidebarMenu ref={sidebarRef}>
          {['inspection', 'supervision'].includes(currentPlace) && (
            <InspectionDrawer
              redux={redux}
              close={sidebarRef?.current?.close ?? (() => {})}
              onSupervision={currentPlace === 'supervision'}
            />
          )}
        </SidebarMenu>
      )}

      {isLogged && (
        <>
          <WarningModal />
          <GeneralModal {...redux?.config?.modal} />
          <FillReduxCashRegister redux={redux} />
          <FillReduxConfig redux={redux} />
          <FillReduxUser redux={redux} />
        </>
      )}
    </>
  )
}

export default React.memo(Header)
