import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setReduxInspectionConfig } from 'redux/ducks/inspectionDuck'
import VelocimetroConfig from './VelocimetroConfig/VelocimetroConfig'

export default function Velocimetro({ sectionNumber, vehicleData, disabled = false }) {
  const [config, setConfig] = useState({
    show: false,
  })
  const reduxConfig = useSelector(store => store.inspection?.config)
  const reduxVelocimeterData = reduxConfig?.velocimeterData
  const [values, setValues] = useState({
    hasGears: reduxVelocimeterData?.hasGears || false,
  })
  const isAvailable = sectionNumber === '2' && vehicleData?.category?.startsWith('L1')
  const dispatch = useDispatch()

  // INICIALIZA MOSTRANDO MODAL SI NO HAY VALORES
  useEffect(() => {
    if (isAvailable && !config.show) {
      setConfig({ ...config, show: true })
    }
  }, [isAvailable, config, reduxVelocimeterData])

  useEffect(() => {
    const newConfig = { ...(reduxConfig || {}), velocimeterData: values }
    dispatch(setReduxInspectionConfig(newConfig))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values])

  return (
    <>
      {config.show && (
        <VelocimetroConfig
          setValues={values => setValues(values)}
          values={values}
          disabled={disabled}
        />
      )}
    </>
  )
}
