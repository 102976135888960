//@ts-check
import React from 'react'
import './ITVCardSelector.scss'
import Select from 'components/Others/Select/Select'
import ITVCardModelsJSON from 'resources/ITVCardModels.json'

/**
 *
 * @param {{selectedCardModel: string, onSelectedCardModelChange: (string) => void, cardModels?: string[]}} param0
 */
export default function ITVCardSelector({
  selectedCardModel,
  onSelectedCardModelChange,
  cardModels,
}) {
  cardModels = cardModels || ITVCardModelsJSON
  return (
    <div className="itv-card-selector">
      <Select
        label="Tipo de Tarjeta ITV"
        placeholder="Seleccione uno..."
        options={cardModels.map(model => ({ value: model, label: model }))}
        value={cardModels
          .map(model => ({ value: model, label: model }))
          .find(model => model.value === selectedCardModel)}
        onChange={opt => {
          onSelectedCardModelChange(opt.value)
        }}
      />
    </div>
  )
}
