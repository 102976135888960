//@ts-check
import { useCallback, useState, useRef } from 'react'
import easyToast from 'components/Others/EasyToast/easyToast'
import isValidFunction from 'myMethods/isValidFunction'
import handleUnauthorizedResponse from 'myMethods/handleUnauthorizedResponse'
import { getInspectionById } from 'services/Inspection'
import { useHistory } from 'react-router-dom'
import InspectionDataMapper from 'dataMappers/InspectionDataMapper'
import { useDispatch, useSelector } from 'react-redux'
import { setReduxInspectionAllData } from 'redux/ducks/inspectionDuck'

/**
 * @template T
 * @param {{service: (...arg0: any[]) => Promise<T>, serviceParams?: any, setOutLoading?: function, handleResponseData?: function, throwMessage?: string, fullResponse?: boolean, notThrowWhenError?: boolean, timeout?: number | null}} param0
 *
 * @returns {{serviceData: T, loading: boolean, fetch: (value: any)=> Promise<T>, status: number | undefined, error: boolean, errorMessage: string | undefined}}
 */
export default function useService({
  service,
  serviceParams,
  setOutLoading,
  handleResponseData,
  throwMessage = '',
  fullResponse = false,
  notThrowWhenError = false,
  timeout = null,
}) {
  const [state, setState] = useState({
    loading: false,
    res: null,
    status: null,
    error: false,
    errorMessage: null,
  })
  const history = useHistory()
  const dispatch = useDispatch()
  // Ref para el AbortController para asegurar que cada uso del hook tenga un controller único
  const abortControllerRef = useRef(null)
  const reduxInspection = useSelector(store => store.inspection)

  const fetchAllInspectionIfNeeded = useCallback(
    async url => {
      try {
        let id = null
        if (history.location.pathname.match('new_inspection/\\w', 'i') || history.location.pathname.match('new_supervision/\\w', 'i'))
          id = window.sessionStorage.getItem('id')

        if (!id || !(url.includes('inspection') || url.includes('supervision'))) return
        console.log('Entra en fetchAllInspectionIfNeeded')
        const res = await getInspectionById({ id })
        if (!res.data) throw new Error('No response data from getInspectionById')
        const reduxData = InspectionDataMapper.fromResponse(res.data, reduxInspection)
        dispatch(setReduxInspectionAllData(reduxData))
      } catch (err) {
        console.error(err)
      }
    },
    [dispatch, history.location.pathname, reduxInspection]
  )

  const fetch = useCallback(
    async (params = {}) => {
      abortControllerRef.current = new AbortController()
      const controller = abortControllerRef.current

      const timeoutId =
        timeout !== null
          ? setTimeout(() => {
              controller.abort()
            }, timeout)
          : null

      try {
        if (!isValidFunction(service))
          throw new Error(`Service is not a valid function: ${service}`)

        console.log('Llamando a servicio: ', service.name)

        setState(prevState => ({ ...prevState, loading: true }))
        if (isValidFunction(setOutLoading)) setOutLoading(true)

        const finalParams = params ?? serviceParams
        const res = await service(finalParams, {
          signal: controller.signal,
        })
        console.log({ serviceRes: res })
        isValidFunction(handleResponseData) && handleResponseData(res.data)
        setState(prevState => ({
          ...prevState,
          res: res.data,
          status: res.status,
          error: false,
          errorMessage: null,
        }))

        await fetchAllInspectionIfNeeded(res?.config?.url)

        return fullResponse ? res : res.data ?? null
      } catch (err) {
        console.error(err)
        console.log('response: ', err.response)
        setState(prevState => ({
          ...prevState,
          res: null,
          status: err.response?.status ?? null,
          error: true,
          errorMessage: err.response?.data.message ?? err.message ?? null,
        }))
        if (err.response?.status === 401) handleUnauthorizedResponse()
        if (err.response?.data?.title === 'INSPECTOR_NOT_AUTHORIZED') {
          const currentUserFullname = err.response?.data?.currentUserFullname
          easyToast(
            'error',
            `No tienes permisos para realizar cambios en la inspección porque ${
              currentUserFullname ? { currentUserFullname } : 'otro inspector'
            } ha tomado la inspección`
          )
        }
        if (throwMessage) easyToast('error', throwMessage)
        if (notThrowWhenError) return fullResponse ? err.response : err.response?.data
        throw err
      } finally {
        setState(prevState => ({ ...prevState, loading: false }))
        if (isValidFunction(setOutLoading)) setOutLoading(false)
        if (timeoutId) clearTimeout(timeoutId)
      }
    },
    [
      service,
      serviceParams,
      setOutLoading,
      handleResponseData,
      fullResponse,
      notThrowWhenError,
      throwMessage,
      timeout,
    ]
  )

  return {
    serviceData: state.res,
    loading: state.loading,
    status: state.status,
    error: state.error,
    errorMessage: state.errorMessage,
    fetch,
  }
}
