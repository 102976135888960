import React, { useState, useEffect } from 'react'
import './CashFlow.scss'
import { useSelector } from 'react-redux'
import { postCashRegisterCashFlow } from 'services/CashRegister'
import easyToast from 'components/Others/EasyToast/easyToast'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import moment from 'moment'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Select from 'components/Others/Select/Select'

const initialState = {
  amount: '',
  reason: null,
}

export default function CashFlows({
  status,
  refetch,
  selectedDate,
  selectedDateIsToday,
  isChanging,
}) {
  const cashFlowsReasons = useSelector(store => store.config.itv?.accounting?.cashFlowsReasons)
  const [options, setOptions] = useState([])
  const [state, setState] = useState(initialState)
  const data = status?.arching?.CashFlows
  const wasOpen = status?.wasOpen
  const [loading, setLoading] = useState(false)

  const validateForm = () => {
    let amount = parseFloat(state.amount)

    if (!amount) return { valid: false, err: 'Inserte una cantidad' }
    if (!state.reason?.name) return { valid: false, err: 'Seleccione un concepto' }
    if (typeof amount !== 'number') return { valid: false, err: 'La cantidad debe ser un número' }
    if (state.reason.negative && amount > 0)
      return { valid: false, err: 'Este concepto no permite una cantidad positiva' }
    if (!state.reason.negative && amount < 0)
      return { valid: false, err: 'Este concepto no permite una cantidad negativa' }
    return { valid: true, err: null }
  }

  const submit = async () => {
    const validation = validateForm()
    if (!validation.valid) return easyToast('error', validation.err)
    try {
      setLoading(true)
      await postCashRegisterCashFlow({
        amount: parseFloat(state.amount),
        reason: state.reason.name,
        cashId: status?.id,
        dateTime: selectedDateIsToday ? undefined : selectedDate,
      })
      easyToast('dark', 'Movimiento realizado correctamente')
      refetch()
      setState(initialState)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error. Inténtelo de nuevo')
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    const restrictedMovements = ['INICIO', 'FIN DE CAJA']
    setOptions(
      cashFlowsReasons
        ?.filter(
          elem =>
            elem.show &&
            (!restrictedMovements.includes(elem.name) ||
              (restrictedMovements.includes(elem.name) &&
                !data?.find(el => el.concepto === elem.name)))
        )
        .map(elem => {
          return {
            ...elem,
            label: elem.name,
            value: elem.name,
          }
        }) || []
    )
  }, [cashFlowsReasons, data])

  useEffect(() => {
    const amount = parseFloat(state.amount) || 0
    const isCorrectSign = () => (state.reason?.negative ? Boolean(amount < 0) : Boolean(amount > 0))
    if (state.reason && state.amount && !isCorrectSign()) {
      setState({ ...state, amount: state.reason.negative ? -Math.abs(amount) : Math.abs(amount) })
    }
  }, [state])

  return (
    <>
      {loading && <ChildSpinner />}
      {!loading && (!status || !Object.keys(status).length > 0) && (
        <div className="no-values">Vaya... parece que aún no tienes asignada una caja</div>
      )}
      {!loading && !isChanging && status && Object.keys(status).length > 0 && (
        <section className="cash-flows">
          <>
            {!wasOpen && (
              <div className="no-values">
                {selectedDateIsToday
                  ? 'La caja debe abrirse para hacer movimientos'
                  : `La caja debió abrirse el ${moment(selectedDate).format(
                      'DD/MM/YYYY'
                    )} para poder hacer movimientos ahora`}
              </div>
            )}
            {wasOpen && (
              <>
                <div className="cash-flows__form">
                  <div className="cash-flows__input">
                    <label>Cantidad (€)</label>
                    <input
                      type="number"
                      value={state.amount}
                      onChange={e => setState({ ...state, amount: e.target.value })}
                    />
                  </div>
                  <Select
                    name="reason"
                    options={options}
                    value={options?.find(elem => elem.name === state.reason?.value) || ''}
                    onChange={opt => {
                      setState({ ...state, reason: opt })
                    }}
                  />
                </div>
                <MyButtonsContainer>
                  <MyButton text="AÑADIR MOVIMIENTO" onClick={submit} />
                </MyButtonsContainer>
              </>
            )}
          </>
        </section>
      )}
    </>
  )
}
