//@ts-check
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { useDispatch } from 'react-redux'
import {
  setReduxFormState,
  setReduxInspectionData,
  setReduxVehicleData,
} from 'redux/ducks/newInspectionDuck'
import 'components/Office/VehicleForm/VehicleForm.scss'
import Input from 'components/Others/Input/Input'
import parseFormValues from 'myMethods/parseFormValues'
import easyToast from 'components/Others/EasyToast/easyToast'
import Spinner from 'components/Others/Spinner/Spinner'
import OfficeFormState from 'components/Others/OfficeFormState/OfficeFormState'
import ReformModal from '../ReformModal/ReformModal'
import Modal from 'components/Others/Modal/Modal'
import { VehicleNewInspection as formSchema } from 'formSchemas/Vehicle'
import { Person as SubmitIcon } from '@mui/icons-material'
import { getVehicleByLicense } from 'services/Vehicles'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { setReduxConfigModal, setReduxConfigFilters } from 'redux/ducks/configDuck'
import ClassificationValidator from 'Share/ClassificationValidator'
import Filters from 'Share/Filters'
import useService from 'hooks/useService'
import Select from 'components/Others/Select/Select'
import useResources from 'hooks/useResources'

/**
 * @typedef {import('types/Vehicle').Vehicle} Vehicle
 */

/**
 * Formulario para la gestión de vehículos en una inspección.
 *
 * @param {Object} props
 * @param {Object} props.redux - Estado de Redux relacionado con el nuevo vehículo.
 * @returns {JSX.Element} Componente de formulario de vehículo.
 */
export default function VehicleForm({ redux }) {
  const dispatch = useDispatch()
  const reduxNewVehicle = redux?.newVehicle
  const [vehicleValues, setVehicleValues] = useState(/** @type {Vehicle} */ ({}))
  const [classificationValidator, setClassificationValidator] = useState({
    isValid: false,
    text: '',
  })
  const clsInput = useRef(null)
  const form = useRef(null)
  const [reformModal, setReformModal] = useState({
    show: false,
    reforms: null,
    vehicleData: null,
  })
  const isReform = Boolean(reduxNewVehicle.inspectionData.inspectionType?.includes('REFORMA'))
  const { fetch: fetchVehicle, loading } = useService({
    service: getVehicleByLicense,
  })
  const { fuels, countries, categories } = useResources({
    fuels: true,
    countries: true,
    categories: true,
  })
  const fuels1 = fuels?.fuels1 ?? []
  const fuels2 = fuels?.fuels2 ?? []

  const initialValuesClear = useMemo(
    () => ({
      license: reduxNewVehicle?.inspectionData?.license,
      classification: '',
      category: '',
      frame: reduxNewVehicle?.inspectionData?.frame,
      homologation: '',
      licenseDate: '',
      licenseDate2: '',
      brand: '',
      model: '',
      fuel1: '',
      fuel2: '',
      type: '',
      expirationDate: '',
      country: countries?.find(elem => elem.label.toLowerCase().includes('españa'))?.value || '',
      lastStation: '',
    }),
    [reduxNewVehicle?.inspectionData]
  )

  const handleInitialValues = useCallback(() => {
    if (!reduxNewVehicle.vehicleData) {
      return Object.keys(vehicleValues).length
        ? parseFormValues(vehicleValues, true)
        : initialValuesClear
    } else {
      return parseFormValues(reduxNewVehicle.vehicleData, true)
    }
  }, [reduxNewVehicle.vehicleData, vehicleValues, initialValuesClear])

  const handleClassification = useCallback(() => {
    try {
      const value = clsInput.current.value
      const validation = new ClassificationValidator(value).validate()
      setClassificationValidator({ isValid: validation.isValid, text: validation.text })
    } catch (err) {
      console.error(err)
    }
  }, [])

  const checkCompatibility = useCallback(
    values => {
      try {
        return Boolean(
          new Filters({
            filters: redux?.config?.filters,
            category: values.category,
            classification: values.classification,
          })?.filter
        )
      } catch (err) {
        easyToast(
          'error',
          'Ha ocurrido un error verificando la compatibilidad de la categoría y la clasificación'
        )
        return false
      }
    },
    [redux?.config?.filters]
  )

  const handleSubmit = useCallback(
    values => {
      if (!checkCompatibility(values))
        return dispatch(
          setReduxConfigModal({
            message:
              'No hay ningún filtro de inspección con esa combinación de categoría y clasificación. Consulte al supervisor responsable',
            alertMessage:
              'Si el supervisor ha realizado cambios, pulse para refrescar el filtro de inspección',
            content: (
              <MyButtonsContainer>
                <MyButton
                  text="Refrescar filtro"
                  icon={<i className="flaticon-filter" />}
                  onClick={() => {
                    dispatch(setReduxConfigFilters({}))
                    dispatch(setReduxConfigModal(null))
                    easyToast('dark', 'Filtro refrescado')
                  }}
                />
              </MyButtonsContainer>
            ),
          })
        )
      if (!isReform || (isReform && reformModal.reforms)) {
        values = parseFormValues(values)
        dispatch(setReduxVehicleData({ ...values, pais: reduxNewVehicle.inspectionData.pais }))
        dispatch(setReduxFormState(2))
      } else {
        setReformModal({ show: true, id: null, vehicleData: values })
      }
    },
    [
      checkCompatibility,
      dispatch,
      isReform,
      reformModal.reforms,
      reduxNewVehicle.inspectionData.pais,
    ]
  )

  useEffect(() => {
    if (!Object.keys(vehicleValues).length && !reduxNewVehicle.vehicleData) {
      fetchVehicle(reduxNewVehicle.inspectionData.license)
        .then(res => {
          if (res) {
            let newValues = JSON.parse(JSON.stringify(initialValuesClear))
            Object.keys(newValues).forEach(key => {
              if (key === 'dni') return (newValues[key] = res.holder?.dni || '')
              return (newValues[key] = res[key] || '')
            })
            newValues = { ...newValues, dni: res.holder?.dni || null }
            setVehicleValues(newValues)

            easyToast('dark', 'Vehículo encontrado. Verifique los datos')
          } else {
            easyToast(
              'dark',
              'Esta matrícula nunca ha sido registrada en esta ITV, inserte los datos manualmente'
            )
          }
        })
        .catch(err => {
          console.error(err)
          if (err?.response?.status === 400) {
            easyToast('error', 'Error cargando los datos del vehículo')
          }
        })
    }
  }, [
    reduxNewVehicle.inspectionData,
    reduxNewVehicle.vehicleData,
    dispatch,
    fetchVehicle,
    initialValuesClear,
    vehicleValues,
  ])

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleClassification()
    }, 500)

    return () => clearTimeout(timeout)
  }, [handleClassification])

  return (
    <>
      <Spinner visible={loading} />
      <Formik
        enableReinitialize
        innerRef={form}
        initialValues={handleInitialValues()}
        validationSchema={() => formSchema(classificationValidator.isValid)}
        onSubmit={values => {
          handleSubmit(values)
        }}
      >
        {({ setFieldValue, handleChange, values, setFieldTouched, errors, touched }) => (
          <div className="vehicle-form">
            <OfficeFormState
              onClick={() => {
                dispatch(setReduxFormState(0))
              }}
            />
            <Form className="vehicle-form__form">
              <div className="vehicle-form__license">
                {vehicleValues.license || reduxNewVehicle.inspectionData.license}
              </div>
              <div className="form-input vehicle-form__clasif-container">
                <label>
                  Clasificación
                  <ErrorMessage className="form-error" name="classification" component="small" />
                </label>
                <div className="vehicle-form__clasif">
                  <Field
                    innerRef={clsInput}
                    onKeyUp={e => {
                      handleChange(e)
                      handleClassification()
                    }}
                    name="classification"
                    placeholder="0000"
                  />
                  <div className="vehicle-form__clasif-text">
                    {classificationValidator.text || '-'}
                  </div>
                </div>
              </div>

              <Select
                label="Categoría"
                ErrorComponent={
                  <ErrorMessage className="form-error" name="category" component="small" />
                }
                name="category"
                placeholder="Escribe para buscar..."
                options={categories}
                value={categories?.find(elem => elem.value === values.category) || ''}
                onChange={opt => {
                  setFieldValue('category', opt ? opt.value : '')
                }}
                onBlur={() => setFieldTouched('category', true)}
                error={errors.category}
                touched={touched.category}
              />

              <Input className="form-input" label="Marca" name="brand" />
              <Input className="form-input" label="Modelo" name="model" />

              <Input className="form-input" label="Tipo" name="type" />
              <Input className="form-input" label="Bastidor" name="frame" />
              <Input
                className="form-input"
                label="Homologación"
                name="homologation"
                style={{ textTransform: 'none' }}
              />

              <Input
                className="form-input"
                label="Fecha caducidad inspección"
                name="expirationDate"
                type="date"
              />

              <Select
                label="Combustible 1"
                ErrorComponent={
                  <ErrorMessage className="form-error" name="fuel1" component="small" />
                }
                name="fuel1"
                placeholder="Escribe para buscar..."
                options={fuels1}
                value={fuels1?.find(elem => elem.value.toUpperCase() === values.fuel1) || ''}
                onChange={opt => {
                  setFieldValue('fuel1', opt ? opt.value : '')
                }}
                onBlur={() => setFieldTouched('fuel1', true)}
                error={errors.fuel1}
                touched={touched.fuel1}
              />

              <Select
                label="Combustible 2"
                ErrorComponent={
                  <ErrorMessage className="form-error" name="fuel2" component="small" />
                }
                name="fuel2"
                options={fuels2}
                value={fuels2?.find(elem => elem.value.toUpperCase() === values.fuel2) || ''}
                onChange={opt => {
                  setFieldValue('fuel2', opt ? opt.value : '')
                }}
                onBlur={() => setFieldTouched('fuel2', true)}
                error={errors.fuel2}
                touched={touched.fuel2}
              />

              <Select
                label="País"
                ErrorComponent={
                  <ErrorMessage className="form-error" name="country" component="small" />
                }
                name="country"
                options={countries}
                value={countries?.find(elem => elem.value === values.country) || ''}
                onChange={opt => {
                  setFieldValue('country', opt ? opt.value : '')
                }}
                onBlur={() => setFieldTouched('country', true)}
                error={errors.country}
                touched={touched.country}
              />
              <Input
                className="form-input"
                label="Fecha 1º matriculación"
                name="licenseDate"
                type="date"
              />
              <Input
                className="form-input"
                label="Fecha 2º matriculación"
                name="licenseDate2"
                type="date"
              />
              <Input
                className="form-input"
                label="Última estación"
                name="lastStation"
                type="number"
              />

              <MyButtonsContainer fullWidth={false} className="vehicle-form__btn">
                <MyButton
                  text="Ir a titular"
                  type="submit"
                  icon={<SubmitIcon />}
                  onClick={() => {}}
                />
              </MyButtonsContainer>
            </Form>
          </div>
        )}
      </Formik>
      <Modal
        className="vehicle-form__reform-modal"
        open={reformModal.show}
        onClose={() => setReformModal({ ...reformModal, show: false })}
        title="Seleccione reformas"
        onAccept={() => {
          if (reformModal.reforms) {
            dispatch(
              setReduxInspectionData({
                ...reduxNewVehicle.inspectionData,
                config: { ...reduxNewVehicle.inspectionData.config, reforms: reformModal.reforms },
              })
            )
            handleSubmit(reformModal.vehicleData)
          } else easyToast('error', 'Seleccione al menos una reforma')
        }}
        closeAfterAccept={false}
        content={
          <ReformModal
            filters={redux?.config?.filters}
            vehicleData={reformModal.vehicleData}
            setReforms={reforms => setReformModal({ ...reformModal, reforms })}
          />
        }
      />
    </>
  )
}
