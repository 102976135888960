import React from 'react'

// MY IMPORTS
import './SonometroConfig.scss'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import TestConfigWrapper from '../../../TestConfigWrapper/TestConfigWrapper'

export default function SonometroConfig({
  setValues,
  values,
  boxCorrectionFactor,
  disabled = false,
}) {
  const setStrokeValue = stroke => {
    setValues({ ...values, rpm: stroke === 2 ? 4000 : stroke === 4 ? 3000 : null, stroke })
  }
  return (
    <TestConfigWrapper
      testName="Sonómetro"
      message2="Solo marcar 2 tiempos o 4 tiempos si no existen valores de referencia"
      disabled={disabled}
    >
      <div className="sonometro-modal">
        <div className="sonometro-modal__checkboxes">
          <div className="sonometro-modal__strokes">
            <Checkbox
              label="2 tiempos"
              checked={values.stroke === 2}
              onChange={value => setStrokeValue(value ? 2 : null)}
              disabled={disabled}
            />
            <Checkbox
              label="4 tiempos"
              checked={values.stroke === 4}
              onChange={value => setStrokeValue(value ? 4 : null)}
              disabled={disabled}
            />
          </div>
          <div className="sonometro-modal__dual-exhaust">
            <Checkbox
              label="Doble escape"
              checked={values.dualExhaust}
              onChange={value => setValues({ ...values, dualExhaust: value })}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="sonometro-modal__input-container">
          <label data-aos="fade-left">LÍMITE db(A) (U.1)</label>
          <input
            type="number"
            inputMode="numeric"
            name="db"
            placeholder="LÍMITE Db(A)"
            value={values?.db}
            onChange={e => {
              setValues({ ...values, db: e.target.value })
            }}
            disabled={disabled}
          />
          {<div className="sonometro-modal__input-unit">db(A)</div>}
        </div>
        <div className="sonometro-modal__input-container">
          <label data-aos="fade-left">RPM REFERENCIA (U.2)</label>
          <input
            type="number"
            inputMode="numeric"
            name="rpm"
            placeholder="RPM"
            value={values?.rpm}
            disabled={values?.stroke || disabled}
            onChange={e => {
              setValues({ ...values, rpm: e.target.value })
            }}
          />
          {<div className="sonometro-modal__input-unit">RPM</div>}
        </div>
        <div className="sonometro-modal__input-container">
          <label data-aos="fade-left">FACTOR CORRECCIÓN BOX</label>
          <input value={boxCorrectionFactor ?? 0} disabled={true} />
        </div>
      </div>
    </TestConfigWrapper>
  )
}
