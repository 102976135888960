import React, { useRef, useState } from 'react'

// MY IMPORTS
import 'components/Inspection/DefectsSupervisor/MachineDefectDetail/MachineDefectDetail.scss'
import calificationToText from 'myMethods/calificationToText'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import formatAndTrimText from 'myMethods/formatAndTrimText'
import easyToast from 'components/Others/EasyToast/easyToast'
import {
  deleteInspectionRemark as deleteInspectionRemarkService,
  postInspectionRemark as postInspectionRemarkService,
  putInspectionRemark as putInspectionRemarkService,
  recalcInspectionMachineDefect as recalcInspectionMachineDefectService,
} from 'services/Inspection'
import Id from 'Share/Id'
import Modal from 'components/Others/Modal/Modal'
import ErrorView from 'components/Others/ErrorView/ErrorView'
import { useDispatch, useSelector } from 'react-redux'
import { Plus as AddIcon } from 'react-bootstrap-icons'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import isValidFunction from 'myMethods/isValidFunction'
import MachineDefectRecalc from './MachineDefectRecalc'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import useService from 'hooks/useService'
import Input from 'components/Others/Input/Input'
/**
 * @typedef {import('types/Inspection').MachineDefect} MachineDefect
 */

/**
 * updateMachineDefects solo se utiliza en MachineDefectsAppliedModal
 *
 * @param {{defectId: string, machineDefects: MachineDefect[],maxRemarkLength: number | undefined, updateMachineDefects: Function | undefined, sectionNumber: string, letModify:boolean}} param0
 * @returns
 */
export default function MachineDefectDetail({
  defectId,
  machineDefects,
  maxRemarkLength,
  sectionNumber,
  vehicleData,
  inspectionType,
  updateMachineDefects,
  letModify = true,
}) {
  const redux = useSelector(store => store)
  const remarkInputRef = useRef(null)
  const [remarkModal, setRemarkModal] = useState({ show: false, type: null })
  const handleRemarkClick = type => {
    if (['add', 'edit'].includes(type)) setRemarkModal({ show: true, type })
  }
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const { fetch: recalcInspectionMachineDefect } = useService({
    service: recalcInspectionMachineDefectService,
    setOutLoading: setLoading,
  })
  const { fetch: postInspectionRemark } = useService({
    service: postInspectionRemarkService,
    setOutLoading: setLoading,
  })
  const { fetch: deleteInspectionRemark } = useService({
    service: deleteInspectionRemarkService,
    setOutLoading: setLoading,
  })
  const { fetch: putInspectionRemark } = useService({
    service: putInspectionRemarkService,
    setOutLoading: setLoading,
  })

  try {
    /**@type {MachineDefect} */
    const machineDefect = machineDefects?.find(elem => elem.defect.id === defectId)

    const handleRemarkAccept = async () => {
      try {
        if (!['add', 'edit'].includes(remarkModal.type))
          throw new Error('remark modal type is not a chance')
        let text = remarkInputRef.current.value
        text = formatAndTrimText(text)
        if (!text) return easyToast('error', 'Inserte una observación')
        if (remarkModal.type === 'add') {
          await postInspectionRemark({
            id: Id.decrypt(window.sessionStorage.getItem('id')),
            defectRemark: {
              defectId: machineDefect.defect.id,
              remark: text,
            },
          })
          easyToast('dark', 'Observación añadida correctamente')
        } else if (remarkModal.type === 'edit') {
          await putInspectionRemark({
            id: Id.decrypt(window.sessionStorage.getItem('id')),
            defectRemark: {
              defectId: machineDefect.defect.id,
              remark: text,
            },
          })
        }
        if (isValidFunction(updateMachineDefects)) updateMachineDefects()
        setRemarkModal({ show: false, type: null })
      } catch (err) {
        console.error(err)
        easyToast('error', 'Ha ocurrido un error guardando la observación')
      }
    }

    const remove = async () => {
      try {
        await deleteInspectionRemark({
          id: Id.decrypt(window.sessionStorage.getItem('id')),
          defectRemark: {
            defectId: defectId,
          },
        })

        easyToast('dark', 'Observación eliminada')
      } catch (err) {
        console.error(err)
        easyToast('error', 'Ha ocurrido un error eliminando el defecto')
      }
    }

    const recalc = async recalcData => {
      try {
        await recalcInspectionMachineDefect({
          id: Id.decrypt(window.sessionStorage.getItem('id')),
          defectId,
          recalcData,
        })
        easyToast('dark', `Defecto ${defectId} recalculado correctamente`)
      } catch (err) {
        easyToast('error', 'Ha ocurrido un error recalculando el defecto')
      }
    }
    return (
      <>
        {loading && <ChildSpinner visible={true} tight={false} />}
        {!loading && (
          <>
            {!machineDefect && (
              <div className="machine-defect-detail">
                <div className="no-values">No se ha encontrado el defecto seleccionado</div>
              </div>
            )}
            {machineDefect && (
              <div className="machine-defect-detail">
                {redux?.user?.data?.user !== machineDefect?.user && (
                  <div className="modal-defect__user">
                    Prueba realizada por {machineDefect.user}
                  </div>
                )}
                <div className="machine-defect-detail__info">
                  <div>{machineDefect.defect.id}</div>
                  <span>{machineDefect.defect.name}</span>
                </div>
                <ListItemsInTwoColumns>
                  <CardNameValue name="TEST" value={machineDefect.testName?.toUpperCase()} />
                  <CardNameValue
                    name="CALIFICACIÓN"
                    value={calificationToText(machineDefect?.calification)}
                  />
                  <CardNameValue
                    name="VALOR"
                    value={`${machineDefect.value ?? '-'} ${
                      machineDefect.value != null ? machineDefect.unit || '' : ''
                    }`}
                    className="machine-defect-detail__value"
                  />
                  <CardNameValue
                    name="VALOR REQUERIDO"
                    value={`${machineDefect.sill ?? '-'} ${
                      machineDefect.sill != null ? machineDefect.unit || '' : ''
                    }`}
                    className="machine-defect-detail__sill"
                  />
                  <CardNameValue
                    name="Lugar/es del defecto"
                    value={machineDefect.places.join(',')}
                  />
                  <CardNameValue
                    name="OBSERVACIÓN"
                    value={
                      <>
                        {machineDefect.remark?.text
                          ? `“${machineDefect.remark?.text}”`
                          : 'SIN OBSERVACIÓN'}
                        {letModify && (
                          <MyButtonsContainer>
                            {!machineDefect.remark && (
                              <MyButton
                                text="AÑADIR OBSERVACIÓN"
                                onClick={() => handleRemarkClick('add')}
                                icon={<AddIcon />}
                              />
                            )}

                            {machineDefect.remark && (
                              <MyButton
                                text="EDITAR OBSERVACIÓN"
                                onClick={() => handleRemarkClick('edit')}
                              />
                            )}
                            {machineDefect.remark && (
                              <MyButton
                                text="ELIMINAR OBSERVACIÓN"
                                warningColor={true}
                                onClick={() => {
                                  dispatch(
                                    setReduxConfigModal({
                                      title: `Eliminar observación de defecto ${machineDefect.defect.id}`,
                                      hasAcceptButton: true,
                                      closeAfterAccept: true,
                                      onAccept: remove,
                                    })
                                  )
                                }}
                              />
                            )}
                          </MyButtonsContainer>
                        )}
                      </>
                    }
                  />
                </ListItemsInTwoColumns>
                <MachineDefectRecalc
                  machineDefect={machineDefect}
                  sectionNumber={sectionNumber}
                  vehicleData={vehicleData}
                  inspectionType={inspectionType}
                  recalc={recalc}
                />
                <Modal
                  open={remarkModal?.show}
                  onClose={() => setRemarkModal({ show: false, type: null })}
                  title={'AÑADIR OBSERVACIÓN A DEFECTO ' + machineDefect?.defect?.id || ''}
                  closeAfterAccept={false}
                  onAccept={handleRemarkAccept}
                  hasAcceptButton={true}
                  loading={loading}
                  content={
                    <Input
                      isFormikInput={false}
                      type="textarea"
                      inputRef={remarkInputRef}
                      defaultValue={machineDefect?.remark?.text || ''}
                      autofocus={true}
                      maxLength={maxRemarkLength || 40}
                    />
                  }
                />
              </div>
            )}
            <Modal
              open={remarkModal?.show}
              onClose={() => setRemarkModal({ show: false, type: null })}
              title={'AÑADIR OBSERVACIÓN A DEFECTO ' + machineDefect?.defect?.id || ''}
              closeAfterAccept={false}
              onAccept={handleRemarkAccept}
              hasAcceptButton={true}
              loading={loading}
              content={
                <Input
                  isFormikInput={false}
                  inputRef={remarkInputRef}
                  type="textarea"
                  defaultValue={machineDefect?.remark?.text || ''}
                  autofocus={true}
                  maxLength={maxRemarkLength || 40}
                />
              }
            />
          </>
        )}
      </>
    )
  } catch (error) {
    console.error(error)
    return <ErrorView />
  }
}
