//@ts-check
import moment from 'moment'

/**
 *
 * @param {*} values
 * @param {boolean?} isInput
 * @returns
 */
export default function parseFormValues(values, isInput = false, toUpperCase = false) {
  const exceptions = ['homologation', 'category']
  if (!values) return {}
  const newValues = JSON.parse(JSON.stringify(values))
  try {
    if (!isInput) {
      Object.keys(newValues).forEach(key => {
        if (
          typeof newValues[key] === 'string' &&
          !['[', '{'].includes(newValues[key].substring(0, 1))
        ) {
          if (exceptions.includes(key)) {
            return (newValues[key] = newValues[key] || '')
          }
          if (key === 'telefono' && newValues[key] === '') {
            return (newValues[key] = null)
          }
          if (
            typeof newValues[key] === 'string' &&
            newValues[key].length === 10 &&
            moment(newValues[key], 'YYYY-MM-DD', true).isValid()
          ) {
            let date = moment(newValues[key])
            let offset = date.utcOffset()
            let adjustedDate = date.add(offset, 'minutes')
            return (newValues[key] = adjustedDate.toISOString())
          }
          if (toUpperCase) {
            newValues[key] = newValues[key]?.toUpperCase()
          }
          return (newValues[key] = newValues[key]?.trim() || '')
        }
      })
    } else {
      Object.keys(newValues).forEach(key => {
        if (
          typeof newValues[key] === 'string' &&
          newValues[key].length === 24 &&
          moment(newValues[key], moment.ISO_8601, true).isValid()
        ) {
          console.log('La variable es una fecha: ', newValues[key])
          return (newValues[key] = moment(newValues[key]).format('YYYY-MM-DD'))
        }
        if (toUpperCase) {
          newValues[key] = newValues[key]?.toUpperCase()
        }
        return newValues[key]
      })
    }
    return newValues
  } catch (err) {
    console.error(err)
    return newValues
  }
}
