//@ts-check
import React from 'react'
import Title from 'components/Others/Title/Title'
import ListOfRemarks from 'components/Inspection/Remarks/ListOfRemarks'
import Remarks from 'components/Inspection/Remarks/Remarks'
import { useSelector } from 'react-redux'

export default function RemarksPage() {
  const redux = useSelector(store => store)
  return (
    <>
      <Title text="OBSERVACIONES" />
      <ListOfRemarks />
      {/* <Remarks redux={redux} /> */}
    </>
  )
}
