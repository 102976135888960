import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
const DATA_EXPORTER_URL = process.env.REACT_APP_DATA_EXPORTER

export function exportToPdf(data, options = {}) {
  return Axios.post(`${DATA_EXPORTER_URL}/pdf`, data, {
    headers: { ...getJWT().headers },
    responseType: 'blob',
    signal: options.signal,
  })
}

export function exportToCsv(data, options = {}) {
  return Axios.post(`${DATA_EXPORTER_URL}/csv`, data, {
    headers: { ...getJWT().headers },
    signal: options.signal,
  })
}
