import React, { useState, useEffect } from 'react'

// SERVICES
import { getAllInspections as getAllInspectionsService } from 'services/Inspection'

// MY IMPORTS
import Modal from 'components/Others/Modal/Modal'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import useService from 'hooks/useService'
import easyToast from 'components/Others/EasyToast/easyToast'

export default function OngoingInspections() {
  const [rowClicked, setRowClicked] = useState(null)
  const [showModal, setShowModal] = useState(false)

  const {
    serviceData: inspections,
    fetch: getAllInspections,
    loading,
  } = useService({
    service: getAllInspectionsService,
  })

  useEffect(() => {
    if (!inspections)
      getAllInspections().catch(err =>
        easyToast('error', 'Ha ocurrido un error cargando las inspecciones en curso')
      )
  }, [getAllInspections, inspections])

  return (
    <>
      <MuiTable
        values={inspections}
        options={{ search: true, grouping: false, selection: false }}
        loading={loading}
        columnsKey="ongoingInspections"
      />
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title={rowClicked?.matricula && 'Inspección en curso a ' + rowClicked.matricula}
        onAccept={() => {}}
        closeAfterAccept={false}
        closeOnOverlay={false}
      />
    </>
  )
}
