import React, { useState } from 'react'
import Modal from 'components/Others/Modal/Modal'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import Switch from 'components/Others/Switch/Switch'
import easyToast from 'components/Others/EasyToast/easyToast'
import './NewComplementaryEquipmentModal.scss'

export default function NewComplementaryEquipmentModal({ handleAccept, closeModal }) {
  const [values, setValues] = useState({
    name: '',
    id: '',
    serialNumber: '',
    active: true,
  })

  const submit = () => {
    try {
      if (!values.name) return easyToast('error', 'El equipo debe tener un nombre')
      if (!values.id) return easyToast('error', 'El equipo debe tener un ID')
      handleAccept(values)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Los valores introducidos no son válidos')
    }
  }
  return (
    <Modal
      open={true}
      closeOnOverlay={false}
      closeAfterAccept={false}
      onClose={closeModal}
      onAccept={submit}
      title="Nuevo equipo complementario"
      content={
        <div className="hardware-by-line__hardware new-complementary-equipment-modal">
          <ListItemsInTwoColumns className="hardware-by-line__hardware-inputs-container">
            <CardNameValue
              name="Activo"
              value={
                <Switch
                  value={values.active}
                  onChange={checked => setValues({ ...values, active: checked })}
                />
              }
            />
            <CardNameValue
              name="Nombre"
              value={
                <input
                  id="name"
                  value={values.name}
                  onChange={e => setValues({ ...values, name: e.target.value })}
                />
              }
            />
            <CardNameValue
              name="ID"
              value={
                <input
                  id="id"
                  value={values.id}
                  onChange={e => setValues({ ...values, id: e.target.value })}
                  maxLength={8}
                />
              }
            />
            <CardNameValue
              name="Número de serie"
              value={
                <input
                  value={values.serialNumber}
                  onChange={e => setValues({ ...values, serialNumber: e.target.value })}
                />
              }
            />
          </ListItemsInTwoColumns>
        </div>
      }
    />
  )
}
