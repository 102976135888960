import Axios from 'axios'
import InvoiceTypeResponse from 'types/InvoiceTypeResponse'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_BILLING_URL

export function getInvoicesByChunk(params, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}invoices`, {
    ...getJWT(),
    params: params ?? {},
    signal: options.signal,
  }).then(res => {
    console.log({ res })
    if (res.data?.data) {
      res.data = InvoiceTypeResponse.fromGetAllInvoices(res.data)
      return res
    }
  })
}

export function getDealsByChunk(params, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}receipts`, {
    ...getJWT(),
    params: { ...(params ?? {}), query: 'series:deal OR series:noPriceDeal' },
    signal: options.signal,
  }).then(res => {
    res.data = InvoiceTypeResponse.fromGetAllInvoices(res.data)
    return res
  })
}

export function getInvoiceData({ invoiceId }, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}receipts/${invoiceId}`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getInvoiceDraft(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}invoices/preview`, data, {
    ...getJWT(),
    responseType: 'blob',
    signal: options.signal,
  })
}

export function getDealInvoiceDraft(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}invoices/deal_invoice/preview`, data, {
    ...getJWT(),
    responseType: 'blob',
    signal: options.signal,
  })
}

export function getDealInvoicesPreviewByDateRange(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}invoices/deal_invoice/preview/dates`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getInvoicePDF(invoiceInfo, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}receipts/${invoiceInfo.invoiceId}/pdf`, {
    ...getJWT(),
    responseType: 'blob',
    signal: options.signal,
  })
}

export function getLastCorrectiveDate(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}invoices/corrective/last_date`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function postCorrectiveInvoice(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}invoices/corrective`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function postCorrectiveInvoicePreview(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}invoices/corrective/preview`, data, {
    ...getJWT(),
    responseType: 'blob',
    signal: options.signal,
  })
}

export function postDealInvoice(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}invoices/deal_invoice`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function postCancelInvoice(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}invoices/cancelled`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putAlterInvoice(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}invoices`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putAbortAlterInvoiceVehicle({ serie, invoiceId }, options = {}) {
  return Axios.put(
    `${SERVER_URL}/api/${v2}invoices/${serie}/${invoiceId}/vehicle/cancel`,
    {},
    {
      ...getJWT(),
      signal: options.signal,
    }
  )
}

export function putRevertCancelledInvoice({ invoiceId, serie }, options = {}) {
  return Axios.put(
    `${SERVER_URL}/api/${v2}invoice/${serie}/${invoiceId}/revert`,
    {},
    {
      ...getJWT(),
      signal: options.signal,
    }
  )
}

export function putRefundMethodToCreditCard(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}invoices/cancelled/${data.invoiceId}/payment`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}
