//@ts-check
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import easyToast from 'components/Others/EasyToast/easyToast'
import Switch from 'components/Others/Switch/Switch'
import useService from 'hooks/useService'
import React, { useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { setReduxInspectionComplementaryEquipment } from 'redux/ducks/inspectionDuck'
import {
  addComplementaryEquipmentToInspection as addComplementaryEquipmentToInspectionService,
  deleteComplementaryEquipmentFromInspection as deleteComplementaryEquipmentFromInspectionService,
} from 'services/Inspection'
import Id from 'Share/Id'
import './ComplementaryEquipment.scss'

/**
 * @typedef {import('types/ComplementaryEquipment').ComplementaryEquipment} ComplementaryEquipment
 * @typedef {import('types/Inspection').ComplementaryEquipment} ComplementaryEquipmentInUse
 */

/**
 *
 * @param {{complementaryEquipment: ComplementaryEquipment[], complementaryEquipmentInUse: ComplementaryEquipmentInUse[]}} param0
 * @returns
 */
export default function ComplementaryEquipment({
  complementaryEquipment,
  complementaryEquipmentInUse = [],
}) {
  const dispatch = useDispatch()
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const { fetch: addComplementaryEquipmentToInspection } = useService({
    service: addComplementaryEquipmentToInspectionService,
  })
  const { fetch: deleteComplementaryEquipmentFromInspection } = useService({
    service: deleteComplementaryEquipmentFromInspectionService,
  })

  const toggleComplementaryEquipment = async (internalId, currentActive) => {
    try {
      if (!internalId || currentActive == null)
        throw new Error('No internalId or currentActive provided')
      if (currentActive) {
        await deleteComplementaryEquipmentFromInspection({
          inspectionId: Id.decrypt(window.sessionStorage.getItem('id')),
          internalId,
        })
      } else {
        await addComplementaryEquipmentToInspection({
          inspectionId: Id.decrypt(window.sessionStorage.getItem('id')),
          internalId,
        })
      }
    } catch (err) {
      console.error(err)
      easyToast(
        'error',
        `Ha ocurrido un error ${
          currentActive ? 'eliminando' : 'añadiendo'
        } el equipo complementario`
      )
    } finally {
      forceUpdate()
    }
  }
  return (
    <>
      {complementaryEquipment?.length > 0 && (
        <div className="list-container">
          {complementaryEquipment
            .sort((a, b) => b.active - a.active)
            .map((item, i) => {
              const inUse = complementaryEquipmentInUse?.some(
                ce => ce.equipment?.internalId === item.internalId
              )
              console.log({ item, inUse })
              return (
                <div
                  className={
                    item.active
                      ? 'list-content complementary-equipment__item'
                      : 'list-content complementary-equipment__item--inactive'
                  }
                  key={i}
                >
                  <CardNameValue
                    className="complementary-equipment__name"
                    name="Nombre"
                    value={item.name ?? ''}
                  />
                  <CardNameValue name="ID" value={item.id ?? ''} />
                  <CardNameValue name="Número de serie" value={item.serialNumber ?? ''} />
                  <div>
                    {item.active ? (
                      <CardNameValue
                        name="Usado"
                        value={
                          <Switch
                            value={inUse}
                            onChange={() => toggleComplementaryEquipment(item.internalId, inUse)}
                          />
                        }
                      />
                    ) : (
                      <span>Este equipo no está activo</span>
                    )}
                  </div>
                </div>
              )
            })}
        </div>
      )}
    </>
  )
}
