import KNumberFormatter from 'components/Others/KNumberFormatter/KNumberFormatter'
import React from 'react'
import './StatisticsListCard.scss'

/**
 *
 * @param {{title: string | undefined, items: {name, value}[], unit: string | undefined, ...rest: any}} param0
 */
export default function StatisticsListCard({ title, items, unit = '', ...rest }) {
  return (
    <div className="statistics-list-card" {...rest}>
      {title && <div className="statistics-list-card__title">{title}</div>}
      {items?.length > 0 && (
        <div className="statistics-list-card__list">
          {items.map(({ name, value }, i) => (
            <div className="statistics-list-card__list-elem" key={i}>
              <div>{name}</div>
              <KNumberFormatter number={value} unit={unit} animate={true} />
            </div>
          ))}
        </div>
      )}
      {!items?.length > 0 && (
        <div className="statistics-list-card__no-values">
          <i className="flaticon-not-found" />
          <div>Sin datos</div>
        </div>
      )}
    </div>
  )
}
