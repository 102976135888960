//@ts-check
import React, { useCallback, useEffect, useState } from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material'
import moment from 'moment'
import MaterialTable, { MTableToolbar } from '@material-table/core'

// MY IMPORTS
import './MuiTable.scss'
import materialTableIcons from 'components/Others/MuiTable/materialTableIcons'
import materialTableTheme from 'components/Others/MuiTable/materialTableTheme'
import ExportationModal from '../ExportationModal/ExportationModal'
import isValidFunction from 'myMethods/isValidFunction'
import TableFilterValues from './TableFilterValues/TableFilterValues'
import useTableColumns from 'hooks/useTableColumns'

/**
 * @param {object} param0
 * @param {any[] | undefined} param0.columns
 * @param {import('types/TableColumnsKeys').TableColumnsKeys | undefined} param0.columnsKey
 * @param {boolean} param0.loading
 * @param {function | undefined} param0.onRowClick
 * @param {React.ReactElement | undefined} param0.tableFilter
 * @param {function | undefined} param0.onClearTableFilter
 * @param {any[]} param0.values
 * @param {import('types/FilterServiceParams').FilterServiceParams | null | undefined} param0.filterValues
 * @param {boolean | undefined} param0.sortByDate
 * @param {object | undefined} param0.options
 * @param {boolean | undefined} param0.singleSelection
 * @param {string | undefined} param0.singleSelectionLabel
 * @param {any | undefined} param0.singleSelectionIcon
 * @param {import('types/ExportationModal').Exportation | undefined} param0.exportation
 * @param {function | undefined} param0.onSingleSelectionClick
 * @param {function | undefined} param0.onSelectionChange
 * @param {string | undefined} param0.singleSelectionHoverText
 * @param {string | undefined} param0.title
 * @param {boolean | undefined} param0.deleteAction
 * @param {function | undefined} param0.onDelete
 * @param {any[] | undefined} param0.moreActions
 * @param {boolean | undefined} param0.showToolbar
 * @param {any | undefined} param0.toolbarComponent
 * @param {string | undefined} param0.tableRef
 * @param {string | undefined} param0.noDataMessage
 *
 * @returns
 */
export default function MatTable({
  columns: columnsProps,
  columnsKey,
  loading = false,
  onRowClick,
  tableFilter,
  onClearTableFilter,
  values,
  filterValues,
  sortByDate = true,
  options,
  singleSelection = false,
  singleSelectionLabel = 'Seleccionar',
  onSingleSelectionClick,
  singleSelectionIcon,
  singleSelectionHoverText = '',
  onSelectionChange,
  exportation,
  title = '',
  deleteAction = false,
  onDelete,
  moreActions,
  showToolbar = true,
  toolbarComponent,
  tableRef,
  noDataMessage = 'No hay datos',
  ...rest
}) {
  const [exportModal, setExportModal] = useState({ show: false, data: null })
  const [hasSelectedRows, setHasSelectedRows] = useState(false)
  const [columns, setColumns] = useState(null)
  const tableIcons = materialTableIcons()
  const tableTheme = materialTableTheme()
  const actions = []
  const { getColumns } = useTableColumns()

  const actionsFunctions = {
    export: useCallback((e, data) => setExportModal({ show: true, data }), []),
    singleSelection: useCallback(
      (e, rowData) => {
        e.stopPropagation()
        isValidFunction(onSingleSelectionClick) && onSingleSelectionClick(rowData)
      },
      [onSingleSelectionClick]
    ),
  }

  if (exportation) {
    actions.push({
      icon: tableIcons.Export,
      tooltip: 'Exportar selección',
      onClick: actionsFunctions.export,
    })
    actions.push({
      icon: tableIcons.Export,
      tooltip: 'Exportar',
      onClick: actionsFunctions.export,
      isFreeAction: true,
    })
  }
  if (singleSelection && singleSelectionIcon)
    actions.push({
      icon: () => <span>{singleSelectionIcon ?? singleSelectionLabel}</span>,
      tooltip: singleSelectionHoverText,
      onClick: actionsFunctions.singleSelection,
      position: 'row',
    })

  if (deleteAction) {
    actions.push({
      icon: tableIcons.Delete,
      tooltip: 'Eliminar',
      onClick: (e, rowData) => isValidFunction(onDelete) && onDelete(rowData),
      position: 'row',
    })
  }
  if (moreActions && moreActions.length) {
    actions.push(...moreActions)
  }

  useEffect(() => {
    if (!columns) {
      if (columnsKey) {
        const newColumns = getColumns(columnsKey)
        setColumns(newColumns ?? columnsProps ?? [])
      } else {
        setColumns(columnsProps ?? [])
      }
    }
  }, [columns, columnsKey, columnsProps, getColumns])

  return (
    <div className="mui-table">
      {tableFilter != null && (
        <>
          <TableFilterValues filterValues={filterValues} onClearTableFilter={onClearTableFilter} />
          {tableFilter}
        </>
      )}
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={tableTheme}>
          <MaterialTable
            tableRef={tableRef}
            icons={tableIcons}
            columns={columns ?? []}
            data={
              sortByDate
                ? values?.sort(
                    (a, b) =>
                      moment(b.fecha || b.date).valueOf() - moment(a.fecha || a.date).valueOf()
                  )
                : values
            }
            isLoading={loading}
            title={title}
            onRowClick={(e, rowData) => {
              if (window.getSelection()?.type === 'Range') return
              isValidFunction(onRowClick) && onRowClick(rowData)
            }}
            onSelectionChange={totalSelection => {
              isValidFunction(onSelectionChange) && onSelectionChange(totalSelection)
              if (totalSelection?.length && !hasSelectedRows) return setHasSelectedRows(true)
              if (!totalSelection.length && hasSelectedRows) return setHasSelectedRows(false)
            }}
            actions={actions}
            options={{
              pageSizeOptions: [],
              pageSize: 25,
              emptyRowsWhenPaging: false,
              padding: 'dense',
              draggable: true,
              maxBodyHeight: '450px',
              selection: true,
              filtering: true,
              grouping: true,
              search: false,
              fixedHeader: true,
              fixedColumns: {
                header: true,
              },
              ...options,
            }}
            localization={{
              toolbar: {
                searchPlaceholder: 'Buscar...',
                searchTooltip: 'Buscar',
                nRowsSelected: useCallback(
                  rows => `${rows} fila${rows > 1 ? 's' : ''} seleccionada${rows > 1 ? 's' : ''}`,
                  []
                ),
              },
              pagination: {
                labelDisplayedRows: '{from}-{to} de {count}',
                firstTooltip: 'Primera página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Página siguiente',
                lastTooltip: 'Última página',
                labelRowsPerPage: '',
              },
              grouping: {
                placeholder: 'Arrastra aquí encabezados para agrupar...',
                groupedBy: 'Agrupado por:',
              },
              body: {
                emptyDataSourceMessage: loading ? '' : noDataMessage,
                filterRow: {
                  filterTooltip: 'Filtrar columna',
                },
              },
              header: {
                actions: '',
              },
            }}
            components={{
              Toolbar: useCallback(
                props => (
                  <div>
                    {(showToolbar || hasSelectedRows) && <MTableToolbar {...props} />}
                    {toolbarComponent && typeof toolbarComponent === 'object' && (
                      <div className="mui-table__toolbar">{toolbarComponent}</div>
                    )}
                  </div>
                ),
                [hasSelectedRows, showToolbar, toolbarComponent]
              ),
            }}
            {...rest}
          />
        </ThemeProvider>
      </StyledEngineProvider>

      {exportation && (
        <ExportationModal
          show={exportModal.show}
          closeModal={() => setExportModal({ show: false, data: null })}
          data={{
            ...exportation?.data,
            values:
              exportModal.data == null || !Object.keys(exportModal.data).length
                ? exportation?.data?.values
                : exportModal.data,
          }}
          type={exportation?.type}
          defaultInputValue={exportation?.defaultInputValue}
          options={exportation?.options}
          pdf={exportation?.pdf}
          csv={exportation?.csv}
          modalTitle={exportation?.modalTitle}
        />
      )}
    </div>
  )
}
