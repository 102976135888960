//@ts-check
import isValidFunction from 'myMethods/isValidFunction'
import React, { useState } from 'react'
import { selectStyles } from 'components/Others/Select/SelectStyles'
import ReactSelect, { components } from 'react-select'
import Creatable from 'react-select/creatable'
import './Select.scss'
import easyToast from '../EasyToast/easyToast'

/**
 * Renderiza un componente Select personalizado con etiquetas opcionales y gestión de errores. Soporta opciones simples
 * o agrupadas.
 *
 * @param {Object} props - Propiedades del componente Select.
 * @param {string=} [props.className]
 * @param {string=} [props.id]
 * @param {(Array<{value: string | number, label: string}>|Array<{label: string, options: Array<{value: string | number, label: string}>}>)} props.options - Opciones del select disponibles para elegir. Puede ser un arreglo de objetos para opciones simples, donde cada objeto tiene una propiedad 'label' para el texto visible y una propiedad 'value' para el valor interno. Alternativamente, puede ser un arreglo de objetos para opciones agrupadas, donde cada objeto tiene una propiedad 'label' para el nombre del grupo y una propiedad 'options' que es un arreglo de objetos con propiedades 'label' y 'value'.
 * @param {Object|null} props.value - Valor actual seleccionado en el Select.
 * @param {function(Object|null): void} props.onChange - Función que se llama cuando el valor del Select cambia.
 * @param {string} [props.label] - Etiqueta de texto para el Select.
 * @param {string=} props.name - Nombre del Select, usado para identificar el componente en formularios.
 * @param {function(): void} [props.onBlur] - Función que se llama cuando el Select pierde foco.
 * @param {React.Component|React.Element} [props.ErrorComponent] - Componente que se renderiza para mostrar errores.
 * @param {string} [props.noOptionsText='No hay opciones'] - Texto a mostrar cuando no hay opciones disponibles.
 * @param {boolean} [props.isDisabled=false] - Indica si el Select debe estar deshabilitado.
 * @param {string} [props.placeholder='Escribe para buscar...'] - Texto del marcador de posición en el Select.
 * @param {boolean} [props.isSearchable=true] - Si tiene un buscador.
 * @param {boolean} [props.isClearable=true] - Si se puede hacer clear de la selección.
 * @param {boolean} [props.isMulti=false] - Habilita la selección múltiple.
 * @param {any} [props.error] - Estado de error del componente Select.
 * @param {boolean} [props.touched] - Indica si el Select ha sido manipulado o tocado.
 * @param {boolean} [props.isCreatable=false] - Indica si el Select debe ser creatable.
 * @param {boolean} [props.checkRepeated=true] - Verifica si el valor a añadir ya existe
 * @param {function(string): boolean} [props.handleCreateOption] - Función que se llama cuando se crea una nueva opción. Debe devolver un booleano. Si es false, no se añadirá el valor
 * @param {string=} [props.createLabel='Añadir '] - Texto a mostrar en el label de añadir opción nueva
 * @param {boolean=} [props.letDropdown=true] - Permitir desplegar las opciones
 * @param {function(string): void} [props.formatAddedOption] - Función que se llama para formatear el nuevo valor a añadir.
 * @param {boolean} [props.loading] - Indica si el Select está en estado de carga.
 * @returns {React.Element} El componente Select con la configuración y comportamiento especificados.
 *
 */

export default function Select({
  className,
  id,
  options: initialOptions,
  value,
  onChange,
  label,
  name,
  onBlur,
  ErrorComponent,
  noOptionsText = 'No hay opciones',
  isDisabled = false,
  placeholder = 'Escribe para buscar...',
  isSearchable = true,
  isClearable = true,
  isMulti = false,
  error,
  touched,
  isCreatable = false,
  checkRepeated = true,
  handleCreateOption,
  createLabel = 'Añadir ',
  letDropdown = true,
  formatAddedOption,
  loading,
}) {
  const [options, setOptions] = useState(initialOptions || [])

  const handleCreate = inputValue => {
    try {
      if (formatAddedOption && isValidFunction(formatAddedOption)) {
        inputValue = formatAddedOption(inputValue)
      }
      if (handleCreateOption && !handleCreateOption(inputValue)) return
      if (checkRepeated && options.some(option => option.label === inputValue)) return
      const newOption = { label: inputValue, value: inputValue }
      setOptions([...options, newOption])
      onChange(isMulti ? [...(value || []), newOption] : newOption)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error añadiendo el nuevo valor')
    }
  }
  const SelectComponent = isCreatable ? Creatable : ReactSelect

  const customComponents = {
    DropdownIndicator: letDropdown ? components.DropdownIndicator : () => null,
  }

  return (
    <div
      className={`select-container ${letDropdown ? '' : 'select-container--no-dropdown'} ${
        className ?? ''
      }`}
      id={id}
    >
      {label && (
        <label>
          {label}
          {ErrorComponent && ErrorComponent}
        </label>
      )}
      <SelectComponent
        noOptionsMessage={() => noOptionsText}
        menuPortalTarget={document.body}
        styles={selectStyles}
        name={name}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMulti={isMulti}
        isDisabled={loading || isDisabled}
        placeholder={placeholder}
        options={options}
        value={value}
        onChange={onChange}
        onBlur={() => {
          isValidFunction(onBlur) && onBlur()
        }}
        isLoading={loading}
        error={Boolean(error)}
        touched={touched}
        onCreateOption={isCreatable ? handleCreate : undefined}
        formatCreateLabel={inputValue => `${createLabel}"${inputValue}"`}
        components={customComponents}
      />
    </div>
  )
}
