import { useState, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setReduxInspectionImagesSrc } from 'redux/ducks/inspectionDuck'
import {
  deleteInspectionImage,
  getInspectionImageByFilename,
  putInspectionImage,
} from 'services/Inspection'
import useService from 'hooks/useService'
import Id from 'Share/Id'
import easyToast from 'components/Others/EasyToast/easyToast'
import moment from 'moment'

const useInspectionImages = ({ type, defectId, testName }) => {
  const [images, setImages] = useState([])
  const [initialLoading, setInitialLoading] = useState(true)
  const reduxInspection = useSelector(store => store.inspection)
  const dispatch = useDispatch()

  const rawImages = useMemo(() => {
    return reduxInspection?.images?.filter(image => {
      if (type == null) return true
      if (type === 'defect' && !defectId) return false
      if (defectId) return image.type === 'defect' && image.defectId === defectId
      if (type === 'testMachine' && !testName) return false
      if (testName) return image.type === 'testMachine' && image.testName === testName
      return image.type === type
    })
  }, [reduxInspection, type, defectId, testName])

  const imagesSrc = reduxInspection?.imagesSrc

  const { fetch: getImage, loading: loadingGet } = useService({
    service: getInspectionImageByFilename,
  })
  const { fetch: deleteImage } = useService({
    service: deleteInspectionImage,
  })
  const { fetch: putImage, loading: loadingUpload } = useService({
    service: putInspectionImage,
  })

  const removeImage = async filename => {
    try {
      await deleteImage({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        filename,
      })
      setImages(images => images.filter(image => image.filename !== filename))
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error eliminando la imagen')
    }
  }

  const uploadImage = ({ image, geolocation, type = 'general', testName, defectId, label }) => {
    try {
      if (testName) type = 'testMachine'
      if (defectId) type = 'defect'
      if (!label) label = type

      const imageDate = moment().format('YYYY-MM-DD HH:mm:ss')
      const img = new Image()
      const imageUrl = URL.createObjectURL(image)

      img.onload = async () => {
        try {
          const width = img.naturalWidth
          const height = img.naturalHeight

          let fd = new FormData()
          fd.append('upload_image', image)
          fd.append('id', Id.decrypt(window.sessionStorage.getItem('id')))
          fd.append(
            'metadata',
            JSON.stringify({
              date: imageDate,
              geolocation: {
                latitude: geolocation.latitude,
                longitude: geolocation.longitude,
              },
              dimensions: { width, height },
            })
          )
          fd.append('type', type)
          fd.append('label', label)
          if (testName) {
            fd.append('testName', testName)
          }
          if (defectId) {
            fd.append('defectId', defectId)
          }
          await putImage(fd)
        } catch (err) {
          easyToast('error', 'Ha ocurrido capturando la imagen. Inténtelo de nuevo')
        }
      }

      img.src = imageUrl
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido capturando la imagen. Inténtelo de nuevo')
    }
  }

  useEffect(() => {
    if (rawImages?.length) {
      const newImages = rawImages.map((image, index) => {
        const existingImage = images.find(img => img.filename === image.filename)
        const imageData = {
          ...image,
          caption: image.label,
          tags: [{ value: image.label, title: image.label }],
          width: image.metadata?.dimensions?.width,
          height: image.metadata?.dimensions?.height,
          downloadFilename: reduxInspection.reportNumber + '-' + (index + 1) + '.jpg',
          error: existingImage ? existingImage.error : false,
        }
        if (!imagesSrc?.[image.filename]) {
          return {
            ...imageData,
            loading: true,
            filename: image.filename,
          }
        } else {
          return {
            ...imageData,
            loading: false,
            filename: image.filename,
            src: imagesSrc[image.filename],
          }
        }
      })

      setImages(newImages)
    }
  }, [rawImages, imagesSrc, reduxInspection.reportNumber])

  useEffect(() => {
    if (loadingGet) return
    const imageToFetch = images.find(image => image.loading && !image.src && !image.error)
    if (!imageToFetch) return setInitialLoading(false)

    const fetchImage = async () => {
      try {
        console.log(`Haciendo fetch de imagen: ${imageToFetch.filename}`)
        const res = await getImage({
          id: Id.decrypt(window.sessionStorage.getItem('id')),
          filename: imageToFetch.filename,
        })
        const blob = new Blob([res], { type: 'image/webp' })
        const src = URL.createObjectURL(blob)

        setImages(prevState =>
          prevState.map(image =>
            image.filename === imageToFetch.filename ? { ...image, loading: false, src } : image
          )
        )
        dispatch(setReduxInspectionImagesSrc({ ...imagesSrc, [imageToFetch.filename]: src }))
      } catch (err) {
        setImages(prevState =>
          prevState.map(image =>
            image.filename === imageToFetch.filename
              ? { ...image, loading: false, error: true }
              : image
          )
        )
        console.error(err)
      } finally {
        setInitialLoading(false)
      }
    }

    fetchImage()
  }, [loadingGet, images, getImage, dispatch, imagesSrc])

  return {
    images,
    deleteImage: removeImage,
    uploadImage,
    loadingImages: initialLoading,
    loadingUpload,
  }
}

export default useInspectionImages
