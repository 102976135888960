import classifications from 'resources/classifications.json'

/**
 * @param {string} classification
 *
 * @returns {{isValid: boolean, text: string}}
 */
export default class ClassificationValidator {
  constructor(classification) {
    this.classification = classification?.toString()
  }

  validate(full = true, errorText = 'INSERTE UNA CLASIFICACIÓN VÁLIDA') {
    try {
      const value = this.classification
      if (!value) return { isValid: false, text: errorText ?? '' }
      let string = ''
      if (value && ((full && value.length === 4) || (!full && [2, 4].includes(value.length)))) {
        const firstDigits = value.substring(0, 2)
        const lastDigits = value.substring(2, 4)
        const obj1 = classifications.first.find(elem => elem.number === parseInt(firstDigits))
        const obj2 = classifications.second.find(elem => elem.number === parseInt(lastDigits))

        if ((full && obj1 && obj2) || (!full && obj1)) {
          // Se valida el input manualmente y se reemplazan caracteres extraños
          string = obj1.name.replace('<=', '≤')
          if (obj2) string += ` ${obj2.name.replace('<=', '≤')}`
          return { isValid: true, text: string }
        } else {
          return { isValid: false, text: errorText ?? '' }
        }
      } else {
        return { isValid: false, text: errorText ?? '' }
      }
    } catch (err) {
      console.error(err)
      return { isValid: false, text: errorText ?? '' }
    }
  }
}
