import React, { useState } from 'react'
import Select from 'react-select'
import { selectStyles } from 'components/Others/Select/SelectStyles'
import prelicenseJSON from 'resources/prelicenseInspections.json'
import { Checkbox } from '@mui/material'
import 'components/Office/PrelicenseModal/PrelicenseModal.scss'

export default function PrelicenseModal({ setPrelicense }) {
  const [checked, setChecked] = useState(false)
  const [value, setValue] = useState(null)
  try {
    const options = prelicenseJSON?.map(elem => {
      return { value: elem.name, label: elem.name, cocNeeded: elem.cocNeeded, id: elem.id }
    })

    return (
      <div>
        <Select
          noOptionsMessage={() => 'No hay opciones'}
          menuPortalTarget={document.body}
          styles={selectStyles}
          isSearchable
          isClearable
          placeholder="Escribe para buscar..."
          options={options}
          value={value}
          onChange={opt => {
            setValue(opt)
            setPrelicense({ value: opt?.value || null, coc: checked, id: opt?.id })
          }}
        />
        {value && value.cocNeeded && (
          <div className="prelicense-modal__coc">
            <Checkbox
              name="idem"
              color="secondary"
              checked={checked}
              onChange={e => {
                setChecked(e.target.checked)
                setPrelicense({ value: value?.label || null, coc: e.target.checked })
              }}
            />
            <span>Presenta COC</span>
          </div>
        )}
      </div>
    )
  } catch (err) {
    console.error(err)
    return 'Ha ocurrido un error cargando las reformas. Recargue la página'
  }
}
