import Title from 'components/Others/Title/Title'
import History from 'components/Supervision/History/History'
import React from 'react'

export default function HistoryPage() {
  return (
    <>
      <Title text="HISTORIAL" />
      <History />
    </>
  )
}
