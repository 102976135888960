export default function formatAndTrimText(text, upperCase = false) {
  try {
    if (!text) return ''
    let newText = text.replace(/(\r\n|\n|\r)/gm, '').trim()
    if (upperCase) newText = newText.toUpperCase()
    return newText
  } catch (err) {
    console.error(err)
    return text
  }
}
