//@ts-check

import isAsyncFunction from 'myMethods/isAsyncFunction'
import isValidFunction from 'myMethods/isValidFunction'
import vibrate from 'myMethods/vibrate'
import React, { useCallback, useEffect } from 'react'
import MyButton from '../Buttons/MyButton/MyButton'
import MyButtonsContainer from '../Buttons/MyButtonsContainer'

/**
 * ModalButtons Component
 * @param {Object} props - Component props
 * @param {boolean} [props.hasCloseButton] - Should the modal have a close button
 * @param {Function} [props.onCloseButton] - Function to call when the close button is clicked
 * @param {Function} [props.onClose] - Function to call when the modal is closed
 * @param {boolean} [props.hasAcceptButton] - Should the modal have an accept button
 * @param {Function} [props.onAccept] - Function to call when the accept button is clicked
 * @param {boolean} [props.closeAfterAccept] - Should the modal close after accepting
 * @param {Function} [props.setInternalLoading] - Function to set the internal loading state
 * @param {JSX.Element} [props.acceptButtonIcon] - Icon for the accept button
 * @param {JSX.Element} [props.closeButtonIcon] - Icon for the close button
 * @param {string} [props.acceptButtonText] - Text for the accept button
 * @param {string} [props.closeButtonText] - Text for the close button
 * @param {boolean} [props.acceptOnEnter] - Should accept on enter key
 * @returns {JSX.Element} ModalButtons component
 */
export default function ModalButtons({
  hasCloseButton = true,
  onCloseButton,
  onClose,
  hasAcceptButton = true,
  onAccept,
  closeAfterAccept = true,
  setInternalLoading,
  acceptButtonIcon,
  closeButtonIcon,
  acceptButtonText = '',
  closeButtonText = '',
  acceptOnEnter = true,
}) {
  const handleAccept = useCallback(async () => {
    vibrate()
    if (onAccept && isValidFunction(onAccept)) {
      const isAsync = isAsyncFunction(onAccept)
      if (isAsync) setInternalLoading(true)
      await onAccept()
      if (isAsync) setInternalLoading(false)
    }
    if (closeAfterAccept) onClose()
  }, [closeAfterAccept, onAccept, onClose, setInternalLoading])

  useEffect(() => {
    const handleKeyPress = e => {
      if (e.key === 'Enter' && acceptOnEnter && hasAcceptButton) {
        handleAccept()
      }
    }

    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [handleAccept, acceptOnEnter, hasAcceptButton])

  return (
    <MyButtonsContainer className="modal__btn-container" modal={true}>
      {hasCloseButton && (
        <MyButton
          className="modal__close-btn"
          text={closeButtonText}
          icon={closeButtonIcon}
          warningColor={true}
          onClick={() => {
            vibrate()
            if (onCloseButton) {
              onCloseButton()
            } else if (onClose) {
              onClose()
            }
          }}
        />
      )}
      {hasAcceptButton && (
        <MyButton text={acceptButtonText} icon={acceptButtonIcon} onClick={handleAccept} />
      )}
    </MyButtonsContainer>
  )
}
