export default function isValidFunction(func) {
  try {
    if (func && typeof func === 'function') {
      return true
    }
    return false
  } catch (err) {
    console.error(err)
    return false
  }
}
