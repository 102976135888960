import React, { useState, useEffect } from 'react'
import { Download } from '@mui/icons-material'
import './BillingExportModal.scss'
// SERVICES
import {
  getInvoicesExport as getInvoicesExportService,
  postInvoicesExport as postInvoicesExportService,
} from 'services/BillingExport'

// MY IMPORTS
import Modal from 'components/Others/Modal/Modal'
import isValidFunction from 'myMethods/isValidFunction'
import easyToast from 'components/Others/EasyToast/easyToast'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import RangeCalendar from 'components/Others/RangeCalendar/RangeCalendar'
import isValidDateRange from 'myMethods/isValidDateRange'
import downloadFile from 'myMethods/downloadFile'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import { NoteAdd } from '@mui/icons-material'
import BillingExportModalClients from './BillingExportModalClients/BillingExportModalClients'
import { getDealInvoicesPreviewByDateRange } from 'services/Billing'
import DealInvoicePreviewModal from './DealInvoicePreviewModal/DealInvoicePreviewModal'
import useService from 'hooks/useService'

export default function BillingExportModal({ closeModal, refetchData }) {
  const [clients, setClients] = useState([])
  const [dates, setDates] = useState(null)
  const [includeExported, setIncludeExported] = useState(false)
  const [refetchCalendar, setRefetchCalendar] = useState(true)
  const [dealInvoicesPreviewModal, setDealInvoicesPreviewModal] = useState({
    show: false,
    data: null,
  })
  const [loading, setLoading] = useState({
    invoices: false,
    remittances: false,
    dealInvoices: false,
  })

  const { fetch: fetchDealInvoicesPreviewByDateRangeAndDnis } = useService({
    service: getDealInvoicesPreviewByDateRange,
    setOutLoading: value => setLoading(prevState => ({ ...prevState, dealInvoices: value })),
  })

  const { serviceData: daysExported, fetch: getInvoicesExport } = useService({
    service: getInvoicesExportService,
  })

  const { fetch: postInvoicesExport } = useService({
    service: postInvoicesExportService,
  })

  const downloadInvoices = async () => {
    try {
      const { start, end } = dates
      console.log({ start, end })
      if (!isValidDateRange([start, end])) return easyToast('error', 'Las fechas no son válidas')
      setLoading({ ...loading, invoices: true })

      const res = await postInvoicesExport({
        range: {
          start,
          end,
          all: includeExported,
        },
        listDni: clients.map(client => client.dni) || null,
      })
      if (res && Object.keys(res).length && res.data) {
        Object.entries(res.data).forEach(([name, data]) => {
          console.log({ name, data })
          if (data)
            downloadFile(
              data,
              `${name}_${start}_${end}${res.extension ? '.' + res.extension : ''}`,
              'string'
            )
        })
      } else throw new Error()
    } catch (err) {
      console.error(err)
      console.error(err?.response)
      if (err.response?.status === 422) {
        if (err.response.data.message.includes('No hay facturas para exportar con dicha condición'))
          return easyToast(
            'error',
            `No se han encontrado facturas para exportar con las condiciones seleccionadas${
              includeExported ? '' : '. Pruebe incluyendo lo ya exportado'
            }`
          )
      }
      easyToast('error', 'Ha ocurrido un error descargando las facturas')
    } finally {
      setLoading({ ...loading, invoices: false })
      setRefetchCalendar(true)
    }
  }
  const getDealInvoicesPreview = async () => {
    try {
      if (!(dates.start && dates.end))
        return easyToast('error', 'Seleccione una fecha de inicio y otra de fin')
      const res = await fetchDealInvoicesPreviewByDateRangeAndDnis({
        startDate: dates.start,
        endDate: dates.end,
        listDni: clients.map(client => client.dni) || null,
      })
      if (!res || !res.length)
        return easyToast(
          'error',
          `No hay ningún albarán sin facturar en esta fecha ${
            clients?.length ? ' para los clientes seleccionados' : ''
          }`
        )
      setDealInvoicesPreviewModal({ show: true, data: res })
    } catch (error) {
      easyToast('error', 'Ha ocurrido un error generando las facturas de fin de mes')
    }
  }

  useEffect(() => {
    if (refetchCalendar) {
      setRefetchCalendar(false)
      getInvoicesExport().catch(() => {
        easyToast('error', 'Ha ocurrido un error detectando los días que ya han sido exportados')
      })
    }
  }, [getInvoicesExport, refetchCalendar])

  return (
    <>
      <Modal
        open={true}
        onClose={() => isValidFunction(closeModal) && closeModal()}
        title="Exportación y fin de mes"
        message={!dates && 'Selecciona un rango de fechas para exportar'}
        hasAcceptButton={false}
        content={
          <div className="billing-export-modal">
            <RangeCalendar
              onChange={setDates}
              markedDates={daysExported}
              markedDatesLegendName="Días ya exportados"
            />
            {dates && (
              <>
                <BillingExportModalClients clients={clients} setClients={setClients} />
                <div className="billing-export-modal__include-exported">
                  <Checkbox
                    label="Incluir ya exportado"
                    checked={includeExported}
                    onChange={value => setIncludeExported(value)}
                  />
                </div>
                <MyButtonsContainer margin="1rem auto 0 auto">
                  <MyButton
                    text="Exportar"
                    icon={<Download />}
                    onClick={downloadInvoices}
                    loading={loading.invoices}
                  />
                  <MyButton
                    text="Descargar remesas"
                    icon={<Download />}
                    loading={loading.remittances}
                  />
                  <MyButton
                    text="Generar facturas fin de mes"
                    icon={<NoteAdd />}
                    onClick={getDealInvoicesPreview}
                    loading={loading.dealInvoices}
                  />
                </MyButtonsContainer>
              </>
            )}
          </div>
        }
      />
      {dealInvoicesPreviewModal?.show && (
        <DealInvoicePreviewModal
          data={dealInvoicesPreviewModal?.data}
          closeModal={() => setDealInvoicesPreviewModal({ show: false, data: null })}
          refetchData={refetchData}
        />
      )}
    </>
  )
}
