import React, { useState, useEffect } from 'react'
import ClassificationValidator from 'Share/ClassificationValidator'
import easyToast from '../EasyToast/easyToast'
import ErrorView from '../ErrorView/ErrorView'
import Modal from '../Modal/Modal'
import { Add as AddIcon, Clear as ClearIcon } from '@mui/icons-material'
import './ClassificationsSelector.scss'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

export default function ClassificationsSelector({
  classifications,
  closeModal,
  handleAccept,
  loading,
}) {
  const [values, setValues] = useState(classifications)
  const [inputValue, setInputValue] = useState(null)
  const [text, setText] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [tooltipText, setTooltipText] = useState(null)

  const handleInputChange = value => {
    try {
      setInputValue(value)
      const validation = new ClassificationValidator(value).validate(false)
      setIsValid(validation.isValid)
      setText(validation.text || 'INSERTE UNA CLASIFICACIÓN')
    } catch (err) {
      console.error(err)
    }
  }

  const addClassification = () => {
    try {
      const validation = new ClassificationValidator(inputValue).validate(false)
      if (!validation.isValid) throw new Error('Invalid classification')
      if (values.includes(inputValue))
        return easyToast('error', 'Esta clasificación ya ha sido agregada')
      setValues([...values, inputValue])
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error al agregar la clasificación')
      console.error(err)
    }
  }

  const deleteClassification = value => {
    try {
      let newValues = [...values]
      newValues.splice(newValues.indexOf(value), 1)
      setValues(newValues)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error al eliminar la clasificación')
    }
  }

  useEffect(() => {
    if (classifications?.length && !values) {
      setValues(classifications)
    }
  }, [classifications, values])

  try {
    return (
      <Modal
        open={true}
        onClose={closeModal}
        title="Editar clasificaciones"
        closeOnOverlay={false}
        onAccept={() => handleAccept(values)}
        loading={loading}
        hasAcceptButton={JSON.stringify(classifications?.sort()) !== JSON.stringify(values?.sort())}
        closeAfterAccept={false}
        content={
          <div className="classifications-selector">
            <div className="classifications-selector__new">
              <div className="classifications-selector__input">
                <label>Nueva clasificación</label>
                <span>
                  Si inserta solo los dos primeros dígitos se incluirán también todas las variantes
                  de esa clasificación
                </span>
                <div>
                  <input
                    type="number"
                    placeholder="0000"
                    value={inputValue}
                    onChange={e => handleInputChange(e.target.value)}
                  />
                  <HoverTooltip title="Añadir clasificación" placement="right">
                    <button disabled={!isValid} onClick={addClassification}>
                      {<AddIcon />}
                    </button>
                  </HoverTooltip>
                </div>
              </div>
              {text && <div className="classifications-selector__text">{text}</div>}
            </div>
            <div className="classifications-selector__list-container">
              <h4>Clasificaciones</h4>
              <div className="classifications-selector__list">
                {values?.length > 0 &&
                  values.map((value, i) => (
                    <HoverTooltip
                      title={tooltipText ? tooltipText : 'Cargando clasificación...'}
                      key={i}
                    >
                      <div
                        onMouseEnter={() =>
                          setTooltipText(new ClassificationValidator(value).validate(false).text)
                        }
                        onMouseLeave={() => setTooltipText(null)}
                        className="classifications-selector__list-elem"
                      >
                        <span>{value.length < 4 ? value + 'xx' : value}</span>
                        <ClearIcon onClick={() => deleteClassification(value)} />
                      </div>
                    </HoverTooltip>
                  ))}
                {!values?.length > 0 && (
                  <div className="classifications-selector__no-values">Sin clasificaciones</div>
                )}
              </div>
            </div>
          </div>
        }
      />
    )
  } catch (err) {
    return <ErrorView />
  }
}
