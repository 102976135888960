import Modal from 'components/Others/Modal/Modal'
import React, { useRef, useCallback, useMemo, useEffect } from 'react'
import { Form, Formik, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import Input from 'components/Others/Input/Input'
import './FifthWheelHitch.scss'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import round from 'myMethods/round'
import { putInspectionCalculations as putInspectionCalculationsService } from 'services/Inspection'
import useService from 'hooks/useService'
import isValidFunction from 'myMethods/isValidFunction'
import Id from 'Share/Id'
import easyToast from 'components/Others/EasyToast/easyToast'

const initialValues = {
  mmta: '',
  mmtaSemirremolque: '',
  cargaVertical: '',
  D: '',
  results: {
    D: {
      value: '',
      valid: null,
    },
  },
}

const CalcResults = () => {
  const { values, setFieldValue } = useFormikContext()

  const calcD = useCallback(() => {
    try {
      return round(
        9.81 *
          ((values.mmta * values.mmtaSemirremolque) /
            (values.mmta + values.mmtaSemirremolque - values.cargaVertical))
      )
    } catch (err) {
      console.error(err)
    }
  }, [values.cargaVertical, values.mmta, values.mmtaSemirremolque])

  useEffect(() => {
    if (values.mmta && values.mmtaSemirremolque && values.cargaVertical && values.D) {
      const D = calcD()
      setFieldValue('results.D.value', D)
      setFieldValue('results.D.valid', D <= values.D)
    } else {
      setFieldValue('results.D.value', '')
      setFieldValue('results.D.valid', null)
    }
  }, [calcD, setFieldValue, values.D, values.cargaVertical, values.mmta, values.mmtaSemirremolque])

  return null
}

export default function FifthWheelHitch({ closeModal }) {
  const formRef = useRef()
  const inspectionCalculations = useSelector(store => store.inspection.calculations)

  const { fetch: putCalculations, loading } = useService({
    service: putInspectionCalculationsService,
  })

  const handleInitialValues = useCallback(() => {
    if (inspectionCalculations?.fifthWheelHitch) return inspectionCalculations.fifthWheelHitch

    return initialValues
  }, [inspectionCalculations?.fifthWheelHitch])

  const persistValues = async shouldCloseModal => {
    try {
      const values = formRef.current.values
      let newInspectionCalculations = { ...(inspectionCalculations ?? {}), fifthWheelHitch: values }
      await putCalculations({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        calculations: newInspectionCalculations,
      })
      if (shouldCloseModal && isValidFunction(closeModal)) closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error al guardar los valores')
    }
  }

  const formikInitialValues = useMemo(() => handleInitialValues(), [handleInitialValues])

  return (
    <Modal
      open={true}
      closeOnOverlay={false}
      closeAfterAccept={false}
      onAccept={() => persistValues(true)}
      title="Quinta rueda"
      onClose={closeModal}
      loading={loading}
      content={
        <div className="fifth-wheel-hitch">
          <Formik
            innerRef={formRef}
            enableReinitialize
            initialValues={formikInitialValues}
            onSubmit={() => {}}
          >
            {({ values }) => (
              <Form>
                {console.log({ values })}
                <CalcResults />
                <div className="fifth-wheel-hitch__form">
                  <div className="fifth-wheel-hitch__inputs-box">
                    <div className="fifth-wheel-hitch__title">Valor D</div>
                    <div className="fifth-wheel-hitch__inputs">
                      <Input name="mmta" label="MMTA" unit="T" />
                      <Input name="mmtaSemirremolque" label="MMTA Semirremolque" unit="T" />
                      <Input name="cargaVertical" label="Carga vertical de quinta rueda" unit="T" />
                      <Input name="D" label="D de certificado" unit="Kn" />
                    </div>
                    <div
                      className={`fifth-wheel-hitch__result ${
                        values.results.D.valid != null
                          ? values.results.D.valid
                            ? 'fifth-wheel-hitch__result--valid'
                            : 'fifth-wheel-hitch__result--invalid'
                          : ''
                      }`}
                    >
                      <CardNameValue name="D" value={values.results.D.value} unit="Kn" />
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  )
}
