//@ts-check
import React, { useState } from 'react'
import Switch from 'components/Others/Switch/Switch'
import easyToast from 'components/Others/EasyToast/easyToast'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Input from 'components/Others/Input/Input'
import TestConfigWrapper from '../../TestConfigWrapper/TestConfigWrapper'
import Checkbox from 'components/Others/Checkbox/Checkbox'

/**
 * @typedef {import('types/Inspection').TechWeights} TechWeights
 * @typedef {import('types/Inspection').VehicleType} VehicleType
 * @typedef {import('types/Inspection').ConfigBrakeMeterData} BrakeMeterData
 */

/**
 *
 * @param {{data: import('./Frenometro').WeightModalData,  techWeights: TechWeights, setTechWeights: Function, filterName: string, isSemiTrailer: boolean, setIsSemiTrailer: Function, tractorAxles: number, vehicleType: VehicleType, values: BrakeMeterData, setValues: function, disabled?: boolean}} param0
 * @returns
 */
export default function FrenometroConfig({
  data,
  techWeights,
  setTechWeights,
  filterName,
  isSemiTrailer,
  setIsSemiTrailer,
  tractorAxles,
  vehicleType,
  values,
  setValues,
  disabled,
}) {
  const [totalMMA, setTotalMMA] = useState(techWeights?.mmaByAxles?.total || 0)
  const [momAddCounter, setMomAddCounter] = useState(0)

  const getTotalMMA = mmaByAxles => {
    try {
      if (!mmaByAxles) return
      let summ = 0
      Object.entries(mmaByAxles).forEach(([key, value]) => {
        if (key !== 'total') {
          summ += value || 0
        }
      })
      return summ
    } catch (err) {
      console.error(err)
      easyToast('error', 'No se pudo calcular la MMA Total')
      return 0
    }
  }

  const setAllMMAByAxles = value => {
    try {
      let mmaByAxles = {}
      Array.apply(null, Array(tractorAxles)).forEach((elem, i) => {
        mmaByAxles = { ...mmaByAxles, [i + 1]: value }
      })
      const mmaTotal = getTotalMMA(mmaByAxles)
      setTechWeights({
        ...techWeights,
        mmaByAxles: { ...mmaByAxles, total: mmaTotal },
      })
      setTotalMMA(mmaTotal)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error estableciendo las masas a ' + value + ' kg')
    }
  }

  return (
    <TestConfigWrapper
      testName="Frenómetro"
      message={data?.message}
      message2={data?.detail}
      disabled={disabled}
    >
      <div className="frenometro-config-container">
        {filterName === 'Remolque pesado' && (
          <div className="frenometro-config-semitrailer">
            <Switch
              label="¿ES UN SEMIREMOLQUE?"
              value={Boolean(isSemiTrailer)}
              onChange={value => {
                setIsSemiTrailer(value)
              }}
              disabled={disabled}
            />
            {isSemiTrailer && (
              <MyButtonsContainer fullWidth={false} margin="1rem auto">
                <MyButton
                  text="Todas a 8000kg"
                  onClick={() => setAllMMAByAxles(8000)}
                  disabled={disabled}
                />
              </MyButtonsContainer>
            )}
          </div>
        )}
        {data?.mom && (
          <>
            <Input
              isFormikInput={false}
              label="MOM (G)"
              name="mom"
              type="number"
              unit="kg"
              value={techWeights?.mom}
              disabled={disabled}
              actionButton={{
                content: `+75 ${momAddCounter ? '(' + momAddCounter + ')' : ''}`,
                onClick: () => {
                  setMomAddCounter(prevState => prevState + 1)
                  setTechWeights(prevState => ({ ...prevState, mom: (prevState.mom ?? 0) + 75 }))
                },
              }}
              onChange={e => {
                if (momAddCounter) setMomAddCounter(0)
                const newValue = parseFloat(e.target.value)
                setTechWeights(prevState => ({ ...prevState, mom: newValue ?? '' }))
              }}
            />
            {vehicleType?.includes('Bike') && techWeights?.mom > 0 && !isNaN(techWeights?.mom) && (
              <div className="frenometro-config-total">
                <label data-aos="fade-left">MOM Total:</label>
                <div>
                  {techWeights.mom + 75} <span>kg</span>
                </div>
              </div>
            )}
          </>
        )}
        {data?.momTrailer && (
          <Input
            isFormikInput={false}
            label="MOM remolque (G)"
            name="momTrailer"
            type="number"
            unit="kg"
            value={techWeights?.momTrailer}
            disabled={disabled}
            onChange={e => {
              const newValue = parseFloat(e.target.value)
              if (newValue) {
                setTechWeights(prevState => ({
                  ...prevState,
                  momTrailer: newValue ?? '',
                }))
              }
            }}
          />
        )}
        {data?.mma && (
          <Input
            isFormikInput={false}
            label="MMA (F.2)"
            name="mma"
            type="number"
            unit="kg"
            value={techWeights?.mma}
            disabled={disabled}
            onChange={e => {
              const newValue = parseFloat(e.target.value)
              if (newValue) {
                setTechWeights(prevState => ({
                  ...prevState,
                  mma: newValue ?? '',
                }))
              }
            }}
          />
        )}
        {data?.mmaTrailer && (
          <Input
            isFormikInput={false}
            label="MMA remolque (F.2)"
            name="mmaTrailer"
            value={techWeights?.mmaTrailer}
            disabled={disabled}
            onChange={e => {
              const newValue = parseFloat(e.target.value)
              setTechWeights(prevState => ({
                ...prevState,
                mmaTrailer: newValue ?? '',
              }))
            }}
          />
        )}
        {data?.mmaByAxles &&
          tractorAxles &&
          [...Array(tractorAxles)].map((elem, i) => {
            return (
              <>
                <Input
                  isFormikInput={false}
                  label={`MMA EJE ${i + 1} (F.2.1)`}
                  name={i + 1}
                  unit="kg"
                  value={techWeights?.mmaByAxles?.[i + 1]}
                  disabled={disabled}
                  onChange={e => {
                    const newValue = parseFloat(e.target.value)
                    const newTechWeights = {
                      ...techWeights,
                      mmaByAxles: { ...techWeights?.mmaByAxles, [i + 1]: newValue ?? '' },
                    }
                    const mmaTotal = getTotalMMA(newTechWeights.mmaByAxles)
                    setTechWeights({
                      ...newTechWeights,
                      mmaByAxles: { ...newTechWeights.mmaByAxles, total: mmaTotal },
                    })
                    setTotalMMA(mmaTotal)
                  }}
                />
                {i + 1 === tractorAxles && (
                  <div className="frenometro-config-total">
                    <label data-aos="fade-left">MMA Total:</label>
                    <div>
                      {totalMMA} <span>kg</span>
                    </div>
                  </div>
                )}
              </>
            )
          })}
      </div>
      <div className="frenometro-config__checkbox">
        <Checkbox
          label="Desactivar medidas freno estacionamiento"
          checked={values?.deactivateParkingBrake}
          disabled={disabled}
          onChange={checked =>
            setValues(prevState => ({ ...prevState, deactivateParkingBrake: checked }))
          }
        />
      </div>
    </TestConfigWrapper>
  )
}
