//@ts-check
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import StackedAreaChart from 'components/Others/Charts/StackedArea'
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import React, { useEffect, useReducer, useState } from 'react'
import StatisticsTools from '../StatisticsTools/StatisticsTools'
import './StatisticsInspection.scss'
import AnimatedCounter from 'components/Others/AnimatedCounter/AnimatedCounter'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import formatTimeToHoursMinutesSeconds from 'myMethods/formatTimeToHoursMinutesSeconds'
import ListOfStatisticsInspectionTests from './ListOfStatisticsInspectionTests/ListOfStatisticsInspectionTests'
import StatisticsInspectionByFilter from './StatisticsInspectionByFilter/StatisticsInspectionByFilter'
import GradientAreaChart from 'components/Others/Charts/GradientAreaChart'

/**
 * @typedef {import('./../StatisticsHandler').default} StatisticsHandler
 */
/**
 *
 * @param {{statisticsHandler: StatisticsHandler}} param0
 * @returns
 */

export default function StatisticsInspection({ statisticsHandler }) {
  const statisticsInspection = statisticsHandler?.inspection
  const dateRangeText = statisticsInspection?.dateRangeText
  const selectors = statisticsInspection?.selectors
    ? Object.entries(statisticsInspection.selectors)
        .map(([selector, state]) => (state ? selector : null))
        .filter(elem => elem)
    : []
  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState({
    show: false,
    title: '',
    inspections: null,
    filterKey: '',
    filterLabel: '',
    total: false,
  })
  const [, forceUpdate] = useReducer(x => x + 1, 0)

  const changeData = async selectedDateRange => {
    try {
      if (!selectedDateRange) return easyToast('error', 'Seleccione un rango de fechas')

      if (JSON.stringify(selectedDateRange) !== JSON.stringify(statisticsInspection.dateRange)) {
        setLoading(true)

        await statisticsHandler.changeInspectionData(selectedDateRange[0], selectedDateRange[1])
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error con el rango de fechas seleccionado')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    try {
      if (
        statisticsHandler &&
        (!statisticsInspection?.initialized ||
          (statisticsHandler?.dateRange?.length &&
            JSON.stringify(statisticsInspection.dateRange) !==
              JSON.stringify(statisticsHandler.dateRange)))
      ) {
        const initialize = async () => {
          setLoading(true)
          await statisticsHandler.initializeInspection()
          setLoading(false)
        }
        initialize()
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos. Seleccione una fecha')
      setLoading(false)
    }
    return () => abortController.abort()
  }, [
    statisticsHandler,
    statisticsInspection?.initialized,
    setLoading,
    statisticsInspection?.dateRange,
  ])

  console.log({ statisticsInspection })

  return (
    <>
      {loading && <ChildSpinner text="Cargando inspección..." mintLogo={true} />}
      {!loading && (
        <>
          <StatisticsTools
            onAccept={changeData}
            loading={loading}
            dateRange={statisticsInspection?.dateRange || []}
            dateRangeText={dateRangeText}
            exportation={{
              type: 'STATISTICS_INSPECTIONS_SUMMARY',
              data: {
                values: statisticsInspection?.data?.totalRange,
              },
              options: {
                dateRange: statisticsInspection?.dateRange,
              },
              modalTitle: 'Exportar resumen de inspecciones',
              defaultInputValue: 'RESUMEN DE INSPECCIONES',
            }}
          />

          <div className="statistics-defects">
            <div className="statistics__main-info" data-aos="fade-up">
              <CardNameValue
                className="statistics__info-card"
                name="TOTAL INSPECCIONES"
                value={
                  <AnimatedCounter number={statisticsInspection?.data?.totalRange?.total ?? 0} />
                }
              />
              {selectors.includes('Favorables') && (
                <CardNameValue
                  className="statistics__info-card"
                  name="TOTAL FAVORABLES"
                  value={
                    <AnimatedCounter
                      number={statisticsInspection?.data?.totalRange?.favorables ?? 0}
                    />
                  }
                />
              )}
              {selectors.includes('Desfavorables') && (
                <CardNameValue
                  className="statistics__info-card"
                  name="TOTAL DESFAVORABLES"
                  value={
                    <AnimatedCounter
                      number={statisticsInspection?.data?.totalRange?.desfavorables ?? 0}
                    />
                  }
                />
              )}
              {selectors.includes('Negativas') && (
                <CardNameValue
                  className="statistics__info-card"
                  name="TOTAL NEGATIVAS"
                  value={
                    <AnimatedCounter
                      number={statisticsInspection?.data?.totalRange?.negativas ?? 0}
                    />
                  }
                />
              )}
              <CardNameValue
                className="statistics__info-card"
                name="TIEMPO MEDIO"
                value={formatTimeToHoursMinutesSeconds(
                  statisticsInspection?.data?.totalRange?.halfTime ?? 0
                )}
              />
              <CardNameValue
                className="statistics__info-card"
                name="PORCENTAJE DE RECHAZO"
                value={
                  <AnimatedCounter
                    number={statisticsInspection?.data?.totalRange?.rejectionRate ?? 0}
                    unit="%"
                  />
                }
              />
            </div>
            <div className="statistics__selectors" data-aos="fade-up" data-aos-delay="100">
              <Checkbox
                label="Favorables"
                color="#71d88b"
                onChange={checked => {
                  statisticsHandler?.toggleInspectionSelectorState('Favorables', checked)
                  forceUpdate()
                }}
                checked={selectors.includes('Favorables')}
              />
              <Checkbox
                label="Desfavorables"
                color="#ffb347"
                onChange={checked => {
                  statisticsHandler?.toggleInspectionSelectorState('Desfavorables', checked)
                  forceUpdate()
                }}
                checked={selectors.includes('Desfavorables')}
              />
              <Checkbox
                label="Negativas"
                color="#ff6961"
                onChange={checked => {
                  statisticsHandler?.toggleInspectionSelectorState('Negativas', checked)
                  forceUpdate()
                }}
                checked={selectors.includes('Negativas')}
              />
            </div>

            <div data-aos="fade-up" data-aos-delay="100">
              <GradientAreaChart
                data={statisticsInspection?.areaChartData}
                dataKeys={statisticsInspection?.rawSelectors}
                hiddenDataKeys={statisticsInspection?.rawSelectors?.filter(
                  sel => !selectors.includes(sel)
                )}
                title="Por resultado"
                height={300}
                colors={['#71d88b', '#ffb347', '#ff6961']}
                extraKeysToShowInToolkit={['Inspecciones', 'Tiempo medio', 'Porcentaje de rechazo']}
              />
              <MyButtonsContainer margin="1rem auto">
                <MyButton
                  text="Resumen de tests"
                  onClick={() =>
                    setModal({
                      show: true,
                      inspections: statisticsInspection?.data?.totalRange?.tests,
                      title: 'Resumen de tests',
                      filterKey: '',
                      filterLabel: '',
                      total: true,
                    })
                  }
                />
              </MyButtonsContainer>
            </div>
            <div className="statistics__section" data-aos="fade-up" data-aos-delay="200">
              <div className="statistics__section-title">Resumen de inspecciones según</div>
              <div className="statistics__section-body">
                <MyButtonsContainer margin="0 auto" styles={{ maxWidth: '100%' }}>
                  <MyButton
                    text="Tipo de inspección"
                    onClick={() =>
                      setModal({
                        show: true,
                        inspections: statisticsInspection?.data?.totalRange?.byType,
                        title: 'Inspecciones por tipo de inspección',
                        filterKey: 'INSPECTION',
                        filterLabel: 'tipo de inspección',
                        total: false,
                      })
                    }
                  />
                  <MyButton
                    text="Categoría"
                    onClick={() =>
                      setModal({
                        show: true,
                        inspections:
                          statisticsInspection?.data?.totalRange?.byFilters?.category?.filterValues,
                        title: 'Inspecciones por categoría',
                        filterKey: 'CATEGORY',
                        filterLabel: 'categoría',
                        total: false,
                      })
                    }
                  />
                  <MyButton
                    text="Clasificación"
                    onClick={() =>
                      setModal({
                        show: true,
                        inspections:
                          statisticsInspection?.data?.totalRange?.byFilters?.classification
                            ?.filterValues,
                        title: 'Inspecciones por clasificación',
                        filterKey: 'CLASSIFICATION',
                        filterLabel: 'clasificación',
                        total: false,
                      })
                    }
                  />
                  <MyButton
                    text="Combustible"
                    onClick={() =>
                      setModal({
                        show: true,
                        inspections: {
                          ...statisticsInspection?.data?.totalRange?.byFilters?.fuel1?.filterValues,
                          ...statisticsInspection?.data?.totalRange?.byFilters?.fuel2?.filterValues,
                        },
                        filterKey: 'FUEL',
                        filterLabel: 'combustible',
                        title: 'Inspecciones por combustible',
                        total: false,
                      })
                    }
                  />
                </MyButtonsContainer>
              </div>
            </div>
          </div>
        </>
      )}
      <Modal
        open={modal?.show}
        onClose={() =>
          setModal({
            show: false,
            title: '',
            inspections: null,
            filterKey: '',
            filterLabel: '',
            total: false,
          })
        }
        title={modal?.title}
        hasAcceptButton={false}
        content={
          modal.total ? (
            <ListOfStatisticsInspectionTests tests={modal?.inspections} />
          ) : (
            <StatisticsInspectionByFilter
              inspectionsByFilter={modal?.inspections}
              filterKey={modal?.filterKey}
              filterLabel={modal?.filterLabel}
              dateRange={statisticsInspection?.dateRange}
              totalInspections={statisticsInspection?.data?.totalRange?.total}
            />
          )
        }
      />
    </>
  )
}
