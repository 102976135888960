import Modal from 'components/Others/Modal/Modal'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import formatTimeToHoursMinutesSeconds from 'myMethods/formatTimeToHoursMinutesSeconds'
import React, { useCallback } from 'react'

/**
 *
 * @param {{values: any[], closeModal: function, isOfficeIncluded: Boolean, loading: Boolean, Tools: Component, dateRange: [string, string] | []}} param0
 * @returns
 */
export default function TimesByUser({
  values = [],
  closeModal,
  isOfficeIncluded = false,
  loading = false,
  Tools = <></>,
  dateRange,
}) {
  const formatValues = useCallback(values => {
    try {
      const obj = {}
      Object.entries(values).forEach(([role, users]) => {
        Object.entries(users).forEach(([username, data]) => {
          if (username === 'SOLO SUPERVISION') return
          if (!(username in obj)) obj[username] = {}
          console.log({ data })
          obj[username][role] = (obj[username][role] || 0) + data.averageTime[role]
          obj[username].total = (obj[username].total || 0) + data.averageTime.total
          obj[username].inspections = (data.inspections || 0) + data.numberInspections
        })
      })
      console.log({ obj })
      return Object.entries(obj).map(([username, data]) => {
        return {
          username,
          ...data,
          formatted_administrative: formatTimeToHoursMinutesSeconds(data.administrative ?? 0),
          formatted_inspectionWaitTime: formatTimeToHoursMinutesSeconds(
            data.inspectionWaitTime ?? 0
          ),
          formatted_inspection: formatTimeToHoursMinutesSeconds(data.inspection ?? 0),
          formatted_supervisorWaitTime: formatTimeToHoursMinutesSeconds(
            data.supervisorWaitTime ?? 0
          ),
          formatted_supervisor: formatTimeToHoursMinutesSeconds(data.supervisor ?? 0),
        }
      })
    } catch (err) {
      console.error(err)
      return []
    }
  }, [])
  console.log({ values: formatValues(values) })
  return (
    <Modal
      open={true}
      title="Tiempos medios por usuario"
      onClose={closeModal}
      hasAcceptButton={false}
      loading={loading}
      content={
        <>
          {Tools}
          <MuiTable
            values={formatValues(values)}
            columns={[
              { title: 'Usuario', field: 'username' },
              { title: 'Total inspecciones', field: 'inspections' },
              {
                title: 'Total',
                render: useCallback(
                  rowData => {
                    return formatTimeToHoursMinutesSeconds(
                      isOfficeIncluded
                        ? rowData.total
                        : rowData.total - (rowData.administrative ?? 0)
                    )
                  },
                  [isOfficeIncluded]
                ),
              },
              { title: 'Oficina', field: 'formatted_administrative' },
              { title: 'Esperando inspección', field: 'formatted_inspectionWaitTime' },
              { title: 'Inspección', field: 'formatted_inspection' },
              { title: 'Esperando supervisión', field: 'formatted_supervisorWaitTime' },
              { title: 'Supervisión', field: 'formatted_supervisor' },
            ]}
            options={{
              grouping: false,
              filtering: false,
              search: true,
            }}
            showToolbar={true}
            exportation={{
              type: 'STATISTICS_TIMES_USER',
              data: { values: formatValues(values) },
              options: {
                dateRange,
              },
              modalTitle: 'Exportar tiempos por usuario',
              defaultInputValue: 'TIEMPOS POR USUARIO',
            }}
          />
        </>
      }
    />
  )
}
