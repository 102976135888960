//@ts-check
import reportDefects from 'resources/defectology/reportDefects.json'

/**
 *
 * @param {import('Share/Defect/InspectionDefectsManager').default} manualManager
 * @returns {{byChapters: import('types/Traceability').TraceabilityChapter[], mustBeSeenIds: string[]} | null}
 */
export default function getTraceability(manualManager) {
  try {
    const reportsDefectsCopy = JSON.parse(JSON.stringify(reportDefects))
    const onlyChapters = reportsDefectsCopy.filter(def => def.id.split('.').length === 1)
    let byChapters = []
    let mustBeSeenIds = []
    onlyChapters.forEach(({ id: chapterId, name: chapterName }) => {
      const foundChapter = manualManager.findDefectAndChilds(chapterId)
      if (!foundChapter) throw new Error(`Chapter ${chapterId} not found`)
      let childs = []
      foundChapter.childs.forEach(childNode => {
        const foundOnReportDefects = reportDefects.find(elem => elem.id === childNode.defect.id)
        if (foundOnReportDefects) {
          childs.push({
            id: childNode.defect.id,
            name: childNode.defect.name,
            user: childNode.defect.user,
            apply: childNode.defect.apply,
          })
        }
        if (childNode.defect.apply && !childNode.defect.user) {
          mustBeSeenIds.push(childNode.defect.id)
        }
      })
      byChapters.push({
        id: chapterId,
        name: chapterName,
        childs,
      })
    })
    return { byChapters, mustBeSeenIds }
  } catch (err) {
    console.error(err)
    return null
  }
}
