import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'
import handleUnauthorizedResponse from 'myMethods/handleUnauthorizedResponse'

const SERVER_URL = process.env.REACT_APP_SERVER_URL
const ITV_CARD_RECOGNIZER_SERVER_URL = process.env.REACT_APP_ITV_CARD_RECOGNIZER_URL

export function getAllInspections({ applyInspectionRestrictions = false }, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}inspection`, {
    ...getJWT(),
    signal: options.signal,
    params: { applyInspectionRestrictions },
  })
}

/**
 *
 * @param {{id: string}} param0
 * @param {*} options
 * @returns {Promise<import('types/InspectionResponse').InspectionGenericResponse>}
 */
export function getInspectionById({ id }, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}inspection/${id}`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getInspectionImageByFilename({ id, filename }, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}inspection/${id}/images`, {
    ...getJWT(),
    signal: options.signal,
    params: { filename },
    responseType: 'arraybuffer',
  })
}

export function initInspectionVehicle(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function finishInspection(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/finish`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function reviveInspection(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}inspection/revive`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function initSupervisionVehicle(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}supervision`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putInspectionDefects(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}inspection/visual_defect`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function deleteInspectionVisualDefect(data, options = {}) {
  return Axios.delete(`${SERVER_URL}/api/${v2}inspection/visual_defect`, {
    ...getJWT(),
    data,
    signal: options.signal,
  })
}

export function putInspectionOptionalDefects(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/update_optional_defects`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putInspectionDefectsDone(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/update_defects_done`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function postInspectionRemark(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}inspection/remark`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putInspectionRemark(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/remark`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function deleteInspectionRemark(data, options = {}) {
  return Axios.delete(`${SERVER_URL}/api/${v2}inspection/remark`, {
    ...getJWT(),
    data,
    signal: options.signal,
  })
}

export function putInspectionAxles(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/axles`, data, {
    ...getJWT(),
    signal: options.signal,
  }).catch(err => {
    if (err.response?.status === 401) {
      handleUnauthorizedResponse()
    }
    throw err
  })
}

export function putInspectionConfig(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/config`, data, {
    ...getJWT(),
    signal: options.signal,
  }).catch(err => {
    if (err.response?.status === 401) {
      handleUnauthorizedResponse()
    }
    throw err
  })
}

export function putInspectionTechWeights(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/techWeights`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putInspectionCalculations(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/calculations`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putInspectionTestsValue(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/testValues`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function deleteInspectionManualTestValues({ id, testName }, options = {}) {
  return Axios.delete(`${SERVER_URL}/api/${v2}inspection/${id}/manual_test`, {
    ...getJWT(),
    data: { testName },
    signal: options.signal,
  })
}

export function putInspectionLine(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/line`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putInspectionInspector(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/inspector`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putInspectionSpeedometerOnlyKm(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/speedometer_km`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function deleteInspectionSpeedometer(data, options = {}) {
  return Axios.delete(`${SERVER_URL}/api/${v2}inspection/speedometer`, {
    ...getJWT(),
    data,
    signal: options.signal,
  })
}

export function postInitInspection(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}init_inspection`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putInspectionDefectApply(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/update_defects_apply`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putInspectionITVCard({ inspectionId, vehicleCard }, options = {}) {
  return Axios.put(
    `${SERVER_URL}/api/${v2}inspection/vehicle_card`,
    { id: inspectionId, vehicleCard },
    {
      ...getJWT(),
      signal: options.signal,
    }
  )
}

export function putInspectionImage(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/images`, data, {
    headers: { ...getJWT().headers, 'content-type': 'multipart/form-data' },
    signal: options.signal,
  })
}

export function putInspectionSpeedometer(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}inspection/speedometer`, data, {
    headers: { ...getJWT().headers, 'content-type': 'multipart/form-data' },
    signal: options.signal,
  })
}

export function deleteInspectionImage(data, options = {}) {
  return Axios.delete(`${SERVER_URL}/api/${v2}inspection/images`, {
    ...getJWT(),
    data: data,
    signal: options.signal,
  })
}

export function cancelInspectionTestValue({ inspectionId, testName, reason }, options = {}) {
  return Axios.put(
    `${SERVER_URL}/api/${v2}inspection/${inspectionId}/testValues/cancel`,
    { testName, reason },
    {
      ...getJWT(),
      signal: options.signal,
    }
  )
}

export function recalcInspectionMachineDefect({ id, defectId, recalcData }, options = {}) {
  return Axios.put(
    `${SERVER_URL}/api/${v2}inspection/machine_defect/recalc`,
    { id, defectId, recalcData },
    {
      ...getJWT(),
      signal: options.signal,
    }
  )
}

export function addComplementaryEquipmentToInspection(
  { inspectionId: id, internalId },
  options = {}
) {
  return Axios.post(
    `${SERVER_URL}/api/${v2}inspection/complementary_equipment`,
    { id, internalId },
    {
      ...getJWT(),
      signal: options.signal,
    }
  )
}

export function deleteComplementaryEquipmentFromInspection(
  { inspectionId: id, internalId },
  options = {}
) {
  return Axios.delete(`${SERVER_URL}/api/${v2}inspection/complementary_equipment`, {
    ...getJWT(),
    data: { id, internalId },
    signal: options.signal,
  })
}

export function postMachineRemoteControl(key_code, options = {}) {
  return Axios.post(
    `localhost:8000/api/press_key`,
    { key_code },
    {
      ...getJWT(),
      signal: options.signal,
    }
  )
}

export function postRecognizeItvCard(data, options = {}) {
  return Axios.post(`${ITV_CARD_RECOGNIZER_SERVER_URL}/api/recognize`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}
