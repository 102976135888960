//@ts-check
import React, { useCallback, useRef, useState, useEffect, useMemo } from 'react'
import './ITVCardForm.scss'
import { Formik, Form, useFormikContext } from 'formik'
import Input from 'components/Others/Input/Input'
import ClassificationValidator from 'Share/ClassificationValidator'
import ListOfStrings from 'components/Others/ListOfStrings/ListOfStrings'
import ITVCardFullLabels from 'resources/ITVCardFullLabels.json'
import moment from 'moment'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { getITVCardPDF } from 'services/ITVCard'
import easyToast from 'components/Others/EasyToast/easyToast'
import useService from 'hooks/useService'
import openPDF from 'myMethods/openPDF'
import useConcurrentServices from 'hooks/useConcurrentServices'
import ITVCardOffsetInput from './ITVCardOffsetInput/ITVCardOffsetInput'
import { useDispatch, useSelector } from 'react-redux'
import { EyeFill as PreviewIcon } from 'react-bootstrap-icons'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import { setReduxInspectionItvCard } from 'redux/ducks/inspectionDuck'
import compareObjects from 'myMethods/compareObjects'
import AdditionalSheetIcon from '@mui/icons-material/NoteAdd'

const MemoizedInput = React.memo(({ input, listsValues, setListsValues, offsets, setOffsets }) => {
  const foundFullLabel = ITVCardFullLabels.find(
    elem => elem.id === input.id.replaceAll('-', '.')
  )?.label
  const inputLabel = foundFullLabel ? `${input.label} - ${foundFullLabel}` : input.label

  if (input.type === 'text') {
    return (
      <Input
        name={input.id}
        label={inputLabel}
        type={input.type}
        disabled={input.id === 'matricula'}
      />
    )
  } else if (input.type === 'arrayOfStrings') {
    return (
      <ListOfStrings
        values={listsValues[input.id]}
        setValues={newValues =>
          setListsValues(prevState => ({ ...prevState, [input.id]: newValues }))
        }
        title={input.label}
        addItemsText="Añadir"
        addItemsIcon={null}
        childrenBefore={
          input.id === 'reformas' ? (
            <ITVCardOffsetInput
              value={offsets.reformas.y}
              setValue={value =>
                setOffsets(prevState => ({
                  ...prevState,
                  reformas: { ...prevState.reformas, y: value },
                }))
              }
            />
          ) : null
        }
      />
    )
  } else if (input.type === 'date') {
    return <Input name={input.id} label={inputLabel} type={input.type} />
  }
  return null
})

const FormikEffect = ({ reduxItvCard, listsValues }) => {
  const { values } = useFormikContext()
  const dispatch = useDispatch()

  useEffect(() => {
    try {
      const finalValues = {
        ...values,
        observaciones: listsValues.observaciones,
        homologaciones: listsValues.homologaciones,
        reformas: listsValues.reformas,
      }

      if (reduxItvCard && !compareObjects({ obj1: finalValues, obj2: reduxItvCard })?.equals) {
        dispatch(setReduxInspectionItvCard(finalValues))
      }
    } catch (error) {
      console.error(error)
    }
  }, [dispatch, values, reduxItvCard, listsValues])

  return null
}

const ITVCardForm = ({
  model,
  inputBoxes,
  vehicleData,
  onlyReforms = false,
  reforms,
  recognizedValues,
}) => {
  const [listsValues, setListsValues] = useState({
    observaciones: [],
    homologaciones: [],
    reformas: [],
  })
  const [offsets, setOffsets] = useState({
    reformas: { x: 0, y: 0 },
  })
  const [reformsAdded, setReformsAdded] = useState([])
  const formRef = useRef()
  const [loadingAdditionalSheet, setLoadingAdditionalSheet] = useState(false)
  const [lastRecognizedValues, setLastRecognizedValues] = useState(null)
  const [initialized, setInitialized] = useState(false)
  const dispatch = useDispatch()
  const reduxItvCard = useSelector(state => state.inspection.itvCard)
  const {
    serviceData: previewPDF,
    fetch: fetchPreview,
    loading: loadingPreview,
  } = useService({
    service: getITVCardPDF,
  })

  const { loading: loadingFinal, fetchAll: fetchAllPdfs } = useConcurrentServices([
    { service: getITVCardPDF },
    { service: getITVCardPDF },
  ])

  const fixValues = values => {
    const newValues = {}
    Object.entries(values).forEach(([key, value]) => {
      newValues[key.replaceAll('-', '.')] = value.toString()
      if (key === 'fecha' && value) newValues[key] = moment(value).format('DD-MM-YYYY')
    })
    newValues.matricula = vehicleData.license
    newValues.observaciones = listsValues.observaciones
    newValues.homologaciones = listsValues.homologaciones
    newValues.reformas = listsValues.reformas
    console.log({ newValues })
    return newValues
  }
  const handleInitialValues = useCallback(() => {
    console.log('Entro handleInitialValues...')
    if (reduxItvCard) {
      if (!initialized) {
        setListsValues({
          observaciones: reduxItvCard.observaciones ?? [],
          homologaciones: reduxItvCard.homologaciones ?? [],
          reformas: reduxItvCard.reformas ?? [],
        })
        setInitialized(true)
      }
      return reduxItvCard
    }
    const initialValues = {}
    inputBoxes.forEach(box => {
      box.inputs.forEach(input => {
        input.id = input.id.replaceAll('.', '-')
        if (input.type === 'text') initialValues[input.id] = ''
        if (input.id === 'matricula') initialValues[input.id] = vehicleData?.license ?? ''
        if (input.id === 'E') initialValues[input.id] = vehicleData?.frame ?? ''
        if (!onlyReforms) {
          if (input.type === 'date') initialValues[input.id] = moment().format('YYYY-MM-DD')
          if (input.id === 'D-1') initialValues[input.id] = vehicleData?.brand ?? ''
          if (input.id === 'D-2') initialValues[input.id] = vehicleData?.type ?? ''
          if (input.id === 'K') initialValues[input.id] = vehicleData?.homologation ?? ''
          if (input.id === 'C-L') {
            const clsValidation = new ClassificationValidator(
              vehicleData?.classification
            ).validate()
            if (clsValidation.isValid) {
              initialValues[input.id] = `${vehicleData.classification} - ${clsValidation.text}`
            }
          }
        }
      })
    })
    return initialValues
  }, [inputBoxes, onlyReforms, reduxItvCard, vehicleData, initialized])

  const generatePreview = async () => {
    try {
      const values = fixValues(formRef.current.values)
      const response = await fetchPreview({
        model: model === 'REFORMAS' ? 'A' : model,
        values,
        preview: true,
        onlyReforms,
        offsets,
      })
      if (response && typeof response === 'object') {
        openPDF(response, '[VISTA PREVIA - NO IMPRIMIR] - Tarjeta ITV')
        easyToast(
          'dark',
          'No imprima la vista previa. Solo revise que es correcta y pulse en "Generar Tarjeta ITV" para obtener el documento final'
        )
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error generando la vista previa')
    }
  }

  const generateITVCard = async () => {
    try {
      const values = fixValues(formRef.current.values)
      const response = await fetchAllPdfs([
        {
          model: model === 'REFORMAS' ? 'A' : model,
          values,
          generateITVCard: true,
          onlyReforms,
          offsets,
        },
      ])
      let counter = 0
      if (response[0]?.status === 'fulfilled' && response[0].data?.value?.data) {
        openPDF(response[0].data.value.data, 'Tarjeta ITV')
        counter++
      }
      easyToast(
        'dark',
        `${counter} ${counter > 1 ? ' documentos generados' : ' documento generado'}`
      )
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error generando la vista previa')
    }
  }

  const generateAdditionalSheet = async () => {
    try {
      setLoadingAdditionalSheet(true)
      const values = fixValues(formRef.current.values)
      if (!values.certificado)
        return easyToast(
          'error',
          'Introduzca el número de certificado de la tarjeta ITV precedente'
        )
      const response = await getITVCardPDF({
        values,
        generateITVCard: false,
        offsets,
      })
      if (response && typeof response === 'object') {
        openPDF(response, `Hoja adicional ${values.certificado}`)
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error generando la hoja adicional')
    } finally {
      setLoadingAdditionalSheet(false)
    }
  }

  const inyectRecognizedValues = useCallback(
    (recognizedValuesProp, join) => {
      console.log('Entro inyectRecognizedValues...', { recognizedValuesProp, join })
      if (recognizedValuesProp) {
        recognizedValuesProp.matricula = vehicleData.license
        recognizedValuesProp.fecha = ''
        if (join) {
          setListsValues(prevState => {
            console.log('Nuevos listsValues: ', {
              observaciones: recognizedValuesProp.observaciones
                ? [...prevState.observaciones, recognizedValuesProp.observaciones]
                : prevState.observaciones,
              homologaciones: recognizedValuesProp.homologaciones
                ? [...prevState.homologaciones, recognizedValuesProp.homologaciones]
                : prevState.homologaciones,
              reformas: recognizedValuesProp.reformas
                ? [...prevState.reformas, recognizedValuesProp.reformas]
                : prevState.reformas,
            })
            return {
              observaciones: recognizedValuesProp.observaciones
                ? [...prevState.observaciones, recognizedValuesProp.observaciones]
                : prevState.observaciones,
              homologaciones: recognizedValuesProp.homologaciones
                ? [...prevState.homologaciones, recognizedValuesProp.homologaciones]
                : prevState.homologaciones,
              reformas: recognizedValuesProp.reformas
                ? [...prevState.reformas, recognizedValuesProp.reformas]
                : prevState.reformas,
            }
          })
          console.log('Estableciendo lastRecognizedValues a : ', {
            ...(formRef.current?.values ?? {}),
            ...recognizedValuesProp,
          })
          setLastRecognizedValues({
            ...(formRef.current?.values ?? {}),
            ...recognizedValuesProp,
          })
          formRef.current.setValues({
            ...(formRef.current?.values ?? {}),
            ...recognizedValuesProp,
          })
        } else {
          setListsValues({
            observaciones: recognizedValuesProp.observaciones
              ? [recognizedValuesProp.observaciones]
              : [],
            homologaciones: recognizedValuesProp.homologaciones
              ? [recognizedValuesProp.homologaciones]
              : [],
            reformas: recognizedValuesProp.reformas ? [recognizedValuesProp.reformas] : [],
          })
          console.log('Estableciendo lastRecognizedValues a : ', recognizedValuesProp)
          setLastRecognizedValues(recognizedValuesProp)
          formRef.current.setValues(recognizedValuesProp)
        }
      }
    },
    [vehicleData?.license]
  )

  useEffect(() => {
    try {
      if (!recognizedValues) return
      const formattedRecognizedValues = {}

      Object.entries(recognizedValues).forEach(([key, value]) => {
        if (typeof value !== 'string') value = ''
        formattedRecognizedValues[key.replaceAll('.', '-')] = value
      })
      console.log('Entro useEffect, ', { lastRecognizedValues, formattedRecognizedValues })
      if (!lastRecognizedValues) return inyectRecognizedValues(formattedRecognizedValues, false)
      console.log(
        compareObjects({
          obj1: formattedRecognizedValues,
          obj2: lastRecognizedValues,
          ignore: ['matricula', 'fecha'],
        })
      )
      if (
        !compareObjects({
          obj1: formattedRecognizedValues,
          obj2: lastRecognizedValues,
          ignore: ['matricula', 'fecha'],
        }).equals
      ) {
        dispatch(
          setReduxConfigModal({
            title:
              'Ya se han reconocido valores con IA, ¿quiéres sobreescribir los valores actuales o añadirlos a los ya existentes?',
            closeButtonText: 'Sobreescribir',
            acceptButtonText: 'Añadir',
            onAccept: () => inyectRecognizedValues(formattedRecognizedValues, true),
            onCloseButton: () => inyectRecognizedValues(formattedRecognizedValues, false),
            acceptButtonIcon: null,
            closeButtonIcon: null,
          })
        )
      }
    } catch (err) {
      console.error(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recognizedValues, dispatch])

  return (
    <Formik enableReinitialize={false} innerRef={formRef} initialValues={handleInitialValues()}>
      {() => (
        <>
          <FormikEffect reduxItvCard={reduxItvCard} listsValues={listsValues} />
          <MyButtonsContainer margin="1.5rem auto">
            <MyButton
              text="Generar vista previa Tarjeta ITV"
              onClick={generatePreview}
              loading={loadingPreview}
              transparent={true}
              disabled={loadingFinal || loadingAdditionalSheet}
              icon={<PreviewIcon />}
            />
            {previewPDF && (
              <MyButton
                text="Generar Tarjeta ITV"
                onClick={generateITVCard}
                loading={loadingFinal}
                disabled={loadingPreview || loadingAdditionalSheet}
              />
            )}
            {!onlyReforms && (
              <MyButton
                text="Generar solo hoja adicional"
                onClick={generateAdditionalSheet}
                loading={loadingAdditionalSheet}
                disabled={loadingPreview || loadingFinal}
                icon={<AdditionalSheetIcon />}
              />
            )}
          </MyButtonsContainer>
          <div
            className={`itv-card-form ${
              inputBoxes.some(box => box.alias === 'Datos Vehiculo 3')
                ? ''
                : 'itv-card-form--no-datos-vehiculo-3'
            } ${onlyReforms ? 'itv-card-form--only-reforms' : ''}`}
          >
            {onlyReforms && (
              <>
                {reforms?.length > 0 && (
                  <div className="itv-card-form__reforms-list-container">
                    <span>Reformas de esta inspección</span>
                    <div className="itv-card-form__reforms-list">
                      {reforms.map((reform, i) => {
                        const reformAlreadyAdded = listsValues.reformas.length
                          ? reformsAdded.includes(reform.value)
                          : false
                        return (
                          <div key={i}>
                            <MyButtonsContainer margin="0">
                              <MyButton
                                className={
                                  reformAlreadyAdded
                                    ? 'itv-card-form__reforms-btn--already-added'
                                    : null
                                }
                                text={reformAlreadyAdded ? 'Añadida' : 'Añadir'}
                                transparent={true}
                                onClick={() => {
                                  setListsValues(prevState => ({
                                    ...prevState,
                                    reformas: [...prevState.reformas, reform.label],
                                  }))
                                  setReformsAdded(prevState => [...prevState, reform.value])
                                }}
                              />
                            </MyButtonsContainer>
                            <div className="itv-card-form__reforms-name">{reform.label}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                <div className="itv-card-form__hint">
                  Estos valores solo se imprimirán en el caso de que sea necesaria una o varias
                  hojas adicionales
                </div>
              </>
            )}
            <Form className="itv-card-form__form">
              {inputBoxes.map((box, i) => (
                <div
                  key={i}
                  className={`itv-card-form__box itv-card-form__box--${box.alias
                    .replaceAll(' ', '-')
                    .toLowerCase()}`}
                >
                  {box.inputs.map((input, j) => (
                    <MemoizedInput
                      key={j}
                      input={input}
                      listsValues={listsValues}
                      setListsValues={setListsValues}
                      offsets={offsets}
                      setOffsets={setOffsets}
                    />
                  ))}
                </div>
              ))}
            </Form>
          </div>
        </>
      )}
    </Formik>
  )
}

export default ITVCardForm
