import React, { useState, useEffect } from 'react'
import Switch from 'components/Others/Switch/Switch'
import { useDispatch } from 'react-redux'
import { setReduxInspectionOptionalDefects } from 'redux/ducks/inspectionDuck'

// MY IMPORTS
import 'components/Inspection/Preinspection/Preinspection.scss'

/**
 *
 * @param {{optionalDefects: import('types/Inspection').OptionalDefects, manualManager: InspectionDefectsManager}} param0
 * @returns
 */
export default function Preinspection({ optionalDefects, manualManager }) {
  const [values, setValues] = useState(null)
  const [defects, setDefects] = useState([])
  const dispatch = useDispatch()

  const handleChange = (checked, name) => {
    try {
      setValues({ ...values, [name]: checked })
      dispatch(setReduxInspectionOptionalDefects({ ...values, [name]: checked }))
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    // COMPROBAMOS SI YA TENEMOS LOS DEFECTOS OPCIONALES EN REDUX o BBDD
    try {
      if (optionalDefects && Object.keys(optionalDefects).length && manualManager && !values) {
        let auxDefects = []
        Object.entries(optionalDefects).forEach(([id, value]) => {
          const node = manualManager.findDefectAndChilds(id)
          if (!node) throw new Error('No node found')
          auxDefects.push({
            id,
            value,
            name: node.defect.name,
          })
        })
        if (auxDefects.length) {
          setDefects(auxDefects)
          setValues(optionalDefects)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [dispatch, optionalDefects, manualManager, values])

  return (
    <>
      {!(defects?.length > 0) && (
        <div className="no-values">No se han encontrado defectos opcionales</div>
      )}
      {defects?.length > 0 && (
        <>
          <div className="preinspection__title">
            <span>Antes de empezar...</span>
            <h3>¿Aplican alguno de estos defectos?</h3>
          </div>
          <div className="preinspection__item">
            <span>ID</span>
            <span>NOMBRE</span>
            <span>APLICA</span>
          </div>
          {defects.map(defect => {
            return (
              <div key={defect.id} className="preinspection__item">
                <div className="preinspection__id">{defect.id}</div>
                <div className="preinspection__name">{defect.name}</div>
                <div className="preinspection__switch">
                  <Switch
                    name={defect.id}
                    value={values[defect.id]}
                    onChange={checked => handleChange(checked, defect.id)}
                  />
                </div>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}
