import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export function getHistoryByChunk(params, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}history/match`, {
    ...getJWT(),
    params: params ?? {},
    signal: options.signal,
  })
}
/**
 *
 * @param {*} param0
 * @param {*} options
 * @returns {Promise<import('types/InspectionResponse').InspectionGenericResponse>}
 */
export function getHistoryById({ id }, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}history/${id}`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getLicenseHistory(license, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}history/license/${license}`, {
    ...getJWT(),
    signal: options.signal,
  })
}
