import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import SonometroModal from './SonometroConfig/SonometroConfig'
import { setReduxInspectionConfig } from 'redux/ducks/inspectionDuck'

export default function Sonometro({ machineSettings, disabled = false }) {
  const [config, setConfig] = useState({
    show: false,
  })

  const reduxInspection = useSelector(store => store.inspection)
  const reduxSoundLevelData = reduxInspection?.config?.soundLevelData
  const [values, setValues] = useState({
    db: reduxSoundLevelData?.db ?? null,
    rpm: reduxSoundLevelData?.rpm ?? null,
    stroke: reduxSoundLevelData?.stroke ?? null,
    dualExhaust: reduxSoundLevelData?.dualExhaust ?? false,
  })
  const dispatch = useDispatch()

  useEffect(() => {
    const newConfig = { ...(reduxInspection?.config || {}), soundLevelData: values }
    dispatch(setReduxInspectionConfig(newConfig))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values])

  useEffect(() => {
    if (!config.show) {
      setConfig({ ...config, show: true })
    }
  }, [config, reduxSoundLevelData, values.rpm])

  return (
    <>
      {config.show && (
        <SonometroModal
          setValues={values => setValues(values)}
          values={values}
          boxCorrectionFactor={machineSettings?.boxCorrectionFactor}
          disabled={disabled}
        />
      )}
    </>
  )
}
