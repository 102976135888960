//@ts-check
import Modal from 'components/Others/Modal/Modal'
import React from 'react'
import './UserInfoModal.scss'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import useResources from 'hooks/useResources'

/**
 * @typedef {import('types/User.d.ts').User} User
 */

/**
 *
 * @param {{user: User, closeModal: Function}} param0
 * @returns
 */
export default function UserInfoModal({ user, closeModal }) {
  const { roles } = useResources({ roles: true })
  return (
    <Modal
      open={true}
      onClose={closeModal}
      title="Información de usuario"
      hasAcceptButton={false}
      content={
        <>
          {user && (
            <div className="user-info-modal">
              <div className="user-info-modal__avatar">
                {user.avatar ? (
                  <img src={`data:image/png;base64,${user.avatar}`} alt="Avatar" />
                ) : (
                  <i className="flaticon-usuario-de-perfil" />
                )}
              </div>
              <ListItemsInTwoColumns>
                <CardNameValue name="Nombre de usuario" value={user.username} />
                <CardNameValue name="Nombre completo" value={user.fullname} />
                <CardNameValue
                  name="Rol"
                  value={roles?.find(role => role.value === user.role)?.label}
                />
              </ListItemsInTwoColumns>
            </div>
          )}
          {!user && (
            <div className="no-values">Ha ocurrido un error obteniendo los datos de usuario</div>
          )}
        </>
      }
    />
  )
}
