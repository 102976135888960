import Textarea from 'components/Others/Textarea/Textarea'
import React from 'react'
import './DefectPlace.scss'
import Input from 'components/Others/Input/Input'

export default function DefectPlace({
  value,
  inputValue,
  setValue,
  setInputValue,
  defectPlaces,
  reduxInspection,
}) {
  const places = [
    'DELANTERO',
    'DELANTERO IZQUIERDA',
    'DELANTERO DERECHA',
    'IZQUIERDA',
    'DERECHA',
    'TRASERO IZQUIERDA',
    'TRASERO DERECHA',
    'TRASERO',
  ]

  const otherPlaces = [
    ...[...Array(reduxInspection?.axles.tractor)].map((elem, i) => 'EJE ' + (i + 1)),
    'SUPERIOR',
    'INFERIOR',
    'INTERIOR',
    'EXTERIOR',
    'OTRO',
  ]

  return (
    <>
      <div className="defect-place__grid">
        {places.map(place => (
          <button
            disabled={Boolean(defectPlaces.find(elem => elem === place))}
            key={place}
            onClick={() => setValue(place)}
            className={`defect-place__elem ${
              value === place ? 'defect-place__elem--selected' : ''
            }`}
          >
            {place}
          </button>
        ))}
        <i className="flaticon-car" />
      </div>
      <div className="defect-place__others">
        {otherPlaces.map(place => (
          <button
            disabled={Boolean(
              defectPlaces.find(elem => (elem === 'OTRO' ? false : elem === place))
            )}
            key={place}
            onClick={() => setValue(place)}
            className={`defect-place__elem ${
              value === place ? 'defect-place__elem--selected' : ''
            }`}
          >
            {place}
          </button>
        ))}
        {value === 'OTRO' && (
          <Input
            type="textarea"
            onChange={e => {
              setInputValue(e.target.value.replace(/(\r\n|\n|\r)/gm, ''))
            }}
            label="Localización del defecto"
            value={inputValue}
            style={{ background: 'white', marginTop: '1rem' }}
            maxLength={50}
            isFormikInput={false}
          />
        )}
      </div>
    </>
  )
}
