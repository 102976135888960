//@ts-check
import React, { useState, useCallback } from 'react'
import AnimatedList from '../AnimatedList/AnimatedList'
import AnimatedListElem from '../AnimatedList/AnimatedListElem'
import { Search as SearchIcon, Check as CheckIcon, Dot as DotIcon } from 'react-bootstrap-icons'
import { Add as AddIcon } from '@mui/icons-material'
import ChildSpinner from '../Spinner/ChildSpinner' // Asegúrate de importar el spinner correctamente
import './SuperList.scss'
import HoverTooltip from '../HoverTooltip/HoverTooltip'
import Input from '../Input/Input'

/**
 * SuperList component
 *
 * @param {Object} props - Component properties
 * @param {string} [props.className] - Additional class names for the component
 * @param {string} [props.noItemsMessage] - Message to show when there are no elements to list
 * @param {boolean} [props.loading] - Flag to show the loading spinner
 * @param {React.ReactNode|React.ReactNode[]} props.children - Child elements to be rendered in the list
 * @param {boolean} [props.animate=true] - Flag to enable/disable animation
 * @param {'fadeIn' | 'fadeInUp' | 'fadeInDown' | 'fadeInLeft' | 'fadeInRight' | 'zoomIn' | 'zoomOut' | 'slideInUp' | 'slideInDown' | 'slideInLeft' | 'slideInRight'} [props.animationType] - Type of animation for the list elements
 * @param {Array<{ text: string, onClick: () => void, checked: boolean, color?: string, counter?: number }>} [props.options] - Array of options to be rendered as buttons
 * @param {boolean} [props.showAddButton=false] - Show the add button
 * @param {string} [props.tooltipAddButtonText="Añadir"] - Text for the add button tooltip
 * @param {string} [props.addButtonText=""] - Text for the add button
 * @param {() => void} [props.onAddButton] - Function to execute when the add button is clicked
 * @param {boolean} [props.search] - Flag to enable/disable the search functionality
 *
 * @returns {JSX.Element} The rendered SuperList component
 */
export default function SuperList({
  className = '',
  children,
  animate = true,
  noItemsMessage = 'No hay elementos para mostrar',
  loading = false,
  animationType,
  options = [],
  search = false,
  showAddButton = false,
  addButtonText = '',
  tooltipAddButtonText = 'Añadir',
  onAddButton = () => {},
  ...rest
}) {
  const [searchTerm, setSearchTerm] = useState('')
  const childArray = React.Children.toArray(children)

  const extractTextFromChild = child => {
    if (typeof child === 'string' || typeof child === 'number') {
      return String(child)
    }

    if (React.isValidElement(child)) {
      const { props } = child
      let text = ''

      if (props) {
        // Recursively extract text from props.children
        if (props.children) {
          text += React.Children.toArray(props.children).map(extractTextFromChild).join(' ')
        }

        // Extract text from other props that are strings or objects
        Object.keys(props).forEach(key => {
          if (typeof props[key] === 'string') {
            text += ` ${props[key]}`
          } else if (Array.isArray(props[key])) {
            text += ` ${props[key].map(extractTextFromChild).join(' ')}`
          } else if (typeof props[key] === 'object' && props[key] !== null) {
            text += ` ${extractTextFromChild(props[key])}`
          } else if (React.isValidElement(props[key])) {
            text += ` ${extractTextFromChild(props[key])}`
          }
        })
      }

      return text.trim()
    }

    if (Array.isArray(child)) {
      return child.map(extractTextFromChild).join(' ')
    }

    if (typeof child === 'object' && child !== null) {
      let text = ''
      Object.keys(child).forEach(key => {
        text += ` ${extractTextFromChild(child[key])}`
      })
      return text.trim()
    }

    return ''
  }

  const filteredChildren = searchTerm
    ? childArray.filter(child => {
        const childText = extractTextFromChild(child).toLowerCase()
        console.log({ childText })
        return childText.includes(searchTerm.toLowerCase())
      })
    : childArray

  const handleSearchChange = useCallback(e => {
    setSearchTerm(e.target.value)
  }, [])

  return (
    <div className={`super-list ${className}`} {...rest}>
      <div className="super-list__options">
        <div className="super-list__options-buttons">
          {options.map((option, index) => (
            <div
              key={index}
              onClick={option.onClick}
              className={`super-list__button ${
                option.checked ? 'super-list__button--selected' : ''
              }`}
              style={{ backgroundColor: option.checked ? option.color : '' }}
            >
              {option.checked ? <CheckIcon /> : <DotIcon />}{' '}
              <span>
                {option.counter != null ? `${option.counter} ` : ''}
                {option.text}
              </span>
            </div>
          ))}
        </div>
        <div className="super-list__tools">
          {search && (
            <Input
              className="super-list__search"
              isFormikInput={false}
              type="text"
              placeholder="Buscar..."
              value={searchTerm}
              onChange={handleSearchChange}
              icon={<SearchIcon />}
              styleVariant="outlined"
            />
          )}
          {showAddButton && (
            <HoverTooltip text={tooltipAddButtonText}>
              <div className="super-list__add-btn" onClick={onAddButton}>
                <AddIcon /> {addButtonText && <span>{addButtonText}</span>}
              </div>
            </HoverTooltip>
          )}
        </div>
      </div>

      {loading ? (
        <div className="super-list__loading">
          <ChildSpinner />
        </div>
      ) : filteredChildren.length === 0 ? (
        <div className="super-list__no-items">
          <div className="no-values">{noItemsMessage}</div>
        </div>
      ) : animate ? (
        <AnimatedList className="super-list__list">
          {filteredChildren.map((child, i) => (
            <AnimatedListElem animationType={animationType} index={i} key={i}>
              {child}
            </AnimatedListElem>
          ))}
        </AnimatedList>
      ) : (
        <div className="super-list__list">
          {filteredChildren.map((child, i) => (
            <div className="list-elem" key={i}>
              {child}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
