import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_BILLING_URL

export function getFee(data, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}fees/breakdown`, {
    ...getJWT(),
    params: data,
    signal: options.signal,
  })
}

export function putFee({ inspectionType, feeId, fee }, options = {}) {
  return Axios.put(
    `${SERVER_URL}/api/${v2}config/fees/${inspectionType}/${feeId}`,
    { fee },
    {
      ...getJWT(),
      signal: options.signal,
    }
  )
}

export function postCalculateFeeTotal(fee, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}fees/calculate`, fee, {
    ...getJWT(),
    signal: options.signal,
  })
}
