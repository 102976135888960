import { toast } from 'react-toastify'

export default function easyToast(type, text, options) {
  options = { position: 'top-center', duration: 4000, preventDuplicates: true, ...options }
  if (options.preventDuplicates) {
    const toasts = document.querySelectorAll('.Toastify__toast')
    console.log({ toasts })
    if (options.isAComponent) {
      if (Array.from(toasts).find(elem => elem.innerText.includes(options.text))) return
    } else {
      if (Array.from(toasts).find(elem => elem.innerText === text)) return
    }
  }
  let progressStyle = '#ff6961'

  if (type === 'warn') progressStyle = '#ffba00'
  else if (type === 'error') progressStyle = '#ffffff'
  else if (type === 'info') progressStyle = '#333'

  toast[type](text, {
    autoClose: options.duration,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    backgroundColor: '#ecebeb',
    progressStyle: { backgroundColor: progressStyle },
    position: options.position,
  })
}
