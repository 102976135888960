import CirclePercentageChart from 'components/Others/Charts/CirclePercentageChart/CirclePercentageChart'
import React from 'react'
import './StatisticsPercentageCard.scss'
import getCssVariable from 'myMethods/getCssVariable'

export default function StatisticsPercentageCard({ title, value, ...rest }) {
  return (
    <div className="statistics-percentage-card" {...rest}>
      {title && <div className="statistics-percentage-card__title">{title}</div>}
      <CirclePercentageChart number={value} strokeColor={getCssVariable('--error-color')} />
    </div>
  )
}
