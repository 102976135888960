import Modal from 'components/Others/Modal/Modal'
import React from 'react'
import { useSelector } from 'react-redux'

export default function ManualTpvModal({ handleClick, closeModal, error, title }) {
  const manualTpvs = useSelector(store => store.config.itv?.accounting?.manualTPVs)

  return (
    <Modal
      open={true}
      onClose={closeModal}
      title={
        title
          ? title
          : error
          ? 'Ha ocurrido un error conectando con el TPV. Cobre manualmente y seleccione el TPV que ha usado o cancele para intentarlo de nuevo'
          : 'Ningún TPV integrado detectado. Cobre manualmente y seleccione el TPV que ha usado o cancele para intentarlo de nuevo'
      }
      hasAcceptButton={false}
      closeOnOverlay={false}
      content={
        <>
          {(!manualTpvs || !manualTpvs.length > 0) && (
            <div className="no-values">No tiene ningún TPV añadido a la lista</div>
          )}
          <div className="list-container checkout__manual-tpvs">
            {manualTpvs?.length > 0 &&
              manualTpvs.map(tpv => (
                <div key={tpv} className="list-content" onClick={() => handleClick(tpv)}>
                  {tpv}
                </div>
              ))}
          </div>
        </>
      }
    />
  )
}
