//@ts-check
import React from 'react'
import './AnimatedLogo.scss'
import { motion } from 'framer-motion'

/**
 *
 * @param {{relative?: boolean}} param0
 * @returns
 */
export default function AnimatedLogoInfinity({ relative = true }) {
  const svgVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  const pulseVariants = {
    pulse: {
      scale: [0.95, 1.05, 0.95], // Alterna la escala entre 1, 1.05 y vuelve a 1
      transition: {
        duration: 2, // Duración de cada ciclo de pulsación
        ease: 'easeInOut', // Tipo de "ease" para suavizar la transición
        repeat: Infinity, // Repite la animación indefinidamente
        repeatType: 'loop', // Asegura que la animación se repita en bucle
      },
    },
  }

  const pathVariants = {
    hidden: {
      pathLength: 0,
      fill: 'none',
    },
    visible: ({ delay = 0 }) => ({
      pathLength: 1,
      transition: {
        type: 'spring',
        delay: delay, // Ajusta o reduce este valor según sea necesario
        duration: 2, // Reduce la duración para acelerar la animación
        repeat: Infinity,
        repeatType: 'reverse', // Opcional: para invertir la animación en cada repetición
      },
    }),
  }

  return (
    <motion.div
      className={`animated-logo animated-logo--infinity ${
        relative ? '' : 'animated-logo--infinity--not-relative'
      }`}
      variants={pulseVariants}
      initial="pulse"
      animate="pulse"
    >
      <div className="animated-logo__wrapper animated-logo__wrapper--infinity">
        <motion.svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 850.66 309.27"
          variants={svgVariants}
          initial="hidden"
          animate="visible"
        >
          <g>
            {/* LETRA M */}
            <motion.path
              custom={{ delay: 0 }}
              variants={pathVariants}
              initial="hidden"
              animate="visible"
              className="animated-logo__1"
              d="M307.07,133.14q21.51,21.51,21.51,64.53V309H315.16a43,43,0,0,1-43-43V190.67q0-23.13-9.58-34.53t-27.29-11.38q-19.89,0-31.45,12.83T192.3,195.73V309H178.89a43,43,0,0,1-43-43V190.67q0-45.91-36.87-45.91-19.51,0-31.08,12.83T56.39,195.73V309H43A43,43,0,0,1,0,266V114.53H31.45a22.41,22.41,0,0,1,22.41,22.41h0a71.28,71.28,0,0,1,26.57-18.8A89.37,89.37,0,0,1,115,111.63q20.6,0,37.23,8.14a66,66,0,0,1,26.75,23.67A79.81,79.81,0,0,1,209.11,120a95.3,95.3,0,0,1,39.95-8.32Q285.55,111.63,307.07,133.14Z"
            />
            {/* LETRA I */}
            <motion.path
              custom={{ delay: 0.3 }}
              variants={pathVariants}
              className="animated-logo__1"
              d="M379.18,114.53h0a56.38,56.38,0,0,1,56.39,56.39V309H422.16a43,43,0,0,1-43-43Z"
            />
            {/* LETRA N */}
            <motion.path
              custom={{ delay: 0.6 }}
              variants={pathVariants}
              className="animated-logo__1"
              d="M664.57,133.32Q686.8,155,686.8,197.67V309H673.38a43,43,0,0,1-43-43V190.67q0-23.13-10.13-34.53T591,144.76q-21.35,0-34,13.19t-12.65,39.22V309H531a43,43,0,0,1-43-43V114.53h31.08a22.77,22.77,0,0,1,22.78,22.77h0a74.71,74.71,0,0,1,27.83-19,97.1,97.1,0,0,1,36.51-6.69Q642.32,111.63,664.57,133.32Z"
            />
            {/* LETRA T */}
            <motion.path
              custom={{ delay: 0.9 }}
              variants={pathVariants}
              className="animated-logo__1"
              d="M848,259.84h0q-9.4,7.23-22.77,7.23-11.58,0-17.89-6.69t-6.33-19V162.24h48.44a43.38,43.38,0,0,0-43.38-43.38H801a47.35,47.35,0,0,0-47.35-47.35h-9v47.35h-30v13.38a30,30,0,0,0,30,30h0v79.89q0,34.33,19,52.05a60.11,60.11,0,0,0,5.51,4.54,58.06,58.06,0,0,0,33.26,10.53c3,.05,6.26,0,9.81-.25C840,307.11,857.65,285.07,848,259.84Z"
            />
            {/* HOJA DERECHA */}
            <motion.path
              custom={{ delay: 1.2 }}
              variants={pathVariants}
              className="animated-logo__2"
              d="M487.07,50.82,465.36,84A61.65,61.65,0,0,1,386,105.37a64.82,64.82,0,0,0,16.92-4c.85-.32,1.7-.67,2.54-1a65.77,65.77,0,0,0,34.14-34.17c2.36-1,4.8-1.79,7.25-2.56A82.36,82.36,0,0,1,457,61.09a81,81,0,0,0-10.49,1.27c-2,.36-3.95.76-5.91,1.24a64.81,64.81,0,0,0,4.31-22.27A61.46,61.46,0,0,1,487.07,50.82Z"
            />
            {/* HOJA IZQUIERDA */}
            <motion.path
              custom={{ delay: 1.5 }}
              variants={pathVariants}
              className="animated-logo__3"
              d="M441.13,39.67c0,.77,0,1.54,0,2.31a61.71,61.71,0,0,1-56.94,59.73c2.55-1.88,5-3.82,7.43-5.94,1.53-1.28,2.93-2.71,4.35-4.1s2.73-2.91,4-4.47a68.47,68.47,0,0,0,6.76-9.76,71.2,71.2,0,0,0,5.05-10.67A76,76,0,0,0,415,55.48a79.77,79.77,0,0,0,1.36-11.6,78.45,78.45,0,0,1-2.14,11.42,75.21,75.21,0,0,1-3.93,10.86,70.49,70.49,0,0,1-5.61,10,66.87,66.87,0,0,1-7.13,8.92c-1.27,1.41-2.68,2.68-4.05,4s-2.86,2.48-4.39,3.59c-2.91,2.23-6,4.31-9.15,6.25l-.31-36.7A61.71,61.71,0,0,1,440.8,0Z"
            />
          </g>
        </motion.svg>
      </div>
    </motion.div>
  )
}
