//@ts-check
import React, { useState, useEffect, useCallback } from 'react'

// MY IMPORTS
import './InvoiceModal.scss'
import {
  postCancelInvoice as postCancelInvoiceService,
  getInvoicePDF as getInvoicePDFService,
  putRevertCancelledInvoice as putRevertCancelledInvoiceService,
  getInvoiceData as getInvoiceDataService,
} from 'services/Billing'
import PDFViewer from 'components/Others/PDFViewer/PDFViewer'
import openPDF from 'myMethods/openPDF'
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import InvoiceModifier from '../../InvoiceModifier/InvoiceModifier'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import InvoiceRectifier from '../../InvoiceRectifier/InvoiceRectifier'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import RefundMoneyModal from '../../RefundMoneyModal/RefundMoneyModal'
import PopoverMenu from 'components/Others/PopoverMenu/PopoverMenu'
import useService from 'hooks/useService'
import useMultipleServices from 'hooks/useMultipleServices'

/**
 * @typedef {import('types/InvoiceTypeResponse').InvoiceDataResDTO} InvoiceDataResDTO
 */

/**
 *
 * @param {{ invoiceId: string, serie: string, closeModal: function, refetch: function, refetchDeals: function}} param0
 * @returns
 */
export default function InvoiceModal({ invoiceId, serie, closeModal, refetch, refetchDeals }) {
  /** @typedef {[InvoiceDataResDTO, function]} */
  const [data, setData] = useState({})

  const [modalsStates, setModalsStates] = useState({
    cancel: false,
    revertCancelled: false,
    refundMoney: false,
    modifier: false,
    rectifier: false,
  })
  const [loading, setLoading] = useState(false)

  const [PDF, setPDF] = useState(null)

  const isCancelled = Boolean(data.canceledBy?.number)
  const isCancelling = Boolean(data.invoiceThatCancels?.number)
  const isRectified = Boolean(data.rectifiedBy?.length)
  const isRectifing = Boolean(data.invoiceThatRectify?.number)
  const isModifiable = !['dealInvoice', 'corrective', 'cancelledDealInvoice'].includes(data.serie)

  const { fetch: fetchInitialData, loading: initialLoading } = useMultipleServices({
    services: [getInvoicePDFService, getInvoiceDataService],
  })

  const { fetch: putRevertCancelledInvoice } = useService({
    service: putRevertCancelledInvoiceService,
    setOutLoading: setLoading,
  })

  const { fetch: postCancelInvoice } = useService({
    service: postCancelInvoiceService,
    setOutLoading: setLoading,
  })

  const changeInvoiceData = ({ id, serie }) => {
    try {
      if (id && serie) {
        fetchData(id, serie)
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando la factura')
    }
  }

  const fetchData = useCallback(
    async invoiceIdParam => {
      try {
        let id = invoiceId
        if (invoiceIdParam) {
          id = invoiceIdParam
        }
        setData({})
        setPDF(null)
        const [pdf, invoice] = await fetchInitialData([{ invoiceId: id }, { invoiceId: id }])
        if (pdf) setPDF(pdf)
        if (invoice?.data) setData(invoice.data)
      } catch (error) {
        easyToast('error', 'Ha ocurrido un error cargando la información de la factura')
      }
    },
    [fetchInitialData, invoiceId]
  )
  const cancel = async () => {
    try {
      await postCancelInvoice({ invoiceId: data.id })
      let refundMoney = false
      let returnRemind = false
      let cancel = false
      if (!(serie.includes('dealInvoice') || serie.includes('corrective'))) {
        if (data.paymentMethod === 'TARJETA') {
          refundMoney = true
        } else {
          returnRemind = true
        }
      } else {
        refetchDeals()
      }
      refetch()
      fetchData()
      setModalsStates(prevState => ({ ...prevState, refundMoney, returnRemind, cancel }))
    } catch (error) {
      easyToast('error', 'Ha ocurrido un error anulando la factura')
    }
  }

  const finishRefund = async response => {
    refetch()
    setLoading(false)
    setModalsStates({ ...modalsStates, refundMoney: false })
  }

  const revertCancelled = async () => {
    try {
      await putRevertCancelledInvoice({ invoiceId: data.id, serie: data.serie })
      easyToast('dark', 'Anulación revertida')
      closeModal()
    } catch (error) {
      easyToast('error', 'Ha ocurrido un error revertiendo la anulación')
    }
  }

  useEffect(() => {
    if (invoiceId && serie && !Object.keys(data).length) {
      fetchData()
    }
    //eslint-disable-next-line
  }, [invoiceId, serie])
  console.log({ data, initialLoading })
  return (
    <>
      <Modal
        open={true}
        onClose={closeModal}
        buttons={!loading}
        hasCloseButton={false}
        loading={initialLoading}
        content={
          <>
            <>
              {!(Object.keys(data)?.length > 0) && (
                <div className="no-values" style={{ marginBottom: '0.5rem' }}>
                  Ha ocurrido un error cargando la factura
                </div>
              )}
              {Object.keys(data)?.length > 0 && (
                <div className="invoice-modal">
                  {isCancelled && (
                    <div className="invoice-modal__banner">
                      FACTURA ANULADA POR {data.canceledBy.number}
                      <button
                        onClick={() =>
                          changeInvoiceData({
                            id: data.canceledBy.id,
                          })
                        }
                      >
                        Ver anulación
                      </button>
                    </div>
                  )}
                  {isCancelling && (
                    <div className="invoice-modal__banner">
                      ESTA FACTURA ANULA A {data.invoiceThatCancels.number}
                      <button
                        onClick={() =>
                          changeInvoiceData({
                            id: data.invoiceThatCancels.id,
                            serie: data.invoiceThatCancels.serie,
                          })
                        }
                      >
                        Ver factura
                      </button>
                    </div>
                  )}
                  {!isCancelled && isRectified && (
                    <div className="invoice-modal__banner">
                      FACTURA RECTIFICADA{' '}
                      {data.rectifiedBy?.length > 1
                        ? data.rectifiedBy?.length + ' VECES'
                        : ' POR ' + data.rectifiedBy?.[0].number}
                      {data.rectifiedBy?.length > 1 ? (
                        <PopoverMenu
                          items={[
                            ...data.rectifiedBy.map(rec => {
                              return {
                                content: rec.number,
                                onClick: () => {
                                  changeInvoiceData({
                                    id: rec.id,
                                  })
                                },
                              }
                            }),
                          ]}
                        >
                          <button>Ver rectificaciones</button>
                        </PopoverMenu>
                      ) : (
                        <button
                          onClick={() =>
                            changeInvoiceData({
                              id: data.rectifiedBy[0].id,
                            })
                          }
                        >
                          Ver rectificación
                        </button>
                      )}
                    </div>
                  )}
                  {isRectifing && (
                    <div className="invoice-modal__banner">
                      ESTA FACTURA RECTIFICA A {data.invoiceThatRectify?.number}
                      <button
                        onClick={() =>
                          changeInvoiceData({
                            id: data.invoiceThatRectify?.id,
                          })
                        }
                      >
                        Ver factura
                      </button>
                    </div>
                  )}
                  <section>
                    <div>
                      {data.user?.username && data.user?.fullname && (
                        <div className="deals-modal__user">
                          Generada por
                          <div>
                            {data.user.username} - {data.user.fullname}
                          </div>
                        </div>
                      )}
                      <MyButtonsContainer
                        margin="1rem auto"
                        className="invoice-modal__buttons-container"
                      >
                        <MyButton text={'ABRIR FACTURA'} onClick={() => openPDF(PDF)} />
                        {!isCancelled && !isCancelling && isModifiable && (
                          <MyButton
                            text="MODIFICAR"
                            onClick={() => setModalsStates({ ...modalsStates, modifier: true })}
                          />
                        )}
                        {!isCancelled &&
                          !isCancelling &&
                          ['dealInvoice', 'corrective'].includes(data?.serie) &&
                          !isRectified && (
                            <MyButton
                              text="RECTIFICAR"
                              onClick={() => setModalsStates({ ...modalsStates, rectifier: true })}
                            />
                          )}
                        {!isCancelled && !data.serie?.includes('cancelled') && (
                          <MyButton
                            text="ANULAR"
                            onClick={() => setModalsStates({ ...modalsStates, cancel: true })}
                          />
                        )}
                        {data.cancelled && (
                          <MyButton
                            text="REVERTIR ANULACIÓN"
                            onClick={() =>
                              setModalsStates({ ...modalsStates, revertCancelled: true })
                            }
                          />
                        )}
                      </MyButtonsContainer>
                    </div>
                    <PDFViewer
                      pdf={PDF}
                      errorMessage="Ha ocurrido un error cargando la factura. Recargue la página"
                      show={true}
                      loading={initialLoading}
                    />
                  </section>
                </div>
              )}
            </>
          </>
        }
      />
      {modalsStates?.refundMoney && (
        <RefundMoneyModal
          invoiceId={data.id}
          amount={data.breakdown?.total}
          finish={finishRefund}
          paymentDetails={data.paymentDetails}
        />
      )}
      {modalsStates?.returnRemind && (
        <Modal
          open={modalsStates.returnRemind}
          onClose={() => setModalsStates({ ...modalsStates, returnRemind: false })}
          hasCloseButton={false}
          title="Factura anulada"
          message="Recuerde devolver el importe de la factura en efectivo"
        />
      )}
      {modalsStates?.modifier && (
        <InvoiceModifier
          data={data}
          closeModal={() => {
            setModalsStates({ ...modalsStates, modifier: false })
          }}
          refetch={() => {
            fetchData()
            refetch()
          }}
        />
      )}
      {modalsStates?.cancel && (
        <Modal
          open={true}
          title={loading ? 'Anulando factura...' : `¿Anular factura ${data.number}?`}
          message={
            loading
              ? null
              : 'Esta acción anulará esta factura generando una nueva con valor negativo'
          }
          onClose={() => setModalsStates({ ...modalsStates, cancel: false })}
          buttons={!loading}
          onAccept={cancel}
          closeAfterAccept={false}
          content={loading && <ChildSpinner />}
        />
      )}
      {modalsStates?.revertCancelled && (
        <Modal
          open={true}
          title={`¿Revertir anulación de factura ${data.id}?`}
          message="Esta acción anulará esta factura generando una nueva con valor positivo"
          onClose={() => setModalsStates({ ...modalsStates, revertCancelled: false })}
          onAccept={revertCancelled}
          closeAfterAccept={false}
        />
      )}

      {modalsStates?.rectifier && (
        <InvoiceRectifier
          data={data}
          closeModal={() => setModalsStates({ ...modalsStates, rectifier: false })}
          refetch={() => {
            fetchData()
            refetch()
          }}
        />
      )}
    </>
  )
}
