//@ts-check
import React, { useEffect, useRef } from 'react'
import 'components/Inspection/CaptureLicense/CaptureLicense.scss'
import Modal from 'components/Others/Modal/Modal'
import isValidFunction from 'myMethods/isValidFunction'
import useInspectionImages from 'hooks/useInspectionImages'
import CaptureImage from '../CaptureImage/CaptureImage'
import { useSelector } from 'react-redux'
import { Camera as CameraIcon } from 'react-bootstrap-icons'

/**
 *
 * @param {{closeModal: function}} param0
 * @returns
 */
export default function CaptureLicense({ closeModal }) {
  const { images, loadingImages, uploadImage, loadingUpload } = useInspectionImages({
    type: 'matricula',
  })
  const captureImageRef = useRef()
  const isRevision = useSelector(store => store.inspection.rev)

  useEffect(() => {
    if (!images?.length && !loadingImages) {
      captureImageRef?.current?.triggerCapture()
    }
  }, [images?.length, loadingImages])

  return (
    <>
      <Modal
        open={true}
        title="Capturar matrícula"
        message="Verifique la matrícula antes de continuar"
        onClose={() => isValidFunction(closeModal) && closeModal()}
        hasCloseButton={!isRevision}
        closeButtonText="Capturar de nuevo"
        closeButtonIcon={<CameraIcon />}
        onCloseButton={() => captureImageRef?.current?.triggerCapture()}
        loading={loadingImages || loadingUpload}
        content={
          <div className="capture-license__modal">
            {images?.[0]?.src && <img alt="Matrícula" src={images[0].src} />}
          </div>
        }
        buttons={true}
      />
      <CaptureImage
        ref={captureImageRef}
        showImageAfterCapture={false}
        onCaptureImage={(image, geolocation) => {
          uploadImage({ image, geolocation, type: 'matricula', label: 'Matrícula' })
        }}
      />
    </>
  )
}
