import { Buffer } from 'buffer'
window.Buffer = window.Buffer || require('buffer').Buffer
export default class DataConverter {
  static async fileToBase64(data) {
    return new Promise((resolve, reject) => {
      try {
        var reader = new FileReader()
        reader.readAsDataURL(data)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => {
          throw new Error(error)
        }
      } catch (err) {
        console.error(err)
        reject(err)
      }
    })
  }

  static toBase64(data) {
    console.log({ data })
    return Buffer.from(data).toString('base64')
  }

  static async base64ToBlob(data) {
    try {
      const r = await fetch(data)
      const blob = await r.blob()
      return blob
      // const b = Buffer.from(data)
      // const byteArray = new Uint8Array(b)
      // return new Blob([byteArray], { type: 'application/pdf' })
    } catch (err) {
      console.error(err)
    }
  }
}
