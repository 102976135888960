//@ts-check
import ConfigFilters from '../Filters'
import DefectId from './DefectId'

/**
 * @typedef {import("../Defect/InspectionDefectsManager").FilterDefect} FilterDefect
 * @typedef {import('types/Vehicle').ClassificationAndCategory} ClassificationAndCategoryVO
 * @typedef {import('types/Filter').Filter} Filter
 */

export default class OptionalFilter {
  /**@type {Map<string, import("../Defect/InspectionDefectsManager").FilterDefect>} */
  #filterMap
  /**
   * @param { Filter[]} filters
   * @param {ClassificationAndCategoryVO} categoryAndClasification
   */
  constructor(categoryAndClasification, filters) {
    const filter = new ConfigFilters({
      filters,
      category: categoryAndClasification.category,
      classification: categoryAndClasification.classification,
    })?.filter
    /**@type {Map<string, FilterDefect>} */
    this.#filterMap = new Map()
    for (const defect of filter.defects) {
      this.#filterMap.set(defect.id, defect)
    }
    this.name = filter.name
    this.section = filter.section
  }

  /**
   *
   * @param {string} id
   * @param {{fuel1: string, fuel2: string}} params
   * @returns
   */
  isOptional(id, { fuel1, fuel2 }) {
    const fatherId = DefectId.formPrimitive(id).father
    if (fatherId == null) return false
    const defect = this.#filterMap.get(fatherId)
    if (defect == null) {
      return false
    }
    if (defect.optional === false || defect.causes != null) return false

    // if (defect.causes != null) {
    //   if (!defect.causes.fuel.includes(fuel1) && !defect.causes.fuel.includes(fuel2)) {
    //     return false
    //   }
    // }
    // if (defect.initialValue != null) {
    //   return defect.initialValue
    // }
    return true
  }

  /**
   *
   * @param {string} id
   * @param {{fuel1: string, fuel2: string}} params
   * @returns
   */
  applyForVehicleData(id, { fuel1, fuel2 }) {
    const fatherId = DefectId.formPrimitive(id).father
    if (fatherId == null) return true
    const defect = this.#filterMap.get(fatherId)
    if (defect == null) {
      return false
    }
    if (defect.optional === false) return true

    if (defect.causes != null) {
      if (defect.causes.fuel.includes(fuel1) || defect.causes.fuel.includes(fuel2)) {
        return true
      }
    }
    if (defect.initialValue != null) {
      return defect.initialValue
    }
    return false
  }

  /**
   *
   * @description Verifica si la unidad de un defecto proporcionado aplica según sus causes
   */
  checkUnitCausesApply(id, { fuel1, fuel2 }) {
    try {
      const defect = DefectId.formPrimitive(id)
      if (defect.parentOrderDepth < 2) {
        return true
      }
      const parentDefectId = defect.getParentIdFormDepth(2)
      if (!parentDefectId) return true
      const unitDefect = this.#filterMap.get(parentDefectId)
      if (!unitDefect || !unitDefect.causes) return true
      if (
        !unitDefect.causes.fuel.includes(fuel1) ||
        (fuel2 && !unitDefect.causes.fuel.includes(fuel2))
      )
        return false
      return true
    } catch (err) {
      console.error(err)
      return true
    }
  }

  /**
   *
   * @param {string} id
   * @param {string[]} list
   * @return {boolean}
   */
  applyForInspectionType(id, list, depth = 2) {
    const idOnDepth = DefectId.formPrimitive(id).getParentIdFormDepth(depth)
    if (idOnDepth == null) return false
    // if (id.split('.').length > 3) return true
    if (
      list.includes(idOnDepth) ||
      list.includes(DefectId.formPrimitive(id).getParentIdFormDepth(1))
    )
      return true
    for (const onlyId of list) {
      if (id === DefectId.formPrimitive(onlyId).father) return true
    }
    return this.applyForInspectionType(id, list, depth + 1)
  }

  /**
   * @param {object} param0
   * @param {string} param0.id
   * @param {boolean} param0.isFiltered
   * @param {{fuel1: string, fuel2: string}} param0.fuels
   * @param {string[] | null} param0.onlyShowThisIds
   *
   * @returns
   */
  show({ id, isFiltered, fuels, onlyShowThisIds }) {
    if (!isFiltered) return true
    const { fuel1, fuel2 } = fuels
    const fatherId = DefectId.formPrimitive(id).father
    if (fatherId == null) return true
    if (
      isFiltered &&
      onlyShowThisIds &&
      Array.isArray(onlyShowThisIds) &&
      onlyShowThisIds.length &&
      !this.applyForInspectionType(id, onlyShowThisIds)
    ) {
      return false
    }
    const defect = this.#filterMap.get(fatherId)

    if (defect == null) {
      return true
    }
    if (defect.optional === false) return true

    if (defect.causes != null) {
      if (defect.causes.fuel.includes(fuel1) || defect.causes.fuel.includes(fuel2)) {
        return true
      }
    }

    return false
  }
}
