//@ts-check
import OptionalFilter from './OptionalFilter'

import InspectionDefect from './InspectionDefect'
import DefectId from './DefectId'
import SectionId from './DefectId'
import ErrorForDefect from './ErrorForDefect'
import SectionNode from './SectionNode'

/**

 * @typedef {Map<string, SectionNode>} SectionDefectsTree
 * @typedef {import('types/Filter').Defect} FilterDefect
 * @typedef {import('types/Inspection').VisualDefect} VisualDefect
 * @typedef {import('types/Inspection').MachineDefect} MachineDefect
 * @typedef {import('types/Inspection').OptionalDefects} OptionalDefects
 * @typedef {import('types/Inspection').DefectsDone} DefectsDone
 * @typedef {import('Share/TestsValues').default} TestsValuesHandler
 * @typedef {import('types/Vehicle').ClassificationAndCategory} ClassificationAndCategoryVO
 * @typedef {import('types/Filter').Filter} Filter
 */

export default class InspectionDefectsManager {
  #defects
  #manualSection
  #categoryAndClassification
  /**
   *
   * @param {SectionDefectsTree} defects
   * @param {ClassificationAndCategoryVO} categoryAndClassification
   */
  constructor(defects, categoryAndClassification) {
    if (defects == null) {
      throw ErrorForDefect.invaliceInicializedContructorDefectManager()
    }
    this.#defects = defects
    this.#categoryAndClassification = categoryAndClassification
  }

  get classificationAndCategory() {
    return this.#categoryAndClassification
  }

  /**
   * @param {object} param0
   * @param {Filter[]} param0.filters
   * @param {ClassificationAndCategoryVO} param0.categoryAndClasification
   * @param {{fuel1: string, fuel2: string}} param0.fuels
   * @param {VisualDefect[]} param0.visualDefects
   * @param {MachineDefect[]} param0.machineDefects
   * @param {DefectsDone[]} param0.defectsDone
   * @param {OptionalDefects} param0.optionalDefects
   * @param {boolean} param0.isFiltered
   * @param {import('types/LinkBetweenMachineAndManual').Links} param0.linkBetweenMachineAndManual
   * @param {import('types/Manual').Defect[]} param0.manualDefects
   * @param {import('types/InspectionProperties').InspectionProperties} param0.inspectionProperties
   * @param {TestsValuesHandler} param0.testsValuesHandler
   * @param {import('types/Inspection').InspectionDefectImagePrimitive []} param0.images
   * @return {InspectionDefectsManager}
   */
  static inicializeSections({
    filters,
    categoryAndClasification,
    fuels,
    visualDefects,
    machineDefects,
    defectsDone,
    optionalDefects,
    isFiltered,
    inspectionProperties,
    testsValuesHandler,
    linkBetweenMachineAndManual,
    manualDefects,
    images,
  }) {
    const filter = InspectionDefectsManager.#getOptionalFilter(categoryAndClasification, filters)
    const defects = new InspectionDefectsManager(new Map(), categoryAndClasification)
    for (const defectManual of manualDefects) {
      try {
        const getMachineInfo = () => {
          let automatic =
            linkBetweenMachineAndManual?.automatic?.byNames?.find(obj => {
              return obj.defects.includes(defectManual.id)
            })?.testName ?? null
          if (automatic) return { name: automatic, automatic: true }
          let semiautomatic =
            linkBetweenMachineAndManual?.semiautomatic?.byNames?.find(obj => {
              return obj.defects.includes(defectManual.id)
            })?.testName ?? null
          if (semiautomatic) return { name: semiautomatic, automatic: false }
          return null
        }
        const machineInfo = getMachineInfo()

        // let show = filter.show({
        //   id: defectManual.id,
        //   isFiltered: isFiltered,
        //   fuels,
        //   onlyShowThisIds: inspectionProperties?.onlyInspectThisDefects || null,
        // })

        let apply = filter.applyForVehicleData(defectManual.id, fuels)
        if (inspectionProperties?.onlyInspectThisDefects?.length) {
          apply = filter.applyForInspectionType(
            defectManual.id,
            inspectionProperties?.onlyInspectThisDefects
          )
          if (apply) {
            apply = filter.checkUnitCausesApply(defectManual.id, {
              fuel1: fuels.fuel1,
              fuel2: fuels.fuel2,
            })
          }
        }

        let testData = testsValuesHandler?.getLastTestValues(machineInfo?.name)?.test || null
        if (testData?.cancellation) testData = null

        const machineDefect = null
        const image = images?.find(image => image.defectId === defectManual.id) || null
        const defect = InspectionDefect.formPrimitives({
          id: defectManual.id,
          name: defectManual.name,
          possibleScores: defectManual.calification
            ? InspectionDefect.validCalification(defectManual.calification)
            : null,
          // apply: filter.apply(defectManual.id, fuels),
          apply,
          done: false,
          optional: filter.isOptional(defectManual.id, fuels),
          remark: null,
          machineInfo,
          isFiltered,
          user: null,
          showWhenFilterOn: true,
          testData,
          score: null,
          machineDefect,
          visualDefect: null,
          image,
        })
        defects.addDefect(defect)
      } catch (error) {
        console.error(error)
        console.error(defectManual)
        throw error
      }
    }

    defects.updateWithOptionalDefects(optionalDefects, isFiltered)
    defects.updateWithDoneDefects(defectsDone)
    defects.updateWithVisualDefects(visualDefects)
    defects.updateWithMachineDefects(machineDefects)
    return defects
  }
  /**
   *
   * @param {import('types/TestValue').TestValues[]} testsValues
   * @param {string | null} testName
   */
  static #foundTestValueMachine(testsValues, testName) {}
  /**
   *
   * @param {OptionalDefects} defectos_opcionales
   * @param {boolean} isFiltered
   * @returns
   */
  updateWithOptionalDefects(defectos_opcionales, isFiltered) {
    if (defectos_opcionales == null) return
    /**@type {{id: string, value: boolean}[]} */
    const listToAppy = []
    for (const key in defectos_opcionales) {
      if (Object.hasOwnProperty.call(defectos_opcionales, key)) {
        listToAppy.push({ id: key, value: defectos_opcionales[key] })
      }
    }
    this.updateFatherAndChildDefectsApplyAndShow(listToAppy, isFiltered)
  }

  updateWithDoneDefects(defectos_vistos) {
    if (defectos_vistos == null) return
    for (const defect of defectos_vistos) {
      this.setFatherAndChildDone(defect.id, defect.user)
      this.updateFatherAndChildDefectsApply({
        id: defect.id,
        value: !defect.noApply,
      })
    }
  }

  updateWithVisualDefects(defectos_visuales) {
    if (defectos_visuales != null) {
      for (const defect of defectos_visuales) {
        this.addVisualDefect(defect)
      }
    }
  }

  /**
   *
   * @param {MachineDefect[]} machineDefects
   */
  updateWithMachineDefects(machineDefects) {
    if (machineDefects == null) return
    for (const node of this.listDefects()) {
      for (const machineDefect of machineDefects) {
        if (node.id === machineDefect.defect.id) {
          node.isFiltered = false
          node.machineDefect = machineDefect
        }
      }
    }
  }

  getFathers() {
    const nodes = []
    for (const [_, chapter] of this.#defects) {
      if (chapter.childs == null) continue
      for (const [_, father] of chapter.childs) {
        nodes.push(father)
      }
    }
    return nodes
  }

  getFathersShowDefect(filtered = false) {
    const fatherPrimitiveShow = []
    for (const [_, chapter] of this.#defects) {
      if (chapter.childs == null) continue
      for (const [_, father] of chapter.childs) {
        if (filtered && !father.defect.apply && !father.defect.done) continue
        fatherPrimitiveShow.push(father.toPrimitiveWithoutChildsAndFather())
      }
    }
    return fatherPrimitiveShow
  }

  /**
   * Devuelve lista de todos los defectos de la sección
   * @param {string | null | undefined} [id]
   */
  listDefects(id) {
    if (id == null) {
      return this.#listDefects(this.#defects)
    }
    const node = this.findDefectAndChilds(id)

    if (node == null || (node.defect == null && node.childs == null)) {
      return []
    }
    if (node.childs == null && node.defect != null) return [node.defect]

    if (node.childs && node.defect == null) return this.#listDefects(node.childs)
    if (node.childs && node.defect) return [node.defect, ...this.#listDefects(node.childs)]
    return []
  }

  listAllDefectsShow() {
    return this.#listDefectsShow(this.#defects)
  }
  listAllDefectsShowOnlyShow() {
    return this.#listDefectsShowOnlyShow(this.#defects)
  }

  /**
   *
   * @return {InspectionDefect[]}
   */
  listDefectsDone() {
    return this.#listDefectsDone(this.#defects)
  }

  /**
   *
   * @param {SectionDefectsTree} defects
   * @return {InspectionDefect[]}
   */
  #listDefectsDone(defects) {
    /**@type {InspectionDefect[]} */
    const list = []
    for (const [_, node] of defects) {
      if (node.defect && node.defect.done === true) {
        list.push(node.defect)
      }
      if (node.childs) {
        list.push(...this.#listDefectsDone(node.childs))
      }
    }
    return list
  }

  listDefectsApply() {
    return this.#listDefectsApply(this.#defects, true)
  }

  listDefectsNotApply() {
    return this.#listDefectsApply(this.#defects, false)
  }

  /**
   *
   * @param {SectionDefectsTree} defects
   * @param {boolean} apply
   * @return {InspectionDefect[]}
   */
  #listDefectsApply(defects, apply) {
    /**@type {InspectionDefect[]} */
    const list = []
    for (const [_, node] of defects) {
      if (node.defect && node.defect.apply === apply) {
        list.push(node.defect)
      }
      if (node.childs) {
        list.push(...this.#listDefectsApply(node.childs, apply))
      }
    }
    return list
  }

  /**
   *
   * @return {InspectionDefect[]}
   */
  toPrimitives() {
    return this.listDefects()
  }

  /**
   *
   * @param {SectionDefectsTree} defects
   * @return {InspectionDefect[]}
   */
  #listDefects(defects) {
    /**@type {InspectionDefect[]} */
    const list = []
    for (const [_, node] of defects) {
      if (node.defect) {
        list.push(node.defect)
      }
      if (node.childs) {
        list.push(...this.#listDefects(node.childs))
      }
    }
    return list
  }

  /**
   *
   * @param {SectionDefectsTree} defects
   * @return {import('types/ManualManager').DefectToShow[]}
   */
  #listDefectsShow(defects) {
    /**@type {import('types/ManualManager').DefectToShow[]} */
    const list = []
    for (const [_, node] of defects) {
      if (node.defect) {
        list.push(node.toPrimitiveWithoutChildsAndFather())
      }
      if (node.childs) {
        list.push(...this.#listDefectsShow(node.childs))
      }
    }
    return list
  }

  /**
   *
   * @param {SectionDefectsTree} defects
   * @return {import('types/ManualManager').DefectToShow[]}
   */
  #listDefectsShowOnlyShow(defects) {
    /**@type {import('types/ManualManager').DefectToShow[]} */
    const list = []
    for (const [_, node] of defects) {
      if (node.defect && node.defect.apply) {
        list.push(node.toPrimitiveWithoutChildsAndFather())
      }
      if (node.childs) {
        list.push(...this.#listDefectsShowOnlyShow(node.childs))
      }
    }
    return list
  }

  /**
   * @param {string} id
   * @param {string} user
   */
  setFatherDone(id, user) {
    const node = this.findDefectAndChilds(id)
    if (!node) throw ErrorForDefect.sectionIdNotExist(id)
    this.#setFatherDone(node, true, user)
  }

  /**
   *
   * @param {string} id
   * @param {string} user
   */
  setFatherAndChildDone(id, user) {
    if (user == null) throw ErrorForDefect.valueFormatInvalid({ key: 'user', value: user })
    const father = this.getFather(id)
    if (father == null) throw ErrorForDefect.notHaveFather(id)
    if (father.defect.user && father.defect.user !== user) {
      throw ErrorForDefect.defectDoneWithOtherUser(father.defect.user, user)
    }
    father.setThisAndChildsDone(true, user)
  }

  /**
   * Set Father only Father Not Done. Not check anything
   * @param {string} id
   * @param {string} user
   */
  setFatherUndone(id, user) {
    const node = this.findDefectAndChilds(id)
    if (!node) throw ErrorForDefect.sectionIdNotExist(id)
    this.#setFatherDone(node, false, user)
  }

  /**
   * retrun Father/Unit from id
   * @param {string} id
   * @return {SectionNode | null }
   */
  getFather(id) {
    const defectId = DefectId.formPrimitive(id)
    const fatherId = defectId.father
    if (fatherId == null) return null
    const father = this.findDefectAndChilds(fatherId)
    return father
  }

  /**
   * @param {string} id
   */
  isFatherDone(id) {
    const defectId = DefectId.formPrimitive(id)
    const fatherId = defectId.father
    if (fatherId == null) throw ErrorForDefect.notHaveFather(id)
    const father = this.findDefectAndChilds(fatherId)
    if (father?.defect == null) throw ErrorForDefect.notHaveFather(id)
    return father.defect.done
  }

  /**
   * Actualiza el padre sin comprobar nada
   * @param {SectionNode | null} node
   * @param {boolean} value
   * @param {string} user
   */
  #setFatherDone(node, value, user) {
    if (!node) return
    if (node.defect && node.defect.parentOrderDepth === 2) {
      node.defect.done = value
      node.defect.user = user
    }
    if (node.father) {
      this.#setFatherDone(node.father, value, user)
    }
    return
  }

  /**
   * Actualiza el valor de aplica del padre/unidad y sus hijos a partir de una lista o uno solo
   * @param {{id: string, value: boolean}[] | {id: string, value: boolean}} ids
   */
  updateFatherAndChildDefectsApply(ids) {
    if (Array.isArray(ids)) {
      for (const state of ids) {
        const fatherId = DefectId.formPrimitive(state.id).father
        if (fatherId == null) {
          continue
        }
        const node = this.findDefectAndChilds(fatherId)
        if (node != null) {
          node.setThisAndChildsApply(state.value)
        }
      }
      return
    }
    const father = this.getFather(ids.id)
    if (father != null) {
      father.setThisAndChildsApply(ids.value)
    }
    return
  }

  /**
   * Actualiza el valor de aplica del padre/unidad y sus hijos a partir de una lista o uno solo
   * @param {{id: string, value: boolean}[] | {id: string, value: boolean}} ids
   * @param {boolean} isFiltered
   */
  updateFatherAndChildDefectsApplyAndShow(ids, isFiltered) {
    if (Array.isArray(ids)) {
      for (const state of ids) {
        const fatherId = DefectId.formPrimitive(state.id).father
        if (fatherId == null) {
          continue
        }
        const node = this.findDefectAndChilds(fatherId)
        if (node != null) {
          node.setThisAndChildsApplyAndShow(isFiltered ? state.value : true)
        }
      }
      return
    }
    const father = this.getFather(ids.id)
    if (father != null) {
      father.setThisAndChildsApplyAndShow(isFiltered ? ids.value : true)
    }
    return
  }

  /**
   *
   * @param {string} id
   * @returns - Number of childs for defect
   */
  getNumberOfChilds(id) {
    const node = this.findDefectAndChilds(id)
    if (node == null) throw ErrorForDefect.sectionIdNotExist()
    return node.childs?.size
  }

  /**
   *
   * @param {string} id
   * @returns - Number of childs that shows for defect
   */
  getNumberOfChildsThatShows(id) {
    const node = this.findDefectAndChilds(id)
    if (node == null) throw ErrorForDefect.sectionIdNotExist()
    const childs = node.toPrimitiveWithoutChildsAndFather()
    return node.childs?.size
  }

  /**
   * @param {string} id
   */
  getNumberOfDescendats(id) {
    const node = this.findDefectAndChilds(id)
    if (node == null) throw ErrorForDefect.sectionIdNotExist()
    return this.#getNumberOfDescendats(node)
  }

  /**
   *
   * @param {SectionNode} sectionNode
   */
  #getNumberOfDescendats(sectionNode) {
    if (sectionNode.childs == null) return 0
    let numberOfDescendats = sectionNode.childs.size
    for (const [id, node] of sectionNode.childs) {
      numberOfDescendats += this.#getNumberOfDescendats(node)
    }
    return numberOfDescendats
  }

  /**
   *
   * @param {string} id
   */
  setThisAndChildsDone(id) {
    const sectionNode = this.findDefectAndChilds(id)
    if (sectionNode == null) throw ErrorForDefect.sectionIdNotExist(id)
    const sectionId = SectionId.formPrimitive(id)
    if (sectionNode.defect != null) {
      sectionNode.defect.done = true
    }
    this.#setChildsDone(sectionId, sectionNode.childs, true)
  }

  /**
   *
   * @param {SectionId} sectionId
   * @param {SectionDefectsTree | null} defects
   * @param {boolean} value
   */
  #setChildsDone(sectionId, defects, value) {
    if (defects == null) {
      return
    }
    for (const [_, section] of defects) {
      if (section.defect != null) {
        section.defect.done = value
      }
      this.#setChildsDone(sectionId, section.childs, value)
    }
  }

  /**
   *
   * @param {string | string[]} id
   */
  apply(id) {
    this.#setApply(id, true)
  }

  /**
   *
   * @param {string | string[]} id
   */
  notApply(id) {
    this.#setApply(id, false)
  }

  /**
   *
   * @param {string | string[]} ids
   * @param {boolean} apply
   */
  #setApply(ids, apply) {
    if (typeof ids === 'string') {
      const section = this.findDefectAndChilds(ids)
      if (section?.defect == null) {
        throw ErrorForDefect.sectionIdNotExist(ids)
      }
      section.defect.apply = apply
      return
    }
    if (Array.isArray(ids)) {
      for (const id of ids) {
        this.#setApply(id, apply)
      }
      return
    }
    throw ErrorForDefect.invalidIdFormat(ids)
  }
  /**
   *
   * @param {string} defectId
   */
  findDefectAndChilds(defectId) {
    const sectionId = SectionId.formPrimitive(defectId)
    return this.#find(sectionId, this.#defects, 1)
  }

  /**
   * @param {SectionId} sectionId
   * @param {SectionDefectsTree} defects
   * @param {number} depth
   * @returns { SectionNode | null}
   */
  #find(sectionId, defects, depth) {
    if (sectionId.parentOrderDepth === depth) {
      const node = defects.get(sectionId.toPrimitive())
      return node ?? null
    }
    const parentId = sectionId.getParentIdFormDepth(depth)
    if (parentId == null) {
      return null
    }
    const node = defects.get(parentId)
    if (node?.childs == null) return null
    return this.#find(sectionId, node.childs, depth + 1)
  }

  /**
   *
   * @param {ClassificationAndCategoryVO} categoryAndClasification
   * @param {Filter[]} filters
   */
  static #getOptionalFilter(categoryAndClasification, filters) {
    return new OptionalFilter(categoryAndClasification, filters)
  }

  /**
   *
   * @param {InspectionDefect} defect
   */
  addDefect(defect) {
    this.#searchAndInsert(this.#defects, defect, null, 1)
  }

  /**
   *
   * @param {SectionDefectsTree} defects
   * @param {InspectionDefect} defect
   * @param {SectionNode | null} father
   * @param {number} depth
   */
  #searchAndInsert(defects, defect, father, depth) {
    if (depth === defect.parentOrderDepth) {
      const defectNode = defects.get(defect.id)
      if (defectNode) {
        defectNode.defect = defect
        return
      }
      defects.set(defect.id, new SectionNode({ defect: defect, childs: null, father }))
      return
    }
    const depthFamilyId = defect.getParentIdFormDepth(depth)
    if (depthFamilyId == null) return
    const defectNode = defects.get(depthFamilyId)
    if (defectNode) {
      if (defectNode.childs == null) {
        defectNode.childs = new Map()
      }
      this.#searchAndInsert(defectNode.childs, defect, defectNode, depth + 1)
      return
    }
    const newDefectNode = new SectionNode({
      defect: new InspectionDefect(
        DefectId.formPrimitive(depthFamilyId),
        '',
        null,
        defect.apply,
        defect.done,
        defect.optional,
        true,
        true,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      ),
      childs: new Map(),
      father,
    })
    defects.set(depthFamilyId, newDefectNode)
    if (newDefectNode.childs != null) {
      this.#searchAndInsert(newDefectNode.childs, defect, newDefectNode, depth + 1)
    }
    return
  }

  /**
   *
   * @param {VisualDefect} visualDefect
   */
  addVisualDefect(visualDefect) {
    if (
      this.isThereAVisualDefectOfTheSameParentThatIsNotTheUser(visualDefect.user, visualDefect.id)
    ) {
      throw ErrorForDefect.existOneVisualDefectOnSameFatherThatIsNotTheSameUser()
    }
    const node = this.findDefectAndChilds(visualDefect.id)
    if (node?.defect == null) {
      throw ErrorForDefect.sectionIdNotExist(visualDefect.id)
    }

    const father = this.getFather(visualDefect.id)
    if (father == null) {
      throw ErrorForDefect.notHaveFather(visualDefect.id)
    }
    if (father.defect?.user && father.defect?.user !== visualDefect.user) {
      throw ErrorForDefect.defectDoneWithOtherUser(father.defect.user, visualDefect.user)
    }
    node.defect.addVisualDefect(visualDefect)
    try {
      this.setFatherDone(visualDefect.id, visualDefect.user)
      this.apply(father.defect.id)
    } catch (error) {
      node.defect.deleteVisualDefect(visualDefect.id, visualDefect.user)
      throw error
    }
  }

  /**
   *
   * @param {string} user
   * @param {string} id
   * @returns
   */
  isThereAVisualDefectOfTheSameParentThatIsNotTheUser(user, id) {
    const fatherId = DefectId.formPrimitive(id).father
    if (fatherId == null) throw ErrorForDefect.notHaveFather(id)
    const father = this.findDefectAndChilds(fatherId)
    if (father == null) throw ErrorForDefect.notHaveFather(id)
    if (father.childs)
      return this.#isThereAVisualDefectOfTheSameParentThatIsNotTheUser(father.childs, user)
    return false
  }

  /**
   *
   * @param {SectionDefectsTree} defects
   * @param {string} user
   */
  #isThereAVisualDefectOfTheSameParentThatIsNotTheUser(defects, user) {
    if (defects == null) return false
    for (const [id, node] of defects) {
      if (
        node.defect?.user != null &&
        node.defect.haveVisualDefect() &&
        node.defect.user !== user
      ) {
        return true
      }
      if (node.childs) {
        if (this.#isThereAVisualDefectOfTheSameParentThatIsNotTheUser(node.childs, user) === true) {
          return true
        }
      }
    }
    return false
  }

  /**
   *
   * @param {VisualDefect} visualDefect
   */
  deleteVisualDefect(visualDefect) {
    const node = this.findDefectAndChilds(visualDefect.id)
    if (node?.defect == null) {
      throw ErrorForDefect.sectionIdNotExist(visualDefect.id)
    }
    node.defect.deleteVisualDefect(visualDefect.id, visualDefect.user)
    this.setFatherDone(visualDefect.id, visualDefect.user)
  }

  listAllVisualDefects() {
    return this.#listAllVisualDefects(this.#defects)
  }

  /**
   *
   * @param {SectionDefectsTree} defects
   */
  #listAllVisualDefects(defects) {
    const listVisualDefects = []

    for (const [id, node] of defects) {
      if (node.defect?.visualDefect != null) {
        listVisualDefects.push(node.defect.visualDefect)
      }
      if (node.childs != null) {
        listVisualDefects.push(this.#listAllVisualDefects(node.childs))
      }
    }
    return listVisualDefects
  }

  /**
   * Toggle the done state defect and return her new state or return null if not a father
   * @param {string} id
   * @param {string} user
   * @return {boolean | null} - Return new state of defect or null if the Defect not a father
   */
  toggleDefectDone(id, user) {
    const defectId = DefectId.formPrimitive(id)
    if (defectId.parentOrderDepth === 2) {
      const node = this.findDefectAndChilds(defectId.toPrimitive())
      if (node?.defect != null) {
        if (node.defect.done === true && node.defect.user && node.defect.user !== user) {
          throw ErrorForDefect.defectDoneWithOtherUser(node.defect.user)
        }
        node.defect.done = !node.defect.done
        node.defect.user = user
        return node.defect.done
      }
    }
    return null
  }
}

class FamilyOrther {
  static get father() {
    return 'FATHER'
  }
  static get son() {
    return 'SON'
  }
  static get grandson() {
    return 'GRANDSON'
  }
  static get greatGrandson() {
    return 'GREATGRANDSON'
  }
}
