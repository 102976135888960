import * as Yup from 'yup'
import moment from 'moment'

function checkPercentMax(value, method) {
  if (method === '%' && value > 100) return false
  return true
}

function checkApplyUntil(value) {
  console.log({ value })
  if (!value) return true
  if (!moment(value).isValid()) return false
  if (moment(value).isBefore(moment())) return false
  return true
}

export function Discount(active, method) {
  return Yup.object().shape({
    name: Yup.string()
      .required('Requerido')
      .min(1, '   ❌ MÍNIMO 1 CARACTER')
      .max(40, '   ❌ MÁXIMO 40 CARACTERES'),
    amount: Yup.number()
      .required('Requerido')
      .min(0.01, '   ❌ MÍNIMO 0,01')
      .test('checkPercentMax', '   ❌ UN PORCENTAJE NO PUEDE SER MAYOR DE 100', value =>
        checkPercentMax(value, method)
      ),
    minimumBenefit: Yup.number().min(0, '   ❌ MÍNIMO 0').nullable(),
    method: Yup.string().nullable(),
    description: Yup.string().max(200, '   ❌ MÁXIMO 200 CARACTERES').nullable(),
    applyFrom: Yup.string().nullable(),
    applyUntil: Yup.string()
      .test('checkApplyUntil', '   ❌ LA FECHA NO PUEDE SER ANTERIOR A AHORA', value =>
        active ? checkApplyUntil(value) : true
      )
      .nullable(true),
  })
}
