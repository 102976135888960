import React, { useState, useEffect, useCallback } from 'react'
import moment from 'moment'

// SERVICES
import { getUsers as getUsersService, putUser as putUserService } from 'services/Users'

// MY IMPORTS
import easyToast from 'components/Others/EasyToast/easyToast'
import MatTable from 'components/Others/MuiTable/MuiTable'
import './Users.scss'
import { useSelector } from 'react-redux'
import Modal from 'components/Others/Modal/Modal'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import UsersForm from './UsersForm/UsersForm'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import useService from 'hooks/useService'
import AddIcon from '@mui/icons-material/Add'

export default function Users() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [showOnlyActive, setShowOnlyActive] = useState(true)
  const [modal, setModal] = useState({ show: false, data: null, isNew: true })
  const [toggleStateModal, setToggleStateModal] = useState({ show: false, data: null })
  const reduxUser = useSelector(store => store?.user?.data)

  const { fetch: getUsers } = useService({
    service: getUsersService,
    setOutLoading: setLoading,
  })
  const { fetch: putUser } = useService({
    service: putUserService,
    setOutLoading: setLoading,
  })

  const toggleUserStatus = async userData => {
    try {
      if (!userData || !userData.id) throw new Error('No user data')
      if (!reduxUser.user) throw new Error('No redux user')
      if (userData.user === reduxUser.user)
        return easyToast('error', 'No puedes desactivarte a ti mismo')
      const fd = new FormData()
      fd.append('username', userData.username)
      fd.append('active', !userData.active)
      await putUser(fd)
      await fetchUsers()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error actualizando el estado del usuario')
    }
  }
  const fetchUsers = useCallback(async () => {
    try {
      const res = await getUsers()
      console.log({ users: res })
      setData(res)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los usuarios')
    }
  }, [getUsers])

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers])

  return (
    <section className="users">
      <MatTable
        columnsKey="users"
        values={showOnlyActive ? data?.filter(elem => elem.active) || [] : data}
        loading={loading}
        sortByDate={false}
        showToolbar={false}
        onRowClick={rowData => setModal({ show: true, data: rowData, isNew: false })}
        toolbarComponent={
          <>
            <Checkbox
              label="Solo activos"
              checked={showOnlyActive}
              onChange={value => setShowOnlyActive(value)}
            />
            <MyButtonsContainer fullWidth={false} margin="0 0 0 auto" styles={{ width: 'auto' }}>
              <MyButton
                icon={<AddIcon />}
                text="Añadir usuario"
                onClick={() => setModal({ show: true, data: null, isNew: true })}
              />
            </MyButtonsContainer>
          </>
        }
        options={{
          filtering: false,
          selection: false,
          grouping: false,
        }}
      />
      {modal?.show && (
        <UsersForm
          data={modal.data}
          isNew={modal.isNew}
          userInfo={reduxUser}
          allUsers={data}
          closeModal={() => {
            setModal({ show: false, data: null, isNew: true })
            fetchUsers()
          }}
        />
      )}
      <Modal
        open={toggleStateModal?.show}
        onClose={() => setToggleStateModal({ show: false, data: null })}
        title={`${toggleStateModal.data?.active ? '¿Desactivar' : '¿Activar'} a ${
          toggleStateModal.data?.username
        } - ${toggleStateModal.data?.fullname}?`}
        onAccept={() => toggleUserStatus(toggleStateModal?.data)}
      />
    </section>
  )
}
