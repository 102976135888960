//@ts-check
import React, { useState, useEffect } from 'react'
import './TireEquivalence.scss'
import Modal from 'components/Others/Modal/Modal'
import Input from 'components/Others/Input/Input'
import Switch from 'components/Others/Switch/Switch'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import Separator from 'components/Others/Separator/Separator'
import Select from 'components/Others/Select/Select'
import { tireEquivalence } from 'resources/selectsValues'
import PrintWrapper from 'components/Others/PrintWrapper/PrintWrapper'

/**
 *
 * @param {{closeModal: function, flaps?: boolean}} param0
 * @returns
 */
export default function TireEquivalence({ closeModal, flaps = false }) {
  const [tire1, setTire1] = useState({
    width: '',
    profile: '',
    rim: '',
    diameter: '',
    manual: true,
    tireLawSelected: null,
  })
  const [tire2, setTire2] = useState({
    width: '',
    profile: '',
    rim: '',
    diameter: '',
    manual: true,
    tireLawSelected: null,
  })
  const [distanceBetweenAxles, setDistanceBetweenAxles] = useState('')
  const [equivalence, setEquivalence] = useState(null)
  const [flapsNeeded, setFlapsNeeded] = useState(null)

  const calcDiameter = (width, profile, rim) => {
    try {
      return ((width * profile) / 100) * 2 + rim * 25.4
    } catch (err) {
      console.error(err)
      return 0
    }
  }

  const calcEquivalence = (diameter1, diameter2) => {
    // Calcula la equivalencia de la siguiente manera: Si diámetro de rueda2 es mayor o menor que diámetro de rueda 1 con un margen de tolerancia del 3% entonces NO son equivalentes
    try {
      const tolerance = 0.03
      const diff = Math.abs(diameter1 - diameter2)
      const percentage = diff / diameter1
      return percentage <= tolerance
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const calcFlapsNeeded = (distanceBetweenAxles, diameter1, diameter2) => {
    try {
      if (!distanceBetweenAxles) return null
      const diff = Math.abs(distanceBetweenAxles) - (diameter1 / 2 + diameter2 / 2)
      return diff >= 250
    } catch (err) {
      console.error(err)
      return null
    }
  }

  useEffect(() => {
    if (tire1.manual) {
      let diameter1 = 0

      if (tire1.width && tire1.profile && tire1.rim) {
        diameter1 = calcDiameter(tire1.width, tire1.profile, tire1.rim)
      }
      setTire1(prevState => ({ ...prevState, diameter: diameter1 || '' }))
    }
    if (tire2.manual) {
      let diameter2 = 0
      if (tire2.width && tire2.profile && tire2.rim) {
        diameter2 = calcDiameter(tire2.width, tire2.profile, tire2.rim)
      }
      setTire2(prevState => ({ ...prevState, diameter: diameter2 || '' }))
    }
  }, [
    tire1.diameter,
    tire1.manual,
    tire1.profile,
    tire1.rim,
    tire1.width,
    tire2.manual,
    tire2.profile,
    tire2.rim,
    tire2.width,
  ])

  useEffect(() => {
    if (tire1.diameter && tire2.diameter) {
      setEquivalence(calcEquivalence(tire1.diameter, tire2.diameter))

      setFlapsNeeded(calcFlapsNeeded(distanceBetweenAxles, tire1.diameter, tire2.diameter))
    } else {
      setFlapsNeeded(null)
    }
  }, [distanceBetweenAxles, tire1.diameter, tire2.diameter])

  return (
    <Modal
      open={true}
      title={flaps ? 'Cálculo de faldillas intermedias' : 'Cálculo de equivalencia de neumáticos'}
      hasCloseButton={false}
      onClose={closeModal}
      closeOnOverlay={false}
      content={
        <PrintWrapper>
          <div className="tire-equivalence">
            <div className="tire-equivalence__form-wrapper">
              <div className="tire-equivalence__form">
                <span>{flaps ? 'Neumático anterior' : 'Neumático de ficha'}</span>
                <Switch
                  label="Reglamento"
                  label2="Milimétrico"
                  value={tire1.manual}
                  onChange={checked => {
                    setTire1(prevState => ({
                      ...prevState,
                      manual: checked,
                      diameter: checked
                        ? ''
                        : prevState.tireLawSelected?.value
                        ? prevState.tireLawSelected.value
                        : '',
                    }))
                  }}
                />

                <div className="tire-equivalence__inputs-wrapper">
                  {tire1.manual && (
                    <>
                      <Input
                        isFormikInput={false}
                        label="Ancho"
                        value={tire1.width}
                        type="number"
                        unit="mm"
                        onChange={e =>
                          setTire1(prevState => ({ ...prevState, width: e.target.value }))
                        }
                      />
                      <Input
                        isFormikInput={false}
                        label="Perfil"
                        value={tire1.profile}
                        type="number"
                        unit="%"
                        onChange={e =>
                          setTire1(prevState => ({ ...prevState, profile: e.target.value }))
                        }
                      />
                      <Input
                        isFormikInput={false}
                        label="Llanta"
                        value={tire1.rim}
                        type="number"
                        unit="in"
                        onChange={e =>
                          setTire1(prevState => ({ ...prevState, rim: e.target.value }))
                        }
                      />
                    </>
                  )}
                  {!tire1.manual && (
                    <Select
                      label="Designación del tamaño"
                      options={tireEquivalence}
                      value={tire1.tireLawSelected}
                      onChange={opt =>
                        setTire1(prevState => ({
                          ...prevState,
                          diameter: opt?.value ?? '',
                          tireLawSelected: opt ?? null,
                        }))
                      }
                    />
                  )}
                </div>

                <Separator />
                <CardNameValue
                  name="Diámetro"
                  value={tire1.diameter ? tire1.diameter + ' mm' : '-'}
                />
              </div>
              <div className="tire-equivalence__form">
                <span>{flaps ? 'Neumático posterior' : 'Neumático que monta'}</span>
                <Switch
                  label="Reglamento"
                  label2="Milimétrico"
                  value={tire2.manual}
                  onChange={checked => {
                    setTire2(prevState => ({
                      ...prevState,
                      manual: checked,
                      diameter: checked
                        ? ''
                        : prevState.tireLawSelected?.value
                        ? prevState.tireLawSelected.value
                        : '',
                    }))
                  }}
                />
                <div className="tire-equivalence__inputs-wrapper">
                  {tire2.manual && (
                    <>
                      <Input
                        isFormikInput={false}
                        label="Ancho"
                        value={tire2.width}
                        type="number"
                        unit="mm"
                        onChange={e =>
                          setTire2(prevState => ({ ...prevState, width: e.target.value }))
                        }
                      />
                      <Input
                        isFormikInput={false}
                        label="Perfil"
                        value={tire2.profile}
                        type="number"
                        unit="%"
                        onChange={e =>
                          setTire2(prevState => ({ ...prevState, profile: e.target.value }))
                        }
                      />
                      <Input
                        isFormikInput={false}
                        label="Llanta"
                        value={tire2.rim}
                        type="number"
                        unit="in"
                        onChange={e =>
                          setTire2(prevState => ({ ...prevState, rim: e.target.value }))
                        }
                      />
                    </>
                  )}
                  {!tire2.manual && (
                    <Select
                      label="Designación del tamaño"
                      options={tireEquivalence}
                      value={tire2.tireLawSelected}
                      onChange={opt =>
                        setTire2(prevState => ({
                          ...prevState,
                          diameter: opt?.value ?? '',
                          tireLawSelected: opt ?? null,
                        }))
                      }
                    />
                  )}
                </div>
                <Separator />
                <CardNameValue
                  name="Diámetro"
                  value={tire2.diameter ? tire2.diameter + ' mm' : '-'}
                />
              </div>
            </div>
            {!flaps && (
              <div className="tire-equivalence__results">
                <CardNameValue
                  className={
                    equivalence == null
                      ? 'tire-equivalence__equivalence'
                      : equivalence
                      ? 'tire-equivalence__equivalence--valid'
                      : 'tire-equivalence__equivalence--invalid'
                  }
                  name="Resultado de equivalencia"
                  value={
                    equivalence == null
                      ? 'Introduzca los valores necesarios'
                      : equivalence
                      ? 'NEUMÁTICO EQUIVALENTE'
                      : 'NEUMÁTICO NO EQUIVALENTE'
                  }
                />
              </div>
            )}
            {flaps && (
              <>
                <Separator />
                <Input
                  isFormikInput={false}
                  label="Distancia entre ejes"
                  value={distanceBetweenAxles}
                  type="number"
                  unit="mm"
                  onChange={e => setDistanceBetweenAxles(e.target.value)}
                />
                <div className="tire-equivalence__results">
                  <CardNameValue
                    className={
                      flapsNeeded == null
                        ? 'tire-equivalence__equivalence'
                        : flapsNeeded
                        ? 'tire-equivalence__equivalence--valid'
                        : 'tire-equivalence__equivalence--invalid'
                    }
                    name="Resultado de faldillas intermedias"
                    value={
                      flapsNeeded == null
                        ? 'Introduzca los valores necesarios'
                        : flapsNeeded
                        ? 'NECESITA FALDILLAS INTERMEDIAS'
                        : 'NO NECESITA FALDILLAS INTERMEDIAS'
                    }
                  />
                </div>
              </>
            )}
          </div>
        </PrintWrapper>
      }
    />
  )
}
