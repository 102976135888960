export default function changeDateFormat(initialDate) {
  try {
    let date = new Date(initialDate)

    let day = date.getDate()
    let month = date.getMonth() + 1
    let year = date.getFullYear()

    return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`
  } catch (err) {
    console.error(err)
  }
}
