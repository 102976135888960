import Axios from 'axios'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export default function postLogin(data, options = {}) {
  return Axios.post(`${SERVER_URL}/${v2}login`, data, {
    signal: options.signal,
  })
}
