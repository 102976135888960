import React, { useState } from 'react'
import Select from 'react-select'
import Filters from 'Share/Filters'
import { selectStyles } from 'components/Others/Select/SelectStyles'
import useReformManual from 'hooks/useReformManual'

export default function ReformModal({ filters, vehicleData, setReforms }) {
  const [values, setValues] = useState(null)
  const sectionNumber = new Filters({
    filters: filters,
    category: vehicleData.category,
    classification: vehicleData.classification,
  })?.filter?.section
  const { reformsManualSectionSelectOptions } = useReformManual(sectionNumber)

  return (
    <div>
      <Select
        noOptionsMessage={() => 'No hay opciones'}
        menuPortalTarget={document.body}
        styles={selectStyles}
        isSearchable
        isClearable
        isMulti
        placeholder="Escribe para buscar..."
        options={reformsManualSectionSelectOptions}
        value={values}
        onChange={opt => {
          setValues(opt)
          setReforms(opt)
        }}
      />
    </div>
  )
}
