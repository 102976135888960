//@ts-check
import { useCallback, useState, useRef, useEffect } from 'react'
import easyToast from 'components/Others/EasyToast/easyToast'
import isValidFunction from 'myMethods/isValidFunction'
import handleUnauthorizedResponse from 'myMethods/handleUnauthorizedResponse'

/**
 * @template T
 * @param {{services: ((...arg0: any[]) => Promise<T>)[], servicesParams?: any[][], setOutLoading?: function, handleResponseData?: function, throwMessage?: string, fullResponse?: boolean, notThrowWhenError?: boolean}} param0
 *
 * @returns {{serviceData: T[], loading: boolean, fetch: (values: any[]) => Promise<T[]>, status: number[] | undefined, errorMessage: string[] | undefined}}
 */
export default function useMultipleServices({
  services,
  servicesParams,
  setOutLoading,
  handleResponseData,
  throwMessage = '',
  fullResponse = false,
  notThrowWhenError = false,
}) {
  const [state, setState] = useState({
    loading: false,
    res: [],
    status: [],
    errorMessage: [],
  })
  let controllerRef = useRef(null)

  useEffect(() => {
    controllerRef.current = new AbortController()
    return () => {
      if (state.loading) {
        controllerRef?.current?.abort()
      }
    }
  }, [state.loading])

  const fetch = useCallback(
    async (params = []) => {
      try {
        if (!Array.isArray(services) || services.some(service => !isValidFunction(service))) {
          throw new Error('One or more services are not valid functions')
        }

        setState(prevState => ({ ...prevState, loading: true }))
        if (isValidFunction(setOutLoading)) setOutLoading(true)

        const finalParams = params.length > 0 ? params : servicesParams
        const responses = await Promise.all(
          services.map((service, index) =>
            service(finalParams[index], { signal: controllerRef.current.signal })
          )
        )
        console.log({ responses })
        isValidFunction(handleResponseData) && handleResponseData(responses.map(res => res.data))
        setState(prevState => ({
          ...prevState,
          res: responses.map(res => res.data),
          status: responses.map(res => res.status),
          errorMessage: responses.map(() => null),
        }))

        return fullResponse ? responses : responses.map(res => res.data)
      } catch (err) {
        console.error(err)
        setState(prevState => ({
          ...prevState,
          res: [],
          status: services.map(() => err.response?.status ?? null),
          errorMessage: services.map(() => err.message),
        }))
        if (err.response?.status === 401) handleUnauthorizedResponse()
        if (throwMessage) easyToast('error', throwMessage)
        if (!notThrowWhenError) throw err
      } finally {
        setState(prevState => ({ ...prevState, loading: false }))
        if (isValidFunction(setOutLoading)) setOutLoading(false)
      }
    },
    [
      services,
      servicesParams,
      setOutLoading,
      handleResponseData,
      throwMessage,
      fullResponse,
      notThrowWhenError,
    ]
  )

  return {
    serviceData: state.res,
    loading: state.loading,
    status: state.status,
    errorMessage: state.errorMessage,
    fetch,
  }
}
