import React, { useState } from 'react'
import MyCalendar from 'react-calendar'
import './RangeCalendar.scss'
import 'react-calendar/dist/Calendar.css'
import { Clear } from '@mui/icons-material'
import moment from 'moment'
import isValidFunction from 'myMethods/isValidFunction'
import HoverTooltip from '../HoverTooltip/HoverTooltip'

export default function RangeCalendar({
  onChange,
  onClear,
  dates,
  showSelectedDaysLength = true,
  maxDateSelectable = new Date(),
  markedDates,
  markedClassName,
  markedDatesLegendName,
  returnValuesInObject = true,
  compactStyle = false,
}) {
  const [values, setValues] = useState(() => {
    if (dates && Array.isArray(dates) && dates[0] && dates[1]) {
      return [new Date(dates[0]), new Date(dates[1])]
    }
    return null
  })
  console.log({ dates, values })
  const [selectedLength, setSelectedLength] = useState(0)

  let className = 'range-calendar'

  if (compactStyle) className += ' range-calendar--compact'

  const formatValuesToObject = values => {
    try {
      let [start, end] = values
      start = moment(start)
      end = moment(end)
      if (start.isValid() && end.isValid()) {
        start = start.format('YYYY-MM-DD')
        end = end.format('YYYY-MM-DD')
      } else {
        throw new Error('Invalid date format')
      }
      return { start, end }
    } catch (err) {
      console.error(err)
      return { start: '', end: '' }
    }
  }

  return (
    <div className={className}>
      <MyCalendar
        maxDate={maxDateSelectable}
        selectRange={true}
        onClickDay={() => {
          if (values?.length === 2) {
            setValues(null)
            onChange(null)
          }
        }}
        onChange={val => {
          setValues(val)
          isValidFunction(onChange) &&
            onChange(returnValuesInObject ? formatValuesToObject(val) : val)
          setSelectedLength(moment(val[1]).diff(moment(val[0]), 'days') + 1)
        }}
        value={values}
        tileClassName={({ date, view }) => {
          if (
            markedDates &&
            Array.isArray(markedDates) &&
            markedDates.find(elem => elem === moment(date).format('YYYY-MM-DD'))
          )
            return markedClassName || 'range-calendar__marked'
        }}
      />
      {values && (
        <HoverTooltip title="Limpiar">
          <div
            className="range-calendar__clear"
            onClick={() => {
              onChange(null)
              setValues(null)
              isValidFunction(onClear) && onClear()
            }}
          >
            <Clear />
          </div>
        </HoverTooltip>
      )}
      {markedDates && Array.isArray(markedDates) && (
        <div className="range-calendar__legend--marked">
          <span></span>
          <i>{markedDatesLegendName}</i>
        </div>
      )}
      {showSelectedDaysLength && values && selectedLength > 0 && (
        <div className="range-calendar__legend">
          <i>{`${selectedLength}${
            selectedLength > 1 ? ' días seleccionados' : ' día seleccionado'
          }`}</i>
        </div>
      )}
    </div>
  )
}
