import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'

import newInspectionReducer from 'redux/ducks/newInspectionDuck'
import userReducer from 'redux/ducks/userDuck'
import inspectionReducer from 'redux/ducks/inspectionDuck'
import chatReducer from 'redux/ducks/chatDuck'
import configReducer from 'redux/ducks/configDuck'
import cashRegisterReducer from 'redux/ducks/cashRegisterDuck'
import mintAIChatbotReducer from 'redux/ducks/mintAIChatbotDuck'
import resourcesReducer from 'redux/ducks/resourcesDuck'

const allReducers = combineReducers({
  newVehicle: newInspectionReducer,
  cashRegister: cashRegisterReducer,
  inspection: inspectionReducer,
  mintAIChatbot: mintAIChatbotReducer,
  user: userReducer,
  chat: chatReducer,
  config: configReducer,
  resources: resourcesReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    state = undefined
  }

  return allReducers(state, action)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default function generateStore() {
  const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
  return store
}
