//@ts-check
import React, { useEffect } from 'react'
import { Tooltip } from '@mui/material'
import generateUniqueString from 'myMethods/generateUniqueString'
import './HoverTooltip.scss'

/**
 *
 * @param {{id?: string | number, title?: any, text?: any, placement?: string, children?: any, open?: boolean, lowZIndex?: boolean, followCursor?: boolean, arrow?:boolean}} param0
 * @returns
 */
export default function HoverTooltip({
  id,
  title,
  text,
  placement = 'bottom',
  children,
  open,
  lowZIndex = false,
  followCursor = false,
  arrow = true,
  ...rest
}) {
  id = id ?? generateUniqueString()
  title = title ?? text

  useEffect(() => {
    const $elem = document.getElementById(id)
    if (lowZIndex && $elem) {
      $elem.style.zIndex = 1
    }

    return () => {
      if ($elem) $elem.style.zIndex = 999
    }
  }, [id, lowZIndex])
  return (
    <Tooltip
      id={id}
      open={open}
      title={title}
      placement={placement}
      followCursor={followCursor}
      arrow={arrow}
      {...rest}
    >
      {children}
    </Tooltip>
  )
}
