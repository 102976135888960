//@ts-check
import React, { useEffect, useMemo, useState, useRef } from 'react'
import ITVCardSelector from './ITVCardSelector/ITVCardSelector'
import Title2 from 'components/Others/Title2/Title2'
import ITVCardModelsJSON from 'resources/ITVCardModels.json'
import ITVCardForm from './ITVCardForm/ITVCardForm'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { Stars as AIIcon } from 'react-bootstrap-icons'
import ITVCardRecognition from './ITVCardRecognition'

/**
 * @typedef {import('types/ITVCard').ModelsTypes} IModels
 */

/**
 *
 * @param {{reduxInspection: import('types/Inspection').Inspection, onlyReforms?: boolean, reforms: import('types/Inspection').InspectionConfigReforms}} param0
 * @returns
 */
export default function ITVCard({ reduxInspection, onlyReforms = false, reforms, ...rest }) {
  /**@type {[IModels, (value: IModels) => void]} */
  const [selectedCardModel, setSelectedCardModel] = useState()
  const captureImageRef = useRef()
  const [recognizedValues, setRecognizedValues] = useState(null)

  /**@type {import('types/ITVCard').Models} */
  const ITVCardModels = ITVCardModelsJSON

  const cardModels = useMemo(() => {
    return Object.keys(ITVCardModels) || []
  }, [ITVCardModels])

  const cardInputs = useMemo(() => {
    return selectedCardModel ? ITVCardModels[selectedCardModel] || [] : []
  }, [ITVCardModels, selectedCardModel])

  useEffect(() => {
    if (!selectedCardModel && reduxInspection?.filter?.section !== null) {
      if (reduxInspection.filter.section === '1') {
        setSelectedCardModel('A')
      }
      if (reduxInspection.filter.section === '2') {
        setSelectedCardModel('AL')
      }
    }
    if (onlyReforms) {
      setSelectedCardModel('REFORMAS')
    }
  }, [onlyReforms, reduxInspection.filter?.section, selectedCardModel])

  return (
    <section className="itv-card" {...rest}>
      <Title2
        text={onlyReforms ? 'Impresión de reformas en Tarjeta ITV' : 'Generador de tarjetas ITV'}
      />
      <MyButtonsContainer>
        <MyButton
          text="Reconocer tarjeta con IA"
          icon={<AIIcon />}
          onClick={() => captureImageRef?.current?.capture()}
        />
      </MyButtonsContainer>
      <ITVCardRecognition ref={captureImageRef} setRecognizedValues={setRecognizedValues} />

      {!onlyReforms && (
        <ITVCardSelector
          selectedCardModel={selectedCardModel}
          onSelectedCardModelChange={setSelectedCardModel}
          cardModels={cardModels.filter(model => model !== 'REFORMAS')}
        />
      )}
      {selectedCardModel && (
        <>
          <ITVCardForm
            model={selectedCardModel}
            inputBoxes={cardInputs}
            vehicleData={reduxInspection?.vehicleData}
            onlyReforms={onlyReforms}
            reforms={reforms}
            recognizedValues={recognizedValues}
          />
        </>
      )}
    </section>
  )
}
