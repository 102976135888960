import useGuidedTour from 'hooks/useGuidedTour'
import React from 'react'
import Joyride from 'react-joyride'
import './GuidedTourWrapper.scss'
import getCssVariable from 'myMethods/getCssVariable'

const GuidedTourWrapper = ({ tourKey, children }) => {
  const { steps, run, stepIndex, startTour, handleJoyrideCallback } = useGuidedTour(tourKey)

  return (
    <>
      <Joyride
        steps={steps}
        run={run}
        stepIndex={stepIndex}
        continuous
        showProgress
        showSkipButton
        callback={handleJoyrideCallback}
        locale={{
          back: 'Atrás',
          close: 'Cerrar',
          last: 'Entendido',
          next: 'Siguiente',
          skip: 'Saltar',
        }}
        styles={{
          options: {
            zIndex: 999999999,
            arrowColor: getCssVariable('--main-color'),
            primaryColor: getCssVariable('--main-color'),
            maxWidth: 600,
            width: 'auto',
          },
        }}
      />
      {React.cloneElement(children, { startTour })}
    </>
  )
}

export default GuidedTourWrapper
