//@ts-check
import React, { useEffect, useState } from 'react'

// MY IMPORTS
import './DeepSearch.scss'
import Modal from '../Modal/Modal'
import isValidFunction from 'myMethods/isValidFunction'
import MatTable from '../MuiTable/MuiTable'
import useServiceByChunk from 'hooks/useServiceByChunk'
import TableFilter from '../TableFilter/TableFilter'
import TablePagination from '../MuiTable/TablePagination/TablePagination'

/**
 *
 * @param {object} param0
 * @param {string | undefined} param0.title
 * @param {string | undefined} param0.message
 * @param {string | undefined} param0.message2
 * @param {boolean} param0.open
 * @param {Function} param0.onClose
 * @param {Function} param0.onAccept
 * @param {Function} param0.service
 * @param {import('types/FilterServiceParams').FilterServiceOptions} param0.filterServiceOptions
 * @param {import('types/FilterServiceParams').FilterServiceParams} param0.forcedFilterValues
 * @param {import('types/TableColumnsKeys').TableColumnsKeys} param0.columnsKey
 * @param {boolean | undefined} param0.multiselection
 * @param {number[] | undefined} param0.idsToExclude
 */
export default function DeepSearch({
  title = null,
  message = null,
  message2 = null,
  open,
  onClose,
  onAccept,
  service,
  filterServiceOptions,
  forcedFilterValues,
  columnsKey,
  multiselection = false,
  idsToExclude,
}) {
  const [selection, setSelection] = useState([])
  const {
    data,
    total,
    filteredTotal,
    fetch,
    fetchNextChunk,
    clearFilter,
    loading,
    currentFilterValues,
  } = useServiceByChunk({ service, forcedFilterValues })

  useEffect(() => {
    fetch()
  }, [fetch])

  const Component = () => (
    <div className="deep-search">
      <MatTable
        tableFilter={
          <TableFilter
            totalValues={total}
            initialFilterValues={currentFilterValues}
            loading={loading}
            onSubmit={filterValues => {
              fetch(null, filterValues)
            }}
            onClear={clearFilter}
            onClose={() => {
              if (
                !data?.length &&
                (!currentFilterValues || !Object.keys(currentFilterValues).length)
              )
                onClose()
            }}
            {...filterServiceOptions}
          />
        }
        columnsKey={columnsKey}
        values={idsToExclude ? data?.filter(item => !idsToExclude.includes(item.id)) : data}
        loading={loading}
        showToolbar={false}
        onRowClick={rowData => {
          if (!multiselection) {
            isValidFunction(onAccept) && onAccept(rowData)
            isValidFunction(onClose) && onClose()
          }
        }}
        onSelectionChange={setSelection}
        options={{
          selection: multiselection,
        }}
        toolbarComponent={
          <>
            <TablePagination
              currentDataLength={data?.length ?? 0}
              totalDataLength={filteredTotal || total}
              onClick={fetchNextChunk}
            />
          </>
        }
      />
    </div>
  )
  console.log({ data, filteredTotal, total })
  return (
    <Modal
      title={title}
      open={open}
      message={message}
      message2={message2}
      onClose={onClose}
      hasAcceptButton={multiselection && selection.length > 0}
      onAccept={() => onAccept(selection)}
      content={<Component />}
    />
  )
}
