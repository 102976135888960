import React, { isValidElement } from 'react'
import './ListItemsInColumns.scss'
import { AnimatePresence, motion } from 'framer-motion'
import AnimatedListElem from '../AnimatedList/AnimatedListElem'

/**
 *
 * @param {{children: any, title?: any, className?: string, parentClassName?: string, childrenClassName?: string, noDataMessage?: string, noColumnsOnPc?: boolean, animate?: boolean}} param0
 * @returns
 */
export default function ListItemsInTwoColumns({
  children,
  title,
  className = '',
  noDataMessage = 'Sin datos',
  noColumnsOnPc = false,
  animate = false,
}) {
  if (Array.isArray(children)) {
    children = children.filter(child => child)
    if (!children.length) children = null
    else {
      // Comprueba si algún hijo es un array y si es así inserta su contenido
      for (const [index, child] of children.entries()) {
        if (Array.isArray(child)) {
          children.splice(index, 1)
          child.forEach(c => children.push(c))
        }
      }
    }
  }
  return (
    <div
      className={`list-items-in-two-columns__container ${
        className ? className + '__container' : ''
      }`}
    >
      {title && (
        <div
          data-aos={animate ? 'fade-in' : ''}
          className={`list-items-in-two-columns__title ${className ? className + '__title' : ''}`}
        >
          {title}
        </div>
      )}
      {animate && Array.isArray(children) && children.length > 0 && (
        <motion.div
          className={
            noColumnsOnPc
              ? `list-items-in-two-columns--no-columns ${
                  className ? className + '--no-columns' : ''
                }`
              : `list-items-in-two-columns ${className ? className : ''}`
          }
        >
          <AnimatePresence>
            {children.map((item, i) => {
              return (
                item &&
                isValidElement(item) && (
                  <AnimatedListElem
                    index={i}
                    key={i}
                    className={`list-items-in-two-columns__elem ${
                      className ? className + '__elem' : ''
                    }`}
                  >
                    {item}
                  </AnimatedListElem>
                )
              )
            })}
          </AnimatePresence>
        </motion.div>
      )}
      {!animate && Array.isArray(children) && children.length > 0 && (
        <div
          className={
            noColumnsOnPc
              ? `list-items-in-two-columns--no-columns ${
                  className ? className + '--no-columns' : ''
                }`
              : `list-items-in-two-columns ${className ? className : ''}`
          }
        >
          {children.map((item, i) => {
            return (
              item &&
              isValidElement(item) && (
                <div
                  key={i}
                  className={`list-items-in-two-columns__elem ${
                    className ? className + '__elem' : ''
                  }`}
                >
                  {item}
                </div>
              )
            )
          })}
        </div>
      )}
      {children && !Array.isArray(children) && (
        <div className={`list-items-in-two-columns__elem ${className ? className + '__elem' : ''}`}>
          {children}
        </div>
      )}
      {!children && (
        <div className="list-items-in-two-columns__no-data">
          <div className="no-values">{noDataMessage}</div>
        </div>
      )}
    </div>
  )
}
