//@ts-check
import moment from 'moment'
import DateTimeITV from './DateTimeITV'

/**
 * @typedef {import("types/TestValue").TestValues} TestValues
 * @typedef {import("types/TestValue").TestValuesData} TestValuesData
 * @typedef {import('types/HardwareConfig').HardwareConfig} HardwareConfig

 * */
export default class TestsValues {
  /**
   * @param {TestValues[]} testsValues
   */
  constructor(testsValues) {
    this.testsValues = testsValues
  }

  /**
   * @param {string} testName
   */
  findTest(testName) {
    return this.testsValues.find(elem => elem.testName === testName)
  }

  /**
   *
   * @param {string} testName
   * @returns {{test: TestValuesData, outIndex?:number, inIndex?:number, manual?: boolean}}
   */
  getLastTestValues(testName, checkCancellation = false) {
    try {
      const outIndex = this.testsValues?.findIndex(elem => elem.testName === testName)
      let test = this.findTest(testName)

      if (!test) throw new Error('No test found')
      const lastTest =
        test?.data
          ?.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
          ?.reverse()[0] || null

      if (!lastTest) throw new Error('No test found with name: ' + testName)
      if (lastTest.cancellation && checkCancellation)
        return { test: null, outIndex: -1, inIndex: -1 }
      const inIndex = this.testsValues
        ?.find(elem => elem.testName === testName)
        ?.data?.findIndex(elem => elem.date === lastTest.date)
      console.log({ test })
      if (inIndex && outIndex && !(outIndex >= 0 && inIndex >= 0))
        throw new Error('No in our outIndex')
      return {
        test: lastTest,
        outIndex,
        inIndex,
        manual: test.manual,
      }
    } catch (err) {
      return { test: null, outIndex: -1, inIndex: -1 }
    }
  }

  /**
   * @description Devuelve la última máquina usada en los tests pasados por parámetro
   * @param {string[]} testNames
   * @param {import('types/Inspection').Machine[]} possibleMachines
   * @param {boolean=} checkCancellation
   * @returns
   */
  getLastMachineUsedByTestNamesAndListOfPossibleMachines(
    testNames,
    possibleMachines,
    checkCancellation = false
  ) {
    try {
      if (!testNames?.length || !possibleMachines?.length)
        throw new Error('No testNames or possibleMachines received')
      console.log({ testNames, possibleMachines })
      let testsValues = []
      testNames.forEach(testName => {
        const testValues = this.getLastTestValues(testName, checkCancellation)?.test
        console.log({ machineFound: testValues?.machine })
        if (
          testValues &&
          testValues.machine &&
          possibleMachines.some(machine => machine.id[testName] === testValues.machine.id)
        )
          testsValues.push(testValues)
      })
      if (!testsValues?.length) return null
      const lastTest = testsValues
        .sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())
        .reverse()[0]
      return lastTest.machine
    } catch (err) {
      console.error(err)
      return null
    }
  }

  /**
   * @param {string[] | undefined} testNames
   *
   * @returns {TestValues[] | null}
   */
  getTestsDataByTestNames(testNames) {
    try {
      if (!testNames?.length) throw new Error('No props received')
      const data = []
      this.testsValues.forEach(test => {
        if (
          testNames.includes(test.testName) &&
          !data.some(elem => elem.testName === test.testName)
        ) {
          data.push(test)
        }
      })
      return data
    } catch (err) {
      console.error(err)
      return null
    }
  }

  /**
   * @param {string} testName
   */
  isThisTestDone(testName) {
    try {
      let done = false
      if (!testName) throw new Error('No testName provided')
      const lastTest = this.getLastTestValues(testName)
      console.log({ lastTest })
      if (!lastTest?.test) return null
      else {
        if (!lastTest.manual && lastTest.test.values) done = true
        if (lastTest.manual && lastTest.test.values.some(elem => elem.value)) done = true
        if (lastTest.test.cancellation) done = false
      }
      return { done, manual: lastTest.manual }
    } catch (err) {
      console.error(err)
      return null
    }
  }

  /**
   *
   * @param {string} testName
   */
  getLastTestDuration(testName) {
    try {
      if (!testName) throw new Error('No testName provided')
      const lastTest = this.getLastTestValues(testName, true)
      if (lastTest?.test?.dates?.send && lastTest?.test?.dates?.read) {
        const stringDate = DateTimeITV.getFormatedElapsedTime(
          lastTest.test.dates.send,
          lastTest.test.dates.read
        )
        return stringDate || null
      }
      return null
    } catch (err) {
      console.error(err)
      return null
    }
  }

  getAllTestsRemarks() {
    try {
      let remarks = []
      if (this.testsValues?.length) {
        this.testsValues.forEach(test => {
          const lastTest = this.getLastTestValues(test.testName, true)
          if (lastTest && lastTest.test.remarks?.length) {
            lastTest.test.remarks.forEach(remark =>
              remarks.push({ testName: test.testName, remark })
            )
          }
        })
      }
      return remarks
    } catch (err) {
      console.error(err)
      return null
    }
  }
}
