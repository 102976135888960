import { useState, useEffect } from 'react'
import ClassificationValidator from 'Share/ClassificationValidator'
import Modal from 'components/Others/Modal/Modal'
import React from 'react'
import './EditFilterClassificationsModal.scss'
import { Add, Clear } from '@mui/icons-material'
import Tooltip from '@mui/material/Tooltip'
import easyToast from 'components/Others/EasyToast/easyToast'
import { useDispatch } from 'react-redux'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import ClassificationsSelector from 'components/Others/ClassificationsSelector/ClassificationsSelector'

export default function EditFilterClassificationsModal({
  closeModal,
  allFilters,
  filter,
  saveChanges,
}) {
  const [loading, setLoading] = useState(false)
  const [inputValue, setInputValue] = useState(null)
  const [text, setText] = useState('')
  const [isValid, setIsValid] = useState(false)
  const [values, setValues] = useState(null)
  const [tooltipText, setTooltipText] = useState(null)
  const dispatch = useDispatch()

  const handleInputChange = value => {
    try {
      setInputValue(value)
      const validation = new ClassificationValidator(value).validate(false)
      setIsValid(validation.isValid)
      setText(validation.text || 'INSERTE UNA CLASIFICACIÓN')
    } catch (err) {
      console.error(err)
    }
  }

  const addClassification = () => {
    try {
      const validation = new ClassificationValidator(inputValue).validate(false)
      if (!validation.isValid) throw new Error('Invalid classification')
      if (values.includes(inputValue))
        return easyToast('error', 'Esta clasificación ya ha sido agregada')
      setValues([...values, inputValue])
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error al agregar la clasificación')
      console.error(err)
    }
  }

  const deleteClassification = value => {
    try {
      let newValues = [...values]
      newValues.splice(newValues.indexOf(value), 1)
      setValues(newValues)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error al eliminar la clasificación')
    }
  }

  const validateClassifications = () => {
    if (!allFilters?.length) throw new Error('Missing allFilters in validateClassifications')
    for (const value of values) {
      // Busca la clasificación en otro filtro
      const duplicatedClassificationFilters = allFilters.filter(
        elem => elem.classifications.includes(value) && elem.name !== filter.name
      )
      console.log({ duplicatedClassificationFilters })
      if (duplicatedClassificationFilters) {
        // Comprueba que no tengan una misma categoría
        let duplicatedCategoryIndex = -1
        const duplicatedCategoryFilter = duplicatedClassificationFilters.find(elem => {
          const index = elem.categories.findIndex(cat => filter.categories.includes(cat))
          if (index !== -1) {
            duplicatedCategoryIndex = index
            return true
          }
          return false
        })
        console.log({ duplicatedCategoryFilter })
        if (duplicatedCategoryIndex !== -1) {
          return {
            isValid: false,
            data: {
              filter: duplicatedCategoryFilter.name,
              classification: value,
              category: duplicatedCategoryFilter.categories[duplicatedCategoryIndex],
            },
          }
        }
      }
    }
    return { isValid: true, data: null }
  }

  const handleAccept = async () => {
    try {
      const validation = validateClassifications()
      if (!validation.isValid)
        return dispatch(
          setReduxConfigModal({
            error: true,
            message: `La classificacion ${
              validation.data.classification.length > 2
                ? validation.data.classification
                : validation.data.classification + 'xx'
            } ya está asignada al filtro ${validation.data.filter} con la categoría ${
              validation.data.category
            }. Si dos filtros tuvieran la misma categoría y clasificación habría conflictos en la inspección`,
            alertMessage:
              'Recuerde que la clasificación de cuatro dígitos tiene prioridad sobre la de dos dígitos',
          })
        )
      let newFilter = JSON.parse(JSON.stringify(filter))
      newFilter.classifications = values
      setLoading(true)
      await saveChanges(newFilter)
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error al guardar las clasificaciones')
      console.error(err)
    } finally {
      loading && setLoading(false)
    }
  }

  useEffect(() => {
    if (!values && filter?.classifications) {
      console.log('Entro useEffect cambiar clasificaciones')
      setValues(filter.classifications)
    }
  }, [filter?.classifications, values])
  return (
    <ClassificationsSelector
      closeModal={closeModal}
      classifications={values}
      handleAccept={handleAccept}
    />
  )
}
