import React, { useState, useEffect, useCallback } from 'react'

// SERVICES
import { getClientsByChunk } from 'services/Clients'

// MY IMPORTS
import MatTable from 'components/Others/MuiTable/MuiTable'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import TablePagination from 'components/Others/MuiTable/TablePagination/TablePagination'
import TableFilter from 'components/Others/TableFilter/TableFilter'
import ClientOptionsModal from './ClientOptionsModal/ClientOptionsModal'
import useServiceByChunk from 'hooks/useServiceByChunk'
import AddIcon from '@mui/icons-material/Add'

export default function Clients() {
  const [modal, setModal] = useState({ show: false, data: null, new: true })
  const {
    data: clients,
    total: totalData,
    fetch: fetchClients,
    fetchNextChunk,
    clearFilter,
    loading,
    currentFilterValues,
  } = useServiceByChunk({ service: getClientsByChunk })

  useEffect(() => {
    if (clients == null) {
      fetchClients()
    }
  }, [clients, fetchClients])
  return (
    <>
      <MatTable
        columnsKey="clients"
        tableFilter={
          <TableFilter
            totalValues={totalData}
            initialFilterValues={currentFilterValues}
            likeDni={true}
            likeName={true}
            loading={loading}
            onSubmit={filterValues => {
              fetchClients(null, filterValues)
            }}
            onClear={clearFilter}
          />
        }
        filterValues={currentFilterValues}
        onClearTableFilter={clearFilter}
        values={clients}
        onRowClick={rowData => {
          setModal({ show: true, data: rowData, new: false })
        }}
        loading={loading}
        showToolbar={false}
        exportation={{
          type: 'CLIENTS',
          data: { values: clients },
          modalTitle: 'Exportar clientes',
          defaultInputValue: 'CLIENTES',
        }}
        toolbarComponent={
          <>
            <MyButtonsContainer margin="0" styles={{ width: 'auto' }}>
              <MyButton
                text="Añadir cliente"
                onClick={() => setModal({ show: true, data: null, new: true })}
                icon={<AddIcon />}
              />
            </MyButtonsContainer>
            <TablePagination
              currentDataLength={clients?.length}
              totalDataLength={totalData}
              onClick={fetchNextChunk}
            />
          </>
        }
      />

      {modal.show && (
        <ClientOptionsModal
          clientData={modal?.data}
          refetch={fetchClients}
          closeModal={() => setModal({ show: false, data: null, new: true })}
          isNew={modal?.new}
        />
      )}
    </>
  )
}
