export default function handleUnauthorizedResponse() {
  try {
    if (window.sessionStorage.getItem('401Response') !== 'true') {
      window.sessionStorage.setItem('401Response', 'true')
      window.location.reload()
    }
  } catch (err) {
    console.error(err)
    throw err
  }
}
