import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export function getClientsByChunk(params, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}clients/match`, {
    ...getJWT(),
    params: params ?? {},
    signal: options.signal,
  })
}

export function getClientByDni(dni, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}clients/${dni}`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getClientsByDeepSearch(str, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}clients/dni_or_name/${str}`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function postClient(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}clients`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putClient(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}clients`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}
