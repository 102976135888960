import React, { Children, useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Pagination,
  Navigation,
  Mousewheel,
  Autoplay,
  EffectFade,
  EffectCube,
  EffectCoverflow,
  EffectFlip,
  EffectCreative,
  EffectCards,
} from 'swiper'
import 'swiper/css'
import 'swiper/css/bundle'
import './Slider.scss'
import { isMobile } from 'react-device-detect'

export default function Slider({
  children,
  autoslideToLastIndex = false,
  detectSlideOnFullContainer = false,
  slidesPerViewOnPc = 1,
  centeredSlides = true,
  pagination = true,
  navigation = false,
  navigationOnPc = false,
  mousewheel = false,
  autoplay = false,
  modules = [],
  effect = 'slide',
  ...rest
}) {
  const slides = useMemo(() => Children.toArray(children), [children])
  const initialSlideIndex = autoslideToLastIndex ? slides.length - 1 : 0

  const swiperModules = useMemo(() => {
    const mods = []

    if (pagination) mods.push(Pagination)
    if (navigation || (!isMobile && navigationOnPc)) mods.push(Navigation)
    if (mousewheel) mods.push(Mousewheel)
    if (autoplay) mods.push(Autoplay)

    switch (effect) {
      case 'fade':
        mods.push(EffectFade)
        break
      case 'cube':
        mods.push(EffectCube)
        break
      case 'coverflow':
        mods.push(EffectCoverflow)
        break
      case 'flip':
        mods.push(EffectFlip)
        break
      case 'creative':
        mods.push(EffectCreative)
        break
      case 'cards':
        mods.push(EffectCards)
        break
      default:
        break
    }

    modules.forEach(module => {
      if (SwiperCore[module]) {
        mods.push(SwiperCore[module])
      }
    })

    return mods
  }, [pagination, navigation, navigationOnPc, mousewheel, autoplay, effect, modules])

  SwiperCore.use(swiperModules)

  return (
    <Swiper
      effect={effect}
      key={slides.length}
      touchEventsTarget={detectSlideOnFullContainer ? 'container' : 'wrapper'}
      spaceBetween={0}
      slidesPerView={1}
      mousewheel={mousewheel}
      pagination={pagination && { clickable: true }}
      navigation={navigation || (!isMobile && navigationOnPc)}
      autoplay={autoplay ? { delay: 3000 } : false}
      breakpoints={{
        640: { slidesPerView: slides.length > 1 ? 2 : 1 },
        1024: { slidesPerView: slidesPerViewOnPc },
      }}
      centeredSlides={centeredSlides}
      initialSlide={initialSlideIndex}
      {...rest}
    >
      {slides.map((slide, i) => (
        <SwiperSlide key={i} className="slider__slide">
          {slide}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
