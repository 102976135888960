//@ts-check
import Accordion from 'components/Others/Accordion/Accordion'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import easyToast from 'components/Others/EasyToast/easyToast'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import Switch from 'components/Others/Switch/Switch'
import useService from 'hooks/useService'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setReduxConfigComplementaryEquipment } from 'redux/ducks/configDuck'
import {
  postComplementaryEquipment as postComplementaryEquipmentService,
  putComplementaryEquipment as putComplementaryEquipmentService,
} from 'services/Hardware'
import NewComplementaryEquipmentModal from './NewComplementaryEquipmentModal/NewComplementaryEquipmentModal'

/**
 *
 * @param {{complementaryEquipment: import('types/ComplementaryEquipment').ComplementaryEquipment[], setLoading: function}} param0
 * @returns
 */
export default function ComplementaryEquipmentConfigurator({ complementaryEquipment, setLoading }) {
  const dispatch = useDispatch()
  const [showAddModal, setShowAddModal] = useState(false)

  const { fetch: postComplementaryEquipment } = useService({
    service: postComplementaryEquipmentService,
    setOutLoading: setLoading,
  })
  const { fetch: putComplementaryEquipment } = useService({
    service: putComplementaryEquipmentService,
    setOutLoading: setLoading,
  })

  const addNew = async values => {
    try {
      values.id = values.id.toUpperCase()
      values.name = values.name.toUpperCase()
      const res = await postComplementaryEquipment(values)
      console.log({ res })
      dispatch(setReduxConfigComplementaryEquipment(res?.complementaryEquipments ?? null))
      easyToast('dark', `${values.name} añadido correctamente`)
    } catch (err) {
      console.error(err)
      easyToast(
        'error',
        'Ha ocurrido un error añadiendo el nuevo equipo complementario. Inténtelo de nuevo'
      )
    }
  }

  const Equipment = ({ data }) => {
    const [editable, setEditable] = useState(false)
    const [values, setValues] = useState({
      serialNumber: data.serialNumber ?? '',
      name: data.name ?? '',
      id: data.id ?? '',
      active: data.active,
      editable: false,
    })

    const saveChanges = async () => {
      try {
        if (!values.id) return easyToast('error', 'El equipo debe tener un ID')
        delete values.editable
        const res = await putComplementaryEquipment({
          internalId: data.internalId,
          data: { ...values, id: values.id.toUpperCase() },
        })
        console.log({ res })
        dispatch(setReduxConfigComplementaryEquipment(res?.complementaryEquipments ?? null))
        setEditable(false)
        easyToast('dark', `${data.name?.toUpperCase()} ${data.id} modificado correctamente`)
      } catch (err) {
        easyToast('error', 'Ha ocurrido un error guardando los datos. Inténtelo de nuevo')
        console.error(err)
      }
    }

    return (
      <div className="hardware-by-line__hardware">
        <ListItemsInTwoColumns className="hardware-by-line__hardware-inputs-container">
          <CardNameValue
            name="Activo"
            value={
              <Switch
                value={values.active}
                onChange={checked => setValues({ ...values, active: checked })}
                disabled={!editable}
              />
            }
          />
          <CardNameValue
            name="Nombre"
            value={
              <input
                id="name"
                value={values.name}
                onChange={e => setValues({ ...values, name: e.target.value })}
                disabled={!editable}
                maxLength={20}
              />
            }
          />
          <CardNameValue
            name="ID"
            value={
              <input
                id="id"
                value={values.id}
                onChange={e => setValues({ ...values, id: e.target.value })}
                disabled={!editable}
                maxLength={8}
              />
            }
          />
          <CardNameValue
            name="Número de serie"
            value={
              <input
                value={values.serialNumber}
                onChange={e => setValues({ ...values, serialNumber: e.target.value })}
                disabled={!editable}
              />
            }
          />
        </ListItemsInTwoColumns>

        <MyButtonsContainer margin="1.5rem auto 0 auto">
          <MyButton
            selected={editable}
            text={editable ? 'Guardar datos' : 'Habilitar edición'}
            icon={<i className={editable ? 'flaticon-diskette' : 'flaticon-pencil'} />}
            onClick={() => {
              editable ? saveChanges() : setEditable(true)
            }}
          />
        </MyButtonsContainer>
      </div>
    )
  }

  return (
    <>
      <div className="hardware-by-line">
        <MyButtonsContainer margin="0 auto 1rem">
          <MyButton
            text="Añadir nuevo"
            icon={<i className="flaticon-plus" />}
            onClick={() => setShowAddModal(true)}
          />
        </MyButtonsContainer>
        {!(complementaryEquipment?.length > 0) && (
          <div className="no-values">
            Aún no hay equipos complementarios, pero puedes crear uno nuevo en el botón de arriba
          </div>
        )}
        {complementaryEquipment?.length > 0 &&
          complementaryEquipment.map((ce, i) => (
            <Accordion
              key={i}
              className="hardware-by-line__accordion"
              title={
                <div className="hardware-by-line__accordion-title">
                  {ce.active != null && (
                    <div
                      className={
                        ce.active
                          ? 'hardware-by-line__accordion-status--active'
                          : 'hardware-by-line__accordion-status--not-active'
                      }
                    >
                      {ce.active ? 'ACTIVO' : 'NO ACTIVO'}
                    </div>
                  )}
                  {ce?.name?.toUpperCase() ?? '-'}
                </div>
              }
              titleWhenOpen={ce?.name?.toUpperCase() ?? '-'}
              elemKey={i}
              // eslint-disable-next-line react/no-children-prop
              children={<Equipment data={ce} />}
            />
          ))}
      </div>
      {showAddModal && (
        <NewComplementaryEquipmentModal
          handleAccept={addNew}
          closeModal={() => setShowAddModal(false)}
        />
      )}
    </>
  )
}
