import AnimatedCounter from 'components/Others/AnimatedCounter/AnimatedCounter'
import './KNumberFormatter.scss'
export default function KNumberFormatter({ number, unit = null, animate = false, className = '' }) {
  try {
    if (number == null || typeof number !== 'number' || isNaN(number))
      throw new Error('No number provided or number is not numeric')
    const hasK = Boolean(Math.abs(number) > 999)
    let result = hasK
      ? Math.sign(number) * (Math.abs(number) / 1000)
      : Math.sign(number) * Math.abs(number)
    if (!Number.isInteger(result)) {
      result = Number(hasK ? result.toFixed(1) : result.toFixed(2))
    }

    return (
      <div className={className ? className + ' k-number-formatter' : 'k-number-formatter'}>
        {animate && <AnimatedCounter number={result} fixedTo={hasK ? 1 : 2} />}
        {!animate && <div>{String(result).replace('.', ',')}</div>}
        {hasK && (
          <small style={{ color: 'gray', marginTop: 'auto', marginBottom: '0.05rem' }}>k</small>
        )}
        {unit && <span style={{ marginLeft: '0.2rem', fontSize: '0.8em' }}>{unit}</span>}
      </div>
    )
  } catch (err) {
    return (
      <div className={className ? className + ' k-number-formatter' : 'k-number-formatter'}>-</div>
    )
  }
}
