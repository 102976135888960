//@ts-check
import React, { useState, useEffect, useRef, useCallback } from 'react'
import './ChatbotFooter.scss'
import { Send as SendIcon } from '@mui/icons-material'
import easyToast from 'components/Others/EasyToast/easyToast'
import Textarea from 'components/Others/Textarea/Textarea'
import Input from 'components/Others/Input/Input'

/**
 *
 * @param {{chatbot: import('types/UseMintAIChatbot').UseMintAIChatbot, isMobile: boolean}} param0
 * @returns
 */
export default function ChatbotFooter({ chatbot, isMobile }) {
  const loading = chatbot.loadingChatbot || chatbot.loadingResponse
  const [message, setMessage] = useState('')
  const textareaRef = useRef()
  const handleSubmit = useCallback(
    async text => {
      try {
        if (!text || loading) return

        chatbot.sendMessage(text)
        setMessage('')
      } catch (err) {
        console.error(err)
        easyToast('error', 'Ha ocurrido un error enviando la pregunta')
      }
    },
    [chatbot, loading]
  )
  useEffect(() => {
    let timeoutId
    const cb = e => {
      if (e.key === 'Enter' && e.altKey) {
        let start = textareaRef.current?.selectionStart
        let end = textareaRef.current?.selectionEnd

        setMessage(
          prevState =>
            `${prevState.substring(0, start)}\n${prevState.substring(end, prevState.length)}`
        )
        timeoutId = setTimeout(() => {
          textareaRef.current.setSelectionRange(start + 1, start + 1)
        }, 50)
      } else if (e.key === 'Enter' && !e.altKey) {
        if (document.activeElement === textareaRef.current) {
          e.preventDefault()
          handleSubmit(e.target.value)
        }
      }
    }
    document.addEventListener('keydown', cb)

    return () => {
      document.removeEventListener('keydown', cb)
      clearTimeout(timeoutId)
    }
  }, [handleSubmit])

  if (!chatbot?.data) return <></>
  else
    return (
      <div className={isMobile ? 'chatbot-footer--mobile' : 'chatbot-footer'}>
        {chatbot.data.isAvailable && (
          <>
            <Input
              type="textarea"
              isFormikInput={false}
              inputRef={textareaRef}
              value={message}
              placeholder="Pregunta algo..."
              onChange={e => {
                setMessage(e.target.value)
              }}
              maxLength={2000}
            />

            <div
              onClick={() => handleSubmit(message)}
              className={loading ? 'chatbot-footer__send--disabled' : 'chatbot-footer__send'}
            >
              <SendIcon />
            </div>
          </>
        )}
        {/* {!chatbot.data.isAvailable && <OnlyRead />}
        {chatbot.data.isAvailable && !subscriptionStatus?.canSendMessages && (
          <ReachedMessagesLimit />
        )} */}
      </div>
    )
}
