import easyToast from 'components/Others/EasyToast/easyToast'
import { isMobile } from 'react-device-detect'
import downloadFile from './downloadFile'

/**
 * Abre un PDF en una nueva ventana y personaliza el título de la ventana.
 *
 * @param {Blob} PDF - PDF en formato Blob.
 * @param {string | undefined} filename - Título de la ventana.
 * @returns - Abre el PDF en una nueva ventana.
 */
export default function openPDF(PDF, filename = 'documento', type = 'blob') {
  try {
    if (!PDF) throw new Error('No PDF received')
    if (filename) filename = filename + '.pdf'
    if (isMobile) {
      return downloadFile(PDF, filename, type)
    }
    const fileURL = PDF instanceof Blob ? URL.createObjectURL(PDF) : PDF
    const pdfWindow = window.open('', '_blank', 'resizable,scrollbars')

    const html = `
      <html>
        <head>
          <title>${filename}</title>
        </head>
        <body style="margin:0">
          <embed width="100%" height="100%" src="${fileURL}" type="application/pdf">
        </body>
      </html>
    `

    pdfWindow.document.write(html)
    pdfWindow.document.close()
  } catch (err) {
    console.error(err)
    easyToast(
      'error',
      'Ha ocurrido un error cargando el PDF. Inténtelo de nuevo o recargue la página'
    )
  }
}
