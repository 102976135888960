//@ts-check
import React, { useState, useRef } from 'react'
import FloatingButton from 'components/Others/FloatingButton/FloatingButton'
import {
  Speedometer as SpeedometerIcon,
  Camera as CameraIcon,
  Check as EndInspectionIcon,
} from 'react-bootstrap-icons'
import { useHistory, useParams } from 'react-router-dom'
import CaptureLicense from '../CaptureLicense/CaptureLicense'
import CaptureSpeedometer from '../CaptureSpeedometer/CaptureSpeedometer'
import CaptureImage from '../CaptureImage/CaptureImage'
import Calculations from '../Calculations/Calculations'
import AddRemarkModal from '../Remarks/AddRemarkModal/AddRemarkModal'

export default function InspectionFloatingButton() {
  const history = useHistory()
  const params = useParams()
  const captureImageRef = useRef()
  const [modalsState, setModalsState] = useState({
    license: false,
    speedometer: false,
    remarks: false,
  })
  return (
    <>
      <FloatingButton
        actions={[
          {
            icon: <EndInspectionIcon />,
            text: 'Finalizar inspección',
            onClick: () => history.replace(`/new_inspection/${params.license}/check`),
          },
          {
            icon: <CameraIcon />,
            text: 'Capturar matrícula',
            onClick: () => setModalsState(prevState => ({ ...prevState, license: true })),
          },
          {
            icon: <SpeedometerIcon />,
            text: 'Lectura de cuentakilómetros',
            onClick: () => setModalsState(prevState => ({ ...prevState, speedometer: true })),
          },
          {
            icon: <CameraIcon />,
            text: 'Capturar imagen',
            onClick: () => captureImageRef.current.triggerCapture(),
          },
          {
            icon: <i className="flaticon-message" />,
            text: 'Añadir observación',
            onClick: () => setModalsState(prevState => ({ ...prevState, remarks: true })),
          },
          {
            icon: <i className="flaticon-calculator" />,
            text: 'Cálculos',
            onClick: () => setModalsState(prevState => ({ ...prevState, calculations: true })),
          },
        ]}
      />
      <CaptureImage ref={captureImageRef} typeSelectable={true} />
      {modalsState.license && (
        <CaptureLicense
          closeModal={() => setModalsState(prevState => ({ ...prevState, license: false }))}
        />
      )}
      {modalsState.speedometer && (
        <CaptureSpeedometer
          closeModal={() => setModalsState(prevState => ({ ...prevState, speedometer: false }))}
        />
      )}
      {modalsState.calculations && (
        <Calculations
          closeModal={() => setModalsState(prevState => ({ ...prevState, calculations: false }))}
        />
      )}
      {modalsState.remarks && (
        <AddRemarkModal
          closeModal={() => setModalsState(prevState => ({ ...prevState, remarks: false }))}
        />
      )}
    </>
  )
}
