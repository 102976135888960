import React, { useState, useEffect, useMemo } from 'react'

// MY IMPORTS
import 'components/Supervision/NewSupervision/Sign/Sign.scss'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import { useHistory, useParams } from 'react-router-dom'
import { Pencil as PencilIcon } from 'react-bootstrap-icons'
import { useDispatch } from 'react-redux'

// SERVICES
import {
  getReport as getReportService,
  postReportPreview as postReportPreviewService,
} from 'services/Reports'
import { putEndSupervision as putEndSupervisionService } from 'services/useCases'
import Id from 'Share/Id'
import PDFViewer from 'components/Others/PDFViewer/PDFViewer'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import useService from 'hooks/useService'
import DateTimeITV from 'Share/DateTimeITV'
import { setReduxInspectionUserNextInspectionDate } from 'redux/ducks/inspectionDuck'
import ErrorView from 'components/Others/ErrorView/ErrorView'

export default function Sign({ redux, onlyRead, ...rest }) {
  const [loadingPDF, setLoadingPDF] = useState(false)
  const [pdf, setPdf] = useState(null)
  const [capsLock, setCapsLock] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [pass, setPass] = useState('')
  const [disabledDate, setDisabledDate] = useState(!redux?.inspection?.userNextInspectionDate)
  const nextInspectionDate = useMemo(
    () =>
      redux?.inspection?.userNextInspectionDate || redux?.inspection?.nextInspectionDate
        ? new DateTimeITV(
            redux?.inspection?.userNextInspectionDate || redux?.inspection?.nextInspectionDate
          )?.toStringDate()
        : '',
    [redux?.inspection?.userNextInspectionDate, redux?.inspection?.nextInspectionDate]
  )

  const reportNumber = redux.inspection.reportNumber
    ? redux.inspection.rev
      ? `${redux.inspection.reportNumber}-${redux.inspection.rev}`
      : redux.inspection.reportNumber
    : ''
  const inspectionGrade = redux?.inspection?.grade || '-'
  const history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()

  const { fetch: fetchReportPDF } = useService({
    service: getReportService,
    setOutLoading: setLoadingPDF,
  })
  const { fetch: postReportPreview } = useService({
    service: postReportPreviewService,
    setOutLoading: setLoadingPDF,
  })
  const { fetch: putEndSupervision, loading } = useService({
    service: putEndSupervisionService,
  })

  console.log({ nextInspectionDate })

  const handleSignClick = () => {
    try {
      const date = redux?.inspection?.userNextInspectionDate
      if (date) {
        const elapsedDays = new DateTimeITV(date).diffDays(new DateTimeITV())
        if (elapsedDays < 1)
          return easyToast(
            'error',
            'La fecha de próxima inspección no puede ser menor o igual a la fecha actual'
          )
        if (elapsedDays < 180)
          easyToast(
            'warn',
            'Ha puesto una fecha de próxima inspección inferior a 6 meses. ¿Está seguro?'
          )
      }
      setShowModal(true)
    } catch (err) {
      console.error(err)
    }
  }

  const finish = async () => {
    try {
      setShowModal(true)
      const res = await putEndSupervision({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        pass,
        userNextInspectionDate: redux.inspection.userNextInspectionDate ?? undefined,
      })
      console.log({ res })
      setShowModal(false)
      easyToast('dark', 'Informe firmado correctamente')
      history.replace(`/new_supervision/${params?.license}/${res.historyId}`)
    } catch (err) {
      if (err.response?.status === 403) {
        easyToast('error', 'Contraseña errónea')
        setPass('')
        return setShowModal(true)
      } else {
        easyToast('error', 'Ha ocurrido un error firmando el informe')
        console.error(err)
        setShowModal(false)
        setShowErrorModal(true)
      }
    }
  }
  useEffect(() => {
    try {
      if (!pdf) {
        if (onlyRead) {
          fetchReportPDF(Id.decrypt(window.sessionStorage.getItem('historyId'))).then(res => {
            if (res && res instanceof Blob) {
              return setPdf(res)
            }
          })
        } else {
          postReportPreview({
            id: Id.decrypt(window.sessionStorage.getItem('id')),
          }).then(res => {
            if (res && res instanceof Blob) {
              return setPdf(res)
            }
          })
        }
      }
    } catch (error) {
      easyToast('error', 'Ha ocurrido un error cargando el PDF del informe')
    }
  }, [
    fetchReportPDF,
    inspectionGrade,
    onlyRead,
    pdf,
    postReportPreview,
    redux?.inspection?.reportId,
  ])
  return (
    <section className="sign" {...rest}>
      <div className="sign__grade">
        EL RESULTADO DE LA INSPECCIÓN {onlyRead ? 'FUE' : 'ES'}
        <span className={inspectionGrade === 'FAVORABLE' ? '' : '--fail'}>
          {' ' + inspectionGrade}
        </span>
      </div>

      <div className="sign__periodicity">
        <span>
          {nextInspectionDate ? 'Fecha de próxima inspección: ' : 'Sin fecha de próxima inspección'}
        </span>
        {(nextInspectionDate || !disabledDate) && (
          <input
            type="date"
            disabled={disabledDate}
            value={nextInspectionDate}
            onChange={e =>
              dispatch(
                setReduxInspectionUserNextInspectionDate(new DateTimeITV(e.target.value).toUTC())
              )
            }
          />
        )}
        {disabledDate && !onlyRead && (
          <PencilIcon className="sign__periodicity-edit" onClick={() => setDisabledDate(false)} />
        )}
      </div>
      {!onlyRead && (
        <>
          <MyButtonsContainer>
            <MyButton
              text={`FIRMAR INFORME ${reportNumber ? '#' + reportNumber : ''}`}
              onClick={handleSignClick}
              loading={loadingPDF}
              disabled={!pdf}
            />
          </MyButtonsContainer>
        </>
      )}
      {!loadingPDF && !pdf && (
        <ErrorView text="Ha ocurrido un error generando el informe" errorColor={true} />
      )}

      {pdf && (
        <PDFViewer
          pdf={pdf}
          show={Boolean(pdf)}
          errorMessage="Ha ocurrido un error cargando el informe. Recargue la página"
          openable={true}
          loading={loadingPDF}
        />
      )}

      <Modal
        className="sign__modal"
        open={showModal}
        onClose={() => setShowModal(false)}
        title={!loading && 'Inserte la contraseña del certificado digital'}
        message={!loading && 'Si no tiene contraseña, deje el campo en blanco'}
        alertMessage={!loading && capsLock ? '⇪  Mayúsculas activadas' : ''}
        onAccept={finish}
        closeAfterAccept={false}
        buttons={!loading}
        content={
          <>
            {!loading && (
              <input
                value={pass}
                type="password"
                autoComplete="on"
                onKeyDown={e => {
                  setCapsLock(Boolean(e.getModifierState('CapsLock')))
                  if (e.key === 'Enter') {
                    finish()
                  }
                }}
                onChange={e => setPass(e.target.value)}
                placeholder="contraseña"
              />
            )}
            {loading && <ChildSpinner visible={true} style={{ padding: '3rem 8rem' }} />}
          </>
        }
      />
      <Modal
        open={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        message="Ha ocurrido un error terminando la inspección, ¿intentar de nuevo?"
        hasCloseButton={true}
        closeOnOverlay={false}
        onAccept={() => {
          setShowErrorModal(false)
          finish()
        }}
      />
    </section>
  )
}
