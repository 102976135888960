import React, { useState } from 'react'
import HoverTooltip from '../HoverTooltip/HoverTooltip'
import { PencilFill as EditIcon, TrashFill as DeleteIcon } from 'react-bootstrap-icons'
import DateTimeITV from 'Share/DateTimeITV'
import './ListCard.scss'
import 'aos/dist/aos.css'

/**
 * ListCard component that displays a card with content, date, and tags.
 * It allows editing and deleting the card.
 *
 * @param {Object} props - The properties object.
 * @param {string} props.id - The ID of the card.
 * @param {string} [props.className] - The class name to apply to the card.
 * @param {React.ReactNode} props.content - The content to display in the card.
 * @param {Function} [props.onDelete] - The function to call when the delete action is confirmed.
 * @param {Function} [props.onEdit] - The function to call when the edit action is triggered.
 * @param {boolean} [props.onlyRead=false] - Whether the card is in read-only mode.
 * @param {boolean} props.loading - Whether the card is in loading state.
 * @param {string} props.date - The date to display in the card.
 * @param {boolean} props.formatDateAgo - Whether to format the date as time ago.
 * @param {Array<{text?: string, icon?: any, color?: string}>} props.tags - The tags to display in the card.
 * @param {Function} [props.onClick] - The function to call when the card is clicked.
 *
 * @returns {React.ReactElement} The rendered card component.
 */
export default function ListCard({
  id,
  className,
  content,
  onDelete,
  onEdit,
  onlyRead = false,
  loading,
  date,
  formatDateAgo,
  tags,
  onClick,
}) {
  const [confirmAction, setConfirmAction] = useState(false)
  const showOptions = onDelete || onEdit

  if (onlyRead) {
    onDelete = undefined
    onEdit = undefined
  }

  const handleDeleteClick = e => {
    e.stopPropagation()
    setConfirmAction(true)
  }

  const handleClick = () => {
    if (confirmAction) return
    onClick?.()
  }

  const handleConfirm = confirm => {
    setConfirmAction(false)
    if (confirm) {
      onDelete?.(id)
    }
  }

  return (
    <div
      className={`list-card ${confirmAction ? 'list-card--confirm' : ''} ${className || ''}`}
      onClick={handleClick}
    >
      {confirmAction ? (
        <div className="list-card__confirm">
          <div className="list-card__confirm-buttons">
            <div className="contrast-on-hover" onClick={() => handleConfirm(false)}>
              Cancelar
            </div>
            <div className="contrast-on-hover" onClick={() => handleConfirm(true)}>
              Eliminar
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="list-card__body">
            <div className="list-card__content">{content}</div>
            {showOptions && (
              <div className="list-card__options">
                {onDelete && (
                  <HoverTooltip text="Eliminar">
                    <DeleteIcon
                      className="list-card__remove"
                      style={{
                        pointerEvents: loading ? 'none' : 'auto',
                      }}
                      onClick={handleDeleteClick}
                    />
                  </HoverTooltip>
                )}
                {onEdit && (
                  <HoverTooltip text="Editar">
                    <EditIcon
                      onClick={e => {
                        e.stopPropagation()
                        onEdit?.(id)
                      }}
                    />
                  </HoverTooltip>
                )}
              </div>
            )}
          </div>
          <div className="list-card__footer">
            {tags?.length > 0 && (
              <div className="list-card__tags">
                {tags.map((tag, i) => (
                  <div
                    key={i}
                    className="list-card__tag"
                    style={{ background: tag.color || undefined, color: '#fff' }}
                  >
                    {tag.icon && <div>{tag.icon}</div>}
                    {tag.text && <span>{tag.text}</span>}
                  </div>
                ))}
              </div>
            )}
            {date && (
              <div className="list-card__date">
                {formatDateAgo
                  ? DateTimeITV.formatDateToStringAgo(date)
                  : DateTimeITV.formatDate(date)}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
