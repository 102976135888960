import moment from 'moment'

export default function formatTimeToHoursMinutesSeconds(time, unit = 'milliseconds') {
  try {
    if (time == null) return '-'
    const duration = moment.duration(time, unit)
    let finalDate = ''

    if (duration._data.hours) finalDate += `${duration._data.hours}h `
    if (duration._data.minutes) finalDate += `${duration._data.minutes}min `
    if (duration._data.seconds) finalDate += `${duration._data.seconds}s `
    if (finalDate === '') return '0'
    return finalDate
  } catch (err) {
    console.error(err)
    return '-'
  }
}
