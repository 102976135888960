import React, { useState } from 'react'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import isValidFunction from 'myMethods/isValidFunction'
import Input from 'components/Others/Input/Input'

export default function TestCancellationModal({
  possibleReasons,
  testName,
  handleAccept,
  closeModal,
  loading,
}) {
  const [inputValue, setInputValue] = useState('')
  const [reason, setReason] = useState('')

  const checkAccept = () => {
    if (!reason) return easyToast('Seleccione una razón')
    if (reason?.toUpperCase() === 'OTRO' && !inputValue) return easyToast('Escriba una razón')
    if (reason?.toUpperCase() === 'OTRO') return handleAccept(inputValue?.toUpperCase())
    return handleAccept(reason)
  }
  console.log({ possibleReasons, testName, reason })

  if (!(possibleReasons?.length && testName)) {
    console.error('No possibleReasons or testName provided to TestCancellationModal')
    isValidFunction(closeModal) && closeModal()
    return <></>
  }
  return (
    <Modal
      open={true}
      onClose={() => {
        isValidFunction(closeModal) && closeModal()
      }}
      onAccept={checkAccept}
      hasAcceptButton={
        reason?.toUpperCase() === 'OTRO' ? Boolean(reason && inputValue) : Boolean(reason)
      }
      closeAfterAccept={false}
      title={`¿POR QUÉ ANULA ${testName?.toUpperCase()}?`}
      loading={loading}
      content={
        <>
          <MyButtonsContainer flow="column no-wrap" margin="1.5rem auto">
            {possibleReasons.map((elem, i) => (
              <MyButton
                text={elem}
                key={i}
                onClick={() => setReason(elem)}
                selectable={true}
                selected={reason === elem}
              />
            ))}
          </MyButtonsContainer>
          {reason && reason?.toUpperCase() === 'OTRO' && (
            <Input
              isFormikInput={false}
              type="textarea"
              onChange={e => {
                setInputValue(e.target.value.replace(/(\r\n|\n|\r)/gm, ''))
              }}
              value={inputValue}
              maxLength={50}
            />
          )}
        </>
      }
    />
  )
}
