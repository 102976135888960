import { useState, useEffect } from 'react'
import useResources from 'hooks/useResources'

/**
 * @typedef {import('../types/Manual').Defect} Defect
 * @typedef {import('../types/Manual').OptionalDefect} OptionalDefect
 * @typedef {import('../types/Filter').Defect} FilterDefect
 *
 */

/**
 *
 * @param {*} section
 * @returns  {{inspectionManual: InspectionManual | null, loading: boolean}}
 */
const useInspectionManual = section => {
  const [manual, setManual] = useState(null)
  const { manualSections, loading } = useResources({
    manualSections: true,
  })

  useEffect(() => {
    try {
      if (manualSections) {
        setManual(new InspectionManual(manualSections[`section${section}`] || []))
      }
    } catch (err) {
      console.error(err)
    }
  }, [section, manualSections])

  return { inspectionManual: manual, loading: loading.manualSections }
}

class InspectionManual {
  #mappedManual
  #rawManual

  /**
   *
   * @param {Defect[]} defects
   * */
  constructor(defects) {
    if (defects === undefined || (Array.isArray(defects) && !defects.length))
      throw new Error('Defects is undefined')

    this.#rawManual = defects
    this.#mappedManual = InspectionManual.toMap(this.#rawManual)
  }

  /**
   *
   * @param {string} id
   * @returns {Defect | null}
   */
  get(id) {
    const defect = this.#mappedManual.get(id)
    if (!defect) return null
    return JSON.parse(JSON.stringify(defect))
  }

  /**
   *
   * @param {Defect[]} defects
   */
  static toMap(defects) {
    /**@type {Map<string,Defect>} */
    const manualSection = new Map()
    for (const defect of defects) {
      manualSection.set(defect.id, defect)
    }
    return manualSection
  }

  /**
   * Extrae los defectos del manual que se le pidan
   * @param {string[] | FilterDefect[]} defectsToGet Puede recibir un array de ids o un array de filter defects
   * @returns {Defect[]}
   */
  getDefects(defectsToGet) {
    try {
      let finalDefects = []

      defectsToGet.forEach(elem => {
        if (typeof elem === 'string') {
          const found = this.#rawManual.find(defect => defect.id === elem)
          if (!found) throw new Error('No defect found for id: ' + elem)
          finalDefects.push(found)
        }
        if (typeof elem === 'object') {
          const found = this.#rawManual.find(defect => defect.id === elem.id)
          if (!found) throw new Error('No defect found for id: ' + elem.id)
          finalDefects.push(found)
        }
      })
      return finalDefects
    } catch (err) {
      console.error(err)
      return []
    }
  }

  /**
   * Mismo funcionamiento que getDefects pero específico para defectos opcionales (Preinspection)
   * @param {FilterDefect[]} defectsToGet
   * @returns {OptionalDefect[]}
   */
  getOptionalDefects(defectsToGet) {
    try {
      let finalDefects = []

      defectsToGet.forEach(elem => {
        let found = this.#rawManual.find(defect => defect.id === elem.id)
        if (!found) throw new Error('No defect found for id: ' + elem.id)

        found = { ...elem, ...found }

        finalDefects.push(found)
      })

      return finalDefects
    } catch (error) {
      console.error(error)
      return []
    }
  }

  /**
   *
   * @returns
   */
  getParents() {
    try {
      return (
        this.#rawManual.filter(
          defect => !defect.calification && defect.id.split('.').length === 2
        ) || []
      )
    } catch (err) {
      console.error(err)
      return []
    }
  }

  /**
   *
   * @param {*} chapter
   * @returns
   */
  getParentsByChapter(chapter) {
    try {
      return this.getParents().filter(defect => defect.id.split('.')[0] === chapter)
    } catch (err) {
      console.error(err)
      return []
    }
  }

  /**
   * Obtiene solo los defectos que aplican en las pruebas de hardware automático
   * @returns {Defect[] }
   */
  getMachineDefects() {
    return this.#rawManual?.filter(defect => defect.machineDetail) || []
  }
}

export default useInspectionManual
