//@ts-check
import easyToast from 'components/Others/EasyToast/easyToast'

// constantes
/**@type {import('types/Inspection').Inspection} */
const initialData = {
  manualManager: null,
  properties: null,
  vehicleData: null,
  vehicleType: null,
  reportId: null,
  reportNumber: null,
  expedient: null,
  inspectionType: null,
  config: null,
  times: null,
  axles: { tractor: null, trailer: null },
  techWeights: null,
  showModalDefect: false,
  showPreinspectionModal: false,
  showSupervisorModal: false,
  optionalDefects: null,
  defectSelected: null,
  visualDefects: null,
  defectsDone: null,
  machineDefects: null,
  speedometer: null,
  images: null,
  testsImages: null,
  imagesSrc: null,
  rerenderRemarks: false,
  isFiltered: true,
  filter: null,
  hardwareConfig: null,
  hardwareStatus: null,
  machinesApplying: null,
  testsValues: null,
  remarks: null,
  internalRemarks: null,
  complementaryEquipment: null,
  traceability: null,
  line: null,
  rev: null,
  errors: null,
  licenseImage: null,
  grade: null,
  previousInspection: null,
  nextInspectionDate: null,
  userNextInspectionDate: null,
  inspector: null,
  itvCard: null,
  calculations: null,
  initialized: false,
}

// types
const SET_REDUX_INSPECTION_MANUAL_MANAGER = 'SET_REDUX_INSPECTION_MANUAL_MANAGER'
const SET_REDUX_INSPECTION_PROPERTIES = 'SET_REDUX_INSPECTION_PROPERTIES'
const SET_REDUX_INSPECTION_ALL_DATA = 'SET_REDUX_INSPECTION_ALL_DATA'
const SET_RESET_REDUX = 'SET_RESET_REDUX'
const SET_VEHICLE_DATA_SUCCESS = 'SET_VEHICLE_DATA_SUCCESS'
const SET_VEHICLE_TYPE_SUCCESS = 'SET_VEHICLE_TYPE_SUCCESS'
const SET_REPORT_NUMBER_SUCCESS = 'SET_REPORT_NUMBER_SUCCESS'
const SET_EXPEDIENT_SUCCESS = 'SET_EXPEDIENT_SUCCESS'
const SET_REPORT_ID_SUCCESS = 'SET_REPORT_ID_SUCCESS'
const SET_INSPECTION_TYPE_SUCCESS = 'SET_INSPECTION_TYPE_SUCCESS'
const SET_CONFIG_SUCCESS = 'SET_CONFIG_SUCCESS'
const SET_TIMES_SUCCESS = 'SET_TIMES_SUCCESS'
const SET_AXLES_SUCCESS = 'SET_AXLES_SUCCESS'
const SET_TECH_WEIGHTS_SUCCESS = 'SET_TECH_WEIGHT_SUCCESS'
const SET_WEIGHT_SUCCESS = 'SET_WEIGHT_SUCCESS'
const SET_SHOW_PREINSPECTION_MODAL_SUCCESS = 'SET_SHOW_PREINSPECTION_MODAL_SUCCESS'
const SET_SHOW_SUPERVISOR_MODAL_SUCCESS = 'SET_SHOW_SUPERVISOR_MODAL_SUCCESS'
const SET_OPTIONAL_DEFECTS_SUCCESS = 'SET_OPTIONAL_DEFECTS_SUCCESS'
const SET_DEFECT_SELECTED_SUCCESS = 'SET_DEFECT_SELECTED_SUCCESS'
const SET_DEFECTS_TOUCHED_SUCCESS = 'SET_DEFECTS_TOUCHED_SUCCESS'
const SET_DEFECTS_DONE_SUCCESS = 'SET_DEFECTS_DONE_SUCCESS'
const SET_MACHINE_DEFECTS_SUCCESS = 'SET_MACHINE_DEFECTS_SUCCESS'
const SET_IMAGES_SUCCESS = 'SET_IMAGES_SUCCESS'
const SET_TESTS_IMAGES_SUCCESS = 'SET_TESTS_IMAGES_SUCCESS'
const SET_IMAGES_SRC_SUCCESS = 'SET_IMAGES_DATA_SUCCESS'
const SET_INTERNAL_REMARKS_SUCCESS = 'SET_INTERNAL_REMARKS_SUCCESS'
const SET_IS_FILTERED_SUCCESS = 'SET_IS_FILTERED_SUCCESS'
const SET_FILTER_SUCCESS = 'SET_FILTER_SUCCESS'
const SET_HARDWARE_CONFIG_SUCCESS = 'SET_HARDWARE_CONFIG_SUCCESS'
const SET_HARDWARE_STATUS_SUCCESS = 'SET_HARDWARE_STATUS_SUCCESS'
const SET_MACHINES_APPLYING_SUCCESS = 'SET_MACHINES_APPLYING_SUCCESS'
const SET_TESTS_VALUES_SUCCESS = 'SET_TESTS_VALUES_SUCCESS'
const SET_REMARKS_SUCCESS = 'SET_REMARKS_SUCCESS'
const SET_INSPECTION_COMPLEMENTARY_EQUIPMENT_SUCCESS =
  'SET_INSPECTION_COMPLEMENTARY_EQUIPMENT_SUCCESS'
const SET_TRACEABILITY_SUCCESS = 'SET_TRACEABILITY_SUCCESS'
const SET_LINE_SUCCESS = 'SET_LINE_SUCCESS'
const SET_SPEEDOMETER_SUCCESS = 'SET_SPEEDOMETER_SUCCESS'
const SET_REV_SUCCESS = 'SET_REV_SUCCESS'
const SET_ERRORS_SUCCESS = 'SET_ERRORS_SUCCESS'
const SET_LICENSE_IMAGE_SUCCESS = 'SET_LICENSE_IMAGE_SUCCESS'
const SET_INSPECTION_GRADE_SUCCESS = 'SET_INSPECTION_GRADE_SUCCESS'
const SET_PREVIOUS_INSPECTION_SUCCESS = 'SET_PREVIOUS_INSPECTION_SUCCESS'
const SET_INSPECTION_NEXT_INSPECTION_DATE = 'SET_INSPECTION_NEXT_INSPECTION_DATE'
const SET_INSPECTION_USER_NEXT_INSPECTION_DATE = 'SET_INSPECTION_USER_NEXT_INSPECTION_DATE'
const SET_INSPECTION_ITV_CARD = 'SET_INSPECTION_ITV_CARD'
const SET_INSPECTION_CALCULATIONS = 'SET_INSPECTION_CALCULATIONS'
const SET_INSPECTION_INSPECTOR = 'SET_INSPECTION_INSPECTOR'

// reducer
export default function newVehicleReducer(state = initialData, action) {
  switch (action.type) {
    case SET_REDUX_INSPECTION_ALL_DATA:
      return action.payload
    case SET_RESET_REDUX:
      return initialData
    case SET_REDUX_INSPECTION_MANUAL_MANAGER:
      return { ...state, manualManager: action.payload }
    case SET_REDUX_INSPECTION_PROPERTIES:
      return { ...state, properties: action.payload }
    case SET_VEHICLE_DATA_SUCCESS:
      return { ...state, vehicleData: action.payload }
    case SET_VEHICLE_TYPE_SUCCESS:
      return { ...state, vehicleType: action.payload }
    case SET_REPORT_NUMBER_SUCCESS:
      return { ...state, reportNumber: action.payload }
    case SET_EXPEDIENT_SUCCESS:
      return { ...state, expedient: action.payload }
    case SET_REPORT_ID_SUCCESS:
      return { ...state, reportId: action.payload }
    case SET_INSPECTION_TYPE_SUCCESS:
      return { ...state, inspectionType: action.payload }
    case SET_CONFIG_SUCCESS:
      return { ...state, config: action.payload }
    case SET_TIMES_SUCCESS:
      return { ...state, times: action.payload }
    case SET_AXLES_SUCCESS:
      return { ...state, axles: action.payload }
    case SET_TECH_WEIGHTS_SUCCESS:
      return { ...state, techWeights: action.payload }
    case SET_WEIGHT_SUCCESS:
      return { ...state, weight: action.payload }
    case SET_SHOW_PREINSPECTION_MODAL_SUCCESS:
      return { ...state, showPreinspectionModal: action.payload }
    case SET_SHOW_SUPERVISOR_MODAL_SUCCESS:
      return { ...state, showSupervisorModal: action.payload }
    case SET_OPTIONAL_DEFECTS_SUCCESS:
      return { ...state, optionalDefects: action.payload }
    case SET_DEFECT_SELECTED_SUCCESS:
      return { ...state, defectSelected: action.payload }
    case SET_DEFECTS_TOUCHED_SUCCESS:
      return { ...state, visualDefects: action.payload }
    case SET_DEFECTS_DONE_SUCCESS:
      return { ...state, defectsDone: action.payload }
    case SET_MACHINE_DEFECTS_SUCCESS:
      return { ...state, machineDefects: action.payload }
    case SET_LICENSE_IMAGE_SUCCESS:
      return { ...state, licenseImage: action.payload }
    case SET_SPEEDOMETER_SUCCESS:
      return { ...state, speedometer: action.payload }
    case SET_IMAGES_SUCCESS:
      return { ...state, images: action.payload }
    case SET_TESTS_IMAGES_SUCCESS:
      return { ...state, testsImages: action.payload }
    case SET_IMAGES_SRC_SUCCESS:
      return { ...state, imagesSrc: action.payload }
    case SET_IS_FILTERED_SUCCESS:
      return { ...state, isFiltered: action.payload }
    case SET_FILTER_SUCCESS:
      return { ...state, filter: action.payload }
    case SET_HARDWARE_CONFIG_SUCCESS:
      return { ...state, hardwareConfig: action.payload }
    case SET_HARDWARE_STATUS_SUCCESS:
      return { ...state, hardwareStatus: action.payload }
    case SET_MACHINES_APPLYING_SUCCESS:
      return { ...state, machinesApplying: action.payload }
    case SET_TESTS_VALUES_SUCCESS:
      return { ...state, testsValues: action.payload }
    case SET_REMARKS_SUCCESS:
      return { ...state, remarks: action.payload }
    case SET_INTERNAL_REMARKS_SUCCESS:
      return { ...state, internalRemarks: action.payload }
    case SET_INSPECTION_COMPLEMENTARY_EQUIPMENT_SUCCESS:
      return { ...state, complementaryEquipment: action.payload }
    case SET_LINE_SUCCESS:
      return { ...state, line: action.payload }
    case SET_TRACEABILITY_SUCCESS:
      return { ...state, traceability: action.payload }
    case SET_REV_SUCCESS:
      return { ...state, rev: action.payload }
    case SET_ERRORS_SUCCESS:
      return { ...state, errors: action.payload }
    case SET_INSPECTION_GRADE_SUCCESS:
      return { ...state, grade: action.payload }
    case SET_PREVIOUS_INSPECTION_SUCCESS:
      return { ...state, previousInspection: action.payload }
    case SET_INSPECTION_NEXT_INSPECTION_DATE:
      return { ...state, nextInspectionDate: action.payload }
    case SET_INSPECTION_USER_NEXT_INSPECTION_DATE:
      return { ...state, userNextInspectionDate: action.payload }
    case SET_INSPECTION_ITV_CARD:
      return { ...state, itvCard: action.payload }
    case SET_INSPECTION_CALCULATIONS:
      return { ...state, calculations: action.payload }
    case SET_INSPECTION_INSPECTOR:
      return { ...state, inspector: action.payload }
    default:
      return state
  }
}

// actions

export const setReduxInspectionAllData = state => dispatch => {
  const data = { ...initialData, ...state }
  try {
    dispatch({
      type: SET_REDUX_INSPECTION_ALL_DATA,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const clearReduxInspection = () => dispatch => {
  try {
    dispatch({
      type: SET_REDUX_INSPECTION_ALL_DATA,
      payload: initialData,
    })
  } catch (err) {
    console.error(err)
  }
}

export const resetInspectionDuck = () => dispatch => {
  try {
    dispatch({
      type: SET_RESET_REDUX,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionManualManager = state => dispatch => {
  try {
    dispatch({
      type: SET_REDUX_INSPECTION_MANUAL_MANAGER,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionProperties = state => dispatch => {
  try {
    dispatch({
      type: SET_REDUX_INSPECTION_PROPERTIES,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionVehicleData = state => dispatch => {
  try {
    dispatch({
      type: SET_VEHICLE_DATA_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionVehicleType = state => dispatch => {
  try {
    dispatch({
      type: SET_VEHICLE_TYPE_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionReportNumber = state => dispatch => {
  try {
    dispatch({
      type: SET_REPORT_NUMBER_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionExpedient = state => dispatch => {
  try {
    dispatch({
      type: SET_EXPEDIENT_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionReportId = state => dispatch => {
  try {
    dispatch({
      type: SET_REPORT_ID_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionType = state => dispatch => {
  try {
    dispatch({
      type: SET_INSPECTION_TYPE_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionConfig = state => dispatch => {
  try {
    dispatch({
      type: SET_CONFIG_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionTimes = state => dispatch => {
  try {
    dispatch({
      type: SET_TIMES_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionAxles = state => dispatch => {
  try {
    dispatch({
      type: SET_AXLES_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionTechWeights = state => dispatch => {
  try {
    dispatch({
      type: SET_TECH_WEIGHTS_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionWeight = state => dispatch => {
  try {
    dispatch({
      type: SET_WEIGHT_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionOpacimeter = state => (dispatch, getState) => {
  let currentConfig = getState().inspection.config || {}

  const copy = JSON.parse(JSON.stringify(currentConfig))
  if (state) {
    copy.opacimeterData = state
  } else if (!state && currentConfig.opacimeterData) {
    delete copy.opacimeterData
  }

  try {
    dispatch({
      type: SET_CONFIG_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionShowPreinspectionModal = state => dispatch => {
  try {
    dispatch({
      type: SET_SHOW_PREINSPECTION_MODAL_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionShowSupervisorModal = state => dispatch => {
  try {
    dispatch({
      type: SET_SHOW_SUPERVISOR_MODAL_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionOptionalDefects = state => dispatch => {
  try {
    dispatch({
      type: SET_OPTIONAL_DEFECTS_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionDefectSelected = state => dispatch => {
  try {
    dispatch({
      type: SET_DEFECT_SELECTED_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionAllVisualDefects = arrayOfDefects => dispatch => {
  try {
    dispatch({
      type: SET_DEFECTS_TOUCHED_SUCCESS,
      payload: arrayOfDefects,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionVisualDefect = defect => (dispatch, getState) => {
  let currentDefects = getState().inspection.visualDefects || []

  const found = currentDefects.find(elem => elem.id === defect.id)
  if (found) {
    currentDefects.splice(currentDefects.indexOf(found), 1)
  }

  currentDefects.push(defect)

  currentDefects = currentDefects.filter(elem => elem.values.calification)
  try {
    dispatch({
      type: SET_DEFECTS_TOUCHED_SUCCESS,
      payload: currentDefects,
    })
  } catch (err) {
    console.error(err)
  }
}

export const deleteReduxInspectionVisualDefects = arrayOfIds => (dispatch, getState) => {
  let { visualDefects } = getState().inspection || []
  let indexes = []

  arrayOfIds.forEach(id => {
    const found = visualDefects.find(elem => elem.id === id)
    found && indexes.push(visualDefects.indexOf(found))
  })
  indexes.forEach((index, i) => visualDefects.splice(i === 0 ? index : index - 1, 1))
  try {
    dispatch({
      type: SET_DEFECTS_TOUCHED_SUCCESS,
      payload: visualDefects,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionAllDefectsDone = state => dispatch => {
  try {
    dispatch({
      type: SET_DEFECTS_DONE_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const addReduxInspectionDefectsDone = defects => (dispatch, getState) => {
  let currentDefects = getState().inspection.defectsDone || []

  defects.forEach(defect => {
    if (currentDefects?.length > 0) {
      const found = currentDefects.find(elem => elem.id === defect.id)
      found && currentDefects.splice(currentDefects.indexOf(found), 1)
    }
    currentDefects.push(defect)
  })

  try {
    dispatch({
      type: SET_DEFECTS_DONE_SUCCESS,
      payload: currentDefects,
    })
  } catch (err) {
    console.error(err)
  }
}

export const deleteReduxInspectionDefectsDone = ids => (dispatch, getState) => {
  let { defectsDone } = getState().inspection || []
  ids.forEach(id => {
    const found = defectsDone.find(elem => elem.id === id)
    found && defectsDone.splice(defectsDone.indexOf(found), 1)
  })

  try {
    dispatch({
      type: SET_DEFECTS_DONE_SUCCESS,
      payload: defectsDone || [],
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionMachineDefects = data => dispatch => {
  try {
    dispatch({
      type: SET_MACHINE_DEFECTS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionLicenseImage = data => dispatch => {
  try {
    dispatch({
      type: SET_LICENSE_IMAGE_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionImages = data => dispatch => {
  try {
    dispatch({
      type: SET_IMAGES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionTestsImages = data => dispatch => {
  try {
    dispatch({
      type: SET_TESTS_IMAGES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionImagesSrc = data => dispatch => {
  try {
    dispatch({
      type: SET_IMAGES_SRC_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionIsFiltered = boolean => dispatch => {
  easyToast('dark', `Filtro ${boolean ? 'activado' : 'desactivado'}`)
  try {
    dispatch({
      type: SET_IS_FILTERED_SUCCESS,
      payload: boolean,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionFilter = data => dispatch => {
  try {
    dispatch({
      type: SET_FILTER_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionHardwareConfig = state => dispatch => {
  try {
    dispatch({
      type: SET_HARDWARE_CONFIG_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionHardwareStatus = state => dispatch => {
  try {
    dispatch({
      type: SET_HARDWARE_STATUS_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionMachinesApplying = state => dispatch => {
  try {
    dispatch({
      type: SET_MACHINES_APPLYING_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const updateReduxInspectionOneHardwareStatus = oneHardwareStatus => (dispatch, getState) => {
  try {
    const currentHardwardStatus = getState().inspection.hardwareStatus
    if (!currentHardwardStatus) throw new Error('No current hardware status found')
    const thisHSIndex = currentHardwardStatus.findIndex(
      elem => elem.internalId === oneHardwareStatus.internalId
    )
    if (thisHSIndex === -1) throw new Error('Hardware status not found')
    currentHardwardStatus[thisHSIndex] = oneHardwareStatus

    dispatch({
      type: SET_HARDWARE_STATUS_SUCCESS,
      payload: currentHardwardStatus,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionTestsValues = state => dispatch => {
  try {
    dispatch({
      type: SET_TESTS_VALUES_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}
export const setReduxInspectionRemarks = state => dispatch => {
  try {
    dispatch({
      type: SET_REMARKS_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionComplementaryEquipment = state => dispatch => {
  try {
    dispatch({
      type: SET_INSPECTION_COMPLEMENTARY_EQUIPMENT_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionInternalRemarks = state => dispatch => {
  try {
    dispatch({
      type: SET_INTERNAL_REMARKS_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionTraceability = state => dispatch => {
  try {
    dispatch({
      type: SET_TRACEABILITY_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionLine = state => dispatch => {
  try {
    dispatch({
      type: SET_LINE_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionSpeedometer = state => dispatch => {
  try {
    dispatch({
      type: SET_SPEEDOMETER_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionRev = state => dispatch => {
  try {
    dispatch({
      type: SET_REV_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}
export const setReduxInspectionGrade = state => dispatch => {
  try {
    dispatch({
      type: SET_INSPECTION_GRADE_SUCCESS,
      payload: state,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionErrors = errors => dispatch => {
  try {
    dispatch({
      type: SET_ERRORS_SUCCESS,
      payload: errors,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionPreviousInspection = data => dispatch => {
  try {
    dispatch({
      type: SET_PREVIOUS_INSPECTION_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionNextInspectionDate = data => dispatch => {
  try {
    dispatch({
      type: SET_INSPECTION_NEXT_INSPECTION_DATE,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionInspector = data => dispatch => {
  try {
    dispatch({
      type: SET_INSPECTION_INSPECTOR,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionItvCard = data => dispatch => {
  try {
    dispatch({
      type: SET_INSPECTION_ITV_CARD,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionCalculations = data => dispatch => {
  try {
    dispatch({
      type: SET_INSPECTION_CALCULATIONS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxInspectionUserNextInspectionDate = data => dispatch => {
  try {
    dispatch({
      type: SET_INSPECTION_USER_NEXT_INSPECTION_DATE,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}
