import React, { useState, useEffect } from 'react'

// SERVICES
import { getVehiclesByChunk } from 'services/Vehicles'

// MY IMPORTS
import VehiclesModal from './VehiclesModal/VehiclesModal'
import MatTable from 'components/Others/MuiTable/MuiTable'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import TablePagination from 'components/Others/MuiTable/TablePagination/TablePagination'
import TableFilter from 'components/Others/TableFilter/TableFilter'
import useServiceByChunk from 'hooks/useServiceByChunk'
import InspectIcon from '@mui/icons-material/Construction'
import AddIcon from '@mui/icons-material/Add'
import { useHistory } from 'react-router-dom'

/**
 * @typedef {import('types/Vehicle').Vehicle} Vehicle
 */
export default function Vehicles() {
  const [modal, setModal] = useState({ show: false, data: null, new: true })
  const history = useHistory()
  const {
    data: vehicles,
    total: totalData,
    fetch: fetchVehicles,
    fetchNextChunk,
    clearFilter,
    loading,
    currentFilterValues,
  } = useServiceByChunk({ service: getVehiclesByChunk })

  useEffect(() => {
    if (vehicles == null) {
      fetchVehicles()
    }
  }, [fetchVehicles, vehicles])

  return (
    <>
      <MatTable
        tableFilter={
          <TableFilter
            totalValues={totalData}
            initialFilterValues={currentFilterValues}
            likeLicense={true}
            licenseDateStart={true}
            licenseDateEnd={true}
            category={true}
            fuel={true}
            likeHolderDni={true}
            loading={loading}
            onSubmit={filterValues => {
              fetchVehicles(null, filterValues)
            }}
            onClear={clearFilter}
          />
        }
        singleSelection={true}
        singleSelectionHoverText="Inspeccionar"
        singleSelectionIcon={<InspectIcon />}
        onSingleSelectionClick={vehicleData => {
          if (!vehicleData) return
          history.push(`create_inspection/${vehicleData?.license}/${vehicleData?.frame}`)
        }}
        filterValues={currentFilterValues}
        onClearTableFilter={clearFilter}
        columnsKey="vehicles"
        values={vehicles}
        onRowClick={rowData => {
          setModal({ show: true, data: rowData, new: false })
        }}
        loading={loading}
        showToolbar={false}
        exportation={{
          type: 'VEHICLES',
          data: { values: vehicles },
          modalTitle: 'Exportar vehículos',
          defaultInputValue: 'VEHÍCULOS',
        }}
        toolbarComponent={
          <>
            <MyButtonsContainer margin="0" styles={{ width: 'auto' }}>
              <MyButton
                text="Añadir vehículo"
                onClick={() => setModal({ show: true, data: null, new: true })}
                icon={<AddIcon />}
              />
            </MyButtonsContainer>
            <TablePagination
              currentDataLength={vehicles?.length}
              totalDataLength={totalData}
              onClick={fetchNextChunk}
            />
          </>
        }
      />

      {modal?.show && (
        <VehiclesModal
          license={modal.data?.license}
          closeModal={() => setModal({ show: false, data: null, new: true })}
          refetch={fetchVehicles}
          isNew={modal?.new}
        />
      )}
    </>
  )
}
