import React, { useState } from 'react'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  popover: {
    pointerEvents: 'none',
  },
}))

export default function MuiPopover({
  id,
  className,
  outsideContent,
  insideContent,
  anchorOrigin,
  popoverOrigin,
}) {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return (
    <div
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      className={`popover-container${className ? ' ' + className + '-container' : ''}`}
    >
      <Typography aria-owns={open ? 'mouse-over-popover' : undefined} aria-haspopup="true">
        {outsideContent}
      </Typography>
      <Popover
        id={id}
        className={classes.popover + ` popover${className ? ' ' + className : ''}`}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={
          anchorOrigin || {
            vertical: 'bottom',
            horizontal: 'right',
          }
        }
        transformOrigin={
          popoverOrigin || {
            vertical: 'top',
            horizontal: 'right',
          }
        }
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{insideContent}</Typography>
      </Popover>
    </div>
  )
}
