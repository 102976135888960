import { tourSteps } from 'components/Others/GuidedTourWrapper/tourSteps'
import { useState, useCallback } from 'react'
import { STATUS } from 'react-joyride'

const useGuidedTour = tourKey => {
  const [steps] = useState(tourSteps[tourKey])
  const [run, setRun] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)

  const startTour = useCallback(() => {
    setRun(true)
    setStepIndex(0)
  }, [])

  const handleJoyrideCallback = useCallback(data => {
    const { status, index, type } = data
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]
    console.log({ status, index, type })

    if (finishedStatuses.includes(status)) {
      setRun(false)
    } else if (type === 'step:after' && status === 'running') {
      // Solo avanza el índice del paso después de que un paso haya sido completado
      setStepIndex(index + 1)
    }
  }, [])

  return {
    steps,
    run,
    stepIndex,
    startTour,
    handleJoyrideCallback,
  }
}

export default useGuidedTour
