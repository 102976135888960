//@ts-check
import { useSelector, useDispatch } from 'react-redux'
import useService from './useService'
import {
  deleteInspectionRemark as deleteInspectionRemarkService,
  postInspectionRemark as postInspectionRemarkService,
  putInspectionRemark as putInspectionRemarkService,
} from 'services/Inspection'
import easyToast from 'components/Others/EasyToast/easyToast'
import formatAndTrimText from 'myMethods/formatAndTrimText'
import Id from 'Share/Id'
import {
  deleteUserPersonalRemark as deleteUserPersonalRemarkService,
  postUserPersonalRemark as postUserPersonalRemarkService,
} from 'services/Users'
import { setReduxUserPersonalSettingsRemarks } from 'redux/ducks/userDuck'
import isValidFunction from 'myMethods/isValidFunction'

/**@typedef {import('types/Inspection').Remark} Remark */
/**@typedef {import('types/Inspection').InternalRemark} InternalRemark */

/**
 * Custom hook para obtener las observaciones de inspección.
 *
 * @param {Object} params - Parámetros de configuración.
 * @param {string[]=} [params.types=['general', 'internal', 'defect']] - Tipos de observaciones a obtener.
 * @param {string=} [params.defectId] - ID del defecto para filtrar las observaciones.
 * @returns {{remarks: (Remark & {defectId?: string,  type: 'general' | 'internal' | 'defect'})[],
 *  addRemark: (remark: string, type: string, addToUserRemarks: boolean, callback?: function) => void,
 *  removeUserRemark: (remark: string) => void,
 *  editRemark: (remark: string, type: string, newRemark: string) => void,
 *  removeRemark: (remark: string, type: string) => void,
 *  loadingStates: {loadingEditRemark: boolean, loadingRemoveRemark: boolean, loadingAddUserRemark: boolean, loadingRemoveUserRemark: boolean}
 *  }} - Observaciones de inspección.
 */
export default function useInspectionRemarks({
  types = ['general', 'internal', 'defect'],
  defectId,
}) {
  const redux = useSelector(store => store)
  const inspection = redux?.inspection
  const userRemarks = redux?.user?.data?.personalSettings?.remarks || []
  const dispatch = useDispatch()

  const { fetch: postInspectionRemark } = useService({
    service: postInspectionRemarkService,
  })
  const { fetch: putInspectionRemark, loading: loadingEditRemark } = useService({
    service: putInspectionRemarkService,
  })
  const { fetch: deleteInspectionRemark, loading: loadingRemoveRemark } = useService({
    service: deleteInspectionRemarkService,
  })
  const { fetch: postUserPersonalRemark, loading: loadingAddUserRemark } = useService({
    service: postUserPersonalRemarkService,
  })
  const { fetch: deleteUserPersonalRemark, loading: loadingRemoveUserRemark } = useService({
    service: deleteUserPersonalRemarkService,
  })

  const getRemarks = () => {
    try {
      if (defectId) types = ['defects']
      let remarks = []
      console.log({ types })
      if (types.includes('general')) {
        remarks = [
          ...remarks,
          ...(inspection.remarks
            ? inspection.remarks.map(rem => ({ ...rem, type: 'general' }))
            : []),
        ]
      }
      if (types.includes('internal')) {
        remarks = [
          ...remarks,
          ...(inspection.internalRemarks
            ? inspection.internalRemarks.map(rem => ({ ...rem, type: 'internal' }))
            : []),
        ]
      }
      if (types.includes('defect')) {
        let defectsRemarks = []
        inspection.visualDefects?.forEach(def => {
          if (def.values.remark && (defectId ? def.id === defectId : true)) {
            defectsRemarks.push({ ...def.values.remark, defectId: def.id, type: 'defect' })
          }
        })
        inspection.machineDefects?.forEach(def => {
          if (def.remark && (defectId ? def.id === defectId : true)) {
            defectsRemarks.push({ ...def.remark, defectId: def.defect.id, type: 'defect' })
          }
        })
        remarks = [...remarks, ...defectsRemarks]
      }
      return remarks
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const addRemark = async (text, type, addToUserRemarks, callback) => {
    try {
      if (!text || !type) throw new Error('No remark or type provided')
      text = formatAndTrimText(text)
      let body = {
        id: Id.decrypt(window.sessionStorage.getItem('id')),
      }
      if (type === 'general') {
        body.generalRemark = text
      } else if (type === 'internal') {
        body.internalRemark = text
      }
      await postInspectionRemark(body)
      easyToast('dark', 'Observación añadida correctamente')

      if (addToUserRemarks && !userRemarks.some(rem => rem.text === text)) {
        try {
          const res = await postUserPersonalRemark({
            personalRemark: text,
          })
          dispatch(setReduxUserPersonalSettingsRemarks(res?.remarks ?? null))
        } catch (err) {
          console.error(err)
        }
      }
      if (callback && isValidFunction(callback)) callback()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error añadiendo la observación')
    }
  }

  const editRemark = async (remark, newRemarkText) => {
    try {
      if (!remark || !remark.type || !newRemarkText)
        throw new Error('No remark, type or newRemark provided')
      const body = {
        id: Id.decrypt(window.sessionStorage.getItem('id')),
      }
      if (remark.type === 'general') {
        body.generalRemark = remark.id
        body.newGeneralRemark = newRemarkText
      } else if (remark.type === 'internal') {
        body.internalRemark = remark.id
        body.newInternalRemark = newRemarkText
      }
      await putInspectionRemark(body)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error editando la observación')
    }
  }

  const removeUserRemark = async remark => {
    try {
      let text = remark.text
      if (!text) throw new Error('No remark provided')
      const res = await deleteUserPersonalRemark({
        personalRemark: text,
      })

      console.log({ res })
      dispatch(setReduxUserPersonalSettingsRemarks(res?.remarks ?? null))
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error eliminando la observación personal')
    }
  }

  const removeRemark = async remark => {
    try {
      if (!remark || !remark.type) throw new Error('No remark or type provided')
      const body = {
        id: Id.decrypt(window.sessionStorage.getItem('id')),
      }
      if (remark.type === 'general') {
        body.generalRemark = remark.id
      } else if (remark.type === 'internal') {
        body.internalRemark = remark.id
      } else if (remark.type === 'defect') {
        body.defectRemark = {
          defectId: remark.defectId,
        }
      }
      await deleteInspectionRemark(body)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error eliminando la observación')
    }
  }

  return {
    remarks: getRemarks(),
    addRemark,
    removeRemark,
    editRemark,
    removeUserRemark,
    loadingStates: {
      loadingEditRemark,
      loadingRemoveRemark,
      loadingAddUserRemark,
      loadingRemoveUserRemark,
    },
  }
}
