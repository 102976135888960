import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { motion } from 'framer-motion'

import './HeaderBanner.scss'

export default function HeaderBanner() {
  const [text, setText] = useState('')
  const [closed, setClosed] = useState(false)
  const redux = useSelector(store => store)
  const role = redux.user?.data?.role
  const history = useHistory()

  const motionVariants = {
    closed: {
      x: '-5000px',
      opacity: 0,
    },
    open: {
      x: 0,
      opacity: 1,
    },
  }
  const motionTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.5,
    delay: 0.2,
  }

  //Cambiar el texto del banner para caja registradora
  useEffect(() => {
    if (['ADMINISTRATIVE', 'ACCOUNTANT'].includes(role)) {
      const cashRegisterStatus = redux?.cashRegister?.status
      if (!cashRegisterStatus?.isClosed && cashRegisterStatus?.wasOpen) {
        console.log('1')
        setText('')
        setClosed(true)
        return
      }
      if (cashRegisterStatus?.isClosed || !cashRegisterStatus?.wasOpen) {
        console.log('2')
        setText('La caja aún no se ha abierto')
        setClosed(false)
      }
      if (cashRegisterStatus?.arching?.noCash) {
        console.log('3')
        setText('No hay dinero en la caja')
        setClosed(false)
      }
    }
  }, [redux.cashRegister, role, history])

  useEffect(() => {
    if (!redux.user.JWT) setClosed(true)
  }, [redux.user.JWT])

  return (
    <motion.div
      variants={motionVariants}
      initial={motionVariants.closed}
      transition={motionTransition}
      animate={closed && text ? 'closed' : 'open'}
      className="header-banner"
    >
      {text && (
        <div>
          {text}
          <div onClick={() => setClosed(true)}>✖</div>
        </div>
      )}
    </motion.div>
  )
}
