//@ts-check
import moment from 'moment'
import React, { useState, useEffect } from 'react'

// MY IMPORTS
import './MyArchings.scss'
import formatEuro from 'myMethods/formatEuro'
import easyToast from 'components/Others/EasyToast/easyToast'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import CashModal from '../CashRegisterArching/CashModal/CashModal'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'
import SubmitArchingModal from '../CashRegisterArching/SubmitArchingModal/SubmitArchingModal'
import { putRepeatCashRegisterArch as putRepeatCashRegisterArchService } from 'services/CashRegister'
import useService from 'hooks/useService'

export default function MyArchings({ status, refetch, isChanging }) {
  const [archings, setArchings] = useState(null)
  const initialCoinsModalState = {
    show: false,
    coinsValue: 0,
    coins: undefined,
    editable: false,
  }
  const initialReachModalState = {
    show: false,
    restWithoutCash: 0,
    type: '',
  }
  const [coinsModal, setCoinsModal] = useState(initialCoinsModalState)
  const [reArchModal, setReArchModal] = useState(initialReachModalState)
  const [loading, setLoading] = useState(true)

  const { fetch: putRepeatCashRegisterArch } = useService({
    service: putRepeatCashRegisterArchService,
    setOutLoading: setLoading,
  })

  useEffect(() => {
    if (status?.archings?.length) {
      setArchings(status.archings)
    }
    setLoading(false)
  }, [status?.archings, loading])

  const reArch = async keep => {
    try {
      if (!reArchModal.type) return easyToast('error', 'Inserte un tipo de arqueo')
      if (keep > coinsModal.coinsValue)
        return easyToast('error', 'La cantidad de dinero que quede en caja no puede ser negativo')
      const values = {
        cash: coinsModal.coinsValue,
        coins: coinsModal.coins,
        reason: reArchModal.type,
        cashRegisterId: status.id,
        keep,
      }
      await putRepeatCashRegisterArch(values)
      refetch()
      setCoinsModal(initialCoinsModalState)
      setReArchModal(initialReachModalState)
      easyToast('dark', 'Arqueo realizado')
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error al repetir el arqueo')
    }
  }

  return (
    <>
      {loading && <ChildSpinner />}
      {!loading && (
        <>
          {!isChanging && (!status || !(Object.keys(status).length > 0)) && (
            <div className="no-values">Vaya... parece que aún no tienes asignada una caja</div>
          )}
          {!isChanging && status && Object.keys(status).length > 0 && (
            <section className="my-archings">
              {(!archings || !(archings.length > 0)) && (
                <div className="no-values">No hay arqueos en esta caja</div>
              )}
              {archings &&
                archings.length > 0 &&
                archings.map((elem, i) => {
                  let date = moment(elem.date)
                  if (!moment(date).isValid()) {
                    date = '-'
                  } else {
                    date = date.format('DD-MM-YYYY HH:mm')
                  }
                  const isRecent = () => {
                    try {
                      const sill = 10 * 60000
                      const diff = moment() - moment(elem.date)
                      return { show: Boolean(diff < sill && diff > 0), elapsedMin: diff / 60000 }
                    } catch (err) {
                      console.error(err)
                      return { show: false, elapsedMin: 0 }
                    }
                  }
                  const isLastArching = !archings.some(arching =>
                    moment(arching.date).isAfter(moment(elem.date))
                  )
                  return (
                    <>
                      {isRecent()?.show && (
                        <div className="my-archings__alert" data-aos="fade-up">
                          ARQUEO RECIENTE ({Math.ceil(isRecent().elapsedMin || 0)} min)
                        </div>
                      )}
                      <div className="my-archings__card">
                        <div className="my-archings__number">#{archings.length - i}</div>
                        <div className="my-archings__body">
                          <CardNameValue name="Fecha" value={date} />
                          <CardNameValue name="Motivo" value={elem.reason} />
                          <CardNameValue name="Efectivo" value={formatEuro(elem.cash)} />
                          <CardNameValue
                            className={elem.rest !== 0 ? 'my-archings__difference' : ''}
                            name="Diferencia"
                            value={formatEuro(elem.rest)}
                          />
                        </div>
                        <div className="my-archings__buttons">
                          <HoverTooltip title="Ver efectivo">
                            <div
                              onClick={() => {
                                setCoinsModal({
                                  show: true,
                                  coinsValue: elem.cash,
                                  coins: elem.coins,
                                  editable: false,
                                })
                              }}
                            >
                              <i
                                className="
                              flaticon-money"
                              />
                            </div>
                          </HoverTooltip>
                          {isLastArching && (
                            <HoverTooltip title="Repetir arqueo">
                              <div
                                onClick={() => {
                                  setCoinsModal({
                                    ...initialCoinsModalState,
                                    show: true,
                                    editable: true,
                                  })
                                  setReArchModal(prevState => ({
                                    ...prevState,
                                    restWithoutCash: elem.rest - elem.cash,
                                    type: elem.reason,
                                  }))
                                }}
                              >
                                <i
                                  className="
                            flaticon-refresh"
                                />
                              </div>
                            </HoverTooltip>
                          )}
                        </div>
                      </div>
                    </>
                  )
                })}
            </section>
          )}
        </>
      )}

      {coinsModal?.show && (
        <CashModal
          coinsValue={coinsModal.coinsValue}
          coins={coinsModal.coins}
          editable={coinsModal.editable}
          closeModal={() => setCoinsModal(initialCoinsModalState)}
          onAccept={(coins, coinsValue) =>
            coinsModal.editable
              ? (() => {
                  setReArchModal(prevState => ({ ...prevState, show: true }))
                  setCoinsModal(prevState => ({ ...prevState, coinsValue, coins }))
                })()
              : setCoinsModal(initialCoinsModalState)
          }
        />
      )}
      {reArchModal?.show && (
        <SubmitArchingModal
          status={status}
          closeModal={() => setReArchModal(initialReachModalState)}
          onAccept={(_, keep) => {
            reArch(keep)
          }}
          loading={loading}
          coinsValue={coinsModal.coinsValue}
          difference={reArchModal.restWithoutCash + coinsModal.coinsValue}
          optionToForce={reArchModal.type}
        />
      )}
    </>
  )
}
