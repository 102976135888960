import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import Modal from 'components/Others/Modal/Modal'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setReduxConfigTimedOutTests } from 'redux/ducks/configDuck'
import TimedOutTests from './TimedOutTests/TimedOutTests'

export default function WarningModal() {
  const redux = useSelector(store => store)
  const hardwareByLine = redux.config.hardwareByLine
  const dispatch = useDispatch()
  const [timedOutTests, setTimedOutTests] = useState([])
  const [showTimedOutModal, setShowTimedOutModal] = useState(false)
  const history = useHistory()

  useEffect(() => {
    if (hardwareByLine && hardwareByLine.length) {
      let timedOut = []
      const now = moment()
      const sillDate = moment().add(redux.config.itv?.notifyHardwareDays || 0, 'days')

      hardwareByLine.forEach(({ line, hardware }) => {
        hardware.forEach(hard => {
          let timedOutTests = []
          hard.tests &&
            Object.entries(hard.tests)?.forEach(([testName, date]) => {
              if (
                date &&
                (moment(date).isBefore(now) ||
                  now.diff(moment(date), 'days') === 0 ||
                  (moment(date).isAfter(now) && moment(date).isBefore(sillDate)))
              ) {
                let daysElapsed = 0
                const hours = now.diff(moment(date), 'hours')

                if (hours > 24) daysElapsed = Math.ceil(hours / 24)
                if (hours < 24) daysElapsed = Math.floor(hours / 24)

                timedOutTests.push({
                  testName,
                  date: moment(date).format('DD-MM-YYYY'),
                  daysElapsed,
                })
              }
            })
          if (timedOutTests.length) {
            timedOut.push({
              ...hard,
              timedOutTests,
            })
          }
        })
      })
      if (timedOut.length) {
        setTimedOutTests(timedOut)
        dispatch(setReduxConfigTimedOutTests(timedOut))

        const timedOutStorage = window.sessionStorage.getItem('timedOutTestsDone')
        if (!timedOutStorage || timedOutStorage === 'false') setShowTimedOutModal(true)
      }
    }
  }, [dispatch, hardwareByLine, redux?.config?.itv?.notifyHardwareDays])
  return (
    <Modal
      open={showTimedOutModal}
      onClose={() => {
        setShowTimedOutModal(false)
        window.sessionStorage.setItem('timedOutTestsDone', 'true')
      }}
      title="ALERTAS SOBRE LA CADUCIDAD DE LOS TESTS"
      hasCloseButton={false}
      content={
        <>
          <MyButtonsContainer margin="0 0 1rem 0">
            <MyButton
              text="IR AL CONFIGURADOR"
              onClick={() => {
                setShowTimedOutModal(false)
                window.sessionStorage.setItem('timedOutTestsDone', 'true')
                history.push('/itv_configurator')
              }}
            />
          </MyButtonsContainer>
          <TimedOutTests data={timedOutTests} />
        </>
      }
    />
  )
}
