import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// MY IMPORTS
import 'pages/Supervision/SupervisionMain.scss'
import FillReduxInspection from 'components/FillRedux/FillReduxInspection'
import Cards from 'components/Inspection/Check/Cards/Cards'
import Traceability from 'components/Inspection/Check/Traceability/Traceability'
import DefectsSupervisor from 'components/Inspection/DefectsSupervisor/DefectsSupervisor'
import Sign from 'components/Supervision/NewSupervision/Sign/Sign'
import InfoMenu from 'components/Inspection/InfoMenu/InfoMenu'
import Clock from 'components/Inspection/Clock/Clock'
import Times from 'components/Supervision/NewSupervision/Times/Times'
import Others from 'components/Supervision/NewSupervision/Others/Others'
import Status from 'components/Inspection/Check/Status/Status'
import MaterialTab from 'components/Others/MaterialTab/MaterialTab'
import SupervisionTests from './SupervisionTests/SupervisionTests'
import { useHistory } from 'react-router-dom'
import FillReduxDetailedHistory from 'components/FillRedux/FillReduxDetailedHistory'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { resetInspectionDuck } from 'redux/ducks/inspectionDuck'
import easyToast from 'components/Others/EasyToast/easyToast'
import { ArrowBack } from '@mui/icons-material'
import Spinner from 'components/Others/Spinner/Spinner'
import getTraceability from 'myMethods/getTraceability'
import CollectedValues from './CollectedValues/CollectedValues'
import ITVCard from 'components/Supervision/NewSupervision/ITVCard/ITVCard'
import { useMemo } from 'react'
import InspectionImages from 'components/Inspection/InspectionImages/InspectionImages'
import ListOfRemarks from 'components/Inspection/Remarks/ListOfRemarks'

export default function SupervisorMain() {
  const redux = useSelector(store => store)
  const dispatch = useDispatch()
  const history = useHistory()
  const [traceability, setTraceability] = useState(null)
  const [loading, setLoading] = useState(true)
  const onDetailedHistory = history.location.pathname.includes('detailed-history')
  const onInspection = window.sessionStorage.getItem('inspectionHistory') === 'true'
  const inspectionType = redux?.inspection?.inspectionType
  const showErrors =
    inspectionType?.includes('PERIÓDICA') ||
    inspectionType?.includes('REFORMA') ||
    inspectionType?.includes('PREVIA A MATRICULACIÓN')

  const tabData = useMemo(() => {
    const data = [
      {
        tab: { text: 'INFO' },
        content: (
          <div data-aos="fade-up">
            <Status
              errors={redux?.inspection?.errors}
              showErrors={showErrors}
              reportDownloader={false}
            />
            <InfoMenu reduxInspection={redux.inspection} />
          </div>
        ),
      },
      {
        tab: { text: onDetailedHistory ? 'RESULTADO' : 'FIRMAR' },
        content: <Sign data-aos="fade-up" redux={redux} onlyRead={onDetailedHistory} />,
      },
      {
        tab: { text: 'RESUMEN' },
        content: <Cards data-aos="fade-up" reduxInspection={redux?.inspection} />,
      },
      {
        tab: { text: 'TRAZABILIDAD' },
        content: (
          <Traceability
            data-aos="fade-up"
            showErrors={showErrors}
            traceability={redux?.inspection?.traceability?.byChapters}
          />
        ),
      },
      {
        tab: { text: 'TESTS' },
        content: <SupervisionTests data-aos="fade-up" />,
      },
      {
        tab: { text: 'DEFECTOS' },
        content: (
          <DefectsSupervisor
            data-aos="fade-up"
            onlyRead={onDetailedHistory}
            isSupervisor={true}
            visualDefects={redux?.inspection?.visualDefects}
            machineDefects={redux?.inspection?.machineDefects}
            manualManager={redux?.inspection?.manualManager}
          />
        ),
      },
      {
        tab: { text: 'VALORES RECOGIDOS' },
        content: <CollectedValues reduxInspection={redux?.inspection} data-aos="fade-up" />,
      },
      {
        tab: { text: 'IMÁGENES' },
        content: (
          <section>
            <InspectionImages />
          </section>
        ),
      },
      {
        tab: { text: 'OBSERVACIONES' },
        content: <ListOfRemarks />,
      },
      {
        tab: { text: 'TIEMPOS' },
        content: (
          <Times
            data-aos="fade-up"
            showSupervisionTime={true}
            reduxInspection={redux?.inspection}
          />
        ),
      },
      {
        tab: { text: 'Tarjeta ITV' },
        content: <ITVCard data-aos="fade-up" reduxInspection={redux?.inspection} />,
      },
      {
        tab: { text: 'OTROS' },
        content: (
          <Others
            data-aos="fade-up"
            reduxInspection={redux?.inspection}
            allowReturn={!onDetailedHistory}
          />
        ),
      },
    ]
    if (inspectionType?.includes('REFORMA')) {
      data.splice(10, 0, {
        tab: { text: 'REFORMAS' },
        content: (
          <ITVCard
            data-aos="fade-up"
            reduxInspection={redux?.inspection}
            onlyReforms={true}
            reforms={redux?.inspection?.config?.reforms}
          />
        ),
      })
    }
    return data
  }, [inspectionType, onDetailedHistory, redux, showErrors])

  const goBack = () => {
    try {
      dispatch(resetInspectionDuck())
      const license = window.sessionStorage.getItem('license')
      window.sessionStorage.removeItem('historyId')
      window.sessionStorage.removeItem('inspectionHistory')
      if (onInspection && license) {
        dispatch(resetInspectionDuck())
        if (license) history.replace(`/new_inspection/${license}/history`)
        else history.replace('/lobby')
      } else if (onDetailedHistory) {
        window.sessionStorage.removeItem('license')
        window.sessionStorage.removeItem('line')
        history.replace('/history')
      } else history.replace('/lobby')
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error')
      history.replace('/lobby')
    }
  }

  useEffect(() => {
    if (onInspection) {
      dispatch(resetInspectionDuck())
    }
  }, [dispatch, onInspection])

  useEffect(() => {
    try {
      if (!onDetailedHistory && redux?.inspection?.manualManager && !traceability) {
        const traceabilityRes = getTraceability(redux.inspection.manualManager)
        if (traceabilityRes) setTraceability(traceabilityRes)
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error comprobando la trazabilidad de la inspección')
    } finally {
      setLoading(false)
    }
  }, [onDetailedHistory, redux.inspection.manualManager, traceability])

  return (
    <>
      {!onDetailedHistory && <FillReduxInspection />}
      {onDetailedHistory && <FillReduxDetailedHistory />}
      {onDetailedHistory && (
        <MyButtonsContainer margin="auto">
          <MyButton text="Volver atrás" icon={<ArrowBack />} onClick={goBack} transparent={false} />
        </MyButtonsContainer>
      )}
      {/* {!onDetailedHistory && (
        <Clock
          data-aos="fade-up"
          dateFrom={redux?.inspection?.times?.supervision_start}
          seconds={true}
          className="supervision-main__clock"
        />
      )} */}
      <MaterialTab
        tabs={tabData.map(el => el.tab)}
        contents={tabData.map(el => el.content)}
        className="supervision-main"
      />
      <Spinner visible={loading} />
    </>
  )
}
