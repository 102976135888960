import React, { useState, forwardRef, useImperativeHandle, useRef, useEffect } from 'react'
import { useGeolocation } from 'react-use'
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import 'components/Inspection/CaptureImage/CaptureImage.scss'
import { isMobile } from 'react-device-detect'
import isValidFunction from 'myMethods/isValidFunction'
import Select from 'components/Others/Select/Select'
import { imageTypes } from 'resources/selectsValues'

/**
 * @typedef {Object} CaptureImageProps
 * @property {boolean} [geolocate=true] - Indica si se debe usar la geolocalización.
 * @property {boolean} [onlyMobile=false] - Indica si solo se permite en dispositivos móviles.
 * @property {function} onCaptureImage - Función que se llama al capturar la imagen.
 * @property {boolean} [letBrowseFiles=false] - Indica si se permite buscar archivos en lugar de usar la cámara.
 * @property {string[]} [filetypes=['image/jpeg', 'image/png', 'image/jpg', 'image/tiff']] - Tipos de archivos permitidos.
 * @property {boolean} [showImageAfterCapture=true] - Indica si se debe mostrar la imagen después de capturarla.
 * @property {boolean} [typeSelectable=false] - Indica si se puede seleccionar el tipo de imagen.
 * @property {Array<{value: string, label: string}>} [selectValues=imageTypes] - Valores del selector.
 * @property {string} [defaultSelectValue] - Valor por defecto del selector.
 */

/**
 * @description Componente para capturar imágenes desde la cámara del dispositivo. Usa ref.current.triggerCapture() para capturar la imagen desde el forwardRef.
 * @param {CaptureImageProps} props
 */
const CaptureImage = forwardRef(
  (
    {
      geolocate = true,
      onlyMobile = false,
      onCaptureImage,
      typeSelectable = false,
      selectValues = imageTypes,
      defaultSelectValue,
      showImageAfterCapture = true,
      letBrowseFiles = false,
      filetypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/tiff'],
    },
    ref
  ) => {
    const [showModal, setShowModal] = useState(false)
    const [type, setType] = useState(null)
    const inputFileRef = useRef()
    const geolocation = useGeolocation()

    useImperativeHandle(ref, () => ({
      triggerCapture() {
        if (onlyMobile && !isMobile)
          return easyToast('error', 'Solo puedes capturar imágenes desde un dispositivo móvil')
        if (geolocate && !checkGeolocation())
          return easyToast('error', 'Debes autorizar el uso de tu ubicación para capturar imágenes')
        inputFileRef.current.click()
      },
    }))

    const checkGeolocation = () => {
      if (!geolocation || !Object.keys(geolocation).length || geolocation.error) {
        return false
      }
      return true
    }

    const validateFileType = file => {
      return filetypes.includes(file.type)
    }

    const handleImageCapture = file => {
      if (isValidFunction(onCaptureImage)) {
        onCaptureImage(file, geolocation, type?.value)
      }
    }

    const showImage = e => {
      const file = e.target.files && e.target.files[0]
      if (file) {
        if (!validateFileType(file)) {
          easyToast('error', 'Tipo de archivo no permitido. Seleccione un archivo válido.')
          return
        }

        if (showImageAfterCapture) {
          setShowModal(true)

          // Use a timeout to ensure the modal has time to render
          setTimeout(() => {
            const imgURL = URL.createObjectURL(file)
            const output = document.getElementById('output-image')
            if (output) {
              output.src = imgURL
              output.crossOrigin = 'Anonymous'
              output.style.display = 'inline-block'
              output.onload = function () {
                URL.revokeObjectURL(output.src) // free memory
              }
            } else {
              console.error('Elemento output no encontrado')
            }
          }, 400) // Short timeout to allow re-render
        } else {
          handleImageCapture(file)
        }
      } else {
        easyToast('error', 'Ha ocurrido un error capturando la imagen. Inténtelo de nuevo')
      }
    }

    const handleAccept = () => {
      handleImageCapture(inputFileRef?.current?.files?.[0])
      setShowModal(false)
    }

    useEffect(() => {
      if (defaultSelectValue) {
        setType(imageTypes?.find(type => type.value === defaultSelectValue) ?? null)
      }
    }, [defaultSelectValue])

    return (
      <>
        <input
          ref={inputFileRef}
          className="capture-image__input-image"
          accept={filetypes.join(',')}
          id="file-input"
          type="file"
          capture={!letBrowseFiles ? 'camera' : undefined}
          onChange={e => showImage(e)}
          style={{ display: 'none' }}
        />
        <Modal
          open={showModal}
          title="AÑADIR IMAGEN"
          closeOnOverlay={false}
          onClose={() => setShowModal(false)}
          onAccept={handleAccept}
          content={
            <div className="capture-image">
              <img
                alt="image"
                className="capture-image__output-image"
                id="output-image"
                crossOrigin="anonymous"
              />
              {typeSelectable && (
                <Select
                  label="Tipo de imagen a añadir"
                  options={selectValues}
                  value={type}
                  onChange={opt => setType(opt)}
                />
              )}
            </div>
          }
        />
      </>
    )
  }
)

export default CaptureImage
