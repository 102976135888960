import React, { useState, useEffect } from 'react'

//MY IMPORTS
import 'components/Office/Checkout/PaymentMethods/PaymentMethods.scss'
import { useDispatch } from 'react-redux'
import { setReduxPaymentData } from 'redux/ducks/newInspectionDuck'
import Modal from 'components/Others/Modal/Modal'
import ReturnCalculator from './ReturnCalculator/ReturnCalculator'
import easyToast from 'components/Others/EasyToast/easyToast'
import { getClientByDni } from 'services/Clients'
import useService from 'hooks/useService'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { Calculator } from 'react-bootstrap-icons'

export default function PaymentMethods({ reduxNewVehicle }) {
  const dispatch = useDispatch()
  const currentMethod = reduxNewVehicle?.paymentData?.method
  const paymentData = reduxNewVehicle?.paymentData
  const fee = reduxNewVehicle?.feeData
  const hasPrice = fee?.total && fee.total > 0
  const sinCargos = reduxNewVehicle?.discountData?.name === 'SIN CARGOS'
  const [showCalcModal, setShowCalcModal] = useState(false)
  const [accountModal, setAccountModal] = useState({ show: false, data: null })
  const [defaultMethod, setDefaultMethod] = useState(null)
  const isChargeAccountPayment = ['RECIBO BANCARIO', 'CRÉDITO'].includes(currentMethod)
  const handleClick = newMethod => {
    dispatch(setReduxPaymentData({ method: newMethod }))
  }

  const handleAccountModalAccept = () => {
    dispatch(setReduxPaymentData({ ...paymentData, dni: accountModal.data.dni }))
    setAccountModal({ show: false, data: null })
  }

  const { fetch: fetchClient } = useService({
    service: getClientByDni,
  })

  useEffect(() => {
    try {
      const deletePaymentMethod = () => {
        dispatch(setReduxPaymentData({ ...reduxNewVehicle.paymentData, method: null }))
      }

      const dni = reduxNewVehicle.invoiceData?.dni || reduxNewVehicle.holderData?.dni

      if (isChargeAccountPayment && paymentData.dni && paymentData.dni !== dni) {
        deletePaymentMethod()
      }

      if (isChargeAccountPayment && !paymentData.dni) {
        fetchClient(dni).then(res => {
          if (res) {
            const licenses = res.chargeAccount?.authVehicles
            const clientName = res.dni + ' - ' + res.name
            if (!res.chargeAccount) {
              easyToast('error', 'Este cliente no tiene ninguna cuenta a cobro')
              return deletePaymentMethod()
            }
            // Si la cuenta está desactivada
            if (!res.chargeAccount.status) {
              easyToast('error', 'La cuenta de cobro de este cliente está desactivada')
              return deletePaymentMethod()
            }
            // Si la matrícula no está incluída
            else if (
              !licenses.find(elem => elem.license === reduxNewVehicle.inspectionData.license)
            ) {
              easyToast(
                'error',
                `La matrícula a inspeccionar no está incluida en la cuenta de cobro de ${clientName}`
              )
              return deletePaymentMethod()
            }
            // Si todo correcto
            else {
              return setAccountModal({ show: true, data: res })
            }
          } else {
            easyToast('error', `No hay ninguna cuenta de cobro asociada a ${dni}`)
            return deletePaymentMethod()
          }
        })
      }
    } catch (err) {
      console.log(err)
      easyToast('error', 'Ha ocurrido un error encontrando la cuenta de cobro de este cliente')
    }
  }, [
    currentMethod,
    reduxNewVehicle?.invoiceData?.dni,
    reduxNewVehicle?.holderData?.dni,
    paymentData?.dni,
    dispatch,
    reduxNewVehicle?.inspectionData?.license,
    reduxNewVehicle?.paymentData,
    isChargeAccountPayment,
    fetchClient,
  ])

  useEffect(() => {
    if (reduxNewVehicle.invoiceData) {
      return setDefaultMethod(reduxNewVehicle.invoiceData.paymentMethod || null)
    }
    setDefaultMethod(reduxNewVehicle.holderData.paymentMethod || null)
  }, [dispatch, reduxNewVehicle.holderData, reduxNewVehicle.invoiceData])

  if (!hasPrice || sinCargos) return <></>
  return (
    <>
      <div className="payment-methods">
        <h3>MÉTODO DE PAGO</h3>
        {defaultMethod && (
          <h4>
            EL MÉTODO DE PAGO POR DEFECTO DE ESTE CLIENTE ES <span>{defaultMethod}</span>
          </h4>
        )}
        <div className="payment-methods__cards-container">
          <div
            onClick={() => handleClick('EFECTIVO')}
            className={currentMethod === 'EFECTIVO' ? 'payment-methods__card--active' : ''}
          >
            <i className="flaticon-money" />
            <span>EFECTIVO</span>
          </div>
          <div
            onClick={() => handleClick('TARJETA')}
            className={currentMethod === 'TARJETA' ? 'payment-methods__card--active' : ''}
          >
            <i className="flaticon-credit-card" />
            <span>TARJETA</span>
          </div>
          {['CRÉDITO', 'RECIBO BANCARIO'].includes(defaultMethod) && (
            <div
              onClick={() => handleClick('CRÉDITO')}
              className={
                currentMethod === 'CRÉDITO'
                  ? 'payment-methods__card--active'
                  : ['CRÉDITO', 'RECIBO BANCARIO'].includes(defaultMethod)
                  ? 'payment-methods__card--default'
                  : ''
              }
            >
              <i className="flaticon-honesty" />
              <span>A CRÉDITO</span>
            </div>
          )}
        </div>
        <MyButtonsContainer>
          <MyButton
            text="Calcular cambio"
            icon={<Calculator />}
            onClick={() => setShowCalcModal(true)}
            transparent={true}
          />
        </MyButtonsContainer>
      </div>

      <Modal
        open={showCalcModal}
        onClose={() => setShowCalcModal(false)}
        title="Calcular cambio"
        content={<ReturnCalculator total={reduxNewVehicle.feeData.total} />}
        hasCloseButton={false}
      />
      <Modal
        open={accountModal.show}
        onClose={() => {
          !reduxNewVehicle.paymentData.dni &&
            dispatch(setReduxPaymentData({ ...reduxNewVehicle.paymentData, method: null }))
          setAccountModal({ show: false, data: null })
        }}
        onAccept={handleAccountModalAccept}
        closeAfterAccept={false}
        title="Cuenta de cobro encontrada..."
        message={`${accountModal?.data?.dni} - ${accountModal?.data?.name}`}
        message2="¿Facturar a esta cuenta?"
      />
    </>
  )
}
