import React from 'react'

// MY IMPORTS
import 'components/Inspection/Check/InspectionTime/InspectionTime.scss'
import Clock from 'components/Inspection/Clock/Clock'

export default function InspectionTime({ timeToShow }) {
  return (
    <section className="inspectionTime">
      <h3>TIEMPO DE INSPECCIÓN</h3>
      <Clock dateFrom={timeToShow} seconds={true} icon={false} className="inspectionTime__clock" />
    </section>
  )
}
