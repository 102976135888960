//@ts-check
import MarkdownRender from 'components/Others/MarkdownRender/MarkdownRender'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import React from 'react'

/**
 *
 * @param {{id?: string, loading?: boolean, content?: string, textRef?: any}} param0
 * @returns
 */
export default function Message({ id, loading = false, content, textRef }) {
  return (
    <div
      className={
        loading
          ? 'chatbot-body__message-container--LOADING'
          : 'chatbot-body__message-container--USER'
      }
      id={id}
    >
      <div className="chatbot-body__message">
        <div className="chatbot-body__text" ref={textRef}>
          {loading ? (
            <ChildSpinner width="3rem" height="1rem" color="#333" />
          ) : (
            <MarkdownRender text={content} />
          )}
        </div>
      </div>
    </div>
  )
}
