import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export function getReport(reportId, options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}history/${reportId}/pdf`, {
    headers: { ...getJWT().headers },
    responseType: 'blob',
    signal: options.signal,
  })
}

export function postReportPreview({ id, grade }, options = {}) {
  return Axios.post(
    `${SERVER_URL}/api/inspection/report/preview`,
    { id, grade },
    {
      headers: { ...getJWT().headers },
      responseType: 'blob',
      signal: options.signal,
    }
  )
}
