import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import Modal from 'components/Others/Modal/Modal'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import React, { useCallback, useEffect, useState } from 'react'
import moment from 'moment/moment'
import CashRegistersConfiguratorModal from './CashRegistersConfiguratorModal/CashRegistersConfiguratorModal'
import { getAllCashRegisters as getAllCashRegistersService } from 'services/CashRegister'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import useService from 'hooks/useService'

export default function CashRegistersConfigurator() {
  const [modal, setModal] = useState({ show: false, data: null, isNew: true })
  const [toggleStateModal, setToggleStateModal] = useState({ show: false, data: null })
  const [showOnlyActive, setShowOnlyActive] = useState(true)
  const [data, setData] = useState(null)
  console.log({ cashRegisters: data })
  const { fetch: getAllCashRegisters, loading } = useService({
    service: getAllCashRegistersService,
  })
  const toggleCashRegisterStatus = () => {} //TODO:

  const fetchCashRegisters = useCallback(async () => {
    setData((await getAllCashRegisters())?.cashRegisters)
  }, [getAllCashRegisters])
  useEffect(() => {}, [])

  useEffect(() => {
    fetchCashRegisters()
  }, [fetchCashRegisters, getAllCashRegisters])

  return (
    <section className="cash-registers-manager">
      <MuiTable
        columnsKey="cashRegisterConfigurator"
        values={showOnlyActive ? data?.filter(elem => elem.active) || [] : data}
        loading={loading}
        sortByDate={false}
        showToolbar={false}
        onRowClick={rowData => setModal({ show: true, data: rowData, isNew: false })}
        toolbarComponent={
          <>
            <MyButtonsContainer fullWidth={false} margin="0 auto 0 0" styles={{ width: 'auto' }}>
              <MyButton
                text="Añadir caja"
                onClick={() => setModal({ show: true, data: null, isNew: true })}
              />
            </MyButtonsContainer>
            <Checkbox
              label="Solo activas"
              checked={showOnlyActive}
              onChange={value => setShowOnlyActive(value)}
            />
          </>
        }
        options={{
          filtering: true,
          selection: false,
          grouping: false,
        }}
      />
      {modal?.show && (
        <CashRegistersConfiguratorModal
          data={modal?.data}
          isNew={modal?.isNew}
          takenCashRegisterUserIds={data?.map(cr => cr?.user?.id)?.filter(elem => elem) || []}
          refetch={fetchCashRegisters}
          closeModal={() => {
            setModal({ show: false, data: null })
          }}
        />
      )}
      <Modal
        open={toggleStateModal?.show}
        onClose={() => setToggleStateModal({ show: false, data: null })}
        title={`${toggleStateModal.data?.active ? '¿Desactivar' : '¿Activar'} a ${
          toggleStateModal.data?.username
        } - ${toggleStateModal.data?.fullname}?`}
        onAccept={() => toggleCashRegisterStatus(toggleStateModal?.data)}
      />
    </section>
  )
}
