import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import EobdConfig from './EobdConfig/EobdConfig'
import { setReduxInspectionConfig } from 'redux/ducks/inspectionDuck'

export default function Eobd({ disabled = false }) {
  const [config, setConfig] = useState({
    show: false,
  })
  const dispatch = useDispatch()
  const redux = useSelector(store => store)
  const reduxInspectionConfig = redux?.inspection?.config
  const reduxEobdData = reduxInspectionConfig?.eobdData
  const isAvailable = ['Autobús', 'Camión'].includes(redux?.inspection?.filter?.name)
  const [values, setValues] = useState(() => {
    let obj = {}
    if (reduxEobdData?.euro === undefined) obj.euro = null
    else obj.euro = reduxEobdData?.euro ?? true
    return obj
  })

  // INICIALIZA MOSTRANDO MODAL SI NO HAY VALORES
  useEffect(() => {
    if (!config.show && isAvailable) {
      setConfig({ ...config, show: true })
    }
  }, [config, reduxEobdData, isAvailable])

  useEffect(() => {
    const newConfig = { ...(reduxInspectionConfig || {}), eobdData: values }
    dispatch(setReduxInspectionConfig(newConfig))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, values])

  return (
    <>
      {config.show && (
        <EobdConfig setValues={values => setValues(values)} values={values} disabled={disabled} />
      )}
    </>
  )
}
