import React from 'react'
import { useHistory } from 'react-router-dom'

// MY IMPORTS
import 'pages/OnlyAllowedRoles/OnlyAllowedRoles.scss'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'

export default function OnlyAllowedRoles() {
  const history = useHistory()
  return (
    <div className="only-allowed-roles">
      <i className="flaticon-forbidden"></i>
      <b className="no-values">NO TIENES PERMISOS PARA VER ESTE CONTENIDO</b>
      <MyButtonsContainer fullWidth={false}>
        <MyButton text="Volver Atrás" onClick={() => history.goBack()} />
      </MyButtonsContainer>
    </div>
  )
}
