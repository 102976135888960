import React, { useState, useRef, useEffect, useCallback } from 'react'
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import moment from 'moment'
import './DealInvoicePreviewModal.scss'
import formatEuro from 'myMethods/formatEuro'
import {
  getDealInvoiceDraft as getDealInvoiceDraftService,
  postDealInvoice as postDealInvoiceService,
} from 'services/Billing'
import PDFModal from 'components/Others/PDFModal/PDFModal'
import isValidFunction from 'myMethods/isValidFunction'
import useService from 'hooks/useService'

export default function DealInvoicePreviewModal({ data, closeModal, refetchData }) {
  const [formattedData, setFormattedData] = useState(null)
  const [loading, setLoading] = useState({ table: false, modal: false })
  const [draftModal, setDraftModal] = useState({ show: false, pdf: null })
  const tableRef = useRef()

  const { fetch: fetchDealDraft } = useService({
    service: getDealInvoiceDraftService,
    setOutLoading: value => setLoading(prevState => ({ ...prevState, table: value })),
  })

  const { fetch: postDealInvoice } = useService({
    service: postDealInvoiceService,
    setOutLoading: value => setLoading(prevState => ({ ...prevState, modal: value })),
    throwMessage: 'Ha ocurrido un error generando las facturas',
  })

  const formatData = useCallback(() => {
    try {
      if (!data?.length) throw new Error('No data provided')
      let finalData = []
      for (const dealInvoice of data) {
        for (const deal of dealInvoice.deals) {
          finalData.push({
            id: deal.id,
            client: `${dealInvoice.client.dni} - ${dealInvoice.client.name}`,
            number: deal.number,
            date: deal.date,
            total: deal.breakdown.total,
            reportNumber: deal.report?.number,
            inspectionType: deal.report?.inspectionType,
            tableData: { checked: true },
          })
        }
      }
      return finalData
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando las facturas')
    }
  }, [data])

  const showDraft = useCallback(
    async client => {
      try {
        const dealsIds = tableRef?.current?.dataManager?.data
          .filter(deal => deal.client === client && deal?.tableData?.checked)
          ?.map(deal => deal.id)

        if (!dealsIds?.length)
          return easyToast('error', 'No hay ningún albarán seleccionado para esta factura')
        const pdf = await fetchDealDraft({ dealsIds })
        setDraftModal({ show: true, pdf })
      } catch (error) {
        easyToast('error', 'Ha ocurrido un error cargando el borrador pdf')
      }
    },
    [fetchDealDraft]
  )

  const generateDealInvoices = async () => {
    try {
      const dealsIds = tableRef?.current?.dataManager?.data
        .filter(deal => deal?.tableData?.checked)
        ?.map(deal => deal.id)
      await postDealInvoice({ dealsIds })
      easyToast('dark', 'Facturas generadas correctamente')
      closeModal()
      isValidFunction(refetchData) && refetchData()
    } catch (error) {
      easyToast('error', 'Ha ocurrido un error generando las facturas')
    }
  }

  useEffect(() => {
    if (formattedData == null && data?.length) {
      setFormattedData(formatData(data))
    }
  }, [data, formatData, formattedData])

  return (
    <Modal
      open={true}
      onClose={closeModal}
      onAccept={generateDealInvoices}
      closeAfterAccept={false}
      loading={loading?.modal}
      title={`Facturas que se generarán ${
        data?.length == null ? '' : '(' + data.length + ' en total)'
      }`}
      content={
        <div className="deal-invoice-preview-modal">
          <MuiTable
            tableRef={tableRef}
            loading={loading?.table}
            values={formattedData ?? []}
            columns={[
              {
                title: 'Cliente',
                field: 'client',
                render: useCallback(
                  client => {
                    return (
                      <>
                        <span>{client}</span>
                        <button
                          style={{ marginLeft: '0.5rem', marginTop: '0.4rem', float: 'right' }}
                          className="action-btn"
                          onClick={() => showDraft(client)}
                        >
                          Ver borrador
                        </button>
                      </>
                    )
                  },
                  [showDraft]
                ),
                defaultGroupOrder: 1,
              },
              { title: 'Albarán', field: 'number' },
              {
                title: 'Fecha',
                render: useCallback(rowData => {
                  if (!rowData.date || !moment(rowData.date).isValid()) return ''
                  return moment(rowData.date).format('DD-MM-YYYY')
                }, []),
              },
              {
                title: 'Importe',
                render: useCallback(
                  rowData => (rowData.total ? formatEuro(rowData.total) : null),
                  []
                ),
              },
              { title: 'Informe', field: 'reportNumber' },
              { title: 'Inspección', field: 'inspectionType' },
            ]}
            options={{
              selection: true,
              grouping: false,
              filtering: false,
            }}
          />
          {draftModal?.show && (
            <PDFModal
              pdf={draftModal.pdf}
              closeModal={() => setDraftModal({ show: false, pdf: null })}
            />
          )}
        </div>
      }
    />
  )
}
