import React from 'react'
import { Switch, Route, useLocation } from 'react-router-dom'

// MY IMPORTS
import ProtectedRoute from './ProtectedRoute'

// ROUTES
import LoginPage from 'pages/Login/Login'
import Lobby from 'pages/Lobby/Lobby'
import OnlyMobile from 'pages/OnlyMobile/OnlyMobile'
import OnlyAllowedRoles from 'pages/OnlyAllowedRoles/OnlyAllowedRoles'
import NewInspection from 'pages/Inspection/NewInspection/NewInspection'
import InspectionMain from 'pages/Inspection/InspectionMain/InspectionMain'
import Remarks from 'pages/Inspection/Remarks/Remarks'
import Defects from 'pages/Inspection/Defects/Defects'
import ChildDefects from 'pages/Inspection/ChildDefects/ChildDefects'
import InspectionHistory from 'pages/Inspection/InspectionHistory/InspectionHistory'
import Images from 'pages/Inspection/Images/Images'
import HardwareMain from 'pages/Inspection/Hardware/Main'
import ComplementaryEquipment from 'pages/Inspection/ComplementaryEquipments/ComplementaryEquipments'
import Error404 from 'pages/Error404/Error404'
import Test from 'pages/Inspection/Hardware/Test'
import Check from 'pages/Inspection/Check/Check'
import SupervisionMain from 'pages/Supervision/SupervisionMain'
import History from 'pages/Supervision/History/History'
import ReportSample from 'pages/Supervision/ReportSample/ReportSamplePage'
import Clients from 'pages/Office/Clients/Clients'
import Vehicles from 'pages/Office/Vehicles/Vehicles'
import ItvConfigurator from 'pages/Supervision/ItvConfigurator/ItvConfigurator'
import OngoingInspections from 'pages/Office/OngoingInspections/OngoingInspections'
import CashRegister from 'pages/Office/CashRegister/CashRegister'
import AnimatedRoute from './AnimatedRoute'
import CreateInspection from 'pages/Office/CreateInspection/CreateInspection'
import UsersPage from 'pages/Users/UsersPage'
import BillingPage from 'pages/Office/Billing/Billing'
import StatisticsPage from 'pages/Statistics/Statistics'
import OfficeConfigurator from 'pages/Office/OfficeConfigurator/OfficeConfigurator'

export default function Routes() {
  const location = useLocation()
  return (
    // <AnimatePresence>
    <Switch location={location} key={location.pathname}>
      <AnimatedRoute exact path="/" component={LoginPage}>
        <Route exact path="/" component={LoginPage} />
      </AnimatedRoute>
      <AnimatedRoute exact path="/lobby" component={Lobby}>
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute exact path="/oops_mobile" component={OnlyMobile}>
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute exact path="/oops_roles" component={OnlyAllowedRoles}>
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/create_inspection/:license?/:frame?"
        component={CreateInspection}
        roles={['ADMINISTRATIVE', 'ACCOUNTANT', 'INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/new_inspection"
        component={NewInspection}
        forceMobile={true}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute
        exact
        path="/new_inspection/:license"
        component={InspectionMain}
        forceMobile={true}
        mobileMenu={true}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute
        exact
        path="/new_inspection/:license/remarks"
        component={Remarks}
        forceMobile={true}
        mobileMenu={true}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute
        exact
        path="/new_inspection/:license/defects"
        component={Defects}
        forceMobile={true}
        mobileMenu={true}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute
        exact
        path="/new_inspection/:license/defects/:manualChapter"
        component={ChildDefects}
        forceMobile={true}
        mobileMenu={true}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute
        exact
        path="/new_inspection/:license/history"
        component={InspectionHistory}
        forceMobile={true}
        mobileMenu={true}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute
        exact
        path="/new_inspection/:license/images"
        component={Images}
        forceMobile={true}
        mobileMenu={true}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute
        exact
        path="/new_inspection/:license/hardware"
        component={HardwareMain}
        forceMobile={true}
        mobileMenu={true}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute
        exact
        path="/new_inspection/:license/complementary_equipment"
        component={ComplementaryEquipment}
        forceMobile={true}
        mobileMenu={true}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute
        exact
        path="/new_inspection/:license/hardware/:machineType/:test"
        component={Test}
        forceMobile={true}
        mobileMenu={true}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute
        exact
        path="/new_inspection/:license/check"
        component={Check}
        forceMobile={true}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute exact path="/history" component={History} roles={['SUPERVISOR', 'BOSS']}>
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/detailed-history"
        component={SupervisionMain}
        roles={['INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/new_supervision/:license"
        component={SupervisionMain}
        roles={['SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/new_supervision/:license/:reportId"
        component={ReportSample}
        roles={['SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/ongoing_inspections"
        component={OngoingInspections}
        roles={['ADMINISTRATIVE', 'ACCOUNTANT', 'INSPECTOR', 'SUPERVISOR', 'BOSS']}
      >
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/vehicles"
        component={Vehicles}
        roles={['ADMINISTRATIVE', 'ACCOUNTANT', 'INSPECTOR', 'SUPERVISOR', 'BOSS']}
      >
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/clients"
        component={Clients}
        roles={['ADMINISTRATIVE', 'ACCOUNTANT', 'INSPECTOR', 'SUPERVISOR', 'BOSS']}
      >
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/billing"
        component={BillingPage}
        roles={['ADMINISTRATIVE', 'ACCOUNTANT']}
      >
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/cash-register"
        component={CashRegister}
        roles={['ADMINISTRATIVE', 'ACCOUNTANT', 'INSPECTOR', 'SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/office-configurator"
        component={OfficeConfigurator}
        roles={['ADMINISTRATIVE', 'ACCOUNTANT']}
      >
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute
        exact
        path="/itv_configurator"
        component={ItvConfigurator}
        roles={['SUPERVISOR']}
      >
        <ProtectedRoute />
      </AnimatedRoute>
      <AnimatedRoute exact path="/statistics" component={StatisticsPage}>
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute exact path="/users" component={UsersPage} roles={[]}>
        <ProtectedRoute />
      </AnimatedRoute>

      <AnimatedRoute path="/:rest*" component={Error404}>
        <Route />
      </AnimatedRoute>
    </Switch>
    // </AnimatePresence>
  )
}
