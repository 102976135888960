//@ts-check
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import React, { useEffect, useState, useRef } from 'react'
import MachineInfo from 'components/Inspection/Hardware/MachineInfo/MachineInfo'
import './MachineTestSend.scss'
import Frenometro from '../Tests/Frenometro/Frenometro'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import CardTextAndIcon from 'components/Others/Cards/CardTextAndIcon/CardTextAndIcon'
import isValidFunction from 'myMethods/isValidFunction'
import Opacimetro from '../Tests/Opacimetro/Opacimetro'
import ErrorView from 'components/Others/ErrorView/ErrorView'
import Sonometro from '../Tests/Sonometro/Sonometro'
import Eobd from '../Tests/Eobd/Eobd'
import AnalizadorDeGases from '../Tests/AnalizadorDeGases/AnalizadorDeGases'
import Velocimetro from '../Tests/Velocimetro/Velocimetro'
import MachineSelector from '../MachineSelector/MachineSelector'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import { PlayFill as StartIcon, Eye as ShowIcon } from 'react-bootstrap-icons'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Alineador from '../Tests/Alineador/Alineador'

/**
 * @typedef {import('types/HardwareConfig').Machine} Machine
 * @typedef {{machine: Machine | null, status: import('types/Inspection').HardwareStatus | null}} State
 * @typedef {import('types/Inspection').VehicleData} vehicleData
 */

/**
 *
 * @param {{testNames: string[], testNamesToWorkOn: string[], machinesByLine: {[key:string] : Machine[]}, state: State, setMachine: Function, loading: boolean, send: Function, sectionNumber: string, vehicleData: vehicleData, inspectionLine, currentTestsValuesButton?: {show: boolean, onClick: function}, machineDefectsButton?: {show: boolean, onClick: function}, showCurrentTestsValues?: Function}} param0
 * @returns
 */
export default function MachineTestSend({
  testNames,
  testNamesToWorkOn,
  machinesByLine,
  state,
  setMachine,
  loading,
  send,
  sectionNumber,
  vehicleData,
  inspectionLine,
  currentTestsValuesButton,
  machineDefectsButton,
}) {
  const [modalsShow, setModalsShow] = useState({
    info: false,
    line: false,
    otherActions: {},
  })
  const line = state?.machine?.line
  const [firstRender, setFirstRender] = useState(true)
  const [loadingFirstRender, setLoadingFirstRender] = useState(true)

  const cards = [
    <CardTextAndIcon
      key={1}
      icon={<i className="flaticon-diagnostic" />}
      text="CAMBIAR MÁQUINA"
      onClick={() => setModalsShow({ ...modalsShow, line: true })}
    />,
  ]
  if (state?.machine && !cards.some(card => card.props.text === 'INFO DE EQUIPOS')) {
    cards.push(
      <CardTextAndIcon
        icon={<i className="flaticon-setting-lines" />}
        text="INFO DE EQUIPOS"
        onClick={() => setModalsShow({ ...modalsShow, info: true })}
      />
    )
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        state != null &&
        state?.machine == null &&
        machinesByLine?.[inspectionLine] &&
        !modalsShow.line &&
        firstRender
      ) {
        setModalsShow(prevState => ({ ...prevState, line: true }))
        setFirstRender(false)
      }
      setLoadingFirstRender(false)
    }, 1000)

    return () => clearTimeout(timer)
  }, [inspectionLine, line, machinesByLine, modalsShow.line, state, firstRender, modalsShow])
  console.log({ state, machinesByLine, firstRender })

  try {
    return (
      <>
        {loading && (
          <div className="machine-test-send">
            <h3>ENVIANDO DATOS...</h3>
            <ChildSpinner />
          </div>
        )}
        {!loading && (
          <>
            {!loadingFirstRender && (
              <div className="machine-test-send">
                {state.machine && (
                  <h3>
                    ¿ENVIAR DATOS A MÁQUINA DE <span>LÍNEA {line}</span>?
                  </h3>
                )}
                {!state.machine && <h3>NINGUNA MÁQUINA ENCONTRADA EN LA LÍNEA POR DEFECTO</h3>}
                <ListItemsInTwoColumns>{cards}</ListItemsInTwoColumns>

                <div className="machine-test-send__tests">
                  {state?.machine && testNames.includes('analizador de gases') && (
                    <AnalizadorDeGases
                      sectionNumber={sectionNumber}
                      vehicleData={vehicleData}
                      disabled={!testNamesToWorkOn.includes('analizador de gases')}
                    />
                  )}

                  {state?.machine && testNames.includes('alineador') && (
                    <Alineador disabled={!testNamesToWorkOn.includes('alineador')} />
                  )}

                  {state?.machine && testNames.includes('eobd') && (
                    <Eobd disabled={!testNamesToWorkOn.includes('eobd')} />
                  )}

                  {state?.machine && testNames.includes('frenómetro') && (
                    <Frenometro disabled={!testNamesToWorkOn.includes('frenómetro')} />
                  )}

                  {state?.machine && testNames.includes('opacímetro') && (
                    <Opacimetro
                      sectionNumber={sectionNumber}
                      disabled={!testNamesToWorkOn.includes('opacímetro')}
                    />
                  )}

                  {state?.machine && testNames.includes('sonómetro') && (
                    <Sonometro
                      machineSettings={state?.machine?.settings?.['sonómetro']}
                      disabled={!testNamesToWorkOn.includes('sonómetro')}
                    />
                  )}

                  {state?.machine && testNames.includes('velocímetro') && (
                    <Velocimetro
                      sectionNumber={sectionNumber}
                      vehicleData={vehicleData}
                      disabled={!testNamesToWorkOn.includes('velocímetro')}
                    />
                  )}
                </div>

                {state?.machine && testNamesToWorkOn?.length !== testNames?.length && (
                  <div className="machine-test-send__alert">
                    <i className="flaticon-warning" />
                    <span>
                      SOLO SE ENVIARÁN DATOS PARA{' '}
                      {testNamesToWorkOn?.join(', ')?.toUpperCase() || ''}
                    </span>
                  </div>
                )}

                {state?.machine && (
                  <MyButtonsContainer>
                    <MyButton
                      text="Iniciar prueba"
                      icon={<StartIcon />}
                      onClick={() => isValidFunction(send) && send()}
                    />
                  </MyButtonsContainer>
                )}
                {(currentTestsValuesButton?.show || machineDefectsButton?.show) && (
                  <MyButtonsContainer margin="2rem auto 0 auto">
                    {currentTestsValuesButton?.show && (
                      <MyButton
                        transparent={true}
                        text="Mostrar resultados"
                        icon={<ShowIcon />}
                        onClick={() =>
                          isValidFunction(currentTestsValuesButton?.onClick) &&
                          currentTestsValuesButton.onClick()
                        }
                      />
                    )}
                    {machineDefectsButton?.show && (
                      <MyButton
                        text="Mostrar defectos"
                        icon={<ShowIcon />}
                        transparent={true}
                        onClick={() =>
                          isValidFunction(machineDefectsButton?.onClick) &&
                          machineDefectsButton.onClick()
                        }
                      />
                    )}
                  </MyButtonsContainer>
                )}
              </div>
            )}
            {loadingFirstRender && (
              <div className="machine-test-send">
                <ChildSpinner mintLogo={true} />
              </div>
            )}
            {modalsShow.info && (
              <MachineInfo
                testNames={testNames}
                machine={state?.machine}
                closeModal={() => setModalsShow({ ...modalsShow, info: false })}
              />
            )}
            {modalsShow.line && (
              <MachineSelector
                machinesByLine={machinesByLine}
                currentMachine={state.machine}
                setMachine={setMachine}
                closeModal={() => setModalsShow({ ...modalsShow, line: false })}
                currentLine={line ?? inspectionLine}
              />
            )}
          </>
        )}
      </>
    )
  } catch (err) {
    console.error(err)
    return <ErrorView />
  }
}
