import moment from 'moment'

export default function formatDateToStringAgo(date) {
  try {
    if (!date) return '-'
    const startTime = moment(date)
    const duration = moment.duration(moment().diff(startTime))
    if (
      !duration._data.days &&
      !duration._data.hours &&
      !duration._data.minutes &&
      duration._data.milliseconds
    )
      return 'Justo ahora'
    let finalDate = 'Hace '

    if (duration._data.days) finalDate += `${duration._data.days}d `
    if (duration._data.hours) finalDate += `${duration._data.hours}h `
    if (duration._data.minutes) finalDate += `${duration._data.minutes}min`

    return finalDate
  } catch (err) {
    console.error(err)
    return '-'
  }
}
