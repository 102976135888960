import React, { useState, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'
import { useDispatch, useSelector } from 'react-redux'
import MobileMenu from 'components/MobileMenu/MobileMenu'
import Banner from 'components/Inspection/Banner/Banner'
import useLogin from 'hooks/useLogin'
import { setReduxJWT, setReduxUserData } from 'redux/ducks/userDuck'
import { isAuth as isAuthService } from 'services/Auth'
import useService from 'hooks/useService'
import useResources from 'hooks/useResources'

export default function ProtectedRoute({
  component: Component,
  mobileMenu = false,
  forceMobile = false,
  roles = [],
  ...rest
}) {
  const redux = useSelector(store => store)
  const reduxRole = redux?.user?.data?.role
  const role = reduxRole || window.sessionStorage.getItem('role')
  const isAllowedRole = () => (role ? Boolean(!roles.length || roles.includes(role)) : false)
  const { logout, isLogged } = useLogin()
  const [auth, setAuth] = useState(isLogged)
  const dispatch = useDispatch()

  const isMobile = true

  const { fetch: isAuth } = useService({
    service: isAuthService,
  })

  const { roles: rolesNames } = useResources({
    roles: true,
  })

  // Check auth
  useEffect(() => {
    if (auth && !isLogged) return setAuth(false)
    isAuth()
      .then(res => {
        if (res === 'User is authenticated') {
          dispatch(setReduxJWT(window.sessionStorage.getItem('jwt')))
          let decoded = jwtDecode(window.sessionStorage.getItem('jwt'))
          const roleName = rolesNames?.find(elem => elem.value === decoded?.role)?.label
          const obj = {
            id: decoded.id,
            user: decoded.username,
            role: decoded.role?.toUpperCase(),
            roleName,
            userFullName: decoded.fullname,
          }
          dispatch(setReduxUserData({ ...(redux?.user?.data || {}), ...obj }))
          window.sessionStorage.setItem('role', decoded.role)
          window.sessionStorage.setItem('userFullName', decoded.fullname)
          if (decoded.username) {
            document.title = 'Mint - ' + decoded.username
          } else {
            document.title = 'Mint'
          }
          auth || setAuth(true)
        } else {
          auth && setAuth(false)
        }
      })
      .catch(err => {
        console.error(err)
        auth && setAuth(false)
      })
    //eslint-disable-next-line
  }, [dispatch])
  return (
    <Route
      {...rest}
      render={props => {
        // Si está auth
        if (auth) {
          // Si tiene permisos de rol
          if (isAllowedRole()) {
            // Si el componente está forzado para móvil
            if (!forceMobile) {
              return <Component {...props} />
            } else if (forceMobile && isMobile) {
              return (
                <>
                  <Component {...props} redux={redux} />
                  {mobileMenu && (
                    <>
                      <div style={{ height: '3rem' }}></div>
                      {<Banner />}
                      <MobileMenu />
                    </>
                  )}
                </>
              )
            } else {
              return <Redirect to="oops_mobile" />
            }
          } else {
            return <Redirect to="oops_roles" />
          }
        } else {
          return logout('jwtExpired')
        }
      }}
    />
  )
}
