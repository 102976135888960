export default function calificationToText(calification, upperCase = true) {
  try {
    calification = calification?.toUpperCase()
    switch (calification) {
      case 'L':
        return upperCase ? 'LEVE' : 'Leve'
      case 'G':
        return upperCase ? 'GRAVE' : 'Grave'
      case 'VG':
        return upperCase ? 'MUY GRAVE' : 'Muy grave'
      default:
        return '-'
    }
  } catch (err) {
    console.error(err)
    return '-'
  }
}
