import React, { useState, useEffect } from 'react'
import Modal from 'components/Others/Modal/Modal'
import './EditFilterDefectModal.scss'
import Switch from 'components/Others/Switch/Switch'
import easyToast from 'components/Others/EasyToast/easyToast'
import isValidFunction from 'myMethods/isValidFunction'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Select from 'components/Others/Select/Select'
import useInspectionManual from 'hooks/useInspectionManual'

const parseToSelectValues = list => {
  try {
    return list.map(elem => ({ value: elem.id, label: `${elem.id} - ${elem.name}` }))
  } catch (err) {
    console.error(err)
    return []
  }
}

export default function EditFilterDefectModal({
  closeModal,
  filter,
  defectId,
  edit = false,
  saveChanges,
}) {
  const [loading, setLoading] = useState(false)
  const { inspectionManual, loading: loadingInspectionManual } = useInspectionManual(
    filter?.section
  )
  const manual = inspectionManual?.getParents()
  const selectOptions = parseToSelectValues(
    manual?.filter(elem => !elem.calification && !filter?.defects?.some(el => elem.id === el.id))
  )
  const [defects, setDefects] = useState(null)
  console.log({ manual })
  const [value, setValue] = useState()

  const handleAccept = () => {
    try {
      if (!value) {
        if (edit) return easyToast('error', 'Ningún defecto seleccionado')
        else throw new Error('Missing value in hhandleAccept function')
      }
      let newFilter = JSON.parse(JSON.stringify(filter))
      let newDefect = {
        id: value.id,
        name: value.name,
        optional: Boolean(value.optional),
      }
      if (
        value.optional &&
        Object.prototype.hasOwnProperty.call(value, 'initialValue') &&
        typeof value.initialValue === 'boolean'
      )
        newDefect.initialValue = value.initialValue
      if (edit) {
        const index = newFilter.defects.findIndex(el => el.id === defectId)
        if (index === -1) throw new Error('Defect not found in filter')
        newFilter.defects[index] = newDefect
      } else {
        newFilter.defects.push(newDefect)
      }
      setLoading(true)
      saveChanges(newFilter)
    } catch (err) {
      console.error(err)
      easyToast('error', 'No se ha podido guardar el defecto')
    } finally {
      loading && setLoading(false)
    }
  }

  const handleDelete = () => {
    try {
      if (!value) {
        if (edit) return easyToast('error', 'Ningún defecto seleccionado')
        else throw new Error('Missing value in hhandleAccept function')
      }
      let newFilter = JSON.parse(JSON.stringify(filter))
      const index = newFilter.defects.findIndex(elem => elem.id === value.id)
      if (index === -1) throw new Error('No defect found with id ' + value.id)
      newFilter.defects.splice(index, 1)
      setLoading(true)
      isValidFunction(saveChanges) && saveChanges(newFilter)
    } catch (err) {
      console.error(err)
      easyToast('dark', 'Ha ocurrido un error eliminado el defecto')
    } finally {
      loading && setLoading(false)
    }
  }

  useEffect(() => {
    if (filter?.defects?.length && defects == null && manual?.length) {
      let foundDefects = []
      filter.defects.forEach(def => {
        const found = manual.find(manDef => manDef.id === def.id)
        found && foundDefects.push({ ...def, ...found })
      })
      setDefects(foundDefects)
    }
  }, [filter.defects, defects, manual])

  useEffect(() => {
    if (defectId && !value && defects?.length) {
      const defect = defects.find(elem => elem.id === defectId)
      if (defect) setValue(defect)
    }
  }, [defectId, value, defects])
  return (
    <Modal
      open={true}
      onClose={closeModal}
      title={edit ? 'Editar defecto' : 'Añadir defecto'}
      closeOnOverlay={false}
      hasAcceptButton={Boolean(value)}
      onAccept={handleAccept}
      loading={loading}
      closeAfterAccept={false}
      content={
        <div className="edit-filter-defect-modal">
          {!edit && (
            <Select
              placeholder="Escribe para buscar un defecto..."
              options={selectOptions}
              value={selectOptions?.find(elem => elem.id && value?.id && elem.id === value.id)}
              onChange={opt => {
                setValue(
                  opt ? manual?.find(elem => elem.id && opt.value && elem.id === opt.value) : null
                )
              }}
            />
          )}
          {!value && edit && (
            <div className="no-values">Ha ocurrido un error cargando el defecto</div>
          )}
          {value && (
            <>
              <div className="edit-filter-defect-modal__edit-value">
                <h3>{value.id + ' - ' + value.name}</h3>
                <Switch
                  label="Es defecto opcional"
                  value={value?.optional}
                  onChange={checked => {
                    let aux = { ...value }
                    aux.optional = checked

                    if (!checked && Object.prototype.hasOwnProperty.call(aux, 'initialValue')) {
                      delete aux.initialValue
                    }
                    setValue(aux)
                  }}
                />
                {value?.optional && (
                  <Switch
                    label="Aplica por defecto"
                    value={value?.initialValue}
                    onChange={checked => setValue({ ...value, initialValue: checked })}
                  />
                )}
              </div>
              {edit && (
                <MyButtonsContainer>
                  <MyButton
                    text="Eliminar defecto"
                    icon={<i className="flaticon-trash" />}
                    onClick={handleDelete}
                  />
                </MyButtonsContainer>
              )}
            </>
          )}
        </div>
      }
    />
  )
}
