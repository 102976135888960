import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import manualChaptersJSON from 'resources/manualChapters.json'

//MY IMPORTS
import ManualChapterCard from './ManualChapter'
import ManualChapter from './ManualChapter'
import RenderListOfManualChapters from './RenderListOfManualChapters'

/**
 * @typedef {import("Share/Defect/InspectionDefectsManager").default} ManualManager
 */

/**
 *
 * @param {{inspectionProperties: import('types/InspectionProperties').InspectionProperty, isFiltered: boolean, manualManager: ManualManager}} param0
 * @returns
 */
export default function ListOfManualChapters() {
  const [manualChapters, setManualChapters] = useState([])
  const reduxInspection = useSelector(store => store.inspection)
  const isFiltered = reduxInspection?.isFiltered
  const inspectionProperties = reduxInspection?.properties
  const manualManager = reduxInspection?.manualManager

  useEffect(() => {
    try {
      if (isFiltered != null && !isFiltered) return setManualChapters(manualChaptersJSON)
      if (inspectionProperties == null || manualManager == null)
        return setManualChapters(manualChaptersJSON)

      let newManualChapters = JSON.parse(JSON.stringify(manualChaptersJSON))
      newManualChapters = newManualChapters.filter(({ chapter, title }) => {
        const chapterNode = manualManager.findDefectAndChilds(chapter)
        if (
          reduxInspection?.inspectionType === 'SEGUNDA INSPECCIÓN O SUCESIVAS' &&
          reduxInspection?.previousInspection
        ) {
          //TODO: Si es segunda inspección
          const defects = [
            ...(reduxInspection.previousInspection.visualDefects || []),
            ...(reduxInspection.previousInspection.machineDefects || []),
          ]
          if (!defects?.length) throw new Error('No defects found in previous inspection')
          console.log({ defects })
          if (
            defects.some(
              defect =>
                defect.id?.split('.')[0] === chapter || defect.defect?.id?.split('.')[0] === chapter
            )
          )
            return true
          return false
        } else {
          let hasChildrenThatApply = false
          chapterNode?.childs.forEach(childNode => {
            if (childNode.defect.apply) hasChildrenThatApply = true
          })
          console.log({ chapter, hasChildrenThatApply, chapterNode })
          return hasChildrenThatApply
        }
      })
      setManualChapters(newManualChapters)
    } catch (err) {
      console.error(err)
      setManualChapters(manualChaptersJSON)
    }
  }, [
    inspectionProperties,
    isFiltered,
    manualChapters.length,
    manualManager,
    reduxInspection.inspectionProperties,
    reduxInspection?.inspectionType,
    reduxInspection.previousInspection,
  ])

  return (
    <RenderListOfManualChapters
      list={manualChapters}
      manualManager={reduxInspection?.manualManager}
    />
  )
}
