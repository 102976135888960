//@ts-check
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import ErrorView from 'components/Others/ErrorView/ErrorView'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import React from 'react'
import './CollectedValues.scss'

/**
 *
 * @param {{reduxInspection: import('types/Inspection').Inspection}} param0
 */
export default function CollectedValues({ reduxInspection, ...rest }) {
  try {
    const axles = reduxInspection?.axles
    const techWeights = reduxInspection?.techWeights
    const brakeMeterData = reduxInspection?.config?.brakeMeterData
    const opacimeterData = reduxInspection?.config?.opacimeterData
    const gasAnalyzerData = reduxInspection?.config?.gasAnalyzerData
    const eobdData = reduxInspection?.config?.eobdData
    const soundLevelData = reduxInspection?.config?.soundLevelData

    return (
      <div className="supervision-collected-values" {...rest}>
        {axles && (
          <ListItemsInTwoColumns title="EJES">
            <CardNameValue name="Vehículo" value={axles.tractor || '-'} />
            <CardNameValue name="Remolque" value={axles.trailer || '-'} />
          </ListItemsInTwoColumns>
        )}
        {techWeights && (
          <ListItemsInTwoColumns title="MASAS">
            {techWeights?.mma != null && (
              <CardNameValue name="MMA" value={techWeights.mma + ' kg'} />
            )}
            {techWeights?.mom != null && (
              <CardNameValue name="MOM" value={techWeights.mom + ' kg'} />
            )}
            {techWeights?.mmaTrailer != null && (
              <CardNameValue name="MMA remolque" value={techWeights.mmaTrailer + ' kg'} />
            )}
            {techWeights?.momTrailer != null && (
              <CardNameValue name="MOM remolque" value={techWeights.momTrailer + ' kg'} />
            )}
            {techWeights?.mmaByAxles &&
              Object.entries(techWeights.mmaByAxles).map(([axle, value], i) => (
                <CardNameValue name={axle.toUpperCase()} value={value + ' kg'} key={i} />
              ))}
          </ListItemsInTwoColumns>
        )}
        {brakeMeterData && (
          <ListItemsInTwoColumns title="FRENÓMETRO">
            {brakeMeterData?.isSemiTrailer != null && (
              <CardNameValue
                name="Semirremolque"
                value={brakeMeterData.isSemiTrailer ? 'Sí' : 'No'}
              />
            )}
          </ListItemsInTwoColumns>
        )}
        {opacimeterData && (
          <ListItemsInTwoColumns title="OPACÍMETRO">
            {opacimeterData?.euro != null && (
              <CardNameValue name="EURO" value={opacimeterData.euro} />
            )}
            {opacimeterData?.plate != null && (
              <CardNameValue name="Valor de placa" value={opacimeterData.plate} />
            )}
            {opacimeterData?.turbo != null && (
              <CardNameValue name="Turbo" value={opacimeterData.turbo ? 'Sí' : 'No'} />
            )}
          </ListItemsInTwoColumns>
        )}
        {gasAnalyzerData && (
          <ListItemsInTwoColumns title="ANALIZADOR DE GASES">
            {gasAnalyzerData?.accelerated != null && (
              <CardNameValue name="Acelerado" value={gasAnalyzerData.accelerated ? 'Sí' : 'No'} />
            )}
          </ListItemsInTwoColumns>
        )}
        {eobdData && (
          <ListItemsInTwoColumns title="EOBD">
            {eobdData?.euro != null && <CardNameValue name="EURO" value={eobdData.euro} />}
            {eobdData?.turbo != null && (
              <CardNameValue name="Turbo" value={eobdData.turbo ? 'Sí' : 'No'} />
            )}
          </ListItemsInTwoColumns>
        )}
        {soundLevelData && (
          <ListItemsInTwoColumns title="SONÓMETRO">
            {soundLevelData?.db != null && <CardNameValue name="db" value={soundLevelData.db} />}
            {soundLevelData?.rpm != null && <CardNameValue name="RPM" value={soundLevelData.rpm} />}
            {soundLevelData?.doubleExhaust != null && (
              <CardNameValue
                name="Doble escape"
                value={soundLevelData.doubleExhaust ? 'Sí' : 'No'}
              />
            )}
          </ListItemsInTwoColumns>
        )}
      </div>
    )
  } catch (err) {
    console.error(err)
    return <ErrorView />
  }
}
