import React, { useState, useEffect, useCallback } from 'react'

// MY IMPORTS
import './EndOfDay.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import formatEuro from 'myMethods/formatEuro'
import StackedBarsChart from 'components/Others/Charts/StackedBars'
import PieChart from 'components/Others/Charts/Pie/Pie'
import ListCashRegisterMovementsTable from '../ListCashRegisterMovements/ListCashRegisterMovementsTable'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { getCashRegisterEndOfDayReport as getCashRegisterEndOfDayReportService } from 'services/CashRegister'
import PDFModal from 'components/Others/PDFModal/PDFModal'
import useService from 'hooks/useService'

export default function EndOfDay({ status, date, isChanging }) {
  const [cashRegisters, setCashRegisters] = useState([])
  const [movements, setMovements] = useState([])
  const [chartsData, setChartsData] = useState({
    bars: [],
    pie: [],
  })
  const [cashRegistersToShow, setCashRegisterToShow] = useState(cashRegisters)
  const [barDataKey, setBarDataKey] = useState('value')
  const [barSelector, setBarSelector] = useState(1)
  const [tpvNames, setTpvNames] = useState([])
  const [onlyTakePart, setOnlyTakePart] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showReportModal, setShowReportModal] = useState(false)

  const {
    serviceData: reportPdf,
    fetch: getCashRegisterEndOfDayReport,
    loading: loadingReport,
  } = useService({
    service: getCashRegisterEndOfDayReportService,
  })

  const showDailyReport = async () => {
    try {
      await getCashRegisterEndOfDayReport(date || undefined)
      setShowReportModal(true)
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error cargando el reporte diario')
    }
  }
  const toggleOnlyTakePart = () => {
    try {
      if (!onlyTakePart) {
        setCashRegisterToShow(
          cashRegistersToShow.filter(
            cr => cr.cashFlowsData?.length || cr.start !== '-' || cr.end !== '-' || cr.revenue
          )
        )
        setOnlyTakePart(true)
      } else {
        if (cashRegistersToShow.length !== cashRegisters.length) {
          setCashRegisterToShow(cashRegisters)
        }
        setOnlyTakePart(false)
      }
    } catch (err) {
      console.error(err)
    }
  }

  const toggleCashRegister = name => {
    setLoading(true)
    try {
      let aux = JSON.parse(JSON.stringify(cashRegistersToShow))
      const index = cashRegistersToShow.findIndex(cr => cr.name === name)
      if (index < 0) {
        const found = cashRegisters.find(cr => cr.name === name)
        if (!found) throw Error('cashRegister not found in toggleCashRegister')
        aux.push(found)
        if (found.start === '-' && onlyTakePart) toggleOnlyTakePart()
      } else {
        if (cashRegistersToShow.length < 2) {
          easyToast('error', 'Debe haber una caja al menos')
          return
        }
        aux.splice(index, 1)
        setCashRegisterToShow(aux)
      }
      setCashRegisterToShow(aux)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error')
    } finally {
      setLoading(false)
    }
  }
  console.log({ cashRegisters, status })

  useEffect(() => {
    try {
      let aux = []

      status?.cashRegisters?.forEach(elem => {
        const pushPayment = invoice => {
          if (!invoice.paymentMethod) return
          // Tipo de pago
          if (paymentsByType[invoice.paymentMethod]) {
            paymentsByType[invoice.paymentMethod].total += invoice.breakdown.total
            paymentsByType[invoice.paymentMethod].data.push(invoice)
          } else {
            paymentsByType[invoice.paymentMethod] = {
              total: invoice.breakdown.total,
              data: [invoice],
            }
          }
          // Detalles de cada tarjeta
          if (invoice.paymentMethod === 'TARJETA') {
            const name = invoice.paymentDetails?.tpv?.name || 'Otros'
            if (paymentsByType[invoice.paymentMethod].details) {
              if (paymentsByType[invoice.paymentMethod].details[name]) {
                paymentsByType[invoice.paymentMethod].details[name].total += invoice.breakdown.total
                paymentsByType[invoice.paymentMethod].details[name].usedTimes += 1
              } else {
                paymentsByType[invoice.paymentMethod].details[name] = {
                  total: invoice.breakdown.total,
                  usedTimes: 1,
                }
              }
            } else {
              paymentsByType[invoice.paymentMethod].details = {
                [name]: {
                  total: invoice.breakdown.total,
                  usedTimes: 1,
                },
              }
            }
          }
        }

        if (elem?.name === 'ITV' || elem?.desactivatedAt) return
        const start =
          elem.archings?.[elem.archings.length - 1]?.reason === 'INICIO'
            ? elem.archings[elem.archings.length - 1]
            : elem.archings?.reverse().find(elem => elem.reason === 'INICIO') || null

        const end =
          elem.archings?.[0]?.reason === 'FIN'
            ? elem.archings[0]
            : elem.archings?.reverse().find(elem => elem.reason === 'FIN') || null

        let revenue = 0
        let creditRevenue = 0
        let cashFlowsData = []
        let cashFlows = 0
        let itvCashFlows = 0
        let paymentsByType = {}

        elem.invoices.concat(elem.deals).forEach(el => {
          if (['invoice', 'ticket', 'cancelledInvoice', 'cancelledTicket'].includes(el.serie)) {
            revenue += el.breakdown?.total || 0
          } else if (el.serie === 'deal') {
            creditRevenue += el.breakdown?.total || 0
          }
          pushPayment(el)
        })
        //CashFlows
        elem.cashFlows?.forEach(cf => {
          cashFlowsData.push(cf)
          if (cf.reason === 'FIN') return
          cashFlows += cf.amount || 0
        })
        //itvCashFlows
        elem.cashFlows
          ?.filter(cf => cf.reason === 'FIN')
          ?.forEach(cf => {
            itvCashFlows += cf.amount || 0
          })

        aux.push({
          name: elem.name,
          start: start?.cash == null ? '-' : formatEuro(start.cash),
          end: end?.cash == null ? '-' : formatEuro(end.cash),
          difference: end?.rest == null ? '-' : formatEuro(end.rest),
          revenue,
          revenueEuro: formatEuro(revenue),
          creditRevenue: formatEuro(creditRevenue),
          cashFlowsData,
          cashFlows: formatEuro(cashFlows),
          itvCashFlows: formatEuro(Math.abs(itvCashFlows)),
          cashLeft: end?.cash == null ? '-' : formatEuro(end.cash - Math.abs(itvCashFlows)),
          paymentsByType,
        })
      })
      setCashRegisterToShow(aux)
      setCashRegisters(aux)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error')
    }
  }, [status?.cashRegisters])

  //Generate movements
  useEffect(() => {
    let aux = []
    cashRegistersToShow?.forEach(cr => {
      if (cr.paymentsByType) {
        Object.values(cr.paymentsByType).forEach(elem => {
          elem.data.forEach(data => aux.push({ ...data, cashRegisterName: cr.name }))
        })
      }
      if (cr.cashFlowsData) {
        cr.cashFlowsData.forEach(elem => aux.push({ ...elem, cashRegisterName: cr.name }))
      }
    })
    setMovements(aux)
  }, [cashRegistersToShow])

  // Genera data para Charts
  useEffect(() => {
    if (cashRegistersToShow?.length) {
      // bars
      let barData1 = []
      let barData2 = []
      let tpvNames = []

      cashRegistersToShow.forEach(cr => {
        if (cr.paymentsByType) {
          Object.entries(cr.paymentsByType).forEach(([key, value]) => {
            const found = barData1.find(elem => elem.name === key)
            if (key === 'TARJETA') {
              if (value.details) {
                Object.entries(value.details).forEach(([tpvName, tpvValue]) => {
                  tpvNames.push(tpvName)
                  const found = barData1.find(elem => elem.name === key)
                  if (found) {
                    const foundTpv = barData1[barData1.indexOf(found)]?.[tpvName]
                    if (foundTpv) {
                      barData1[barData1.indexOf(found)][tpvName] += tpvValue.total
                    } else {
                      barData1[barData1.indexOf(found)][tpvName] = tpvValue.total
                    }
                  } else {
                    barData1.push({
                      name: key,
                      [tpvName]: tpvValue.total,
                    })
                  }
                })
              }
              return
            }
            if (found) {
              barData1[barData1.indexOf(found)].value += value.total
            } else {
              barData1.push({
                name: key,
                value: value.total,
              })
            }
          })
        }
      })
      setBarDataKey('value')

      const seriesToShow = ['invoice', 'ticket']
      cashRegistersToShow.forEach(cr => {
        if (cr.paymentsByType) {
          Object.entries(cr.paymentsByType).forEach(([key, value]) => {
            const found = barData2.find(elem => elem.name === key)
            if (key === 'TARJETA') {
              if (value.total <= 0) return
              if (value.details) {
                Object.entries(value.details).forEach(([tpvName, tpvValue]) => {
                  tpvNames.push(tpvName)
                  const found = barData2.find(elem => elem.name === key)
                  if (found) {
                    const foundTpv = barData2[barData2.indexOf(found)]?.[tpvName]
                    if (foundTpv) {
                      barData2[barData2.indexOf(found)][tpvName] += tpvValue.usedTimes
                    } else {
                      barData2[barData2.indexOf(found)][tpvName] = tpvValue.usedTimes
                    }
                  } else {
                    barData2.push({
                      name: key,
                      [tpvName]: tpvValue.usedTimes,
                    })
                  }
                })
              }
              return
            }
            if (found) {
              barData2[barData2.indexOf(found)].Pagos +=
                value.data?.filter(inv => seriesToShow.includes(inv.serie))?.length || 0
            } else {
              barData2.push({
                name: key,
                Pagos: value.data?.filter(inv => seriesToShow.includes(inv.serie))?.length || 0,
              })
            }
          })
        }
      })
      setBarDataKey('Pagos')

      setTpvNames([...new Set(tpvNames)])
      setChartsData(prevState => ({ ...prevState, bars: { 1: barData1, 2: barData2 } }))

      // pie
      let pieData = []
      cashRegistersToShow.forEach(
        cr => cr.name && cr.revenue > 0 && pieData.push({ name: cr.name, value: cr.revenue })
      )
      setChartsData(prevState => ({ ...prevState, pie: pieData }))
    }
  }, [cashRegistersToShow])
  console.log({ chartsData })
  return (
    <>
      {loading && <ChildSpinner visible={true} />}
      {!loading && !isChanging && (!status || !Object.keys(status).length > 0) && (
        <div className="no-values">Vaya... parece que aún no tienes asignada una caja</div>
      )}
      {!loading && !isChanging && cashRegisters && cashRegisters.length > 0 && (
        <section className="end-of-day">
          <MyButtonsContainer margin="0 auto 1rem">
            <MyButton text="Ver reporte diario" onClick={showDailyReport} loading={loadingReport} />
          </MyButtonsContainer>
          {status?.cashRegisters && status.cashRegisters.length > 1 && (
            <div className="end-of-day__selector">
              <div>
                {status.cashRegisters
                  .filter(elem => elem.name !== 'ITV')
                  ?.map((elem, i) => (
                    <div
                      key={i}
                      className={
                        cashRegistersToShow?.some(cr => cr.name === elem.name)
                          ? 'end-of-day__selector-elem--selected'
                          : 'end-of-day__selector-elem'
                      }
                      onClick={() => toggleCashRegister(elem.name)}
                    >
                      {elem.name}
                    </div>
                  ))}
              </div>
            </div>
          )}
          <MuiTable
            data={cashRegisters.filter(cr =>
              cashRegistersToShow.some(elem => elem.name === cr.name)
            )}
            columnsKey="endOfDay"
            exportation={{
              type: 'END_OF_DAY_CASH_REGISTERS',
              data: {
                values: cashRegisters.filter(cr =>
                  cashRegistersToShow.some(elem => elem.name === cr.name)
                ),
              },
              modalTitle: 'Exportar resumen de cajas registradoras',
              defaultInputValue: 'RESUMEN DE CAJAS REGISTRADORAS',
              options: {
                date: date,
              },
            }}
            title="Cajas registradoras"
            options={{
              grouping: false,
              filtering: false,
              selection: true,
            }}
            toolbarComponent={
              <Checkbox
                label="Solo cajas intervinientes"
                checked={onlyTakePart}
                onChange={toggleOnlyTakePart}
              />
            }
          />
          <div className="end-of-day__charts">
            <div className="end-of-day__bar-chart">
              <h4>Métodos de pago</h4>
              <StackedBarsChart
                data={chartsData?.bars?.[barSelector]}
                dataKeys={chartsData?.bars?.[barSelector]?.length ? [barDataKey, ...tpvNames] : []}
                tooltip={true}
                formatAsEuros={barSelector === 1}
              />
              <div className="end-of-day__bar-selector-container">
                <div
                  onClick={() => setBarSelector(1)}
                  className={
                    barSelector === 1
                      ? 'end-of-day__bar-selector--selected'
                      : 'end-of-day__bar-selector'
                  }
                >
                  Por volúmen de ventas (€)
                </div>
                <div
                  onClick={() => setBarSelector(2)}
                  className={
                    barSelector === 2
                      ? 'end-of-day__bar-selector--selected'
                      : 'end-of-day__bar-selector'
                  }
                >
                  Por número de pagos
                </div>
              </div>
            </div>

            {chartsData.pie.length > 0 && (
              <div>
                <h4>Ingresos por caja</h4>
                <PieChart data={chartsData?.pie} unit={'€'} />
              </div>
            )}
          </div>
          <ListCashRegisterMovementsTable
            data={movements}
            endOfDay={true}
            cashRegisterName={status?.name}
            selectedDate={date}
          />
        </section>
      )}
      {!loading && !isChanging && cashRegisters && !cashRegisters.length > 0 && (
        <div className="no-values">No hay actividad de cajas</div>
      )}
      {showReportModal && (
        <PDFModal
          pdf={reportPdf}
          title="Reporte diario"
          closeModal={() => setShowReportModal(false)}
          loading={loadingReport}
        />
      )}
    </>
  )
}
