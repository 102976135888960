import React from 'react'
import InspectionHistory from 'components/Inspection/InspectionHistory/InspectionHistory'
import Title from 'components/Others/Title/Title'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import NoFilter from 'components/Inspection/NoFilter/NoFilter'

export default function InspectionHistoryPage() {
  const params = useParams()
  const license = params.license || window.sessionStorage.getItem('license')
  const reduxInspection = useSelector(store => store.inspection)
  return (
    <>
      {license && <Title text={`HISTORIAL DE ${license}`} />}
      {!reduxInspection?.filter && <NoFilter />}
      {reduxInspection?.filter && <InspectionHistory license={license} />}
    </>
  )
}
