//@ts-check
import moment from 'moment'

/**
 * @typedef {(import('../types/Hardware').HardwareResponse & {errors?: import('../types/Hardware').HardwareResponseError[]})[]} HardwareValueArray
 * @typedef {import('../types/HardwareSorted').HardwareResponseSortedByDivision} HardwareResponseSortedByDivision
 * @typedef {import('../types/Hardware').HardwareValue} HardwareValue
 * @typedef {import('../types/HardwareSorted').HardwareValueSortedByDivision} HardwareValueSortedByDivision
 * @typedef {import('types/TestValue').TestValues} TestValues
 *
 */
export default class HardwareMapperResponse {
  /**
   *
   * @param {HardwareValue[]}  hardwareValue
   * @returns {HardwareValueSortedByDivision[]}
   */
  static sortDataByDivision(hardwareValue) {
    /**@type {HardwareValueSortedByDivision[]} */
    let auxArray = []
    console.log({ hardwareValue })
    hardwareValue.forEach(eachResult => {
      const currentDivision = eachResult.division
      // Si tiene division, se tiene que mostrar, y no es freno de servicio de triciclo
      if (currentDivision && eachResult.show) {
        // Si tiene division
        let foundElem = auxArray.find(elem => elem.name === currentDivision)
        if (foundElem) {
          foundElem.values.push(eachResult)
          auxArray[auxArray.indexOf(foundElem)] = foundElem
        } else {
          auxArray.push({ name: currentDivision, values: [eachResult] })
        }
        // Si no tiene división
      } else if (!currentDivision && eachResult.show) {
        const found = auxArray.find(elem => elem.name === 'Otros')
        if (found) {
          let aux = found
          aux.values.push(eachResult)
          auxArray[auxArray.indexOf(found)] = aux
        } else {
          auxArray.push({ name: 'Otros', values: [eachResult] })
        }
      }
    })

    // Organiza divisiones alfabéticamente
    auxArray.sort((a, b) => a.name.localeCompare(b.name))

    // Comprueba que la división "Otros", quede al final
    const othersIndex = auxArray.findIndex(division => division.name === 'Otros')
    console.log({ otrosIndex: othersIndex })
    if (othersIndex !== auxArray.length - 1 && othersIndex !== -1) {
      const others = auxArray.find(division => division.name === 'Otros')
      auxArray.splice(othersIndex, 1)
      auxArray.push(others)
    }

    return auxArray
  }
  /**
   *
   * @param {TestValues[]} tests
   * @returns {TestValues[]}
   */
  static sortAllTestByDivision(tests) {
    if (!tests) return []
    let testsCopy = JSON.parse(JSON.stringify(tests))
    /** @type {TestValues[]} */
    let newTests = []
    for (let test of testsCopy) {
      if (!test.data || !test.data.length) {
        newTests.push({
          ...test,
          testName: test.testName,
          data: [],
        })
        continue
      }
      let newData = test.data
      console.log({ prueba: test })

      for (let data of newData) {
        console.log({ data, values: data.values })
        if (data.machine.manual) {
          data.values = [
            {
              name: 'Resultados',
              values: data.values,
            },
          ]
          console.log('Es manual, newData: ', { newData })
        } else {
          data.values = this.sortDataByDivision(data.values)
        }
      }
      test.data.sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf())

      newTests.push({
        ...test,
        testName: test.testName,
        data: newData,
      })
    }
    console.log({ newTests })
    return newTests
  }
}
