import React, { useEffect, useState } from 'react'
import './StatisticsTimes.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import StatisticsTimesAverageCard from './StatisticsTimesAverageCard/StatisticsTimesAverageCard'
import formatTimeToHoursMinutesSeconds from 'myMethods/formatTimeToHoursMinutesSeconds'
import TimesByCategory from './TimesByCategory/TimesByCategory'
import TimesByClassification from './TimesByClassification/TimesByClassification'
import TimesByInspectionType from './TimesByInspectionType/TimesByInspectionType'
import TimesByFuel from './TimesByFuel/TimesByFuel'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import TimesByUser from './TimesByUser/TimesByUser'
import StatisticsTools from '../StatisticsTools/StatisticsTools'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import AnimatedCounter from 'components/Others/AnimatedCounter/AnimatedCounter'

/**
 *
 * @param {{statisticsHandler: import('./../StatisticsHandler').default}} param0
 * @returns
 */
export default function StatisticsTimes({ statisticsHandler }) {
  const statisticsTimes = statisticsHandler?.times
  const dateRangeText = statisticsTimes?.dateRangeText
  const averageTimes = statisticsTimes?.data?.totalRange?.times?.averageTime
  const timesBy = statisticsTimes?.data?.totalRange?.timesBy
  const [loading, setLoading] = useState(false)
  const [modalToShow, setModalToShow] = useState('')
  const [isOfficeIncluded, setIsOfficeIncluded] = useState(
    statisticsTimes?.isOfficeIncluded || false
  )

  console.log({ statisticsTimes })

  const Tools = ({ onModal = true }) => (
    <StatisticsTools
      onAccept={changeData}
      loading={loading}
      dateRange={statisticsTimes?.dateRange || []}
      dateRangeText={dateRangeText}
      otherComponents={
        <Checkbox
          label="Incluir oficina en tiempo medio total"
          checked={isOfficeIncluded}
          onChange={changeIsOfficeIncludedValue}
        />
      }
      exportation={
        onModal
          ? undefined
          : {
              type: 'STATISTICS_TIMES_SUMMARY',
              data: { values: statisticsTimes?.data?.totalRange?.times },
              options: {
                dateRange: statisticsTimes?.dateRange,
              },
              modalTitle: 'Exportar resumen de tiempos',
              defaultInputValue: 'RESUMEN DE TIEMPOS',
            }
      }
    />
  )

  const changeData = async selectedDateRange => {
    try {
      if (!selectedDateRange) return easyToast('error', 'Seleccione un rango de fechas')

      if (JSON.stringify(selectedDateRange) !== JSON.stringify(statisticsTimes.dateRange)) {
        setLoading(true)

        await statisticsHandler.changeTimesData(selectedDateRange[0], selectedDateRange[1])
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error con el rango de fechas seleccionado')
    } finally {
      setLoading(false)
    }
  }

  const changeIsOfficeIncludedValue = value => {
    try {
      setIsOfficeIncluded(value)
      statisticsHandler.isOfficeIncluded = value
    } catch (err) {
      console.error(err)
    }
  }
  useEffect(() => {
    const abortController = new AbortController()
    try {
      if (
        statisticsHandler &&
        (!statisticsTimes?.initialized ||
          (statisticsHandler?.dateRange?.length &&
            JSON.stringify(statisticsTimes.dateRange) !==
              JSON.stringify(statisticsHandler.dateRange)))
      ) {
        const initialize = async () => {
          setLoading(true)
          await statisticsHandler.initializeTimes()
          setLoading(false)
        }
        initialize()
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos. Seleccione una fecha')
      setLoading(false)
    }
    return () => abortController.abort()
  }, [statisticsHandler, statisticsTimes?.initialized, setLoading, statisticsTimes?.dateRange])

  console.log({ statisticsTimes })

  return (
    <>
      {loading && <ChildSpinner text="Cargando tiempos..." mintLogo={true} />}
      {!loading && (
        <>
          <>
            {<Tools onModal={false} />}
            <div className="statistics-times" data-aos="fade-up">
              <div className="statistics__main-info" data-aos="fade-up">
                {statisticsTimes?.data?.totalRange?.numberOfInspections != null && (
                  <CardNameValue
                    className="statistics__info-card"
                    name="INSPECCIONES TOTALES"
                    value={
                      <AnimatedCounter
                        number={statisticsTimes?.data?.totalRange?.numberOfInspections}
                      />
                    }
                  />
                )}
              </div>
              <div className="statistics__section" data-aos="fade-up" data-aos-delay="150">
                <div className="statistics__section-title">
                  Tiempos medios por fase de inspección
                </div>
                <div className="statistics__section-body">
                  {averageTimes && (
                    <>
                      <StatisticsTimesAverageCard
                        icon={<i className="flaticon-cash-register" />}
                        text="Oficina"
                        value={formatTimeToHoursMinutesSeconds(averageTimes.administrative)}
                      />
                      <StatisticsTimesAverageCard
                        icon={<i className="flaticon-brake" />}
                        text="Esperando inspección"
                        value={formatTimeToHoursMinutesSeconds(averageTimes.inspectionWaitTime)}
                        secondary={true}
                      />
                      <StatisticsTimesAverageCard
                        icon={<i className="flaticon-inspection" />}
                        text="Inspección"
                        value={formatTimeToHoursMinutesSeconds(averageTimes.inspection)}
                      />
                      <StatisticsTimesAverageCard
                        icon={<i className="flaticon-car-1" />}
                        text="Esperando supervisión"
                        value={formatTimeToHoursMinutesSeconds(averageTimes.supervisorWaitTime)}
                        secondary={true}
                      />
                      <StatisticsTimesAverageCard
                        icon={<i className="flaticon-checklist" />}
                        text="Supervisión"
                        value={formatTimeToHoursMinutesSeconds(averageTimes.supervisor)}
                      />
                      <StatisticsTimesAverageCard
                        icon={<i className="flaticon-clock" />}
                        text="Total"
                        value={formatTimeToHoursMinutesSeconds(
                          isOfficeIncluded
                            ? averageTimes.total
                            : averageTimes.total - averageTimes.administrative
                        )}
                      />
                    </>
                  )}
                  {!averageTimes && <div>Sin datos</div>}
                </div>
              </div>

              <div className="statistics__section" data-aos="fade-up" data-aos-delay="300">
                <div className="statistics__section-title">Otros tiempos medios</div>
                <div className="statistics__section-body">
                  <MyButtonsContainer margin="0 auto" styles={{ maxWidth: '100%' }}>
                    <MyButton
                      text="Por tipo de inspección"
                      onClick={() => setModalToShow('inspectionType')}
                    />
                    <MyButton text="Por categoría" onClick={() => setModalToShow('category')} />
                    <MyButton
                      text="Por clasificación"
                      onClick={() => setModalToShow('classification')}
                    />
                    <MyButton text="Por combustible" onClick={() => setModalToShow('fuel')} />
                    <MyButton text="Por usuario" onClick={() => setModalToShow('user')} />
                  </MyButtonsContainer>
                </div>
              </div>
            </div>
          </>
        </>
      )}
      {modalToShow === 'inspectionType' && (
        <TimesByInspectionType
          values={timesBy?.inspectionType}
          closeModal={() => setModalToShow('')}
          isOfficeIncluded={isOfficeIncluded}
          loading={loading}
          Tools={<Tools />}
          dateRange={statisticsTimes?.dateRange}
        />
      )}
      {modalToShow === 'category' && (
        <TimesByCategory
          values={timesBy?.category}
          closeModal={() => setModalToShow('')}
          isOfficeIncluded={isOfficeIncluded}
          loading={loading}
          Tools={<Tools />}
          dateRange={statisticsTimes?.dateRange}
        />
      )}
      {modalToShow === 'classification' && (
        <TimesByClassification
          values={timesBy?.classification}
          closeModal={() => setModalToShow('')}
          isOfficeIncluded={isOfficeIncluded}
          loading={loading}
          Tools={<Tools />}
          dateRange={statisticsTimes?.dateRange}
        />
      )}
      {modalToShow === 'fuel' && (
        <TimesByFuel
          values={{ ...(timesBy?.fuel1 || {}), ...(timesBy?.fuel2 || {}) }}
          closeModal={() => setModalToShow('')}
          isOfficeIncluded={isOfficeIncluded}
          loading={loading}
          Tools={<Tools />}
          dateRange={statisticsTimes?.dateRange}
        />
      )}

      {modalToShow === 'user' && (
        <TimesByUser
          values={{
            administrative: timesBy?.administrative || {},
            inspector: timesBy?.inspector || {},
            supervisor: timesBy?.supervisor || {},
          }}
          closeModal={() => setModalToShow('')}
          isOfficeIncluded={isOfficeIncluded}
          loading={loading}
          Tools={<Tools />}
          dateRange={statisticsTimes?.dateRange}
        />
      )}
    </>
  )
}
