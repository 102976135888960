//@ts-check
import React, { useState, useEffect, useRef } from 'react'
import Drawer from 'components/Others/Drawer/Drawer'
import './Statistics.scss'
import StatisticsHandler from './StatisticsHandler'
import StatisticsSummary from './StatisticsSummary/StatisticsSummary'
import StatisticsBilling from './StatisticsBilling/StatisticsBilling'
import StatisticsTimes from './StatisticsTimes/StatisticsTimes'
import StatisticsDefects from './StatisticsDefects/StatisticsDefects'
import StatisticsInspection from './StatisticsInspection/StatisticsInspection'
// import StatisticsInspection from './StatisticsInspection/StatisticsInspection'

export default function Statistics() {
  /**
   * @type {[import('./StatisticsHandler').default | null, function]}
   */
  const [statisticsHandler, setStatisticsHandler] = useState(null)
  const items = [
    {
      text: 'Resumen del mes',
      icon: <i className="flaticon-home" />,
      content: <StatisticsSummary statisticsHandler={statisticsHandler} />,
    },
    {
      text: 'Facturación',
      icon: <i className="flaticon-money" />,
      content: <StatisticsBilling statisticsHandler={statisticsHandler} />,
    },
    {
      text: 'Tiempos',
      icon: <i className="flaticon-chronograph-watch" />,
      content: <StatisticsTimes statisticsHandler={statisticsHandler} />,
    },
    {
      text: 'Inspección',
      icon: <i className="flaticon-inspection" />,
      content: <StatisticsInspection statisticsHandler={statisticsHandler} />,
    },
    {
      text: 'Defectos',
      icon: <i className="flaticon-edit" />,
      content: <StatisticsDefects statisticsHandler={statisticsHandler} />,
    },
  ]
  const controllerRef = useRef(null)

  useEffect(() => {
    controllerRef.current = new AbortController()
    return () => controllerRef.current.abort()
  }, [])

  useEffect(() => {
    try {
      const abortController = new AbortController()
      const statistics = new StatisticsHandler({
        abortServiceCall: () => {
          controllerRef.current.abort()
          controllerRef.current = new AbortController()
        },
      })
      setStatisticsHandler(statistics)
      return () => abortController.abort()
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <div className="statistics" id="drawer-container">
      <Drawer items={items} />
    </div>
  )
}
