//@ts-check
import React, { useState } from 'react'

// MY IMPORTS
import Modal from 'components/Others/Modal/Modal'
import './ExportationModal.scss'
import ChildSpinner from '../Spinner/ChildSpinner'
import {
  exportToCsv as exportToCsvService,
  exportToPdf as exportToPdfService,
} from 'services/Share'
import easyToast from '../EasyToast/easyToast'
import MyButtonsContainer from '../Buttons/MyButtonsContainer'
import MyButton from '../Buttons/MyButton/MyButton'
import useService from 'hooks/useService'

/**
 * @typedef {import('types/ExportationModal').Exportation} Exportation
 */

/**
 *
 * @param {Exportation & {show: boolean, closeModal: function}} param0
 * @returns
 */
export default function ExportationModal({
  show,
  closeModal,
  data,
  defaultInputValue = '',
  type,
  options,
  pdf = true,
  csv = true,
  modalTitle = 'EXPORTAR SELECCIÓN',
}) {
  const [inputValue, setInputValue] = useState(defaultInputValue)

  const { fetch: exportToPdf, loading: loading1 } = useService({
    service: exportToPdfService,
  })
  const { fetch: exportToCsv, loading: loading2 } = useService({
    service: exportToCsvService,
  })

  const loading = loading1 || loading2

  const generatePdf = async title => {
    try {
      const res = await exportToPdf({ type, title, data, options })
      if (!res) throw new Error('Error exportating to pdf')
      let file = new Blob([res], { type: 'application/pdf' })
      let fileURL = URL.createObjectURL(file)
      window.open(fileURL)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error generando el PDF')
    } finally {
      closeModal()
    }
  }

  const generateCsv = async title => {
    try {
      const res = await exportToCsv({ type, title, data, options })
      if (!res) throw new Error('Error exportating to csv')
      let a = document.createElement('a')
      document.body.appendChild(a)
      a.style = 'display: none'
      a.setAttribute('href', 'data:text/csv; charset=utf-8,' + encodeURIComponent('\uFEFF' + res))
      a.download = title.trim().split(/(\s+)/).join('_') + '.csv'
      a.click()
      easyToast('dark', 'La descarga del archivo CSV ha comenzado')
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error generando el CSV')
    } finally {
      closeModal()
    }
  }

  return (
    <Modal
      open={show}
      onClose={() => closeModal()}
      title={loading ? null : modalTitle}
      hasAcceptButton={false}
      buttons={Boolean(!loading)}
      closeAfterAccept={false}
      content={
        <>
          {loading && <ChildSpinner visible={true} style={{ padding: '3rem' }} />}
          {!loading && (
            <div>
              <div className="exportation-table-modal__input form-input">
                <label>Título documento</label>
                <input
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value?.toUpperCase() || '')}
                  placeholder={defaultInputValue}
                />
              </div>

              <MyButtonsContainer>
                {pdf && (
                  <MyButton
                    text="PDF"
                    onClick={() => generatePdf(inputValue || defaultInputValue)}
                  />
                )}
                {csv && (
                  <MyButton
                    text="CSV"
                    onClick={() => generateCsv(inputValue || defaultInputValue)}
                  />
                )}
              </MyButtonsContainer>
            </div>
          )}
        </>
      }
    />
  )
}
