import React, { useRef, useEffect, useState } from 'react'
import './CashRegistersConfiguratorModal.scss'
import { cashRegister as formSchema } from 'formSchemas/CashRegister'
import Input from 'components/Others/Input/Input'
import { Formik, ErrorMessage } from 'formik'
import easyToast from 'components/Others/EasyToast/easyToast'
import isValidFunction from 'myMethods/isValidFunction'
import { getUsers as getUsersService } from 'services/Users'
import Switch from 'components/Others/Switch/Switch'
import formatAndTrimText from 'myMethods/formatAndTrimText'
import {
  postCashRegister as postCashRegisterService,
  putCashRegister as putCashRegisterService,
} from 'services/CashRegister'
import Modal from 'components/Others/Modal/Modal'
import useService from 'hooks/useService'
import parseFormValues from 'myMethods/parseFormValues'
import Select from 'components/Others/Select/Select'

const initialValuesClear = {
  id: null,
  name: '',
  user: null,
  activeUntil: null,
  active: true,
}

export default function CashRegistersConfiguratorModal({
  data,
  isNew,
  takenCashRegisterUserIds,
  closeModal,
  refetch,
}) {
  const formRef = useRef()
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)

  const { fetch: postCashRegister } = useService({
    service: postCashRegisterService,
    setOutLoading: setLoading,
  })
  const { fetch: putCashRegister } = useService({
    service: putCashRegisterService,
    setOutLoading: setLoading,
  })

  const { fetch: getUsers } = useService({
    service: getUsersService,
    setOutLoading: setLoading,
  })

  const handleInitialValues = () => {
    try {
      if (!isNew) {
        if (!data || !Object.keys(data).length) {
          easyToast('error', 'Ha ocurrido un error cargando el usuario')
          isValidFunction(closeModal) && closeModal()
          return
        }
        console.log(
          parseFormValues(
            {
              id: data?.id,
              name: data?.name,
              userId: data?.user?.id,
              activeUntil: data?.activeUntil,
              active: data?.active,
            },
            true
          )
        )
        return parseFormValues(
          {
            id: data?.id,
            name: data?.name,
            userId: data?.user?.id,
            activeUntil: data?.activeUntil,
            active: data?.active,
          },
          true
        )
      } else return initialValuesClear
    } catch (err) {
      return initialValuesClear
    }
  }

  const onSubmit = async values => {
    try {
      const newValues = parseFormValues({
        name: formatAndTrimText(values.name),
        userId: values.userId,
        activeUntil: values.activeUntil || null,
        active: values.active,
      })

      if (isNew) {
        await postCashRegister(newValues)
      } else {
        await putCashRegister({ id: data.id, data: newValues })
      }
      easyToast(
        'dark',
        isNew
          ? `${newValues.name} se ha creado correctamente`
          : `${newValues.name} modificada correctamente`
      )
      refetch()
      closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', `Ha ocurrido un error ${isNew ? 'añadiendo' : 'modificando'} la caja`)
    }
  }

  useEffect(() => {
    getUsers()
      .then(res => {
        if (!res || !res.length) throw new Error('No users responsed')
        let formattedUsers = []
        res.forEach(user => {
          if (
            user.id &&
            user.username &&
            user.fullname &&
            (!takenCashRegisterUserIds.includes(user.id) || user.id === data?.user?.id)
          )
            formattedUsers.push({
              value: user.id,
              label: `${user.username} - ${user.fullname}`,
            })
          if (formattedUsers.length) {
            setUsers(formattedUsers)
          }
        })
      })
      .catch(err => console.error(err))
  }, [data?.user?.id, getUsers, takenCashRegisterUserIds])

  console.log({ data, formRef, users })

  return (
    <Modal
      open={true}
      closeAfterAccept={false}
      onAccept={formRef?.current?.submitForm}
      onClose={closeModal}
      title={isNew ? 'Nueva caja' : `Editar caja ${data?.name || ''}`}
      loading={loading}
      closeOnOverlay={false}
      content={
        <Formik
          enableReinitialize
          initialValues={handleInitialValues()}
          validationSchema={formSchema}
          innerRef={formRef}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
            <div className="cash-register-configurator-modal">
              {console.log({ values })}
              <div className="cash-register-configurator-modal__active">
                <Switch
                  label="Caja activa"
                  value={values?.active}
                  onChange={value => setFieldValue('active', value)}
                />
              </div>
              <Input label="Nombre de caja" name="name" />
              <Input label="Activo hasta" name="activeUntil" type="date" />

              <Select
                label="Usuario"
                ErrorComponent={
                  <ErrorMessage className="form-error" name="category" component="small" />
                }
                name="userId"
                options={users || []}
                value={users.find(elem => elem.value === values.userId) || ''}
                onChange={opt => {
                  setFieldValue('userId', opt ? opt.value : '')
                }}
                onBlur={() => setFieldTouched('userId', true)}
                error={errors.userId}
                touched={touched.userId}
              />
            </div>
          )}
        </Formik>
      }
    />
  )
}
