function useSessionStorage() {
  const setValue = (key, value) => {
    if (!key || !value) return
    window.sessionStorage.setItem(key, value)
  }

  const getValue = key => {
    if (!key) return
    return window.sessionStorage.getItem(key)
  }

  const removeValue = key => {
    if (!key) return
    window.sessionStorage.removeItem(key)
  }

  const clear = () => {
    window.sessionStorage.clear()
  }

  const initInspection = (id, line, license) => {
    setValue('id', id)
    setValue('license', license)
    setValue('prompt', false)
    setValue('line', line)
    setValue('new_inspection', true)
  }

  const enterInspection = (id, line, license) => {
    setValue('id', id)
    setValue('line', line)
    setValue('license', license)
    setValue('prompt', false)
  }

  const initSupervision = (id, license) => {
    setValue('id', id)
    setValue('license', license)
    setValue('prompt', false)
  }

  const enterSupervision = (id, line, license) => {
    setValue('id', id)
    setValue('line', line)
    setValue('license', license)
    setValue('prompt', false)
  }

  const exitInspectionOrSupervision = () => {
    removeValue('id')
    removeValue('license')
    removeValue('line')
    removeValue('refresh')
    removeValue('prompt')
  }

  return {
    getValueSS: getValue,
    setValueSS: setValue,
    removeValueSS: removeValue,
    clearSS: clear,
    initInspectionSS: initInspection,
    enterInspectionSS: enterInspection,
    initSupervisionSS: initSupervision,
    enterSupervisionSS: enterSupervision,
    exitInspectionOrSupervisionSS: exitInspectionOrSupervision,
  }
}

export default useSessionStorage
