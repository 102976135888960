import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'
import FillReduxInspection from 'components/FillRedux/FillReduxInspection'
import Traceability from 'components/Inspection/Check/Traceability/Traceability'
import Status from 'components/Inspection/Check/Status/Status'
import Title from 'components/Others/Title/Title'
import Spinner from 'components/Others/Spinner/Spinner'
import Cards from 'components/Inspection/Check/Cards/Cards'
import InspectionTime from 'components/Inspection/Check/InspectionTime/InspectionTime'
import { finishInspection as finishInspectionService } from 'services/Inspection'
import easyToast from 'components/Others/EasyToast/easyToast'
import Id from 'Share/Id'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import useService from 'hooks/useService'
import FaceID from 'components/Others/FaceID/FaceID'
import './Check.scss'
import InspectionStatistics from 'pages/InspectionStatistics/InspectionStatitistics'

export default function Check() {
  const redux = useSelector(store => store)
  const params = useParams()
  const history = useHistory()

  const { fetch: finishInspection, loading } = useService({
    service: finishInspectionService,
  })

  const EndInspectionButton = () =>
    redux?.config?.itv?.faceIdToEndInspection ? (
      <MyButtonsContainer styles={{ width: '95%', margin: '2rem auto 0' }}>
        <FaceID className="check__face-id" userId="Manu" onSuccess={handleSubmit}>
          <MyButton
            transparent={false}
            // text="ENVIAR A SUPERVISIÓN (Identificación facial)"
            text="Enviar a supervisión"
          />
        </FaceID>
      </MyButtonsContainer>
    ) : (
      <MyButtonsContainer styles={{ width: '95%', margin: '2rem auto 0' }}>
        <MyButton transparent={false} text="ENVIAR A SUPERVISIÓN" onClick={handleSubmit} />
      </MyButtonsContainer>
    )

  const handleSubmit = async () => {
    try {
      await finishInspection({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        times: redux.inspection.times,
      })
      window.sessionStorage.setItem('justInspected', true)
      window.sessionStorage.removeItem('id')
      window.location.replace('/new_inspection')
    } catch (err) {
      easyToast(
        'error',
        'Ha ocurrido un error terminando la inspección. Inténtelo de nuevo o recargue la página'
      )
    }
  }

  return (
    <>
      <Spinner visible={loading} />
      <FillReduxInspection />
      <>
        <Title text={`FINALIZAR INSPECCIÓN DE ${params.license}`} />
        <MyButtonsContainer margin="0 auto 1.5rem auto">
          <MyButton
            icon={<ArrowBack />}
            transparent={false}
            text="VOLVER A INSPECCIÓN"
            onClick={() => history.replace(`/new_inspection/${params.license}`)}
          />
        </MyButtonsContainer>
        {redux?.inspection?.grade && (
          <div
            data-aos="fade-in-down"
            className={
              ['DESFAVORABLE', 'NEGATIVA'].includes(redux.inspection.grade)
                ? 'check__inspection-grade--negative'
                : 'check__inspection-grade'
            }
          >
            RESULTADO DE LA INSPECCIÓN: <strong>{redux?.inspection?.grade}</strong>
          </div>
        )}
        <Status errors={redux?.inspection?.errors} />
        <InspectionTime timeToShow={redux?.inspection?.times?.inspection_start} />
        {/* <InspectionStatistics /> */}
        <Cards reduxInspection={redux.inspection} />
        <Traceability traceability={redux?.inspection?.traceability?.byChapters} />

        <EndInspectionButton />
        <div style={{ height: '5rem' }} />
      </>
    </>
  )
}
