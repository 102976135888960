import React, { useState } from 'react'
import Tpv from 'Share/Tpv'
import ManualTpvModal from 'components/Office/ManualTpvModal/ManualTpvModal'
import Modal from 'components/Others/Modal/Modal'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import formatEuro from 'myMethods/formatEuro'

import { putRefundMethodToCreditCard as putRefundMethodToCreditCardService } from 'services/Billing'
import easyToast from 'components/Others/EasyToast/easyToast'
import useService from 'hooks/useService'

export default function RefundMoneyModal({ invoiceId, amount, finish, paymentDetails }) {
  const [loading, setLoading] = useState(false)
  const [manualTpvModal, setManualTpvModal] = useState({ show: false, error: false, title: '' })

  const { fetch: putRefundMethodToCreditCard } = useService({
    service: putRefundMethodToCreditCardService,
    setOutLoading: setLoading,
  })

  const handleCashClick = async () => {
    try {
      await putRefundMethodToCreditCard({
        invoiceId,
        paymentDetails: { method: 'EFECTIVO', details: {} },
      })
      finish({ error: false, method: 'EFECTIVO' })
    } catch (err) {
      console.error(err)
      finish({ error: true, method: null })
    }
  }

  const handleCardClick = async () => {
    try {
      let done = false
      if (
        paymentDetails?.tpv &&
        Object.prototype.hasOwnProperty.call(paymentDetails.tpv, 'manual')
      ) {
        if (!paymentDetails.tpv.manual) {
          setLoading(true)
          const myTpv = await Tpv.create()
          console.log({ myTpv })
          if (!myTpv || !myTpv.terminal) {
            setLoading(false)
            return setManualTpvModal({ show: true, error: false, title: '' })
          }
          const tpvRes = await myTpv.refund({
            amount,
            ref: null,
            numPedido: paymentDetails.tpv.transactionData?.pedido,
            rts: paymentDetails.tpv.transactionData?.identificadorRTS,
          })
          const tpv = {
            terminal: myTpv.terminal,
            name: myTpv.name,
            manual: false,
            transactionData: tpvRes?.data,
          }
          if (tpvRes?.ok && !tpvRes?.error && tpvRes?.data && !tpvRes?.duplicate) {
            await putRefundMethodToCreditCard({
              invoiceId,
              paymentDetails: { method: 'TARJETA', details: { tpv } },
            })
            easyToast('dark', 'Devolución mediante tarjeta realizada correctamente')
            done = true
          } else if (tpvRes?.ok && !tpvRes?.error && tpvRes?.data && tpvRes?.duplicate) {
            easyToast('error', 'Esta factura ya ha sido devuelta')
            await putRefundMethodToCreditCard({
              invoiceId,
              paymentDetails: { method: 'TARJETA', details: { tpv } },
            })
            done = true
            easyToast('dark', 'Devolución mediante tarjeta realizada correctamente')
          } else {
            setLoading(false)
            setManualTpvModal({ show: true, error: true })
            return
          }
          if (done) {
            finish({ error: false, method: 'TARJETA' })
          }
        } else {
          setManualTpvModal({
            show: true,
            error: false,
            title: `El cobro se realizó con un TPV manual${
              paymentDetails.tpv.name ? ' (' + paymentDetails.tpv.name + ')' : ''
            }. Cobre manualmente y seleccione el TPV que ha usado`,
          })
        }
      } else {
        easyToast('error', 'Esta factura no fue cobrada con tarjeta')
      }
    } catch (err) {
      console.error(err)
      finish({ error: true, method: null })
    }
  }

  const saveManualTpvData = async name => {
    try {
      setManualTpvModal({ show: false, error: false, title: '' })
      await putRefundMethodToCreditCard({
        invoiceId,
        paymentDetails: { method: 'TARJETA', details: { tpv: { name, manual: true } } },
      })
      easyToast('dark', 'Devolución mediante tarjeta realizada correctamente')
      await finish({ error: false, method: 'TARJETA' })
    } catch (err) {
      console.error(err)
      finish({ error: true, method: null })
    }
  }

  return (
    <Modal
      open={true}
      buttons={false}
      closeOnOverlay={false}
      title="Devolución de dinero"
      message={`Importe: ${formatEuro(amount)}`}
      message2={loading ? 'Realizando devolución...' : 'Seleccione un método de devolución'}
      content={
        <>
          {loading && <ChildSpinner />}
          {!loading && (
            <div>
              <div className="payment-methods__cards-container" style={{ marginBottom: '2rem' }}>
                <div className="payment-methods__card" onClick={handleCashClick}>
                  <i className="flaticon-money" />
                  <span>EFECTIVO</span>
                </div>
                <div className="payment-methods__card" onClick={handleCardClick}>
                  <i className="flaticon-credit-card" />
                  <span>TARJETA</span>
                </div>
              </div>
            </div>
          )}
          {manualTpvModal.show && (
            <ManualTpvModal
              handleClick={saveManualTpvData}
              title={manualTpvModal.title}
              error={manualTpvModal.error}
              closeModal={() => setManualTpvModal({ show: false, error: false, title: '' })}
            />
          )}
        </>
      }
    />
  )
}
