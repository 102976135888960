import MuiTable from 'components/Others/MuiTable/MuiTable'
import React, { useCallback, useState } from 'react'
import './StatisticsInspectionByFilter.scss'
import formatNumberToString from 'myMethods/formatNumberToString'
import Modal from 'components/Others/Modal/Modal'
import ListOfStatisticsInspectionTests from '../ListOfStatisticsInspectionTests/ListOfStatisticsInspectionTests'
import ErrorView from 'components/Others/ErrorView/ErrorView'

/**
 *
 * @param {{inspectionsByFilter: object, filterKey: string, filterLabel: string, dataRange: any, totalInspections: number}} param0
 * @returns
 */
export default function StatisticsInspectionByFilter({
  inspectionsByFilter,
  filterKey,
  filterLabel,
  dateRange,
  totalInspections,
}) {
  const [modalTests, setModalTests] = useState({
    show: false,
    data: null,
    filterKey: '',
  })
  const formatValues = useCallback(values => {
    return Object.entries(values).map(([key, data]) => {
      const rejectionFormatted = formatNumberToString(data.rejectionRate)
      return {
        filterKey: key,
        rejectionRate_formatted: rejectionFormatted === '-' ? '-' : rejectionFormatted + '%',
        ...data,
      }
    })
  }, [])

  console.log({ inspectionsByFilter })
  try {
    return (
      <>
        <div>
          <MuiTable
            values={formatValues(inspectionsByFilter)}
            columns={[
              { title: filterLabel, field: 'filterKey' },
              { title: 'Total inspecciones', field: 'total' },
              { title: 'Porcentaje de rechazo', field: 'rejectionRate_formatted' },
              { title: 'Total favorables', field: 'favorables' },
              { title: 'Total desfavorables', field: 'desfavorables' },
              { title: 'Total negativas', field: 'negativas' },
              { title: 'Muy graves', field: 'verySerious' },
            ]}
            options={{
              grouping: false,
              filtering: false,
              search: true,
            }}
            showToolbar={true}
            singleSelection={true}
            singleSelectionLabel="Mostrar tests"
            onSingleSelectionClick={rowData =>
              setModalTests({
                show: true,
                data: inspectionsByFilter?.[rowData.filterKey]?.tests,
                filterKey: rowData.filterKey,
              })
            }
            exportation={{
              type: `STATISTICS_INSPECTIONS_${filterKey}`,
              data: { totalInspections, values: formatValues(inspectionsByFilter) },
              options: {
                dateRange,
              },
              modalTitle: `Exportar defectos por ${filterLabel}`,
              defaultInputValue: `DEFECTOS POR ${filterLabel.toUpperCase()}`,
            }}
          />
        </div>
        <Modal
          open={modalTests.show}
          onClose={() => setModalTests({ show: false, data: null, filterKey: '' })}
          title={`TESTS POR ${filterLabel.toUpperCase()} ${modalTests.filterKey}`}
          hasAcceptButton={false}
          content={<ListOfStatisticsInspectionTests tests={modalTests?.data} />}
        />
      </>
    )
  } catch (err) {
    console.error(err)
    return <ErrorView />
  }
}
