import React, { useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useLongPress } from 'use-long-press'

// MY IMPORTS
import 'components/Inspection/Defectology/Defect/Defect.scss'
import Modal from 'components/Others/Modal/Modal'
import easyToast from 'components/Others/EasyToast/easyToast'
import Id from 'Share/Id'

// SERVICES
import {
  putInspectionDefectApply as putInspectionDefectApplyService,
  putInspectionDefectsDone as putInspectionDefectsDoneService,
} from 'services/Inspection'
import ModalDefect from '../Modal/ModalDefect/ModalDefect'
import isValidFunction from 'myMethods/isValidFunction'
import useService from 'hooks/useService'

/**
 *
 * @param {{defect: import('types/ManualManager').DefectToShow, noUserInteraction?: boolean, forcedOnClick?: function}} param0
 * @returns
 */
export default function Defect({ defect, noUserInteraction = false, forcedOnClick }) {
  const [showModal, setShowModal] = useState(false)
  const [visualDefectSelected, setVisualDefectSelected] = useState(null)
  const [isLongPressed, setIsLongPressed] = useState(false)
  const [lastClick, setLastClick] = useState(null)
  const params = useParams()
  const history = useHistory()
  const redux = useSelector(store => store)
  const reduxInspection = redux.inspection
  const inputDoneRef = useRef()
  const defectInfo = {
    visualDefect: reduxInspection?.visualDefects?.find(elem => elem.id === defect.id),
    defectDone: reduxInspection?.defectsDone?.find(elem => elem.id === defect.id),
  }
  const score = defect.score
  console.log({ defect })
  const noApplyForUser = !defect.apply && defect.done

  let component = null

  const { fetch: postInspectionDefectApply } = useService({
    service: putInspectionDefectApplyService,
  })
  const { fetch: putInspectionDefectsDone } = useService({
    service: putInspectionDefectsDoneService,
  })
  const hasAuth = () => {
    console.log({ defectInfo, user: redux.user.data.username })
    return !defectInfo?.defectDone || defectInfo?.defectDone.user === redux.user.data?.username
      ? true
      : false
  }
  const onLongPress = () => {
    if (defect.maxChildrenScore)
      return easyToast(
        'error',
        'No puedes marcar como no aplica una unidad que tiene defectos marcados'
      )
    if (hasAuth()) {
      setIsLongPressed(true)
      setShowModal(true)
    } else {
      easyToast('error', 'No puedes marcar como aplica un defecto visto por otro usuario')
    }
  }

  const longPress = useLongPress(onLongPress, { cancelOnMovement: true, threshold: 1000 })

  const click = e => {
    // Gestiona el tiempo entre clicks (ya que desconozco el motivo de por qué se clicka 3 veces)
    if (!lastClick || new Date().getTime() - lastClick > 500) {
      // Si se fuerza otro comportamiento con el defecto que el por defecto
      if (forcedOnClick && isValidFunction(forcedOnClick)) return forcedOnClick(defect)
      // Si se ha clickado en el título, se entra en el link. Si no, se ha clickado en el tick
      e.target.nodeName === 'DIV' ? handleClick() : handleDefectDoneOnClick()
    }
    setLastClick(new Date().getTime())
  }

  const toggleDefectDone = async () => {
    try {
      await putInspectionDefectsDone({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        defectsIds: [defect.id],
        doneValue: !defect.done,
      })
    } catch (err) {
      console.error(err)
    }
  }

  const toggleApply = async apply => {
    try {
      console.log({
        request: {
          id: Id.decrypt(window.sessionStorage.getItem('id')),
          defectId: defect.id,
          apply,
        },
      })
      await postInspectionDefectApply({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        defectId: defect.id,
        apply,
      })
    } catch (err) {
      console.error(err)
    }
  }

  const handleClick = () => {
    if (hasAuth()) {
      if (!isLongPressed && noApplyForUser) {
        setShowModal(true)
      } else if (isLongPressed) {
        setShowModal(true)
      } else {
        history.replace(`/new_inspection/${params.license}/defects/${defect.id}`)
      }
    } else {
      easyToast(
        'error',
        `Este defecto ya ha sido inspeccionado${
          defectInfo?.defectDone?.user ? ' por ' + defectInfo.defectDone.user : ''
        }`
      )
    }
  }

  const handleClickDefect = () => {
    setVisualDefectSelected(defect)
  }

  const renderPossibleScoresColors = defectsWithChildren => {
    const key = defectsWithChildren ? 'childrenPossibleScores' : 'possibleScores'
    return (
      <div className="defect__scores">
        {defect?.[key]?.length && (
          <>
            {defect[key].reverse().map((score, i) => (
              <div key={i} className={`defect__scores-score defect__scores-score--${score}`}></div>
            ))}
          </>
        )}
      </div>
    )
  }

  const handleDefectDoneOnClick = () => {
    if (hasAuth()) {
      if (noApplyForUser) return setShowModal(true)
      if (defect.maxChildrenScore && defect.apply)
        return easyToast('error', 'No puedes desmarcar el defecto si hay defectos hijos marcados')
      toggleDefectDone()
    } else {
      easyToast(
        'error',
        `No puedes desmarcar un defecto inspeccionado por otro usuario${
          defectInfo?.defectDone?.user ? ' (' + defectInfo.defectDone.user + ')' : ''
        }`
      )
    }
  }
  if (
    defect.possibleScores?.length &&
    (!defect.numberOfChilds || params.manualChapter === defect.id)
  ) {
    //Si es un defecto hijo normal
    component = (
      <div
        onClick={handleClickDefect}
        className={`defect${
          noApplyForUser ? ' defect--no-apply' : ''
        } contrast-on-hover list-content ${score ? 'defect--' + score : ''}`}
      >
        {renderPossibleScoresColors()}
        <div className={`defect__id`}>{defect.id}</div>
        <div className={`defect__name defect__name`}>{defect.name}</div>
        {!noUserInteraction && (
          <div className="defect__icon">
            <i className="flaticon-edit-2" />
          </div>
        )}
      </div>
    )
  } else {
    if (defect.depth === 2) {
      //Si es un padre
      component = (
        <div
          className={`${
            noApplyForUser ? 'defect--no-apply' : ''
          } defect__parent-container contrast-on-hover`}
          {...longPress()}
          onClick={click}
        >
          <div
            className={`${
              noUserInteraction ? 'defect__parent--no-user-interaction' : 'defect__parent'
            } list-content ${defect.maxChildrenScore ? 'defect--' + defect.maxChildrenScore : ''}`}
          >
            <div className="defect__id">{defect.id}</div>
            <div className="defect__name">{defect.name}</div>
          </div>
          {!noUserInteraction && (
            <aside className={`defect__done ${!hasAuth() ? 'defect__done--red' : ''}`}>
              <label>
                <input type="checkbox" ref={inputDoneRef} name={defect.id} checked={defect?.done} />
                <span>
                  <b>
                    <i className="flaticon-cheque" />
                  </b>
                </span>
              </label>
            </aside>
          )}
        </div>
      )
    } else {
      //Si es un defecto con hijos dentro
      component = (
        <div
          onClick={() => {
            if (forcedOnClick && isValidFunction(forcedOnClick)) return forcedOnClick(defect)
            history.push(`/new_inspection/${params.license}/defects/${defect.id}`)
          }}
          className={`defect__title-settings contrast-on-hover list-content ${
            defect.maxChildrenScore ? 'defect--' + defect.maxChildrenScore : ''
          }`}
        >
          {renderPossibleScoresColors(true)}
          <div className="defect__id">{defect.id}</div>
          <div className="defect__name">{defect.name}</div>
          {defect.numberOfChilds > 0 && (
            <div className="defect__counter">{`${defect.numberOfChilds}+`}</div>
          )}
        </div>
      )
    }
  }

  return (
    <>
      {component}
      {isLongPressed && (
        <Modal
          open={showModal}
          title={`¿Marcar defecto como${noApplyForUser ? '' : ' no'} aplica?`}
          message={`${defect.id} - ${defect.name}`}
          closable={true}
          onClose={() => {
            setShowModal(false)
            setIsLongPressed(false)
          }}
          buttons={true}
          onAccept={() => {
            setIsLongPressed(false)
            toggleApply(!defect.apply)
          }}
        />
      )}
      {!isLongPressed && (
        <Modal
          open={showModal}
          title="Este defecto no aplica, no puede acceder a los subdefectos"
          message="¿Marcar como aplica?"
          closable={true}
          onClose={() => setShowModal(false)}
          buttons={true}
          onAccept={() => {
            toggleApply(true)
          }}
        />
      )}
      {visualDefectSelected && (
        <ModalDefect
          defectId={visualDefectSelected?.id}
          closeModal={() => setVisualDefectSelected(null)}
        />
      )}
    </>
  )
}
