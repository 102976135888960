import React, { useEffect, useRef } from 'react'
import { animate } from 'framer-motion'

export default function AnimatedCounter({ number, fixedTo = 2, duration = 1, unit = '' }) {
  const nodeRef = useRef()
  useEffect(() => {
    try {
      const node = nodeRef.current
      const format = number =>
        isInteger ? Math.round(number) : number.toFixed(fixedTo).replace('.', ',')

      const controls = animate(0, number, {
        duration,
        onUpdate(value) {
          let newValue = format(value)
          node.textContent = unit ? newValue + ' ' + unit : newValue
        },
      })
      return () => controls.stop()
    } catch (error) {
      console.error(error)
    }
    //eslint-disable-next-line
  }, [number, duration])

  if (number == null) return <div>-</div>
  const isInteger = Number.isInteger(number)
  return <div ref={nodeRef} />
}
