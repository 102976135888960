import Axios from 'axios'

const SERVER_URL = process.env.REACT_APP_MANUAL_HANDLER_URL

export function getBookmarkPdf({ section, bookmarkId }, options = {}) {
  return Axios.get(`${SERVER_URL}/bookmark/${section}/${bookmarkId}`, {
    responseType: 'blob',
    signal: options.signal,
  })
}
