import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export function getInvoicesExport(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}invoices/export`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function postInvoicesExport(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}billing/export`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}
