//@ts-check
import React from 'react'
import './TestConfigWrapper.scss'

/**
 *
 * @param {{testName: string, message?: string, message2?: string, disabled?: boolean, children: any}} param0
 * @returns
 */
export default function TestConfigWrapper({
  testName,
  message,
  message2,
  disabled = false,
  children,
}) {
  return (
    <div className={`test-config-wrapper ${disabled ? 'test-config-wrapper--disabled' : ''}`}>
      {disabled && (
        <div className="test-config-wrapper__disabled">
          No puede editar los valores porque la prueba ha sido realizada
        </div>
      )}
      <div className="test-config-wrapper__test-name">{testName}</div>
      {message && <div className="test-config-wrapper__message">{message}</div>}
      {message2 && <div className="test-config-wrapper__message-2">{message2}</div>}
      <div className="test-config-wrapper__body">{children}</div>
    </div>
  )
}
