//@ts-check
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './ShowTestsResults.scss'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import easyToast from 'components/Others/EasyToast/easyToast'
import moment from 'moment'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Modal from 'components/Others/Modal/Modal'
import TestsResultsErrorModal from './TestsResultsErrorModal/TestsResultsErrorModal'
import { getTestSourceFile as getTestSourceFileService } from 'services/Files'
import downloadFile from 'myMethods/downloadFile'
import HardwareMapperResponse from 'Share/HardwareResponseMapper'
import TestsValues from 'Share/TestsValues'
import MachineInfo from '../../MachineInfo/MachineInfo'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import isValidFunction from 'myMethods/isValidFunction'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'
import Slider from 'components/Others/Slider/Slider'
import useService from 'hooks/useService'
import TextWithHoverIfEllipsis from 'components/Others/TextWithHoverIfEllipsis/TextWithHoverIfEllipsis'
import useInspectionImages from 'hooks/useInspectionImages'
import Gallery from 'components/Others/Gallery/Gallery'
import ToggleButtons from 'components/Others/Buttons/ToggleButtons/ToggleButtons'

/**
 * Componente para mostrar los resultados de las pruebas.
 * @param {Object} props - Propiedades del componente.
 * @param {boolean} props.showAll - Indica si se deben mostrar todas las pruebas.
 * @param {Array<string>} props.testNames - Nombres de las pruebas a mostrar.
 * @param {boolean} [props.loading=false] - Indica si se están cargando los datos.
 * @param {boolean} [props.letCancel=false] - Indica si se permite cancelar las pruebas.
 * @param {Function} props.cancelTest - Función para cancelar una prueba.
 * @param {React.ReactNode} props.customButton - Botón personalizado.
 */
export default function ShowTestsResults({
  showAll,
  testNames,
  loading = false,
  letCancel = true,
  cancelTest,
  customButton,
}) {
  const reduxInspection = useSelector(store => store.inspection)
  const testsValues = reduxInspection?.testsValues
  const [errorsModal, setErrorsModal] = useState({ show: false, testName: '', errors: [] })
  const [machineInfoModal, setMachineInfoModal] = useState({
    show: false,
    testName: null,
    machine: null,
  })
  const [index, setIndex] = useState(0)
  const showSourceFile = useSelector(store => store.user.data.role) === 'SUPERVISOR'
  const [tests, setTests] = useState([])

  const { fetch: getTestSourceFile } = useService({
    service: getTestSourceFileService,
  })

  const selectedTest = tests[index]

  const downloadSourceFile = async filename => {
    try {
      const blob = await getTestSourceFile(filename)
      if (!blob || !(blob instanceof Blob))
        throw new Error('No file or file is not instance of Blob')
      downloadFile(blob, filename, 'blob')
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error descargando archivo')
      console.error(err)
    }
  }

  const SourceFileDownloadBtn = ({ filename }) => (
    <div className="show-tests-results__download">
      <HoverTooltip title="Descargar fichero fuente">
        <i className="flaticon-file" onClick={() => downloadSourceFile(filename)} />
      </HoverTooltip>
    </div>
  )

  const handleTestCancel = () => {
    try {
      isValidFunction(cancelTest) && cancelTest(selectedTest.testName)
    } catch (err) {
      console.error(err)
      easyToast('error', 'No se puede anular esta prueba')
    }
  }

  const MachineInfoBtn = ({ testName, machine }) => (
    <div className="show-tests-results__machine-info">
      <HoverTooltip title="Información de equipo">
        <i
          className="flaticon-setting-lines"
          onClick={() =>
            setMachineInfoModal({
              show: true,
              testName,
              machine,
            })
          }
        />
      </HoverTooltip>
    </div>
  )

  useEffect(() => {
    if (testsValues) {
      const testsValuesHandler = new TestsValues(testsValues)
      let tests = []
      if (showAll) tests = testsValuesHandler.testsValues
      else if (testNames?.length) tests = testsValuesHandler.getTestsDataByTestNames(testNames)
      if (tests?.length) tests = HardwareMapperResponse.sortAllTestByDivision(tests)
      tests?.length && setTests(tests)
    }
  }, [showAll, testNames, testsValues])

  const Values = ({ insideIndex }) => {
    const { images, uploadImage } = useInspectionImages({
      type: 'testMachine',
      testName: selectedTest.testName,
    })

    try {
      const selectedData = selectedTest.data[insideIndex]
      return (
        <div id="show-tests-results__test-info" className="show-tests-results__test-info">
          <MyButtonsContainer margin="1.5rem auto 0">
            {selectedData.errors && selectedData.errors.length > 0 && (
              <MyButton
                warningColor={true}
                transparent={true}
                text={`Ver ${selectedData.errors.length > 1 ? 'errores' : 'error'} (${
                  selectedData.errors.length
                })`}
                icon={<i className="flaticon-warning" />}
                onClick={() => {
                  setErrorsModal({
                    show: true,
                    errors: selectedData.errors,
                    testName: selectedTest.testName,
                  })
                }}
              />
            )}
          </MyButtonsContainer>

          {!selectedData.cancellation && (
            <>
              {letCancel && (
                <MyButtonsContainer margin="1.3rem auto 1.5rem">
                  <MyButton
                    text="Anular test"
                    icon={<i className="flaticon-trash" />}
                    warningColor={true}
                    onClick={handleTestCancel}
                  />
                </MyButtonsContainer>
              )}

              <div className="show-tests-results__rep-wrapper">
                <h4>TEST FINAL</h4>
                <div className="show-tests-results__rep">
                  {moment(selectedData?.date)?.isValid() && (
                    <div>
                      <span>Fecha de test</span>
                      {moment(selectedData.date)?.format('DD-MM-YYYY - HH:mm:ss')}
                    </div>
                  )}

                  {selectedData?.user && (
                    <div>
                      <span>Usuario</span>
                      {selectedData.user}
                    </div>
                  )}
                  {showSourceFile && selectedData?.filename && (
                    <SourceFileDownloadBtn filename={selectedData.filename} />
                  )}
                  {selectedData?.machine && (
                    <MachineInfoBtn
                      machine={selectedData.machine}
                      testName={selectedTest.testName}
                    />
                  )}
                </div>
              </div>
            </>
          )}
          {selectedData.cancellation && (
            <div className="show-tests-results__rep-wrapper--error">
              <h4>TEST ANULADO</h4>
              <div className="show-tests-results__rep">
                {moment(selectedData?.date)?.isValid() && (
                  <div>
                    <span>Fecha de test</span>
                    {moment(selectedData.date)?.format('DD-MM-YYYY - HH:mm:ss')}
                  </div>
                )}
                {moment(selectedData.cancellation?.date)?.isValid() && (
                  <div>
                    <span>Fecha de anulación</span>
                    {moment(selectedData.cancellation.date)?.format('DD-MM-YYYY - HH:mm:ss')}
                  </div>
                )}
                {selectedData.cancellation?.reason && (
                  <div>
                    <span>Motivo</span>
                    {selectedData.cancellation.reason}
                  </div>
                )}
                {selectedData.cancellation?.user && (
                  <div>
                    <span>Usuario</span>
                    {selectedData.cancellation.user}
                  </div>
                )}
                {showSourceFile && selectedData.filename && (
                  <SourceFileDownloadBtn filename={selectedData.filename} />
                )}
                {selectedData?.machine && (
                  <MachineInfoBtn machine={selectedData.machine} testName={selectedTest.testName} />
                )}
              </div>
            </div>
          )}
          {selectedTest.data[insideIndex]?.values?.map(test => {
            const valuesToShow = test.values.filter(value => value.value != null)
            const isCanceled = Boolean(selectedTest.data[insideIndex]?.cancellation)
            return (
              <div
                className={
                  isCanceled
                    ? 'show-tests-results__division--canceled'
                    : 'show-tests-results__division'
                }
                key={test.name}
              >
                <h3>{test.name.toUpperCase()}</h3>
                <ListItemsInTwoColumns className="show-tests-results__list" noColumnsOnPc={true}>
                  {valuesToShow
                    .sort((a, b) => a.sort - b.sort)
                    .map(elem => (
                      <div className="show-tests-results__card" key={elem.name + Math.random()}>
                        <TextWithHoverIfEllipsis>
                          <div className="show-tests-results__card-name">{elem.name}</div>
                        </TextWithHoverIfEllipsis>
                        <div className="show-tests-results__card-value">
                          {elem.value}
                          <span> {elem.unit}</span>
                        </div>
                        {elem.detail && (
                          <div className="show-tests-results__card-detail">{elem.detail}</div>
                        )}
                      </div>
                    ))}
                </ListItemsInTwoColumns>
              </div>
            )
          })}
          {!selectedData?.values?.length && (
            <div className="no-values">NO HAY DATOS PARA ESTE TEST</div>
          )}
          {!selectedData.cancellation && (
            <Gallery
              images={images}
              centerImages={true}
              defaultSelectValue={selectedTest.testName}
              typeSelectable={false}
              addButtonText={`Añadir imagen de ${selectedTest.testName}`}
              noImagesText={`Aún no hay imágenes de ${selectedTest.testName}`}
              onCaptureImage={(image, geolocation) =>
                uploadImage({
                  image,
                  geolocation,
                  type: 'testmachine',
                  testName: selectedTest.testName,
                })
              }
            />
          )}
        </div>
      )
    } catch (err) {
      console.error(err)
      return <div className="no-values">Ha ocurrido un error</div>
    }
  }

  return (
    <>
      {!(tests?.length > 0) && showAll && (
        <div className="no-values">NO SE HAN REALIZADO TESTS</div>
      )}
      {tests?.length > 0 && (
        <section className="show-tests-results__test-results">
          {loading && <ChildSpinner visible={loading} />}
          {!loading && (
            <>
              <MyButtonsContainer margin="0 auto 1.5rem auto">
                {tests.length > 0 && customButton}
              </MyButtonsContainer>
              {tests.length > 0 && (
                <ToggleButtons
                  className="show-tests-results__test-selector"
                  handleChange={setIndex}
                  selectedValue={index}
                  buttons={tests.map((test, index) => {
                    const isCanceled = Boolean(test.data[test.data.length - 1]?.cancellation)
                    return {
                      text: test.testName.toUpperCase(),
                      value: index,
                      color: isCanceled ? 'var(--error-color)' : null,
                    }
                  })}
                />
              )}
              <>
                {selectedTest?.data?.length > 1 && (
                  <div className="show-tests-results__hint">
                    Desliza hacia la derecha para ver los test anulados
                  </div>
                )}
                {selectedTest?.data?.length && (
                  <Slider autoslideToLastIndex={true} mouseWheel={true}>
                    {Array.from({ length: selectedTest.data.length }, (_, i) => (
                      <Values insideIndex={i} key={i} />
                    ))}
                  </Slider>
                )}
              </>
              {!selectedTest.data?.length && (
                <div className="no-values">NO HAY DATOS PARA ESTE TEST</div>
              )}
            </>
          )}
          <Modal
            open={errorsModal.show}
            onClose={() => setErrorsModal({ show: false, errors: [] })}
            title={`${errorsModal.errors?.length > 1 ? 'Errores' : 'Error'} en ${
              selectedTest?.testName
            }`}
            hasCloseButton={false}
            content={<TestsResultsErrorModal errors={errorsModal.errors} />}
          />
          {machineInfoModal?.show && (
            <MachineInfo
              testNames={[machineInfoModal.testName]}
              machine={machineInfoModal.machine}
              closeModal={() => setMachineInfoModal({ ...machineInfoModal, show: false })}
            />
          )}
        </section>
      )}
    </>
  )
}
