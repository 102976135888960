import React, { useCallback, useState, useEffect } from 'react'
import { PieChart as RechartPie, Pie, Cell, ResponsiveContainer, Legend, Sector } from 'recharts'
import './Pie.scss'

const renderCustomizedLabel = ({ cx, cy, midAngle, outerRadius, fill, percent, payload }) => {
  const RADIAN = Math.PI / 180
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 8) * cos
  const sy = cy + (outerRadius + 8) * sin
  const mx = cx + (outerRadius + 20) * cos
  const my = cy + (outerRadius + 20) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 12
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  if (percent) {
    return (
      <g>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 8}
          y={ey}
          fontSize="0.9rem"
          fontWeight={500}
          textAnchor={textAnchor}
          fill="#000"
        >
          {payload.name}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 8}
          y={ey}
          dy={13}
          fontSize="0.8rem"
          textAnchor={textAnchor}
          fontWeight={500}
          fill="#999"
        >
          {payload.value}
          {payload.unit ? payload.unit : ''}-{`${(percent * 100)?.toFixed(2)?.replace('.', ',')}%`}
        </text>
      </g>
    )
  }
}

const RenderLegend = ({ data }) => {
  console.log({ data })
  return (
    <div className="pie__legend">
      {data.map((entry, i) => (
        <div key={i}>
          <div
            className="pie__legend-dot"
            style={{
              backgroundColor: entry.color ?? 'var(--main-color)',
            }}
          />
          <span>
            {entry.value} {entry.name}
          </span>
        </div>
      ))}
    </div>
  )
}

/**
 *
 * @param {{data: {name: string, value: number, color?: string, unit?: string}[], donut: boolean, noValuesText?: string}} param0
 * @returns
 */
const PieChart = ({ data, donut = false, noValuesText = 'Sin valores' }) => {
  const [activeIndex, setActiveIndex] = useState(null)
  const [animationKey, setAnimationKey] = useState(0)
  const hasValues = data?.some(d => d.value > 0)

  useEffect(() => {
    setAnimationKey(prevKey => prevKey + 1)
  }, [data])

  const onMouseOver = useCallback((data, index) => {
    setActiveIndex(index)
  }, [])
  const onMouseLeave = useCallback(() => {
    setActiveIndex(null)
  }, [])

  const renderCustomizedActiveShape = props => {
    return (
      <g>
        <text x={props.cx} y={props.cy} dy={8} textAnchor="middle" fill="#000">
          {props.name}: {props.value}
          {props.unit}
        </text>
        <Sector
          cx={props.cx}
          cy={props.cy}
          innerRadius={props.innerRadius}
          outerRadius={props.outerRadius}
          startAngle={props.startAngle}
          endAngle={props.endAngle}
          fill={props.fill ?? 'var(--main-color)'}
          cornerRadius={10}
          style={{ transition: 'all 0.5s ease', borderRadius: '0.4rem' }}
        />
      </g>
    )
  }

  if (!hasValues)
    return (
      <div className="pie-chart__no-values">
        <i className="flaticon-not-found" />
        <span>{noValuesText}</span>
      </div>
    )
  return (
    <>
      <div className="pie-chart-container chart-container">
        <ResponsiveContainer width="100%" height="100%">
          <RechartPie key={animationKey}>
            {hasValues && (
              <Pie
                data={data}
                labelLine={false}
                label={props => (donut ? null : renderCustomizedLabel(props))}
                outerRadius="80%"
                innerRadius={donut ? '60%' : 0}
                dataKey="value"
                paddingAngle={donut ? 5 : 0}
                activeIndex={activeIndex}
                activeShape={donut ? renderCustomizedActiveShape : null}
                onMouseOver={(data, index) => onMouseOver(data, index)}
                onMouseLeave={onMouseLeave}
                cornerRadius={donut ? 10 : 0}
              >
                {data?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.color ?? 'var(--main-color)'}
                    opacity={0.7}
                    style={donut ? { borderRadius: '0.4rem', transition: 'all 0.5s ease' } : {}}
                  />
                ))}
              </Pie>
            )}
            {donut && <Legend content={<RenderLegend data={data} />} />}
          </RechartPie>
        </ResponsiveContainer>
      </div>
    </>
  )
}

export default PieChart

PieChart.donut = props => <PieChart {...props} donut={true} />
