export default function formatNumberToString(number, toFixed = 2) {
  try {
    if (
      number == null ||
      Number.isNaN(number) ||
      typeof number !== 'number' ||
      !Number.isFinite(number)
    )
      return '-'
    if (Number.isInteger(number)) return String(number)
    else return number.toFixed(toFixed).replace('.', ',')
  } catch (err) {
    console.error(err)
    return '-'
  }
}
