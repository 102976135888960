import easyToast from 'components/Others/EasyToast/easyToast'
import isValidFunction from 'myMethods/isValidFunction'

// constantes
const initialData = {
  open: false,
  chats: null,
}

// types
const SET_OPEN_SUCCESS = 'SET_OPEN_SUCCESS'
const SET_CHAT_SUCCESS = 'SET_CHAT_SUCCESS'

// reducer
export default function configReducer(state = initialData, action) {
  switch (action.type) {
    case SET_CHAT_SUCCESS:
      return { ...state, chats: action.payload }
    case SET_OPEN_SUCCESS:
      return { ...state, open: action.payload }
    default:
      return state
  }
}

// actions

export const setReduxOpenChat = data => dispatch => {
  try {
    dispatch({
      type: SET_OPEN_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxChats = data => dispatch => {
  console.log('entroSetReduxChats ', data)
  try {
    dispatch({
      type: SET_CHAT_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const addReduxChatMessage =
  ({ messageData, toast }) =>
  (dispatch, getState) => {
    try {
      isValidFunction(toast) && toast()
      let { chats } = getState().chat
      const index = chats.findIndex(chat => chat.id === messageData.id)
      if (index !== -1 && messageData.message) {
        if (
          !chats[index]?.messages?.some(
            m => m.id === messageData.message.id && m.text === messageData.message.text
          )
        ) {
          chats[index].messages.push(messageData.message)
          chats[index].lastSeen[messageData.message.author] = messageData.message.time
        }
      }

      dispatch({
        type: SET_CHAT_SUCCESS,
        payload: JSON.parse(JSON.stringify(chats)),
      })
    } catch (err) {
      console.error(err)
    }
  }

export const updateReduxChatLastSeen = data => (dispatch, getState) => {
  try {
    let { chats } = getState().chat
    const index = chats.findIndex(chat => chat.id === data.id)
    if (index !== -1) {
      chats[index].lastSeen = data.lastSeen
    }

    dispatch({
      type: SET_CHAT_SUCCESS,
      payload: chats,
    })
  } catch (err) {
    console.error(err)
  }
}
