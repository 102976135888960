import React from 'react'
import { useSelector } from 'react-redux'
import CashRegister from 'components/Office/CashRegister/CashRegister'
import Title from 'components/Others/Title/Title'

export default function CashPage() {
  const redux = useSelector(store => store)
  const cashRegister = redux?.cashRegister

  return (
    <>
      {cashRegister?.status?.name && (
        <Title
          text={
            cashRegister.isChanging
              ? 'ESPERANDO CAJA...'
              : cashRegister?.selectedCashRegister?.name || cashRegister.status.name
          }
        />
      )}
      <CashRegister redux={redux} />
    </>
  )
}
