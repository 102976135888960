//@ts-check
import React, { useState, useEffect } from 'react'
import Modal from 'components/Others/Modal/Modal'
import easyToast from 'components/Others/EasyToast/easyToast'
import { useDispatch } from 'react-redux'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import Select from 'components/Others/Select/Select'
import useResources from 'hooks/useResources'

/**
 * EditFilterCategoriesModal component allows the user to edit categories for a given filter.
 * @param {Object} props - The properties object.
 * @param {function} props.closeModal - Function to close the modal.
 * @param {Array} props.allFilters - List of all filters.
 * @param {Object} props.filter - The filter to be edited.
 * @param {function} props.saveChanges - Function to save the changes.
 * @returns {JSX.Element} The rendered component.
 */
export default function EditFilterCategoriesModal({ closeModal, allFilters, filter, saveChanges }) {
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState([])
  const dispatch = useDispatch()
  const { categories, loading: { categories: loadingCategories } = {} } = useResources({
    categories: true,
  })

  useEffect(() => {
    if (filter?.categories?.length && categories?.length) {
      const newValues = filter.categories
        .map(elem => categories?.find(cat => cat.value === elem))
        .filter(Boolean)
      setValues(newValues)
    }
  }, [categories, filter.categories])

  const validateCategories = () => {
    if (!allFilters?.length) throw new Error('Missing allFilters in validateCategories')
    for (const value of values) {
      const duplicatedCategoryFilters = allFilters.filter(
        elem => elem.categories.includes(value.value) && elem.name !== filter.name
      )
      if (duplicatedCategoryFilters.length) {
        const duplicatedClassificationFilter = duplicatedCategoryFilters.find(elem => {
          const index = elem.classifications.findIndex(cla => filter.classifications.includes(cla))
          return index !== -1
        })
        if (duplicatedClassificationFilter) {
          return {
            isValid: false,
            data: {
              filter: duplicatedClassificationFilter.name,
              category: value.label,
              classification: duplicatedClassificationFilter.classifications.find(cla =>
                filter.classifications.includes(cla)
              ),
            },
          }
        }
      }
    }
    return { isValid: true, data: null }
  }

  const handleAccept = async () => {
    try {
      if (!values.length) return easyToast('error', 'Ninguna categoría seleccionada')
      const newCategories = values.map(elem => elem.value)
      const validation = validateCategories()
      if (!validation.isValid) {
        return dispatch(
          setReduxConfigModal({
            error: true,
            message: `La categoría ${validation.data.category} ya está asignada al filtro ${
              validation.data.filter
            } con la clasificación ${
              validation.data.classification.length > 2
                ? validation.data.classification
                : validation.data.classification + 'xx'
            }. Si dos filtros tuvieran la misma categoría y clasificación habría conflictos en la inspección`,
            alertMessage:
              'Recuerde que la clasificación de cuatro dígitos tiene prioridad sobre la de dos dígitos',
            hasCloseButton: false,
          })
        )
      }
      const newFilter = { ...filter, categories: newCategories }
      setLoading(true)
      await saveChanges(newFilter)
    } catch (err) {
      console.error(err)
      easyToast('error', 'No se han podido guardar las categorías')
    } finally {
      setLoading(false)
    }
  }
  return (
    <Modal
      open={true}
      onClose={closeModal}
      title="Editar categorías"
      closeOnOverlay={false}
      onAccept={handleAccept}
      loading={loading || loadingCategories}
      hasAcceptButton={
        JSON.stringify(filter?.categories?.sort()) !==
        JSON.stringify(values?.map(cat => cat.value)?.sort())
      }
      closeAfterAccept={false}
      content={
        <div className="edit-filter-categories-modal">
          <Select
            label="Categorías"
            name="categoria"
            isSearchable
            isClearable={false}
            isMulti={true}
            options={categories}
            value={values}
            onChange={setValues}
          />
        </div>
      }
    />
  )
}
