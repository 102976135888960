import React from 'react'

// MY IMPORTS
import 'components/Others/OfficeFormState/OfficeFormState.scss'

export default function OfficeFormState({ onClick }) {
  return (
    <div className="office-form-state" onClick={onClick}>
      <i className="flaticon-upload" />
      <span></span>
    </div>
  )
}
