import ErrorView from 'components/Others/ErrorView/ErrorView'
import React, { useEffect, useState } from 'react'
import './ListOfStatisticsDefects.scss'
import reportDefectsJSON from 'resources/defectology/reportDefects.json'
import useWindowDimensions from 'hooks/useWindowDimensions'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'

const getReportDefectsFormatted = (columns = 2) => {
  const defectsTotal = reportDefectsJSON?.length ?? 0
  const eachDivisionLength = Math.round(defectsTotal / columns)
  return (
    reportDefectsJSON?.map((defect, index) => {
      let gridColumnStart = 1
      if (index > eachDivisionLength) {
        if (index < eachDivisionLength * 2 + 1) gridColumnStart = 2
        else if (index < eachDivisionLength * 3 + 1) gridColumnStart = 3
        else if (index < eachDivisionLength * 4 + 1) gridColumnStart = 4
        else gridColumnStart = 5
      }
      return { ...defect, gridColumnStart }
    }) || []
  )
}

export default function ListOfStatisticsDefects({ defects: summary }) {
  const { width } = useWindowDimensions()
  const [data, setRows] = useState({
    totalColumns: 2,
    reportDefectsFormatted: getReportDefectsFormatted(),
  })
  const defects = summary?.defects || summary?.byDefects

  useEffect(() => {
    if (width < 1200 && data.totalColumns !== 2) {
      setRows({ totalColumns: 2, reportDefectsFormatted: getReportDefectsFormatted(2) })
    } else if (width > 1200 && width < 1600 && data.totalColumns !== 3) {
      setRows({ totalColumns: 3, reportDefectsFormatted: getReportDefectsFormatted(3) })
    } else if (width > 1600 && data.totalColumns !== 4) {
      setRows({ totalColumns: 4, reportDefectsFormatted: getReportDefectsFormatted(4) })
    }
    //eslint-disable-next-line
  }, [width])

  try {
    if (!defects || !data.reportDefectsFormatted?.length || !Object.keys(defects).length)
      return <div className="no-values">Sin datos</div>
    return (
      <div className="list-of-statistics-defects">
        <div className="list-of-statistics-defects__summary">
          <CardNameValue name="Inspecciones" value={summary.inspections ?? 0} />
          <CardNameValue name="Total defectos" value={summary.totalDefects ?? summary.total ?? 0} />
          <CardNameValue
            name="Leves"
            value={summary.slight ?? 0}
            className="list-of-statistics-defects__summary-elem--l"
          />
          <CardNameValue
            name="Graves"
            value={summary.serious ?? 0}
            className="list-of-statistics-defects__summary-elem--g"
          />
          <CardNameValue
            name="Muy graves"
            value={summary.verySerious ?? 0}
            className="list-of-statistics-defects__summary-elem--vg"
          />
        </div>
        <div
          className="list-of-statistics-defects__list"
          style={{ gridTemplateColumns: Array(data.totalColumns).fill('1fr').join(' ') }}
        >
          {data.reportDefectsFormatted.map(({ id, name, gridColumnStart }) => {
            let totalDefects = defects[id]?.total ?? 0
            const lDefects = defects[id]?.slight ?? 0
            const gDefects = defects[id]?.serious ?? 0
            const vgDefects = defects[id]?.verySerious ?? 0
            let lDefectWidth = defects[id]?.slight ? (lDefects * 100) / totalDefects + '%' : '0%'
            let gDefectWidth = defects[id]?.serious ? (gDefects * 100) / totalDefects + '%' : '0%'
            let vgDefectWidth = defects[id]?.verySerious
              ? (vgDefects * 100) / totalDefects + '%'
              : '0%'

            return (
              <HoverTooltip
                title={
                  <div className="list-of-statistics-defects__tooltip">
                    <span>DEFECTO {id}</span>
                    <div>Leves: {lDefects}</div>
                    <div>Graves: {gDefects}</div>
                    <div>Muy graves: {vgDefects}</div>
                  </div>
                }
              >
                <div
                  className="list-of-statistics-defects__list-defect"
                  style={{ gridColumnStart }}
                >
                  <div className="list-of-statistics-defects__list-defect-id">{id}</div>
                  <div className="list-of-statistics-defects__list-defect-name">{name}</div>
                  <div className="list-of-statistics-defects__list-defect-total">
                    {totalDefects}
                  </div>
                  <div className="list-of-statistics-defects__list-defect-bar">
                    <div style={{ width: lDefectWidth }}></div>
                    <div style={{ width: gDefectWidth }}></div>
                    <div style={{ width: vgDefectWidth }}></div>
                  </div>
                </div>
              </HoverTooltip>
            )
          })}
        </div>
      </div>
    )
  } catch (error) {
    console.error(error)
    return <ErrorView />
  }
}
