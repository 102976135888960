import Title from 'components/Others/Title/Title'
import Users from 'components/Users/Users'
import React from 'react'

export default function UsersPage() {
  return (
    <>
      <Title text="GESTIÓN DE USUARIOS" />
      <Users />
    </>
  )
}
