import React from 'react'
import './FilterNameIcon.scss'

const iconPaths = {
  turismo: '/icons/vehicleTypes/turismo.svg',
  autobús: '/icons/vehicleTypes/autobus.svg',
  furgoneta: '/icons/vehicleTypes/furgoneta.svg',
  camión: '/icons/vehicleTypes/camion.svg',
  tractora: '/icons/vehicleTypes/tractora.svg',
  ciclomotor: '/icons/vehicleTypes/ciclomotor.svg',
  motocicleta: '/icons/vehicleTypes/motocicleta.svg',
  'remolque-sin-matrícula': '/icons/vehicleTypes/remolque-sin-matricula.svg',
  'remolque-pesado': '/icons/vehicleTypes/remolque-pesado.svg',
  'remolque-ligero': '/icons/vehicleTypes/remolque-ligero.svg',
  'triciclo-ligero': '/icons/vehicleTypes/triciclo.svg',
  'triciclo-pesado': '/icons/vehicleTypes/triciclo.svg',
  'quad-ligero': '/icons/vehicleTypes/quad.svg',
  'quad-pesado': '/icons/vehicleTypes/quad.svg',
  agrícola: '/icons/vehicleTypes/agricola.svg',
  'remolque-agrícola': '/icons/vehicleTypes/remolque-pesado.svg',
  'obras-y-servicios': '/icons/vehicleTypes/obras-y-servicios.svg',
  'remolque-de-obras-y-servicios': '/icons/vehicleTypes/remolque-pesado.svg',
}

const FilterNameIcon = ({ type }) => {
  const iconPath = iconPaths[type]
  if (!iconPath) return null

  return <img src={iconPath} alt={type} className={`filter-name-icon filter-name-icon-${type}`} />
}

export default FilterNameIcon
