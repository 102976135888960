//@ts-check
import React from 'react'
import { useCallback } from 'react'
import moment from '../../node_modules/moment/moment'
import formatEuro from 'myMethods/formatEuro'
import formatDateToStringAgo from 'myMethods/formatDateToStringAgo'
import { useSelector } from 'react-redux'
import useResources from './useResources'

/**
 * @typedef {import('types/TableColumnsKeys').TableColumnsKeys} TableColumnsKeys
 */

/** */
export default function useTableColumns() {
  const redux = useSelector(store => store)
  const series = redux?.config?.itv?.accounting?.series
  const { roles } = useResources({ roles: true })

  const tableColumns = {
    clients: [
      { title: 'Nombre', field: 'name' },
      { title: 'DNI/CIF', field: 'dni' },
      { title: 'Método de pago', field: 'paymentMethod' },
      { title: 'Domicilio', field: 'address' },
      { title: 'CP', field: 'zipCode' },
      { title: 'Provincia', field: 'province' },
      { title: 'Localidad', field: 'locality' },
      { title: 'E-mail', field: 'email' },
      {
        title: 'Última modificación',
        render: useCallback(data => data.user?.username, []),
        customFilterAndSearch: (term, rowData) =>
          rowData.user?.username?.toLowerCase().includes(term.toLowerCase()),
      },
    ],
    vehicles: [
      { title: 'Matrícula', field: 'license' },
      { title: 'Categoría', field: 'category' },
      { title: 'Clasificación', field: 'classification' },
      { title: 'Marca', field: 'brand' },
      { title: 'Modelo', field: 'model' },
      { title: 'Tipo', field: 'type' },
      { title: 'Bastidor', field: 'frame' },
      { title: 'Homologación', field: 'homologation' },
      { title: 'Combustible 1', field: 'fuel1' },
      { title: 'Combustible 2', field: 'fuel2' },
      {
        title: 'Fecha de 1ª matriculación',
        render: useCallback(rowData => {
          if (!rowData.licenseDate) return '-'
          const date = moment(rowData.licenseDate)
          if (!date.isValid()) return '-'
          return date.format('DD-MM-YYYY')
        }, []),
      },
      {
        title: 'Fecha de 2ª matriculación',
        render: useCallback(rowData => {
          if (!rowData.licenseDate2) return '-'
          const date = moment(rowData.licenseDate2)
          if (!date.isValid()) return '-'
          return date.format('DD-MM-YYYY')
        }, []),
      },
      {
        title: 'Fecha de caducidad',
        render: useCallback(rowData => {
          if (!rowData.expirationDate) return '-'
          const date = moment(rowData.expirationDate)
          if (!date.isValid()) return '-'
          return date.format('DD-MM-YYYY')
        }, []),
      },
      {
        title: 'DNI titular',
        render: data => data.holder?.dni,
        customFilterAndSearch: (term, data) =>
          data?.holder?.dni?.toLowerCase().includes(term?.toLowerCase()),
      },
      {
        title: 'Nombre titular',
        render: data => data.holder?.name,
        customFilterAndSearch: (term, data) =>
          data?.holder?.name?.toLowerCase().includes(term?.toLowerCase()),
      },
      {
        title: 'Última modificación',
        render: data => data.user?.username,
        customFilterAndSearch: (term, data) =>
          data.user?.username?.toLowerCase().includes(term?.toLowerCase()),
      },
    ],
    invoices: [
      {
        title: 'Factura',
        field: 'invoiceNumber',
      },
      {
        title: 'Serie',
        render: useCallback(
          data => {
            return series?.[data.serie]
          },
          [series]
        ),
        customFilterAndSearch: useCallback(
          (term, rowData) => {
            try {
              return term?.toUpperCase() === series[rowData.serie]
            } catch (err) {
              console.error(err)
              return false
            }
          },
          [series]
        ),
      },
      {
        title: 'Fecha',
        render: useCallback(data => {
          try {
            if (!data?.date) throw new Error('No date found in data')
            return moment(data.date).format('DD-MM-YYYY HH:mm')
          } catch (err) {
            console.error(err)
          }
        }, []),
      },
      { title: 'DNI/CIF', field: 'clientDni' },
      { title: 'Nombre', field: 'clientName' },
      {
        title: 'Importe',
        render: useCallback(data => formatEuro(data.breakdown?.total), []),
        customFilterAndSearch: useCallback((term, rowData) => {
          try {
            let amount = 0
            if (rowData.breakdown) {
              amount = rowData.breakdown?.total || 0
            }
            term.replace(',', '.')
            if (amount.toString().includes(term)) return true
            return false
          } catch (err) {
            console.error(err)
            return false
          }
        }, []),
      },

      {
        title: 'Método de pago',
        render: useCallback(data => data.paymentDetails?.tpv?.name || data.paymentMethod, []),
        customFilterAndSearch: useCallback(
          (term, rowData) =>
            rowData.paymentDetails?.tpv?.name?.toLowerCase().includes(term.toLowerCase()) ||
            rowData.paymentMethod?.toLowerCase().includes(term.toLowerCase()),
          []
        ),
      },
      { title: 'Informe', field: 'reportNumber' },
      { title: 'Tipo de inspección', field: 'inspectionType' },
      { title: 'Generada por', field: 'userUsername' },
    ],
    deals: [
      { title: 'Albarán', field: 'invoiceNumber' },
      {
        title: 'Facturado',
        filtering: false,
        render: useCallback(data => (data.inUse ? '✔' : ''), []),
        cellStyle: { textAlign: 'center' },
      },
      {
        title: 'Fecha',
        render: useCallback(data => {
          try {
            if (!data.date) throw new Error('No date found in data')
            return moment(data.date).format('DD-MM-YYYY HH:mm')
          } catch (err) {
            console.error(err)
          }
        }, []),
      },
      {
        title: 'DNI/CIF',
        field: 'clientDni',
      },
      {
        title: 'Cliente',
        field: 'clientName',
      },
      {
        title: 'Importe',
        render: useCallback(data => {
          try {
            return formatEuro(data.breakdown?.total)
          } catch (err) {
            console.error(err)
            return '-'
          }
        }, []),
        customFilterAndSearch: useCallback((term, rowData) => {
          let amount = 0
          if (rowData.desglose) {
            amount = rowData.desglose?.total || 0
          }
          term.replace(',', '.')
          if (amount.toString().includes(term)) return true
          return false
        }, []),
      },
      { title: 'Informe', field: 'reportNumber' },
      { title: 'Tipo de inspección', field: 'inspectionType' },

      { title: 'Generada por', field: 'userUsername' },
    ],
    history: [
      { title: 'Informe', field: 'numero_informe' },
      {
        title: 'Revisión',
        render: useCallback(data => (data.rev ? data.rev : 0), []),
        customFilterAndSearch: useCallback(
          (term, rowData) => (rowData.rev?.toString() ?? '0').includes(String(term)),
          []
        ),
      },
      {
        title: 'Fecha',
        render: data => {
          const date = data?.tiempos?.supervision_end
          return date ? moment(date).format('DD-MM-YYYY HH:mm') : '-'
        },
      },
      { title: 'Inspección', field: 'inspeccion' },
      { title: 'Resultado', field: 'resultado' },
      {
        title: 'Matrícula',
        render: useCallback(data => data?.vehicleData?.license, []),
        customFilterAndSearch: useCallback(
          (term, rowData) =>
            rowData?.vehicleData?.license?.toUpperCase().includes(term.toUpperCase()),
          []
        ),
      },

      {
        title: 'Categoria',
        render: useCallback(data => data?.vehicleData?.category, []),
        customFilterAndSearch: useCallback(
          (term, rowData) =>
            rowData?.vehicleData?.category?.toUpperCase().includes(term.toUpperCase()),
          []
        ),
      },
      {
        title: 'Clasificación',
        render: useCallback(data => data?.vehicleData?.classification, []),
        customFilterAndSearch: useCallback(
          (term, rowData) =>
            rowData?.vehicleData?.classification?.toUpperCase().includes(term.toUpperCase()),
          []
        ),
      },
      {
        title: 'Combustible 1',
        render: useCallback(data => data?.vehicleData?.fuel1, []),
        customFilterAndSearch: useCallback(
          (term, rowData) =>
            rowData?.vehicleData?.fuel1?.toUpperCase().includes(term.toUpperCase()),
          []
        ),
      },
      {
        title: 'Combustible 2',
        render: useCallback(data => data?.vehicleData?.fuel2, []),
        customFilterAndSearch: useCallback(
          (term, rowData) =>
            rowData?.vehicleData?.fuel2?.toUpperCase().includes(term.toUpperCase()),
          []
        ),
      },
      {
        title: 'DNI/CIF titular',
        render: useCallback(data => data?.vehicleData?.holder?.dni, []),
        customFilterAndSearch: useCallback(
          (term, rowData) =>
            rowData?.vehicleData?.holder?.dni?.toUpperCase().includes(term.toUpperCase()),
          []
        ),
      },
      {
        title: 'Nombre titular',
        render: useCallback(data => data?.vehicleData?.holder?.name, []),
        customFilterAndSearch: useCallback(
          (term, rowData) =>
            rowData?.vehicleData?.holder?.name?.toUpperCase().includes(term.toUpperCase()),
          []
        ),
      },
    ],
    rectifierDeals: [
      { title: 'Albarán', field: 'number' },
      {
        title: 'Fecha',
        render: useCallback(data => {
          try {
            if (data.date && moment(data.date).isValid()) {
              return moment(data.fecha).format('DD-MM-YYYY HH:mm')
            }
          } catch (err) {
            console.error(err)
          }
        }, []),
      },
      {
        title: 'DNI/CIF',
        render: useCallback(data => data.client?.dni, []),
      },
      { title: 'Nombre', render: useCallback(data => data.client?.name, []) },
      {
        title: 'Importe',
        render: useCallback(data => {
          try {
            return formatEuro(data.breakdown.total)
          } catch (err) {
            console.error(err)
          }
        }, []),
      },
      {
        title: 'Matrícula',
        render: useCallback(data => data.report?.vehicle?.license, []),
        customFilterAndSearch: useCallback(
          (term, rowData) =>
            rowData.report?.vehicle?.license?.toLowerCase().includes(term.toLowerCase()),
          []
        ),
      },
      { title: 'Informe', render: useCallback(data => data.report?.number, []) },
      {
        title: 'Tipo de inspección',
        render: useCallback(data => data.report?.inspectionType, []),
      },

      { title: 'Generado por', render: useCallback(data => data.user?.username, []) },
    ],
    ongoingInspections: [
      { title: 'Matrícula', render: data => data.vehicleData?.license },
      {
        title: 'Estado',
        render: data => {
          const times = data.tiempos
          if (times?.supervision_start) return 'EN SUPERVISIÓN...'
          if (times?.inspection_end) return 'ESPERANDO SUPERVISIÓN...'
          if (times?.inspection_start) return 'EN INSPECCIÓN...'
          return 'ESPERANDO INSPECCIÓN...'
        },
      },
      { title: 'Tipo de inspección', field: 'inspeccion' },
      { title: 'Línea', field: 'linea' },

      {
        title: 'Inspector/Supervisor',
        render: data => `${data.usuario_inspector || '-'}/${data.usuario_supervisor || '-'}`,
      },
      {
        title: 'Hora',
        render: data => formatDateToStringAgo(data.tiempos?.office_end),
      },
      { title: 'Informe', field: 'numero_informe' },
      { title: 'Marca', render: data => data.vehicleData?.brand },
      { title: 'Modelo', render: data => data.vehicleData?.model },
      { title: 'Combustible 1', render: data => data.vehicleData.fuel1 },
      { title: 'Combustible 2', render: data => data.vehicleData?.fuel2 },
      { title: 'Categoría', render: data => data.vehicleData?.category },
      { title: 'Clasificación', render: data => data.vehicleData?.classification },

      { title: 'DNI/CIF', field: 'dni_cliente' },
      { title: 'Nombre', field: 'nombre_cliente' },
      { title: 'Generada por', field: 'usuario_administrativa' },
    ],
    billingExportModalClients: [
      { title: 'Nombre', field: 'name' },
      { title: 'DNI/CIF', field: 'dni' },
      { title: 'Método de pago', field: 'paymentMethod' },
    ],
    cashRegisterConfigurator: [
      {
        title: 'Activa',
        render: useCallback(rowData => (rowData.active ? '✔' : '✖'), []),
        disableClick: true,
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
      },
      { title: 'Nombre de caja', field: 'name' },
      {
        title: 'Usuario',
        render: useCallback(rowData => rowData?.user?.username, []),
        customFilterAndSearch: useCallback(
          (term, rowData) => rowData.user?.username?.toLowerCase().includes(term.toLowerCase()),
          []
        ),
      },
      {
        title: 'Fecha de creación',
        render: useCallback(data => {
          try {
            if (data?.createdAt && moment(data.createdAt).isValid()) {
              return moment(data.createdAt).format('DD-MM-YYYY HH:mm:ss')
            }
            return '-'
          } catch (err) {
            console.error(err)
            return '-'
          }
        }, []),
      },
      {
        title: 'Activo hasta',
        render: useCallback(data => {
          try {
            if (!data?.activeUntil || !moment(data.activeUntil).isValid()) return 'INDEFINIDO'
            return moment(data?.activeUntil)?.format('DD-MM-YYYY')
          } catch (err) {
            console.error(err)
            return '-'
          }
        }, []),
      },
    ],
    endOfDay: [
      { title: 'Nombre', field: 'name' },
      {
        title: 'Cerrada',
        render: useCallback(data => (data.end === '-' ? '' : '✔'), []),
      },
      { title: 'Inicio', field: 'start' },
      { title: 'Fin', field: 'end' },
      { title: 'Diferencia', field: 'difference' },
      { title: 'Ingresos', field: 'revenueEuro' },
      { title: 'Ingresos crédito', field: 'creditRevenue' },
      { title: 'Movimientos', field: 'cashFlows' },
      { title: 'A caja ITV', field: 'itvCashFlows' },
      { title: 'Queda en caja', field: 'cashLeft' },
    ],
    discounts: [
      { title: 'Nombre', field: 'name' },
      {
        title: 'Descuento',
        render: useCallback(rowData => {
          if (rowData.method === '%') return `${rowData.amount}%`
          if (rowData.method === 'DECREMENTO') return formatEuro(rowData.amount)
        }, []),
      },
      { title: 'Descripción', field: 'description' },
      {
        title: 'Mínimo beneficio',
        render: useCallback(
          rowData => (rowData.minimumBenefit ? formatEuro(rowData.minimumBenefit) : '-'),
          []
        ),
      },
      {
        title: 'Combustibles',
        render: useCallback(
          rowData => (rowData.fuels?.length ? rowData.fuels.join(', ').toUpperCase() : 'Todos'),
          []
        ),
        customFilterAndSearch: useCallback(
          (term, rowData) => rowData.fuels?.find(elem => elem.toLowerCase() === term.toLowerCase()),
          []
        ),
      },
      {
        title: 'Categorías',
        render: useCallback(
          rowData =>
            rowData.categories?.length ? rowData.categories.join(', ').toUpperCase() : 'Todas',
          []
        ),
        customFilterAndSearch: useCallback(
          (term, rowData) =>
            rowData.categories?.find(elem => elem.toLowerCase() === term.toLowerCase()),
          []
        ),
      },
      {
        title: 'Clasificaciones',
        render: useCallback(
          rowData =>
            rowData.classifications?.length
              ? rowData.classifications.map(cla => (cla.length === 2 ? cla + 'xx' : cla)).join(', ')
              : 'Todas',
          []
        ),
        customFilterAndSearch: useCallback(
          (term, rowData) =>
            rowData.classifications?.find(elem => elem.toLowerCase() === term.toLowerCase()),
          []
        ),
      },
      {
        title: 'Aplicable desde',
        render: useCallback(rowData => {
          if (rowData.applyFrom && moment(rowData.applyFrom).isValid()) {
            return moment(rowData.applyFrom).format('DD-MM-YYYY HH:mm:ss')
          }
          return '-'
        }, []),
      },
      {
        title: 'Aplicable hasta',
        render: useCallback(rowData => {
          if (rowData.applyUntil && moment(rowData.applyUntil).isValid()) {
            return moment(rowData.applyUntil).format('DD-MM-YYYY HH:mm:ss')
          }
          return '-'
        }, []),
      },
    ],
    users: [
      {
        title: 'Activo',
        render: rowData => (rowData.active ? '✔' : '✖'),
        disableClick: true,
        headerStyle: {
          textAlign: 'center',
        },
        cellStyle: {
          textAlign: 'center',
        },
      },
      {
        field: 'url',
        title: 'Avatar',
        render: rowData => {
          try {
            if (rowData.avatar) {
              return (
                <div className="users__avatar">
                  <img src={`data:image/png;base64,${rowData.avatar}`} alt="Avatar" />
                </div>
              )
            } else {
              return (
                <div className="users__avatar">
                  <i className="flaticon-usuario-de-perfil" />
                </div>
              )
            }
          } catch (err) {
            console.error(err)
            return <i className="flaticon-usuario-de-perfil" />
          }
        },
      },
      { title: 'Usuario', field: 'username' },
      { title: 'Nombre', field: 'fullname' },
      {
        title: 'Rol',
        render: data => {
          try {
            const rol = roles?.find(elem => elem.value === data.role)?.label
            if (rol) {
              return rol.charAt(0).toUpperCase() + rol.slice(1)
            }
            throw new Error('No role found')
          } catch (err) {
            return '-'
          }
        },
        customFilterAndSearch: (term, rowData) => {
          try {
            const rol = roles?.find(elem => elem.value === rowData.role)?.label
            return rol.includes(term)
          } catch (err) {
            return false
          }
        },
      },
      {
        title: 'Fecha de creación',
        render: data => {
          try {
            if (data?.createdAt && moment(data.createdAt).isValid()) {
              return moment(data.createdAt).format('DD-MM-YYYY HH:mm:ss')
            }
            return '-'
          } catch (err) {
            console.error(err)
            return '-'
          }
        },
      },
      {
        title: 'Activo hasta',
        render: data => {
          try {
            if (!data.active || !moment(data.activeUntil).isValid()) return 'INDEFINIDO'
            return moment(data?.activeUntil)?.format('DD-MM-YYYY HH:mm')
          } catch (err) {
            console.error(err)
            return '-'
          }
        },
      },
    ],
  }

  return {
    getColumns: columsnKey => tableColumns[columsnKey],
    ...tableColumns,
  }
}
