/**
 * Rounds a number to a specified number of decimal places.
 *
 * @param {number|string} number - The number to be rounded. If a string is provided, it will be parsed to a float.
 * @param {number} [decimals=2] - The number of decimal places to round to. Must be a non-negative integer. Defaults to 2.
 * @returns {number} - The rounded number. Returns 0 if the input is invalid.
 */
export default function round(number, decimals = 2) {
  try {
    const parsedNumber = parseFloat(number)
    if (isNaN(parsedNumber)) {
      throw new Error('No number or number param type is not number in round function')
    }

    if (typeof decimals !== 'number' || decimals < 0 || !Number.isInteger(decimals)) {
      throw new Error('Decimals param must be a non-negative integer')
    }

    const factor = Math.pow(10, decimals)
    return Math.round((parsedNumber + Number.EPSILON) * factor) / factor
  } catch (err) {
    console.error(err)
    return 0
  }
}
