//@ts-check
import React, { useState, useEffect } from 'react'
import './ChatbotMain.scss'
import Logo from 'logo/mint-ai-full.svg'
import ChatbotSelector from '../ChatbotSelector/ChatbotSelector'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'

/**
 *
 * @param {{chatbot: import('types/UseMintAIChatbot').UseMintAIChatbot}} param0
 * @returns
 */
export default function ChatbotMain({ chatbot }) {
  const [loading, setLoading] = useState(true)

  if (loading)
    return (
      <>
        <img src={Logo} style={{ display: 'none' }} onLoad={() => setLoading(false)} />
        <ChildSpinner />
      </>
    )

  return (
    <div className="chatbot-main">
      <div className="chatbot-main__logo">
        <img src={Logo} alt="MintAI" />
      </div>
      <ChatbotSelector chatbot={chatbot} />
    </div>
  )
}
