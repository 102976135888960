//@ts-check
import React from 'react'
import Switch from 'components/Others/Switch/Switch'
import './AnalizadorDeGasesConfig.scss'
import TestConfigWrapper from '../../../TestConfigWrapper/TestConfigWrapper'

export default function AnalizadorDeGasesConfig({ setValues, values }) {
  return (
    <TestConfigWrapper testName="Analizador de gases">
      <div className="analizador-de-gases-config">
        <Switch
          label="Acelerado"
          name="accelerated"
          value={values?.accelerated}
          onChange={checked => setValues({ ...values, accelerated: checked })}
        />
      </div>
    </TestConfigWrapper>
  )
}
