//@ts-check
import React, { useRef, useState, useCallback, useMemo } from 'react'
import { ErrorMessage, Formik } from 'formik'
import './UsersForm.scss'
import isValidFunction from 'myMethods/isValidFunction'
import easyToast from 'components/Others/EasyToast/easyToast'
import Input from 'components/Others/Input/Input'
import MyButtonsCointainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { postUser as postUserService, putUser as putUserService } from 'services/Users'
import { user as userSchema } from 'formSchemas/User'
import Switch from 'components/Others/Switch/Switch'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'
import useService from 'hooks/useService'
import parseFormValues from 'myMethods/parseFormValues'
import Select from 'components/Others/Select/Select'
import Modal from 'components/Others/Modal/Modal'
import { KeyFill as PasswordIcon } from 'react-bootstrap-icons'
import { useSelector } from 'react-redux'
import useResources from 'hooks/useResources'

const initialValuesClear = {
  id: null,
  fullname: '',
  role: '',
  activeUntil: '',
  active: true,
  inspectionRestrictionVehicles: [],
  inspectionRestrictionNotInspectLicenses: [],
}

const UsersForm = ({ data, isNew = true, closeModal, userInfo, allUsers }) => {
  const imageInput = useRef(null)
  const form = useRef(null)
  const [image, setImage] = useState(null)
  const [deleteImage, setDeleteImage] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const isHimself = Boolean(userInfo?.user === data?.username)
  const { fetch: putUser, loading: loading1 } = useService({ service: putUserService })
  const { fetch: postUser, loading: loading2 } = useService({ service: postUserService })
  const loading = loading1 || loading2
  const filtersConfig = useSelector(store => store.config.filters)
  const filtersNamesOptions = filtersConfig?.map(({ name }) => ({ label: name, value: name }))
  const { roles } = useResources({ roles: true })

  const handleInitialValues = useCallback(() => {
    if (!isNew) {
      if (!data || !Object.keys(data).length) {
        easyToast('error', 'Ha ocurrido un error cargando el usuario')
        isValidFunction(closeModal) && closeModal()
        return initialValuesClear
      }
      return parseFormValues(
        {
          id: data?.id,
          username: data?.username,
          fullname: data?.fullname,
          role: data?.role,
          avatar: data?.avatar,
          activeUntil: data?.activeUntil,
          active: data?.active,
          inspectionRestrictionVehicles: data?.inspectionRestriction?.vehicles ?? [],
          inspectionRestrictionNotInspectLicenses:
            data?.inspectionRestriction?.notInspectLicenses ?? [],
        },
        true
      )
    }
    return initialValuesClear
  }, [isNew, data, closeModal])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = async values => {
    try {
      console.log('Entro submit')
      console.log({ values })
      if (showChangePassword || isNew) {
        if (!values?.pass || !values?.rePass) {
          easyToast('error', 'Debes ingresar ambas contraseñas')
          return
        }
        if (values.pass !== values.rePass) {
          easyToast('error', 'Las contraseñas no coinciden')
          return
        }
        if (values.pass.length < 6 || values.pass.length > 20) {
          easyToast('error', 'La contraseña debe ser mayor a 6 caracteres y menor a 20')
          return
        }
      }

      const fd = new FormData()
      deleteImage ? fd.append('image', null) : image && fd.append('image', image.raw)
      fd.append('fullname', values.fullname)
      fd.append('role', values.role)
      fd.append('activeUntil', values.activeUntil || null)
      fd.append('active', values.active)
      fd.append('password', showChangePassword || isNew ? values.pass : undefined)
      fd.append(
        'inspectionRestriction',
        JSON.stringify({
          vehicles: values.inspectionRestrictionVehicles,
          notInspectLicenses: values.inspectionRestrictionNotInspectLicenses,
        })
      )
      for (var pair of fd.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      if (!isNew) {
        fd.append('username', data.username)
        await putUser(fd)
      } else {
        fd.append('username', values.username)
        await postUser(fd)
      }

      easyToast('dark', isNew ? 'Usuario añadido correctamente' : 'Usuario editado correctamente')
      isValidFunction(closeModal) && closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error guardando el usuario. Inténtelo de nuevo')
    }
  }

  const modalContent = useMemo(
    () => (
      <div className="users-form" id="users-form">
        <div className="users-form__avatar-container">
          {(image || data?.avatar) && !deleteImage ? (
            <div className="users-form__avatar">
              <HoverTooltip title="Cambiar imagen" placement="top">
                <img
                  src={image?.src || `data:image/png;base64,${data?.avatar}`}
                  alt="Avatar"
                  onClick={() => imageInput?.current?.click()}
                  style={{ borderRadius: '0.4rem' }}
                />
              </HoverTooltip>
              {!image && (
                <div onClick={() => setDeleteImage(true)}>
                  <i className="flaticon-trash" /> Eliminar imagen
                </div>
              )}
            </div>
          ) : (
            <HoverTooltip title="Añadir imagen" placement="top">
              <div className="users-form__avatar">
                <i
                  className="flaticon-usuario-de-perfil"
                  onClick={() => imageInput?.current?.click()}
                />
              </div>
            </HoverTooltip>
          )}
        </div>
        <input
          ref={imageInput}
          style={{ display: 'none' }}
          accept="image/*"
          id="file-input"
          type="file"
          onChange={e =>
            setImage({
              raw: e.target.files[0],
              src: URL.createObjectURL(e.target.files[0]),
            })
          }
        />
        <Formik
          enableReinitialize
          initialValues={handleInitialValues()}
          validationSchema={userSchema(isNew, allUsers)}
          innerRef={form}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
            <form onSubmit={onSubmit}>
              <Switch
                label="Activo"
                value={values?.active}
                onChange={value => setFieldValue('active', value)}
              />
              <div className="users-form__form">
                {isNew && <Input className="form-input" name="username" label="Usuario" />}
                <Input className="form-input" name="fullname" label="Nombre completo" />
                <Select
                  id={isHimself ? 'rol' : ''}
                  label="Rol"
                  ErrorComponent={
                    <ErrorMessage className="form-error" name="role" component="small" />
                  }
                  name="rol"
                  options={roles}
                  value={roles.find(elem => elem.value === values?.role) || ''}
                  onChange={opt => setFieldValue('role', opt ? opt.value : '')}
                  isDisabled={isNew ? false : isHimself}
                  onBlur={() => setFieldTouched('role', true)}
                  error={errors.role}
                  touched={Boolean(touched.role)}
                />
                <Input label="Activo hasta" name="activeUntil" type="datetime-local" />
                <Select
                  ErrorComponent={
                    <ErrorMessage
                      className="form-error"
                      name="inspectionRestriction.vehicles"
                      component="small"
                    />
                  }
                  label="Vehículos que puede inspeccionar"
                  name="inspectionRestrictionVehicles"
                  isMulti={true}
                  onBlur={() => setFieldTouched('inspectionRestrictionVehicles', true)}
                  error={errors.inspectionRestrictionVehicles}
                  touched={Boolean(touched.inspectionRestrictionVehicles)}
                  options={filtersNamesOptions}
                  value={filtersNamesOptions.filter(option =>
                    values.inspectionRestrictionVehicles.includes(option.value)
                  )}
                  onChange={opt =>
                    setFieldValue(
                      'inspectionRestrictionVehicles',
                      opt.map(option => option.value)
                    )
                  }
                />
                <Select
                  ErrorComponent={
                    <ErrorMessage
                      className="form-error"
                      name="notInspectLicenses"
                      component="small"
                    />
                  }
                  label="Matrículas que no puede inspeccionar (Imparcialidad)"
                  name="inspectionRestrictionNotInspectLicenses"
                  isMulti={true}
                  onBlur={() => setFieldTouched('inspectionRestrictionNotInspectLicenses', true)}
                  error={errors.inspectionRestrictionNotInspectLicenses}
                  touched={Boolean(touched.inspectionRestrictionNotInspectLicenses)}
                  options={[]}
                  placeholder="Escribe para añadir una matrícula..."
                  noOptionsText="Añada la matrícula sin espacios ni guiones. Ej: 1234ABC"
                  isCreatable={true}
                  formatAddedOption={inputValue =>
                    inputValue.toUpperCase().trim().replace(/\s+/g, '')
                  }
                  value={values.inspectionRestrictionNotInspectLicenses.map(value => ({
                    label: value,
                    value,
                  }))}
                  onChange={opt =>
                    setFieldValue(
                      'inspectionRestrictionNotInspectLicenses',
                      opt.map(option => option.value)
                    )
                  }
                  letDropdown={false}
                />
              </div>
              {!showChangePassword && !isNew && (
                <MyButtonsCointainer>
                  <MyButton
                    text="Cambiar contraseña"
                    icon={<PasswordIcon />}
                    onClick={() => {
                      setShowChangePassword(true)
                      setTimeout(() => {
                        document.getElementById('modal')?.scroll({
                          top: document.getElementById('modal').scrollHeight,
                          behavior: 'smooth',
                        })
                      }, 200)
                    }}
                  />
                </MyButtonsCointainer>
              )}
              {(showChangePassword || isNew) && (
                <div className="users-form__change-password">
                  <Input label="Contraseña (6-20 caracteres)" type="password" name="pass" />
                  <Input label="Repita contraseña" type="password" name="rePass" />
                </div>
              )}
            </form>
          )}
        </Formik>
      </div>
    ),
    [
      image,
      data?.avatar,
      deleteImage,
      handleInitialValues,
      isNew,
      allUsers,
      onSubmit,
      isHimself,
      showChangePassword,
    ]
  )

  return (
    <Modal
      open={true}
      title={isNew ? 'Nuevo usuario' : `Editar ${data?.username}`}
      closeOnOverlay={false}
      loading={loading}
      onCloseButton={() => isValidFunction(closeModal) && closeModal()}
      onAccept={() => form?.current?.submitForm()}
      closeAfterAccept={false}
      acceptOnEnter={false}
      content={modalContent}
    />
  )
}

export default UsersForm
