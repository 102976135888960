// constantes
const initialData = {
  itv: {},
  filters: [],
  linkBetweenMachineAndManual: null,
  fees: null,
  hardwareByLine: null,
  complementaryEquipment: null,
  timedOutTests: null,
  showAnimatedLogo: false,
  modal: {},
}

// types
const SET_ITV_SUCCESS = 'SET_ITV_SUCCESS'
const SET_FILTERS_SUCCESS = 'SET_FILTERS_SUCCESS'
const SET_LINK_BEETWEEN_MACHINE_AND_MANUAL = 'SET_LINK_BEETWEEN_MACHINE_AND_MANUAL'
const SET_FEES = 'SET_FEES'
const SET_HARDWARE_BY_LINE_SUCCESS = 'SET_HARDWARE_BY_LINE_SUCCESS'
const SET_COMPLEMENTARY_EQUIPMENT_SUCCESS = 'SET_COMPLEMENTARY_EQUIPMENT_SUCCESS'
const SET_TIMED_OUT_TESTS_SUCCESS = 'SET_TIMED_OUT_TESTS_SUCCESS'
const SET_MODAL_SUCCESS = 'SET_MODAL_SUCCESS'
const SET_SHOW_ANIMATED_LOGO_SUCCESS = 'SET_SHOW_ANIMATED_LOGO_SUCCESS'
const RESET_CONFIG_DUCK_SUCCESS = 'RESET_CONFIG_DUCK_SUCCESS'

// reducer
export default function configReducer(state = initialData, action) {
  switch (action.type) {
    case SET_ITV_SUCCESS:
      return { ...state, itv: action.payload }
    case SET_FILTERS_SUCCESS:
      return { ...state, filters: action.payload }

    case SET_LINK_BEETWEEN_MACHINE_AND_MANUAL:
      return { ...state, linkBetweenMachineAndManual: action.payload }
    case SET_FEES:
      return { ...state, fees: action.payload }
    case SET_HARDWARE_BY_LINE_SUCCESS:
      return { ...state, hardwareByLine: action.payload }
    case SET_COMPLEMENTARY_EQUIPMENT_SUCCESS:
      return { ...state, complementaryEquipment: action.payload }
    case SET_TIMED_OUT_TESTS_SUCCESS:
      return { ...state, timedOutTests: action.payload }
    case SET_MODAL_SUCCESS:
      return { ...state, modal: action.payload }
    case SET_SHOW_ANIMATED_LOGO_SUCCESS:
      return { ...state, showAnimatedLogo: action.payload }
    case RESET_CONFIG_DUCK_SUCCESS:
      return undefined
    default:
      return state
  }
}

// actions

export const setReduxConfigItv = data => dispatch => {
  try {
    dispatch({
      type: SET_ITV_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxConfigFilters = data => dispatch => {
  try {
    dispatch({
      type: SET_FILTERS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxConfigLinkBetweenMachineAndManual = data => dispatch => {
  try {
    dispatch({
      type: SET_LINK_BEETWEEN_MACHINE_AND_MANUAL,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxConfigFees = data => dispatch => {
  try {
    dispatch({
      type: SET_FEES,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxConfigHardwareByLine = data => dispatch => {
  try {
    dispatch({
      type: SET_HARDWARE_BY_LINE_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxConfigComplementaryEquipment = data => dispatch => {
  try {
    dispatch({
      type: SET_COMPLEMENTARY_EQUIPMENT_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxConfigTimedOutTests = data => dispatch => {
  try {
    dispatch({
      type: SET_TIMED_OUT_TESTS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxConfigModal = data => (dispatch, getState) => {
  const modal = getState().config.modal
  if (!modal.open && data) modal.open = true
  try {
    dispatch({
      type: SET_MODAL_SUCCESS,
      payload: data ? { ...modal, ...data } : {},
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxConfigShowAnimatedLogo = data => dispatch => {
  try {
    dispatch({
      type: SET_SHOW_ANIMATED_LOGO_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const resetConfigDuck = data => dispatch => {
  try {
    dispatch({
      type: SET_TIMED_OUT_TESTS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}
