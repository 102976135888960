import React from 'react'

// MY IMPORTS
import Title from 'components/Others/Title/Title'
import CaptureImage from 'components/Inspection/CaptureImage/CaptureImage'
import Carousel from 'components/Inspection/InspectionImages/InspectionImages'

export default function Images() {
  return (
    <>
      <Title text={'IMÁGENES'} />
      <CaptureImage />
      <Carousel />
    </>
  )
}
