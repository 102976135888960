export const imageTypes = [
  {
    value: 'general',
    label: 'General',
  },
  {
    value: 'cuentakilometros',
    label: 'Cuentakilómetros',
  },
  {
    value: 'matricula',
    label: 'Matrícula',
  },
  {
    value: 'bastidor',
    label: 'Bastidor',
  },
  {
    value: 'vehiculo frontal',
    label: 'Vehiculo Frontal',
  },
  {
    value: 'vehiculo frontal diagonal izquierda',
    label: 'Vehiculo Frontal Diagonal Izquierda',
  },
  {
    value: 'vehiculo trasero',
    label: 'Vehiculo Trasero',
  },
  {
    value: 'vehiculo trasero',
    label: 'Vehiculo Trasero Diagonal Izquierda',
  },
]

export const euro = [
  {
    value: 3,
    label: '3 y anterior',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
  {
    value: 6,
    label: '6',
  },
]

export const tireEquivalence = [
  {
    label: 'Reglamento 30',
    options: [
      {
        label: '4.80-10',
        value: 490,
      },
      {
        label: '5.20-10',
        value: 508,
      },
      {
        label: '5.20-12',
        value: 558,
      },
      {
        label: '5.60-13',
        value: 600,
      },
      {
        label: '5.90-13',
        value: 616,
      },
      {
        label: '6.40-13',
        value: 642,
      },
      {
        label: '5.20-14',
        value: 612,
      },
      {
        label: '5.60-14',
        value: 626,
      },
      {
        label: '5.90-14',
        value: 642,
      },
      {
        label: '6.40-14',
        value: 666,
      },
      {
        label: '5.60-15',
        value: 650,
      },
      {
        label: '5.90-15',
        value: 668,
      },
      {
        label: '6.40-15',
        value: 692,
      },
      {
        label: '6.70-15',
        value: 710,
      },
      {
        label: '7.10-15',
        value: 724,
      },
      {
        label: '7.60-15',
        value: 742,
      },
      {
        label: '8.20-15',
        value: 760,
      },
      {
        label: '5.50-12',
        value: 552,
      },
      {
        label: '6.00-12',
        value: 574,
      },
      {
        label: '7.00-13',
        value: 644,
      },
      {
        label: '7.00-14',
        value: 668,
      },
      {
        label: '7.50-14',
        value: 688,
      },
      {
        label: '8.00-14',
        value: 702,
      },
      {
        label: '6.00-15 L',
        value: 650,
      },
      {
        label: '155-13/6.15-13',
        value: 582,
      },
      {
        label: '165-13/6.45-13',
        value: 600,
      },
      {
        label: '175-13/6.95-13',
        value: 610,
      },
      {
        label: '155-14/6.15-14',
        value: 608,
      },
      {
        label: '165-14/6.45-14',
        value: 626,
      },
      {
        label: '175-14/6.95-14',
        value: 638,
      },
      {
        label: '185-14/7.35-14',
        value: 654,
      },
      {
        label: '195-14/7.75-14',
        value: 670,
      },
      {
        label: '5.9-10',
        value: 483,
      },
      {
        label: '6.5-13',
        value: 586,
      },
      {
        label: '6.9-13',
        value: 600,
      },
      {
        label: '7.3-13',
        value: 614,
      },
      {
        label: '125 R 10',
        value: 459,
      },
      {
        label: '145 R 10',
        value: 492,
      },
      {
        label: '125 R 12',
        value: 510,
      },
      {
        label: '135 R 12',
        value: 522,
      },
      {
        label: '145 R 12',
        value: 542,
      },
      {
        label: '155 R 12',
        value: 550,
      },
      {
        label: '125 R 13',
        value: 536,
      },
      {
        label: '135 R 13',
        value: 548,
      },
      {
        label: '145 R 13',
        value: 566,
      },
      {
        label: '155 R 13',
        value: 578,
      },
      {
        label: '165 R 13',
        value: 596,
      },
      {
        label: '175 R 13',
        value: 608,
      },
      {
        label: '185 R 13',
        value: 624,
      },
      {
        label: '125 R 14',
        value: 562,
      },
      {
        label: '135 R 14',
        value: 574,
      },
      {
        label: '145 R 14',
        value: 590,
      },
      {
        label: '155 R 14',
        value: 604,
      },
      {
        label: '165 R 14',
        value: 622,
      },
      {
        label: '175 R 14',
        value: 634,
      },
      {
        label: '185 R 14',
        value: 650,
      },
      {
        label: '195 R 14',
        value: 666,
      },
      {
        label: '205 R 14',
        value: 686,
      },
      {
        label: '215 R 14',
        value: 700,
      },
      {
        label: '225 R 14',
        value: 714,
      },
      {
        label: '125 R 15',
        value: 588,
      },
      {
        label: '135 R 15',
        value: 600,
      },
      {
        label: '145 R 15',
        value: 616,
      },
      {
        label: '155 R 15',
        value: 630,
      },
      {
        label: '165 R 15',
        value: 646,
      },
      {
        label: '175 R 15',
        value: 660,
      },
      {
        label: '185 R 15',
        value: 674,
      },
      {
        label: '195 R 15',
        value: 690,
      },
      {
        label: '205 R 15',
        value: 710,
      },
      {
        label: '215 R 15',
        value: 724,
      },
      {
        label: '225 R 15',
        value: 738,
      },
      {
        label: '235 R 15',
        value: 752,
      },
      {
        label: '175 R 16',
        value: 686,
      },
      {
        label: '185 R 16',
        value: 698,
      },
      {
        label: '205 R 16',
        value: 736,
      },
      {
        label: '280/45 R 415',
        value: 661,
      },
    ],
  },
  {
    label: 'Reglamento 54',
    options: [
      {
        label: '4.00R8',
        value: 414,
      },
      {
        label: '4.00R10',
        value: 466,
      },
      {
        label: '4.00R12',
        value: 517,
      },
      {
        label: '4.50R8',
        value: 439,
      },
      {
        label: '4.50R10',
        value: 490,
      },
      {
        label: '4.50R12',
        value: 545,
      },
      {
        label: '5.00R8',
        value: 467,
      },
      {
        label: '5.00R10',
        value: 516,
      },
      {
        label: '5.00R12',
        value: 568,
      },
      {
        label: '6.00R9',
        value: 540,
      },
      {
        label: '6.00R14C',
        value: 626,
      },
      {
        label: '6.00R16',
        value: 728,
      },
      {
        label: '6.50R10',
        value: 588,
      },
      {
        label: '6.50R14C',
        value: 640,
      },
      {
        label: '6.50R16',
        value: 742,
      },
      {
        label: '6.50R20',
        value: 860,
      },
      {
        label: '7.00R12',
        value: 672,
      },
      {
        label: '7.00R14C',
        value: 650,
      },
      {
        label: '7.00R15',
        value: 746,
      },
      {
        label: '7.00R16C',
        value: 778,
      },
      {
        label: '7.00R16',
        value: 784,
      },
      {
        label: '7.00R20',
        value: 892,
      },
      {
        label: '7.50R10',
        value: 645,
      },
      {
        label: '7.50R14C',
        value: 686,
      },
      {
        label: '7.50R15',
        value: 772,
      },
      {
        label: '7.50R16',
        value: 802,
      },
      {
        label: '7.50R17',
        value: 852,
      },
      {
        label: '7.50R20',
        value: 928,
      },
      {
        label: '8.25R15',
        value: 836,
      },
      {
        label: '8.25R16',
        value: 860,
      },
      {
        label: '8.25R17',
        value: 886,
      },
      {
        label: '8.25R20',
        value: 962,
      },
      {
        label: '9.00R15',
        value: 840,
      },
      {
        label: '9.00R16',
        value: 912,
      },
      {
        label: '9.00R20',
        value: 1018,
      },
      {
        label: '10.00R15',
        value: 918,
      },
      {
        label: '10.00R20',
        value: 1052,
      },
      {
        label: '10.00R22',
        value: 1102,
      },
      {
        label: '11.00R16',
        value: 980,
      },
      {
        label: '11.00R20',
        value: 1082,
      },
      {
        label: '11.00R22',
        value: 1132,
      },
      {
        label: '11.00R24',
        value: 1182,
      },
      {
        label: '12.00R20',
        value: 1122,
      },
      {
        label: '12.00R22',
        value: 1174,
      },
      {
        label: '12.00R24',
        value: 1226,
      },
      {
        label: '13.00R20',
        value: 1176,
      },
      {
        label: '14.00R20',
        value: 1238,
      },
      {
        label: '14.00R24',
        value: 1340,
      },
      {
        label: '16.00R20',
        value: 1370,
      },
      {
        label: '12/80 R 20',
        value: 1008,
      },
      {
        label: '13/80 R 20',
        value: 1048,
      },
      {
        label: '14/80 R 20',
        value: 1090,
      },
      {
        label: '14/80 R 24',
        value: 1192,
      },
      {
        label: '14.75/80 R 20',
        value: 1124,
      },
      {
        label: '15.5/80 R 20',
        value: 1158,
      },
      {
        label: '7.50 R 18 MPT',
        value: 885,
      },
      {
        label: '10.5 R 18 MPT',
        value: 905,
      },
      {
        label: '10.5 R 20 MPT',
        value: 955,
      },
      {
        label: '12.5 R 18 MPT',
        value: 990,
      },
      {
        label: '12.5 R 20 MPT',
        value: 1040,
      },
      {
        label: '14.5 R 20 MPT',
        value: 1095,
      },
      {
        label: '14.5 R 24 MPT',
        value: 1195,
      },
      {
        label: '7 R 17.5',
        value: 752,
      },
      {
        label: '7 R 19.5',
        value: 800,
      },
      {
        label: '8 R 17.5',
        value: 784,
      },
      {
        label: '8 R 19.5',
        value: 856,
      },
      {
        label: '8 R 22.5',
        value: 936,
      },
      {
        label: '8.5 R 17.5',
        value: 802,
      },
      {
        label: '9 R 17.5',
        value: 820,
      },
      {
        label: '9 R 19.5',
        value: 894,
      },
      {
        label: '9 R 22.5',
        value: 970,
      },
      {
        label: '9.5 R 17.5',
        value: 842,
      },
      {
        label: '9.5 R 19.5',
        value: 916,
      },
      {
        label: '10 R 17.5',
        value: 858,
      },
      {
        label: '10 R 19.5',
        value: 936,
      },
      {
        label: '10 R 22.5',
        value: 1020,
      },
      {
        label: '11 R 22.5',
        value: 1050,
      },
      {
        label: '11 R 24.5',
        value: 1100,
      },
      {
        label: '12 R 22.5',
        value: 1084,
      },
      {
        label: '13 R 22.5',
        value: 1124,
      },
      {
        label: '15 R 19.5',
        value: 998,
      },
      {
        label: '15 R 22.5',
        value: 1074,
      },
      {
        label: '16.5 R 19.5',
        value: 1046,
      },
      {
        label: '16.5 R 22.5',
        value: 1122,
      },
      {
        label: '18 R 19.5',
        value: 1082,
      },
      {
        label: '18 R 22.5',
        value: 1158,
      },
      {
        label: '10/70 R 22.5',
        value: 928,
      },
      {
        label: '11/70 R 22.5',
        value: 962,
      },
      {
        label: '12/70 R 22.5',
        value: 1000,
      },
      {
        label: '13/70 R 22.5',
        value: 1033,
      },
      {
        label: '145 R 10 C',
        value: 492,
      },
      {
        label: '145 R 12 C',
        value: 542,
      },
      {
        label: '145 R 13 C',
        value: 566,
      },
      {
        label: '145 R 14 C',
        value: 590,
      },
      {
        label: '145 R 15 C',
        value: 616,
      },
      {
        label: '155 R 12 C',
        value: 550,
      },
      {
        label: '155 R 13 C',
        value: 578,
      },
      {
        label: '155 R 14 C',
        value: 604,
      },
      {
        label: '165 R 13 C',
        value: 596,
      },
      {
        label: '165 R 14 C',
        value: 622,
      },
      {
        label: '165 R 15 C',
        value: 646,
      },
      {
        label: '175 R 13 C',
        value: 608,
      },
      {
        label: '175 R 14 C',
        value: 634,
      },
      {
        label: '175 R 16 C',
        value: 684,
      },
      {
        label: '185 R 13 C',
        value: 624,
      },
      {
        label: '185 R 14 C',
        value: 650,
      },
      {
        label: '185 R 15 C',
        value: 674,
      },
      {
        label: '185 R 16 C',
        value: 700,
      },
      {
        label: '195 R 14 C',
        value: 666,
      },
      {
        label: '195 R 15 C',
        value: 690,
      },
      {
        label: '195 R 16 C',
        value: 716,
      },
      {
        label: '205 R 14 C',
        value: 686,
      },
      {
        label: '205 R 15 C',
        value: 710,
      },
      {
        label: '205 R 16 C',
        value: 736,
      },
      {
        label: '215 R 14 C',
        value: 700,
      },
      {
        label: '215 R 15 C',
        value: 724,
      },
      {
        label: '215 R 16 C',
        value: 750,
      },
      {
        label: '245 R 16 C',
        value: 798,
      },
      {
        label: '17 R 15 C',
        value: 678,
      },
      {
        label: '17 R 380 C',
        value: 678,
      },
      {
        label: '17 R 400 C',
        value: 400,
      },
      {
        label: '19 R 400 C',
        value: 400,
      },
      {
        label: '5.60 R 12 C',
        value: 570,
      },
      {
        label: '6.40 R 13 C',
        value: 648,
      },
      {
        label: '6.70 R 13 C',
        value: 660,
      },
      {
        label: '6.70 R 14 C',
        value: 688,
      },
      {
        label: '6.70 R 15 C',
        value: 712,
      },
      {
        label: '15×4 1/2-8',
        value: 385,
      },
      {
        label: '16×6-8',
        value: 425,
      },
      {
        label: '18×7',
        value: 462,
      },
      {
        label: '18×7-8',
        value: 462,
      },
      {
        label: '21×8-9',
        value: 535,
      },
      {
        label: '21×4',
        value: 565,
      },
      {
        label: '22×4 1/2',
        value: 595,
      },
      {
        label: '23×5',
        value: 635,
      },
      {
        label: '23×9-10',
        value: 595,
      },
      {
        label: '25×6',
        value: 680,
      },
      {
        label: '27×10-12',
        value: 690,
      },
      {
        label: '28×9-15',
        value: 707,
      },
      {
        label: '200-15',
        value: 730,
      },
      {
        label: '250-15',
        value: 735,
      },
      {
        label: '300-15',
        value: 840,
      },
      {
        label: '6.00-16LT',
        value: 732,
      },
      {
        label: '6.50-16LT',
        value: 755,
      },
      {
        label: '6.70-16LT',
        value: 722,
      },
      {
        label: '7.00-13LT',
        value: 647,
      },
      {
        label: '7.00-14LT',
        value: 670,
      },
      {
        label: '7.00-15LT',
        value: 752,
      },
      {
        label: '7.00-16LT',
        value: 778,
      },
      {
        label: '7.10-15LT',
        value: 738,
      },
      {
        label: '7.50-15LT',
        value: 782,
      },
      {
        label: '7.50-16LT',
        value: 808,
      },
      {
        label: '8.25-16LT',
        value: 859,
      },
      {
        label: '9.00-16LT',
        value: 890,
      },
      {
        label: 'G78-15LT',
        value: 711,
      },
      {
        label: 'H78-15LT',
        value: 727,
      },
      {
        label: 'L78-15LT',
        value: 749,
      },
      {
        label: 'L78-16LT',
        value: 775,
      },
      {
        label: '7-14.5LT',
        value: 677,
      },
      {
        label: '8-14.5LT',
        value: 707,
      },
      {
        label: '9-14.5LT',
        value: 711,
      },
      {
        label: '7-17.5LT',
        value: 758,
      },
      {
        label: '8-17.5LT',
        value: 788,
      },
      {
        label: '9-15LT',
        value: 744,
      },
      {
        label: '10-15LT',
        value: 773,
      },
      {
        label: '11-15LT',
        value: 777,
      },
      {
        label: '24×7.50-13LT',
        value: 597,
      },
      {
        label: '27×8.50-14LT',
        value: 674,
      },
      {
        label: '28×8.50-15LT',
        value: 699,
      },
      {
        label: '29×9.50-15LT',
        value: 724,
      },
      {
        label: '30×9.50-15LT',
        value: 750,
      },
      {
        label: '31×10.50-15LT',
        value: 775,
      },
      {
        label: '31×11.50-15LT',
        value: 775,
      },
      {
        label: '31×13.50-15LT',
        value: 775,
      },
      {
        label: '31×15.50-15LT',
        value: 775,
      },
      {
        label: '32×11.50-15LT',
        value: 801,
      },
      {
        label: '33×12.50-15LT',
        value: 826,
      },
      {
        label: '35×12.50-15LT',
        value: 877,
      },
      {
        label: '37×12.50-15LT',
        value: 928,
      },
      {
        label: '37×14.50-15LT',
        value: 928,
      },
      {
        label: '8.00-16.5LT',
        value: 720,
      },
      {
        label: '8.75-16.5LT',
        value: 748,
      },
      {
        label: '9.50-16.5LT',
        value: 776,
      },
      {
        label: '10-16.5LT',
        value: 762,
      },
      {
        label: '12-16.5LT',
        value: 818,
      },
      {
        label: '30×9.50-16.5LT',
        value: 750,
      },
      {
        label: '31×10.50-16.5LT',
        value: 775,
      },
      {
        label: '33×12.50-16.5LT',
        value: 826,
      },
      {
        label: '37×12.50-16.5LT',
        value: 928,
      },
      {
        label: '37×14.50-16.5LT',
        value: 928,
      },
      {
        label: '33×9.50 R15LT',
        value: 826,
      },
      {
        label: '35×12.50 R16.5LT',
        value: 877,
      },
      {
        label: '37×12.50 R17LT',
        value: 928,
      },
      {
        label: '6.50-20',
        value: 878,
      },
      {
        label: '7.00-15TR',
        value: 777,
      },
      {
        label: '7.00-18',
        value: 853,
      },
      {
        label: '7.00-20',
        value: 904,
      },
      {
        label: '7.50-15TR',
        value: 808,
      },
      {
        label: '7.50-17',
        value: 859,
      },
      {
        label: '7.50-18',
        value: 884,
      },
      {
        label: '7.50-20',
        value: 935,
      },
      {
        label: '8.25-15TR',
        value: 847,
      },
      {
        label: '8.25-20',
        value: 974,
      },
      {
        label: '9.00-15TR',
        value: 891,
      },
      {
        label: '9.00-20',
        value: 1019,
      },
      {
        label: '10.00-15TR',
        value: 927,
      },
      {
        label: '10.00-20',
        value: 1054,
      },
      {
        label: '10.00-22',
        value: 1104,
      },
      {
        label: '11.00-20',
        value: 1085,
      },
      {
        label: '11.00-22',
        value: 1135,
      },
      {
        label: '11.00-24',
        value: 1186,
      },
      {
        label: '11.50-20',
        value: 1085,
      },
      {
        label: '12.00-20',
        value: 1125,
      },
      {
        label: '12.00-24',
        value: 1226,
      },
      {
        label: '14.00-20',
        value: 1241,
      },
      {
        label: '14.00-24',
        value: 1343,
      },
      {
        label: '10.00-20ML',
        value: 1073,
      },
      {
        label: '11.00-22ML',
        value: 1155,
      },
      {
        label: '13.00-24ML',
        value: 1302,
      },
      {
        label: '14.00-20ML',
        value: 1266,
      },
      {
        label: '14.00-24ML',
        value: 1368,
      },
      {
        label: '15-19.5ML',
        value: 1019,
      },
      {
        label: '24 R 21',
        value: 1372,
      },
      {
        label: '8-19.5',
        value: 859,
      },
      {
        label: '8-22.5',
        value: 935,
      },
      {
        label: '9-22.5',
        value: 974,
      },
      {
        label: '10-22.5',
        value: 1019,
      },
      {
        label: '11-22.5',
        value: 1054,
      },
      {
        label: '11-24.5',
        value: 1104,
      },
      {
        label: '12-22.5',
        value: 1085,
      },
      {
        label: '12-24.5',
        value: 1135,
      },
      {
        label: '12.5-22.5',
        value: 1085,
      },
      {
        label: '12.5-24.5',
        value: 1135,
      },
      {
        label: '14-17.5',
        value: 907,
      },
      {
        label: '15-19.5',
        value: 1005,
      },
      {
        label: '15-22.5',
        value: 1082,
      },
      {
        label: '16.5-22.5',
        value: 1128,
      },
      {
        label: '18-19.5',
        value: 1080,
      },
      {
        label: '18-22.5',
        value: 1158,
      },
    ],
  },
  {
    label: 'Reglamento 75',
    options: [
      {
        label: '2.50-8',
        value: 338,
      },
      {
        label: '2.50-9',
        value: 364,
      },
      {
        label: '2.50-10',
        value: 389,
      },
      {
        label: '2.50-12',
        value: 440,
      },
      {
        label: '2.75-8',
        value: 348,
      },
      {
        label: '2.75-9',
        value: 374,
      },
      {
        label: '2.75-10',
        value: 399,
      },
      {
        label: '2.75-12',
        value: 450,
      },
      {
        label: '3.00-4',
        value: 251,
      },
      {
        label: '3.00-5',
        value: 276,
      },
      {
        label: '3.00-6',
        value: 301,
      },
      {
        label: '3.00-7',
        value: 327,
      },
      {
        label: '3.00-8',
        value: 362,
      },
      {
        label: '3.00-9',
        value: 388,
      },
      {
        label: '3.00-10',
        value: 413,
      },
      {
        label: '3.00-12',
        value: 464,
      },
      {
        label: '3.25-8',
        value: 372,
      },
      {
        label: '3.25-9',
        value: 398,
      },
      {
        label: '3.25-10',
        value: 424,
      },
      {
        label: '3.25-12',
        value: 475,
      },
      {
        label: '3.50-4',
        value: 274,
      },
      {
        label: '3.50-5',
        value: 299,
      },
      {
        label: '3.50-6',
        value: 324,
      },
      {
        label: '3.50-7',
        value: 350,
      },
      {
        label: '3.50-8',
        value: 386,
      },
      {
        label: '3.50-9',
        value: 412,
      },
      {
        label: '3.50-10',
        value: 437,
      },
      {
        label: '3.50-12',
        value: 488,
      },
      {
        label: '4.00-5',
        value: 326,
      },
      {
        label: '4.00-6',
        value: 351,
      },
      {
        label: '4.00-7',
        value: 377,
      },
      {
        label: '4.00-8',
        value: 415,
      },
      {
        label: '4.00-10',
        value: 466,
      },
      {
        label: '4,00-12',
        value: 517,
      },
      {
        label: '4.50-6',
        value: 376,
      },
      {
        label: '4.50-7',
        value: 402,
      },
      {
        label: '4.50-8',
        value: 442,
      },
      {
        label: '4.50-9',
        value: 468,
      },
      {
        label: '4.50-10',
        value: 493,
      },
      {
        label: '4.50-12',
        value: 544,
      },
      {
        label: '5.00-8',
        value: 465,
      },
      {
        label: '5.00-10',
        value: 516,
      },
      {
        label: '5.00-12',
        value: 567,
      },
      {
        label: '6.00-6',
        value: 436,
      },
      {
        label: '6.00-7',
        value: 462,
      },
      {
        label: '6.00-8',
        value: 506,
      },
      {
        label: '6,00-9',
        value: 532,
      },
      {
        label: '2-12',
        value: 417,
      },
      {
        label: '2-1/2-12',
        value: 431,
      },
      {
        label: '2-1/2-8',
        value: 345,
      },
      {
        label: '2-1/2-9',
        value: 371,
      },
      {
        label: '2-3/4-9',
        value: 381,
      },
      {
        label: '3-10',
        value: 418,
      },
      {
        label: '3-12',
        value: 469,
      },
      {
        label: '1 3/4-19',
        value: 589,
      },
      {
        label: '2-14',
        value: 468,
      },
      {
        label: '2-15',
        value: 493,
      },
      {
        label: '2-16',
        value: 518,
      },
      {
        label: '2-17',
        value: 544,
      },
      {
        label: '2-18',
        value: 569,
      },
      {
        label: '2-19',
        value: 595,
      },
      {
        label: '2-20',
        value: 620,
      },
      {
        label: '2-21',
        value: 645,
      },
      {
        label: '2-22',
        value: 670,
      },
      {
        label: '2 1/4-14',
        value: 482,
      },
      {
        label: '2 1/4-15',
        value: 507,
      },
      {
        label: '2 1/4-16',
        value: 532,
      },
      {
        label: '2 1/4-17',
        value: 558,
      },
      {
        label: '2 1/4-18',
        value: 583,
      },
      {
        label: '2 1/4-19',
        value: 609,
      },
      {
        label: '2 1/4-20',
        value: 634,
      },
      {
        label: '2 1/4-21',
        value: 659,
      },
      {
        label: '2 1/4-22',
        value: 685,
      },
      {
        label: '2 1/2-14',
        value: 498,
      },
      {
        label: '2 1/2-15',
        value: 523,
      },
      {
        label: '2 1/2-16',
        value: 548,
      },
      {
        label: '2 1/2-17',
        value: 574,
      },
      {
        label: '2 1/2-18',
        value: 599,
      },
      {
        label: '2 1/2-19',
        value: 625,
      },
      {
        label: '2 1/2-20',
        value: 650,
      },
      {
        label: '2 1/2-21',
        value: 675,
      },
      {
        label: '2 1/2-22',
        value: 701,
      },
      {
        label: '2 3/4-14',
        value: 508,
      },
      {
        label: '2 3/4-15',
        value: 533,
      },
      {
        label: '2 3/4-16',
        value: 558,
      },
      {
        label: '2 3/4-17',
        value: 584,
      },
      {
        label: '2 3/4-18',
        value: 609,
      },
      {
        label: '2 3/4-19',
        value: 635,
      },
      {
        label: '2 3/4-20',
        value: 660,
      },
      {
        label: '2 3/4-21',
        value: 685,
      },
      {
        label: '2 3/4-22',
        value: 711,
      },
      {
        label: '3-16',
        value: 570,
      },
      {
        label: '3-17',
        value: 596,
      },
      {
        label: '3-18',
        value: 621,
      },
      {
        label: '3-19',
        value: 647,
      },
      {
        label: '3 1/4-16',
        value: 586,
      },
      {
        label: '3 1/4-17',
        value: 612,
      },
      {
        label: '3 1/4-18',
        value: 637,
      },
      {
        label: '3 1/4-19',
        value: 663,
      },
      {
        label: '2.00-14',
        value: 466,
      },
      {
        label: '2.00-15',
        value: 491,
      },
      {
        label: '2.00-16',
        value: 516,
      },
      {
        label: '2.00-17',
        value: 542,
      },
      {
        label: '2.00-18',
        value: 567,
      },
      {
        label: '2.00-19',
        value: 593,
      },
      {
        label: '2.25-14',
        value: 480,
      },
      {
        label: '2.25-15',
        value: 505,
      },
      {
        label: '2.25-16',
        value: 530,
      },
      {
        label: '2.25-17',
        value: 556,
      },
      {
        label: '2.25-18',
        value: 581,
      },
      {
        label: '2.25-19',
        value: 607,
      },
      {
        label: '2.50-14',
        value: 492,
      },
      {
        label: '2.50-15',
        value: 517,
      },
      {
        label: '2.50-16',
        value: 542,
      },
      {
        label: '2.50-17',
        value: 568,
      },
      {
        label: '2.50-18',
        value: 593,
      },
      {
        label: '2.50-19',
        value: 619,
      },
      {
        label: '2.50-21',
        value: 669,
      },
      {
        label: '2.75-14',
        value: 512,
      },
      {
        label: '2.75-15',
        value: 537,
      },
      {
        label: '2.75-16',
        value: 562,
      },
      {
        label: '2.75-17',
        value: 588,
      },
      {
        label: '2.75-18',
        value: 613,
      },
      {
        label: '2.75-19',
        value: 639,
      },
      {
        label: '2.75-21',
        value: 689,
      },
      {
        label: '3.00-14',
        value: 526,
      },
      {
        label: '3.00-15',
        value: 551,
      },
      {
        label: '3.00-16',
        value: 576,
      },
      {
        label: '3.00-17',
        value: 602,
      },
      {
        label: '3.00-18',
        value: 627,
      },
      {
        label: '3.00-19',
        value: 653,
      },
      {
        label: '3.00-21',
        value: 703,
      },
      {
        label: '3.00-23',
        value: 754,
      },
      {
        label: '3.25-14',
        value: 538,
      },
      {
        label: '3.25-15',
        value: 563,
      },
      {
        label: '3.25-16',
        value: 588,
      },
      {
        label: '3.25-17',
        value: 614,
      },
      {
        label: '3.25-18',
        value: 639,
      },
      {
        label: '3.25-19',
        value: 665,
      },
      {
        label: '3.25-21',
        value: 715,
      },
      {
        label: '3.50-14',
        value: 548,
      },
      {
        label: '3.50-15',
        value: 573,
      },
      {
        label: '3.50-16',
        value: 598,
      },
      {
        label: '3.50-17',
        value: 624,
      },
      {
        label: '3.50-18',
        value: 649,
      },
      {
        label: '3.50-19',
        value: 675,
      },
      {
        label: '3.50-21',
        value: 725,
      },
      {
        label: '3.75-16',
        value: 610,
      },
      {
        label: '3.75-17',
        value: 636,
      },
      {
        label: '3.75-18',
        value: 661,
      },
      {
        label: '3.75-19',
        value: 687,
      },
      {
        label: '4.00-16',
        value: 620,
      },
      {
        label: '4.00-17',
        value: 646,
      },
      {
        label: '4.00-18',
        value: 671,
      },
      {
        label: '4.00-19',
        value: 697,
      },
      {
        label: '4.25-16',
        value: 632,
      },
      {
        label: '4.25-17',
        value: 658,
      },
      {
        label: '4.25-18',
        value: 683,
      },
      {
        label: '4.25-19',
        value: 709,
      },
      {
        label: '4.50-16',
        value: 640,
      },
      {
        label: '4.50-17',
        value: 666,
      },
      {
        label: '4.50-18',
        value: 691,
      },
      {
        label: '4.50-19',
        value: 717,
      },
      {
        label: '5.00-16',
        value: 666,
      },
      {
        label: '5.00-17',
        value: 692,
      },
      {
        label: '5.00-18',
        value: 717,
      },
      {
        label: '5.00-19',
        value: 743,
      },
      {
        label: '3.60-18',
        value: 615,
      },
      {
        label: '2.15',
        value: 102,
      },
      {
        label: '3.60-19',
        value: 641,
      },
      {
        label: '4.10-18',
        value: 641,
      },
      {
        label: '2.50',
        value: 119,
      },
      {
        label: '4.10-19',
        value: 667,
      },
      {
        label: '5.10-16',
        value: 625,
      },
      {
        label: '5.10-17',
        value: 651,
      },
      {
        label: '5.10-18',
        value: 676,
      },
      {
        label: '4.25/85-18',
        value: 659,
      },
      {
        label: '4.60-16',
        value: 604,
      },
      {
        label: '4.60-17',
        value: 630,
      },
      {
        label: '4.60-18',
        value: 654,
      },
      {
        label: '6.10-16',
        value: 658,
      },
      {
        label: '3.00-8C',
        value: 369,
      },
      {
        label: '3.00-10C',
        value: 420,
      },
      {
        label: '3.00-12C',
        value: 471,
      },
      {
        label: '3.50-8C',
        value: 386,
      },
      {
        label: '3.50-10C',
        value: 437,
      },
      {
        label: '3.50-12C',
        value: 488,
      },
      {
        label: '4.00-8C',
        value: 415,
      },
      {
        label: '4.00-10C',
        value: 466,
      },
      {
        label: '4.00-12C',
        value: 517,
      },
      {
        label: '4.50-8C',
        value: 439,
      },
      {
        label: '4.50-10C',
        value: 490,
      },
      {
        label: '4.50-12C',
        value: 541,
      },
      {
        label: '5.00-8C',
        value: 465,
      },
      {
        label: '5.00-10C',
        value: 516,
      },
      {
        label: '5.00-12C',
        value: 567,
      },
      {
        label: '5.4-10',
        value: 481,
      },
      {
        label: '5.4-12',
        value: 532,
      },
      {
        label: '5.4-14',
        value: 582,
      },
      {
        label: '5.4-16',
        value: 633,
      },
      {
        label: '6.7-10',
        value: 541,
      },
      {
        label: '6.7-12',
        value: 592,
      },
      {
        label: '6.7-14',
        value: 642,
      },
      {
        label: 'MH90-21',
        value: 686,
      },
      {
        label: 'MJ90-18',
        value: 625,
      },
      {
        label: 'MJ90-19',
        value: 650,
      },
      {
        label: 'ML90-18',
        value: 634,
      },
      {
        label: 'ML90-19',
        value: 659,
      },
      {
        label: 'MM90-19',
        value: 669,
      },
      {
        label: 'MN90-18',
        value: 662,
      },
      {
        label: 'MP90-18',
        value: 673,
      },
      {
        label: 'MR90-18',
        value: 687,
      },
      {
        label: 'MS90-17',
        value: 667,
      },
      {
        label: 'MT90-16',
        value: 650,
      },
      {
        label: 'MT90-17',
        value: 675,
      },
      {
        label: 'MU90-15M/C',
        value: 642,
      },
      {
        label: 'MU90-16',
        value: 667,
      },
      {
        label: 'MV90-15M/C',
        value: 651,
      },
      {
        label: 'MP85-18',
        value: 660,
      },
      {
        label: 'MR85-16',
        value: 623,
      },
      {
        label: 'MS85-18',
        value: 682,
      },
      {
        label: 'MT85-18',
        value: 688,
      },
      {
        label: 'MU85 16M/C',
        value: 658,
      },
      {
        label: 'MV85-15M/C',
        value: 635,
      },
    ],
  },
  {
    label: 'Reglamento 106',
    options: [
      {
        label: '4,00 - 9',
        value: 460,
      },
      {
        label: '4,00 - 12',
        value: 535,
      },
      {
        label: '4,00 - 15',
        value: 610,
      },
      {
        label: '4,00 - 16',
        value: 630,
      },
      {
        label: '4,00 - 19',
        value: 712,
      },
      {
        label: '4,50 - 10',
        value: 505,
      },
      {
        label: '4,50 - 16',
        value: 655,
      },
      {
        label: '4,50 - 19',
        value: 736,
      },
      {
        label: '5,00 - 10',
        value: 530,
      },
      {
        label: '5,00 - 12',
        value: 580,
      },
      {
        label: '5,00 - 15',
        value: 655,
      },
      {
        label: '5,00 - 16',
        value: 680,
      },
      {
        label: '5,50 - 16',
        value: 710,
      },
      {
        label: '6,00 - 14',
        value: 688,
      },
      {
        label: '6,00 - 16',
        value: 735,
      },
      {
        label: '6,00 - 18',
        value: 790,
      },
      {
        label: '6,00 - 19',
        value: 814,
      },
      {
        label: '6,00 - 20',
        value: 840,
      },
      {
        label: '6,50 - 10',
        value: 608,
      },
      {
        label: '6,50 - 16',
        value: 760,
      },
      {
        label: '6,50 - 20',
        value: 865,
      },
      {
        label: '7,50 - 16',
        value: 805,
      },
      {
        label: '7,50 - 18',
        value: 860,
      },
      {
        label: '7,50 - 20',
        value: 915,
      },
      {
        label: '8,00 - 16',
        value: 813,
      },
      {
        label: '9,00 - 16',
        value: 855,
      },
      {
        label: '9,50 - 20',
        value: 978,
      },
      {
        label: '10,00 - 16',
        value: 895,
      },
      {
        label: '11,00 - 16',
        value: 965,
      },
      {
        label: '11,00 - 24',
        value: 1170,
      },
      {
        label: '7,5L - 15',
        value: 745,
      },
      {
        label: '8,25/85 - 15',
        value: 745,
      },
      {
        label: '9,5L - 15',
        value: 785,
      },
      {
        label: '9,5/85 - 15',
        value: 785,
      },
      {
        label: '11L - 15',
        value: 815,
      },
      {
        label: '11,5/75 - 15',
        value: 815,
      },
      {
        label: '7,5L - 16',
        value: 746,
      },
      {
        label: '11L - 16',
        value: 840,
      },
      {
        label: '14L - 16,1',
        value: 985,
      },
      {
        label: '14,0/80 - 16,1',
        value: 985,
      },
      {
        label: '14,5/75 - 16,1',
        value: 940,
      },
      {
        label: '16,5L - 16,1',
        value: 1072,
      },
      {
        label: '4,00 - 7',
        value: 410,
      },
      {
        label: '4,00 - 8',
        value: 435,
      },
      {
        label: '4,00-10',
        value: 485,
      },
      {
        label: '4,00-18',
        value: 690,
      },
      {
        label: '5,0 -10',
        value: 505,
      },
      {
        label: '5,00-10',
        value: 530,
      },
      {
        label: '5,00-12',
        value: 580,
      },
      {
        label: '5,00-15',
        value: 645,
      },
      {
        label: '6,00-12',
        value: 635,
      },
      {
        label: '6,00-16',
        value: 735,
      },
      {
        label: '6,5-15',
        value: 685,
      },
      {
        label: '6,50-16',
        value: 760,
      },
      {
        label: '7,50-18',
        value: 860,
      },
      {
        label: '8,00-20',
        value: 965,
      },
      {
        label: '5-12',
        value: 545,
      },
      {
        label: '5-14',
        value: 595,
      },
      {
        label: '5-26',
        value: 900,
      },
      {
        label: '6-10',
        value: 550,
      },
      {
        label: '6-12',
        value: 600,
      },
      {
        label: '6-14',
        value: 650,
      },
      {
        label: '7-14',
        value: 690,
      },
      {
        label: '7-16',
        value: 740,
      },
      {
        label: '8-16',
        value: 790,
      },
      {
        label: '8-18',
        value: 840,
      },
      {
        label: '7,2-20',
        value: 845,
      },
      {
        label: '7,2-24',
        value: 945,
      },
      {
        label: '7,2-30',
        value: 1095,
      },
      {
        label: '7,2-36',
        value: 1250,
      },
      {
        label: '7,2-40',
        value: 1350,
      },
      {
        label: '8,3-16',
        value: 790,
      },
      {
        label: '8,3-20',
        value: 890,
      },
      {
        label: '8,3-22',
        value: 940,
      },
      {
        label: '8,3-24',
        value: 995,
      },
      {
        label: '8,3-26',
        value: 1045,
      },
      {
        label: '8,3-28',
        value: 1095,
      },
      {
        label: '8,3-32',
        value: 1195,
      },
      {
        label: '8,3-36',
        value: 1300,
      },
      {
        label: '8,3-38',
        value: 1350,
      },
      {
        label: '8,3-42',
        value: 1450,
      },
      {
        label: '8,3-44',
        value: 1500,
      },
      {
        label: '9,5-16',
        value: 845,
      },
      {
        label: '9,5-18',
        value: 895,
      },
      {
        label: '9,5-20',
        value: 945,
      },
      {
        label: '9,5-22',
        value: 995,
      },
      {
        label: '9,5-24',
        value: 1050,
      },
      {
        label: '9,5-26',
        value: 1100,
      },
      {
        label: '9,5-28',
        value: 1140,
      },
      {
        label: '9,5-32',
        value: 1250,
      },
      {
        label: '9,5-36',
        value: 1355,
      },
      {
        label: '9,5-38',
        value: 1405,
      },
      {
        label: '9,5-42',
        value: 1505,
      },
      {
        label: '9,5-44',
        value: 1555,
      },
      {
        label: '9,5-48',
        value: 1655,
      },
      {
        label: '11,2-18',
        value: 955,
      },
      {
        label: '11,2-20',
        value: 1005,
      },
      {
        label: '11,2-24',
        value: 1105,
      },
      {
        label: '11,2-26',
        value: 1155,
      },
      {
        label: '11,2-28',
        value: 1205,
      },
      {
        label: '11,2-36',
        value: 1410,
      },
      {
        label: '11,2-38',
        value: 1460,
      },
      {
        label: '11,2-42',
        value: 1555,
      },
      {
        label: '11,2-44',
        value: 1610,
      },
      {
        label: '11,2-48',
        value: 1710,
      },
      {
        label: '12,4-16',
        value: 956,
      },
      {
        label: '12,4-20',
        value: 1045,
      },
      {
        label: '12,4-24',
        value: 1160,
      },
      {
        label: '12,4-26',
        value: 1210,
      },
      {
        label: '12,4-28',
        value: 1260,
      },
      {
        label: '12,4-30',
        value: 1310,
      },
      {
        label: '12,4-32',
        value: 1360,
      },
      {
        label: '12,4-36',
        value: 1465,
      },
      {
        label: '12,4-38',
        value: 1515,
      },
      {
        label: '12,4-42',
        value: 1615,
      },
      {
        label: '12,4-46',
        value: 1705,
      },
      {
        label: '12,4-52',
        value: 1860,
      },
      {
        label: '13,6-16',
        value: 1005,
      },
      {
        label: '13,6-24',
        value: 1210,
      },
      {
        label: '13,6-26',
        value: 1260,
      },
      {
        label: '13,6-28',
        value: 1310,
      },
      {
        label: '13,6-36',
        value: 1515,
      },
      {
        label: '13,6-38',
        value: 1565,
      },
      {
        label: '13,6-48',
        value: 1805,
      },
      {
        label: '13,9-36',
        value: 1478,
      },
      {
        label: '14,9/80-24',
        value: 1215,
      },
      {
        label: '14,9-20',
        value: 1165,
      },
      {
        label: '14,9-24',
        value: 1265,
      },
      {
        label: '14,9-26',
        value: 1315,
      },
      {
        label: '14,9-28',
        value: 1365,
      },
      {
        label: '14,9-30',
        value: 1415,
      },
      {
        label: '14,9-38',
        value: 1615,
      },
      {
        label: '14,9-46',
        value: 1824,
      },
      {
        label: '15,5-38',
        value: 1570,
      },
      {
        label: '16,9-24',
        value: 1335,
      },
      {
        label: '16,9-26',
        value: 1385,
      },
      {
        label: '16,9-28',
        value: 1435,
      },
      {
        label: '16,9-30',
        value: 1485,
      },
      {
        label: '16,9-34',
        value: 1585,
      },
      {
        label: '16,9-38',
        value: 1690,
      },
      {
        label: '16,9-42',
        value: 1775,
      },
      {
        label: '18,4-16.1',
        value: 1137,
      },
      {
        label: '18,4-24',
        value: 1400,
      },
      {
        label: '18,4-26',
        value: 1450,
      },
      {
        label: '18,4-28',
        value: 1501,
      },
      {
        label: '18,4-30',
        value: 1550,
      },
      {
        label: '18,4-34',
        value: 1650,
      },
      {
        label: '18,4-38',
        value: 1750,
      },
      {
        label: '18,4-42',
        value: 1850,
      },
      {
        label: '18,4-46',
        value: 1958,
      },
      {
        label: '20,8-34',
        value: 1735,
      },
      {
        label: '20,8-38',
        value: 1835,
      },
      {
        label: '20,8-42',
        value: 1935,
      },
      {
        label: '23,1-26',
        value: 1605,
      },
      {
        label: '23,1-30',
        value: 1705,
      },
      {
        label: '23,1-34',
        value: 1805,
      },
      {
        label: '24,5-32',
        value: 1805,
      },
      {
        label: '7,5L-15',
        value: 745,
      },
      {
        label: '14,9LR-20',
        value: 1100,
      },
      {
        label: '17,5L-24',
        value: 1265,
      },
      {
        label: '19,5L-24',
        value: 1339,
      },
      {
        label: '21L-24',
        value: 1402,
      },
      {
        label: '28,1-26',
        value: 1615,
      },
      {
        label: '28L-26',
        value: 1615,
      },
      {
        label: '30,5L-32',
        value: 1820,
      },
      {
        label: '11,2/78-28',
        value: 1180,
      },
      {
        label: '12,4/78-28',
        value: 1240,
      },
      {
        label: '12,4/78-36',
        value: 1440,
      },
      {
        label: '13,6/78-28',
        value: 1285,
      },
      {
        label: '13,6/78-36',
        value: 1490,
      },
      {
        label: '14,9/78-28',
        value: 1345,
      },
      {
        label: '16,9/78-28',
        value: 1410,
      },
      {
        label: '16,9/78-30',
        value: 1460,
      },
      {
        label: '16,9/78-34',
        value: 1560,
      },
      {
        label: '16,9/78-38',
        value: 1665,
      },
      {
        label: '18,4/78-30',
        value: 1525,
      },
      {
        label: '18,4/78-38',
        value: 1730,
      },
      {
        label: '300/70R20',
        value: 952,
      },
      {
        label: '320/70R20',
        value: 982,
      },
      {
        label: '320/70R24',
        value: 1094,
      },
      {
        label: '320/70R28',
        value: 1189,
      },
      {
        label: '360/70R20',
        value: 1042,
      },
      {
        label: '360/70R24',
        value: 1152,
      },
      {
        label: '360/70R28',
        value: 1251,
      },
      {
        label: '380/70R20',
        value: 1082,
      },
      {
        label: '380/70R24',
        value: 1190,
      },
      {
        label: '380/70R28',
        value: 1293,
      },
      {
        label: '420/70R24',
        value: 1248,
      },
      {
        label: '420/70R28',
        value: 1349,
      },
      {
        label: '420/70R30',
        value: 1398,
      },
      {
        label: '480/70R24',
        value: 1316,
      },
      {
        label: '480/70R26',
        value: 1372,
      },
      {
        label: '480/70R28',
        value: 1421,
      },
      {
        label: '480/70R30',
        value: 1478,
      },
      {
        label: '480/70R34',
        value: 1580,
      },
      {
        label: '480/70R38',
        value: 1681,
      },
      {
        label: '520/70R26',
        value: 1456,
      },
      {
        label: '520/70R30',
        value: 1536,
      },
      {
        label: '520/70R34',
        value: 1640,
      },
      {
        label: '520/70R38',
        value: 1749,
      },
      {
        label: '580/70R38',
        value: 1827,
      },
      {
        label: '125 - 15 IMP',
        value: 590,
      },
      {
        label: '140 - 6 IMP',
        value: 315,
      },
      {
        label: '165 - 15 IMP',
        value: 650,
      },
      {
        label: '2,50 - 4 IMP',
        value: 225,
      },
      {
        label: '2,75 - 4 IMP',
        value: 234,
      },
      {
        label: '2,50 - 8 IMP',
        value: 338,
      },
      {
        label: '3,00 - 4 IMP',
        value: 265,
      },
      {
        label: '3,00 - 8 IMP',
        value: 367,
      },
      {
        label: '3,00 - 10 IMP',
        value: 418,
      },
      {
        label: '3,25 - 8 IMP',
        value: 366,
      },
      {
        label: '3,25 - 16 IMP',
        value: 590,
      },
      {
        label: '4,10/3,50-4 IMP',
        value: 272,
      },
      {
        label: '3,50 - 5 IMP',
        value: 292,
      },
      {
        label: '3,50 - 6 IMP',
        value: 343,
      },
      {
        label: '3,50 - 8 IMP',
        value: 393,
      },
      {
        label: '3,50 - 16 IMP',
        value: 590,
      },
      {
        label: '4,00 - 4 IMP',
        value: 313,
      },
      {
        label: '4,00 - 5 IMP',
        value: 310,
      },
      {
        label: '4,00 - 6 IMP',
        value: 374,
      },
      {
        label: '4,00 - 8 IMP',
        value: 425,
      },
      {
        label: '4,00 - 9 IMP',
        value: 460,
      },
      {
        label: '4,0 - 10 IMP',
        value: 465,
      },
      {
        label: '4,00 - 10 IMP',
        value: 475,
      },
      {
        label: '4,00 - 12 IMP',
        value: 536,
      },
      {
        label: '4,00 - 15 IMP',
        value: 612,
      },
      {
        label: '4,00 - 16 IMP',
        value: 608,
      },
      {
        label: '4,00 - 18 IMP',
        value: 688,
      },
      {
        label: '4,00 - 19 IMP',
        value: 672,
      },
      {
        label: '4,00 - 21 IMP',
        value: 694,
      },
      {
        label: '4,00/4,50 - 21 IMP',
        value: 765,
      },
      {
        label: '4,10 - 4 IMP',
        value: 765,
      },
      {
        label: '4,10 - 6 IMP',
        value: 268,
      },
      {
        label: '4,50 - 9 IMP',
        value: 319,
      },
      {
        label: '4,50 - 14 IMP',
        value: 466,
      },
      {
        label: '4,50 - 16 IMP',
        value: 593,
      },
      {
        label: '4,50 - 19 IMP',
        value: 733,
      },
      {
        label: '4,80 - 8 IMP',
        value: 449,
      },
      {
        label: '5,00 - 8 IMP',
        value: 467,
      },
      {
        label: '5,00 - 9 IMP',
        value: 497,
      },
      {
        label: '5,0 - 10 IMP',
        value: 517,
      },
      {
        label: '5,0 - 12 IMP',
        value: 566,
      },
      {
        label: '5,00 - 12 IMP',
        value: 580,
      },
      {
        label: '5,00 - 14 IMP',
        value: 631,
      },
      {
        label: '5,0 - 15 IMP',
        value: 642,
      },
      {
        label: '5,00 - 15 IMP',
        value: 655,
      },
      {
        label: '5,00 - 16 IMP',
        value: 669,
      },
      {
        label: '5,00/5,25 - 21 IMP',
        value: 824,
      },
      {
        label: '5,50 - 16 IMP',
        value: 703,
      },
      {
        label: '5,70 - 12 IMP',
        value: 570,
      },
      {
        label: '5,70 - 15 IMP',
        value: 647,
      },
      {
        label: '5,90 - 15 IMP',
        value: 681,
      },
      {
        label: '6 - 6 IMP',
        value: 425,
      },
      {
        label: '6,00 - 9 IMP',
        value: 556,
      },
      {
        label: '6 - 12 IMP',
        value: 585,
      },
      {
        label: '6,0 - 12 IMP',
        value: 569,
      },
      {
        label: '6,00 - 12 IMP',
        value: 579,
      },
      {
        label: '6,00 - 16 IMP',
        value: 729,
      },
      {
        label: '6,00 - 19 IMP',
        value: 810,
      },
      {
        label: '6,00 - 20 IMP',
        value: 830,
      },
      {
        label: '6,40 - 15 IMP',
        value: 684,
      },
      {
        label: '6,5 - 15 IMP',
        value: 674,
      },
      {
        label: '6,50 - 10 IMP',
        value: 597,
      },
      {
        label: '6,50 - 16 IMP',
        value: 754,
      },
      {
        label: '6,50 - 20 IMP',
        value: 850,
      },
      {
        label: '6,70 - 15 IMP',
        value: 720,
      },
      {
        label: '6,90 - 9 IMP',
        value: 545,
      },
      {
        label: '7,00- 12 IMP',
        value: 685,
      },
      {
        label: '7,00 - 14 IMP',
        value: 691,
      },
      {
        label: '7,00 - 15 IMP',
        value: 744,
      },
      {
        label: '7,00 - 16 IMP',
        value: 769,
      },
      {
        label: '7,00 - 18 IMP',
        value: 820,
      },
      {
        label: '7,00 - 19 IMP',
        value: 845,
      },
      {
        label: '7,50 - 10 IMP',
        value: 649,
      },
      {
        label: '7,50 - 14 IMP',
        value: 686,
      },
      {
        label: '7,50 - 15 IMP',
        value: 808,
      },
      {
        label: '7,50 - 16 IMP',
        value: 801,
      },
      {
        label: '7,50 - 18 IMP',
        value: 852,
      },
      {
        label: '7,50 - 20 IMP',
        value: 903,
      },
      {
        label: '7,50 - 24 IMP',
        value: 1013,
      },
      {
        label: '7,60 - 15 IMP',
        value: 751,
      },
      {
        label: '8 - 16 IMP',
        value: 795,
      },
      {
        label: '8,00 - 6 IMP',
        value: 452,
      },
      {
        label: '8,00 - 12 IMP',
        value: 710,
      },
      {
        label: '8,00 - 16 IMP',
        value: 808,
      },
      {
        label: '8,00 - 19 IMP',
        value: 888,
      },
      {
        label: '8,00 - 20 IMP',
        value: 945,
      },
      {
        label: '8,25 - 15 IMP',
        value: 835,
      },
      {
        label: '8,25 - 16 IMP',
        value: 832,
      },
      {
        label: '8,25 - 20 IMP',
        value: 934,
      },
      {
        label: '9,00 - 10 IMP',
        value: 696,
      },
      {
        label: '9,00 - 13 IMP',
        value: 814,
      },
      {
        label: '9,00- 15 IMP',
        value: 850,
      },
      {
        label: '9,00 - 16 IMP',
        value: 48,
      },
      {
        label: '9,00 - 24 IMP',
        value: 1094,
      },
      {
        label: '10,00 - 12 IMP',
        value: 790,
      },
      {
        label: '10,00 - 15 IMP',
        value: 853,
      },
      {
        label: '10,00 - 16 IMP',
        value: 895,
      },
      {
        label: '10,50 - 16 IMP',
        value: 955,
      },
      {
        label: '11,00 - 12 IMP',
        value: 835,
      },
      {
        label: '11,00 - 16 IMP',
        value: 937,
      },
      {
        label: '11,0 - 20 IMP',
        value: 950,
      },
      {
        label: '11,25 - 24 IMP',
        value: 1171,
      },
      {
        label: '11,25 - 28 IMP',
        value: 1273,
      },
      {
        label: '11,5 - 24 IMP',
        value: 1070,
      },
      {
        label: '13,50 - 16,1 IMP',
        value: 1043,
      },
      {
        label: '14,0 - 24 IMP',
        value: 1170,
      },
      {
        label: '15,0 - 24 IMP',
        value: 1210,
      },
      {
        label: '15,0 - 28 IMP',
        value: 1310,
      },
      {
        label: '17,0 - 28 IMP',
        value: 1390,
      },
      {
        label: '17,0 - 30 IMP',
        value: 1440,
      },
      {
        label: '18,5 - 34 IMP',
        value: 1600,
      },
      {
        label: '20 - 20 IMP',
        value: 1270,
      },
      {
        label: '190-8 IMP',
        value: 430,
      },
      {
        label: '7,5 L - 15 IMP',
        value: 745,
      },
      {
        label: '8,5L - 14 IMP',
        value: 735,
      },
      {
        label: '9,5L - 14 IMP',
        value: 757,
      },
      {
        label: '9,5L - 15 IMP',
        value: 782,
      },
      {
        label: '11L - 14 IMP',
        value: 770,
      },
      {
        label: '11L - 15 IMP',
        value: 796,
      },
      {
        label: '11L - 16 IMP',
        value: 821,
      },
      {
        label: '12,5L - 15 IMP',
        value: 845,
      },
      {
        label: '12,5L - 16 IMP',
        value: 870,
      },
      {
        label: '14 L - 16,1 IMP',
        value: 940,
      },
      {
        label: '16,5L - 16,1 IMP',
        value: 1046,
      },
      {
        label: '19 L - 16,1 IMP',
        value: 1087,
      },
      {
        label: '21,5 L - 16,1 IMP',
        value: 1130,
      },
      {
        label: '05/50 - 10 IMP',
        value: 450,
      },
      {
        label: '19,0/45 - 17 IMP',
        value: 866,
      },
      {
        label: '15,0/55 - 17 IMP',
        value: 872,
      },
      {
        label: '10,5/65 - 16 IMP',
        value: 755,
      },
      {
        label: '11,0/60 - 16 IMP',
        value: 742,
      },
      {
        label: '11,0/65 - 12 IMP',
        value: 692,
      },
      {
        label: '13,0/65 - 18 IMP',
        value: 890,
      },
      {
        label: '13,0/70 - 16 IMP',
        value: 890,
      },
      {
        label: '14,0/65 - 16 IMP',
        value: 870,
      },
      {
        label: '9,0/70 - 16 IMP',
        value: 725,
      },
      {
        label: '11,5/70 - 16 IMP',
        value: 815,
      },
      {
        label: '11,5/70 - 18 IMP',
        value: 865,
      },
      {
        label: '15,0/70 - 18 IMP',
        value: 990,
      },
      {
        label: '16,0/70 - 20 IMP',
        value: 1097,
      },
      {
        label: '16,5/70 - 22.5 IMP',
        value: 1158,
      },
      {
        label: '20,0/70 - 508 IMP',
        value: 1220,
      },
      {
        label: '8,0/75 - 15 IMP',
        value: 710,
      },
      {
        label: '9,0/75 - 16 IMP',
        value: 770,
      },
      {
        label: '10,0/75 - 12 IMP',
        value: 685,
      },
      {
        label: '10,0 - 15.3 IMP',
        value: 785,
      },
      {
        label: '10,0/75 - 15.3 IMP',
        value: 780,
      },
      {
        label: '10,0/75 - 16 IMP',
        value: 805,
      },
      {
        label: '12,0/75 - 18 IMP',
        value: 937,
      },
      {
        label: '13,0/75 - 16 IMP',
        value: 900,
      },
      {
        label: '13,5/75 - 430,9 IMP',
        value: 945,
      },
      {
        label: '14,5/75 - 20 IMP',
        value: 1060,
      },
      {
        label: '6,5/80 - 12 IMP',
        value: 588,
      },
      {
        label: '6,5/80 - 15 IMP',
        value: 663,
      },
      {
        label: '8,50 - 12 IMP',
        value: 715,
      },
      {
        label: '10,0/80 - 12 IMP',
        value: 730,
      },
      {
        label: '10 - 18 IMP',
        value: 875,
      },
      {
        label: '10,5/80 - 18 IMP',
        value: 907,
      },
      {
        label: '11,5 - 15,3 IMP',
        value: 860,
      },
      {
        label: '11,5/80 - 15,3 IMP',
        value: 867,
      },
      {
        label: '12,5/80 - 15,3 IMP',
        value: 889,
      },
      {
        label: '12,5/80 - 18 IMP',
        value: 987,
      },
      {
        label: '14,5/80 - 18 IMP',
        value: 1082,
      },
      {
        label: '15,5/80 - 24 IMP',
        value: 1262,
      },
      {
        label: '17,0/80 - 508 IMP',
        value: 1200,
      },
      {
        label: '19,5/80 - 20 IMP',
        value: 1300,
      },
      {
        label: '21,0/80 - 20 IMP',
        value: 1362,
      },
      {
        label: '5,5/85 - 9 IMP',
        value: 475,
      },
      {
        label: '10,5/85 - 15,3 IMP',
        value: 792,
      },
      {
        label: '13,5/85 - 28 IMP',
        value: 1293,
      },
      {
        label: '16,5/85 - 24 IMP',
        value: 1344,
      },
      {
        label: '16,5/85 - 28 IMP',
        value: 1445,
      },
      {
        label: '9×3,50 - 4',
        value: 229,
      },
      {
        label: '11×4,00 - 4',
        value: 280,
      },
      {
        label: '11×4,00 - 5',
        value: 272,
      },
      {
        label: '11×7 - 4',
        value: 270,
      },
      {
        label: '12×4,00 - 5',
        value: 298,
      },
      {
        label: '13×5,00 - 6',
        value: 320,
      },
      {
        label: '13×6,00-6',
        value: 330,
      },
      {
        label: '13×6,00 - 8',
        value: 330,
      },
      {
        label: '13×6,50 - 6',
        value: 330,
      },
      {
        label: '14×4,50-6',
        value: 356,
      },
      {
        label: '14×5,00 - 6',
        value: 347,
      },
      {
        label: '14×6,00 - 6',
        value: 340,
      },
      {
        label: '15×6,00 - 6',
        value: 366,
      },
      {
        label: '16×4,50 - 9',
        value: 405,
      },
      {
        label: '16×5,50 - 8',
        value: 414,
      },
      {
        label: '16×6,50 - 8',
        value: 405,
      },
      {
        label: '16×7,50 - 8',
        value: 411,
      },
      {
        label: '17×8,00 - 8',
        value: 438,
      },
      {
        label: '17×8,00 - 12',
        value: 432,
      },
      {
        label: '18×6,50 - 8',
        value: 457,
      },
      {
        label: '18×7,00 - 8',
        value: 450,
      },
      {
        label: '18×7,50-8',
        value: 457,
      },
      {
        label: '18×8,50 - 8',
        value: 450,
      },
      {
        label: '18×9,50 - 8',
        value: 462,
      },
      {
        label: '19×7,50 - 8',
        value: 480,
      },
      {
        label: '19×8,00 - 10',
        value: 483,
      },
      {
        label: '19×9,50-8',
        value: 483,
      },
      {
        label: '19×10,00 - 8',
        value: 483,
      },
      {
        label: '20×8,00-8',
        value: 508,
      },
      {
        label: '20×8,00 - 10',
        value: 500,
      },
      {
        label: '20×9,00-8',
        value: 508,
      },
      {
        label: '20×10,00 - 8',
        value: 508,
      },
      {
        label: '20×10,00 - 10',
        value: 508,
      },
      {
        label: '20,5×8,00 - 10',
        value: 526,
      },
      {
        label: '21×7,00-10',
        value: 533,
      },
      {
        label: '21×8,00 - 10',
        value: 525,
      },
      {
        label: 'AT21×7 - 10',
        value: 533,
      },
      {
        label: '21×11,00 - 8',
        value: 518,
      },
      {
        label: '21×11,00 - 10',
        value: 525,
      },
      {
        label: '22×8,00 - 10',
        value: 556,
      },
      {
        label: '22×8,50 - 12',
        value: 551,
      },
      {
        label: 'AT22×9 - 8',
        value: 559,
      },
      {
        label: '22×10,00 - 8',
        value: 572,
      },
      {
        label: '22×10,00 - 10',
        value: 559,
      },
      {
        label: '22×11,00 - 8',
        value: 546,
      },
      {
        label: '22×11,00 - 10',
        value: 559,
      },
      {
        label: 'AT23×7 - 10',
        value: 587,
      },
      {
        label: 'AT23×8 - 11',
        value: 584,
      },
      {
        label: '23×8,50 - 12',
        value: 575,
      },
      {
        label: '23×9,00 - 12',
        value: 575,
      },
      {
        label: '23×9,50-12',
        value: 577,
      },
      {
        label: '23×10,50 - 12',
        value: 579,
      },
      {
        label: 'AT24×8 - 11',
        value: 610,
      },
      {
        label: 'AT24×9 - 11',
        value: 610,
      },
      {
        label: 'AT24×10 - 11',
        value: 610,
      },
      {
        label: '24×8,50 - 12',
        value: 602,
      },
      {
        label: '24×8,50 - 14',
        value: 602,
      },
      {
        label: '24×11,00 - 10',
        value: 607,
      },
      {
        label: '24×12,00-12',
        value: 610,
      },
      {
        label: '24×13,00 - 12',
        value: 592,
      },
      {
        label: '25×7,50 - 15',
        value: 640,
      },
      {
        label: 'AT25×8 - 12',
        value: 635,
      },
      {
        label: '25×8,00-12',
        value: 635,
      },
      {
        label: '25×8,50 - 14',
        value: 645,
      },
      {
        label: '25×10,00-12',
        value: 635,
      },
      {
        label: '25×10,50 - 15',
        value: 640,
      },
      {
        label: '25×11,00-12',
        value: 635,
      },
      {
        label: 'AT25×11 - 9',
        value: 635,
      },
      {
        label: 'AT25×11 - 10',
        value: 645,
      },
      {
        label: '25×12,00 - 9',
        value: 635,
      },
      {
        label: '25×12,50 - 15',
        value: 640,
      },
      {
        label: '26×10,00 - 12',
        value: 660,
      },
      {
        label: '26×12,00 - 12',
        value: 660,
      },
      {
        label: '26×14,00 - 12',
        value: 660,
      },
      {
        label: '27×8,50 - 15',
        value: 680,
      },
      {
        label: '27×9,50 - 15',
        value: 686,
      },
      {
        label: '27×10,50 - 15',
        value: 691,
      },
      {
        label: '27×10 - 15.3',
        value: 685,
      },
      {
        label: '28×9,00 - 15',
        value: 710,
      },
      {
        label: '28×13 - 15',
        value: 711,
      },
      {
        label: '29×12,00 - 15',
        value: 742,
      },
      {
        label: '29×12,50 - 15',
        value: 742,
      },
      {
        label: '29×13,50 - 15',
        value: 742,
      },
      {
        label: '31×11,50 - 15',
        value: 793,
      },
      {
        label: '31×12,50 - 15',
        value: 792,
      },
      {
        label: '31×13,50 - 15',
        value: 782,
      },
      {
        label: '31×13,5 - 15',
        value: 782,
      },
      {
        label: '31×15,50 - 15',
        value: 792,
      },
      {
        label: '31×15,5 - 15',
        value: 792,
      },
      {
        label: '33×12,50 - 15',
        value: 843,
      },
      {
        label: '33×15,50 - 15',
        value: 843,
      },
      {
        label: '36×13,50 - 15',
        value: 909,
      },
      {
        label: '38×14,00 - 20',
        value: 991,
      },
      {
        label: '38×18,00 - 20',
        value: 991,
      },
      {
        label: '38×20,00 - 16.1',
        value: 991,
      },
      {
        label: '41×14,00 - 20',
        value: 1067,
      },
      {
        label: '42×25,00 - 20',
        value: 1080,
      },
      {
        label: '43×13,50 - 22',
        value: 1102,
      },
      {
        label: '44×18,00 - 20',
        value: 1143,
      },
      {
        label: '44×41,00 - 20',
        value: 1143,
      },
      {
        label: '48×20,00 - 24',
        value: 1245,
      },
      {
        label: '48×25,00 - 20',
        value: 1245,
      },
      {
        label: '48×31,00 - 20',
        value: 1245,
      },
      {
        label: '54×31,00 - 26',
        value: 1397,
      },
      {
        label: '66×43,00 - 25',
        value: 1702,
      },
      {
        label: '66×43,00 - 26',
        value: 1702,
      },
      {
        label: '66×44,00 - 25',
        value: 1702,
      },
      {
        label: '67×34,00 - 25',
        value: 1727,
      },
      {
        label: '67×34,00 - 26',
        value: 1727,
      },
      {
        label: '67×34,00 - 30',
        value: 1727,
      },
      {
        label: '68×50,00 - 32',
        value: 1753,
      },
      {
        label: 'VA73×44,00 - 32',
        value: 1880,
      },
      {
        label: 'DH73×44,00 - 32',
        value: 1880,
      },
      {
        label: 'DH73×50,00-32',
        value: 1880,
      },
    ],
  },
]
