//@ts-check
import React from 'react'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'
import { Delete as DeleteIcon } from '@mui/icons-material'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import moment from '../../../../../node_modules/moment/moment'
import isValidFunction from 'myMethods/isValidFunction'

/**
 * @param {{filterValues: import('types/FilterServiceParams').FilterServiceParams, onClearTableFilter: function}} param0
 */
export default function TableFilterValues({ filterValues, onClearTableFilter }) {
  console.log({ filterValues })
  if (filterValues == null) return <></>
  return (
    <div className="mui-table__filter-values" data-aos="fade-up">
      <div className="mui-table__filter-values-header">
        <span>Filtros de búsqueda avanzada</span>
        <HoverTooltip title="Eliminar búsqueda">
          <div onClick={() => isValidFunction(onClearTableFilter) && onClearTableFilter()}>
            <DeleteIcon />
          </div>
        </HoverTooltip>
      </div>
      <div className="mui-table__filter-values-body">
        {filterValues.startDate && (
          <CardNameValue
            name="Desde"
            value={
              moment(filterValues.startDate).isValid
                ? moment(filterValues.startDate).format('DD-MM-YYYY')
                : '-'
            }
          />
        )}
        {filterValues.endDate && (
          <CardNameValue
            name="Hasta"
            value={
              moment(filterValues.endDate).isValid
                ? moment(filterValues.endDate).format('DD-MM-YYYY')
                : '-'
            }
          />
        )}
        {filterValues.license && (
          <CardNameValue name="Matrícula exacta" value={filterValues.license} />
        )}
        {filterValues.likeLicense && (
          <CardNameValue name="Matrícula que incluya" value={filterValues.likeLicense} />
        )}
        {filterValues.likeDniHolder && (
          <CardNameValue name="DNI/CIF titular que incluya" value={filterValues.likeDniHolder} />
        )}
        {filterValues.licenseDateStart && (
          <CardNameValue
            name="Matriculado después de"
            value={
              moment(filterValues.licenseDateStart).isValid
                ? moment(filterValues.licenseDateStart).format('DD-MM-YYYY')
                : '-'
            }
          />
        )}
        {filterValues.licenseDateEnd && (
          <CardNameValue
            name="Matriculado antes de"
            value={
              moment(filterValues.licenseDateEnd).isValid
                ? moment(filterValues.licenseDateEnd).format('DD-MM-YYYY')
                : '-'
            }
          />
        )}
        {filterValues.category && <CardNameValue name="Categoría" value={filterValues.category} />}
        {filterValues.fuel && <CardNameValue name="Combustible" value={filterValues.fuel} />}
        {filterValues.dni && <CardNameValue name="DNI/CIF exacto" value={filterValues.dni} />}
        {filterValues.likeDni && (
          <CardNameValue name="DNI/CIF que incluya" value={filterValues.likeDni} />
        )}
        {filterValues.likeName && (
          <CardNameValue name="Nombre que incluya" value={filterValues.likeName} />
        )}
        {filterValues.inUse != null && (
          <CardNameValue
            name="Solo albaranes facturados"
            value={filterValues.inUse ? 'Sí' : 'No'}
          />
        )}
        {filterValues.rev && <CardNameValue name="Revisión" value={filterValues.rev} />}
        {filterValues.numero && (
          <CardNameValue name="Número de informe" value={filterValues.numero} />
        )}
        {filterValues.likeNumero && (
          <CardNameValue name="Número de informe que incluya" value={filterValues.likeNumero} />
        )}
      </div>
    </div>
  )
}
