//@ts-check
import React, { useState, useEffect } from 'react'

// MY IMPORTS
import MaterialTab from 'components/Others/MaterialTab/MaterialTab'
import HardwareByLine from './HardwareByLine/HardwareByLine'
import ErrorView from 'components/Others/ErrorView/ErrorView'
import ComplementaryEquipmentConfigurator from './ComplementaryEquipment/ComplementaryEquipment'

/**
 * @typedef {import('types/HardwareConfig').HardwareByLine} HardwareByLine
 * @typedef {import('types/ComplementaryEquipment').ComplementaryEquipment} ComplementaryEquipment
 */

/**
 * @param {{hardwareByLine: HardwareByLine[], complementaryEquipment: ComplementaryEquipment[]}} param0
 */
export default function LinesConfig({ hardwareByLine, complementaryEquipment }) {
  const [state, setState] = useState({
    tabs: [],
    contents: [],
  })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (hardwareByLine?.length || complementaryEquipment?.length) {
      let tabs = []
      let contents = []
      if (hardwareByLine?.length) {
        hardwareByLine.forEach(({ line, hardware }) => {
          if (line)
            tabs.push({ text: `LÍNEA ${line.toUpperCase()}`, counter: hardware?.length || 0 })
          else tabs.push({ text: 'OTROS', counter: hardware?.length || 0 })
          if (hardware?.length)
            contents.push(<HardwareByLine hardwareList={hardware} setLoading={setLoading} />)
          else contents.push(<div className="no-values">No hay máquinas en esta línea</div>)
        })
      }

      tabs.push({ text: 'EQUIPOS COMPLEMENTARIOS', counter: complementaryEquipment?.length ?? 0 })
      contents.push(
        <ComplementaryEquipmentConfigurator
          complementaryEquipment={complementaryEquipment}
          setLoading={setLoading}
        />
      )

      setState({ tabs, contents })
    }
    setLoading(false)
  }, [hardwareByLine, complementaryEquipment])
  if (!state.tabs?.length) return <ErrorView />
  return <MaterialTab tabs={state?.tabs} contents={state?.contents} loading={loading} />
}
