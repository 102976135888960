import React from 'react'
import './InspectionMain.scss'
import InfoMenu from 'components/Inspection/InfoMenu/InfoMenu'
import Title from 'components/Others/Title/Title'
import Clock from 'components/Inspection/Clock/Clock'
import { useSelector } from 'react-redux'
import NoFilter from 'components/Inspection/NoFilter/NoFilter'

export default function InspectionMain() {
  const reduxInspection = useSelector(store => store.inspection)

  return (
    <>
      <Title text="INFORMACIÓN DEL VEHÍCULO" />
      {!reduxInspection.filter &&
        reduxInspection?.vehicleData &&
        Object.keys(reduxInspection.vehicleData)?.length > 0 && <NoFilter />}
      {reduxInspection?.filter && (
        <section className="inspection-main">
          {reduxInspection?.times?.inspection_start && (
            <Clock
              seconds={true}
              dateFrom={reduxInspection.times.inspection_start}
              style={{ marginTop: '1rem' }}
            />
          )}

          <InfoMenu className="info-menu--inspection" reduxInspection={reduxInspection} />
        </section>
      )}
    </>
  )
}
