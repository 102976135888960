//@ts-check
import React from 'react'
import { useLottie } from 'lottie-react'
import './LottieAnimation.scss'

/**
 * Componente de animación Lottie con opciones de personalización.
 *
 * @param {Object} props
 * @param {string | undefined} [props.className]
 * @param {boolean} [props.fullWindow=false]
 * @param {Object} props.animationData
 * @param {boolean} [props.loop=true]
 * @param {boolean} [props.autoplay=true]
 * @param {number | undefined} [props.secondsToStop]
 * @param {number} [props.speed=1]
 * @returns {React.ReactElement}
 */
export default function LottieAnimation({
  className,
  fullWindow = false,
  animationData,
  loop = true,
  autoplay = true,
  speed = 1,
  ...rest
}) {
  const options = {
    animationData: animationData,
    loop,
    autoplay,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
    speed,
    ...rest,
  }
  const { View, setSpeed } = useLottie(options)

  setSpeed(speed)

  const lottieContent = <div style={{ width: '100%', height: '100%' }}>{View}</div>

  if (fullWindow) {
    return (
      <div
        className={
          className ? `lottie-animation--full-window ${className}` : 'lottie-animation--full-window'
        }
      >
        <div className="lottie-animation__overlay" />
        <div className="lottie-animation__lottie">{lottieContent}</div>
      </div>
    )
  }

  return (
    <div className={className ? `lottie-animation ${className}` : 'lottie-animation'}>
      <div className="lottie-animation__lottie">{lottieContent}</div>
    </div>
  )
}
