import round from './round'

export default function formatEuro(number) {
  try {
    if (number == null || typeof number !== 'number') throw new Error('No number provided')
    return round(number).toFixed(2).replace('.', ',') + ' €'
  } catch (err) {
    return formatEuro(0)
  }
}
