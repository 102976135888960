import React, { useState, useEffect } from 'react'
import { getReport } from 'services/Reports'

// MY IMPORTS
import './HistoryModal.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import { reviveInspection as reviveInspectionService } from 'services/Inspection'
import PDFViewer from 'components/Others/PDFViewer/PDFViewer'
import openPDF from 'myMethods/openPDF'
import { useHistory } from 'react-router-dom'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Id from 'Share/Id'
import useService from 'hooks/useService'

export default function HistoryModal({ data, closeModal }) {
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()

  const { fetch: reviveInspection } = useService({
    service: reviveInspectionService,
  })

  const {
    serviceData: PDF,
    fetch: fetchReportPDF,
    loading: loadingPDF,
  } = useService({
    service: getReport,
  })

  const reportId = data?.rev ? `${data?.numero_informe}-${data?.rev}` : data?.numero_informe

  const handleDetailedViewClick = () => {
    try {
      window.sessionStorage.setItem('historyId', Id.encrypt(data?.idHistorial))
      window.sessionStorage.setItem('license', data?.vehicleData?.license)
      return history.push('/detailed-history')
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error abriendo la vista detallada')
    }
  }

  const revive = async () => {
    try {
      await reviveInspection({
        idHistorial: data?.idHistorial,
        rev: showModal.revisionNumber,
      })
      closeModal()
      easyToast(
        'dark',
        `Informe ${reportId}-${showModal.revisionNumber} generado. Vaya a la lista de inspecciones.`
      )
    } catch (err) {
      easyToast('error', 'Ha ocurrido un error. Quizá ya ha revivido este informe')
    }
  }

  useEffect(() => {
    fetchReportPDF(data.idHistorial).catch(() =>
      easyToast('error', 'Ha ocurrido un error cargando el PDF del informe')
    )
  }, [data?.idHistorial, fetchReportPDF])
  console.log({ loadingPDF })
  return (
    <div className="history-modal">
      <div>
        <MyButtonsContainer
          margin="1rem auto"
          className="history-modal__buttons-container"
          fullWidth={false}
        >
          <MyButton text="REVIVIR" onClick={() => setShowModal({ show: true })} />
          <MyButton text="VISTA DETALLADA" onClick={handleDetailedViewClick} />
          <MyButton text="ABRIR INFORME" onClick={() => openPDF(PDF)} />
        </MyButtonsContainer>
      </div>
      <PDFViewer
        pdf={PDF}
        show={true}
        errorMessage="Ha ocurrido un error cargando el informe. Recargue la página"
        loading={loadingPDF}
      />
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        onAccept={revive}
        title={`¿Revivir informe ${reportId}?`}
        alertMessage="El informe aparecerá en la lista de vehículos en espera para inspeccionar"
      />
    </div>
  )
}
