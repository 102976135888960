import React from 'react'

// MY IMPORTS
import 'components/Others/Buttons/MyButtonsContainer.scss'

export default function MyButtonsContainer({
  margin = '1.5rem auto 0 auto',
  styles = {},
  className = '',
  children = null,
  ...rest
}) {
  try {
    if (children && Array.isArray(children)) {
      children = children?.filter(children => children)
    } else if (children && !Array.isArray(children)) {
      children = [children]
    }

    return (
      <div
        className={`${className ? `my-buttons-container ${className}` : 'my-buttons-container'}`}
        style={{ ...styles, margin }}
        {...rest}
      >
        {children}
      </div>
    )
  } catch (err) {
    console.error(err)
    return <></>
  }
}
