import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

// SERVICES
import { putItvControlPanelFile as putItvControlPanelFileService } from 'services/Files'

// MY IMPORTS
import './ControlPanel.scss'
import Switch from 'components/Others/Switch/Switch'
import easyToast from 'components/Others/EasyToast/easyToast'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import { setReduxConfigItv } from 'redux/ducks/configDuck'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import ErrorView from 'components/Others/ErrorView/ErrorView'
import useService from 'hooks/useService'

export default function ControlPanel({ itv }) {
  const [disabled, setDisabled] = useState(true)
  const [values, setValues] = useState({
    speedometer: itv?.speedometer,
    timeout: { enabled: Boolean(itv?.notifyHardwareExpiration), days: itv?.notifyHardwareDays },
    pgc: itv?.PGCName,
  })
  const dispatch = useDispatch()

  const { fetch: putItvControlPanelFile, loading } = useService({
    service: putItvControlPanelFileService,
  })

  const handleClick = async () => {
    try {
      if (disabled) return setDisabled(false)
      const res = await putItvControlPanelFile(values)
      setDisabled(true)
      dispatch(setReduxConfigItv(res?.itv ?? null))
      easyToast('dark', 'Valores actualizados correctamente')
    } catch (error) {
      dispatch(setReduxConfigItv(null))
      easyToast('error', 'Ha ocurrido un error actualizando los datos, inténtelo de nuevo')
    }
  }

  useEffect(() => {
    setValues({
      speedometer: itv?.speedometer,
      timeout: { enabled: Boolean(itv?.notifyHardwareExpiration), days: itv?.notifyHardwareDays },
      pgc: itv?.PGCName,
    })
  }, [itv])

  return (
    <>
      <div className="control-panel">
        {loading && <ChildSpinner visible={true} style={{ height: '70px' }} />}
        {!loading && (
          <>
            {itv == null && <ErrorView />}
            {itv != null && (
              <>
                <section>
                  <div
                    className={
                      disabled
                        ? 'control-panel__elem control-panel__elem--disabled'
                        : 'control-panel__elem'
                    }
                  >
                    <div>
                      <div>Notificar caducidad de equipos</div>
                      <Switch
                        name="timeout"
                        value={values.timeout?.enabled}
                        onChange={checked =>
                          setValues({
                            ...values,
                            timeout: { ...values.timeout, enabled: checked },
                          })
                        }
                        disabled={disabled}
                      />
                    </div>
                    {values.timeout?.enabled && (
                      <div>
                        <div>Días de antelación:</div>
                        <input
                          type="number"
                          value={values.timeout?.days}
                          onChange={e =>
                            setValues({
                              ...values,
                              timeout: { ...values.timeout, days: e.target.value },
                            })
                          }
                          disabled={disabled}
                        />
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      disabled
                        ? 'control-panel__elem control-panel__elem--disabled'
                        : 'control-panel__elem'
                    }
                  >
                    <div>Captura de cuentakilómetros</div>
                    <Switch
                      name="speedometer"
                      value={values.speedometer}
                      onChange={checked => {
                        console.log({ checked })
                        setValues({ ...values, speedometer: checked })
                      }}
                      disabled={disabled}
                    />
                  </div>

                  <div
                    className={
                      disabled
                        ? 'control-panel__elem control-panel__elem--disabled'
                        : 'control-panel__elem'
                    }
                  >
                    <div>PGC:</div>
                    <input
                      value={values.pgc}
                      onChange={e => setValues({ ...values, pgc: e.target.value })}
                      disabled={disabled}
                    />
                  </div>
                </section>
                <MyButtonsContainer>
                  <MyButton
                    text={disabled ? 'EDITAR' : 'GUARDAR CAMBIOS'}
                    onClick={handleClick}
                    selectable={true}
                    selected={!disabled}
                  />
                </MyButtonsContainer>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}
