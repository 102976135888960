//@ts-check
import MuiTable from 'components/Others/MuiTable/MuiTable'
import React, { useState } from 'react'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { getClientsByChunk as getClientsByChunkService } from 'services/Clients'
import DeepSearch from 'components/Others/DeepSearch/DeepSearch'
import easyToast from 'components/Others/EasyToast/easyToast'

export default function BillingExportModalClients({ clients, setClients }) {
  const [showDeepSearchModal, setShowDeepSearchModal] = useState(false)

  const addClients = clientsProp => {
    try {
      if (!clientsProp || !clientsProp?.length) throw new Error('No client provided')
      clientsProp = clientsProp.filter(cl => !clients.find(c => c.id === cl.id))

      setClients([...clientsProp, ...clients])
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error añadiendo el cliente')
    }
  }

  const deleteClient = client => {
    try {
      if (!client) throw new Error('No client provided')
      setClients(clients.filter(cl => cl.id !== client.id))
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error eliminando el cliente')
    }
  }

  return (
    <div className="billing-export-modal-clients">
      <MuiTable
        columnsKey="billingExportModalClients"
        data={clients}
        noDataMessage="Todos los clientes"
        options={{
          filtering: false,
          grouping: false,
          selection: false,
        }}
        deleteAction={true}
        onDelete={deleteClient}
        showToolbar={false}
        toolbarComponent={
          <>
            <h2>Filtrar clientes</h2>
            <MyButtonsContainer fullWidth={false} margin="0" styles={{ width: 'auto' }}>
              <MyButton text="Añadir cliente" onClick={() => setShowDeepSearchModal(true)} />
            </MyButtonsContainer>
          </>
        }
      />
      <DeepSearch
        open={showDeepSearchModal}
        onClose={() => setShowDeepSearchModal(false)}
        title="Buscar clientes"
        message="Seleccione al menos un cliente"
        onAccept={addClients}
        service={getClientsByChunkService}
        columnsKey="clients"
        filterServiceOptions={{
          likeDni: true,
          likeName: true,
        }}
        multiselection={true}
        idsToExclude={clients?.map(cl => cl.id)}
      />
    </div>
  )
}
