//@ts-check
import React, { useState, useEffect } from 'react'
import './ChatbotSelector.scss'
import ItemPicker from 'components/Others/ItemPicker/ItemPicker'
import RenderListOfDefects from 'components/Inspection/Defectology/Defect/RenderListOfDefects'
import RenderListOfManualChapters from 'components/Inspection/Defectology/ManualChapter/RenderListOfManualChapters'
import { useSelector } from 'react-redux'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import useInspectionManual from 'hooks/useInspectionManual'

/**
 *
 * @param {{chatbot: import('types/UseMintAIChatbot').UseMintAIChatbot}} param0
 * @returns
 */
export default function ChatbotSelector({ chatbot }) {
  const vehicleData = useSelector(state => state.inspection.vehicleData)
  const { inspectionManual } = useInspectionManual(chatbot.selectorState.inspection.manualSection)

  const ALL_STATES = {
    inspection: ['Sección', 'Capítulo', 'Unidad'],
  }
  const initialStates = { inspection: 'Sección' }
  const [states, setStates] = useState(initialStates)

  const updateState = (scope, state) => {
    try {
      if (!ALL_STATES[scope]) throw new Error('Invalid scope')
      if (!ALL_STATES[scope].includes(state)) throw new Error('Invalid state')

      setStates(prevState => ({
        ...prevState,
        [scope]: state,
      }))
    } catch (err) {
      console.error(err)
      setStates(initialStates)
    }
  }

  const [parents, setParents] = useState([])

  const TITLE_BY_INSPECTION_STATES = {
    Sección: 'Seleccione una sección del manual',
    Capítulo: 'Seleccione un capítulo',
    Unidad: 'Seleccione una unidad',
  }

  useEffect(() => {
    if (chatbot.selectorState.type === 'inspection') {
      if (
        chatbot.selectorState.inspection.manualSection !== null &&
        chatbot.selectorState.inspection.chapter !== null
      ) {
        return setParents(
          inspectionManual.getParentsByChapter(chatbot.selectorState.inspection.chapter.id)
        )
      }
    }
  }, [
    chatbot.selectorState.inspection.chapter,
    chatbot.selectorState.inspection.manualSection,
    chatbot.selectorState.type,
    inspectionManual,
  ])

  const BreadCrums = ({ scope }) => {
    const currentIndex = ALL_STATES[scope].indexOf(states[scope])
    if (currentIndex === -1 || currentIndex === 0) return <></>
    return (
      <div className="chatbot-selector__breadcrumbs">
        {ALL_STATES[scope].slice(0, currentIndex + 1).map((state, index) => {
          const isActive = states[scope] === state

          return (
            <>
              {index > 0 && <div className="chatbot-selector__breadcrumbs-separator"> &gt; </div>}
              <div
                key={index}
                className={`chatbot-selector__breadcrumb ${
                  isActive ? 'chatbot-selector__breadcrumb--active' : ''
                }`}
                onClick={() => updateState(scope, state)}
              >
                {state}
              </div>
            </>
          )
        })}
      </div>
    )
  }
  console.log({ states })
  if (!chatbot) return <></>
  return (
    <div className="chatbot-selector">
      {chatbot.selectorState.type === 'inspection' && (
        <>
          <BreadCrums scope="inspection" />
          {TITLE_BY_INSPECTION_STATES[states.inspection] !== null && (
            <div className="chatbot-selector__title">
              {TITLE_BY_INSPECTION_STATES[states.inspection]}
            </div>
          )}
          {states.inspection === 'Sección' && (
            <>
              <ItemPicker
                values={['1', '2', '3', '4', '5']}
                onAccept={section => {
                  chatbot.setInspectionSelectorState({ manualSection: section })
                  updateState('inspection', 'Capítulo')
                }}
                renderOnModal={false}
              />
              {vehicleData && (
                <div className="chatbot-selector__vehicle">
                  <div className="chatbot-selector__title">
                    O SIGA CON EL VEHÍCULO EN INSPECCIÓN
                  </div>
                  <div className="chatbot-selector__vehicle-values">
                    <CardNameValue name="Matrícula" value={vehicleData.license} />
                    <CardNameValue name="Categoría" value={vehicleData.category} />
                    <CardNameValue name="Clasificación" value={vehicleData.classification} />
                    <CardNameValue name="Combustible 1" value={vehicleData.fuel1} />
                    <CardNameValue name="Fecha de Matriculación" value={vehicleData.licenseDate} />
                  </div>
                </div>
              )}
            </>
          )}
          {states.inspection === 'Capítulo' && (
            <>
              {/* <div
                className="chatbot-selector__selection-stop"
                onClick={() => chatbot.initialize()}
              >
                Toda la sección {chatbot.selectorState.inspection.manualSection}
              </div> */}
              <RenderListOfManualChapters
                checkIfDone={false}
                forcedOnClick={manualSection => {
                  chatbot.setInspectionSelectorState({ chapter: manualSection })
                  updateState('inspection', 'Unidad')
                }}
              />
            </>
          )}
          {states.inspection === 'Unidad' && (
            <>
              <div
                className="chatbot-selector__selection-stop"
                onClick={() => chatbot.initialize()}
              >
                Todo el capítulo {chatbot.selectorState.inspection.chapter.id}
              </div>
              <RenderListOfDefects
                defects={parents}
                noUserInteraction={true}
                forcedOnClick={parent => {
                  chatbot.setInspectionSelectorState({ parent })
                  chatbot.initialize()
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  )
}
