//@ts-check
import React from 'react'
import './CardNameValue.scss'

/**
 *
 * @param {{name?: string, value?: any, className?: string, color?: string, unit?: string}} param0
 * @returns
 */
export default function CardNameValue({ name, value, className = '', color = '', unit, ...rest }) {
  if (!name) return <></>
  if (value === '') value = null
  return (
    <div className={`card-name-value${className ? ' ' + className : ''}`} {...rest}>
      {name && <label style={{ color }}>{name}</label>}
      <div className="card-name-value__value" style={{ color }}>
        {value ?? '-'}
      </div>
      {unit && <div className="card-name-value__unit">{unit}</div>}
    </div>
  )
}
