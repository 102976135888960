import OngoingInspections from 'components/Office/OngoingInspections/OngoingInspections'
import Title from 'components/Others/Title/Title'
import React from 'react'

export default function OngoingInspectionsPage() {
  return (
    <>
      <Title text="INSPECCIONES EN CURSO" />
      <OngoingInspections />
    </>
  )
}
