import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import 'components/Inspection/Hardware/Hardware.scss'
import HardwareHandler from 'Share/HardwareHandler'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import TestsValues from 'Share/TestsValues'
import { InfoCircle as HintIcon } from 'react-bootstrap-icons'

export default function Hardware() {
  const history = useHistory()
  const reduxInspection = useSelector(store => store.inspection)
  const [hardwareByType, setHardwareByType] = useState({ machine: [], automatic: [] })
  const params = useParams()
  const testsValuesHandler = new TestsValues(
    reduxInspection?.testsValues,
    reduxInspection?.hardwareConfig
  )

  const ListHardware = ({ hardware, title, manual }) => (
    <div className="hardware">
      <h4>{title}</h4>
      {(!hardware || !hardware.length) && (
        <div className="no-values">NO HAY EQUIPOS DISPONIBLES</div>
      )}
      {hardware && hardware.length > 0 && (
        <div className="hardware__elem-container">
          <ListItemsInTwoColumns>
            {hardware.map((elem, i) => {
              console.log({ elem })
              const name = elem.name.join(' Y ').toUpperCase()
              const url = elem.name.join('_')
              const testsDone = elem.name.map(name => {
                const doneRes = testsValuesHandler.isThisTestDone(name)
                if (!doneRes) return false
                if (elem.manual === doneRes.manual) {
                  return doneRes.done
                }
                return false
              })
              const areAllTestsDone = testsDone.every(done => done)
              return (
                <div
                  key={i}
                  className={`hardware__elem ${
                    areAllTestsDone ? 'hardware__elem--done' : ''
                  } contrast-on-hover`}
                  onClick={() =>
                    history.push(
                      `/new_inspection/${params.license}/hardware/${
                        manual ? 'manual' : 'automatic'
                      }/${url}`
                    )
                  }
                >
                  <span>{name}</span>
                  <div className="hardware__done">
                    {testsDone.map((isDone, index) => {
                      if (elem.name.length > 1 && isDone) {
                        return (
                          <div key={index}>
                            {elem.name[index]?.substring(0, 1)?.toUpperCase() || '✔'}
                          </div>
                        )
                      } else if (elem.name.length === 1 && isDone) return <div key={index}>✔</div>
                      else return <></>
                    })}
                  </div>
                </div>
              )
            })}
          </ListItemsInTwoColumns>
        </div>
      )}
    </div>
  )

  useEffect(() => {
    try {
      const hardwareHandler = new HardwareHandler({
        machinesApplying: reduxInspection.machinesApplying,
        hardwareConfig: reduxInspection.hardwareConfig,
        vehicleFuel1: reduxInspection.vehicleData?.fuel1,
      })
      const manual = hardwareHandler?.getHardwareConfigByManualOrAutomatic(
        reduxInspection?.isFiltered
      ).manual
      const automatic = hardwareHandler?.getHardwareConfigByManualOrAutomatic(
        reduxInspection?.isFiltered
      ).automatic
      if (manual && automatic) setHardwareByType({ manual, automatic })
    } catch (error) {
      console.error(error)
    }
  }, [
    reduxInspection?.hardwareConfig,
    reduxInspection?.isFiltered,
    reduxInspection?.machinesApplying,
    reduxInspection?.vehicleData?.fuel1,
  ])

  return (
    <>
      {reduxInspection?.isFiltered && (
        <div className="hardware__hint">
          <HintIcon />{' '}
          <span>Si quiere mostrar todos los equipos, desactive el filtro de inspección</span>
        </div>
      )}
      {!reduxInspection?.isFiltered && (
        <div className="hardware__hint hardware__hint--warning">
          <HintIcon /> <span>Filtro desactivado - Mostrando todos los equipos</span>
        </div>
      )}
      <ListHardware
        hardware={hardwareByType.automatic}
        title="TRANSMISIÓN AUTOMÁTICA DE DATOS"
        manual={false}
      />
      <ListHardware
        hardware={hardwareByType.manual}
        title="TRANSMISIÓN MANUAL DE DATOS"
        manual={true}
      />
    </>
  )
}
