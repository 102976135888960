//@ts-check
import React from 'react'
import TestConfigWrapper from '../../../TestConfigWrapper/TestConfigWrapper'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import './AlineadorConfig.scss'

export default function AlineadorConfig({ setValues, values, vehicleAxles, disabled }) {
  const handleAxleChange = axle => {
    try {
      if (!values?.axles) return
      const currentValues = [...values.axles]
      const index = currentValues.indexOf(axle)
      if (index === -1) {
        currentValues.push(axle)
      } else {
        currentValues.splice(index, 1)
      }
      setValues({ ...values, axles: currentValues })
    } catch (err) {
      console.error(err)
    }
  }
  return (
    <TestConfigWrapper testName="Alineador" disabled={disabled}>
      <div className="alineador-config__axles-wrapper">
        <label>Ejes direccionales</label>
        <div className="alineador-config__axles">
          {[...Array(vehicleAxles?.tractor ?? 4)].map((_, i) => {
            const axle = i + 1
            return (
              <Checkbox
                key={i}
                label={`Eje ${axle}`}
                checked={values?.axles?.includes(axle)}
                onChange={() => handleAxleChange(axle)}
                disabled={disabled}
              />
            )
          })}
        </div>
      </div>
    </TestConfigWrapper>
  )
}
