import React, { useState } from 'react'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import './Accordion.scss'
import isValidFunction from 'myMethods/isValidFunction'

/**
 *
 * @param {{title: any, titleWhenOpen?: any, children: any, elemKey: string | number, initiallyOpen?: string | number, setOpenIndex?: function, className?: string}} param0
 * @returns
 */
export default function Accordion({
  title,
  titleWhenOpen,
  children,
  elemKey,
  initiallyOpen,
  setOpenIndex,
  className,
}) {
  const [expanded, setExpanded] = useState(initiallyOpen)

  const handleExpand = panel => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false)
    if (isValidFunction(setOpenIndex)) setOpenIndex(newExpanded ? panel : null)
  }

  return (
    <div className={`accordion${className ? ' ' + className : ''}`}>
      <MuiAccordion
        expanded={expanded === elemKey}
        onChange={handleExpand(elemKey)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <MuiAccordionSummary
          className="accordion__summary"
          expandIcon={<i className="flaticon-upload" />}
        >
          {(titleWhenOpen == null || (titleWhenOpen != null && expanded !== elemKey)) && (
            <Typography>{title}</Typography>
          )}
          {titleWhenOpen != null && expanded === elemKey && (
            <Typography>{titleWhenOpen}</Typography>
          )}
        </MuiAccordionSummary>
        {children &&
          Array.isArray(children) &&
          children.map(child => (
            <MuiAccordionDetails className="accordion__detail">{child}</MuiAccordionDetails>
          ))}
        {children && !Array.isArray(children) && (
          <MuiAccordionDetails className="accordion__detail">{children}</MuiAccordionDetails>
        )}
      </MuiAccordion>
    </div>
  )
}
