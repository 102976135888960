import React from 'react'

// MY IMPORTS
import Title from 'components/Others/Title/Title'
import MobileBackBtn from 'components/Others/MobileBackBtn/MobileBackBtn'
import ComplementaryEquipment from 'components/Inspection/ComplementaryEquipment/ComplementaryEquipment'

export default function ComplementaryEquipmentPage({ redux }) {
  return (
    <>
      <Title text={'EQUIPOS COMPLEMENTARIOS'} />
      <ComplementaryEquipment
        complementaryEquipment={redux?.config?.complementaryEquipment}
        complementaryEquipmentInUse={
          redux?.inspection?.complementaryEquipment === null
            ? []
            : redux.inspection.complementaryEquipment
        }
      />
      <MobileBackBtn />
    </>
  )
}
