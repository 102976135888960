import moment from 'moment'
/**
 * @description Check if dates are a valid dates range
 *
 * @param {[start,end]} dates
 * @returns {Boolean} true if the dates are valid, false otherwise
 */
export default function isValidDateRange(dates) {
  try {
    if (dates && Array.isArray(dates) && dates.length === 2) {
      let [start, end] = dates
      start = moment(start)
      end = moment(end)
      if (start && end && start.isValid() && end.isValid()) {
        if (start.isBefore(end) || start.isSame(end)) {
          return true
        }
      }
      return false
    }
  } catch (err) {
    console.error(err)
    return false
  }
}
