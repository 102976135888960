//@ts-check
import React, { useState, useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { useRef } from 'react'
import { motion } from 'framer-motion'
import { BoxArrowUpRight as OpenIcon } from 'react-bootstrap-icons'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

// MY IMPORTS
import './PDFViewer.scss'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import useWindowDimensions from 'hooks/useWindowDimensions'
import easyToast from 'components/Others/EasyToast/easyToast'
import openPDF from 'myMethods/openPDF'

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

/**
 *
 * @param {object} param0
 * @param {any} param0.pdf
 * @param {boolean} param0.show
 * @param {string=} param0.errorMessage
 * @param {boolean=} param0.openable
 * @param {boolean=} param0.closable
 * @param {any=} param0.parentToScroll
 * @param {boolean=} param0.showPageChanger
 * @param {Function=} param0.onClose
 * @param {boolean=} param0.displayAllPages
 * @param {boolean=} param0.loading
 * @returns
 */
export default function PDFViewer({
  pdf,
  show,
  errorMessage = 'Error cargando el PDF',
  openable = true,
  closable = false,
  parentToScroll = null,
  showPageChanger = true,
  onClose = () => {},
  displayAllPages = false,
  loading: outLoading,
  ...rest
}) {
  const [error, setError] = useState(false)
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)
  const [scale, setScale] = useState(1.4)
  const { width } = useWindowDimensions()
  const constraintsRef = useRef(null)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setError(false)
    setNumPages(numPages)
  }

  useEffect(() => {
    if (width < 600 && scale !== 0.5) {
      setScale(0.5)
      return
    }
    if (width > 600 && width < 900 && scale !== 1.1) {
      setScale(1.1)
      return
    }
    if (width > 900 && scale !== 1.4) return setScale(1.4)
    //eslint-disable-next-line
  }, [width])

  useEffect(() => {
    if (parentToScroll && show) {
      try {
        setTimeout(() => {
          const $pdf = document.getElementById('pdf-viewer')
          parentToScroll.scroll({
            top: parentToScroll.scrollHeight - $pdf?.offsetHeight - 90,
            behavior: 'smooth',
          })
        }, 200)
      } catch (err) {
        console.error(err)
      }
    }
  }, [parentToScroll, show])

  return (
    <motion.div
      className="pdf-viewer"
      id="pdf-viewer"
      style={{ display: show ? 'block' : 'none' }}
      {...rest}
      ref={constraintsRef}
    >
      {outLoading && <ChildSpinner text="Cargando PDF" style={{ height: '50rem' }} />}
      {!pdf && !outLoading && <div className="no-values">Ha ocurrido un error cargando el pdf</div>}
      {pdf && !outLoading && (
        <>
          <Document
            file={pdf}
            onLoadSuccess={onDocumentLoadSuccess}
            error={<div className="no-values">{errorMessage}</div>}
            onLoadError={() => {
              setError(true)
              easyToast('error', errorMessage)
            }}
            loading={<ChildSpinner text="Cargando PDF" style={{ height: '50rem' }} />}
            noData={() => setError(true)}
          >
            {!displayAllPages && <Page pageNumber={pageNumber} scale={scale} />}
            {displayAllPages &&
              Array.apply(null, Array(numPages))
                .map((x, i) => i + 1)
                .map((page, i) => <Page key={i} pageNumber={page} scale={scale} />)}
          </Document>

          {!error &&
            showPageChanger &&
            !displayAllPages &&
            (numPages > 1 || closable || openable) && (
              <motion.div className="pdf-viewer__wrapper" drag dragConstraints={constraintsRef}>
                <div className="pdf-viewer__pages-changer">
                  <div
                    className={pageNumber === 1 ? 'disabled' : ''}
                    onClick={() => {
                      if (pageNumber - 1 >= 1) setPageNumber(pageNumber - 1)
                    }}
                  >
                    ᐊ
                  </div>
                  <div>
                    {pageNumber} de {numPages}
                  </div>
                  <div
                    className={pageNumber === numPages ? 'disabled' : ''}
                    onClick={() => {
                      if (pageNumber + 1 <= numPages) setPageNumber(pageNumber + 1)
                    }}
                  >
                    ᐅ
                  </div>
                </div>

                <div className="pdf-viewer__options">
                  {openable && (
                    <div className="pdf-viewer__open" onClick={() => openPDF(pdf)}>
                      <OpenIcon /> Abrir
                    </div>
                  )}
                  {closable && (
                    <div className="pdf-viewer__close" onClick={onClose}>
                      ✖
                    </div>
                  )}
                </div>
              </motion.div>
            )}
        </>
      )}
    </motion.div>
  )
}
