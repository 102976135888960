export default class Id {
  static encrypt(id) {
    if (!id) return null
    return id

    // const string =
    //   Math.random().toString(36).substring(2, 5) +
    //   id * 33 +
    //   '_' +
    //   Math.random().toString(36).substring(2, 10) +
    //   '_' +
    //   Math.floor(Math.random() * 100 + 1) +
    //   Math.random().toString(36).substring(2, 10)
  }

  static decrypt(string) {
    return string
    // if (string && typeof string === 'string') {
    //   try {
    //     const firstStr = string.split('_')[0]
    //     return parseInt(firstStr.slice(3)) / 33
    //   } catch (err) {
    //     return null
    //   }
    // }
  }
}
