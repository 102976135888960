import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import React, { useState, useEffect } from 'react'
import './NoFilter.scss'

export default function NoFilter() {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
    }, 2000)

    return () => clearTimeout(timeout)
  }, [])

  if (loading) return <ChildSpinner visible={true} tight={false} mintLogo={true} />

  return (
    <div className="no-filter">
      <i className="flaticon-not-found" />
      <b>
        No se ha podido encontrar ningún filtro para este vehículo. Consulte al supervisor
        responsable
      </b>
      <span>No puede utilizar ninguna herramienta de inspección hasta solucionar el problema</span>
    </div>
  )
}
