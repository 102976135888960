import formatEuro from 'myMethods/formatEuro'
import React from 'react'
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts'

const ToolTipComponent = ({ payload, data, unit, formatAsEuros, extraContent, extraKeys }) => {
  try {
    if (payload?.length) {
      let extraKeysData = []
      if (extraKeys?.length && data.length) {
        const currentData = data.find(elem => elem.name === payload[0]?.payload?.name)
        if (currentData) {
          extraKeys.forEach(key => {
            if (key in currentData) extraKeysData.push({ key, value: currentData[key] })
          })
        }
      }
      return (
        <div
          style={{
            backgroundColor: '#efefef',
            opacity: '0.9',
            padding: '0.5rem 1rem',
            borderRadius: '0.4rem',
          }}
        >
          <div style={{ color: '#333', fontWeight: '500' }}>{payload[0]?.payload?.name}</div>
          {payload.map(elem => {
            elem.name = elem.name.replace('_', ' ')
            return (
              <div style={{ color: elem.color, fontWeight: '500' }}>
                {elem.name === 'value' ? '' : elem.name + ': '}
                {formatAsEuros ? formatEuro(elem.payload[elem.name]) : elem.payload[elem.name]}{' '}
                {unit ? unit : ''}
              </div>
            )
          })}
          {extraKeysData.map(({ key, value }) => (
            <div style={{ color: '#9c9c9c', fontWeight: '500' }}>
              {key}: {value}
            </div>
          ))}
          {extraContent && <div>{extraContent}</div>}
        </div>
      )
    } else return <></>
  } catch (err) {
    console.error(err)
    return <></>
  }
}

export default function GradientAreaChart({
  data = [],
  dataKeys = [],
  hiddenDataKeys = [],
  height = 400,
  tooltip = true,
  unit = null,
  formatAsEuros = false,
  YAxisUnit = null,
  title = '',
  legend = true,
  extraTooltipContent = null,
  extraKeysToShowInToolkit = [],
  colors = ['#8884d8', '#82ca9d', '#ffc658', '#E67777', '#CCCCCC', '#444444'],
}) {
  try {
    return (
      <div className="gradient-area-chart">
        <h3 style={{ textAlign: 'center', marginBottom: 0 }}>{title}</h3>
        <ResponsiveContainer width="99%" height={height}>
          <AreaChart
            width={500}
            height={height}
            data={data ?? []}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis unit={YAxisUnit} />
            {tooltip && (
              <Tooltip
                content={({ payload }) => (
                  <ToolTipComponent
                    payload={payload}
                    data={data}
                    unit={unit}
                    formatAsEuros={formatAsEuros}
                    extraContent={extraTooltipContent}
                    extraKeys={extraKeysToShowInToolkit}
                  />
                )}
              />
            )}
            {legend && <Legend />}
            {data &&
              dataKeys &&
              dataKeys.length &&
              dataKeys.map((dataKey, i) => {
                if (hiddenDataKeys.includes(dataKey)) return <></>
                return (
                  <>
                    <defs>
                      <linearGradient id={dataKey.replace(' ', '_')} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={colors[i]} stopOpacity={0.8} />
                        <stop offset="95%" stopColor={colors[i]} stopOpacity={0.1} />
                      </linearGradient>
                    </defs>
                    <Area
                      key={i}
                      type="monotone"
                      dataKey={dataKey}
                      stroke={colors[i]}
                      fill={`url(#${dataKey.replace(' ', '_')})`}
                      fillOpacity={1}
                    />
                  </>
                )
              })}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    )
  } catch (error) {
    console.error(error)
    return <></>
  }
}
