//@ts-check
import React, { useEffect, useReducer, useState } from 'react'
import 'moment/locale/es'
import './StatisticsBilling.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import SimpleBarsChart from 'components/Others/Charts/SimpleBars'
import {
  Close as CloseIcon,
  Person as PersonIcon,
} from '../../../../node_modules/@mui/icons-material/index'
import DeepSearch from 'components/Others/DeepSearch/DeepSearch'
import { getClientsByChunk as getClientsByChunkService } from 'services/Clients'
import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import StatisticsListCard from '../Share/StatisticsListCard/StatisticsListCard'
import StatisticsTools from '../StatisticsTools/StatisticsTools'
import AnimatedCounter from 'components/Others/AnimatedCounter/AnimatedCounter'
import GradientAreaChart from 'components/Others/Charts/GradientAreaChart'

/**
 *
 * @param {{statisticsHandler: import('./../StatisticsHandler').default}} param0
 * @returns
 */
export default function StatisticsBilling({ statisticsHandler }) {
  const statisticsBilling = statisticsHandler?.billing
  const [loading, setLoading] = useState(false)
  const dateRangeText = statisticsBilling?.dateRangeText
  const [showClientModal, setShowClientModal] = useState(false)
  const [, forceUpdate] = useReducer(x => x + 1, 0)
  const selectors = statisticsBilling?.selectors
    ? Object.entries(statisticsBilling.selectors)
        .map(([selector, state]) => (state ? selector : null))
        .filter(elem => elem)
    : []
  const changeBillingData = async (selectedDateRange, removeClient = false) => {
    try {
      if (!selectedDateRange?.length && !removeClient)
        return easyToast('error', 'Seleccione un rango de fechas')
      if (
        removeClient ||
        (!removeClient &&
          JSON.stringify(selectedDateRange) !== JSON.stringify(statisticsBilling.dateRange))
      ) {
        setLoading(true)
        removeClient
          ? await statisticsHandler.removeBillingClient()
          : await statisticsHandler.changeBillingData(selectedDateRange[0], selectedDateRange[1])
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error con el rango de fechas seleccionado')
    } finally {
      setLoading(false)
    }
  }

  const handleClientModalAccept = async client => {
    try {
      if (!(client && client.dni)) throw new Error('No client or client dni provided')
      setLoading(true)
      await statisticsHandler.changeBillingData(null, null, client)
      setShowClientModal(false)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error filtrando por el cliente seleccionado')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const abortController = new AbortController()
    try {
      if (
        statisticsHandler &&
        (!statisticsBilling?.initialized ||
          (statisticsHandler?.dateRange?.length &&
            JSON.stringify(statisticsBilling.dateRange) !==
              JSON.stringify(statisticsHandler.dateRange)))
      ) {
        const initialize = async () => {
          setLoading(true)
          await statisticsHandler.initializeBilling()
          setLoading(false)
        }
        try {
          initialize()
        } catch (err) {
          console.error(err)
          throw err
        }
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando los datos. Seleccione una fecha')
    }
    return () => abortController.abort()
  }, [statisticsHandler, statisticsBilling?.initialized, setLoading, statisticsBilling?.dateRange])

  if (loading) {
    return <ChildSpinner text="Cargando facturación..." mintLogo={true} />
  }
  console.log({ statisticsBilling })
  return (
    <>
      <div className="statistics-billing">
        <StatisticsTools
          onAccept={changeBillingData}
          loading={loading}
          dateRange={statisticsBilling?.dateRange || []}
          dateRangeText={dateRangeText}
          calendarInfo={
            <div className="statistics-billing__range-info">
              <i className="flaticon-analytics" />
              <div>
                <span>De 1 día a 90 días: datos en días</span>
                <span>De 3 meses a 2 años: datos en meses</span>
                <span>Más de 2 años: datos en años</span>
              </div>
            </div>
          }
          otherComponents={
            <div
              className="statistics-billing__selected-client"
              onClick={() => setShowClientModal(true)}
            >
              <PersonIcon />
              <span>
                {statisticsBilling?.client
                  ? `${statisticsBilling.client.dni} - ${statisticsBilling.client.name}`
                  : 'Filtrar por cliente'}
              </span>
              <div
                className="statistics-billing__selected-client-clear"
                onClick={e => {
                  e.stopPropagation()
                  changeBillingData([], true)
                }}
              >
                {statisticsBilling?.client && <CloseIcon />}
              </div>
            </div>
          }
          exportation={{
            type: 'STATISTICS_BILLING_SUMMARY',
            data: {
              values: {
                ...statisticsBilling?.data?.totalRange,
                topClients: statisticsBilling?.topClients,
                users: statisticsBilling?.users,
              },
            },
            options: {
              dateRange: statisticsBilling?.dateRange,
              client: statisticsBilling?.client,
            },
            modalTitle: 'Exportar resumen de facturación',
            defaultInputValue: 'RESUMEN DE FACTURACIÓN',
          }}
        />
        <div className="statistics__main-info" data-aos="fade-up">
          <CardNameValue
            className="statistics__info-card"
            name="TOTAL VENTAS"
            value={
              <AnimatedCounter number={statisticsBilling?.data?.totalRange?.amount ?? 0} unit="€" />
            }
          />
          <CardNameValue
            className="statistics__info-card"
            name="TOTAL COBROS"
            value={<AnimatedCounter number={statisticsBilling?.data?.totalRange?.total ?? 0} />}
          />
          {selectors.includes('Efectivo') && (
            <CardNameValue
              className="statistics__info-card"
              name="TOTAL COBROS EFECTIVO"
              value={
                <AnimatedCounter
                  number={statisticsBilling?.data?.totalRange?.efectivo?.total ?? 0}
                />
              }
              color="#8884d8"
            />
          )}
          {selectors.includes('Tarjeta') && (
            <CardNameValue
              className="statistics__info-card"
              name="TOTAL COBROS TARJETA"
              value={
                <AnimatedCounter
                  number={statisticsBilling?.data?.totalRange?.tarjeta?.total ?? 0}
                />
              }
              color="#82ca9d"
            />
          )}
          {selectors.includes('Albaranes') && (
            <CardNameValue
              className="statistics__info-card"
              name="TOTAL COBROS ALBARANES"
              value={
                <AnimatedCounter
                  number={
                    (statisticsBilling?.data?.totalRange?.dealsBilling?.total ?? 0) +
                    (statisticsBilling?.data?.totalRange?.dealsNotBilling?.total ?? 0)
                  }
                />
              }
              color="#ffc658"
            />
          )}
        </div>
        <div className="statistics__selectors" data-aos="fade-up" data-aos-delay="100">
          <Checkbox
            label="Efectivo"
            color="#8884d8"
            onChange={checked => {
              statisticsHandler?.toggleBillingSelectorState('Efectivo', checked)
              forceUpdate()
            }}
            checked={selectors.includes('Efectivo')}
          />
          <Checkbox
            label="Tarjeta"
            color="#82ca9d"
            onChange={checked => {
              statisticsHandler?.toggleBillingSelectorState('Tarjeta', checked)
              forceUpdate()
            }}
            checked={selectors.includes('Tarjeta')}
          />
          <Checkbox
            label="Albaranes"
            color="#ffc658"
            onChange={checked => {
              statisticsHandler?.toggleBillingSelectorState('Albaranes', checked)
              forceUpdate()
            }}
            checked={selectors.includes('Albaranes')}
          />
        </div>
        <div data-aos="fade-up" data-aos-delay="100">
          <GradientAreaChart
            data={statisticsBilling?.areaChartData}
            dataKeys={statisticsBilling?.rawSelectors}
            hiddenDataKeys={statisticsBilling?.rawSelectors?.filter(
              sel => !selectors.includes(sel)
            )}
            formatAsEuros={true}
            YAxisUnit="€"
            title="Total ventas"
            height={300}
            extraKeysToShowInToolkit={['Total facturado']}
            colors={['#8884d8', '#82ca9d', '#ffc658', '#E67777']}
          />
        </div>
        <div className="statistics-billing__bottom" data-aos="fade-up" data-aos-delay="200">
          <SimpleBarsChart
            data={statisticsBilling?.barsChartData}
            dataKey="Total"
            hiddenNames={statisticsBilling?.rawSelectors?.filter(sel => !selectors.includes(sel))}
            tooltip={true}
            height={250}
            formatAsEuros={true}
            YAxisUnit="€"
            colors={['#8884d8', '#82ca9d', '#ffc658']}
            style={{ gridColumn: statisticsBilling?.client ? 'x0/x2' : '' }}
          />
          <StatisticsListCard
            title="USUARIOS INTERVINIENTES"
            items={statisticsBilling?.users?.map(user => ({
              name: user.username,
              value: user.amount,
            }))}
            unit="€"
          />
          {!statisticsBilling?.client && (
            <StatisticsListCard
              title="TOP 10 CLIENTES"
              items={statisticsBilling?.topClients?.map(client => ({
                name: `${client.name} - ${client.dni}`,
                value: client.amount,
              }))}
              unit="€"
            />
          )}
        </div>
      </div>

      <DeepSearch
        open={showClientModal}
        onClose={() => setShowClientModal(false)}
        title="Filtrar por cliente"
        message="Seleccione un cliente"
        columnsKey="clients"
        filterServiceOptions={{
          likeDni: true,
          likeName: true,
        }}
        service={getClientsByChunkService}
        onAccept={handleClientModalAccept}
      />
    </>
  )
}
