import CardNameValue from 'components/Others/Cards/CardNameValue/CardNameValue'
import ErrorView from 'components/Others/ErrorView/ErrorView'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import formatNumberToString from 'myMethods/formatNumberToString'
import formatTimeToHoursMinutesSeconds from 'myMethods/formatTimeToHoursMinutesSeconds'
import React from 'react'
import './ListOfStatisticsInspectionTests.scss'

export default function ListOfStatisticsInspectionTests({ tests }) {
  try {
    if (!tests || !Object.values(tests).length) return <div className="no-values">Sin tests</div>
    return (
      <div className="list-of-statistics-inspection-tests">
        {Object.entries(tests)
          ?.sort((a, b) => a[0] - b[0])
          ?.map(([testName, data]) => {
            const averageTries = data.inspections / data.number
            const averageRepetitions = data.repetitions / data.inspections
            return (
              <div className="list-of-statistics-inspection-tests__test">
                <div className="list-of-statistics-inspection-tests__test-head">{testName}</div>
                <div className="list-of-statistics-inspection-tests__test-body">
                  <ListItemsInTwoColumns>
                    <CardNameValue name="Inspecciones" value={data.inspections} />
                    <CardNameValue
                      name="Tiempo medio"
                      value={formatTimeToHoursMinutesSeconds(data.halfTime)}
                    />
                    <CardNameValue
                      name="Realizaciones medias"
                      value={formatNumberToString(averageTries < 1 ? 1 : averageTries)}
                    />
                    <CardNameValue
                      name="Repeticiones medias"
                      value={formatNumberToString(averageRepetitions < 1 ? 1 : averageRepetitions)}
                    />
                  </ListItemsInTwoColumns>
                </div>
              </div>
            )
          })}
      </div>
    )
  } catch (err) {
    console.error(err)
    return <ErrorView />
  }
}
