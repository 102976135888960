//@ts-check
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  clearReduxInspection,
  setReduxInspectionIsFiltered,
  setReduxInspectionShowPreinspectionModal,
} from 'redux/ducks/inspectionDuck'
import AdditionalSheetIcon from '@mui/icons-material/NoteAdd'
import manualPdf from 'resources/defectology/manuals/inspectionManual.pdf'

import easyToast from 'components/Others/EasyToast/easyToast'
import {
  putInspectionInspector as putInspectionInspectorService,
  putInspectionLine as putInspectionLineService,
} from 'services/Inspection'
import Id from 'Share/Id'
import useLogin from 'hooks/useLogin'
import Modal from 'components/Others/Modal/Modal'
import ItemPicker from 'components/Others/ItemPicker/ItemPicker'
import SelectAxles from 'components/Inspection/SelectAxles/SelectAxles'
import Spinner from 'components/Others/Spinner/Spinner'
import PDFModal from 'components/Others/PDFModal/PDFModal'
import useService from 'hooks/useService'
import openPDF from 'myMethods/openPDF'
import MechanicForm from 'components/Inspection/MechanicForm/MechanicForm'
import TireEquivalence from 'components/Inspection/Calculations/TireEquivalence/TireEquivalence'
import AdditionalSheetPrinter from 'components/Inspection/AdditionalSheetPrinter/AdditionalSheetPrinter'
import useSessionStorage from 'hooks/useSessionStorage'
import Calculations from 'components/Inspection/Calculations/Calculations'
import AnimatedList from 'components/Others/AnimatedList/AnimatedList'

export default function InspectionDrawer({ redux, close, onSupervision }) {
  const { logout } = useLogin()
  const [modalsShow, setModalsShow] = useState({
    line: false,
    exit: false,
    axles: false,
    manualPdf: false,
    mechanic: false,
    calculations: false,
    additionalSheet: false,
  })
  const dispatch = useDispatch()
  const history = useHistory()
  const line = redux.inspection.line || window.sessionStorage.getItem('line')
  const IS_SECOND_INSPECTION = redux?.inspection?.inspectionType?.includes('SEGUNDA INSPECCIÓN')
  const { exitInspectionOrSupervisionSS } = useSessionStorage()

  const { fetch: putInspectionLine, loading: loading1 } = useService({
    service: putInspectionLineService,
  })
  const { fetch: putInspectionInspector, loading: loading2 } = useService({
    service: putInspectionInspectorService,
  })

  const loading = loading1 || loading2
  const handleFilterBtn = () => {
    redux.inspection.isFiltered
      ? dispatch(setReduxInspectionIsFiltered(false))
      : dispatch(setReduxInspectionIsFiltered(true))
  }

  const handlePreinspectionBtn = () => {
    history.replace(`/new_inspection/${sessionStorage.getItem('license')}/defects`)
    dispatch(setReduxInspectionShowPreinspectionModal(true))
  }

  const handleModalLineAccept = async line => {
    try {
      if (!line) throw new Error('No line provided')

      await putInspectionLine({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        line,
      })
      window.sessionStorage.setItem('line', line)
      easyToast('dark', `Línea ${line} seleccionada`)
      setModalsShow(prevState => ({ ...prevState, line: false }))
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un problema seleccionando la línea, inténtelo de nuevo')
    }
  }

  const exitInspection = async (makeLogout = false) => {
    try {
      await putInspectionInspector({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        operation: onSupervision ? 'exitSupervision' : 'exitInspection',
      })
      exitInspectionOrSupervisionSS()
      dispatch(clearReduxInspection())
      makeLogout ? logout() : history.replace('/new_inspection')
    } catch (err) {
      console.error(err)
      easyToast(
        'error',
        `Ha ocurrido un error saliendo de la ${
          onSupervision ? 'supervisión' : 'inspección'
        }. Inténtelo de nuevo`
      )
    }
  }

  history.listen(() => {
    if (window.sessionStorage.getItem('prompt') === 'true' && window.sessionStorage.getItem('id')) {
      setModalsShow(prevState => ({ ...prevState, exit: true }))
    }
  })

  const Elem = ({ icon, text, onClick, closeAfterOnClick = true, showOnSupervision = false }) => {
    return (
      (!onSupervision || (onSupervision && showOnSupervision)) && (
        <div
          onClick={() => {
            onClick && onClick()

            closeAfterOnClick && close()
          }}
        >
          {icon && icon}
          {text && <span>{text}</span>}
        </div>
      )
    )
  }

  return (
    <>
      <Spinner visible={loading} />
      <Elem
        icon={<i className="flaticon-curve-arrow" />}
        text={onSupervision ? 'SALIR DE LA SUPERVISIÓN' : 'SALIR DE LA INSPECCIÓN'}
        onClick={() => setModalsShow(prevState => ({ ...prevState, exit: true }))}
        showOnSupervision={true}
      />
      <Elem
        icon={
          redux.inspection.isFiltered ? (
            <i className="flaticon-filter-1" />
          ) : (
            <i className="flaticon-filter" />
          )
        }
        text={
          redux.inspection.isFiltered
            ? 'DESACTIVAR FILTRO DE DEFECTOS (PRECAUCIÓN)'
            : 'ACTIVAR FILTRO DE DEFECTOS'
        }
        onClick={handleFilterBtn}
      />

      <Elem
        icon={<i className="flaticon-setting-lines" />}
        text="EDITAR DEFECTOS OPCIONALES"
        onClick={handlePreinspectionBtn}
      />
      <Elem
        icon={<i className="flaticon-lane" />}
        text={`CAMBIAR LÍNEA POR DEFECTO ${line ? `(LÍNEA ${line})` : ''}`}
        onClick={() => setModalsShow(prevState => ({ ...prevState, line: true }))}
      />
      <Elem
        icon={<i className="flaticon-axle" />}
        text="CAMBIAR NÚMERO DE EJES Y REMOLQUE"
        onClick={() => setModalsShow(prevState => ({ ...prevState, axles: true }))}
      />

      <Elem
        icon={<i className="flaticon-copy-two-paper-sheets-interface-symbol" />}
        text="VER MANUAL ITV COMPLETO"
        onClick={() => openPDF(manualPdf, 'manual_itv.pdf', 'url')}
        showOnSupervision={true}
      />
      {IS_SECOND_INSPECTION && (
        <Elem
          icon={<i className="flaticon-diagnostic" />}
          text="IDENTIFICAR TALLER"
          onClick={() => setModalsShow(prevState => ({ ...prevState, mechanic: true }))}
          showOnSupervision={true}
        />
      )}
      <Elem
        icon={<i className="flaticon-calculator" />}
        text="CÁLCULOS"
        onClick={() => setModalsShow(prevState => ({ ...prevState, calculations: true }))}
        showOnSupervision={true}
      />
      <Elem
        icon={<i className="flaticon-calculator" />}
        text="CÁLCULO DE FALDILLAS INTERMEDIAS"
        onClick={() => setModalsShow(prevState => ({ ...prevState, flaps: true }))}
        showOnSupervision={true}
      />
      <Elem
        icon={<AdditionalSheetIcon />}
        text="GENERAR HOJA ADICIONAL"
        onClick={() => setModalsShow(prevState => ({ ...prevState, additionalSheet: true }))}
        showOnSupervision={true}
      />

      {modalsShow.line && (
        <ItemPicker
          title="Seleccione una línea"
          values={redux.config.itv.lines}
          currentValue={line}
          onClose={() => setModalsShow(prevState => ({ ...prevState, line: false }))}
          onAccept={handleModalLineAccept}
        />
      )}
      {modalsShow.manualPdf && (
        <PDFModal
          pdf={manualPdf}
          closeModal={() => setModalsShow(prevState => ({ ...prevState, manualPdf: false }))}
          displayAllPages={true}
        />
      )}
      <Modal
        open={modalsShow.exit}
        onClose={() => {
          setModalsShow(prevState => ({ ...prevState, exit: false }))
          window.sessionStorage.setItem('prompt', 'false')
        }}
        title="¿Salir de la inspección?"
        alertMessage="No se perderá ningún dato"
        buttons={true}
        onAccept={exitInspection}
      />
      <SelectAxles
        show={modalsShow.axles}
        onClose={() => setModalsShow(prevState => ({ ...prevState, axles: false }))}
      />
      {modalsShow.mechanic && (
        <MechanicForm
          inspectionConfig={redux?.inspection?.config}
          onClose={() => setModalsShow(prevState => ({ ...prevState, mechanic: false }))}
        />
      )}
      {(modalsShow.tireEquivalence || modalsShow.flaps) && (
        <TireEquivalence
          onClose={() =>
            setModalsShow(prevState => ({ ...prevState, tireEquivalence: false, flaps: false }))
          }
          flaps={modalsShow.flaps}
        />
      )}
      {modalsShow.calculations && (
        <Calculations
          closeModal={() => setModalsShow(prevState => ({ ...prevState, calculations: false }))}
        />
      )}
      {modalsShow.additionalSheet && (
        <AdditionalSheetPrinter
          onClose={() => setModalsShow(prevState => ({ ...prevState, additionalSheet: false }))}
        />
      )}
    </>
  )
}
