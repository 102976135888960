import React, { useEffect, useState } from 'react'

import './Status.scss'
import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import openPDF from 'myMethods/openPDF'
import { postReportPreview as postReportPreviewService } from 'services/Reports'
import Id from 'Share/Id'
import PDFViewer from 'components/Others/PDFViewer/PDFViewer'
import useService from 'hooks/useService'
import LottieAnimation from 'components/Others/LottieAnimation/LottieAnimation'
import checkAnimationData from 'lottie/check.json'
import errorAnimationData from 'lottie/error.json'
import ErrorView from 'components/Others/ErrorView/ErrorView'

export default function Status({ errors, reportDownloader = true }) {
  const [loading, setLoading] = useState(true)
  const [showPDF, setShowPDF] = useState(false)
  const [pdf, setPdf] = useState(null)
  const [pdfError, setPdfError] = useState(false)
  const hasErrors = errors && Object.values(errors).some(value => value)

  const {
    fetch: postReportPreview,
    loading: loadingPDF,
    error: hasFetchReportError,
  } = useService({
    service: postReportPreviewService,
  })

  console.log({ errors })

  useEffect(() => {
    if (reportDownloader && !pdf && !loadingPDF && !hasFetchReportError) {
      postReportPreview({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
      })
        .then(res => {
          if (res && res instanceof Blob) {
            return setPdf(res)
          } else throw new Error('No PDF response or not a Blob')
        })
        .catch(err => {
          console.error(err)
          setPdfError(true)
        })
    }
  }, [hasFetchReportError, loadingPDF, pdf, postReportPreview, reportDownloader])

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 2500)

    return () => clearTimeout(timer)
  }, [])

  return (
    <section className="status">
      {loading && <ChildSpinner mintLogo={true} />}
      {!loading && (
        <>
          <div className={hasErrors ? 'status__title--has-errors' : 'status__title'}>
            <LottieAnimation
              className="status__animation"
              animationData={hasErrors ? errorAnimationData : checkAnimationData}
              loop={false}
            />
            <h3>{hasErrors ? 'ERRORES EN LA INSPECCIÓN' : '¡ INSPECCIÓN SIN ERRORES !'}</h3>
          </div>
          {hasErrors && (
            <div className="status__errors-list">
              {errors?.traceability && errors?.traceability?.length > 0 && (
                <div className="status__error">
                  <div>DEFECTOS SIN INSPECCIONAR</div>
                  <div className="status__error-detail">{errors.traceability.join(', ')}</div>
                </div>
              )}
              {errors?.cuentakilometros && (
                <div className="status__error">
                  <div>CUENTAKILÓMETROS</div>
                  <div className="status__error-detail">
                    No hay lectura de cuentakilómetros/cuentahoras
                  </div>
                </div>
              )}
              {errors?.images?.length > 0 && (
                <div className="status__error">
                  <div>IMÁGENES NO CAPTURADAS</div>
                  <div className="status__error-detail">{errors.images.join(', ')}</div>
                </div>
              )}
              {errors?.tests?.length > 0 && (
                <div className="status__error">
                  <div>TESTS NO REALIZADOS</div>
                  <div className="status__error-detail">{errors.tests.join(', ')}</div>
                </div>
              )}
            </div>
          )}
          {reportDownloader && (
            <>
              {pdfError && (
                <ErrorView
                  text="Ha ocurrido un error cargando el borrador del informe"
                  errorColor={true}
                />
              )}
              {!pdfError && (
                <>
                  <MyButtonsContainer>
                    {!showPDF && (
                      <MyButton
                        text="Ver borrador"
                        onClick={() => setShowPDF(!showPDF)}
                        loading={loadingPDF}
                      />
                    )}
                    <MyButton
                      text="Abrir borrador"
                      onClick={() => openPDF(pdf)}
                      loading={loadingPDF}
                    />
                  </MyButtonsContainer>
                  <PDFViewer
                    pdf={pdf}
                    show={showPDF}
                    closable={true}
                    loading={loadingPDF}
                    onClose={() => setShowPDF(false)}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </section>
  )
}
