//@ts-check
import React, { useState } from 'react'
import './ChatbotHeader.scss'
import {
  InfoCircle as DetailIcon,
  List as MenuIcon,
  PlusLg as AddIcon,
} from 'react-bootstrap-icons'
import Logo from 'logo/favicon-384x384.png'

/**
 * @typedef {import('types/UseMintAIChatbot').UseMintAIChatbot} UseChatbot
 */

/**
 *
 * @param {{chatbot: UseChatbot, isMobile: boolean, openDrawer: Function}} param0
 * @returns
 */

export default function ChatbotHeader({ chatbot, isMobile, openDrawer }) {
  return (
    <>
      <div className={isMobile ? 'chatbot-header--mobile' : 'chatbot-header'}>
        <div className="chatbot-header__avatar">
          {isMobile && (
            <button onClick={openDrawer}>
              <MenuIcon />
            </button>
          )}
          <img src={Logo} alt="MintAI" />
        </div>
        <div className="chatbot-header__info">
          <div className="chatbot-header__name">{chatbot?.data?.name || 'MintAI'}</div>
        </div>
        {/* <div className="chatbot-header__options">
          <HoverTooltip title={t(tKeys.chatbot.header.details.tooltip)}>
            <button className="chatbot-header__action" onClick={() => setShowDetailModal(true)}>
              <DetailIcon />
            </button>
          </HoverTooltip>
          {isMobile && !chatbot.hasReachedMaxChats && (
            <button
              disabled={chatbot?.loadingNewChat}
              className="chatbot-header__action"
              onClick={chatbot?.newChat}
            >
              <AddIcon />
            </button>
          )}
        </div> */}
      </div>
    </>
  )
}
