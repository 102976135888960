// constantes
const initialData = {
  status: null,
  names: [],
  selectedCashRegister: null,
  cashSelectorValues: {},
  isChanging: false,
}

// types
const SET_STATUS_SUCCESS = 'SET_STATUS_SUCCESS'
const SET_NAMES_SUCCESS = 'SET_NAMES_SUCCESS'
const SET_SELECTED_CASH_REGISTER_SUCCESS = 'SET_SELECTED_CASH_REGISTER_SUCCESS'

const SET_CASH_SELECTOR_VALUES_SUCCESS = 'SET_CASH_SELECTOR_VALUES_SUCCESS'
const SET_IS_CHANGING_SUCCESS = 'SET_IS_CHANGING_SUCCESS'

// reducer
export default function cashRegisterReducer(state = initialData, action) {
  switch (action.type) {
    case SET_STATUS_SUCCESS:
      return { ...state, status: action.payload }
    case SET_NAMES_SUCCESS:
      return { ...state, names: action.payload }
    case SET_SELECTED_CASH_REGISTER_SUCCESS:
      return { ...state, selectedCashRegister: action.payload }

    case SET_CASH_SELECTOR_VALUES_SUCCESS:
      return { ...state, cashSelectorValues: action.payload }
    case SET_IS_CHANGING_SUCCESS:
      return { ...state, isChanging: action.payload }
    default:
      return state
  }
}

// actions

export const setReduxCashRegisterStatus = data => dispatch => {
  try {
    dispatch({
      type: SET_STATUS_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxCashRegisterNames = data => dispatch => {
  try {
    dispatch({
      type: SET_NAMES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxCashSelectorValues = data => dispatch => {
  try {
    dispatch({
      type: SET_CASH_SELECTOR_VALUES_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxSelectedCashRegister = data => dispatch => {
  try {
    dispatch({
      type: SET_SELECTED_CASH_REGISTER_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}

export const setReduxCashRegisterIsChanging = data => dispatch => {
  try {
    dispatch({
      type: SET_IS_CHANGING_SUCCESS,
      payload: data,
    })
  } catch (err) {
    console.error(err)
  }
}
