//@ts-check
import React from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import AnimatedListElem from './AnimatedListElem'

/**
 * AnimatedList Component
 *
 * @param {Object} props - The component props
 * @param {string=} [props.className] - Additional class names for the container
 * @param {React.ReactNode} props.children - Children nodes to be animated
 * @returns {JSX.Element} The animated list component
 */
export default function AnimatedList({ className, children }) {
  if (!children) {
    console.error('No children provided')
    return <div className="no-values">Ha ocurrido un error cargando la lista</div>
  }

  return (
    <motion.div className={className ? ` ${className}` : ''}>
      <AnimatePresence>
        {Array.isArray(children) ? (
          children.map((child, i) => (
            <AnimatedListElem index={i} key={i}>
              {child}
            </AnimatedListElem>
          ))
        ) : (
          <AnimatedListElem index={0}>{children}</AnimatedListElem>
        )}
      </AnimatePresence>
    </motion.div>
  )
}
