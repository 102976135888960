import React from 'react'

// MY IMPORTS
import 'components/Others/Table/Table.scss'

export default function Table({
  columnsNames = [],
  values = [],
  onClick,
  className,
  noValuesText = 'No hay datos',
}) {
  if (!values.length) return <div className="no-values">{noValuesText}</div>
  return (
    <section className="table-wrapper">
      <table className={`table${className ? ' ' + className : ''}`}>
        <thead>
          <tr>
            {columnsNames.map(elem => (
              <th scope="col" key={elem}>
                {elem}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {values?.length > 0 &&
            values.map(elem => {
              return (
                <tr
                  key={elem.id + Math.random()}
                  onClick={() => {
                    onClick && onClick(elem.id)
                  }}
                >
                  {elem.data.map(el => (
                    <td key={el}>{el}</td>
                  ))}
                </tr>
              )
            })}
        </tbody>
      </table>
    </section>
  )
}
