import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import ClockHandler from 'Share/Clock'

// MY IMPORTS
import 'components/Inspection/Clock/Clock.scss'

export default function Clock({
  dateFrom,
  seconds = false,
  icon = true,
  label = false,
  className,
  date,
  ...rest
}) {
  const [time, setTime] = useState(null)

  useEffect(() => {
    try {
      if (dateFrom) {
        if (!time) {
          setTime(ClockHandler.getElapsedTimeString(dateFrom))
        } else {
          const timeout = setInterval(
            () => {
              setTime(ClockHandler.getElapsedTimeString(dateFrom))
            },
            seconds ? 1 : 15000
          )

          return () => clearTimeout(timeout)
        }
      }
    } catch (error) {
      console.error(error)
    }
  }, [dateFrom, time, seconds])

  return (
    <div className={`${className || ''}${label ? ' clock--label' : ' clock'}`} {...rest}>
      {icon && <i className="flaticon-chronograph-watch" />}
      <div className="clock__time">{time}</div>
      {label && <div>{label}</div>}
    </div>
  )
}
