//@ts-check
import React from 'react'
import Switch from 'components/Others/Switch/Switch'
import './OpacimetroConfig.scss'
import TestConfigWrapper from '../../../TestConfigWrapper/TestConfigWrapper'
import ToggleButtons from 'components/Others/Buttons/ToggleButtons/ToggleButtons'
import Input from 'components/Others/Input/Input'

export default function OpacimeterConfig({ setValues, values, disabled = false }) {
  return (
    <TestConfigWrapper testName="Opacímetro" disabled={disabled}>
      <div className="opacimetro-config">
        <div className="opacimetro-config__switch">
          <span className="opacimetro-config__title">Turbo</span>
          <Switch
            name="turbo"
            value={values?.turbo}
            onChange={checked => setValues({ ...values, turbo: checked })}
            disabled={disabled}
          />
        </div>
        <ToggleButtons
          label="EURO"
          buttons={[
            { text: '3 y anterior', value: 3 },
            { text: '4', value: 4 },
            { text: '5', value: 5 },
            { text: '5', value: 6 },
          ]}
          selectedValue={values?.euro}
          handleChange={value => setValues({ ...values, euro: value })}
          disabled={disabled}
        />

        <div className="opacimetro-config__plate">
          <Input
            name="plate"
            isFormikInput={false}
            label="Valor de placa"
            value={values?.plate}
            onChange={e => setValues({ ...values, plate: e.target.value })}
            disabled={disabled}
          />
        </div>
      </div>
    </TestConfigWrapper>
  )
}
