//@ts-check
import React, { useState } from 'react'
import DateTimeITV from 'Share/DateTimeITV'
import SuperList from 'components/Others/SuperList/SuperList'
import useInspectionRemarks from 'hooks/useInspectionRemarks'
import './ListOfRemarks.scss'
import {
  PersonFill as UserIcon,
  PencilFill as EditIcon,
  TrashFill as DeleteIcon,
  FloppyFill as SaveIcon,
} from 'react-bootstrap-icons'
import getCssVariable from 'myMethods/getCssVariable'
import AddRemarkModal from './AddRemarkModal/AddRemarkModal'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'
import Input from 'components/Others/Input/Input'
import easyToast from 'components/Others/EasyToast/easyToast'
import sortArrayOfObjectsByDate from 'myMethods/sortArrayOfObjectsByDate'
import ModalDefect from '../Defectology/Modal/ModalDefect/ModalDefect'

const colors = {
  general: getCssVariable('--general-remark-color'),
  internal: getCssVariable('--internal-remark-color'),
  defect: getCssVariable('--defect-remark-color'),
}

const Remark = ({ remark, i, removeRemark, loading, editRemark, onlyRead }) => {
  const [enableEdition, setEnableEdition] = useState(false)
  const [inputValue, setInputValue] = useState(remark?.text ?? '')
  const [showModalDefect, setShowModalDefect] = useState(false)

  const formatType = (type, defectId) => {
    switch (type) {
      case 'general':
        return 'General'
      case 'internal':
        return 'Interna'
      case 'defect':
        if (defectId) return 'Defecto ' + defectId
        return 'Defecto'
      default:
        return ''
    }
  }

  return (
    <>
      <div className="list-of-remarks__remark contrast-on-hover" key={i}>
        {!onlyRead && (
          <div className="list-of-remarks__options">
            {!enableEdition && (
              <HoverTooltip text="Eliminar">
                <DeleteIcon
                  className="list-of-remarks__remove"
                  style={{
                    pointerEvents: loading ? 'none' : 'auto',
                  }}
                  onClick={() => removeRemark(remark)}
                />
              </HoverTooltip>
            )}
            {!enableEdition && (
              <HoverTooltip text="Editar">
                <EditIcon
                  onClick={() =>
                    remark.defectId ? setShowModalDefect(true) : setEnableEdition(true)
                  }
                />
              </HoverTooltip>
            )}
            {enableEdition && (
              <HoverTooltip>
                <SaveIcon
                  onClick={async () => {
                    if (!inputValue || inputValue === '')
                      return easyToast('error', 'Inserte una observación')
                    await editRemark(remark, inputValue)
                    setEnableEdition(false)
                  }}
                />
              </HoverTooltip>
            )}
          </div>
        )}
        {!enableEdition && <div className="list-of-remarks__text">«{remark.text}»</div>}
        {enableEdition && (
          <Input
            className="list-of-remarks__input"
            type="textarea"
            isFormikInput={false}
            value={inputValue}
            onChange={e => setInputValue(e.target.value)}
            name="remark"
          />
        )}
        <div className="list-of-remarks__date">{DateTimeITV.formatDate(remark.createdAt)}</div>
        <div className="list-of-remarks__details">
          <div
            className="list-of-remarks__type"
            style={{ background: colors[remark.type], color: '#fff' }}
          >
            <span>{formatType(remark.type, remark.defectId)}</span>
          </div>
          <div className="list-of-remarks__user">
            <UserIcon />
            <span>{remark.user}</span>
          </div>
        </div>
      </div>
      {showModalDefect && (
        <ModalDefect defectId={remark?.defectId} closeModal={() => setShowModalDefect(false)} />
      )}
    </>
  )
}

export default function ListOfRemarks({ onlyRead = false }) {
  const [types, setTypes] = useState(['general', 'internal', 'defect'])
  const [showAddModal, setShowAddModal] = useState(false)

  const { remarks, removeRemark, editRemark, loadingStates } = useInspectionRemarks({ types })

  return (
    <div className="list-of-remarks">
      <SuperList
        noItemsMessage="Sin observaciones"
        search={true}
        showAddButton={true}
        onAddButton={() => setShowAddModal(true)}
        options={[
          {
            text: 'Generales',
            color: colors.general,
            checked: types.includes('general'),
            counter: remarks?.filter(r => r.type === 'general').length,
            onClick: () =>
              setTypes(types =>
                types.includes('general')
                  ? types.filter(t => t !== 'general')
                  : [...types, 'general']
              ),
          },
          {
            text: 'Internas',
            color: colors.internal,
            counter: remarks?.filter(r => r.type === 'internal').length,
            checked: types.includes('internal'),
            onClick: () =>
              setTypes(types =>
                types.includes('internal')
                  ? types.filter(t => t !== 'internal')
                  : [...types, 'internal']
              ),
          },
          {
            text: 'Defectos',
            color: colors.defect,
            checked: types.includes('defect'),
            counter: remarks?.filter(r => r.type === 'defect').length,
            onClick: () =>
              setTypes(types =>
                types.includes('defect') ? types.filter(t => t !== 'defect') : [...types, 'defect']
              ),
          },
        ]}
      >
        {sortArrayOfObjectsByDate(remarks, 'createdAt', false).map((remark, i) => (
          <Remark
            key={i}
            remark={remark}
            i={i}
            removeRemark={removeRemark}
            editRemark={editRemark}
            loading={loadingStates.loadingRemoveRemark || loadingStates.loadingEditRemark}
            onlyRead={onlyRead}
          />
        ))}
      </SuperList>
      {showAddModal && <AddRemarkModal closeModal={() => setShowAddModal(false)} />}
    </div>
  )
}
