import * as Yup from 'yup'

export function user(isNew, allUsers) {
  const schemaObject = {
    fullname: Yup.string()
      .required('Requerido')
      .min(3, '   ❌ MUY CORTO')
      .max(45, '   ❌ MUY LARGO'),
    role: Yup.string().required('Requerido'),
    activeUntil: Yup.date(),
    inspectionRestrictions: Yup.object().shape({
      vehicles: Yup.array().of(Yup.string()),
      notInspectLicenses: Yup.array().of(Yup.string()),
    }),
  }
  if (isNew) {
    schemaObject.username = Yup.string()
      .required('Requerido')
      .min(3, '   ❌ MÍNIMO 3 CARACTERES')
      .max(5, '   ❌ MÁXIMO 5 CARACTERES')
      .test(
        'username-unique',
        '   ❌ ESTE USUARIO YA EXISTE',
        value =>
          !allUsers?.find(user => {
            console.log(user.username, value?.toUpperCase())
            return user.username === value?.toUpperCase()
          })
      )
  }
  return Yup.object().shape(schemaObject)
}
