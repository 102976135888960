import getCssVariable from 'myMethods/getCssVariable'
import React from 'react'

export default function ErrorView({
  text = 'Ha ocurrido un error cargando los datos',
  errorColor = false,
  ...rest
}) {
  return (
    <div
      className="no-values"
      style={{ margin: '1rem auto', color: errorColor ? getCssVariable('--error-color') : '#333' }}
      {...rest}
    >
      {text}
    </div>
  )
}
