import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_BILLING_URL

export function getAllDiscounts(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}discounts`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function postDiscount(data, options = {}) {
  return Axios.post(`${SERVER_URL}/api/${v2}discounts`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function putDiscount(data, options = {}) {
  return Axios.put(`${SERVER_URL}/api/${v2}discounts`, data, {
    ...getJWT(),
    signal: options.signal,
  })
}
