//@ts-check
import React from 'react'
import { Switch as MuiSwitch } from '@mui/material'

// MY IMPORTS
import 'components/Others/Switch/Switch.scss'
import isValidFunction from 'myMethods/isValidFunction'

/**
 *
 * @param {{name?:string, value: boolean, label?: string, label2?: string, onChange: function, disabled?: boolean}} param0
 * @returns
 */
export default function Switch({ name, value, label, label2, onChange, disabled = false }) {
  return (
    <div className="switch-container">
      {label && <label>{label}</label>}
      <div className="switch">
        <MuiSwitch
          checked={value}
          onChange={e => {
            isValidFunction(onChange) && onChange(e.target.checked)
          }}
          name={name}
          disabled={disabled}
        />
      </div>
      {label2 && <label>{label2}</label>}
    </div>
  )
}
