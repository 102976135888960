import React from 'react'

// MY IMPORTS
import Clients from 'components/Office/Clients/Clients'
import Title from 'components/Others/Title/Title'

export default function ClientsPage() {
  return (
    <>
      <Title text="CLIENTES" />
      <Clients />
    </>
  )
}
