import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

// MY IMPORTS
import ListOfDefects from 'components/Inspection/Defectology/Defect/ListOfDefects'

export default function ChildDefects() {
  const reduxInspection = useSelector(store => store.inspection),
    params = useParams(),
    history = useHistory()

  useEffect(() => {
    // Si se ha refrescado la página, se manda al inicio para que no desaparezcan los defectos opcionales
    if (
      !reduxInspection?.optionalDefects ||
      !reduxInspection?.vehicleData ||
      !Object.keys(reduxInspection.vehicleData).length ||
      !Object.keys(reduxInspection.optionalDefects).length
    ) {
      history.push(`/new_inspection/${params.license}/defects`)
    }
  }, [params.license, reduxInspection, history])

  return (
    reduxInspection?.optionalDefects &&
    reduxInspection?.vehicleData &&
    Object.keys(reduxInspection.optionalDefects).length &&
    Object.keys(reduxInspection.vehicleData).length && <ListOfDefects />
  )
}
