import React, { useState, useEffect, useRef } from 'react'
import { setReduxInvoiceData } from 'redux/ducks/newInspectionDuck'
import { useDispatch } from 'react-redux'
import { setReduxHolderData } from 'redux/ducks/newInspectionDuck'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import 'components/Office/ClientForm/ClientForm.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import Input from 'components/Others/Input/Input'
import Spinner from 'components/Others/Spinner/Spinner'
import { ClientNewInspection as formSchema } from 'formSchemas/Client'
import { getClientByDni, getClientsByChunk as getClientsByChunkService } from 'services/Clients'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import { PersonSearch } from '@mui/icons-material'
import DeepSearch from 'components/Others/DeepSearch/DeepSearch'
import parseFormValues from 'myMethods/parseFormValues'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'
import useService from 'hooks/useService'
import Select from 'components/Others/Select/Select'
import { Pencil as EditIcon } from 'react-bootstrap-icons'
import { Euro as EuroIcon } from '@mui/icons-material'
import provinces from 'resources/provinces.json'

/**
 * @typedef {import('types/Client').Client} Client
 */

export default function ClientForm({
  reduxNewVehicle,
  invoice = false,
  onSubmit,
  className,
  ...rest
}) {
  const dispatch = useDispatch()

  const [values, setValues] = useState(
    invoice ? reduxNewVehicle?.invoiceData || {} : reduxNewVehicle?.holderData || {}
  )

  const [dni, setDni] = useState(
    invoice
      ? reduxNewVehicle?.invoiceData?.dni || ''
      : reduxNewVehicle?.holderData?.dni || reduxNewVehicle?.vehicleData?.dni || ''
  )
  const [disabledInputs, setDisabledInputs] = useState(true)
  const [showDeepSearchModal, setShowDeepSearchModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showProvince, setShowProvince] = useState(
    Boolean(invoice ? reduxNewVehicle?.invoiceData?.cp : reduxNewVehicle?.holderData?.cp)
  )

  const dniInput = useRef()
  const form = useRef()
  const initialValuesClear = {
    dni: dni,
    name: '',
    address: '',
    zipCode: '',
    province: '',
    locality: '',
    email: '',
    phone: '',
  }

  const { fetch: fetchClient } = useService({
    service: getClientByDni,
    setOutLoading: setLoading,
  })

  const handleInitialValues = () => {
    let returnValues = {}
    try {
      if (!invoice) {
        if (!Object.keys(reduxNewVehicle.holderData).length) {
          if (Object.keys(values).length) returnValues = values
        } else {
          returnValues = reduxNewVehicle.holderData
        }
      } else {
        if (!Object.keys(reduxNewVehicle.invoiceData).length) {
          if (Object.keys(values).length) returnValues = values
        } else {
          returnValues = reduxNewVehicle.invoiceData
        }
      }
      returnValues = parseFormValues(returnValues, true)
      return Object.keys(returnValues).length ? returnValues : initialValuesClear
    } catch (err) {
      console.error(err)
      return initialValuesClear
    }
  }

  console.log({ values })
  const handleZipCode = zipCode => {
    if (zipCode && zipCode.toString().length > 1) {
      const id = zipCode.toString().substring(0, 2)
      const province = provinces.find(elem => elem.id === id)
      form.current.setFieldValue('province', province ? province.value : '')
      setShowProvince(true)
    } else {
      form.current.setFieldValue('province', '')
      form.current.setFieldValue('locality', '')
      setShowProvince(false)
    }
  }

  const insertSelectedClient = async client => {
    try {
      client = { ...client, disabledDni: true }
      setShowDeepSearchModal(false)
      setDni(client?.dni || '')
      setDisabledInputs(true)
      form?.current?.setValues(client)
      setValues(client)
      client = parseFormValues(client)
      dispatch(setReduxHolderData(client))
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error insertando el cliente seleccionado')
    }
  }

  useEffect(() => {
    if (values.zipCode && values.province && !showProvince) setShowProvince(true)
    if (!values.disabledDni) setDisabledInputs(false)
    if (dni && !Object.keys(values).length) {
      fetchClient(dni)
        .then(res => {
          console.log({ res: res })
          console.log({ res: res })
          if (res) {
            setValues({ ...res, disabledDni: true })
            setDisabledInputs(true)
            dispatch(invoice ? setReduxInvoiceData(res) : setReduxHolderData(res))
            easyToast(
              'dark',
              invoice
                ? 'Cliente encontrado. Verifique los datos'
                : 'Titular encontrado. Verifique los datos'
            )
          }
        })
        .catch(err => {
          console.error(err)
          if (err.response?.status === 404)
            return easyToast(
              'dark',
              'Este DNI o CIF nunca ha sido registrado en esta ITV, inserte los datos manualmente'
            )
          easyToast('error', 'Error cargando los datos')
        })
    }
    //eslint-disable-next-line
  }, [dni, dispatch, invoice, values])
  console.log(form)
  return (
    <>
      <Spinner visible={loading} />
      <Formik
        enableReinitialize
        initialValues={handleInitialValues()}
        validationSchema={formSchema}
        innerRef={form}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, setFieldTouched, errors, touched }) => (
          <>
            {disabledInputs && (
              <MyButtonsContainer>
                <MyButton
                  text="Editar datos"
                  onClick={() => {
                    setDisabledInputs(false)
                    easyToast('dark', 'Edición habilitada')
                  }}
                  icon={<EditIcon />}
                  transparent={true}
                />
              </MyButtonsContainer>
            )}
            <div className={className ? `client-form ${className}` : 'client-form'} {...rest}>
              <Form className="client-form__form">
                <div className="client-form__dni">
                  <div>
                    <label>
                      DNI / CIF
                      <ErrorMessage className="form-error" name="dni" component="small" />
                    </label>
                    <Field
                      name="dni"
                      disabled={disabledInputs || dni}
                      autoFocus
                      innerRef={dniInput}
                    />
                  </div>
                  <HoverTooltip title="Buscar por DNI/CIF o nombre">
                    <div onClick={() => setShowDeepSearchModal(true)} className="input-action">
                      <PersonSearch />
                    </div>
                  </HoverTooltip>
                </div>
                <div className="client-form__inputs" style={{ position: 'relative' }}>
                  {disabledInputs && (
                    <div
                      className="form-overlay"
                      onClick={() => easyToast('error', 'Antes debes habilitar la edición')}
                    />
                  )}
                  <Input
                    className="form-input"
                    label="Nombre o razón social"
                    name="name"
                    disabled={disabledInputs}
                  />
                  <Input
                    className="form-input"
                    label="Domicilio"
                    name="address"
                    disabled={disabledInputs}
                  />
                  <Input
                    className="form-input"
                    label="Código Postal"
                    name="zipCode"
                    type="number"
                    inputMode="numeric"
                    disabled={disabledInputs}
                    onChange={e => {
                      setFieldValue('cp', e?.target?.value || '')
                      handleZipCode(e?.target?.value)
                    }}
                  />
                  {showProvince && (
                    <Select
                      label="Provincia"
                      ErrorComponent={
                        <ErrorMessage className="form-error" name="province" component="small" />
                      }
                      name="province"
                      isDisabled={disabledInputs}
                      options={provinces}
                      value={provinces?.find(elem => elem.value === values?.province) || ''}
                      onChange={opt => {
                        setFieldValue('province', opt ? opt.value : '')
                      }}
                      onBlur={() => setFieldTouched('province', true)}
                      error={errors.province}
                      touched={touched.province}
                    />
                  )}
                  {showProvince && (
                    <Input
                      className="form-input"
                      label="Localidad"
                      name="locality"
                      disabled={disabledInputs}
                    />
                  )}
                  <Input
                    className="form-input"
                    label="Email"
                    name="email"
                    type="email"
                    disabled={disabledInputs}
                  />
                  <Input
                    className="form-input"
                    label="Teléfono"
                    name="phone"
                    type="number"
                    disabled={disabledInputs}
                  />
                </div>
                <MyButtonsContainer fullWidth={false} className="client-form__btn">
                  <MyButton
                    text={invoice ? 'Guardar' : 'Ir a facturación'}
                    type="submit"
                    icon={invoice ? null : <EuroIcon />}
                  />
                </MyButtonsContainer>
              </Form>
            </div>
          </>
        )}
      </Formik>
      <DeepSearch
        open={showDeepSearchModal}
        onClose={() => setShowDeepSearchModal(false)}
        title="Buscar cliente"
        message="Seleccione un cliente"
        columnsKey="clients"
        filterServiceOptions={{
          likeDni: true,
          likeName: true,
        }}
        service={getClientsByChunkService}
        onAccept={insertSelectedClient}
      />
    </>
  )
}
