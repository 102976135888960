import React from 'react'
import manualChaptersJSON from 'resources/manualChapters.json'
import ManualChapter from './ManualChapter'
import AnimatedList from 'components/Others/AnimatedList/AnimatedList'

/**
 *
 * @param {{list?: {"chapter": string, "title": string}[], forcedOnClick?: function, checkIfDone?: boolean, manualManager?: import('Share/Defect/InspectionDefectsManager').default, animate?: boolean}} param0
 * @returns
 */
export default function RenderListOfManualChapters({
  list,
  forcedOnClick,
  checkIfDone = true,
  manualManager,
  animate = true,
}) {
  const manualChapters = list || manualChaptersJSON
  const getList = () =>
    manualChapters?.map(elem => {
      return (
        <ManualChapter
          key={elem.chapter}
          number={elem.chapter}
          name={elem.title}
          manualManager={manualManager}
          forcedOnClick={forcedOnClick}
          checkIfDone={checkIfDone}
        />
      )
    }) || []

  if (!animate) return <div className="list-container">{getList()}</div>
  return <AnimatedList className="list-container">{getList()}</AnimatedList>
}
