// tourSteps.js
import React from 'react'

export const tourSteps = {
  itvCardAnnotator: [
    {
      target: '.modal__title',
      type: 'tooltip',
      disableBeacon: true,
      content: (
        <div className="image-polygon-annotator__tour-gif">
          <div className="tour-steps__title">Selecciona cada columna de manera independiente</div>
          <img src="/videos/itvCard.gif" />
        </div>
      ),
    },
    {
      target: '.image-polygon-annotator__button-rectangle',
      type: 'tooltip',
      disableBeacon: true,
      content: (
        <>
          <div className="tour-steps__title">Herramienta rectángulo</div>
          <div>
            Por defecto, la herramienta seleccionada es polígono libre. Pero puedes pulsar aquí para
            trazar rectángulos de una forma más sencilla
          </div>
        </>
      ),
    },
    {
      target: '.image-polygon-annotator__button-undo',
      type: 'tooltip',
      disableBeacon: true,
      content: (
        <>
          <div className="tour-steps__title">Deshacer cambios</div>
          <div>Si cometes un error, puedes deshacer la última acción pulsando aquí</div>
          <div>Pista: el click derecho también deshace la última acción</div>
        </>
      ),
    },
  ],
}
