//@ts-check
import useResources from 'hooks/useResources'

/**
 *
 * @param {*} section
 * @returns  {{reformsManualSection: {id: string, name: string, chapters: string[]}[]  | null, reformsManualSectionSelectOptions: {value: string, label: string}[] | [] , loading: boolean}}
 */
export default function useReformManual(section) {
  const { reformsManualSections, loading } = useResources({
    reformsManualSections: true,
  })

  return {
    reformsManualSection: reformsManualSections?.[`section${section}`] ?? null,
    reformsManualSectionSelectOptions:
      reformsManualSections?.[`section${section}`]?.map(elem => {
        return { value: elem.id, label: elem.id + ' - ' + elem.name }
      }) ?? [],
    loading: loading.manualSections,
  }
}
