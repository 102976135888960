//@ts-check
import React, { useEffect, useRef, useState } from 'react'
import 'components/Inspection/CaptureLicense/CaptureLicense.scss'
import Modal from 'components/Others/Modal/Modal'
import isValidFunction from 'myMethods/isValidFunction'
import useInspectionImages from 'hooks/useInspectionImages'
import CaptureImage from '../CaptureImage/CaptureImage'
import { useSelector } from 'react-redux'
import { Camera as CameraIcon } from 'react-bootstrap-icons'
import Input from 'components/Others/Input/Input'
import './CaptureSpeedometer.scss'
import { putInspectionSpeedometerOnlyKm } from 'services/Inspection'
import useService from 'hooks/useService'
import Id from 'Share/Id'

/**
 *
 * @param {{closeModal: function}} param0
 * @returns
 */
export default function CaptureSpeedometer({ closeModal }) {
  const { images, loadingImages, uploadImage, loadingUpload } = useInspectionImages({
    type: 'cuentakilometros',
  })
  const captureImageRef = useRef()
  const inspection = useSelector(store => store.inspection)
  const isRevision = inspection?.rev
  const [speedometer_km, setSpeedometer_km] = useState('')

  const { fetch: putInspectionSpeedometer } = useService({
    service: putInspectionSpeedometerOnlyKm,
  })

  const handleAccept = async () => {
    if (speedometer_km != null) {
      await putInspectionSpeedometer({
        id: Id.decrypt(window.sessionStorage.getItem('id')),
        km: speedometer_km,
      })
    }
  }

  useEffect(() => {
    if (!images?.length && !loadingImages) {
      captureImageRef?.current?.triggerCapture()
    }
  }, [images?.length, loadingImages])

  useEffect(() => {
    if (inspection?.speedometer?.km != null) {
      setSpeedometer_km(inspection.speedometer.km)
    }
  }, [inspection?.speedometer?.km])

  console.log({ images })

  return (
    <>
      <Modal
        open={true}
        title="Capturar cuentakilómetros"
        message="Inserte o verifique el valor introducido antes de continuar"
        onClose={() => isValidFunction(closeModal) && closeModal()}
        hasCloseButton={!isRevision}
        onAccept={handleAccept}
        closeButtonText="Capturar de nuevo"
        closeButtonIcon={<CameraIcon />}
        onCloseButton={() => captureImageRef?.current?.triggerCapture()}
        loading={loadingImages || loadingUpload}
        content={
          <div className="capture-speedometer__modal">
            {images?.[0]?.src && <img alt="Cuentakilómetros" src={images[0].src} />}
            <Input
              isFormikInput={false}
              value={speedometer_km}
              onChange={e => setSpeedometer_km(e.target.value)}
              label="Kilómetros/Horas"
              type="number"
            />
          </div>
        }
        buttons={true}
      />
      <CaptureImage
        ref={captureImageRef}
        showImageAfterCapture={false}
        onCaptureImage={(image, geolocation) => {
          uploadImage({ image, geolocation, type: 'cuentakilometros', label: 'Cuentakilómetros' })
        }}
      />
    </>
  )
}
