import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setReduxInvoiceData } from 'redux/ducks/newInspectionDuck'

// MY IMPORTS
import 'components/Office/Checkout/InvoiceForm/InvoiceForm.scss'
import ClientForm from '../../ClientForm/ClientForm'
import easyToast from 'components/Others/EasyToast/easyToast'
import parseFormValues from 'myMethods/parseFormValues'
import Modal from 'components/Others/Modal/Modal'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import Checkbox from 'components/Others/Checkbox/Checkbox'

export default function InvoiceForm({ reduxNewVehicle }) {
  const dispatch = useDispatch()
  const [idem, setIdem] = useState(!hasReduxValues())
  const [open, setOpen] = useState(false)
  const [formValues, setFormValues] = useState(hasReduxValues() ? reduxNewVehicle.invoiceData : {})
  const [showModal, setShowModal] = useState(false)

  function hasReduxValues() {
    return Boolean(
      reduxNewVehicle.invoiceData &&
        reduxNewVehicle.invoiceData.dni &&
        reduxNewVehicle.invoiceData.nombre
    )
  }

  const handleIdemChange = checked => {
    if (checked) {
      if (Object.keys(formValues).length) setShowModal(true)
      else {
        setIdem(true)
        setOpen(false)
      }
    } else {
      setIdem(false)
      setOpen(true)
    }
  }

  const handleSubmit = values => {
    values = parseFormValues(values)

    setOpen(false)
    setFormValues(values)
    dispatch(setReduxInvoiceData(values))
  }

  return (
    <>
      <div className="invoice-form">
        <h3>CLIENTE A FACTURAR</h3>
        <div className="invoice-form__invoice">
          <div className="invoice-form__checkboxes">
            <div className="invoice-form__idem">
              <Checkbox
                label="FACTURAR A TITULAR"
                checked={idem}
                onChange={checked => handleIdemChange(checked)}
              />
            </div>
          </div>

          {open && (
            <ClientForm
              data-aos="zoom-in"
              className="invoice-form__form"
              invoice={true}
              reduxNewVehicle={reduxNewVehicle}
              onSubmit={values => handleSubmit(values)}
              btnText="Validar"
            />
          )}
          {Object.keys(formValues).length > 0 && !open && (
            <div className="invoice-form__saved-values">
              <span>DATOS DE FACTURACIÓN REGISTRADOS PARA</span>
              <b>
                {formValues.dni} - {formValues.name}
              </b>

              <MyButtonsContainer>
                <MyButton text="Editar" onClick={() => setOpen(true)} />
              </MyButtonsContainer>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        title="Hay datos de facturación registrados"
        message="¿Establecer la misma dirección de facturación?"
        alertMessage="Se borrarán los datos registrados"
        buttons={true}
        onAccept={() => {
          dispatch(setReduxInvoiceData({}))
          setFormValues({})
          setOpen(false)
          setIdem(true)
          easyToast('dark', 'Datos eliminados')
        }}
      />
    </>
  )
}
