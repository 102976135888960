import React, { useState, useEffect } from 'react'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import ListItemsInTwoColumns from 'components/Others/ListItemsInColumns/ListItemsInColumns'
import Modal from 'components/Others/Modal/Modal'
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import AnimatedList from 'components/Others/AnimatedList/AnimatedList'
import './MachineSelector.scss'
import easyToast from 'components/Others/EasyToast/easyToast'
import isValidFunction from 'myMethods/isValidFunction'

export default function MachineSelector({
  machinesByLine,
  setMachine,
  currentMachine,
  currentLine,
  closeModal,
}) {
  const [selectedLine, setSelectedLine] = useState(currentLine)
  const [selectedMachine, setSelectedMachine] = useState(currentMachine)
  const lineHasMultipleMachines = selectedLine && machinesByLine[selectedLine]?.length > 1
  console.log({ machinesByLine, currentMachine, currentLine, selectedLine })
  const handleAccept = () => {
    try {
      console.log('entro handleAccept', { selectedLine, selectedMachine })
      if (!isValidFunction(setMachine)) throw new Error('setMachine is not a valid function')
      if (!selectedLine) return easyToast('error', 'Seleccione una línea')
      if (!lineHasMultipleMachines) {
        const machine = machinesByLine[selectedLine][0]
        if (!machine) throw new Error('No machine found in machines by line')
        setMachine(machine)
      } else {
        if (!selectedMachine) return easyToast('error', 'Seleccione una máquina de la lista')
        setMachine(selectedMachine)
      }
      easyToast('dark', `Máquina de línea ${selectedLine} seleccionada`)
      closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error seleccionando la máquina')
    }
  }

  useEffect(() => {
    if (currentMachine?.line && currentMachine.line !== currentLine) {
      setSelectedLine(currentMachine.line)
    }
  }, [currentLine, currentMachine?.line])
  return (
    <Modal
      title={
        selectedLine && lineHasMultipleMachines
          ? `La línea ${selectedLine} tiene ${machinesByLine[selectedLine]?.length} máquinas`
          : 'Seleccione una línea'
      }
      message={
        selectedLine && lineHasMultipleMachines ? 'Seleccione una máquina de la lista' : null
      }
      onAccept={handleAccept}
      hasAcceptButton={Boolean(
        selectedLine ? (lineHasMultipleMachines ? selectedMachine : true) : false
      )}
      closeAfterAccept={false}
      open={true}
      onClose={closeModal}
      content={
        <>
          {machinesByLine && !(selectedLine && lineHasMultipleMachines) && (
            <div data-aos="fade-in">
              <ListItemsInTwoColumns>
                {Object.keys(machinesByLine)?.map((line, i) => {
                  let className = 'item-picker__card'
                  if (selectedLine === line) className = 'item-picker__card--selected'
                  return (
                    <div key={i} onClick={() => setSelectedLine(line)} className={className}>
                      {line}
                    </div>
                  )
                })}
              </ListItemsInTwoColumns>
            </div>
          )}
          {selectedLine && lineHasMultipleMachines && (
            <>
              <MyButtonsContainer margin="0 auto 1rem">
                <MyButton
                  text="Cambiar línea"
                  icon={<ArrowBackIcon />}
                  onClick={() => setSelectedLine(null)}
                />
              </MyButtonsContainer>
              <AnimatedList>
                {machinesByLine[selectedLine].map((machine, i) => (
                  <div
                    key={i}
                    className={
                      selectedMachine?.internalId === machine.internalId
                        ? 'machine-selector__machine--selected'
                        : 'machine-selector__machine'
                    }
                    onClick={() => setSelectedMachine(machine)}
                  >
                    <div className="machine-selector__machine-brand">{machine.brand}</div>
                    {Object.entries(machine.id).map(([testName, id], i) => (
                      <div className="machine-selector__machine-test" key={i}>
                        <span>{testName?.toUpperCase()}</span>
                        <div className="machine-selector__machine-info">
                          <div>
                            <label>ID:</label>
                            <div>{id}</div>
                          </div>
                          <div>
                            <label>ALIAS:</label>
                            <div>{machine.alias[testName] || '-'}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </AnimatedList>
            </>
          )}
        </>
      }
    />
  )
}
