import React from 'react'
import './ITVCardOffsetInput.scss'
import { InfoCircle as InfoIcon } from 'react-bootstrap-icons'
import HoverTooltip from 'components/Others/HoverTooltip/HoverTooltip'

export default function ITVCardOffsetInput({ value, setValue }) {
  return (
    <div className="itv-card-offset-input">
      <label htmlFor="itv-card-offset">
        Margen superior (mm):{' '}
        <HoverTooltip title="Ejemplo">
          <InfoIcon />
        </HoverTooltip>
      </label>
      <input
        type="number"
        name="itv-card-offset"
        id="itv-card-offset"
        value={value}
        min={0}
        onChange={e => setValue(e.target.value === '' ? '' : Math.abs(e.target.value))}
      />
    </div>
  )
}
