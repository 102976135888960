import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import moment from 'moment'
import React, { useState, useEffect, useCallback } from 'react'
import { getClientByDni as getClientByDniService } from 'services/Clients'
import {
  getInvoiceData as getInvoiceDataService,
  getLastCorrectiveDate as getLastCorrectiveDateService,
  postCorrectiveInvoice as postCorrectiveInvoiceService,
  postCorrectiveInvoicePreview as postCorrectiveInvoicePreviewService,
} from 'services/Billing'
import DealModal from '../Deals/DealModal/DealModal'
import AddRectifierDeals from './AddRectifierDeals'
import './InvoiceRectifier.scss'
import ListRectifierDeals from './ListRectifierDeals'
import compareObjects from 'myMethods/compareObjects'
import ModificationDiff from '../ModificationDiff/ModificationDiff'
import PDFViewer from 'components/Others/PDFViewer/PDFViewer'
import openPDF from 'myMethods/openPDF'
import { useDispatch } from 'react-redux'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import formatAndTrimText from 'myMethods/formatAndTrimText'
import useService from 'hooks/useService'
import useMultipleServices from 'hooks/useMultipleServices'
import Input from 'components/Others/Input/Input'

/**
 *
 * @param {{data: import('types/Invoice').Invoice, closeModal: any}} param0
 * @returns
 */

export default function InvoiceRectifier({ data, closeModal, refetch }) {
  const [rawData, setRawData] = useState({
    client: [],
    deals: [],
    invoiceDeals: [],
    invoiceData: {},
    date: { minDate: null, maxDate: null },
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [values, setValues] = useState({
    date: '',
    dealsToShow: [],
    dealsIn: [],
    dealsOut: [],
    reason: '',
  })
  const [pdf, setPdf] = useState({ show: false, data: null })
  const [dealModal, setDealModal] = useState({ show: false, data: null })
  const [addModal, setAddModal] = useState({ show: false, deals: [] })
  const [status, setStatus] = useState({
    client: { refresh: false, diff: [] },
    deals: { diff: [] },
  })
  const dispatch = useDispatch()
  const letSubmit =
    !moment(values.data).isSame(moment(moment(data.date).format('YYYY-MM-DD'))) ||
    status.client.refresh ||
    values.dealsIn.length ||
    values.dealsOut.length
  const inputMaxLength = 100

  const { fetch: postCorrectiveInvoice } = useService({
    service: postCorrectiveInvoiceService,
    setOutLoading: setLoading,
  })
  const { fetch: postCorrectiveInvoicePreview } = useService({
    service: postCorrectiveInvoicePreviewService,
    setOutLoading: setLoading,
  })

  const { fetch: fetchInitialData, loading: initialLoading } = useMultipleServices({
    services: [getClientByDniService, getLastCorrectiveDateService, getInvoiceDataService],
    promiseType: 'allSettled',
  })

  const handleInputChange = useCallback((e, type) => {
    setValues(prevState => ({
      ...prevState,
      [type]: e.target.value,
    }))
  }, [])

  const handleDateChange = value => {
    setValues(prevState => ({ ...prevState, date: value }))
  }

  const deleteDeal = id => {
    try {
      if (!id) throw Error('No id ~ deleteDeal() InvoiceRectifier.js')
      let aux = JSON.parse(JSON.stringify(values))
      const index = aux.dealsToShow.findIndex(elem => elem.id === id)
      if (index > -1) {
        if (
          !values.dealsOut.find(elem => elem.id === id) &&
          rawData.invoiceDeals.some(elem => elem.id === id)
        ) {
          aux.dealsOut.push(aux.dealsToShow[index])
        }
        if (values.dealsIn.some(elem => elem.id === id)) {
          aux.dealsIn.splice(
            aux.dealsIn.findIndex(elem => elem.id === id),
            1
          )
        }
        aux.dealsToShow.splice(index, 1)
        setValues(aux)
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error eliminando el albarán')
    }
  }

  const handleClickClientRefresh = () => {
    try {
      if (status.client.refresh) {
        return setStatus({ ...status, client: { refresh: false } })
      }
      let invoiceClient = rawData?.invoiceData?.client
      let newClient = rawData?.client
      invoiceClient.paymentMethod = rawData.invoiceData.paymentMethod
      if (newClient.chargeAccount) {
        newClient = { ...newClient, ...newClient.chargeAccount }
        delete newClient.chargeAccount
      }
      const equalsRes = compareObjects({
        obj1: invoiceClient,
        obj2: newClient,
        onlyKeys: [
          'dni',
          'name',
          'address',
          'locality',
          'zipCode',
          'email',
          'phone',
          'paymentMethod',
          'iban',
          'note',
        ],
      })
      console.log({ invoiceClient, newClient })
      if (!equalsRes || equalsRes.error)
        throw new Error('Error in areObjectEquals comparing client in InvoiceRectifier')
      if (equalsRes.equals || !equalsRes.diff.length) {
        setStatus({ ...status, client: { refresh: false } })
        return easyToast('error', 'No hay cambios que actualizar en el cliente')
      } else if (
        !equalsRes.equals &&
        equalsRes.diff.length &&
        JSON.stringify(equalsRes.diff) !== JSON.stringify(status.client.diff)
      ) {
        setStatus({ ...status, client: { ...status.client, refresh: true, diff: equalsRes.diff } })
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error')
    }
  }

  const handleAddModalAccept = deals => {
    try {
      if (!deals?.length) throw Error('No deals in handleAddModalAccept in InvoiceRectifier')
      console.log({ deals })
      const excludedLicenses = deals
        .filter(
          deal =>
            !rawData.client?.chargeAccount?.authVehicles?.some(
              vehicle => vehicle.license === deal.license
            )
        )
        ?.map(deal => deal.license)
      if (excludedLicenses?.length) {
        dispatch(
          setReduxConfigModal({
            error: true,
            message:
              'Las siguientes matrículas no están incluidas en la cuenta a cobro del cliente: ',
            message2: excludedLicenses.join(', '),
            hasCloseButton: false,
          })
        )
        return
      }

      // dataMapping deals
      let aux = JSON.parse(JSON.stringify(values))
      deals.forEach(newDeal => {
        newDeal = rawData.deals.find(elem => elem.id === newDeal.id)
        if (!aux.dealsToShow.some(elem => elem.id === newDeal.id)) {
          aux.dealsToShow.push(newDeal)
          console.log('No está en deals to show')
        }
        if (
          !aux.dealsIn.some(elem => elem.id === newDeal.id) &&
          !rawData.invoiceDeals.some(elem => elem.id === newDeal.id)
        ) {
          console.log('No está en dealsIn')
          aux.dealsIn.push(newDeal)
        }
        // Si está en out y luego se vuelve a añadir
        if (values.dealsOut.some(elem => elem.id === newDeal.id)) {
          aux.dealsOut.splice(
            aux.dealsOut.findIndex(elem => elem.id === newDeal.id),
            1
          )
        }
      })
      setValues(aux)
      console.log('Salgo addModalAccept', addModal, aux)
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error añadiendo los nuevos albaranes')
    }
  }
  console.log({ data })

  // OBTENER DATOS NECESARIOS
  useEffect(() => {
    const fetchData = async () => {
      console.log('entro a fetchData')
      try {
        let [client, lastCorrective, invoiceData] = await fetchInitialData([
          data.client.dni,
          null,
          { serie: data?.serie, invoiceId: data?.id },
        ])
        console.log({ client, lastCorrective, invoiceData })
        if (!lastCorrective.value && lastCorrective?.reason?.response?.status !== 404)
          throw new Error('Error getting lastCorrective')
        client = client?.value?.data
        lastCorrective = lastCorrective.value?.data ?? null
        invoiceData = invoiceData?.value?.data
        if (!client || !invoiceData) throw new Error('No client or invoiceData in InvoiceRectifier')
        const invoiceDeals = invoiceData.deals?.map(elem => elem.deal)
        let minDate = moment(data.date)
        if (lastCorrective && Object.keys(lastCorrective).length) {
          if (lastCorrective.date) {
            minDate = moment(lastCorrective.date)
          } else throw new Error('No lastCorrective date in InvoiceRectifier')
        }

        const maxDate = moment(data.date).clone().add(4, 'y')

        setRawData({
          client,
          invoiceData,
          invoiceDeals,
          lastCorrectiveDate: lastCorrective?.date || null,
          dates: {
            minDate,
            maxDate,
          },
        })
        setValues(prevState => ({
          ...prevState,
          date: values.data ? values.data : minDate ? minDate.format('YYYY-MM-DD') : '',
        }))

        setError(false)
      } catch (err) {
        console.error(err)
        setError(true)
      } finally {
        setLoading(false)
      }
    }
    fetchData()
    //eslint-disable-next-line
  }, [data])

  useEffect(() => {
    if (
      !values.dealsToShow.length &&
      !values.dealsIn.length &&
      !values.dealsOut.length &&
      rawData.invoiceDeals.length
    ) {
      setValues(values => ({ ...values, dealsToShow: rawData.invoiceDeals }))
    }
  }, [values, rawData.invoiceDeals])

  console.log({ rawData, values, status })

  // useEffect(() => {
  //   // Genera los albaranes que se pueden seleccionar eliminando los que se están mostrando
  //   let currentDeals = [...values.dealsToShow, ...rawData.invoiceDeals]
  //   let newSelectableDeals = JSON.parse(JSON.stringify(deals))
  //   currentDeals.forEach(cd => {
  //     const index = newSelectableDeals.findIndex(elem => elem.id === cd.id)
  //     if (index !== -1) newSelectableDeals.splice(index, 1)
  //   })
  //   setSelectableDeals(newSelectableDeals)
  // }, [rawData, values.dealsToShow, deals])

  useEffect(() => {
    try {
      if (values.dealsIn.length + values.dealsOut.length !== status.deals.diff.length) {
        let diff = []
        values.dealsIn.forEach(deal => diff.push({ value2: deal.id }))
        values.dealsOut.forEach(deal => diff.push({ value1: deal.id }))
        setStatus(prevState => ({ ...prevState, deals: { diff } }))
      } else if (values.dealsIn.length + values.dealsOut.length === 0 && status.deals.diff.length) {
        setStatus(prevState => ({ ...prevState, deals: { diff: [] } }))
      }
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error')
    }
  }, [status.deals.diff.length, values.dealsIn, values.dealsOut])

  useEffect(() => {
    if (
      data?.id &&
      Boolean(
        (status?.client?.refresh && status?.client?.diff?.length && status?.deals?.diff?.length) ||
          status?.deals?.diff?.length
      )
    ) {
      postCorrectiveInvoicePreview({
        invoiceId: data.id,
        newClient: status.client.refresh,
        date: values.date,
        dealsIds: [...values.dealsIn, ...values.dealsOut].map(elem => elem.id),
        reason: values.reason,
      })
        .then(res => {
          if (!res) throw new Error('No pdf received')
          setPdf({ show: false, data: res })
        })
        .catch(err => {
          if (pdf.show || pdf.data) setPdf({ show: false, data: null })
          if (err.response.data.message) {
            return easyToast('error', err.response.data.message)
          }
          easyToast('error', 'Ha ocurrido un error cargando el borrador')
        })
    }
    //eslint-disable-next-line
  }, [data?.id, postCorrectiveInvoicePreview, status, values])

  const handleAccept = useCallback(async () => {
    try {
      setLoading(true)
      let error = false
      if (!values.reason) {
        easyToast('error', 'Inserte un motivo')
        error = true
      }
      if (!values.date) {
        easyToast('error', 'Inserte una fecha')
        error = true
      }
      if (values.dealsIn.length + values.dealsOut.length < 1 && !status.client.refresh) {
        easyToast('error', 'No se han modificado los albaranes')
        error = true
      }
      if (rawData?.dates?.minDate && moment(values.date).isBefore(rawData?.dates?.minDate, 'day')) {
        easyToast(
          'error',
          'La fecha no puede ser anterior a la fecha de la factura original o de la última factura rectificativa'
        )
        error = true
      }
      if (error) return
      await postCorrectiveInvoice({
        invoiceId: data.id,
        newClient: status.client.refresh,
        date: moment(values.date).toISOString(),
        dealsIds: [...values.dealsIn, ...values.dealsOut].map(elem => elem.id),
        reason: formatAndTrimText(values.reason, false),
      })
      easyToast('dark', 'Factura rectificativa creada correctamente')
      refetch()
      closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error. Inténtelo de nuevo')
      if (pdf.show) setPdf({ ...pdf, show: false })
    }
    //eslint-disable-next-line
  }, [data?.id, status?.client?.refresh, values, rawData?.dates?.minDate, data?.date])

  return (
    <Modal
      open={true}
      onClose={closeModal}
      onAccept={handleAccept}
      closeAfterAccept={false}
      hasAcceptButton={!error && letSubmit}
      closeOnOverlay={false}
      buttons={!loading}
      loading={loading}
      title={`Rectificar factura ${data?.number}`}
      content={
        <>
          {error && (
            <div className="no-values">
              Ha ocurrido un error obteniendo la información de la factura. Inténtelo de nuevo
            </div>
          )}
          {!error && (
            <div className="invoice-rectifier">
              {(data?.date || rawData.dates?.minDate) && (
                <div className="invoice-rectifier__last-date">
                  {data?.date && (
                    <div>
                      Fecha de factura que rectifica: {moment(data.date)?.format('DD-MM-YYYY')}
                    </div>
                  )}
                  {rawData.dates?.minDate && (
                    <div>
                      Fecha de última rectificativa:{' '}
                      {moment(rawData.dates?.minDate)?.format('DD-MM-YYYY')}
                    </div>
                  )}
                </div>
              )}

              <div className="invoice-rectifier__inputs">
                <div className="invoice-rectifier__input-container">
                  <h3>FECHA</h3>
                  <input
                    type="date"
                    value={values.date}
                    onChange={e => handleDateChange(e.target.value)}
                  />
                </div>

                <div className="invoice-rectifier__input-container">
                  <h3>CLIENTE</h3>
                  <div className="invoice-rectifier__client">
                    {data?.client?.dni} - {data?.client?.name}
                  </div>
                  <MyButtonsContainer>
                    <MyButton
                      text="Actualizar"
                      icon={<i className="flaticon-refresh" />}
                      onClick={handleClickClientRefresh}
                      selectable={true}
                      selected={status?.client?.refresh}
                      style={{ minWidth: '12rem' }}
                    />
                  </MyButtonsContainer>
                </div>
              </div>
              <div className="invoice-rectifier__inputs">
                <div className="invoice-rectifier__reason">
                  <h3>
                    MOTIVO DE RECTIFICACIÓN <span>(saldrá en la factura)</span>
                  </h3>
                  <Input
                    type="textarea"
                    isFormikInput={false}
                    onChange={e => handleInputChange(e, 'reason')}
                    value={values.reason}
                    maxLength={inputMaxLength}
                  />
                </div>
              </div>
              <div className="invoice-rectifier__list-deals">
                <h3>ALBARANES</h3>
                <div className="invoice-rectifier__warning">
                  <div>¡ATENCIÓN!</div>
                  <div>
                    En esta lista debe dejar los albaranes finales que quiera facturar al cliente.
                    <br /> La factura rectificativa se generará automáticamente con las diferencias
                  </div>
                </div>

                <ListRectifierDeals
                  values={values?.dealsToShow}
                  showAddModal={() => setAddModal({ show: true, deals: [] })}
                  deleteDeal={deleteDeal}
                  onRowClick={data => setDealModal({ show: true, data })}
                  loading={initialLoading}
                />
              </div>
              <ModificationDiff
                show={
                  !error &&
                  (status.client.refresh || values.dealsIn?.length || values.dealsOut.length)
                }
                status={status}
              />
              {pdf.data && (
                <>
                  <MyButtonsContainer margin="1rem auto" fullWidth={true}>
                    {!pdf.show && (
                      <MyButton
                        text="Ver borrador"
                        onClick={() => {
                          setPdf({ ...pdf, show: true })
                        }}
                      />
                    )}
                    <MyButton text={'Abrir borrador'} onClick={() => openPDF(pdf)} />
                  </MyButtonsContainer>
                  <PDFViewer
                    pdf={pdf.data}
                    show={pdf.show}
                    closable={true}
                    onClose={() => setPdf({ ...pdf, show: false })}
                    loading={loading}
                    parentToScroll={
                      document.getElementsByClassName('modal')?.[
                        document.getElementsByClassName('modal')?.length - 1
                      ]
                    }
                  />
                </>
              )}
            </div>
          )}
          {addModal?.show && (
            <AddRectifierDeals
              dealsToHide={[...values.dealsToShow, ...rawData.invoiceDeals]}
              closeModal={() => setAddModal({ show: false, deals: [] })}
              handleAccept={deals => handleAddModalAccept(deals)}
            />
          )}
          {dealModal.show && (
            <DealModal
              invoiceId={dealModal.data?.id}
              closeModal={() => setDealModal({ show: false, data: null })}
              onlyRead={true}
            />
          )}
        </>
      }
    />
  )
}
