import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import easyToast from 'components/Others/EasyToast/easyToast'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import React, { useCallback, useEffect, useState } from 'react'
import { getAllDiscounts as getAllDiscountsService } from 'services/Discounts'
import DiscountsConfiguratorModal from './DiscountsConfiguratorModal/DiscountsConfiguratorModal'
import { useDispatch } from 'react-redux'
import { setReduxConfigModal } from 'redux/ducks/configDuck'
import useService from 'hooks/useService'

export default function DiscountsConfigurator() {
  const [showOnlyActive, setShowOnlyActive] = useState(true)
  const [modal, setModal] = useState({ show: false, data: null, isNew: true })
  const dispatch = useDispatch()

  const {
    serviceData,
    fetch: getAllDiscounts,
    loading,
  } = useService({
    service: getAllDiscountsService,
  })

  const discounts = serviceData?.discounts ?? []

  console.log({ discounts })

  const deleteDiscount = async discountId => {
    try {
      console.log({ discountId })
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error eliminando la promoción')
    }
  }

  const fetchAllDiscounts = useCallback(async () => {
    try {
      await getAllDiscounts()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error cargando las promociones')
    }
  }, [getAllDiscounts])

  useEffect(() => {
    if (!discounts?.length) fetchAllDiscounts()
  }, [discounts?.length, fetchAllDiscounts])
  return (
    <>
      <MuiTable
        loading={loading}
        values={showOnlyActive ? discounts?.filter(elem => elem.isActivated) || [] : discounts}
        columnsKey="discounts"
        options={{ grouping: false, selection: false }}
        showToolbar={false}
        deleteAction={true}
        onDelete={rowData => {
          dispatch(
            setReduxConfigModal({
              title: `¿ELIMINAR PROMOCIÓN${rowData?.name ? ' "' + rowData.name + '" ' : ''}?`,
              onAccept: () => deleteDiscount(rowData.id),
            })
          )
        }}
        toolbarComponent={
          <>
            <MyButtonsContainer fullWidth={false} margin="0 auto 0 0" styles={{ width: 'auto' }}>
              <MyButton
                text="Añadir promoción"
                onClick={() => setModal({ show: true, data: null, isNew: true })}
              />
            </MyButtonsContainer>
            <Checkbox
              label="Solo activas"
              checked={showOnlyActive}
              onChange={value => setShowOnlyActive(value)}
            />
          </>
        }
        onRowClick={rowClicked => setModal({ show: true, data: rowClicked, isNew: false })}
      />
      {modal?.show && (
        <DiscountsConfiguratorModal
          discount={modal.data}
          isNew={modal.isNew}
          refetch={fetchAllDiscounts}
          closeModal={() => setModal({ show: false, data: null, isNew: false })}
          deleteDiscount={deleteDiscount}
        />
      )}
    </>
  )
}
