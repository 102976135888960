import Axios from 'axios'

import { getJWT } from 'myMethods/getJWT'
import { v2 } from './v2'

const SERVER_URL = process.env.REACT_APP_SERVER_URL
const BILLING_SERVER_URL = process.env.REACT_APP_BILLING_URL

export function getResourcesRoles(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}config/roles`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getResourcesFuels(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}config/fuels`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getResourcesCountries(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}config/countries`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getResourcesCategories(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}config/categories`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getResourcesClientPaymentMethods(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}config/client_payment_methods`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getResourcesFees(data, options = {}) {
  return Axios.get(`${BILLING_SERVER_URL}/api/${v2}fees`, {
    ...getJWT(),
    params: data,
    signal: options.signal,
  })
}

export function getResourcesManualSections(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}config/manual`, {
    ...getJWT(),
    signal: options.signal,
  })
}

export function getResourcesReformsManualSections(options = {}) {
  return Axios.get(`${SERVER_URL}/api/${v2}config/reform`, {
    ...getJWT(),
    signal: options.signal,
  })
}
