import React from 'react'

export default function InfoMenuField({ label, value }) {
  return (
    <div className="info-menu__field">
      <div className="info-menu__label">{label}</div>
      {value}
    </div>
  )
}
