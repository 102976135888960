//@ts-check
import React, { useState } from 'react'
import './AddRemarkModal.scss'
import ToggleButtons from 'components/Others/Buttons/ToggleButtons/ToggleButtons'
import Modal from 'components/Others/Modal/Modal'
import getCssVariable from 'myMethods/getCssVariable'
import Input from 'components/Others/Input/Input'
import MyButtonsContainer from 'components/Others/Buttons/MyButtonsContainer'
import MyButton from 'components/Others/Buttons/MyButton/MyButton'
import SuperList from 'components/Others/SuperList/SuperList'
import { useSelector } from 'react-redux'
import Checkbox from 'components/Others/Checkbox/Checkbox'
import { TrashFill } from 'react-bootstrap-icons'
import useInspectionRemarks from 'hooks/useInspectionRemarks'
import easyToast from 'components/Others/EasyToast/easyToast'

/**
 *
 * @param {{closeModal: function}} param0
 * @returns
 */
export default function AddRemarkModal({ closeModal }) {
  const [selectedType, setSelectedType] = useState('general')
  const [remark, setRemark] = useState('')
  const [addToUserRemarks, setAddToUserRemarks] = useState(false)
  const [showMyRemarks, setShowMyRemarks] = useState(false)
  const redux = useSelector(store => store)
  const userRemarks = redux.user.data?.personalSettings?.remarks ?? []
  const maxLengths = redux?.config?.itv?.maxTextsLengths
  const { addRemark, loadingStates, removeUserRemark } = useInspectionRemarks({})

  return (
    <Modal
      show={true}
      title="Añadir observación"
      onClose={closeModal}
      closeOnOverlay={false}
      onAccept={async () => {
        if (!remark) return easyToast('error', 'Escribe una observación')
        if (!selectedType) return easyToast('error', 'Selecciona un tipo de observación')
        await addRemark(remark, selectedType, addToUserRemarks, closeModal)
      }}
      closeAfterAccept={false}
      content={
        <div className="add-remark-modal">
          <ToggleButtons
            handleChange={setSelectedType}
            selectedValue={selectedType}
            buttons={[
              {
                text: 'General',
                value: 'general',
                color: getCssVariable('--general-remark-color'),
              },
              {
                text: 'Interna',
                value: 'internal',
                color: getCssVariable('--internal-remark-color'),
              },
            ]}
          />
          <Input
            className="add-remark-modal__input"
            type="textarea"
            textAreaMaxLength={
              (selectedType === 'general' ? maxLengths.remark : maxLengths.internalRemark) || 200
            }
            value={remark}
            onChange={e => setRemark(e.target.value)}
            name="remark"
            label={selectedType === 'general' ? 'Observación general' : 'Observación interna'}
            placeholder={
              selectedType === 'general'
                ? 'Escribe una observación general'
                : 'Escribe una observación interna'
            }
            isFormikInput={false}
          />
          <Checkbox
            className="add-remark-modal__checkbox"
            checked={addToUserRemarks}
            onChange={checked => setAddToUserRemarks(checked)}
            label="Añadir observación a mis observaciones"
          />
          <MyButtonsContainer margin="1rem 0 0 0">
            <MyButton
              selectable={true}
              selected={showMyRemarks}
              text={showMyRemarks ? 'Ocultar mis observaciones' : 'Ver mis observaciones'}
              onClick={() => setShowMyRemarks(!showMyRemarks)}
            />
          </MyButtonsContainer>
          {showMyRemarks && (
            <SuperList noItemsMessage="Aún no tienes observaciones guardadas">
              {userRemarks.map(({ text: remark }, i) => {
                return (
                  <div
                    key={i}
                    className="add-remark-modal__my-remark contrast-on-hover"
                    onClick={() => {
                      setRemark(remark)
                      setShowMyRemarks(false)
                    }}
                    style={{
                      pointerEvents: loadingStates.loadingRemoveUserRemark ? 'none' : 'auto',
                    }}
                  >
                    <span>{remark}</span>
                    <TrashFill
                      onClick={e => {
                        e.stopPropagation()
                        removeUserRemark(remark)
                      }}
                    />
                  </div>
                )
              })}
            </SuperList>
          )}
        </div>
      }
    />
  )
}
