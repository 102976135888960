//@ts-check
import easyToast from 'components/Others/EasyToast/easyToast'
import Modal from 'components/Others/Modal/Modal'
import MuiTable from 'components/Others/MuiTable/MuiTable'
import isValidFunction from 'myMethods/isValidFunction'
import React from 'react'

/**
 * @typedef {import('types/Discount').Discount} Discount
 */
/**
 *
 * @param {{discounts: Discount[], applyDiscount: Function, closeModal: Function}} param0
 * @returns
 */
export default function Discounts({ discounts, applyDiscount, closeModal }) {
  /**@param {Discount} discount*/
  const submit = async discount => {
    try {
      if (!discount) throw new Error('No discount provided')
      isValidFunction(applyDiscount) && applyDiscount(discount.id)
      closeModal()
    } catch (err) {
      console.error(err)
      easyToast('error', 'Ha ocurrido un error aplicando la promoción')
    }
  }

  return (
    <Modal
      open={true}
      onClose={closeModal}
      title="Seleccione una promoción"
      closeAfterAccept={false}
      hasAcceptButton={false}
      content={
        <div className="discounts">
          <MuiTable
            title="PROMOCIONES"
            values={discounts}
            columnsKey="discounts"
            options={{ search: true, grouping: false, selection: false }}
            onRowClick={rowClicked => submit(rowClicked)}
          />
        </div>
      }
    />
  )
}
