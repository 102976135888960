//@ts-check
import React, { useState } from 'react'

// MY IMPORTS
import 'components/Header/Avatar/Avatar.scss'
import getUserShortName from 'myMethods/getUserShortName'
import useLogin from 'hooks/useLogin'
import MuiAvatar from '@mui/material/Avatar'
import PopoverMenu from 'components/Others/PopoverMenu/PopoverMenu'
import { Person as MyAccountIcon, BoxArrowLeft as LogoutIcon } from 'react-bootstrap-icons'
import { useSelector } from 'react-redux'
import UserInfoModal from 'components/Users/UserInfoModal/UserInfoModal'

export default function Avatar() {
  const userData = useSelector(store => store.user.data)
  const userShortName = getUserShortName()
  const [showUserModal, setShowUserModal] = useState(false)
  const { logout } = useLogin()

  return (
    <>
      <div className="avatar">
        <PopoverMenu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          items={[
            {
              icon: <MyAccountIcon />,
              content: 'Mi cuenta',
              onClick: () => setShowUserModal(true),
            },
            {
              icon: <LogoutIcon />,
              content: 'Cerrar sesión',
              onClick: logout,
            },
          ]}
        >
          <MuiAvatar>{userShortName?.[0]}</MuiAvatar>
        </PopoverMenu>
      </div>
      {showUserModal && (
        <UserInfoModal user={userData} closeModal={() => setShowUserModal(false)} />
      )}
    </>
  )
}
