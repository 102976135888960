//@ts-check

/**
 * @typedef {import("types/Chatbot").Chatbot} Chatbot
 * @typedef {import("types/MintAIChatbotRedux").ChatbotRedux} ChatbotRedux
 */

// constantes
/**
 * @type {ChatbotRedux}
 */
const initialData = {
  chatbot: null,
}

// types
const SET_CHATBOT_SUCCESS = 'SET_CHATBOT_SUCCESS'

// reducer
export default function chatbotsReducer(state = initialData, action) {
  switch (action.type) {
    case SET_CHATBOT_SUCCESS:
      return {
        ...state,
        chatbot: action.payload,
        loading: false,
      }
    default:
      return state
  }
}

export const setReduxChatbot = chatbot => dispatch => {
  try {
    dispatch({
      type: SET_CHATBOT_SUCCESS,
      payload: chatbot,
    })
  } catch (err) {
    console.error(err)
  }
}
