import ChildSpinner from 'components/Others/Spinner/ChildSpinner'
import React from 'react'
import './MyButton.scss'

export default function MyButton({
  text,
  onClick,
  disabled = false,
  selectable = false,
  selected = false,
  transparent = false,
  icon = null,
  type = 'button',
  loading = false,
  className: classNameProp = '',
  deleteIcon = false,
  warningColor = false,
  ...rest
}) {
  const getClassName = () => {
    let className = 'my-button'
    if (transparent) className += ' my-button--transparent'
    if (selectable) className += ' my-button--selectable'
    if (selected) className += ' my-button--selected'
    if (warningColor) className += ' my-button--warning'
    if (classNameProp) className += ' ' + classNameProp
    return className
  }
  try {
    return (
      <button
        onClick={!loading && onClick ? onClick : () => {}}
        disabled={disabled}
        type={type}
        className={getClassName()}
        {...rest}
        style={{ pointerEvents: loading ? 'none' : 'auto', cursor: loading ? 'wait' : 'pointer' }}
      >
        {!loading && (
          <>
            {(deleteIcon || icon) && (
              <div className="my-button__icon">
                {deleteIcon ? <i className="flaticon-trash" /> : icon}
              </div>
            )}
            {text && <div>{text}</div>}
          </>
        )}
        {loading && (
          <ChildSpinner
            height="0.7rem"
            mintLogo={false}
            color={transparent ? '#5fa185b9' : '#ffffff'}
          />
        )}
      </button>
    )
  } catch (err) {
    console.error(err)
    return <></>
  }
}
