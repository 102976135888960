import React from 'react'
import isValidFunction from 'myMethods/isValidFunction'
import {
  FormControlLabel,
  Checkbox as MuiCheckbox,
} from '../../../../node_modules/@mui/material/index'
import './Checkbox.scss'

/**
 *
 * @param {{className?: string, label?: string, checked: boolean, onChange: function, color?: string, disabled?: boolean}} param0
 * @returns
 */
export default function Checkbox({
  className = '',
  label,
  checked,
  onChange,
  color = '#5fa186',
  disabled = false,
}) {
  return (
    <div className={`checkbox ${className ? className : ''}`}>
      <FormControlLabel
        control={
          <MuiCheckbox
            checked={checked}
            style={{
              color,
            }}
            onChange={e => {
              isValidFunction(onChange) && onChange(e.target.checked)
            }}
            disabled={disabled}
          />
        }
        label={label}
      />
    </div>
  )
}
